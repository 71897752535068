import { useRef, useState } from 'react';
import './drag-drop-file.scss';

export default function DragDropFile({
  handleFile,
  title,
  content,
  multiple,
  idxScholarship,
}) {
  const [dragActive, setDragActive] = useState(false);
  // const inputRef = useRef(null);
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0], idxScholarship);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0], idxScholarship);
    }
  };

  return (
    <form
      id="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <input
        // ref={inputRef}
        type="file"
        id={'input-file-upload' + idxScholarship}
        multiple={multiple}
        onChange={handleChange}
        className="input-file-upload"
      />
      <label
        id="label-file-upload"
        style={{ cursor: 'pointer' }}
        htmlFor={'input-file-upload' + idxScholarship}
        className={dragActive ? 'drag-active' : ''}
      >
        <UploadImage />
        <div style={{ fontFamily: 'SF Pro Text' }}>
          <p className="import-user-section_text text-lg font-semibold leading-7">
            {title}
          </p>
          {content ? (
            <small className="import-user-section_text leading-5 text-sm font-medium">
              {content}
            </small>
          ) : (
            <small className="import-user-section_text leading-5 text-sm font-medium">
              Drop files here or click&nbsp;
              <span
                className="font-normal underline cursor-pointer"
                style={{ color: '#0E4DA4' }}
              >
                browse
              </span>
              &nbsp;thorough your machine
            </small>
          )}
        </div>
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
    // <input
    //   // ref={inputRef}
    //   type="file"
    //   // id="input-file-upload"
    //   multiple={multiple}
    //   onChange={handleChange}
    // />
  );
}

const UploadImage = () => {
  return (
    <svg
      className="w-52 h-44"
      viewBox="0 0 223 176"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 88.6772C0 107.018 6.74953 123.73 17.8563 136.361C30.5983 150.856 49.0755 159.969 69.6715 160C78.6728 160.013 87.2796 158.28 95.1854 155.117C99.2731 153.481 103.848 153.669 107.807 155.608C112.451 157.878 117.652 159.151 123.143 159.151C125.411 159.151 127.633 158.932 129.781 158.517C135.915 157.336 141.496 154.54 146.08 150.581C148.96 148.091 152.65 146.84 156.421 146.844H156.485C168.944 146.844 180.518 142.981 190.126 136.361C198.717 130.451 205.731 122.339 210.405 112.815C214.48 104.515 216.774 95.1394 216.774 85.2163C216.774 51.1825 189.779 23.5886 156.485 23.5886C153.09 23.5886 149.765 23.8816 146.521 24.4284C137.931 10.2135 122.548 0.737305 104.999 0.737305C97.5905 0.737305 90.5666 2.42624 84.2794 5.45379C78.3002 8.32366 72.9841 12.4016 68.6354 17.3714C53.869 17.6121 40.2152 22.543 29.0401 30.7722C11.449 43.7184 0 64.8327 0 88.6772Z"
          fill="url(#paint0_linear_6148_8152)"
        />
      </g>
      <g filter="url(#filter0_d_6148_8152)">
        <path
          d="M147.201 44.3003C147.223 44.2508 147.24 44.1984 147.258 44.146C147.202 44.2618 147.154 44.3809 147.112 44.5026C147.143 44.4342 147.173 44.3672 147.201 44.3003Z"
          fill="url(#paint1_linear_6148_8152)"
        />
        <path
          d="M134.756 99.0297C134.704 99.6773 133.11 99.4328 133.11 99.4328C130.467 99.7996 130.904 97.209 130.904 97.209V97.0766L130.996 94.6607L131.022 93.9868C131.022 93.9868 133.933 92.6566 133.858 92.936C133.831 93.0408 133.892 93.2926 133.976 93.5648C134.122 94.0174 134.33 94.5297 134.33 94.5297C134.33 94.5297 134.347 95.5979 134.26 95.9123C134.173 96.2266 134.716 97.0678 134.716 97.0678C134.716 97.0678 134.716 97.1202 134.724 97.2134C134.767 97.8178 134.778 98.4241 134.756 99.0297V99.0297Z"
          fill="#B77983"
        />
        <path
          d="M139.852 99.7296C139.852 99.7296 138.258 99.9755 138.206 99.3264C138.181 98.7195 138.191 98.1117 138.233 97.5057C138.233 97.4184 138.24 97.3602 138.24 97.3602C138.24 97.3602 138.783 96.519 138.696 96.2046C138.609 95.8903 138.626 94.8206 138.626 94.8206C138.626 94.8206 138.683 94.6852 138.757 94.4902C138.913 94.0813 139.147 93.4176 139.099 93.2284C139.029 92.9475 141.936 94.2777 141.936 94.2777L141.965 95.0316L142.054 97.3675V97.4999C142.054 97.4999 142.496 100.098 139.852 99.7296Z"
          fill="#B77983"
        />
        <path
          opacity="0.05"
          d="M133.988 93.5648C132.894 94.8659 131.712 94.844 131.008 94.6607L131.034 93.9868C131.034 93.9868 133.945 92.6566 133.871 92.936C133.843 93.0408 133.898 93.2926 133.988 93.5648Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M141.965 95.0375C140.107 96.1115 139.017 94.8526 138.764 94.4961C138.919 94.0871 139.154 93.4235 139.106 93.2343C139.036 92.9534 141.942 94.2836 141.942 94.2836L141.965 95.0375Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M139.975 84.3215C139.975 84.3215 141.743 83.3027 142.129 83.7611C142.514 84.2196 139.975 84.3215 139.975 84.3215Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M141.042 84.8818C141.042 84.8818 140.751 85.0274 141.393 85.2137C142.035 85.4 141.042 84.8818 141.042 84.8818Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M130.349 82.0805C130.29 82.098 131.821 83.1138 133.203 82.2726C133.198 82.2726 130.694 81.9757 130.349 82.0805Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M133.128 84.0234C133.128 84.0234 132.709 84.8472 131.85 84.9694C130.991 85.0917 133.128 84.0234 133.128 84.0234Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M138.749 85.8105C138.749 85.8105 140.763 89.0124 140.378 89.7677C139.992 90.523 138.749 85.8105 138.749 85.8105Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M134.774 98.5422C134.774 98.7212 134.774 98.8885 134.757 99.0268C134.704 99.6744 133.111 99.4299 133.111 99.4299C131.655 99.6322 131.131 98.9337 130.958 98.2656C130.904 98.0487 130.877 97.8257 130.878 97.602C130.877 97.4686 130.886 97.3353 130.904 97.2032V97.0708C131.218 96.9762 131.558 97.0456 131.809 97.2556C132.457 97.781 133.316 97.3779 133.316 97.3779C133.316 97.3779 133.946 97.4303 134.189 97.6398C134.326 97.7577 134.551 97.4739 134.723 97.2032C134.733 97.3779 134.749 97.6937 134.759 98.0313C134.771 98.2045 134.774 98.3792 134.774 98.5422Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M132.218 98.5039C132.218 98.5039 131.782 98.9318 130.958 98.2711C130.904 98.0541 130.877 97.8311 130.878 97.6074C131.299 97.9276 132.079 98.5039 132.218 98.5039Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M134.774 98.5421C134.702 98.5975 134.61 98.6207 134.521 98.6064C134.431 98.5921 134.352 98.5414 134.301 98.4664C134.471 98.3372 134.627 98.1909 134.766 98.0298C134.771 98.2044 134.774 98.3791 134.774 98.5421Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M138.19 98.842C138.19 99.0224 138.19 99.1898 138.206 99.3266C138.258 99.9757 139.852 99.7297 139.852 99.7297C141.307 99.9335 141.831 99.2349 142.004 98.5654C142.09 98.218 142.108 97.8574 142.058 97.503V97.3706C141.744 97.2776 141.404 97.3469 141.151 97.5554C140.504 98.0808 139.647 97.6777 139.647 97.6777C139.647 97.6777 139.016 97.7315 138.773 97.9411C138.637 98.059 138.411 97.7752 138.241 97.5045C138.231 97.6791 138.213 97.9949 138.203 98.3326C138.191 98.5029 138.188 98.6775 138.19 98.842Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M140.745 98.8008C140.745 98.8008 141.182 99.2287 142.004 98.568C142.059 98.3515 142.086 98.129 142.086 97.9058C141.663 98.2245 140.883 98.8008 140.745 98.8008Z"
          fill="black"
        />
        <path
          opacity="0.05"
          d="M138.19 98.8419C138.261 98.8972 138.353 98.9205 138.442 98.9061C138.531 98.8918 138.611 98.8412 138.661 98.7662C138.491 98.6372 138.335 98.4909 138.196 98.3296C138.191 98.5028 138.188 98.6774 138.19 98.8419Z"
          fill="black"
        />
        <g opacity="0.05">
          <path
            d="M146.941 44.923C146.971 44.856 147 44.7891 147.028 44.7236C147.056 44.6581 147.066 44.6217 147.082 44.5693C147.028 44.6845 146.981 44.8026 146.941 44.923Z"
            fill="black"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M68.0703 129.231C67.7176 128.807 67.5559 128.255 67.6235 127.707L74.0475 75.1438L74.5801 70.7777C74.7036 69.773 75.5531 69.0158 76.5653 69.008L95.3818 68.8828C95.923 68.8795 96.4401 68.6583 96.8164 68.2692C97.1926 67.8801 97.3963 67.3558 97.3814 66.8147L97.3742 66.6153C97.3556 66.0696 97.5594 65.5398 97.9389 65.1472C98.3183 64.7545 98.8409 64.5328 99.3869 64.5327H119.16C120.27 64.5327 121.17 65.4312 121.172 66.5411C121.172 66.6316 121.166 66.722 121.155 66.8118C121.079 67.3783 121.248 67.9503 121.619 68.3851C121.99 68.82 122.528 69.0766 123.099 69.0909L142.089 69.5756C142.673 69.5899 143.222 69.8577 143.593 70.3093C143.965 70.7608 144.121 71.3515 144.022 71.9275L134.528 127.377L134.349 128.425C134.305 128.693 134.206 128.948 134.058 129.176L68.0703 129.231Z"
          fill="#404EED"
        />
        <rect
          x="125.376"
          y="53.397"
          width="11.119"
          height="37.7392"
          transform="rotate(34.64 125.376 53.397)"
          fill="#F9FAFB"
        />
        <rect
          width="7.84983"
          height="7.84983"
          transform="matrix(0.821109 0.570772 -0.57077 0.82111 126.035 54.8677)"
          fill="#FFC107"
        />
        <rect
          opacity="0.5"
          x="120.986"
          y="62.6353"
          width="7.86046"
          height="7.86045"
          transform="rotate(34.64 120.986 62.6353)"
          fill="#FFC107"
        />
        <rect
          opacity="0.3"
          x="115.591"
          y="70.4434"
          width="7.86046"
          height="7.86047"
          transform="rotate(34.64 115.591 70.4434)"
          fill="#FFC107"
        />
        <rect
          x="112.451"
          y="51.4521"
          width="11.119"
          height="37.5966"
          transform="rotate(16.29 112.451 51.4521)"
          fill="#F9FAFB"
        />
        <rect
          x="113.853"
          y="52.4937"
          width="7.86046"
          height="7.86046"
          transform="rotate(16.29 113.853 52.4937)"
          fill="#FF4842"
        />
        <rect
          opacity="0.5"
          x="111.19"
          y="61.6025"
          width="7.86046"
          height="7.86046"
          transform="rotate(16.29 111.19 61.6025)"
          fill="#FF4842"
        />
        <rect
          opacity="0.3"
          x="108.528"
          y="70.71"
          width="7.86047"
          height="7.86047"
          transform="rotate(16.29 108.528 70.71)"
          fill="#FF4842"
        />
        <rect
          x="104.892"
          y="52.021"
          width="11.119"
          height="37.5966"
          transform="rotate(4.6 104.892 52.021)"
          fill="#F9FAFB"
        />
        <rect
          x="106.469"
          y="52.7583"
          width="7.86046"
          height="7.86046"
          transform="rotate(4.6 106.469 52.7583)"
          fill="#2F80ED"
        />
        <rect
          opacity="0.5"
          x="105.707"
          y="62.2153"
          width="7.86046"
          height="7.86046"
          transform="rotate(4.6 105.707 62.2153)"
          fill="#2F80ED"
        />
        <rect
          opacity="0.3"
          x="104.946"
          y="71.6758"
          width="7.86046"
          height="7.86047"
          transform="rotate(4.6 104.946 71.6758)"
          fill="#2F80ED"
        />
        <rect
          x="99.9053"
          y="53.5278"
          width="11.119"
          height="37.5966"
          transform="rotate(-2.61 99.9053 53.5278)"
          fill="white"
        />
        <rect
          x="101.561"
          y="54.0591"
          width="7.86046"
          height="7.86045"
          transform="rotate(-2.61 101.561 54.0591)"
          fill="#2F80ED"
        />
        <rect
          opacity="0.5"
          x="102.301"
          y="63.2383"
          width="7.87028"
          height="7.87028"
          transform="rotate(-2.8624 102.301 63.2383)"
          fill="#2F80ED"
        />
        <rect
          opacity="0.3"
          x="102.425"
          y="73.0186"
          width="7.86045"
          height="7.86046"
          transform="rotate(-2.61 102.425 73.0186)"
          fill="#2F80ED"
        />
        <path
          d="M104.622 103.074L94.7428 106.896L80.3041 112.477C79.7583 111.458 78.955 110.081 77.961 108.438C74.5408 102.771 68.8823 93.9559 63.8439 86.209C58.1679 77.4767 53.2822 70.1082 53.2822 70.1082L56.8188 69.0894L85.7079 60.7021L104.622 103.074Z"
          fill="#E6ECF8"
        />
        <path
          opacity="0.1"
          d="M104.622 103.074L94.7429 106.896C89.2242 108.045 83.5925 108.563 77.9567 108.438C74.5365 102.771 68.8781 93.9559 63.8396 86.209L56.8203 69.0836L85.708 60.7021L104.622 103.074Z"
          fill="black"
        />
        <path
          d="M111.971 100.696C111.971 100.696 96.624 109.775 73.7107 107.721L55.4443 63.1868L64.1984 61.682L94.4614 56.4849L111.635 99.8302L111.971 100.696Z"
          fill="#404EED"
        />
        <path
          opacity="0.1"
          d="M111.63 99.836C108.016 100.014 103.835 99.9961 99.6288 99.494C95.0764 98.9584 90.4978 97.8553 86.5726 95.8323C83.2908 94.1426 80.4848 92.3045 78.0383 90.0996C71.674 84.3756 67.7358 76.1877 64.1992 61.6879L94.4622 56.4907L111.63 99.836Z"
          fill="black"
        />
        <path
          d="M122.888 97.3455C122.888 97.3455 111.576 99.5605 100.061 98.1969C95.5084 97.6613 90.9298 96.5581 87.0047 94.5351C83.7228 92.8455 80.9183 91.0073 78.4704 88.8024C70.5939 81.7162 66.4345 70.8563 62.1455 48.9253C62.1455 48.9253 80.9518 53.4646 99.5485 43.5215C99.5427 43.5288 105.811 82.6462 122.888 97.3455Z"
          fill="white"
        />
        <path
          opacity="0.1"
          d="M100.06 98.1971C95.5078 97.6615 90.9292 96.5583 87.004 94.5354C83.7222 92.8457 80.9177 91.0076 78.4697 88.8027C85.1761 88.2584 86.1395 84.5923 86.1395 84.5923C91.1111 92.4818 100.06 98.1971 100.06 98.1971Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M71.873 56.9768C71.873 56.9768 87.1661 56.1123 91.4361 52.8159L92.0838 54.8971C92.0838 54.8971 81.9791 59.3549 71.873 59.4087V56.9768Z"
          fill="#2F80ED"
        />
        <path
          opacity="0.3"
          d="M71.873 67.2515C71.873 67.2515 86.68 66.4409 97.8674 61.0371L98.2458 61.9016C98.2458 61.9016 89.3681 67.0842 71.873 68.4406L71.873 67.2515Z"
          fill="#2F80ED"
        />
        <path
          opacity="0.3"
          d="M73.0654 71.2554C73.0654 71.2554 87.8724 70.4448 99.0584 65.041L99.4368 65.9055C99.4368 65.9055 90.559 71.0866 73.0654 72.4445L73.0654 71.2554Z"
          fill="#2F80ED"
        />
        <path
          opacity="0.3"
          d="M74.2559 75.2647C74.2559 75.2647 89.0628 74.4541 100.25 69.0503L100.627 69.9148C100.627 69.9148 91.7494 75.0974 74.2559 76.4538L74.2559 75.2647Z"
          fill="#2F80ED"
        />
        <path
          opacity="0.3"
          d="M75.4473 79.2745C75.4473 79.2745 90.2542 78.4638 101.44 73.0601L101.819 73.9245C101.819 73.9245 92.9408 79.1071 75.4473 80.4635L75.4473 79.2745Z"
          fill="#2F80ED"
        />
        <path
          opacity="0.3"
          d="M76.6377 83.2852C76.6377 83.2852 91.4446 82.4746 102.632 77.0708L103.009 77.9353C103.009 77.9353 94.1313 83.1164 76.6377 84.4743V83.2852Z"
          fill="#2F80ED"
        />
        <path
          d="M180.751 133.069C180.751 133.069 174.597 132.698 175.322 137.01C175.322 137.01 175.177 137.771 175.869 138.118C175.869 138.118 175.879 137.798 176.499 137.907C176.721 137.944 176.945 137.955 177.169 137.939C177.471 137.918 177.757 137.8 177.985 137.601V137.601C177.985 137.601 179.716 136.886 180.388 134.06C180.388 134.06 180.886 133.443 180.866 133.284L179.828 133.721C179.828 133.721 180.183 134.471 179.904 135.092C179.904 135.092 179.87 133.749 179.671 133.782C179.63 133.782 179.131 134.041 179.131 134.041C179.131 134.041 179.741 135.351 179.276 136.294C179.276 136.294 179.451 134.686 178.936 134.136L178.208 134.562C178.208 134.562 178.921 135.91 178.438 137.01C178.438 137.01 178.562 135.323 178.054 134.666L177.392 135.182C177.392 135.182 178.062 136.511 177.654 137.424C177.654 137.424 177.6 135.459 177.247 135.31C177.247 135.31 176.665 135.821 176.581 136.038C176.581 136.038 177.039 137.001 176.754 137.511C176.754 137.511 176.579 136.201 176.437 136.201C176.437 136.201 175.855 137.074 175.799 137.656C175.799 137.656 175.824 136.772 176.297 136.112C175.964 136.176 175.656 136.335 175.412 136.571C175.412 136.571 175.503 135.956 176.44 135.904C176.44 135.904 176.917 135.246 177.044 135.206C177.044 135.206 176.112 135.128 175.548 135.379C175.548 135.379 176.045 134.797 177.215 135.063L177.869 134.53C177.869 134.53 176.642 134.361 176.122 134.548C176.122 134.548 176.721 134.035 178.045 134.402L178.757 133.977C178.757 133.977 177.712 133.752 177.089 133.832C177.089 133.832 177.745 133.477 178.966 133.861L179.474 133.632C179.474 133.632 178.709 133.487 178.485 133.458C178.26 133.429 178.247 133.373 178.247 133.373C178.733 133.291 179.231 133.347 179.687 133.533C179.687 133.533 180.768 133.137 180.751 133.069Z"
          fill="#2F80ED"
        />
        <ellipse
          opacity="0.1"
          cx="176.513"
          cy="138.493"
          rx="4.35447"
          ry="0.736427"
          fill="#2F80ED"
        />
        <path
          d="M183.617 118.834C183.617 118.834 180.148 118.625 180.56 121.056C180.517 121.309 180.641 121.561 180.867 121.681C180.867 121.681 180.875 121.5 181.224 121.561C181.349 121.582 181.475 121.588 181.601 121.579C181.771 121.568 181.932 121.501 182.061 121.39V121.39C182.061 121.39 183.036 120.987 183.416 119.393C183.416 119.393 183.697 119.045 183.685 118.956L183.103 119.205C183.103 119.205 183.302 119.629 183.145 119.979C183.145 119.979 183.126 119.221 183.014 119.239C182.991 119.239 182.71 119.384 182.71 119.384C182.71 119.384 183.053 120.112 182.794 120.655C182.794 120.655 182.893 119.748 182.602 119.437L182.19 119.678C182.19 119.678 182.592 120.438 182.32 121.058C182.32 121.058 182.39 120.106 182.103 119.736L181.73 120.028C181.73 120.028 182.107 120.777 181.876 121.291C181.876 121.291 181.845 120.183 181.648 120.1C181.506 120.22 181.38 120.356 181.271 120.506C181.271 120.506 181.53 121.051 181.37 121.337C181.37 121.337 181.271 120.599 181.191 120.597C181.191 120.597 180.865 121.084 180.831 121.419C180.847 121.108 180.944 120.807 181.112 120.546C180.924 120.581 180.75 120.671 180.613 120.805C180.613 120.805 180.664 120.458 181.195 120.429C181.195 120.429 181.464 120.058 181.535 120.035C181.535 120.035 181.01 119.991 180.691 120.132C180.691 120.132 180.972 119.806 181.632 119.955L182.001 119.653C182.001 119.653 181.308 119.559 181.016 119.664C181.016 119.664 181.354 119.373 182.1 119.585L182.502 119.345C182.193 119.277 181.876 119.25 181.56 119.265C181.56 119.265 181.931 119.064 182.618 119.281L182.909 119.153C182.909 119.153 182.473 119.067 182.35 119.054C182.228 119.041 182.217 119.006 182.217 119.006C182.49 118.96 182.771 118.992 183.029 119.096C183.029 119.096 183.628 118.872 183.617 118.834Z"
          fill="#2F80ED"
        />
        <ellipse
          opacity="0.1"
          cx="181.23"
          cy="121.892"
          rx="2.45522"
          ry="0.414783"
          fill="#2F80ED"
        />
        <path
          d="M163.143 120.944C163.143 120.944 158.954 120.691 159.449 123.627C159.396 123.932 159.546 124.236 159.82 124.381C159.82 124.381 159.82 124.162 160.257 124.235C160.407 124.26 160.56 124.268 160.712 124.257C160.918 124.243 161.113 124.162 161.268 124.027V124.027C161.268 124.027 162.445 123.541 162.904 121.617C162.904 121.617 163.243 121.198 163.228 121.089L162.514 121.391C162.514 121.391 162.755 121.901 162.565 122.324C162.565 122.324 162.541 121.41 162.406 121.431C162.378 121.431 162.039 121.608 162.039 121.608C162.039 121.608 162.454 122.496 162.141 123.142C162.141 123.142 162.261 122.048 161.91 121.672L161.412 121.963C161.412 121.963 161.897 122.882 161.568 123.63C161.568 123.63 161.652 122.481 161.307 122.029L160.856 122.381C160.856 122.381 161.313 123.285 161.034 123.906C161.034 123.906 160.999 122.569 160.759 122.468C160.759 122.468 160.364 122.816 160.305 122.959C160.305 122.959 160.616 123.615 160.422 123.961C160.422 123.961 160.303 123.071 160.206 123.066C160.206 123.066 159.814 123.657 159.769 124.06C159.789 123.687 159.906 123.324 160.108 123.01C159.881 123.053 159.672 123.162 159.507 123.323C159.507 123.323 159.568 122.905 160.206 122.868C160.206 122.868 160.53 122.42 160.617 122.394C160.617 122.394 159.983 122.34 159.599 122.51C159.599 122.51 159.936 122.117 160.732 122.296L161.178 121.933C161.178 121.933 160.344 121.819 159.989 121.944C159.989 121.944 160.396 121.596 161.299 121.851L161.783 121.56C161.783 121.56 161.072 121.407 160.647 121.463C160.647 121.463 161.095 121.221 161.924 121.483L162.271 121.327C162.271 121.327 161.75 121.225 161.597 121.209C161.444 121.193 161.437 121.151 161.437 121.151C161.767 121.095 162.106 121.132 162.416 121.259C162.416 121.259 163.156 120.99 163.143 120.944Z"
          fill="#2F80ED"
        />
        <ellipse
          opacity="0.1"
          cx="160.258"
          cy="124.637"
          rx="2.96458"
          ry="0.500656"
          fill="#2F80ED"
        />
        <rect
          x="126.748"
          y="54.0479"
          width="24.8388"
          height="44.4499"
          rx="4.73723"
          transform="rotate(-71.99 126.748 54.0479)"
          fill="white"
        />
        <g opacity="0.2">
          <ellipse cx="139.152" cy="43.5787" rx="4.56404" ry="4.56404" fill="#2F80ED" />
        </g>
        <g opacity="0.2">
          <rect
            x="150.072"
            y="42.7568"
            width="1.25016"
            height="19.8847"
            transform="rotate(-71.99 150.072 42.7568)"
            fill="#2F80ED"
          />
        </g>
        <g opacity="0.2">
          <rect
            x="149.087"
            y="45.7881"
            width="1.25016"
            height="10.1294"
            transform="rotate(-71.99 149.087 45.7881)"
            fill="#2F80ED"
          />
        </g>
        <g opacity="0.2">
          <rect
            x="148.1"
            y="48.8213"
            width="1.25016"
            height="16.3831"
            transform="rotate(-71.99 148.1 48.8213)"
            fill="#2F80ED"
          />
        </g>
        <g opacity="0.2">
          <rect
            x="147.114"
            y="51.853"
            width="1.25016"
            height="19.8847"
            transform="rotate(-71.99 147.114 51.853)"
            fill="#2F80ED"
          />
        </g>
        <g opacity="0.5">
          <rect
            x="159.008"
            y="61.7876"
            width="2.41737"
            height="5.50276"
            transform="rotate(-71.99 159.008 61.7876)"
            fill="#2F80ED"
          />
        </g>
        <rect
          x="122.869"
          y="97.1816"
          width="24.4857"
          height="38.118"
          rx="4"
          transform="rotate(-57.2648 122.869 97.1816)"
          fill="white"
        />
        <rect
          x="127.621"
          y="95.7451"
          width="6.92757"
          height="1.63002"
          transform="rotate(-57.57 127.621 95.7451)"
          fill="#DFE3E8"
        />
        <rect
          x="132.019"
          y="96.9292"
          width="15.2145"
          height="1.49467"
          transform="rotate(-57.57 132.019 96.9292)"
          fill="#DFE3E8"
        />
        <rect
          x="134.713"
          y="98.6401"
          width="15.2145"
          height="1.49467"
          transform="rotate(-57.57 134.713 98.6401)"
          fill="#DFE3E8"
        />
        <rect
          x="137.407"
          y="100.354"
          width="15.2144"
          height="1.49467"
          transform="rotate(-57.57 137.407 100.354)"
          fill="#DFE3E8"
        />
        <rect
          x="140.195"
          y="102.836"
          width="15.1931"
          height="1.49257"
          transform="rotate(-57.2648 140.195 102.836)"
          fill="#DFE3E8"
        />
        <rect
          width="15.2145"
          height="1.49467"
          transform="matrix(0.536268 -0.844048 0.844047 0.536269 142.797 103.777)"
          fill="#F0F0F0"
        />
        <rect
          x="145.491"
          y="105.488"
          width="15.2144"
          height="1.49467"
          transform="rotate(-57.57 145.491 105.488)"
          fill="#F0F0F0"
        />
        <rect
          width="3.93969"
          height="3.93968"
          transform="matrix(0.536268 -0.844048 0.844047 0.53627 156.806 98.1929)"
          fill="#DFE3E8"
        />
        <path
          d="M122.436 75.3433L100.158 75.5835C99.3237 75.5922 98.6521 76.2704 98.6513 77.1043V78.9454C98.6508 79.771 97.9922 80.4457 97.1669 80.4662L81.0908 80.8519C80.3897 80.8695 79.7675 80.4054 79.5845 79.7284L78.8292 76.953C78.648 76.2834 78.0368 75.8213 77.3433 75.8294L55.8693 76.0608C55.424 76.0652 55.003 76.2645 54.7174 76.6061C54.4317 76.9477 54.3102 77.3974 54.3848 77.8364L63.0239 128.681C63.1477 129.411 63.7784 129.946 64.5185 129.949L132.359 130.24C132.814 130.241 133.245 130.04 133.536 129.69C133.826 129.34 133.945 128.879 133.86 128.432L123.946 76.5789C123.808 75.8562 123.172 75.3359 122.436 75.3433V75.3433Z"
          fill="#404EED"
        />
        <ellipse
          opacity="0.1"
          cx="49.5721"
          cy="119.108"
          rx="2.35916"
          ry="0.324547"
          fill="#2F80ED"
        />
        <ellipse
          opacity="0.1"
          cx="40.5359"
          cy="120.023"
          rx="2.35916"
          ry="0.324547"
          fill="#2F80ED"
        />
        <ellipse
          opacity="0.1"
          cx="44.1522"
          cy="122.633"
          rx="1.67659"
          ry="0.229954"
          fill="#2F80ED"
        />
        <ellipse
          opacity="0.1"
          cx="34.7742"
          cy="122.403"
          rx="1.67659"
          ry="0.229954"
          fill="#2F80ED"
        />
        <path
          d="M40.4848 119.952C40.4848 119.952 43.7928 109.107 39.9666 104.762C37.1039 101.512 33.8541 101.901 32.3987 102.303C31.6371 102.506 31.003 103.033 30.6639 103.745C30.1545 104.842 30.2462 106.624 33.8381 108.703C39.8502 112.185 40.2242 116.96 40.2242 116.96L40.4848 119.952Z"
          fill="#2F80ED"
        />
        <path
          d="M32.9189 103.841C32.9189 103.841 42.6699 106.897 40.4869 119.952"
          stroke="#404EED"
          strokeWidth="0.5"
          strokeLinecap="round"
        />
        <path
          d="M38.6148 104.934C38.6148 104.934 37.2715 105.839 37.6994 107.2"
          stroke="#404EED"
          strokeWidth="0.5"
          strokeLinecap="round"
        />
        <path
          d="M33.8965 105.74C33.8965 105.74 34.7173 104.905 35.7171 105.366"
          stroke="#404EED"
          strokeWidth="0.5"
          strokeLinecap="round"
        />
        <path
          d="M37.752 109.94C37.752 109.94 39.1942 109.213 39.5668 110.125"
          stroke="#404EED"
          strokeWidth="0.5"
          strokeLinecap="round"
        />
        <path
          d="M41.3185 111.464C41.3185 111.464 40.2357 111.378 40.2168 111.901"
          stroke="#404EED"
          strokeWidth="0.5"
          strokeLinecap="round"
        />
        <path
          d="M40.6191 120.038C40.6191 120.038 36.7434 112.156 35.6795 112.324C35.1745 112.407 34.9417 112.907 34.8354 113.404C34.7048 114.05 34.7785 114.721 35.0464 115.323C35.5966 116.589 37.0738 118.983 40.6191 120.038Z"
          fill="#2F80ED"
        />
        <path
          d="M35.708 113.823C35.708 113.823 39.7743 119.72 40.5602 119.979"
          stroke="#404EED"
          strokeWidth="0.5"
          strokeLinecap="round"
        />
        <path
          d="M35.334 114.745H36.2829"
          stroke="#404EED"
          strokeWidth="0.5"
          strokeLinecap="round"
        />
        <path
          d="M36.4707 116.902L37.9595 116.966"
          stroke="#404EED"
          strokeWidth="0.5"
          strokeLinecap="round"
        />
        <path
          d="M37.3774 115.175L37.2158 115.951"
          stroke="#404EED"
          strokeWidth="0.5"
          strokeLinecap="round"
        />
        <path
          d="M38.9594 117.535L38.9303 118.239"
          stroke="#404EED"
          strokeWidth="0.5"
          strokeLinecap="round"
        />
        <path
          d="M40.5039 120.038C40.5039 120.038 44.3781 112.156 45.442 112.324C45.9485 112.407 46.1813 112.907 46.2861 113.404C46.4167 114.05 46.343 114.721 46.0751 115.323C45.5249 116.589 44.0463 118.983 40.5039 120.038Z"
          fill="#2F80ED"
        />
        <path
          d="M45.4142 113.823C45.4142 113.823 41.3392 119.72 40.5605 119.979"
          stroke="#404EED"
          strokeWidth="0.5"
          strokeLinecap="round"
        />
        <path
          d="M45.7887 114.745H44.8398"
          stroke="#404EED"
          strokeWidth="0.5"
          strokeLinecap="round"
        />
        <path
          d="M44.6519 116.902L43.1631 116.966"
          stroke="#404EED"
          strokeWidth="0.5"
          strokeLinecap="round"
        />
        <path
          d="M43.7451 115.175L43.9067 115.951"
          stroke="#404EED"
          strokeWidth="0.5"
          strokeLinecap="round"
        />
        <path
          d="M42.1627 117.535L42.1918 118.239"
          stroke="#404EED"
          strokeWidth="0.5"
          strokeLinecap="round"
        />
        <path
          d="M33.8503 121.879C33.7448 121.793 33.6682 121.676 33.6305 121.546C33.5934 121.41 33.6583 121.268 33.7848 121.207C33.9303 121.153 34.0758 121.25 34.1923 121.352C34.3087 121.454 34.4411 121.562 34.5867 121.537C34.4296 121.395 34.3588 121.18 34.4004 120.972C34.4064 120.93 34.424 120.89 34.4513 120.857C34.5299 120.773 34.6726 120.809 34.7671 120.876C35.0582 121.086 35.1499 121.492 35.1514 121.859C35.1659 121.724 35.1659 121.588 35.1514 121.454C35.1435 121.316 35.2046 121.184 35.3144 121.1C35.3852 121.062 35.4651 121.043 35.5458 121.045C35.681 121.026 35.8181 121.065 35.9227 121.153C36.0246 121.291 36.0186 121.482 35.9082 121.614C35.7967 121.739 35.6648 121.844 35.5181 121.925C35.4068 121.992 35.3118 122.083 35.2401 122.192C35.2314 122.206 35.2245 122.222 35.2198 122.238H34.3727C34.1831 122.143 34.0074 122.022 33.8503 121.879Z"
          fill="#2F80ED"
        />
        <path
          d="M48.6151 118.703C48.5094 118.618 48.4326 118.502 48.3953 118.371C48.358 118.236 48.4222 118.094 48.5481 118.032C48.6937 117.979 48.8392 118.076 48.9556 118.178C49.0721 118.28 49.2031 118.39 49.353 118.369C49.1958 118.227 49.1245 118.013 49.1652 117.805C49.1717 117.763 49.1898 117.723 49.2176 117.69C49.2962 117.606 49.4388 117.642 49.5334 117.708C49.8318 117.919 49.9162 118.325 49.9176 118.692C49.9322 118.557 49.9322 118.421 49.9176 118.286C49.9045 118.15 49.9588 118.016 50.0632 117.928C50.1343 117.89 50.2141 117.871 50.2946 117.874C50.4296 117.855 50.5663 117.893 50.6715 117.98C50.7738 118.119 50.7672 118.311 50.6555 118.443C50.5448 118.568 50.4134 118.673 50.2669 118.754C50.1556 118.821 50.0606 118.911 49.989 119.019C49.9804 119.034 49.9736 119.05 49.9686 119.067H49.1405C48.9495 118.971 48.7727 118.848 48.6151 118.703V118.703Z"
          fill="#2F80ED"
        />
        <path
          d="M43.6945 121.879C43.5891 121.792 43.5121 121.676 43.4733 121.546C43.4362 121.41 43.5011 121.268 43.6275 121.207C43.7731 121.153 43.9186 121.25 44.0351 121.352C44.1515 121.454 44.281 121.565 44.4324 121.544C44.2755 121.402 44.2043 121.188 44.2446 120.98C44.2511 120.937 44.2692 120.897 44.297 120.865C44.3756 120.78 44.5182 120.817 44.6128 120.883C44.9112 121.093 44.9941 121.499 44.9971 121.866C45.0116 121.731 45.0116 121.596 44.9971 121.461C44.9856 121.324 45.0428 121.19 45.1499 121.103C45.2206 121.064 45.3006 121.045 45.3813 121.048C45.5165 121.029 45.6535 121.068 45.7582 121.156C45.8603 121.295 45.8536 121.485 45.7422 121.617C45.6312 121.742 45.4998 121.847 45.3536 121.928C45.2423 121.995 45.1473 122.086 45.0756 122.195C45.0668 122.209 45.06 122.225 45.0553 122.241H44.2112C44.0236 122.144 43.8498 122.022 43.6945 121.879V121.879Z"
          fill="#2F80ED"
        />
        <ellipse
          opacity="0.1"
          cx="32.4732"
          cy="35.5449"
          rx="3.14797"
          ry="3.14796"
          fill="#2F80ED"
        />
        <ellipse
          opacity="0.1"
          cx="183.845"
          cy="60.7007"
          rx="3.14796"
          ry="3.14797"
          fill="#2F80ED"
        />
        <ellipse
          opacity="0.1"
          cx="127.257"
          cy="25.5479"
          rx="3.14796"
          ry="3.14797"
          fill="#2F80ED"
        />
        <circle opacity="0.1" cx="38.7689" cy="52.9119" r="5.27572" fill="#2F80ED" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.6486 95.375C87.7663 95.375 84.6191 98.5222 84.6191 102.404C84.6191 106.287 87.7663 109.434 91.6486 109.434C95.5308 109.434 98.678 106.287 98.678 102.404C98.678 98.5222 95.5308 95.375 91.6486 95.375V95.375Z"
          fill="#404EED"
        />
        <path
          opacity="0.72"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.6483 96.1929C95.0789 96.1929 97.86 98.974 97.86 102.405C97.86 105.835 95.0789 108.616 91.6483 108.616C88.2176 108.616 85.4365 105.835 85.4365 102.405C85.4395 98.9752 88.2189 96.1959 91.6483 96.1929"
          fill="#2F80ED"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88.7422 102.918L88.4023 102.586C88.3288 102.519 88.2871 102.425 88.2871 102.327C88.2871 102.229 88.3288 102.135 88.4023 102.068L91.3846 99.1548C91.4534 99.0832 91.5496 99.0425 91.6502 99.0425C91.7509 99.0425 91.847 99.0832 91.9158 99.1548L94.8982 102.065C94.9702 102.133 95.0109 102.227 95.0109 102.325C95.0109 102.422 94.9702 102.516 94.8982 102.584L94.5582 102.915C94.4887 102.986 94.3928 103.025 94.2927 103.025C94.1925 103.025 94.0967 102.986 94.0271 102.915L92.2637 101.101V105.406C92.2644 105.501 92.2261 105.593 92.1573 105.661C92.0885 105.728 91.9949 105.766 91.8972 105.766H91.4059C91.202 105.766 91.0367 105.605 91.0367 105.406V101.106L89.2733 102.921C89.2034 102.991 89.1074 103.03 89.0072 103.029C88.907 103.029 88.8114 102.989 88.7422 102.918V102.918Z"
          fill="#404EED"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_6148_8152"
          x="17.3252"
          y="10.3999"
          width="205.668"
          height="164.83"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="12" dy="12" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.321569 0 0 0 0 0.286275 0 0 0 0.32 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6148_8152"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6148_8152"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_6148_8152"
          x1="148.495"
          y1="188.328"
          x2="225.532"
          y2="11.083"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0E4DA4" />
          <stop offset="1" stopColor="#00AB55" stopOpacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6148_8152"
          x1="147.258"
          y1="44.5026"
          x2="147.258"
          y2="44.146"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
      </defs>
    </svg>
  );
};
