import { useEffect, useState } from 'react';
import CashierFeeCard from '../CashierFeeCard';

import './unpaid-list.scss';

function UnpaidList(props) {
  const { fee = [], handleSelected = () => {} } = props;
  const [listFee, setListFee] = useState([]);

  useEffect(() => {
    setListFee(fee);
  }, [fee]);

  let totalPriceDueThisMonth = 0;
  let totalPriceDueLater = 0;
  listFee.forEach((fee) => {
    if (fee.isNow) {
      totalPriceDueThisMonth += fee.price;
    } else if (!fee.isPaid) {
      totalPriceDueLater += fee.price;
    }
  });

  return (
    <div className="unpaid-list">
      <div className="mt-5">
        <div className="flex items-center justify-between mb-2">
          <p className="unpaid-list-title">Due this Month</p>
          <p className="unpaid-list-price">₹ {totalPriceDueThisMonth}</p>
        </div>
        {/* <div className="max-h-[35vh] overflow-y-auto w-full"> */}
        <div className=''>
          {listFee.map((data, idx) => {
            return (
              data.isNow && !data.isPaid && (
                <CashierFeeCard
                  dueDate={data.date}
                  key={`${data.id}-${data.name}`}
                  isComplete={data.isSelected}
                  title={data.name}
                  id={data.id}
                  price={data.price}
                  idx={idx}
                  handleSelected={handleSelected}
                />
              )
            );
          })}
        </div>
        {/* </div> */}
      </div>

      <div className="my-5">
        <div className="flex items-center justify-between mb-2">
          <p className="unpaid-list-title">Due later</p>
          <p className="unpaid-list-price">₹ {totalPriceDueLater}</p>
        </div>
        <div className=''>
          {listFee.map((data, idx) => {
            return (
              !data.isNow && !data.isPaid && (
                <CashierFeeCard
                  dueDate={data.date}
                  key={`${data.id}-${data.name}`}
                  isComplete={data.isSelected}
                  title={data.name}
                  id={data.id}
                  price={data.price}
                  idx={idx}
                  handleSelected={handleSelected}
                />
              )
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default UnpaidList;
