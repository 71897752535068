import React from 'react';
import clsx from 'clsx';

const Draw = (props) => {
  const { disable, onlyView, indexPer, handleChange, roleName } = props;

  return (
    <svg
      width="24"
      className={clsx('mr-2', onlyView ? 'cursor-not-allowed' : 'cursor-pointer')}
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={(e) => {
        if (onlyView) return;
        handleChange(roleName, indexPer, 'edit', disable);
      }}
    >
      <circle cx="12" cy="12" r="12" fill={!disable ? '#C4CDD5' : '#404EED'} />
      <rect width="12" height="12" transform="translate(6 6)" fill={!disable ? '#C4CDD5' : '#404EED'} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3302 8.29993L15.7002 9.66993C16.0831 10.0348 16.0988 10.6408 15.7352 11.0249L11.2352 15.5249C11.0722 15.6865 10.8585 15.7872 10.6302 15.8099L8.54515 15.9999H8.50015C8.36725 16.0007 8.23951 15.9485 8.14515 15.8549C8.03967 15.7498 7.98651 15.6032 8.00015 15.4549L8.21515 13.3699C8.2379 13.1415 8.33853 12.9279 8.50015 12.7649L13.0002 8.26493C13.3881 7.93721 13.96 7.95226 14.3302 8.29993ZM12.6602 9.99993L14.0002 11.3399L15.0002 10.3649L13.6352 8.99993L12.6602 9.99993Z"
        fill="white"
      />
    </svg>
  );
};
export default Draw;
