import React, { memo, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';

import { FeeTermsCard, ToggleButton, TypesOfFees } from '@stories';
import { anal, analarrow, analarrow_down, anal_down, arrow } from '@static/image';
import { feebookField } from 'entities/data';
import { formatMoney } from '@helper/format-money';

const Card = memo((props) => {
  const {
    termsLength = 0,
    types,
    data,
    feeBookInfo,
    burger,
    title,
    refresh,
    handleAddData,
    dataFeeCallApi,
    setDataFeeCallApi = () => {},
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [admission, setAdmission] = useState(false);
  const [application, setApplication] = useState(false);
  const [book, setBook] = useState(false);
  const [active, setActive] = useState(types && types[0]);
  const [classFeeInfo, setClassFeeInfo] = useState();
  const [feeInfo, setFeeInfo] = useState({});
  const [numFee, setNumFee] = useState(0);
  const [numBook, setNumBook] = useState(0);
  const [numAdms, setNumAdms] = useState(0);
  const [estAnnualFee, setEstAnnualFee] = useState(0);
  const [numApplications, setNumApplications] = useState(0);
  const [admissionFeeInfo, setAdmissionFeeInfo] = useState({});
  const [applicationFeeInfo, setApplicationFeeInfo] = useState({});

  const [numFeeSave, setNumFeeSave] = useState(0);
  const [numBookSave, setNumBookSave] = useState(0);
  const [numAdmsSave, setNumAdmsSave] = useState(0);
  const [estAnnualFeeSave, setEstAnnualFeeSave] = useState(0);
  const [numApplicationsSave, setNumApplicationsSave] = useState(0);
  // const [numberTerms, setNumberTerms] = useState(0);
  const [termsArray, setTermsArray] = useState([]);
  const [termsArraySave, setTermsArraySave] = useState([]);

  const getInfo = useCallback(
    (data, feeBookInfo) => {
      if (!data || !feeBookInfo) {
        return '';
      }
      let result;
      if (feeBookInfo?.tutionFees) {
        result = feeBookInfo.tutionFees.find((fee) => {
          return fee.classId === data.id;
        });
      }
      if (!result) {
        return '';
      }
      // return JSON.parse(result.info);
      return result.info;
    },
    [feeBookInfo],
  );

  useEffect(() => {
    const est = calcEstManualFee(
      numFee,
      numBook,
      numAdms,
      active,
      book,
      admission,
      termsArray,
    );
    setEstAnnualFee(est);
  }, [numFee, numBook, numAdms, active, book, admission, termsArray]);
  
  useEffect(() => {
    if (termsLength) {
      if (termsLength === 0) {
        setTermsArray([]);
      } else if (termsLength > termsArray.length) {
        setTermsArray((prev) => {
          const arr = [...prev];
          for (let i = termsArray.length; i < termsLength; i++) {
            arr.push({
              name: `Term ${i + 1} Fee`,
              fee: 0,
              dueFee: new Date(),
            });
          }
          return arr;
        });
      } else if (termsLength < termsArray.length) {
        setTermsArray((prev) => {
          return prev.slice(0, termsLength);
        });
      }
    }
  }, [termsLength]);

  useEffect(() => {
    const dataInfo = getInfo(data, feeBookInfo);
    setFeeInfo(dataInfo);
    if (data) {
      setClassFeeInfo(dataInfo);
    }
    if (dataInfo.feeType === 'Custom' || dataInfo.feeType === 'Term Dates') {
      const dataTerm = dataInfo.feeDistribution.terms;
      dataTerm && setTermsArray(dataTerm);
      dataTerm && setTermsArraySave(JSON.parse(JSON.stringify(dataTerm)));
    }
    setAdmissionFeeInfo((prev) => {
      const admissionFee = feeBookInfo.admissionFee;
      if (typeof admissionFee === 'string') {
        const admFeeInfo = JSON.parse(admissionFee);
        return admFeeInfo;
      } else {
        return {};
      }
    });
    setApplicationFeeInfo((prev) => {
      const applicationFee = feeBookInfo.applicationFee;
      if (typeof applicationFee === 'string') {
        const applicationFeeInfo = JSON.parse(applicationFee);
        return applicationFeeInfo;
      } else {
        return {};
      }
    });
  }, [data, feeBookInfo]);

  useEffect(() => {
    if (!!classFeeInfo) {
      setActive(classFeeInfo.feeType);
      classFeeInfo.feeDistribution &&
        setAdmission(!!classFeeInfo.feeDistribution.admissionFee?.value);
      classFeeInfo.feeDistribution?.bookFee &&
        setBook(!!classFeeInfo.feeDistribution.bookFee?.value);
      classFeeInfo.feeDistribution?.applicationFee &&
        setApplication(!!classFeeInfo.feeDistribution.applicationFee?.value);

      classFeeInfo.feeTypeFee?.value
        ? setNumFee(classFeeInfo.feeTypeFee.value)
        : setNumFee(numFeeSave);
      classFeeInfo.bookKitFee?.value
        ? setNumBook(classFeeInfo.bookKitFee.value)
        : setNumBook(numBookSave);
      classFeeInfo.admissionFee?.value
        ? setNumAdms(classFeeInfo.admissionFee.value)
        : setNumAdms(numAdmsSave);
      classFeeInfo.applicationFee?.value
        ? setNumApplications(classFeeInfo.applicationFee.value)
        : setNumApplications(numApplicationsSave);
      classFeeInfo.estAnnualFee?.value
        ? setEstAnnualFee(classFeeInfo.estAnnualFee.value)
        : setEstAnnualFee(estAnnualFeeSave);

      classFeeInfo.feeTypeFee?.value && setNumFeeSave(classFeeInfo.feeTypeFee.value);
      classFeeInfo.bookKitFee?.value && setNumBookSave(classFeeInfo.bookKitFee.value);
      classFeeInfo.admissionFee?.value && setNumAdmsSave(classFeeInfo.admissionFee.value);
      classFeeInfo.estAnnualFee?.value &&
        setEstAnnualFeeSave(classFeeInfo.estAnnualFee.value);
      classFeeInfo.applicationFee?.value &&
        setNumApplicationsSave(classFeeInfo.applicationFee.value);
    } else {
      setNumFee(numFeeSave);
      setNumBook(numBookSave);
      setNumAdms(numAdmsSave);
      setEstAnnualFee(estAnnualFeeSave);
      setNumApplications(numApplicationsSave);
    }
  }, [classFeeInfo, refresh]);

  const stringValidation = (val, title = '') => {
    // if(val === 0) {
    //   return '-'
    // }
    if (title === 'book-fee' && !book) {
      return '--';
    }
    if (title === 'admission-fee' && !admission) {
      return '--';
    }
    return formatMoney(val);
  };

  const calcCustomFee = (termsArray = []) => {
    let sum = 0;
    termsArray.forEach((term) => {
      sum += +term.fee;
    });
    return sum;
  };

  const calcEstManualFee = (
    numFee,
    numBook,
    numAdms,
    active,
    book,
    admission,
    termsArray = [],
  ) => {
    let sum = 0;
    if (active !== 'Custom' && active !== 'Term Dates') {
      sum += +numFee;
    } else {
      sum += calcCustomFee(termsArray);
    }
    sum += book ? +numBook : 0;
    sum += admission ? +numAdms : 0;
    return sum;
  };

  const handleSetTutionFeeCallApi = (listTerms) => {
    const dataFee = { ...dataFeeCallApi };
    const key = feebookField[title];
    if (dataFee[key]?.[data.id]) {
      dataFee[key][data.id].feeType = active
      if (dataFee[key][data.id].feeDistribution){
        const feeDistri = {
          terms: listTerms,
          bookFee: book ? numBook : null,
          admissionFee: admission ? numAdms : null,
          applicationFee: application ? numApplications : null,
          numberOfTerms: termsLength,
        }
        dataFee[key][data.id].feeDistribution = {...feeDistri};
      }
      setDataFeeCallApi(dataFee);
    } else {
      const info = {
        ...feeInfo,
        feeTypeFee: calcCustomFee(listTerms),
        feeType: active,
        feeDistribution: {
          bookFee: book ? numBook : null,
          admissionFee: admission ? numAdms : null,
          applicationFee: application ? numApplications : null,
          numberOfTerms: termsLength,
          terms: listTerms
        },
      };
      const objFee = { ...dataFeeCallApi[key], [data.id]: info };
      handleAddData(key, objFee);
    }
  }

  // let termsArray = [...Array(Number(numberTerms)).keys()];
  const styles = {
    p: { color: '#81878c', fontSize: '0.875rem', marginBottom: '0.313rem' },
    img: { marginBottom: '0.125rem', marginLeft: '0.313rem' },
    span: { fontSize: '0.75rem', marginBottom: '0.063rem' },
  };

  return (
    <React.Fragment>
      <div
        className={clsx(
          'feebook-edit-card py-2',
          isOpen ? 'feebook-edit-card_open' : 'feebook-edit-card_closed',
        )}
      >
        <div className="feebook-edit-card_row w-full" onClick={() => setIsOpen(!isOpen)}>
          <p
            className={clsx(
              'font-normal text-base ml-8',
              burger ? 'min-w-[8rem]' : 'min-w-[9rem]',
            )}
          >
            {title === 'Food Fee' || title === 'Transport Fee' ? title : data.name}
          </p>
          <TypesOfFees
            title={`${active} Fee`}
            sum={
              (active !== 'Custom' && active !== 'Term Dates')
                ? `${stringValidation(numFee)}`
                : `${stringValidation(calcCustomFee(termsArray))}`
            }
          />
          <TypesOfFees
            title="Book-Kit Fee"
            sum={`${stringValidation(numBook, 'book-fee')}`}
          />
          <TypesOfFees
            title="Admission Fee"
            sum={`${stringValidation(numAdms, 'admission-fee')}`}
          />
          <TypesOfFees
            title="Est. Annual Fee"
            sum={`${stringValidation(estAnnualFee, '')}`}
          />
          <div className="flex items-center">
            {classFeeInfo?.totalPercentageChange && (
              <img
                src={
                  classFeeInfo?.totalPercentageChange &&
                  classFeeInfo.totalPercentageChange <= 0
                    ? anal_down
                    : anal
                }
                alt=""
                className={clsx(
                  classFeeInfo?.totalPercentageChange &&
                    classFeeInfo.totalPercentageChange <= 0
                    ? 'rotate-90'
                    : '',
                )}
              />
            )}
            <span
              className={clsx(
                'ml-1',
                classFeeInfo?.totalPercentageChange &&
                  classFeeInfo.totalPercentageChange <= 0
                  ? 'text-[#ff4343]'
                  : '',
              )}
            >
              {!!classFeeInfo?.totalPercentageChange
                ? '' + classFeeInfo.totalPercentageChange.toFixed(2) + '%'
                : '--'}
            </span>
          </div>
          <button
            className={clsx('feebook-edit-card_row_button', burger ? 'mr-4' : 'mr-8')}
          >
            <img src={arrow} alt="" />
          </button>
        </div>
        {isOpen && (
          <div className="feebook-edit-card_content w-[95%]">
            <h3 className=" font-normal text-sm">Fee Type</h3>
            <div className="feebook-edit-card_content_buttons flex">
              {types.map((type) => (
                <button
                  key={type}
                  onClick={() => {
                    setActive(type);
                    if (type !== 'Custom' && type !== 'Term Dates') {
                      setNumFee(0);
                    }
                    // const estFee = calcEstManualFee(numFee, numBook, numAdms, type, book, admission, termsArray)
                    // setEstAnnualFee(estFee)
                  }}
                  className={clsx('tab-button', type === active && 'tab-button_active')}
                >
                  {type}
                </button>
              ))}
            </div>
            <h3 className=" font-normal text-sm">Fee Distribution</h3>
            <div className="flex ml-1.5 mb-3">
              <div className="flex flex-col w-40">
                <p style={styles.p}>
                  {(active === 'Custom' || active === 'Term Dates') ? 'Number of Terms' : `${active} Fee`}
                </p>
                <p className="flex items-center">
                  {(active === 'Custom' || active === 'Term Dates') ? (
                    <input
                      disabled
                      value={termsLength}
                      type="number"
                      className="w-14 border-none outline-none"
                    />
                  ) : (
                    <React.Fragment>
                      ₹{' '}
                      <input
                        value={numFee}
                        type="number"
                        className="ml-1 border-none outline-none"
                        style={{ maxWidth: '3.5rem' }}
                        onChange={(e) => {
                          const info = {
                            ...feeInfo,
                            feeTypeFee: +e.target.value,
                            feeType: active,
                            feeDistribution: {
                              bookFee: book ? numBook : null,
                              admissionFee: admission ? numAdms : null,
                            },
                          };

                          if (title !== 'Tution Fee' && title !== 'Residential Fee') {
                            handleAddData(feebookField[title], {
                              info,
                            });
                          } else {
                            const key = feebookField[title];
                            if (!dataFeeCallApi?.[key]) {
                              handleAddData(key, {
                                [data.id]: info,
                              });
                            } else {
                              const objFee = { ...dataFeeCallApi[key], [data.id]: info };
                              handleAddData(key, objFee);
                            }
                          }
                          setNumFee(+e.target.value);
                          setFeeInfo(info);
                        }}
                      />{' '}
                      {classFeeInfo?.feeTypeFee?.percentageChange && (
                        <img
                          src={
                            classFeeInfo?.feeTypeFee?.percentageChange &&
                            classFeeInfo.feeTypeFee.percentageChange <= 0
                              ? analarrow_down
                              : analarrow
                          }
                          style={styles.img}
                          alt=""
                          className={clsx(
                            classFeeInfo?.feeTypeFee?.percentageChange &&
                              classFeeInfo.feeTypeFee.percentageChange <= 0
                              ? 'rotate-180'
                              : '',
                          )}
                        />
                      )}
                      <span
                        style={styles.span}
                        className={clsx(
                          classFeeInfo?.feeTypeFee?.percentageChange &&
                            classFeeInfo.feeTypeFee.percentageChange <= 0
                            ? 'text-[#ff4343]'
                            : 'text-[#2ED775]',
                        )}
                      >
                        {!!classFeeInfo?.feeTypeFee?.percentageChange
                          ? '' + classFeeInfo.feeTypeFee.percentageChange.toFixed(2) + '%'
                          : '--'}
                      </span>
                    </React.Fragment>
                  )}
                </p>
              </div>
              <div className="flex flex-col w-40 mr-6">
                <div className="flex items-center">
                  <p style={styles.p}>Admission Fee</p>
                  <ToggleButton
                    value={admission}
                    onChange={() => {
                      if (
                        admissionFeeInfo.isActive &&
                        admissionFeeInfo.type !== 'Different'
                      ) {
                        return;
                      }
                      setAdmission(!admission);
                      let feeDistribution = {
                        ...feeInfo.feeDistribution,
                        admissionFee: !admission ? numAdms : null,
                        bookFee: book ? numBook : null,
                        applicationFee: application ? numApplications : null,
                      };
                      const info = {
                        ...feeInfo,
                        admissionFee: numAdms,
                        feeType: active,
                        feeDistribution: feeDistribution,
                      };
                      setFeeInfo(info);
                      if (title !== 'Tution Fee' && title !== 'Residential Fee') {
                        handleAddData(feebookField[title], {
                          info,
                        });
                      } else {
                        const key = feebookField[title];
                        if (!dataFeeCallApi?.[key]) {
                          handleAddData(key, {
                            [data.id]: info,
                          });
                        } else {
                          const objFee = { ...dataFeeCallApi[key], [data.id]: info };
                          handleAddData(key, objFee);
                        }
                      }
                    }}
                    inputSize="smalling"
                    customStyle={styles.img}
                  />
                </div>
                {admission && (
                  <p
                    className={clsx(
                      'flex items-center',
                      admission ? ' card-row_active' : ' card-row',
                    )}
                  >
                    ₹{' '}
                    <input
                      value={numAdms}
                      type="number"
                      className="ml-1 border-none outline-none"
                      style={{ maxWidth: '3.5rem' }}
                      onChange={(e) => {
                        setNumAdms(+e.target.value);
                        let feeDistribution = {
                          ...feeInfo.feeDistribution,
                          admissionFee: +e.target.value,
                          bookFee: book ? numBook : null,
                          applicationFee: application ? numApplications : null,
                        };
                        const info = {
                          ...feeInfo,
                          admissionFee: +e.target.value,
                          feeType: active,
                          feeDistribution: feeDistribution,
                        };
                        setFeeInfo(info);
                        if (title !== 'Tution Fee' && title !== 'Residential Fee') {
                          handleAddData(feebookField[title], {
                            info,
                          });
                        } else {
                          const key = feebookField[title];
                          if (!dataFeeCallApi?.[key]) {
                            handleAddData(key, {
                              [data.id]: info,
                            });
                          } else {
                            const objFee = { ...dataFeeCallApi[key], [data.id]: info };
                            handleAddData(key, objFee);
                          }
                        }
                      }}
                    />{' '}
                    {classFeeInfo?.admissionFee?.percentageChange && (
                      <img
                        src={
                          classFeeInfo?.admissionFee?.percentageChange &&
                          classFeeInfo.admissionFee.percentageChange <= 0
                            ? analarrow_down
                            : analarrow
                        }
                        style={styles.img}
                        alt=""
                        className={clsx(
                          classFeeInfo?.admissionFee?.percentageChange &&
                            classFeeInfo.admissionFee.percentageChange <= 0
                            ? 'rotate-180'
                            : '',
                        )}
                      />
                    )}
                    <span
                      style={styles.span}
                      className={clsx(
                        classFeeInfo?.admissionFee?.percentageChange &&
                          classFeeInfo.admissionFee.percentageChange <= 0
                          ? 'text-[#ff4343]'
                          : 'text-[#2ED775]',
                      )}
                    >
                      {!!classFeeInfo?.admissionFee?.percentageChange
                        ? '' + classFeeInfo.admissionFee.percentageChange.toFixed(2) + '%'
                        : '--'}
                    </span>
                  </p>
                )}
              </div>
              <div className="flex flex-col mr-6">
                <div className="flex items-center">
                  <p className="flex items-center" style={styles.p}>
                    Book Fee
                  </p>
                  <ToggleButton
                    value={book}
                    onChange={() => {
                      setBook(!book);
                      let feeDistribution = {
                        ...feeInfo.feeDistribution,
                        bookFee: !book ? numBook : null,
                        admissionFee: admission ? numAdms : null,
                        applicationFee: application ? numApplications : null,
                      };
                      let info = {
                        ...feeInfo,
                        bookKitFee: numBook,
                        feeType: active,
                        feeDistribution: feeDistribution,
                      };
                      setFeeInfo(info);
                      if (title !== 'Tution Fee' && title !== 'Residential Fee') {
                        handleAddData(feebookField[title], {
                          info,
                        });
                      } else {
                        const key = feebookField[title];
                        if (!dataFeeCallApi?.[key]) {
                          handleAddData(key, {
                            [data.id]: info,
                          });
                        } else {
                          const objFee = { ...dataFeeCallApi[key], [data.id]: info };
                          handleAddData(key, objFee);
                        }
                      }
                    }}
                    inputSize="smalling"
                    customStyle={styles.img}
                  />
                </div>
                {book && (
                  <p
                    className={clsx(
                      'flex items-center',
                      book ? ' card-row_active' : ' card-row',
                    )}
                  >
                    ₹{' '}
                    <input
                      value={numBook}
                      type="number"
                      className="ml-1 border-none outline-none"
                      style={{ maxWidth: '3.5rem' }}
                      onChange={(e) => {
                        setNumBook(e.target.value);
                        let feeDistribution = {
                          ...feeInfo.feeDistribution,
                          bookFee: +e.target.value,
                          admissionFee: admission ? numAdms : null,
                          applicationFee: application ? numApplications : null,
                        };
                        let info = {
                          ...feeInfo,
                          bookKitFee: +e.target.value,
                          feeType: active,
                          feeDistribution: feeDistribution,
                        };
                        setFeeInfo(info);

                        if (title !== 'Tution Fee' && title !== 'Residential Fee') {
                          handleAddData(feebookField[title], {
                            info,
                          });
                        } else {
                          const key = feebookField[title];
                          if (!dataFeeCallApi?.[key]) {
                            handleAddData(key, {
                              [data.id]: info,
                            });
                          } else {
                            const objFee = { ...dataFeeCallApi[key], [data.id]: info };
                            handleAddData(key, objFee);
                          }
                        }
                      }}
                    />{' '}
                    {classFeeInfo?.bookKitFee?.percentageChange && (
                      <img
                        src={
                          classFeeInfo?.bookKitFee?.percentageChange &&
                          classFeeInfo.bookKitFee.percentageChange <= 0
                            ? analarrow_down
                            : analarrow
                        }
                        style={styles.img}
                        alt=""
                        className={clsx(
                          classFeeInfo?.bookKitFee?.percentageChange &&
                            classFeeInfo.bookKitFee.percentageChange <= 0
                            ? 'rotate-180'
                            : '',
                        )}
                      />
                    )}
                    <span
                      style={styles.span}
                      className={clsx(
                        classFeeInfo?.bookKitFee?.percentageChange &&
                          classFeeInfo.bookKitFee.percentageChange <= 0
                          ? 'text-[#ff4343]'
                          : 'text-[#2ED775]',
                      )}
                    >
                      {!!classFeeInfo?.bookKitFee?.percentageChange
                        ? '' + classFeeInfo.bookKitFee.percentageChange.toFixed(2) + '%'
                        : '--'}
                    </span>
                  </p>
                )}
              </div>
              <div className="flex flex-col w-40 mr-6">
                <div className="flex items-center">
                  <p style={styles.p}>Application Fee</p>
                  <ToggleButton
                    value={application}
                    onChange={() => {
                      if (
                        applicationFeeInfo.isActive &&
                        applicationFeeInfo.type !== 'Different'
                      ) {
                        return;
                      }
                      setApplication(!application);
                      let feeDistribution = {
                        ...feeInfo.feeDistribution,
                        admissionFee: admission ? numAdms : null,
                        bookFee: book ? numBook : null,
                        applicationFee: !application ? +numApplications : null,
                      };
                      const info = {
                        ...feeInfo,
                        applicationFee: +numApplications,
                        feeType: active,
                        feeDistribution: feeDistribution,
                      };
                      setFeeInfo(info);
                      if (title !== 'Tution Fee' && title !== 'Residential Fee') {
                        handleAddData(feebookField[title], {
                          info,
                        });
                      } else {
                        const key = feebookField[title];
                        if (!dataFeeCallApi?.[key]) {
                          handleAddData(key, {
                            [data.id]: info,
                          });
                        } else {
                          const objFee = { ...dataFeeCallApi[key], [data.id]: info };
                          handleAddData(key, objFee);
                        }
                      }
                    }}
                    inputSize="smalling"
                    customStyle={styles.img}
                  />
                </div>
                {application && (
                  <p
                    className={clsx(
                      'flex items-center',
                      application ? ' card-row_active' : ' card-row',
                    )}
                  >
                    ₹{' '}
                    <input
                      value={numApplications}
                      type="number"
                      className="ml-1 border-none outline-none"
                      style={{ maxWidth: '3.5rem' }}
                      onChange={(e) => {
                        setNumApplications(+e.target.value);
                        let feeDistribution = {
                          ...feeInfo.feeDistribution,
                          admissionFee: admission ? numAdms : null,
                          bookFee: book ? numBook : null,
                          applicationFee: +e.target.value,
                        };
                        const info = {
                          ...feeInfo,
                          applicationFee: +e.target.value,
                          feeType: active,
                          feeDistribution: feeDistribution,
                        };
                        setFeeInfo(info);
                        if (title !== 'Tution Fee' && title !== 'Residential Fee') {
                          handleAddData(feebookField[title], {
                            info,
                          });
                        } else {
                          const key = feebookField[title];
                          if (!dataFeeCallApi?.[key]) {
                            handleAddData(key, {
                              [data.id]: info,
                            });
                          } else {
                            const objFee = { ...dataFeeCallApi[key], [data.id]: info };
                            handleAddData(key, objFee);
                          }
                        }
                      }}
                    />{' '}
                    {classFeeInfo?.applicationFee?.percentageChange && (
                      <img
                        src={
                          classFeeInfo?.applicationFee?.percentageChange &&
                          classFeeInfo.applicationFee.percentageChange <= 0
                            ? analarrow_down
                            : analarrow
                        }
                        style={styles.img}
                        alt=""
                        className={clsx(
                          classFeeInfo?.applicationFee?.percentageChange &&
                            classFeeInfo.applicationFee.percentageChange <= 0
                            ? 'rotate-180'
                            : '',
                        )}
                      />
                    )}
                    <span
                      style={styles.span}
                      className={clsx(
                        classFeeInfo?.applicationFee?.percentageChange &&
                          classFeeInfo.applicationFee.percentageChange <= 0
                          ? 'text-[#ff4343]'
                          : 'text-[#2ED775]',
                      )}
                    >
                      {!!classFeeInfo?.applicationFee?.percentageChange
                        ? '' +
                          classFeeInfo.applicationFee.percentageChange.toFixed(2) +
                          '%'
                        : '--'}
                    </span>
                  </p>
                )}
              </div>
            </div>
            {(active === 'Custom' || active === 'Term Dates') &&
              termsArray.map((item, key) => (
                <FeeTermsCard
                  handleSetTutionFeeCallApi={handleSetTutionFeeCallApi}
                  setTermsArray={setTermsArray}
                  termsArray={termsArray}
                  item={item}
                  key={key}
                  index={key}
                  maxValue={termsArray.length}
                />
              ))}
          </div>
        )}
      </div>
    </React.Fragment>
  );
});

export default Card;

Card.displayName = 'Card';
