import axios from 'axios';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Autocomplete, GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { my_location } from '@static/image';
import './style.css';

const GgMap = memo((props) => {
  const { info, libraries, customStyle, handleChange } = props;
  const refAutocomplete = useRef();
  const refInput = useRef();
  const [prevCenter, setPrevCenter] = useState({});
  const lat = info?.coordinates?.lat;
  const lng = info?.coordinates?.lng;

  const position = {
    lat: lat || 10.99835602,
    lng: lng || 77.01502627,
  };

  const center = {
    lat: prevCenter?.lat || position.lat,
    lng: prevCenter?.lng || position.lng,
  };

  const containerStyle = useMemo(
    () => ({
      width: 'calc(100% - 5rem)',
      height: '40vh',
      marginLeft: '2.5rem',
      marginRight: '2.5rem',
      borderRadius: '10px',
      ...customStyle,
    }),
    [customStyle],
  );

  useEffect(() => {
    if (refInput.current) refInput.current.value = info?.fullAddress || '';
  }, [info?.fullAddress]);

  const getInfoAddress = useCallback((data) => {
    if (!data) return {};
    let country, postalCode, state, division, district, subdistrict, block;
    const address = [];
    const typePolitical = 'political';
    for (const item of data) {
      const { types, long_name } = item;
      for (const type of types) {
        if (type !== typePolitical)
          switch (type) {
            case 'country':
              country = long_name;
              break;
            case 'postal_code':
              postalCode = long_name;
              break;
            case 'administrative_area_level_1':
              state = long_name;
              break;
            case 'administrative_area_level_2':
              division = long_name;
              break;
            case 'administrative_area_level_3':
              district = long_name;
              break;
            case 'administrative_area_level_4':
              subdistrict = long_name;
              break;
            case 'administrative_area_level_5':
              block = long_name;
              break;
            default:
              address.push(long_name);
              break;
          }
      }
    }
    return {
      country,
      postalCode,
      state,
      division,
      district,
      subdistrict,
      block,
      address,
    };
  }, []);

  const setInfoAddress = useCallback(
    ({ place, lat, lng }) => {
      const { formatted_address, address_components, place_id } = place;
      const {
        country,
        postalCode,
        state,
        division,
        district,
        subdistrict,
        block,
        address,
      } = getInfoAddress(address_components);

      handleChange({
        objValue: {
          coordinates: { lat, lng },
          fullAddress: formatted_address,
          placeId: place_id,
          country,
          postalCode,
          state,
          division,
          district,
          subdistrict,
          block,
          address: address ? address.join(', ') : '',
        },
      });
    },
    [getInfoAddress, handleChange],
  );

  const getPlace = useCallback(async ({ lat, lng }) => {
    const res = await axios({
      method: 'get',
      url: 'https://maps.googleapis.com/maps/api/geocode/json',
      params: {
        latlng: `${lat}, ${lng}`,
        key: process.env.REACT_APP_MAPS_API_KEY,
      },
    });
    if (res.data.results.length !== 0) return res.data.results[0];
  }, []);

  const handleClickMap = useCallback(
    async (e) => {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      const place = await getPlace({ lat, lng });
      setInfoAddress({ place, lat, lng });
      refInput.current.value = place.formatted_address;
    },
    [getPlace, setInfoAddress],
  );

  const handleClickMyLocation = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { coords } = position;
        const lat = coords.latitude;
        const lng = coords.longitude;
        const place = await getPlace({ lat, lng });
        refInput.current.value = place.formatted_address;
        setInfoAddress({ place, lat, lng });
        setPrevCenter({ lat, lng });
      },
      (error) => {
      },
    );
  }, [getPlace, setInfoAddress]);

  const handleLoad = useCallback((place) => {
    refAutocomplete.current = place;
  }, []);

  const handleSelectedPlace = useCallback(() => {
    if (refAutocomplete.current) {
      const place = refAutocomplete.current.getPlace();
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setInfoAddress({ place, lat, lng });
      setPrevCenter({ lat, lng });
    } else console.log('Autocomplete is not loaded yet!');
  }, [setInfoAddress]);

  useEffect(() => {
    const handle = (e) => {
      const ggMapRender = document.getElementById('gg-map-render');
      const autocomplete = document.getElementsByClassName('autocomplete')[0];
      const autocompleteInput = document.getElementById('autocomplete-input');
      const myLocation = document.getElementById('my-location');

      let target = e.target;
      let pacContainerElements = document.getElementsByClassName('pac-container');
      if (pacContainerElements.length > 0) {
        let pacContainer = document.getElementsByClassName('pac-container')[0];
        pacContainer.classList.toggle('fullscreen-pac-container');
        autocompleteInput.classList.toggle('fullscreen-autocomplete');
        myLocation.classList.toggle('fullscreen-my-location');

        if (pacContainer.parentElement === target) {
          // Exiting fullscreen
          ggMapRender.appendChild(myLocation);
          ggMapRender.appendChild(autocomplete);
          document.getElementsByTagName('body')[0].appendChild(pacContainer);
        } else {
          //Entering fullscreen
          target.appendChild(myLocation);
          target.appendChild(autocomplete);
          target.appendChild(pacContainer);
        }
      } else {
        console.log('FULL SCREEN change - no pacContainer found');
      }
    };
    window.addEventListener('fullscreenchange', handle);

    return () => {
      window.removeEventListener('fullscreenchange', handle);
    };
  }, []);

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_MAPS_API_KEY}
      libraries={libraries}
      language="en"
    >
      <GoogleMap
        id="gg-map-render"
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onClick={handleClickMap}
        fullscreenControl={false}
      // onLoad={onLoad}
      // onUnmount={onUnmount}
      >
        <Autocomplete
          className="autocomplete"
          onLoad={handleLoad}
          onPlaceChanged={handleSelectedPlace}
        >
          <input
            id="autocomplete-input"
            ref={refInput}
            type="text"
            placeholder="Enter Address"
            className="absolute outline-0 border border-solid autocomplete-styles"
            style={{ borderRadius: `3px` }}
          />
        </Autocomplete>
        <img
          id="my-location"
          src={my_location}
          alt="location"
          onClick={handleClickMyLocation}
        />
        <Marker position={position} />
      </GoogleMap>
    </LoadScript>
  );
});
GgMap.displayName = 'GgMap';
export default GgMap;
