import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import brokenArrow from '../../../../static/image/brokenArrow.svg'
import { chartsDataGiiki, giikiSeries } from '../../../../entities/charts'
import './data-giiki-charts.scss'

export default function DataGiikiCharts({ burger }) {
  const options = { value: '2021', label: '43% than last year' }
  return (
    <section className={clsx('data-giiki-charts grid-container__item--3', burger ? 'data-giiki-charts_open' : 'data-giiki-charts_closed')}>
      <div className='pl-7'>
        <h1 className='font-semibold text-xl'>Total Installed</h1>
        <div className='relative right-2.5'>
          <p><img src={brokenArrow} alt='' />
            {options.label > 0 ? `-${options.label}` : `+${options.label}`}</p>
        </div>
      </div>
      <div id='chart' className={clsx(burger ? 'ml-3' : 'ml-9')}>
        <ReactApexChart
          options={chartsDataGiiki && chartsDataGiiki.options}
          series={giikiSeries}
          // chart={chartsDataGiiki && chartsDataGiiki.chart}
          type='line'
          height={343}
          width={'100%'}
        />
      </div>
    </section>
  )
}

DataGiikiCharts.propTypes = {
  /**  @param Type An object.
   * @param Object Keys for object - date: array of strings, series: object
   * @param series Keys for object - series[date]: array of objects
   * @param date Keys for objects - name: string, type: string, data: array of numbers, color: string
   * @param Example {
  date: ['2021','2020','2019'],
  series: series2021: [
    {
      name: 'Enquiries',
      type: 'line',
      data: [80, 0, 47, 0, 38, 0, 50, 0, 98, 0, 73, 0],
      color: '#008060'
    },
    {
      name: 'Conversion',
      type: 'line',
      data: [25, 30, 40, 40, 50, 45, 45, 50, 50, 55, 55, 50],
      color: '#FFC107'
    },
    {
      name: 'Enrolment',
      type: 'line',
      data: [35, 40, 50, 53, 63, 65, 55, 57, 60, 63, 58, 60],
      color: '#2E72D2'
    }
  ],
  series2020: [
    {
      name: 'Enquiries',
      type: 'column',
      data: [60, 0, 47, 0, 28, 0, 70, 0, 58, 0, 63, 0],
      color: '#008060'
    },
    {
      name: 'Conversion',
      type: 'area',
      data: [15, 20, 30, 45, 45, 60, 60, 50, 50, 55, 60, 60],
      color: '#FFC107'
    },
    {
      name: 'Enrolment',
      type: 'line',
      data: [45, 42, 53, 54, 60, 65, 77, 73, 62, 64, 68, 64],
      color: '#2E72D2'
    }
  ],
  series2019: [
    {
      name: 'Enquiries',
      type: 'column',
      data: [50, 0, 47, 0, 48, 0, 54, 0, 28, 0, 73, 0],
      color: '#008060'
    },
    {
      name: 'Conversion',
      type: 'area',
      data: [32, 43, 52, 52, 62, 57, 57, 62, 62, 67, 67, 62],
      color: '#FFC107'
    },
    {
      name: 'Enrolment',
      type: 'line',
      data: [45, 50, 60, 63, 73, 75, 65, 67, 70, 73, 70, 70],
      color: '#2E72D2'
    }
  ]
}
   **/
  data: PropTypes.object
}

/*
type?: "line"
    | "area"
    | "bar"
    | "histogram"
    | "pie"
    | "donut"
    | "radialBar"
    | "scatter"
    | "bubble"
    | "heatmap"
    | "treemap"
    | "boxPlot"
    | "candlestick"
    | "radar"
    | "polarArea"
    | "rangeBar",
    series?: ApexOptions['series'],
    width?: string | number,
    height?: string | number,
    options?: ApexOptions,
    [key: string]: any,
*/