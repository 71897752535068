import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import ContentGroup from './content-group/content-group';

const ApplicationInformation = memo((props) => {
  const { listField, info, handleChange } = props;
  const [imgPreview, setImgPreview] = useState(false);
  const name = `${info?.firstName} ${info?.lastName}`;

  const changeImageHandler = (e) => {
    let selected = e.target.files;
    if (selected && selected[0]) {
      handleChange({ name: 'studentInfo', key: 'photoUrl', value: selected[0] });
      let reader = new FileReader();
      reader.onload = (e) => {
        setImgPreview(e.target.result);
      };
      reader.readAsDataURL(selected[0]);
    }
  };
  return (
    <div className="information-field_application px-0">
      <div className="h-24 flex items-center bg-gray-100">
        <div className="ml-10">
          <label htmlFor="image-upload" className="cursor-pointer">
            {imgPreview || info?.avatar ? (
              <img
                className="add-enquiry-upload_image"
                src={imgPreview || info.avatar || ''}
                alt="uploaded"
              />
            ) : (
              <div
                className="flex items-center justify-center add-enquiry-upload_image text-main-blue text-xl"
                style={{ background: '#E5EFFD' }}
              >
                +
              </div>
            )}
          </label>
          <input
            type="file"
            id="image-upload"
            name="image-upload"
            className="hidden"
            accept="image/*"
            onChange={changeImageHandler}
          />
        </div>
        <p className="text-2xl font-bold text-main-black ml-5">{name}</p>
      </div>
      <div className="grid grid-cols-2 1620px:grid-cols-3 mt-10 px-10 h-fit gap-y-4 gap-x-8 pb-8">
        {listField.map((item, index) => {
          return (
            <ContentGroup
              key={`student${index}`}
              data={item}
              info={info?.[item.name]}
              variation="admission"
              handleChange={(e) => {
                handleChange({ name: 'studentInfo', key: item.name, value: e });
              }}
            />
          );
        })}
      </div>
    </div>
  );
});
ApplicationInformation.displayName = 'ApplicationInformation';
export default ApplicationInformation;

ApplicationInformation.propTypes = {
  /**  @param Type An object.
   * @param Object Keys for objects - name: string, image: string, firstName: '', lastName: string, middleName: string, date: string, grade: string, gender: string, enrollment: string, campus: string
   * @param Example {
   * name: 'Dhruv Malpuri',
   * image: '',
   * firstName: '',
   * middleName: '',
   * lastName: '',
   * date: '',
   * grade: '',
   * gender: '',
   * enrollment: '',
   * campus: ''
   * }
   **/
  data: PropTypes.object,
};
