import React, { memo } from 'react';
import clsx from 'clsx';

import CustomFeeCard from './CustomFeeCard';

const CustomFee = memo((props) => {
  const {
    termsLength = 1,
    title,
    listClasses = [],
    customFees,
    burger,
    refresh,
    handleAddData = () => {},
    dataFeeCallApi,
    setDataFeeCallApi = () => {},
    razorpayBankAccount,
    listBankAccount = [],
    listAcademicYearTerm = [],
  } = props;

  const types = [
    'Monthly',
    // 'Quaterly',
    // 'Trimester',
    // 'Half Yearly',
    'Term Dates',
    'Custom',
  ];

  return (
    <div className={clsx('w-full')}>
      <p className="text-lg font-bold mt-8 mb-4">{title}</p>
      <div className={clsx('w-full')}>
        <CustomFeeCard
          oldTerms={customFees.terms || []}
          termsLength={termsLength}
          listClasses={listClasses}
          types={types}
          customFees={customFees}
          title={title}
          refresh={refresh}
          dataFeeCallApi={dataFeeCallApi}
          setDataFeeCallApi={setDataFeeCallApi}
          razorpayBankAccount={razorpayBankAccount}
          listBankAccount={listBankAccount}
          listAcademicYearTerm={listAcademicYearTerm}
        />
      </div>
    </div>
  );
});
CustomFee.displayName = 'CustomFee';
export default CustomFee;
