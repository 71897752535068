import React from 'react';
import clsx from 'clsx';

const Add = (props) => {
  const { disable, onlyView, indexPer, handleChange, roleName } = props;

  return (
    <svg
      width="24"
      className={clsx(onlyView ? 'cursor-not-allowed' : 'cursor-pointer')}
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={(e) => {
        if (onlyView) return;
        handleChange(roleName, indexPer, 'create', disable);
      }}
    >
      <circle cx="12" cy="12" r="12" fill={!disable ? '#C4CDD5' : '#404EED'} />
      <rect width="12" height="12" transform="translate(6 6)" fill={!disable ? '#C4CDD5' : '#404EED'} />
      <path
        d="M15.5 11.5H12.5V8.5C12.5 8.22386 12.2761 8 12 8C11.7239 8 11.5 8.22386 11.5 8.5V11.5H8.5C8.22386 11.5 8 11.7239 8 12C8 12.2761 8.22386 12.5 8.5 12.5H11.5V15.5C11.5 15.7761 11.7239 16 12 16C12.2761 16 12.5 15.7761 12.5 15.5V12.5H15.5C15.7761 12.5 16 12.2761 16 12C16 11.7239 15.7761 11.5 15.5 11.5Z"
        fill="white"
      />
    </svg>
  );
};

export default Add;
