import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import { mainavatar } from '../../../../../static/image';
import { HeaderField, TextFieldCard } from '../subcomponents';
import clsx from 'clsx';
import DefaultAvatar from '@components/default-avatar';

export default function ParentInformation(props) {
  const { burger, data, listField } = props;
  const [active, setActive] = useState();
  const [parentInfo, setParentInfo] = useState({});

  const parentTypes = useMemo(() => {
    const types = [];
    data?.forEach((parent, index) => {
      types.push(`Parent / Guardian ${index + 1}`);
    });

    setActive(types && types[0]);
    setParentInfo(data[0]);
    return types;
  }, [data]);

  const changeActive = useCallback(
    (type, index) => {
      setActive(type);
      setParentInfo(data[index]);
    },
    [data],
  );

  return (
    <div className='px-8'>
      <div className={clsx('information-field_parent h-fit pb-4', burger && '1599px:w-[56.25rem]')}>
        <div className="w-full flex items-center justify-between mb-2 pt-5 pl-10 pr-10">
          <p className="font-bold text-main-black text-base">Parent Information</p>
        </div>
        <HeaderField
          types={parentTypes}
          active={active}
          changeActive={changeActive}
          className="mb-5"
        // indexParentSelected={indexParentSelected}
        />

        <>
          <div className="flex items-center mb-5">
            {parentInfo?.photoURL ? (
              <img
                src={parentInfo.photoURL}
                className="w-12 h-12 rounded-full ml-10 mr-3"
                alt="mainavatar"
              />
            ) : (
              <DefaultAvatar title={parentInfo?.fullName || 'a'} className='w-12 h-12 rounded-full ml-10 mr-3' />
            )}

            <div>
              <p className="text-sm font-bold text-main-black mb-1">
                {parentInfo['fullName']}
              </p>
              <div className="flex">
                <div className="chip-field">
                  {parentInfo['relationship']?.label || parentInfo['relationship']}
                </div>
                <div className="chip-field">{parentInfo['phoneNumber']}</div>
                <div className="chip-field">{parentInfo['email']}</div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-4 pl-12 gap-x-4">
            {listField.map((field) => {
              const { label, name } = field;
              return <TextFieldCard title={label} body={parentInfo[name]} key={name} />;
            })}
          </div>
        </>
      </div>
    </div>
  );
}

ParentInformation.propTypes = {
  /**  @param Type An array of objects.
   * @param Object Keys for objects - title: string, body: string
   * @param Example [
  { title: 'First Name', body: 'Thomas' },
  { title: 'Middle Name ', body: 'Mathew' },
  { title: 'Last Name', body: 'Fulloway ' },
  { title: 'Preferred Name', body: 'Thoma' },
  { title: 'Relation with applicant', body: 'Father' },
  { title: 'Date of Birth', body: '02 June, 1985' },
  { title: 'Gender', body: 'Male' },
  { title: 'Email', body: 'radnik.osw@gmail.com' },
  { title: 'Mobile Number', body: '+91 3423 322121' }
]
   **/
  data: PropTypes.array,
};
