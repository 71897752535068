import React, { useState, useContext } from 'react';
import Zoom from 'react-reveal/Zoom';
import clsx from 'clsx';

import EditPanelContent from './edit-panel-content.jsx';
import { mainavatar } from '../../static/image';
import { ProdCard } from '../../stories';
import { rolesDataProgCard } from '../../entities/data';
import { callApi } from '../../helper/call-api.js';
import { ToastMessageContext } from '../../context/toast-context.jsx';

import './edit-create-role.scss';
import centralAdminApi from 'api/central-admin.js';

export default function EditCreateRole({ visible, setVisible, campus }) {
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const [role, setRole] = useState({
    roleId: '',
    roleName: 'Art Director',
    companyId: campus.companyId,
    permissionId: [],
  });

  const onClickCreateRole = (e, role) => {
    e.preventDefault();
    const data = {
      roleId: role.roleId,
      roleName: role.roleName,
      companyId: role.companyId,
      permissionId: role.permissionId,
    };
    setRole({
      roleId: '',
      roleName: 'Art Director',
      companyId: campus.companyId,
      permissionId: [],
    });
    centralAdminApi.addCustomRole(data)
      .then((response) => {
        setToastMessage({
          status: 'success',
          title: 'Create Role Successfully',
          message: response.data.message,
        });
        setIsShowToastMessage(true);
      })
      .catch((err) => {
        setToastMessage({
          status: 'error',
          title: 'Create Role Failed',
          message: err.message,
        });
        setIsShowToastMessage(true);
        console.log(err);
      });
  };

  return (
    <Zoom bottom duration={500} when={visible}>
      <div
        className={clsx(
          'edit-create-role',
          visible ? 'pointer-events-auto' : 'pointer-events-none hidden',
        )}
      >
        <button className="float-right" onClick={() => setVisible(false)}>
          <Close />
        </button>
        <div className="role-permission">
          <div className="flex items-center justify-between">
            <p>Role Name</p>
          </div>
          <p style={{ opacity: 0.5 }}>
            <input
              placeholder={role.roleName}
              onChange={(e) => {
                setRole({
                  ...role,
                  roleName: e.target.value,
                  roleId: role.companyId + '-' + e.target.value.toLowerCase(),
                });
              }}
            ></input>
          </p>
        </div>
        <div className="access-levels">
          <p style={{ opacity: 0.3 }}>Access Levels</p>
          <div className="grades-programs-row">
            <div className="grades-programs-row_card">
              {rolesDataProgCard.map((item, key) => (
                <div
                  className={clsx(
                    'grades-programs-row_card--prodcard',
                    role?.opinio === item.opinio ? 'bg-ligther-gray' : '',
                  )}
                  onClick={(e) => {
                    setRole({
                      ...role,
                      permissionId: permission[item.opinio - 1].permission,
                      opinio: item.opinio,
                    });
                  }}
                  key={key}
                >
                  <ProdCard
                    key={key}
                    access={true}
                    status={item.status}
                    // firstHeader={item.firstHeader}
                    // firstName={item.firstName}
                    // modal={item.modal}
                    //crumbs={item.crumbs}
                    opinio={item.opinio}
                    secondHeader={item.secondHeader}
                  //secondName={item.secondName}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <button
          className="btn-save"
          onClick={(e) => {
            onClickCreateRole(e, role);
            setVisible(false);
          }}
        >
          Save
        </button>
      </div>
    </Zoom>
  );
}

const Close = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 1.4L14.6 0L8 6.6L1.4 0L0 1.4L6.6 8L0 14.6L1.4 16L8 9.4L14.6 16L16 14.6L9.4 8L16 1.4Z"
        fill="#2F80ED"
      />
    </svg>
  );
};
const permission = [
  {
    opinio: 1,
    permission: ['1', '2', '3'],
  },
  {
    opinio: 2,
    permission: ['1', '2', '3'],
  },
  {
    opinio: 3,
    permission: ['1', '3'],
  },
  {
    opinio: 4,
    permission: [],
  },
];
