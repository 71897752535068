import React from 'react';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { urls } from 'entities/urls';

const ApprovalIcon = (props) => {
  const { customClass } = props;
  const history = useHistory();
  const handleOnClickApprovalIcon = useCallback(() => {
    history.push(urls.central.approval.base);
  }, []);

  return (
    <button className={customClass} onClick={handleOnClickApprovalIcon}>
      <svg
        // width={'1.25rem'}
        // height={'1.25rem'}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ cursor: 'pointer' }}
      >
        <path
          d="m15.62 12.68.05-5.993a1 1 0 0 0-1-1.008H7.352a1 1 0 0 0-1 1v8.81a1 1 0 0 0 1 1h4.756m-3.914-8.57h5.468M8.31 10.552h5.353M8.31 13.184h5.353"
          stroke="#202223"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.917 18.049A9.975 9.975 0 0 1 9.936 1.083c2.51-.26 4.609.044 6.62 1.569M3.916 18.049v-2.12m0 2.12H1.979M18.084 3.943a9.976 9.976 0 0 1 .465 13.6 9.954 9.954 0 0 1-6.485 3.366c-2.51.259-4.609.027-6.62-1.499m12.64-15.467v2.12m0-2.12h1.938"
          stroke="#202223"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="m13.548 15.032 1.496 1.568 3.454-3.416"
          stroke="#202223"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};

ApprovalIcon.propTypes = {
  handleOnClick: PropTypes.func,
};

ApprovalIcon.defaultProps = {
  handleOnClick: () => { },
};

export default ApprovalIcon;
