import React from 'react';
import clsx from 'clsx';

const AccountTypeHolder = ({
  image,
  title,
  subtitle,
  opinion,
  value,
  label,
  isChosen,
  handleChange,
}) => {
  return (
    <div
      style={{
        borderColor: isChosen ? 'blue' : 'rgb(231 234 238)',
        boxShadow: '0 0.5rem 1rem rgba(0,0,0,.2)',
      }}
      // className="min-h-[94px] w-full rounded border-2 border-light cursor-pointer p-8"
      className="w-[8rem] border-2 cursor-pointer p-2 m-2 rounded-lg"
      onClick={() => handleChange('loginTypeId', value)}
    >
      <div className="flex flex-col items-start relative bottom-1">
        <h5 className="font-bold text-xs leading-4 text-button-disable">Access Level</h5>
        <div className="flex">
          <p className="font-normal mt-1 mr-2 text-xs">{subtitle}</p>
          {opinion > 0 && opinion < 5 ? (
            <div className="flex">
              <div
                className={clsx(
                  'prog-card-new-row_opinion',
                  `prog-card-new-row_opinion_${opinion}`,
                )}
              />
              <div
                className={clsx(
                  'prog-card-new-row_opinion',
                  opinion > 1
                    ? `prog-card-new-row_opinion_${opinion}`
                    : `prog-card-new-row_opinion_none`,
                )}
              />
              <div
                className={clsx(
                  'prog-card-new-row_opinion',
                  opinion > 2
                    ? `prog-card-new-row_opinion_${opinion}`
                    : `prog-card-new-row_opinion_none`,
                )}
              />
              <div
                className={clsx(
                  'prog-card-new-row_opinion',
                  opinion > 3
                    ? `prog-card-new-row_opinion_${opinion}`
                    : `prog-card-new-row_opinion_none`,
                )}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AccountTypeHolder;
