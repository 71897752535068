import { memo } from 'react';
import {
  componentFieldName,
  componentSpecialName,
  componentCustomName,
} from '../../utils';
import {
  multiselect,
  photo,
  radioGroup,
  textArea,
  datePicker,
  checkbox,
  paragraph,
  dropdown,
  pageBreak,
} from './icon/index';

const Block = memo((props) => {
  const { block, onDragStart } = props;
  const { icon, label, name, type } = block;
  const isHalf = type === 'half';

  return (
    <div
      className="single-block"
      draggable
      onDragStart={onDragStart}
      component={name}
      style={{
        width: isHalf ? '46%' : '100%',
        justifyContent: 'center',
      }}
    >
      <img
        src={icon}
        draggable={false}
        className={`${label === componentFieldName.checkbox ? 'object-none w-4' : 'w-4'}`}
        alt="icon"
      />
      <p
        title={label}
        className="ml-4 w-[65%] text-ellipsis whitespace-nowrap overflow-hidden"
      >
        {label}
      </p>
    </div>
  );
});
Block.displayName = 'Block';

const listBlockField = [
  {
    category: 'Fields',
    blocks: [
      {
        name: componentFieldName.image,
        icon: photo,
        label: componentFieldName.image,
        type: 'half',
      },
      {
        name: componentFieldName.textArea,
        icon: textArea,
        label: componentFieldName.textArea,
        type: 'half',
      },
      {
        name: componentFieldName.paragraph,
        icon: paragraph,
        label: componentFieldName.paragraph,
        type: 'half',
      },
      {
        name: componentFieldName.multiselect,
        icon: multiselect,
        label: componentFieldName.multiselect,
        type: 'half',
      },
      {
        name: componentFieldName.checkbox,
        icon: checkbox,
        label: componentFieldName.checkbox,
        type: 'half',
      },
      {
        name: componentFieldName.dropdown,
        icon: dropdown,
        label: componentFieldName.dropdown,
        type: 'half',
      },
      {
        name: componentFieldName.radioGroup,
        icon: radioGroup,
        label: componentFieldName.radioGroup,
        type: 'half',
      },
      {
        name: componentFieldName.datePicker,
        icon: datePicker,
        label: componentFieldName.datePicker,
        type: 'half',
      },
      {
        name: componentFieldName.pageBreak,
        icon: pageBreak,
        label: componentFieldName.pageBreak,
        type: 'half',
      },
    ],
  },
];

const listBlockCustom = [
  {
    category: 'Custom',
    blocks: [
      {
        name: componentCustomName.identityIdType,
        icon: dropdown,
        label: componentCustomName.identityIdType,
        type: 'half',
      },
      {
        name: componentCustomName.avatar,
        icon: photo,
        label: componentCustomName.avatar,
        type: 'half',
      },
      {
        name: componentCustomName.language,
        icon: multiselect,
        label: componentCustomName.language,
        type: 'half',
      },
      {
        name: componentCustomName.nationality,
        icon: multiselect,
        label: componentCustomName.nationality,
        type: 'half',
      },
      {
        name: componentCustomName.gender,
        icon: radioGroup,
        label: componentCustomName.gender,
        type: 'half',
      },
      {
        name: componentCustomName.relationship,
        icon: dropdown,
        label: componentCustomName.relationship,
        type: 'half',
      },
      {
        name: componentCustomName.phoneNumber,
        icon: textArea,
        label: componentCustomName.phoneNumber,
        type: 'half',
      },
      {
        name: componentCustomName.email,
        icon: textArea,
        label: componentCustomName.email,
        type: 'half',
      },
      {
        name: componentCustomName.dateOfBirth,
        icon: datePicker,
        label: componentCustomName.dateOfBirth,
        type: 'half',
      },
    ],
  },
];

const listBlockSpecial = [
  {
    category: 'Special',
    blocks: [
      {
        name: componentSpecialName.parentGuardian,
        icon: dropdown,
        label: componentSpecialName.parentGuardian,
        type: 'half',
      },
      {
        name: componentSpecialName.anticipatedGradeOfEntry,
        icon: dropdown,
        label: componentSpecialName.anticipatedGradeOfEntry,
        type: 'half',
      },
      {
        name: componentSpecialName.enrollmentYear,
        icon: dropdown,
        label: componentSpecialName.enrollmentYear,
        type: 'half',
      },
      {
        name: componentSpecialName.campusName,
        icon: textArea,
        label: componentSpecialName.campusName,
        type: 'half',
      },
      {
        name: componentSpecialName.languageCard,
        icon: dropdown,
        label: componentSpecialName.languageCard,
        type: 'half',
      },
    ],
  },
];

export { listBlockField, listBlockCustom, listBlockSpecial, Block };
