import React from "react";
import Reports from "../giiki-admin-reports/reports";

const GiikiAdminReports = (props) => {
  const { burger } = props

  return (
    <Reports burger={burger} />
  );
};

GiikiAdminReports.propTypes = {};

export default GiikiAdminReports;
