import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { brokenArrow, brokenArrowDown, dynamics } from '@static/image'
import './data-widget.scss'

export default function DataWidget(props) {
  const { title, percents, count } = props;
  
  return (
    <div className={clsx('data-widget')}>
      <div className='data-widget-infp'>
        <p className='data-widget__title'>{title}</p>
        <p className='data-widget__percents'>
          <img src={percents < 0 ? brokenArrowDown : brokenArrow} alt='' className={clsx(percents < 0 ? 'bg-[#EB575744] rotate-90' : 'bg-[#00806044]')}/>
          <span className={clsx(percents < 0 ? 'text-[#EB5757]' : 'text-[#008060]')}>{percents < 0 ? `${percents}` : `+${percents}`}%</span>
        </p>
        <p className='data-widget__count'>{count}</p>
      </div>
      <div className='data-widget__img-wrapper'>
        <img src={dynamics} alt='' className='data-widget__img' />
      </div>
    </div>
  )
}

DataWidget.propTypes = {
  title: PropTypes.string,
  percents: PropTypes.number,
  count: PropTypes.number,
  burger: PropTypes.bool
}
