/* eslint-disable react/no-typos */
import { Modal } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button } from '@stories/index';
import { checkErrorMultipleApiFetch, checkErrorSingleApi } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { dataLinks } from 'entities/routes';
import { NavLinks } from '../Header/components/index';
import EditStagePopup from './edit-stage-popup';
import './stages.scss';
import clsx from 'clsx';
import { EnquiryStatus } from 'entities/data';
import admissionApi from 'api/admission';
import useStorage from 'store/storage';
import centralAdminApi from 'api/central-admin';

const styles = {
  btnBlue: {
    width: '6.563rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    backgroundColor: '#404eed',
    height: '2.563rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    left: '80%',
  },
  btnWhite: {
    width: '6.563rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#404eed',
    backgroundColor: 'white',
    height: '2.563rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    border: '1px solid #404eed',
    left: '80%',
  },
};

const Stages = React.forwardRef((props, ref) => {
  const { burger } = props;
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [cards, setCards] = useState([]);
  const [cardsChange, setCardChange] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [listForm, setListForm] = useState([])
  const [currentStage, setCurrentStage] = useState({})
  const campus = useStorage((state) => state.currentCampus);

  const fetch = useCallback(async () => {
    try {
      const campusId = campus?.isCentral ? undefined : campus?.id
      const apiGetForms = centralAdminApi.getListFormBuilder({ campusId, status: 'Active' })
      const apiGetStages = admissionApi.getListStage({ campusId })
      const res = await Promise.all([apiGetForms, apiGetStages])
      if (checkErrorMultipleApiFetch(res, setToastMessage, setIsShowToastMessage)) {
        const forms = res[0].data.data.objects.map((item) => {
          return {
            label: item.name,
            value: item.id,
            info: item,
          };
        });
        setListForm(forms)
        setCards(res[1].data.data)
      }
    } catch (error) {
      setToastMessage({
        status: 'error',
        title: 'Fetch Data',
        message: 'Fetch Data Failed',
      });
      setIsShowToastMessage(true);
      console.log('Fetch Data Error', error);
    }
  }, [campus])

  useEffect(() => {
    fetch()
  }, [campus]);

  const handleCancelStage = () => {
    admissionApi.getListStage({ campusId: campus?.isCentral ? undefined : campus?.id })
      .then((res) => {
        if (
          checkErrorSingleApi(
            res,
            setToastMessage,
            setIsShowToastMessage,
            'Reset Enquiry Stages',
          )
        ) {
          const { data } = res;
          setCards(data.data);
          setCardChange(false);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Reset Enquiry Stages Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Enquiry Stages Error', error);
      });
  };

  const handleSaveChangeStage = () => {
    const currentRowHaveNull = cards.findIndex((raw, index) => {
      if (raw.left) {
        if (!raw.left.text) return index;
      }
      if (raw.middle) {
        if (!raw.middle.text) return index;
      }
      if (raw.right) {
        if (!raw.right.text) return index;
      }
    });
    if (currentRowHaveNull !== -1) {
      const currentRow = currentRowHaveNull + 1;
      setToastMessage({
        status: 'error',
        title: 'Invalid Data',
        message: `This title enquiry field in row ${currentRow} left is null`,
      });
      setIsShowToastMessage(true);
      setCardChange(false);
      return;
    }
    // admissionApi.updateStages({ campusId: campus?.isCentral ? undefined : campus?.id }, cards)
    admissionApi.updateStages({ campusId: campus?.id }, cards)
      .then((res) => {
        if (
          checkErrorSingleApi(
            res,
            setToastMessage,
            setIsShowToastMessage,
            'Update Enquiry Stages',
          )
        ) {
          const { data } = res;
          setCards(data.data);
          setCardChange(false);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Update Enquiry Stages Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Enquiry Stages Error', error);
      });
  };
  const handleSaveEdit = (indexOfStage, nameOfStage, percentOfStage, formId) => {
    setCards((prev) => {
      const data = [...prev];
      data[indexOfStage].middle.text = nameOfStage;
      data[indexOfStage].middle.percent = percentOfStage;
      data[indexOfStage].middle.formId = formId;
      return data;
    });
    setVisibleModal((prev) => !prev);
    setCardChange(true);
  };


  return (
    <div className={clsx('stages px-24 transition-all-300', burger ? '1400px:pl-80' : '1400px:pl-40')}>
      <NavLinks urls={dataLinks.admissionsStagesLinks} />
      <Modal open={visibleModal} onClose={setVisibleModal}>
        <div open={visibleModal} onClose={setVisibleModal}>
          <EditStagePopup
            ref={ref}
            open={visibleModal}
            isOpen={setVisibleModal}
            currentStage={currentStage}
            listForm={listForm}
            handleSaveEdit={handleSaveEdit}
          />
        </div>
      </Modal>
      <div className="flex justify-between">
        <div>
          <h1 className="text-base font-normal mt-7 mb-1.5">Pre Enquiry</h1>
          <p
            className="text-sm font-normal"
            style={{ color: '#81878C', width: '16.438rem' }}
          >
            This is the stage where you got data or Refferal but havent contacted
          </p>
          <h1 className="text-base font-normal mt-12 mb-1.5">Enquiry</h1>
          <p
            className="text-sm font-normal"
            style={{ color: '#81878C', width: '16.438rem' }}
          >
            Once the cantact is made my the admissions team
          </p>
        </div>
        <div className="flex flex-col">
          {cards.map((item, key) => (
            <div className="flex" key={key}>
              <Cards
                onClick={(e) => {
                  e.stopPropagation();
                  const { middle } = item
                  const { text, percent, textDefault, formId } = middle
                  setCurrentStage(prev => ({
                    ...prev,
                    index: key,
                    name: text,
                    conversionProbability: percent,
                    textDefault: textDefault,
                    formId: formId ? formId : ''
                  }))
                  setVisibleModal((prev) => !prev);
                }}
                item={item}
                setCards={setCards}
                num={key}
                cards={cards}
                cardsChange={cardsChange}
                setCardChange={setCardChange}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="flex items-center w-full relative left-0 1340px:left-9 justify-between">
        <div className="relative bottom-8">
          <h1 className="text-base font-normal mb-1.5">Closed</h1>
          <p
            className="text-sm font-normal"
            style={{ color: '#81878C', width: '16.438rem' }}
          >
            These stages represent results of Admission Journey
          </p>
        </div>
        <div className="flex justify-end w-full items-center">
          {closedCardsData.map((item, key) => (
            <FooterCard key={key} title={item.title} body={item.body} type={item.type} />
          ))}
        </div>
      </div>
      <div className="flex items-center w-full relative left-0 1340px:left-9 justify-between">
        <div className="relative bottom-8">
          <h1 className="text-base font-normal">Other</h1>
          <p
            className="text-sm font-normal"
            style={{ color: '#81878C', width: '16.438rem' }}
          >
            Inconclusive
          </p>
        </div>
        <div className="flex justify-end w-full items-center">
          {otherCardsData.map((item, key) => (
            <FooterCard key={key} title={item.title} body={item.body} type={item.type} />
          ))}
        </div>
      </div>
      <div
        className="bottom-1_footer"
        style={{ display: 'flex', alignItems: 'center', columnGap: '1.25rem' }}
      >
        <Button
          text="Cancel"
          buttonDisabled={!cardsChange}
          onClick={handleCancelStage}
          customStyle={styles.btnWhite}
        />
        <Button
          text="Save"
          buttonDisabled={!cardsChange}
          onClick={handleSaveChangeStage}
          customStyle={styles.btnBlue}
        />
      </div>
    </div>
  );
});
export const FooterCard = ({ title, body, type }) => {
  return title !== null ? (
    <div className="stages--dom-card justify-center items-center pl-0 pt-0 pb-0 mr-1 ml-1 1340px:mr-3.5 1340px:ml-3.5">
      <p className="mb-2">{title}</p>
      <p style={{ color: '#81878C' }}>{body}</p>
      <div
        className="absolute bottom-0 left-0 w-full rounded-br-lg rounded-bl-lg"
        style={{ height: '0.438rem', background: type }}
      />
    </div>
  ) : (
    <div
      className="mr-1 ml-1 1340px:mr-3.5 1340px:ml-3.5"
      style={{ width: ` 10.938rem` }}
    />
  );
};

FooterCard.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  type: PropTypes.string,
};

export const Cards = ({
  item,
  num,
  setCards,
  cards,
  cardsChange,
  setCardChange,
  onClick,
}) => {
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const addLeftCard = () => {
    cards[num].left === null
      ? (cards[num].left = { align: 'left', text: 'Left card', percent: 0 })
      : (cards[num].left = null);
    if (!cardsChange) {
      setCardChange(true);
    }
    setCards([...cards]);
  };

  const addMiddleCard = () => {
    cards.splice(num + 1, 0, {
      left: null,
      middle: { align: 'middle', text: 'Middle card', percent: 0 },
      right: null,
    });
    if (!cardsChange) {
      setCardChange(true);
    }
    setCards([...cards]);
  };

  const addMiddleUpCard = () => {
    cards.splice(num, 0, {
      left: null,
      middle: { align: 'middle', text: 'Middle card', percent: 0 },
      right: null,
    });

    if (!cardsChange) {
      setCardChange(true);
    }
    setCards([...cards]);
  };

  const removeDownCards = () => {
    if ([EnquiryStatus.EXAM, EnquiryStatus.PRINCIPAL_MEETING, EnquiryStatus.APPLICATION].includes(cards[num + 1]?.middle?.textDefault)) {
      setToastMessage({
        status: 'warning',
        title: 'Required Stage',
        message: "Can't delete this stage",
      });
      setIsShowToastMessage(true);
      return
    }

    cards.splice(num + 1, 1);
    if (!cardsChange) {
      setCardChange(true);
    }
    setCards([...cards]);
  };

  const removeUpCards = () => {
    if ([EnquiryStatus.EXAM, EnquiryStatus.APPLICATION].includes(cards[num - 1]?.middle?.textDefault)) {
      setToastMessage({
        status: 'warning',
        title: 'Required Stage',
        message: "Can't delete this stage",
      });
      setIsShowToastMessage(true);
      return
    }

    cards.splice(num - 1, 1);
    if (!cardsChange) {
      setCardChange(true);
    }
    setCards([...cards]);
  };

  const addRightCard = () => {
    cards[num].right === null
      ? (cards[num].right = { align: 'right', text: 'Right card', percent: 0 })
      : (cards[num].right = null);

    if (!cardsChange) {
      setCardChange(true);
    }
    setCards([...cards]);
  };

  const updateLeftCards = ({ text, percent }) => {
    cards[num].left = {
      align: 'left',
      text: text !== undefined ? text : cards[num].left.text,
      percent: percent !== undefined ? percent : cards[num].left.percent,
    };

    if (!cardsChange) {
      setCardChange(true);
    }
    setCards([...cards]);
  };

  const updateMiddleCards = ({ text, percent }) => {
    cards[num].middle = {
      align: 'middle',
      text: text !== undefined ? text : cards[num].middle.text,
      percent: percent !== undefined ? percent : cards[num].middle.percent,
    };

    if (!cardsChange) {
      setCardChange(true);
    }
    setCards([...cards]);
  };

  const updateRightCards = ({ text, percent }) => {
    cards[num].right = {
      align: 'right',
      text: text !== undefined ? text : cards[num].right.text,
      percent: percent !== undefined ? percent : cards[num].right.percent,
    };

    if (!cardsChange) {
      setCardChange(true);
    }
    setCards([...cards]);
  };

  const defaultLeftCards = () => {
    cards[num].left = {
      align: 'left',
      text: cards[num].left.text,
      percent: cards[num].left.percent ? +cards[num].left.percent : +0,
    };
    if (!cards[num].left.text) {
      const currentRow = num + 1;
      setToastMessage({
        status: 'error',
        title: 'Invalid Data',
        message: `This title enquiry field in row ${currentRow} left is null`,
      });
      setIsShowToastMessage(true);
    }

    setCards([...cards]);
  };

  const defaultMiddleCards = () => {
    cards[num].middle = {
      align: 'middle',
      text: cards[num].middle.text,
      percent: cards[num].middle.percent ? +cards[num].middle.percent : +0,
    };
    if (!cards[num].middle.text) {
      const currentRow = num + 1;
      setToastMessage({
        status: 'error',
        title: 'Invalid Data',
        message: `This title enquiry field in row ${currentRow} middle is null`,
      });
      setIsShowToastMessage(true);
    }
    setCards([...cards]);
  };

  const defaultRightCards = () => {
    cards[num].right = {
      align: 'right',
      text: cards[num].right.text,
      percent: +cards[num].right.percent ? +cards[num].right.percent : +0,
    };
    if (!cards[num].right.text) {
      const currentRow = num + 1;
      setToastMessage({
        status: 'error',
        title: 'Invalid Data',
        message: `This title enquiry field in row ${currentRow} right is null`,
      });
      setIsShowToastMessage(true);
    }
    setCards([...cards]);
  };

  const getWidth = useCallback((text) => {
    const long = text.toString().length
    return `${(long + 0.75 * long) * 7}px`
  }, [])

  return (
    <React.Fragment>
      {(item.left && (
        <div className="relative">
          <div className="stages--dom-card pl-0 text-center">
            <input
              value={item.left.text}
              className='text-center'
              onChange={(e) => {
                updateLeftCards({ text: e.target.value });
              }}
              onBlur={() => {
                defaultLeftCards();
              }}
            />
            <p style={{ color: '#81878C' }}>
              <input
                value={item.left.percent}
                style={{
                  textAlign: 'right',
                  width: getWidth(item.left.percent)
                }}
                onChange={(e) => {
                  updateLeftCards({ percent: e.target.value });
                  e.target.style.width = getWidth(e.target.value)
                }}
                onBlur={(e) => {
                  defaultLeftCards();
                  e.target.style.width = getWidth(e.target.value)
                }}
              />
              % win likelihood
            </p>
          </div>
          <div
            className="bg-main-black h-10 absolute"
            style={{ width: 1, top: '6.25rem', left: '7.5rem' }}
          />
          <div
            className="bg-main-black absolute"
            style={{ height: 1, width: '15.5rem', top: '8.75rem', left: '7.5rem' }}
          />
        </div>
      )) || <div className="mr-9 ml-9" style={{ width: '10.938rem' }} />}
      {item.middle !== null && (
        <div className="relative">
          <div onClick={onClick} className="stages--dom-card pl-0 text-center">
            {/* <input
              value={item.middle.text}
              style={{ marginLeft: '5px' }}
              onChange={(e) => {
                updateMiddleCards({ text: e.target.value });
              }}
              onBlur={() => {
                defaultMiddleCards();
              }}
            /> */}
            <div className="cursor-pointer">
              {item.middle.text}
            </div>
            <p style={{ color: '#81878C' }}>
              <input
                value={item.middle.percent}
                style={{
                  textAlign: 'right',
                  width: getWidth(item.middle.percent)
                }}
                onChange={(e) => {
                  updateMiddleCards({ percent: e.target.value });
                  e.target.style.width = getWidth(e.target.value)
                }}
                onBlur={(e) => {
                  defaultMiddleCards();
                  e.target.style.width = getWidth(e.target.value)
                }}
              />
              % win likelihood
            </p>
            <div className="group absolute w-10 h-20 top-0 left-0">
              <button
                className="button-card button-card_left"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  addLeftCard();
                }}
              >
                {cards[num].left === null ? '+' : '-'}
              </button>
            </div>
            <div
              className="group absolute h-8 top-0 left-10"
              style={{ width: '5.938rem' }}
            >
              <button
                className="button-card button-card_top__add"
                style={num !== 0 ? {} : { left: '2.1rem' }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  addMiddleUpCard();
                }}
              >
                +
              </button>

              {num !== 0 && (
                <button
                  className="button-card button-card_top__remove"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    removeUpCards();
                  }}
                >
                  -
                </button>
              )}
            </div>
            <div
              className="group absolute  h-8 bottom-0 left-10"
              style={{ width: '5.938rem' }}
            >
              <button
                className="button-card button-card_middle__add"
                style={num + 1 !== cards.length ? {} : { left: '2.1rem' }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  addMiddleCard();
                }}
              >
                +
              </button>
              {num + 1 !== cards.length && (
                <button
                  className="button-card button-card_middle__remove"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    removeDownCards();
                  }}
                >
                  -
                </button>
              )}
            </div>
            <div className="group absolute  w-10 h-20 top-0 right-0">
              <button
                className="button-card button-card_right"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  addRightCard();
                }}
              >
                {cards[num].right === null ? '+' : '-'}
              </button>
            </div>
          </div>
          {num + 1 !== cards.length && (
            <div
              className="bg-main-black absolute"
              style={{ width: 1, height: '4.25rem', top: '6.25rem', left: '7.5rem' }}
            />
          )}
        </div>
      )}
      {(item.right && (
        <div className="relative">
          <div className="stages--dom-card pl-0 text-center">
            <input
              value={item.right.text}
              className='text-center'
              onChange={(e) => {
                updateRightCards({ text: e.target.value });
              }}
              onBlur={() => {
                defaultRightCards();
              }}
            />
            <p style={{ color: '#81878C' }}>
              <input
                value={item.right.percent}
                style={{
                  textAlign: 'right',
                  width: getWidth(item.right.percent)
                }}
                onChange={(e) => {
                  updateRightCards({ percent: e.target.value });
                  e.target.style.width = getWidth(e.target.value)
                }}
                onBlur={(e) => {
                  defaultRightCards();
                  e.target.style.width = getWidth(e.target.value)
                }}
              />
              % win likelihood
            </p>
          </div>
          <div
            className="bg-main-black h-10 absolute"
            style={{ width: 1, top: '6.25rem', left: '7.5rem' }}
          />
          <div
            className="bg-main-black absolute"
            style={{ height: 1, width: '15.5rem', top: '8.75rem', right: '7.9rem' }}
          />
        </div>
      )) || <div className="mr-9 ml-9" style={{ width: '10.938rem' }} />}
    </React.Fragment>
  );
};

Cards.propTypes = {
  /**  @param Type An array of objects.
   * @param Object Keys for object - left: object, middle: object, right: object
   * @param Object Keys for object(left, middle, right) -  align: string, text: string, percent: number
   * @param Example  [
   {
    left: { align: 'left', text: 'Data Collection', percent: 1 },
    middle: { align: 'middle', text: 'Refferal', percent: 2 },
    right: { align: 'right', text: 'Refferal', percent: 1 }
  },
   {
    left: null,
    middle: { align: 'middle', text: 'Phone call', percent: 5 },
    right: null
  },
   {
    left: null,
    middle: { align: 'middle', text: 'Home Visit', percent: 5 },
    right: { align: 'right', text: 'Direct Enquiry', percent: 20 }
  },
   {
    left: null,
    middle: { align: 'middle', text: 'Campus Visit', percent: 15 },
    right: null
  },
   {
    left: null,
    middle: { align: 'middle', text: 'Application', percent: 60 },
    right: null
  },
   {
    left: null,
    middle: { align: 'middle', text: 'Cadmission Test', percent: 80 },
    right: null
  },
   {
    left: null,
    middle: { align: 'middle', text: 'Principal Meeting', percent: 90 },
    right: null
  }
   ]
   **/
  data: PropTypes.array,
  item: PropTypes.object,
  num: PropTypes.number,
  setCards: PropTypes.func,
  cards: PropTypes.array,
};

// const data = [
//   {
//     left: { align: 'left', text: 'Data Collection', percent: 1 },
//     middle: { align: 'middle', text: 'Refferal', percent: 2 },
//     right: { align: 'right', text: 'Refferal', percent: 1 },
//   },
//   {
//     left: null,
//     middle: { align: 'middle', text: 'Phone call', percent: 5 },
//     right: null,
//   },
//   {
//     left: null,
//     middle: { align: 'middle', text: 'Home Visit', percent: 5 },
//     right: { align: 'right', text: 'Direct Enquiry', percent: 20 },
//   },
//   {
//     left: null,
//     middle: { align: 'middle', text: 'Campus Visit', percent: 15 },
//     right: null,
//   },
//   {
//     left: null,
//     middle: { align: 'middle', text: 'Application', percent: 60 },
//     right: null,
//   },
//   {
//     left: null,
//     middle: { align: 'middle', text: 'Cadmission Test', percent: 80 },
//     right: null,
//   },
//   {
//     left: null,
//     middle: { align: 'middle', text: 'Principal Meeting', percent: 90 },
//     right: null,
//   },
// ];

const closedCardsData = [
  { title: 'Won', body: 'Only closed ones', type: '#27C59A' },
  { title: 'Unqulified', body: 'Fee or Other issues', type: '#404EED' },
  { title: 'Failed', body: 'School Criteria not met', type: '#F2C94C' },
  { title: 'Lost', body: 'Joined other schools', type: '#EB5757' },
];

const otherCardsData = [
  { title: null, body: null, type: null },
  { title: 'Contact Issue', body: '100% win likelihood', type: '#F2C94C' },
  { title: 'Upcoming Academic year', body: '100% win likelihood', type: '#F2C94C' },
  { title: null, body: null, type: null },
];
export default Stages;

