import Select from 'react-select';
import { selectStyle } from 'entities/select-style';
import { stringToCamelCase } from '@utils/utils';
import CurrencyInput from 'react-currency-input-field';

const TypeAndValue = (props) => {
  const { info, isFixed, handleChange, className, label } = props
  const { discountType, value } = info

  const options = isFixed ? [
    { value: 'Fixed Percentage', label: 'Fixed Percentage' },
    { value: 'Fixed Amount', label: 'Fixed Amount' },
  ] : [
    { value: 'Percentage', label: 'Percentage' },
    { value: 'Amount', label: 'Amount' }
  ]

  return (
    <div className={className}>
      {/* Discount Type */}
      <div className="principial-card-row mb-2">
        <span className="principial-card-row_fix">{label.discountType}</span>
        <div>
          <Select
            defaultValue={options.find(item => item.value === discountType) || null}
            styles={selectStyle}
            isMulti={false}
            options={options}
            customStyle={{ maxWidth: '50%' }}
            onChange={(e) => {
              handleChange({
                name: stringToCamelCase(label.discountType),
                value: e.value,
              });
            }}
          />
        </div>
      </div>

      {/* Value */}
      <div className="principial-card-row">
        <span>{label.value}</span>

        {discountType?.includes('Percentage') ? (
          <div className='flex relative mr-9'>
            <input className={'flex w-20 text-right ml-[-1.2rem]'}
              value={value <= 100 ? value : 100}
              type="number"
              step='any'
              onKeyDown={(e) => {
                if (['e', '+', '*', '/', '-'].includes(e.key)) e.preventDefault()
              }}
              onChange={(e) => {
                if (Number(e.target.value > 100)) return e.preventDefault()

                handleChange({
                  name: stringToCamelCase(label.value),
                  value: e.target.value,
                });
              }}
            />
            <label className="absolute ml-[4rem]">%</label>
          </div>
        ) : (

          <div className='flex relative mr-5'>
            <label className="absolute ml-[-0.7rem]">₹</label>
            <CurrencyInput
              className='w-20'
              allowNegativeValue={false}
              defaultValue={value}
              decimalsLimit={2}
              onValueChange={(value) => {
                handleChange({
                  name: stringToCamelCase(label.value),
                  value
                })
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default TypeAndValue;