import React, { useState, useEffect, useRef } from 'react';
import './photo.scss';

export default function Photo(props) {
  const { onChange, value, customStyle, label, ...rest } = props;
  const [imgPreview, setImgPreview] = useState('');
  const refInput = useRef();

  useEffect(() => {
    if (value) {
      if (typeof value === 'object' && value.name) {
        let reader = new FileReader();
        reader.onload = (e) => {
          setImgPreview(e.target.result);
        };
        reader.readAsDataURL(value);
      }
    }
  }, [value]);
  // const changeImageHandler = (e) => {
  //   let selected = e.target.files;

  //   if (selected && selected[0]) {
  //     let reader = new FileReader();
  //     reader.onload = (e) => {
  //       setImgPreview(e.target.result);
  //     };
  //     reader.readAsDataURL(selected[0]);
  //   }
  // };
  // let preview;
  // if (value) {
  //   let reader = new FileReader();
  //       reader.onload = (e) => {
  //       setImgPreview(e.target.result);
  //     };
  //     reader.readAsDataURL(selected[0]);
  //   }
  //   preview = 0
  // }

  return (
    <div {...rest} className="flex justify-center aligns-center" style={customStyle}>
      <div
        className="cursor-pointer bg-[#e5effd] rounded-full w-full h-full flex items-center justify-center"
        onClick={() => {
          if (refInput.current) refInput.current.click();
        }}
      >
        {value ? (
          <img
            className="add-enquiry-upload_image"
            src={imgPreview || value}
            alt="uploaded image"
          />
        ) : (
          <p className="flex items-center justify-center add-enquiry-upload_image text-main-blue text-xl">
            +
          </p>
        )}
      </div>
      <input
        ref={refInput}
        type="file"
        id="image-upload-2"
        name="image-upload-2"
        className="hidden"
        accept="image/*"
        onChange={onChange}
      />
    </div>
  );

  // return (
  //   <div {...rest} className="flex justify-center aligns-center" style={customStyle}>
  //     <label htmlFor="image-upload-2" className="cursor-pointer w-[5rem]">
  //       {value && (
  //         <img
  //           className="add-enquiry-upload_image"
  //           src={imgPreview || value}
  //           alt="uploaded image"
  //         />
  //       )}
  //       {!value && (
  //         <div
  //           className="flex items-center justify-center add-enquiry-upload_image text-main-blue text-xl"
  //           style={{ background: '#E5EFFD', ...customStyle }}
  //         >
  //           +
  //         </div>
  //       )}
  //     </label>
  //     <input
  //       type="file"
  //       id="image-upload-2"
  //       name="image-upload-2"
  //       className="hidden"
  //       accept="image/*"
  //       onChange={onChange}
  //     />
  //   </div>
  // );
}
