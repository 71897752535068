import { callApi } from '../../../helper/call-api';
import { Input, Photo, RadioGroup, SelectGroup } from '../../../stories';
import DatePicker from '../../../stories/datepicker/datepicker';
import Image from '../../../stories/image';
import { stringToCamelCase } from '../../../utils/utils';
import AnticipatedGradeOfEntry from '../components/form-AnticipatedGradeOfEntry';
import CampusName from '../components/form-CampusName';
import FormCheckbox from '../components/form-CheckboxLabel';
import EnrollmentYear from '../components/form-EnrollmentYear';
import FormParentGuardian from '../components/form-ParentGuardian';
import FormComponentUser from '../components/FormComponentUser';
import FormLanguageCard from '../components/form-LanguageCard';
import FormImage from '../components/form-Image';
import { validEmail, validPhone } from '../../../utils/validate';
import FormParagraph from '../components/form-Paragraph';
import mediaApi from 'api/media';

const validateName = {
  Email: 'Email',
  'Phone Number': 'Phone Number',
};

const transformObjInfoToArray = (obj, info) => {
  const { traits, variationOf, name, id } = obj;
  const labelItem = traits.find(({ name }) => name === 'label' || 'title');
  if (labelItem) {
    const camelCaseLabel = stringToCamelCase(labelItem.value);
    if (info.hasOwnProperty(camelCaseLabel)) {
      return {
        id,
        label: camelCaseLabel,
        value: info[camelCaseLabel].value,
        variationOf,
        componentName: name,
        isHidden: info[camelCaseLabel].isHidden,
      };
    }
  }
  return {};
};

const checkIfErrorFormData = ({ info, validate }) => {
  // info: array object field [{label, value}]
  // validate: object validateRule {label-camelCase: {isRequired, type}}
  // output: true if invalid
  let error = false;
  info.forEach((item, index) => {
    if (Object.keys(item).length !== 0) {
      const { label, value } = item;
      if (label) {
        const { isRequired, type } = validate[label];
        let tmpValidate;
        if (type === 'email' && value) {
          tmpValidate = !validEmail(value);
          info[index].error = tmpValidate;
          info[index].textForError = 'Invalid email';
        } else if (type === 'phoneNumber' && value) {
          tmpValidate = !validPhone(value);
          info[index].error = tmpValidate;
          info[index].textForError = 'Invalid phone number';
        } else if (isRequired) {
          tmpValidate = value === '';
          info[index].error = tmpValidate;
          if (tmpValidate) info[index].textForError = 'Required field';
        }
        if (tmpValidate) error = tmpValidate;
      }
    }
  });

  return error;
};

const promiseUploadPhoto = (file) => {
  const formData = new FormData();
  formData.set('file', file);
  formData.set('mediaId', file.name);
  return mediaApi.uploadMedia(formData, 'multipart/form-data');
};

const permissionMap = {
  admin: 'Admin',
  user: 'user',
};

const variantName = {
  multiselect: 'multiselect',
  dropdown: 'dropdown',
  textArea: 'textArea',
  photo: 'photo',
  datePicker: 'datePicker',
  checkbox: 'checkbox',
  pageBreak: 'pageBreak',
  radioGroup: 'radioGroup',
  languageCard: 'Language Card',
  document: 'Document',
};

const categoryMap = {
  field: 'Field',
  special: 'Special',
  custom: 'Custom',
};

const componentFieldName = {
  textArea: 'Text Area',
  multiselect: 'Multiselect',
  datePicker: 'Date Picker',
  // paragraph: 'Paragraph',
  // addGroupFields: 'Add Group Fields',
  // newRow: 'New Row',
  radioGroup: 'Radio Group',
  paragraph: 'Paragraph',
  checkbox: 'Checkbox',
  checkboxOutline: 'checkbox Outline',
  dropdown: 'Dropdown',
  pageBreak: 'Page Break',
  drag: 'Drag',
  hidden: 'Hidden',
  image: 'Image',
  photo: 'Photo',
  image: 'Image',
};

const componentCustomName = {
  avatar: 'Avatar',
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  fullName: 'Full Name',
  dateOfBirth: 'Date of Birth',
  phoneNumber: 'Phone Number',
  email: 'Email',
  relationship: 'Relationship',
  gender: 'Gender',
  language: 'Language',
  languageCustom: 'Language Custom',
  nationality: 'Nationality',
  number: 'Number',
  identityIdType: 'Identity ID Type',
};

const componentSpecialName = {
  anticipatedGradeOfEntry: 'Anticipated Grade Of Entry',
  enrollmentYear: 'Enrollment Year',
  campusName: 'Campus Name',
  parentGuardian: 'Parent Guardian',
  languageCard: 'Language Card',
};

const componentSpecial = {
  [componentSpecialName.parentGuardian]: (props) => <FormParentGuardian {...props} />,
  [componentSpecialName.anticipatedGradeOfEntry]: (props) => (
    <AnticipatedGradeOfEntry {...props} />
  ),
  [componentSpecialName.enrollmentYear]: (props) => <EnrollmentYear {...props} />,
  [componentSpecialName.campusName]: (props) => <CampusName {...props} />,
  [componentSpecialName.languageCard]: (props) => <FormLanguageCard {...props} />,
};

const componentField = {
  [componentFieldName.image]: (props) => <FormImage {...props} />,
  [componentFieldName.textArea]: (props) => <Input {...props} />,
  [componentFieldName.multiselect]: (props) => <SelectGroup {...props} isMulti={true} />,
  [componentFieldName.datePicker]: (props) => <DatePicker {...props} />,
  [componentFieldName.radioGroup]: (props) => <RadioGroup {...props} />,
  [componentFieldName.paragraph]: (props) => <FormParagraph {...props} />,
  [componentFieldName.dropdown]: (props) => <SelectGroup {...props} isMulti={false} />,
  [componentFieldName.checkbox]: (props) => <FormCheckbox {...props} />,
  [componentFieldName.image]: (props) => <Image {...props} />,
  [componentFieldName.photo]: (props) => <Photo {...props} />,
  [componentFieldName.hidden]: (props) => <></>,
};

const componentCustom = {
  [componentCustomName.identityIdType]: (props) => (
    <FormComponentUser {...props} component={componentFieldName.dropdown} />
  ),
  [componentCustomName.avatar]: (props) => (
    <FormComponentUser {...props} component={componentFieldName.photo} />
  ),
  [componentCustomName.languageCustom]: (props) => (
    <FormComponentUser {...props} component={componentFieldName.multiselect} />
  ),
  [componentCustomName.language]: (props) => (
    <FormComponentUser {...props} component={componentFieldName.multiselect} />
  ),
  [componentCustomName.nationality]: (props) => (
    <FormComponentUser {...props} component={componentFieldName.multiselect} />
  ),
  [componentCustomName.gender]: (props) => (
    <FormComponentUser {...props} component={componentFieldName.radioGroup} />
  ),
  [componentCustomName.relationship]: (props) => (
    <FormComponentUser {...props} component={componentFieldName.dropdown} />
  ),
  [componentCustomName.fullName]: (props) => (
    <FormComponentUser {...props} component={componentFieldName.textArea} />
  ),
  [componentCustomName.firstName]: (props) => (
    <FormComponentUser {...props} component={componentFieldName.textArea} />
  ),
  [componentCustomName.middleName]: (props) => (
    <FormComponentUser {...props} component={componentFieldName.textArea} />
  ),
  [componentCustomName.lastName]: (props) => (
    <FormComponentUser {...props} component={componentFieldName.textArea} />
  ),
  [componentCustomName.phoneNumber]: (props) => (
    <FormComponentUser {...props} component={componentFieldName.textArea} />
  ),
  [componentCustomName.email]: (props) => (
    <FormComponentUser {...props} component={componentFieldName.textArea} />
  ),
  [componentCustomName.number]: (props) => (
    <FormComponentUser {...props} component={componentFieldName.textArea} />
  ),
  [componentCustomName.dateOfBirth]: (props) => (
    <FormComponentUser {...props} component={componentFieldName.datePicker} />
  ),
};

export {
  componentCustomName,
  componentFieldName,
  componentSpecialName,
  componentField,
  componentCustom,
  componentSpecial,
  categoryMap,
  variantName,
  permissionMap,
  validateName,
  promiseUploadPhoto,
  transformObjInfoToArray,
  checkIfErrorFormData,
};
