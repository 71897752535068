import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Fade } from 'react-reveal';
import { useEffect } from 'react';
import { ToastMessageContext } from '../../context/toast-context';
import { dataLinks } from '../../entities/routes';
import { staticsize } from '../../static/image';
import { Button, Input, SelectGroup, Upload } from '../../stories';
import {
  checkErrorApiFetch,
  checkErrorMultipleApiFetch,
  checkErrorSingleApi,
} from '../../utils/check-error/api-error';
import useDebounce from '../../utils/custom-hook/useDebounce';
import { validEmail, validPhone } from '../../utils/validate';
import { NavLinks } from '../Header/components';
import './new-enquiry.scss';
import NewSelectGroup from '@components/select/NewSelectGroup';
import { relationship } from '@utils/utils';
import InputSearchUser from '@components/admission-module/application/information/subcomponents/input-suggest-user/input-suggest-user';
import { useSuggestUser } from '@utils/custom-hook';
import admissionApi from 'api/admission';
import authApi from 'api/auth';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';

const styleInput = { height: 'unset' };

export default function NewEnquiry({ burger }) {
  const [info, setInfo] = useState({});
  const [error, setError] = useState({});
  const [optionCampus, setOptionCampus] = useState([]);
  const [optionProgramsAndClasses, setOptionProgramsAndClasses] = useState([]);
  const [optionYear, setOptionYear] = useState([]);
  const [showOptionEmail, setShowOptionEmail] = useState(false);
  const [showOptionPhone, setShowOptionPhone] = useState(false);
  const [showOptionFullName, setShowOptionFullName] = useState(false);
  const [formData, setFormData] = useState(new FormData());
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campus = useStorage((state) => state.currentCampus);
  const campusId = campus?.id;
  const parentUserIdChosen = useRef({});

  const dataSuggestByEmail = [];
  const dataSuggestByPhone = [];
  // const { data: dataSuggestByEmail } = useSuggestUser({
  //   textSearch: info?.parent?.email,
  // });
  // const { data: dataSuggestByPhone } = useSuggestUser({
  //   textSearch: info?.parent?.phoneNumber && encodeURIComponent(info.parent.phoneNumber),
  // });
  const { data: dataSuggestByName } = useSuggestUser({
    textSearch: info?.parent?.fullName && encodeURIComponent(info.parent.fullName),
  });

  useEffect(() => {
    if (campusId) {
      // const apiCampus = callApi({
      //   method: 'get',
      //   url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/campus`,
      //   params: { campusId: campus?.id },
      // });
      const apiCampus = authApi.getCampus({ campusId: campusId })
      // const apiAcademicYear = callApi({
      //   method: 'get',
      //   url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2/admission/academic/list?campusId=${campus?.id}`,
      // });
      const apiAcademicYear = centralAdminApi.getListAcademicYear({ campusId: campusId })
      Promise.all([apiCampus, apiAcademicYear])
        .then((res) => {
          if (checkErrorMultipleApiFetch(res, setToastMessage, setIsShowToastMessage)) {
            const { id, name } = res[0].data.data;
            setInfo((prev) => ({ ...prev, campus: { value: id, label: name } }));
            const listYear = res[1].data.data.objects.map((academicYear) => ({
              value: academicYear.name,
              label: academicYear.name,
            }));
            setOptionYear(listYear);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Fetch Data Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Enquiry Error', error);
        });
    } else {
      // callApi({
      //   method: 'get',
      //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/campus/user`,
      //   // params: { campusId },
      // })
      admissionApi.getListCampusOfUser()
        .then((res) => {
          if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage)) {
            const listCampus = res.data.data.objects.map(({ id, name }) => ({
              value: id,
              label: name,
            }));
            setOptionCampus(listCampus);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Fetch Data Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Enquiry Error', error);
        });
    }
  }, [campus]);

  useEffect(() => {
    if (info?.year && campusId) {
      // callApi({
      //   method: 'get',
      //   url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2/admission/grade/list-programs-and-classes?campusId=${campus?.id}&academicYearName=${info.year.value}`,
      // })
      centralAdminApi.getListProgramAndClass(campusId, info.year.value)
        .then((res) => {
          if (
            checkErrorApiFetch(
              res,
              setToastMessage,
              setIsShowToastMessage,
              'Get List Programs',
            )
          ) {
            const listProgramsAndClasses = res.data.data.map((data) => {
              const listClasses = [];
              (data?.programGrade || []).forEach((programGrade) => {
                if (programGrade?.class) {
                  listClasses.push({
                    value: programGrade.id,
                    label: `${programGrade.class.name} (${programGrade.grade?.name})`,
                  });
                }
              });
              return {
                value: data.id,
                label: data.name,
                childrens: listClasses,
              };
            });
            listProgramsAndClasses?.length &&
              setOptionProgramsAndClasses(listProgramsAndClasses);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Get List Programs',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Enquiry Error', error);
        });
    }
  }, [info.year, campus]);

  const validateEmail = useDebounce((text) => {
    setError((prev) => ({
      ...prev,
      email: !validEmail(text),
    }));
  });

  const validatePhone = useDebounce((text) => {
    setError((prev) => ({
      ...prev,
      phoneNumber: !validPhone(text),
    }));
  });

  const handleOnChange = useCallback((key, value, parentKey = '') => {
    setInfo((prev) => {
      if (key === 'campus') {
        return { ...prev, campus: value, grade: '', year: '' };
      } else if (key === 'grade') {
        return { ...prev, grade: value };
      } else if (key === 'year') {
        return { ...prev, year: value };
      } else {
        return { ...prev, [key]: value };
      }
    });
    if (key === 'parent') {
      if (parentKey === 'phoneNumber') {
        validatePhone(value?.[parentKey]);
      } else if (parentKey === 'email') {
        validateEmail(value?.[parentKey]);
      } else if (parentKey === 'fullName') {
        setError((prev) => ({ ...prev, [parentKey]: !value?.[parentKey]?.trim() }));
      } else {
        setError((prev) => ({ ...prev, [parentKey]: !value?.[parentKey] }));
      }
    } else if (key === 'firstName' || key === 'lastName') {
      setError((prev) => ({ ...prev, [key]: !value?.trim() }));
    } else {
      setError((prev) => ({ ...prev, [key]: !value }));
    }
    // if (key === 'email') validateEmail(value);
    // else if (key === 'phone') validatePhone(value);
    // else setError((prev) => ({ ...prev, [key]: !value }));
  }, []);

  const handleChangePhoto = useCallback(
    (e) => {
      if (e.target.files[0]) {
        if (info.urlPhoto) URL.revokeObjectURL(info.urlPhoto);
        const file = e.target.files[0];
        formData.set('file', file);
        formData.set('mediaId', file.name);
        setFormData(formData);
        setInfo((prev) => ({ ...prev, urlPhoto: URL.createObjectURL(file) }));
      }
    },
    [formData, info],
  );

  const handleCLickCancel = useCallback(() => {
    if (info.urlPhoto) URL.revokeObjectURL(info.urlPhoto);
    setInfo((prev) => {
      if (campusId) return { campus: prev.campus };
      return {};
    });
    setError({});
    setOptionProgramsAndClasses([]);
  }, [info]);

  const checkHasErrorField = useCallback((info, field) => {
    if (field === 'firstName' || field === 'lastName') {
      setError((prev) => ({ ...prev, [field]: !info?.[field]?.trim() }));
      return !info?.[field]?.trim();
    } else {
      setError((prev) => ({ ...prev, [field]: !info?.[field] }));
      return !info?.[field];
    }
  }, []);
  const checkHasErrorFieldParent = useCallback((info, field) => {
    if (field === 'phoneNumber') {
      setError((prev) => ({ ...prev, [field]: !validPhone(info?.['parent']?.[field]) }));
      return !validPhone(info?.['parent']?.[field]);
    } else if (field === 'email') {
      setError((prev) => ({ ...prev, [field]: !validEmail(info?.['parent']?.[field]) }));
      return !validEmail(info?.['parent']?.[field]);
    } else {
      setError((prev) => ({ ...prev, [field]: !info?.['parent']?.[field] }));
      return !info?.['parent']?.[field];
    }
  }, []);

  const checkHasError = () => {
    // output
    // has error: true
    // no error: false
    let result = false;
    result = Object.keys(error).length === 0;
    if (!result) {
      result = Object.values(error).some((item) => item);
    }
    const listError = [];
    ['grade', 'year', 'campus', 'firstName', 'lastName'].forEach((item) =>
      listError.push(checkHasErrorField(info, item)),
    );
    ['fullName', 'relationship', 'phoneNumber', 'email'].forEach((item) =>
      listError.push(checkHasErrorFieldParent(info, item)),
    );
    result = listError.some((dt) => dt);
    return result;
  };

  const handleClickAdd = useCallback(async () => {
    if (checkHasError()) {
      setToastMessage({
        status: 'warning',
        title: 'Create new enquiry',
        message: 'Some fields are missing, please check again',
      });
      setIsShowToastMessage(true);
    } else {
      let photo = '';
      if (info.urlPhoto) {
        try {
          // const res = await callApi({
          //   method: 'post',
          //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/media`,
          //   data: formData,
          //   contentType: 'multipart/form-data',
          // });
          const res = await admissionApi.uploadMedia(formData)
          if (
            checkErrorSingleApi(
              res,
              setToastMessage,
              setIsShowToastMessage,
              'Upload Image',
              false,
            )
          ) {
            photo = res.data.data;
            URL.revokeObjectURL(info.urlPhoto);
          }
        } catch (error) {
          setToastMessage({
            status: 'error',
            title: 'Upload Image',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Enquiry Error', error);
        }
      }
      const { parent, year, firstName, lastName, grade } = info;
      if (!parent?.id) {
        try {
          // const createParent = await callApi({
          //   method: 'post',
          //   url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/user/parent`,
          //   // params: { campusId },
          //   data: {
          //     email: parent.email,
          //     name: parent.fullName,
          //     phoneNumber: parent.phoneNumber
          //   },
          // });
          const createParent = await authApi.createParent({
            email: parent.email,
            name: parent.fullName,
            phoneNumber: parent.phoneNumber
          })
          if (!createParent.data.success) {
            throw new Error('Create parent failed');
          }
          parent.id = createParent.data?.data?.id;
        } catch (error) {
          setToastMessage({
            status: 'error',
            title: 'Add Enquiry',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Enquiry Error', error);
        }
      }
      const data = {
        info: {
          firstName,
          lastName,
          avatar: photo,
        },
        campusId: campusId || '',
        programGradeId: grade.value,
        academicYear: year.value,
        parent: { ...parent, relationship: parent.relationship?.value || '' },
      };
      try {
        // const res = await callApi({
        //   method: 'post',
        //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/enquiry`,
        //   data,
        //   params: { campusId },
        // });
        const res = await admissionApi.createNewEnquiry({ campusId }, data)
        if (
          checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, 'Add Enquiry')
        ) {
          handleCLickCancel();
        }
      } catch (error) {
        setToastMessage({
          status: 'error',
          title: 'Add Enquiry',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Enquiry Error', error);
      }
    }
  }, [error, info]);

  const styles = {
    btn: {
      width: '48%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    btnWhite: {
      width: '48%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#404eed',
      backgroundColor: '#fff',
      border: '1px solid #404eed',
    },
    input: {
      // height: '2.4rem',
      fontSize: '1.25rem',
      // borderRadius: '0.25rem',
      lineHeight: '1.75rem',
      borderRadius: '8px',
      height: 'unset',
      width: '100%',
    },
  };

  const selectGroupStyle = {
    container: (styles) => ({ ...styles, width: '100%' }),
    control: (styles) => ({
      ...styles,
      minHeight: '38px',
      padding: '0.28rem 0px',
      borderRadius: '8px',
    }),
    indicatorSeparator: () => null,
    input: (styles) => ({ ...styles, width: '6.25rem', color: '#212B36' }),
    indicatorsContainer: () => null,
    option: (styles, state) => ({
      ...styles,
      height: '2.188rem',
      width: '100%',
      padding: '0.438rem',
      backgroundColor: state.isSelected ? '#e2e2e2' : 'white',
      color: '#212B36',
      cursor: 'pointer',
      '&:hover': state.isSelected
        ? { backgroundColor: '#e2e2e2' }
        : { backgroundColor: '#404eed', color: 'white' },
    }),
    menu: (styles) => ({ ...styles, width: '100%', zIndex: '2' }),
    singleValue: (styles) => ({ ...styles, color: '#212B36' }),
  };

  return (
    <div className="w-full flex justify-center add-enquiry">
      <div style={{ width: '30rem' }}>
        <Fade clear duration={300}>
          <NavLinks urls={dataLinks.enquiryLinks} />
          <h1 className="namepage namepage-enquiry">Add a New Enquiry</h1>
          <form action="#" method="POST" className="add-enquiry-form w-full">
            <div className="add-enquiry-form_section add-enquiry-section">
              <div className="add-enquiry-section_header">
                <h2 className="add-enquiry-section_title">1. Enquiry Photo</h2>
                <p className="add-enquiry-section_text">
                  Upload an image to make it easily recognizable.
                </p>
              </div>
              <div className="add-enquiry-upload">
                <img
                  className="add-enquiry-upload_image"
                  src={info.urlPhoto || staticsize}
                  alt="uploaded"
                />
                <Upload
                  text="Upload Image"
                  id="image-upload"
                  accept="image/*"
                  onChange={handleChangePhoto}
                />
              </div>
            </div>
            <div className="add-enquiry-form_section add-enquiry-section">
              <div className="add-enquiry-section_header">
                <h2 className="add-enquiry-section_title">2. Enquiry Details</h2>
                <p className="add-enquiry-section_text">Enter applicant details below.</p>
              </div>
              <div className="add-enquiry-section_content">
                {/* <div className="w-full flex justify-between"> */}
                <Input
                  label="First Name"
                  name="first-name"
                  customStyle={styles.input}
                  error={error.firstName || false}
                  value={info.firstName || ''}
                  onChange={(e) => {
                    handleOnChange('firstName', e.target.value);
                  }}
                />
                <Input
                  label="Last Name"
                  name="last-name"
                  customStyle={styles.input}
                  error={error.lastName || false}
                  value={info.lastName || ''}
                  onChange={(e) => {
                    handleOnChange('lastName', e.target.value);
                  }}
                />
                {/* </div> */}
                <SelectGroup
                  options={optionCampus}
                  title="Campus"
                  error={error.campus || false}
                  isDisabled={Boolean(campusId) || false}
                  value={info.campus || ''}
                  onChange={(e) => {
                    handleOnChange('campus', e);
                  }}
                  // classNameSelect={'select-group1'}
                  styles={selectGroupStyle}
                />
                <SelectGroup
                  options={optionYear}
                  title="Enrollment Year"
                  error={error.year || false}
                  value={info.year || ''}
                  onChange={(e) => {
                    handleOnChange('year', e);
                  }}
                  styles={selectGroupStyle}
                />
                <NewSelectGroup
                  listOptions={optionProgramsAndClasses}
                  onChange={(e) => {
                    const dataSelected = {
                      label: e.target.value,
                      value: e.target.value,
                    };
                    handleOnChange('grade', dataSelected);
                  }}
                  isError={error.grade || false}
                  id="grade"
                  label="Anticipated Grade of Entry"
                />
              </div>
            </div>
            <div className="add-enquiry-form_section add-enquiry-section">
              <div className="add-enquiry-section_header">
                <h2 className="add-enquiry-section_title">3. Parent Details</h2>
                <p className="add-enquiry-section_text">Enter applicant details below.</p>
              </div>
              <div className="add-enquiry-section_content">
                {/* <div className="w-full flex justify-between"> */}
                {/* <Input
                  label="Full Name"
                  name="full-name"
                  customStyle={styles.input}
                  error={error.fullName || false}
                  value={info?.parent?.fullName || ''}
                  onChange={(e) => {
                    const parentInfo = info?.parent || {};
                    handleOnChange(
                      'parent',
                      { ...parentInfo, fullName: e.target.value },
                      'fullName',
                    );
                  }}
                /> */}
                <InputSearchUser
                  label="Full Name"
                  value={info?.parent?.fullName || ''}
                  options={dataSuggestByName}
                  exceptId={parentUserIdChosen}
                  showOption={showOptionEmail}
                  error={error?.fullName}
                  customStyle={styleInput}
                  handleChangeText={(e) => {
                    const parentInfo = info?.parent || {};
                    handleOnChange(
                      'parent',
                      { ...parentInfo, fullName: e, id: '' },
                      'fullName',
                    );
                    setShowOptionEmail(true);
                  }}
                  handleChooseOption={(e) => {
                    const parentInfo = info?.parent || {};
                    handleOnChange('parent', {
                      ...parentInfo,
                      phoneNumber: e.phoneNumber,
                      fullName: e.name,
                      email: e.email,
                      id: e.id,
                    });
                    setShowOptionEmail(false);
                    validatePhone(e.phoneNumber);
                    validateEmail(e.email);
                  }}
                />
                <SelectGroup
                  styles={selectGroupStyle}
                  options={relationship}
                  error={error.relationship || false}
                  title="Relationship with Applicant"
                  value={info?.parent?.relationship || null}
                  onChange={(e) => {
                    const parentInfo = info?.parent || {};
                    handleOnChange(
                      'parent',
                      { ...parentInfo, relationship: e },
                      'relationship',
                    );
                  }}
                />
                <InputSearchUser
                  label="Mobile Number"
                  value={info?.parent?.phoneNumber || ''}
                  options={dataSuggestByPhone}
                  exceptId={parentUserIdChosen}
                  showOption={showOptionPhone}
                  error={error?.phoneNumber}
                  customStyle={styleInput}
                  handleChangeText={(e) => {
                    const parentInfo = info?.parent || {};
                    handleOnChange(
                      'parent',
                      { ...parentInfo, phoneNumber: e, id: '' },
                      'phoneNumber',
                    );
                    setShowOptionPhone(true);
                    validatePhone(e);
                  }}
                  handleChooseOption={(e) => {
                    const parentInfo = info?.parent || {};
                    handleOnChange('parent', {
                      ...parentInfo,
                      phoneNumber: e.phoneNumber,
                      fullName: e.name,
                      email: e.email,
                      id: e.id,
                    });
                    setShowOptionPhone(false);
                    validatePhone(e.phoneNumber);
                    validateEmail(e.email);
                  }}
                />
                <InputSearchUser
                  label="Email"
                  value={info?.parent?.email || ''}
                  options={dataSuggestByEmail}
                  exceptId={parentUserIdChosen}
                  showOption={showOptionEmail}
                  error={error?.email}
                  customStyle={styleInput}
                  handleChangeText={(e) => {
                    const parentInfo = info?.parent || {};
                    handleOnChange(
                      'parent',
                      { ...parentInfo, email: e, id: '' },
                      'email',
                    );
                    setShowOptionEmail(true);
                    validateEmail(e);
                  }}
                  handleChooseOption={(e) => {
                    const parentInfo = info?.parent || {};
                    handleOnChange('parent', {
                      ...parentInfo,
                      phoneNumber: e.phoneNumber,
                      fullName: e.name,
                      email: e.email,
                      id: e.id,
                    });
                    setShowOptionEmail(false);
                    validatePhone(e.phoneNumber);
                    validateEmail(e.email);
                  }}
                />
              </div>
              <div className="add-enquiry-section_footer">
                <Button
                  text="Cancel"
                  customStyle={styles.btnWhite}
                  onClick={handleCLickCancel}
                />
                <Button text="Add" customStyle={styles.btn} onClick={handleClickAdd} />
              </div>
            </div>
          </form>
        </Fade>
        {/* <ApplicationPanel /> */}
      </div>
    </div>
  );
}
