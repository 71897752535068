import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Accept,
  boxArrow,
  ellipsis,
  msg,
  Reject,
  Waiting,
} from '../../../../static/image';
import ApprovalInformationPanel from '../../components/approval-information-panel';
import Feedback from '../../components/feedback';

import './table.scss';

const Table = ({ approval, type, isUpdate, setIsUpdate, isMyRequest }) => {
  const [visiblePanel, setVisiblePanel] = useState(false);
  const [isShowFeedbackModal, setIsShowFeedbackModal] = useState({
    isVisible: false,
    status: null // "approve" || "reject" || "need info" || null
  });
  const history = useHistory();
  const handleIconAproval = (isApproval) => {
    let iconAproval;
    if (isApproval.toLowerCase() === 'approve') {
      iconAproval = Accept;
    } else if (isApproval.toLowerCase() === 'reject') {
      iconAproval = Reject;
    } else {
      iconAproval = Waiting;
    }
    return iconAproval;
  };
  const handleDateTime = (date) => {
    if (!date) {
      return '';
    }
    const data = new Date(date).toUTCString();
    return data.slice(5, 16);
  };
  const statusColor =
    approval?.stageResult && approval.stageResult === 'reject' ? '#FD5749' : '#27AE60';
  const statusBackgroundColor =
    approval?.stageResult && approval.stageResult === 'reject' ? '#FED3D1' : '#AEE9D1';

  const space = useMemo(
    () => ({
      History: [
        'w-[18%]',
        'w-[3%]',
        'w-[10%]',
        'w-[12%]',
        'w-[12%]',
        'w-[10%]',
        'w-[5%]',
      ],
      'In Progress': ['w-[20%]', 'w-[5%]', 'w-[15%]', '', 'w-[20%]', '', 'w-[10%]', ''],
    }),
    [],
  );
  return (
    <div className="w-full">
      <div
        className="company-card border border-slate-300 mb-4 "
        onClick={() => {
          setVisiblePanel(true);
        }}
      >
        <div className={`flex items-center flex-auto ${space[type][0]}`}>
          <img
            className="w-8 h-8 mr-4 rounded-full"
            alt="approval"
            src={approval.avatar}
          />
          <div>
            <p className="datatable-name text-sm" style={{ color: '#212B36' }}>
              {approval.requestName}
            </p>
            <p className="datatable-code text-xs" style={{ color: '#006D41' }}>
              {approval.code}
            </p>
          </div>
        </div>
        <img src={msg} className={`${space[type][1]} h-4 ml-4`} alt="" />
        <div className={`flex items-center justify-center flex-auto ${space[type][2]}`}>
          {approval.users.map((user, idx) => {
            return (
              <div key={idx} className="relative">
                <img
                  src={user.avatar}
                  alt="avatar"
                  className="w-8 h-8 mr-2 rounded-full"
                />
                <img
                  src={handleIconAproval(user.isApproval)}
                  className="w-4 h-4 absolute bottom-0 right-0 z-4"
                  alt=""
                />
              </div>
            );
          })}
        </div>
        {type === 'History' && (
          <div className={`${space[type][3]} mr-[2%]`}>
            <DataProcessStatus
              text={approval?.stageResult ? approval.stageResult : ''}
              handleIconAproval={handleIconAproval}
              statusColor={statusColor}
              statusBackgroundColor={statusBackgroundColor}
            />
          </div>
        )}
        <div className={`${space[type][4]} mr-[3%]`}>
          <DataTableStatus
            text={approval?.processName ? approval.processName : ''}
            color={approval?.processColor ? approval.processColor : '#637381'}
          />
        </div>

        <div className={`flex-auto w-16 ${space[type][5]}`}>
          <p className="datatable-multiple text-sm w-full">
            {approval.multiple ? 'Multiple' : 'Single'} Approvals
          </p>
          <p
            className="datatable-dueDate text-xs w-full"
            style={{ color: '#637381', opacity: 0.6 }}
          >
            Due on{' '}
            {approval.dueOn && (
              <span
                className={clsx(
                  new Date() < new Date(approval.dueOn)
                    ? 'text-[#04AA6D]'
                    : new Date() > new Date(approval.dueOn)
                      ? 'text-[#fd5749]'
                      : '',
                )}
              >
                {handleDateTime(approval.dueOn).slice(0, 6)}
              </span>
            )}
          </p>
        </div>
        {type === 'History' && (
          <div className={`flex-auto ${space[type][6]} ml-[1%]`}>
            <p
              className="datatable-multiple text-sm"
              style={{ color: '#637381', opacity: 0.6 }}
            >
              {approval?.stageResult && approval.stageResult === 'reject'
                ? 'Rejected on'
                : 'Approved on'}
            </p>
            <p className="datatable-dueDate text-sm">
              <span style={{ color: statusColor }}>
                {handleDateTime(approval.resultDate)}
              </span>
            </p>
          </div>
        )}
        <button className="datatable-button">
          <img src={boxArrow} alt="button" />
        </button>
        <button className="datatable-button ml-12">
          <img src={ellipsis} alt="button" />
        </button>
      </div>
      <div className={clsx(visiblePanel ? 'pointer-events-none' : 'pointer-events-auto')}>
        {visiblePanel && (
          <ApprovalInformationPanel
            requestId={approval.code}
            isMyRequest={isMyRequest}
            visible={visiblePanel}
            setVisible={setVisiblePanel}
            setIsShowFeedbackModal={setIsShowFeedbackModal}
            type={type}
          />
        )}
      </div>
      <div
        className={clsx(
          isShowFeedbackModal.isVisible ? 'pointer-events-none' : 'pointer-events-auto',
        )}
      >
        {isShowFeedbackModal.isVisible && (
          <Feedback
            users={approval.users}
            requestId={approval.code}
            isShowFeedbackModal={isShowFeedbackModal}
            setIsShowFeedbackModal={setIsShowFeedbackModal}
            feedbackModalStatus={isShowFeedbackModal.status}
            isUpdate={isUpdate}
            setIsUpdate={setIsUpdate}
          />
        )}
      </div>
    </div>
  );
};

const DataTableStatus = ({ text, color }) => {
  return (
    <div
      className={clsx('text-center border px-1.5 py-1.5 mr-8 rounded-md text-xs w-full')}
      style={{ color: color, borderColor: color }}
    >
      {text}
    </div>
  );
};

const DataProcessStatus = ({
  text,
  statusColor,
  handleIconAproval,
  statusBackgroundColor,
}) => {
  return (
    <div
      style={{
        color: statusColor,
        borderColor: statusColor,
        backgroundColor: statusBackgroundColor,
      }}
      className="flex-auto items-center justify-center relative text-center border pt-2 pb-2 mr-8 px-0 rounded-full w-full"
    >
      <img
        src={handleIconAproval(text)}
        className={clsx(
          'w-4 h-4 absolute left-3',
          text === 'approve' ? 'top-2.5' : 'top-3',
        )}
      />
      <p className="pl-3 text-sm">{text === 'reject' ? 'Rejected' : 'Approved'}</p>
    </div>
  );
};

export default Table;

/* No delete code below*/
// const handleOnClickEdit = (approval, key) => {
//   const index = key + currentPage * rowsPerPage;
//   const selectApproval = approvals[index];
//   // history.push(`/detail/approval-edit/${approval.id}`, { approval })
//   // history.push(`/detail/company-edit`, { approval, selectApproval })
// }

// const handleOnClickMoreInfo = (approval, key) => {
//   const index = key + currentPage * rowsPerPage;
//   const selectApproval = approvals[index];
//   history.push(`/detail/approval-information`, { approval, selectApproval})
// }
