import clsx from 'clsx';
import PropTypes from 'prop-types';

const HeaderField = (props) => {
  const { types, active, changeActive, className } = props;

  return (
    <div className={`header-field ${className}`}>
      <div className="flex h-[30px] flex-wrap flex-col overflow-auto">
        {types.map((type, index) => {
          return (
            <button
              key={`type${index}`}
              style={{ fontSize: 14 }}
              onClick={() => changeActive(type, index)}
              className={clsx(
                'header-field-button',
                type === active && 'header-field-button_active',
              )}
            >
              <p>{type}</p>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default HeaderField;

HeaderField.propTypes = {
  types: PropTypes.array,
  active: PropTypes.string,
  setActive: PropTypes.func,
  className: PropTypes.string,
};
