import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { ellipsis } from '../../../../static/image';
import { useHistory } from 'react-router-dom';
import { urls } from 'entities/urls';
import useStorage from 'store/storage';

const ActionDropdownMenu = (props) => {
  const { data } = props;
  const history = useHistory();
  const isGiikiAdmin = useStorage((state) => state.isGiikiAdmin);
  return (
    <Menu
      menuButton={
        <MenuButton>
          <img src={ellipsis} alt="button" />
        </MenuButton>
      }
      transition
    >
      {/* <MenuItem
        onClick={() => {
          // handleClickUsers(data, index);
          handleClickUsers(data);
        }}
      >
        Users
      </MenuItem>
      <MenuItem>Like</MenuItem>
      <MenuItem>Disable</MenuItem> */}
      <MenuItem
        data={data}
        onClick={() => {
          history.push(isGiikiAdmin ? urls.giiki.institutions.company.campus.edit : urls.central.setup.campus.edit, { data: data });

        }}
      >
        Edit Campus
      </MenuItem>
      <MenuItem
        data={data}
        onClick={() => {
          history.push(isGiikiAdmin ? urls.giiki.institutions.company.campus.importUser : urls.central.setup.campus.importUser, { data: data });
        }}
      >
        Import Users
      </MenuItem>
    </Menu>
  );
};
export default ActionDropdownMenu;
