import { info } from 'autoprefixer';
import { useEffect } from 'react';
import { successIcon, infoIcon, warningIcon, errorIcon } from '../../static/image';

import './toast-message.scss';

function ToastMessage({
  setIsHover = () => { },
  setIsShowToastMessage = () => { },
  status = 'error',
  title = 'Warning',
  message = '',
  isHover = false,
  duration = 2500,
}) {
  const dataToastMessage = {
    success: {
      backgroundColor: '#E9FCD4',
      textColor: '#08660D',
      borderColor: '#AAF27F',
      icon: successIcon,
    },
    error: {
      backgroundColor: '#FFE7D9',
      textColor: '#7A0C2E',
      borderColor: '#FFA48D',
      icon: errorIcon,
    },
    info: {
      backgroundColor: '#82ABE4',
      textColor: '#04297A',
      borderColor: '#588EDB',
      icon: infoIcon,
    },
    warning: {
      backgroundColor: '#FFF7CD',
      textColor: '#7A4F01',
      borderColor: '#FFE16A',
      icon: warningIcon,
    },
  };

  if (typeof message === 'object') {
    if (message.message) {
      message = message.message.toString();
    } else {
      message = message.toString();
    }
  }

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setIsShowToastMessage(false);
    }, duration);
    if (isHover) {
      clearTimeout(timeOut);
    }
    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, [isHover]);

  return (
    <div className="fixed z-[999999] top-[3.75rem] right-[1rem]">
      <div
        className={`toast flex items-center justify-start border rounded-lg`}
        style={{
          backgroundColor: dataToastMessage?.[status]?.backgroundColor || '#FFF7CD',
          borderColor: dataToastMessage?.[status]?.borderColor || '#FFE16A',
        }}
      >
        <div className={`toast-body flex`}>
          <img
            src={dataToastMessage?.[status]?.icon || warningIcon}
            alt="message"
            className="w-6 h-6"
          />
          <div style={{ marginLeft: '0.5rem', marginRight: '1rem' }}>
            <p
              style={{
                wordBreak: 'break-all',
                fontSize: '1rem',
                color: dataToastMessage?.[status]?.textColor || '#7A4F01',
              }}
            >
              {title}
            </p>
            <p
              style={{
                wordBreak: 'break-all',
                color: dataToastMessage?.[status]?.textColor || '#7A4F01',
                fontSize: '0.75rem',
              }}
            >
              {message}
            </p>
          </div>
        </div>
        <div
          onClick={() => {
            setIsHover(false);
            setIsShowToastMessage(false);
          }}
          className="w-7 h-7 cursor-pointer"
        >
          <Close />
        </div>
      </div>
    </div>
  );
}

export default ToastMessage;

const Close = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 1.4L14.6 0L8 6.6L1.4 0L0 1.4L6.6 8L0 14.6L1.4 16L8 9.4L14.6 16L16 14.6L9.4 8L16 1.4Z"
        fill="#04297A"
      />
    </svg>
  );
};
