/* eslint-disable no-restricted-globals */
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Checkbox, Pagination } from '@stories/index';
import { urls } from 'entities/urls';
import { ToastMessageContext } from 'context/toast-context';
import { callApi } from '@helper/call-api';
import { mainavatar } from '@static/image';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_START } from '@utils/utils';
import { rowsPerPageCount } from '@utils/storage';
import authApi from 'api/auth';

export default function Admins({ burger, state }) {
  const [companyUsers, setCompanyUsers] = useState([]);
  const [totalObjectsFromApi, setTotalObjectsFromApi] = useState(0);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_START);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const history = useHistory();

  const handleChangeRowsPerPage = (event) => {
    setSelectedRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    // const res = callApi({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/user/get-users-of-company`,
    //   params: {
    //     companyId: state.company.id,
    //     page: currentPage,
    //     pageSize: selectedRowsPerPage,
    //   },
    // });
    // res
    authApi.getListUserOfCompany({
      companyId: state.id,
      page: currentPage,
      pageSize: selectedRowsPerPage,
    })
      .then((res) => {
        const { data } = res;
        if (data.success) {
          setCompanyUsers(data.data.objects);
          setTotalObjectsFromApi(data.data.total);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Get Users Failed',
            message: data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Get Users Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Company Settings Admins Error', error);
      });
  }, [currentPage, selectedRowsPerPage]);

  const handleDateTime = (date) => {
    let data;
    if (date) {
      data = new Date(date);
    } else {
      data = new Date();
    }
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    const dateAdded = data.toLocaleDateString('en-GB', options);
    return `${dateAdded.slice(0, dateAdded.length - 5)}, ${dateAdded.slice(dateAdded.length - 5)}`;
  };

  const styles = {
    button: {
      width: '9.813rem',
      height: '2.75rem',
      background: '#663174',
      color: '#F1F8F5',
    },
  };

  return (
    <div className={clsx(burger ? '2000px:pl-80' : '2000px:pl-40')}>
      <div className="flex justify-items-center justify-between w-full mb-7">
        <h1 className="font-bold text-2xl">{`Users (${totalObjectsFromApi})`}</h1>
        <div className="flex">
          <button
            onClick={() => history.push(urls.giiki.institutions.company.createUser, { companyId: state.id })}
            className="flex items-center justify-center rounded-lg"
            style={styles.button}
          >
            Create Member
          </button>
        </div>
      </div>
      <table className="w-full">
        <thead>
          <tr className="font-medium text-xs">
            <th align="left" className="relative w-[5%]" style={{ left: 6 }}>
              <Checkbox />
            </th>
            <th align="left" className='w-[22%]'>Name</th>
            <th align="left" className='w-[22%]'>Role</th>
            <th align="left" className="relative w-[15%]">
              Date Added
            </th>
            <th align="left">Email Address</th>
            <th align="left">Status</th>
          </tr>
        </thead>
        <tbody>
          {companyUsers.map((user, key) => (
            <React.Fragment key={key}>
              <tr className="h-6" />
              <tr className="font-medium text-sm">
                <td className="max-w-[8%]">
                  <img
                    className="w-8 h-8 rounded-full"
                    src={user.photoURL ? user.photoURL : mainavatar}
                    alt="admins"
                  />
                </td>
                <td
                  className="text-left max-w-[20%]"
                  onClick={() => {
                    history.push(urls.editUser, {
                      selectUser: user,
                      company: state,
                    });
                  }}
                >
                  {user.name}
                </td>
                <td className="text-left max-w-[20%]">
                  {user.roles?.length === 0 && <p>No Role</p>}
                  {user.roles.map((role) => {
                    return <p key={role.id}>{role.name}</p>;
                  })}
                </td>
                <td className="relative text-left max-w-[14%]">
                  {handleDateTime(user.dateUpdated)}
                </td>
                <td className="text-left max-w-[30%]">{user.email}</td>
                <td
                  className="text-left max-w-[8%]"
                  style={
                    user.status === 'Active' ? { color: '#3A8B93' } : { color: '#6C0F00' }
                  }
                >
                  {user?.status && user.status ? user.status : 'Inactive'}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <div className="h-20"></div>
      <div className="datatable-footer">
        <Pagination
          customClass="fixed bottom-0 right-0 z-[3] pr-4"
          customStyle={{ height: '3rem' }}
          page={currentPage}
          rowsPerPage={selectedRowsPerPage}
          rowsPerPageCount={rowsPerPageCount}
          totalRows={totalObjectsFromApi}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
        />
      </div>
    </div>
  );
}
