import { memo } from 'react';
import SingleCheckbox from './component/single-checkbox';
import TypeAndValue from './component/type-and-value';

const NewAdmissionCard = memo((props) => {
  const { data, handleChange } = props
  const name = data?.name || ''
  const active = !!data?.active
  const discountType = data?.discountType || null
  const value = data?.value || ''

  return (
    <div className="principial-card h-fit">

      {/* name */}
      <SingleCheckbox label={name}
        value={active}
        classLabel='font-extrabold text-lg mb-4'
        onChange={(e) => {
          handleChange({
            index: 1,
            name: 'active',
            value: e.target.checked,
          })
        }}
      />

      <TypeAndValue info={{ discountType, value }}
        isFixed={true}
        label={{ value: 'Value', discountType: 'Discount Type' }}
        handleChange={({ name, value }) => handleChange({ index: 1, name, value })}
      />

    </div>
  )
})
NewAdmissionCard.displayName = 'NewAdmissionCard'

export default NewAdmissionCard
