import { convertSecondToHourMinuteSecond } from "@helper/admission"
import { pause, play as playIcon, rejected } from "@static/image"
import React, { useEffect, useRef, useState } from "react"

let intervalRecording = null
let intervalReview = null
let mimeType = 'audio/mp3'

const Audio = (props) => {
  const { setAudioFile, } = props
  const [audioChunks, setAudioChunks] = useState([]);
  const [audio, setAudio] = useState(null);
  const [countRecording, setCountRecording] = useState(0)

  // const getMinutes = (count) => {
  //   if (!count) return '00:00'

  //   const minutes = String(Math.floor(count / 60)).padStart(2, '0')
  //   const seconds = String(count % 60).padStart(2, '0')

  //   return `${minutes}:${seconds}`
  // }

  return (
    <>
      {audio ?
        <AudioReview
          audio={audio}
          setAudio={setAudio}
          countRecording={countRecording}
          setCountRecording={setCountRecording}
          setAudioChunks={setAudioChunks}
          getMinutes={convertSecondToHourMinuteSecond}
          setAudioFile={setAudioFile}
        />
        :
        <AudioRecording
          setAudio={setAudio}
          audioChunks={audioChunks}
          setAudioChunks={setAudioChunks}
          getMinutes={convertSecondToHourMinuteSecond}
          countRecording={countRecording}
          setCountRecording={setCountRecording}
          setAudioFile={setAudioFile}
        />}
    </>
  )
}

export default Audio;

const AudioReview = (props) => {
  const { audio, setAudio, setCountRecording, countRecording, setAudioChunks, setAudioFile, getMinutes } = props
  const [count, setCount] = useState(countRecording)
  const [play, setPlay] = useState(false)
  const ref = useRef()

  const audioPlay = () => {
    ref.current.play()
    setPlay(true)

    intervalReview = setInterval(() => {
      setCount(prev => prev - 1)
    }, 1000)
  }

  const audioStop = () => {
    ref.current.pause()
    setPlay(false)
    clearInterval(intervalReview)
  }

  const onRetry = () => {
    setAudio(null)
    setCountRecording(0)
    setAudioFile(null)
    setAudioChunks([])
  }

  useEffect(() => {
    if (count === 0) {
      if (intervalReview) clearInterval(intervalReview)
      setPlay(false)
      setCount(countRecording)
      setPlay(false)
    }
  }, [count])

  return (
    <div className="w-full flex items-center h-6">
      <audio ref={ref} src={audio} />
      <div className="w-2/12 text-center">
        {convertSecondToHourMinuteSecond(count)}
      </div>
      <div className="relative w-8/12">
        <span className="h-1 bg-[#94a3b8] w-full rounded" />
        <span className="absolute h-1 bg-[#000] top-0 left-0 rounded"
          style={{
            width: `${(100 / countRecording) * count}%`,
            transition: 'width 1s',
            transitionTimingFunction: 'linear'
          }} />
      </div>

      <div className="w-1/12 flex">
        {play ? (
          <button onClick={audioStop} className="m-auto">
            <img src={pause} alt={'pause'} />
          </button>)
          :
          (<button onClick={audioPlay} className="m-auto">
            <img src={playIcon} alt={'play'} />
          </button>)
        }
      </div>

      {/* <div className="w-1/12"> */}
      <button onClick={onRetry} className="w-1/12 flex">
        <img src={rejected} alt={'play'} className="h-4 aspect-square m-auto" />
      </button>
      {/* </div> */}

    </div >
  )
}

const AudioRecording = (props) => {
  const { setAudio, audioChunks, setAudioChunks, setAudioFile, getMinutes, countRecording, setCountRecording } = props
  const mediaRecorder = useRef(null);
  const [recording, setRecording] = useState(false)
  const [stream, setStream] = useState(null)

  // const getMicrophonePermission = async () => {
  //   if ("MediaRecorder" in window) {
  //     try {
  //       const streamData = await window.navigator.mediaDevices.getUserMedia({
  //         audio: true,
  //         video: false,
  //       });
  //       setStream(streamData);
  //     } catch (err) {
  //       alert(err.message);
  //     }
  //   } else {
  //     alert("The MediaRecorder API is not supported in your browser.");
  //   }
  // };

  // useEffect(() => {
  //   getMicrophonePermission()
  // }, [])

  const startRecording = async () => {
    try {
      if ("MediaRecorder" in window) {
        const streamData = await window.navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setStream(streamData);

        setRecording(true)
        intervalRecording = setInterval(() => {
          setCountRecording(prev => prev + 1)
        }, 1000)

        const media = new MediaRecorder(streamData, { type: mimeType });
        mediaRecorder.current = media;

        mediaRecorder.current.start();
        let localAudioChunks = [];

        mediaRecorder.current.ondataavailable = (event) => {
          if (typeof event.data === "undefined") return;
          if (event.data.size === 0) return;
          localAudioChunks.push(event.data);
        };
        setAudioChunks(localAudioChunks);
      }

    } catch (error) {
      console.log('startRecording', error)
    }
  }

  const stopRecording = () => {
    setRecording(false)
    if (intervalRecording)
      clearInterval(intervalRecording)

    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {

      const audioBlob = new Blob(audioChunks, { type: mimeType });

      const audioUrl = URL.createObjectURL(audioBlob);

      const formData = new FormData();
      formData.set('file', audioBlob);
      formData.set('mediaId', 'audio.mp3')

      setAudioFile(formData)
      setAudio(audioUrl);
      setAudioChunks([]);
    }
  }

  useEffect(() => {
    if (countRecording === 100) {
      stopRecording();
    }
  }, [countRecording])

  return (
    <div className="w-full flex items-center h-6">
      <div className="relative w-9/12 pl-2">
        <span className="h-1 bg-[#94a3b8] w-full rounded" />
        <span className="absolute h-1 bg-[#000] top-0 left-0 rounded"
          style={{
            width: `${countRecording}%`,
            transition: 'width 1s',
            transitionTimingFunction: 'linear'
          }} />
      </div>
      <div className="w-2/12 text-center">
        {/* 00:00 */}
        {getMinutes(countRecording)}
      </div>
      {/* <div> */}

      {recording ? (
        <button onClick={stopRecording}>
          <img src={pause} alt={'pause'} />
        </button>)
        :
        (<button onClick={startRecording}>
          <img src={playIcon} alt={'play'} />
        </button>)
      }

      {/* </div> */}
    </div>
  )
}