import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import Zoom from 'react-reveal/Zoom';
// import EditPanelContent from './edit-panel-content.jsx'
import { callApi } from '../../../helper/call-api';
import {
  Accept,
  approve,
  boxArrow,
  mainavatar,
  needInfoN,
  Reject,
  rejected,
  Waiting,
  ApproveFontAwesome,
  RejectFontAwesome,
  NeedInfoFontAwesome,
} from '../../../static/image';
import BodyActivity from './body-activity';
import BodyInformation from './body-infomartion';
import { ToastMessageContext } from '../../../context/toast-context';
import './approval-information-panel.scss';
import BodyFile from './body-file';

export default function ApprovalInformationPanel(props) {
  const {
    isMyRequest = false,
    visible,
    setVisible,
    requestId,
    type,
    setIsShowFeedbackModal,
  } = props;
  const [headerData, setHeaderData] = useState({});
  const [footerData, setFooterData] = useState({});
  const [infoData, setInfoData] = useState({ actionName: '' });
  const [actionId, setActionId] = useState({ actionName: '' });
  const [bodyData, setBodyData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const tabTypes = ['Information', 'Activity', 'Files'];
  const [tabType, setTabType] = useState(tabTypes && tabTypes[0]);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const tabRenderComponent = [
    {
      type: 'Information',
      component: (key) => (
        <BodyInformation
          key={key}
          bodyData={bodyData}
          requestId={requestId}
          infoData={infoData}
          actionId={actionId}
        />
      ),
    },
    {
      type: 'Activity',
      component: (key) => (
        <BodyActivity key={key} activityData={activityData} requestId={requestId} />
      ),
    },
    { type: 'Files', component: (key) => <BodyFile key={key} requestId={requestId} /> },
  ];

  useEffect(() => {
    function getData() {
      const res = callApi({
        method: 'get',
        url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/request/${requestId}`,
      });
      res
        .then((res) => {
          if (res.data.message) {
            const dataApi = res.data.data;
            const infoData = JSON.parse(dataApi.approvalRequest.changeContent);
            setInfoData(infoData);
            setActionId(dataApi.approvalRequest.actionId);
            const listKeyOfBodyData = Object.keys(infoData);
            const data = listKeyOfBodyData.map((key) => {
              return {
                key: key,
                value: infoData[key],
              };
            });
            const stages = [];
            dataApi.requestHistoryStage.forEach((stage) => {
              const curStage = {
                name: stage.name,
                users: [],
              };
              stage.processStageApprover.forEach((data) => {
                const { approver, processStageApproverResult } = data;
                const user = {
                  photoUrl: approver?.photoURL ? approver?.photoURL : mainavatar,
                  status: processStageApproverResult?.status
                    ? processStageApproverResult.status
                    : '',
                };
                curStage.users.push(user);
              });
              stages.push(curStage);
            });
            setActivityData(dataApi.requestHistoryStage);
            setBodyData(data);
            setFooterData({
              stages: stages,
              status: dataApi.status ? dataApi.status : '',
            });
            const { approvalRequest } = dataApi;
            setHeaderData({
              requestName: approvalRequest?.name ? approvalRequest.name : 'Request Name',
              requestId: approvalRequest?.id ? approvalRequest.id : 'Request Id',
              userPhotoUrl: approvalRequest?.userRequest?.photoURL
                ? approvalRequest.userRequest.photoURL
                : mainavatar,
            });
          } else {
            setToastMessage({
              status: 'error',
              title: 'Get Request Failed',
              message: res.data.message,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Get Request Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Approval Information Error', error);
        });
    }
    getData();
  }, [visible]);

  const handleIconAproval = (isApproval) => {
    let iconAproval;
    if (isApproval === 'approve') {
      iconAproval = Accept;
    } else if (isApproval === 'reject') {
      iconAproval = Reject;
    } else {
      iconAproval = Waiting;
    }
    return iconAproval;
  };

  const handleOverallStatus = (status) => {
    const result = {
      color: '',
      text: '',
      backgroundColor: '',
    };
    if (status.toLowerCase() === 'approve') {
      result.color = '#27AE60';
      result.text = 'Approved';
      result.backgroundColor = '#AEE9D1';
    } else if (status.toLowerCase() === 'reject') {
      result.color = '#FD5749';
      result.text = 'Rejected';
      result.backgroundColor = '#FED3D1';
    } else {
      result.color = '#979797';
      result.text = 'Pending';
      result.backgroundColor = '#EDEEEF';
    }

    return result;
  };

  const onApproveClick = (status) => {
    // status might be "approve" || "reject" || "need info"
    setVisible(false);
    setIsShowFeedbackModal((pre) => {
      return {
        isVisible: true,
        status: status,
      };
    });
  };

  return (
    <Zoom bottom duration={700} when={visible}>
      <div
        className={clsx(
          'approval-information-panel',
          visible ? 'pointer-events-auto' : 'pointer-events-none hidden',
        )}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center flex-auto w-32">
            <img
              className="w-8 h-8 mr-4 rounded-full"
              alt="approval"
              src={headerData.userPhotoUrl}
            />
            <div>
              <p className="datatable-name text-sm" style={{ color: '#212B36' }}>
                {headerData.requestName}
              </p>
              <p className="datatable-code text-xs" style={{ color: '#006D41' }}>
                {headerData.requestId}
              </p>
            </div>
            <img className="pl-4" src={boxArrow} alt="button" />
          </div>
          <button onClick={() => setVisible(false)}>
            <Close />
          </button>
        </div>
        <hr className="mt-2" />
        <div className="flex justify-between">
          <div className="pt-0">
            {tabTypes.map((type, key) => (
              <button
                className={clsx(
                  'approval-information-panel--tab h-8 none-after',
                  type === tabType && 'approval-information-panel--tab_active',
                )}
                key={key}
                onClick={() => setTabType(type)}
              >
                {type}
              </button>
            ))}
          </div>
          {type === 'In Progress' && !isMyRequest && (
            <div className="flex mt-2 gap-1">
              <div
                className="icons cursor-pointer flex flex-col items-center justify-center"
                onClick={() => {
                  onApproveClick('approve');
                }}
              >
                <div className="w-6 h-6 bg-[#008060] flex items-center justify-center rounded-full">
                  <ApproveFontAwesome />
                </div>
                <figcaption className="text-[8px]">Approve</figcaption>
              </div>
              <div
                className="icons cursor-pointer flex flex-col items-center justify-center"
                onClick={() => {
                  onApproveClick('reject');
                }}
              >
                <div className="w-6 h-6 bg-[#fd5749] flex items-center justify-center rounded-full">
                  <RejectFontAwesome />
                </div>
                <figcaption className="text-[8px]">Reject</figcaption>
              </div>
              <div
                className="icons cursor-pointer flex flex-col items-center justify-center"
                onClick={() => {
                  onApproveClick('need info');
                }}
              >
                <div className="w-6 h-6 bg-[#f2c94c] flex items-center justify-center rounded-full">
                  <NeedInfoFontAwesome />
                </div>
                <figcaption className="text-[8px]">Need Info</figcaption>
              </div>
            </div>
          )}
        </div>
        <div className="h-[55vh] overflow-auto">
          {tabRenderComponent.map(
            (item, key) => item.type === tabType && item.component(key),
          )}
        </div>
        <Footer
          footerData={footerData}
          handleIconAproval={handleIconAproval}
          handleOverallStatus={handleOverallStatus}
        />
      </div>
    </Zoom>
  );
}

// const BodyFile = ({ data }) => {
//   return (
//     <div className="">
//       <h1>Files</h1>
//     </div>
//   );
// };

const Close = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 1.4L14.6 0L8 6.6L1.4 0L0 1.4L6.6 8L0 14.6L1.4 16L8 9.4L14.6 16L16 14.6L9.4 8L16 1.4Z"
        fill="#2F80ED"
      />
    </svg>
  );
};

const DataProcessStatus = ({ footerData, handleIconAproval, handleOverallStatus }) => {
  const { text, color, backgroundColor } = handleOverallStatus(footerData.status);
  return (
    <div
      style={{ color: color, borderColor: color, backgroundColor: backgroundColor }}
      className="flex-auto items-center justify-end relative text-center mt-2 pt-2 pb-2 px-0 rounded-full"
    >
      <img
        src={handleIconAproval(footerData.status)}
        className={clsx(
          'w-4 h-4 absolute left-3',
          footerData.status === 'approve' ? 'top-2.5' : 'top-3',
        )}
      />
      <p className="pl-3 text-sm">{text}</p>
    </div>
  );
};

const Footer = ({ footerData, handleIconAproval, handleOverallStatus }) => {
  return (
    <div className="absolute bottom-0 left-7 right-7">
      {Object.keys(footerData).length === 0 ? (
        <div></div>
      ) : (
        <div className="">
          <hr className="mb-3" />
          <div className="flex justify-between">
            <div className="flex">
              {footerData.stages.map((stage, idx) => {
                return (
                  <div key={idx} className="flex-row mr-4">
                    <p className="text-xs" style={{ color: '#637381' }}>
                      {stage.name}
                    </p>
                    <div className="flex justify-center mt-2">
                      {stage.users.map((user, key) => {
                        return (
                          <div key={key} className="relative mb-5">
                            <img
                              src={user.photoUrl}
                              alt="avatar"
                              className="w-8 h-8 mr-2 rounded-full"
                            />
                            <img
                              src={handleIconAproval(user.status)}
                              className="w-4 h-4 absolute bottom-0 right-0 z-2"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="w-28 mb-2">
              <div className="text-xs text-center" style={{ color: '#637381' }}>
                Overall Status
              </div>
              <DataProcessStatus
                footerData={footerData}
                handleIconAproval={handleIconAproval}
                handleOverallStatus={handleOverallStatus}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
