import { useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { callApi } from '@helper/call-api';
import { pdf, uploadFile, word, xls } from '@static/image';
import { ToastMessageContext } from 'context/toast-context';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';

const BodyFile = (props) => {
  const { requestId } = props;
  const [listFile, setListFile] = useState([]);
  const [refeshData, setRefeshData] = useState(0);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    accept: '.pdf,.doc,.docx,.xls , .xlsx, .xlsm, .xltx, .xltm',
    onDrop: async (files) => {
      const rawQuery = [];
      await Promise.all(
        files.map(async (file) => {
          const formData = new FormData();
          formData.set('file', file);
          formData.set('mediaId', file.name);
          await centralAdminApi.uploadFile(formData)
            .then((response) => {
              const { data } = response;
              if (data.success) {
                rawQuery.push({ fileURL: data.data, fileName: file.name });
              } else {
                setToastMessage({
                  status: 'error',
                  title: 'Upload File Failed',
                  message: response.data.message,
                });
                setIsShowToastMessage(true);
              }
            })
            .catch((error) => {
              setToastMessage({
                status: 'error',
                title: 'Upload File Failed',
                message: error.response?.data?.message || error,
              });
              setIsShowToastMessage(true);
              console.log('Upload to server error', error);
            });
        }),
      ).then(() => {
        if (rawQuery.length > 0) {
          const saveFile = callApi({
            method: 'post',
            url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/request/files`,
            data: { requestId: requestId, fileURLs: [...rawQuery] },
          });
          saveFile
            .then((response) => {
              const { data } = response;
              if (data.success) {
                setRefeshData((prevState) => prevState + 1);
                setToastMessage({
                  status: 'success',
                  title: 'Upload File Successfully',
                  message: data.message,
                });
                setIsShowToastMessage(true);
              } else {
                setToastMessage({
                  status: 'error',
                  title: 'Upload File Failed',
                  message: data.message,
                });
                setIsShowToastMessage(true);
              }
            })
            .catch((error) => {
              setToastMessage({
                status: 'error',
                title: 'Upload File Failed',
                message: error.response?.data?.message || error,
              });
              setIsShowToastMessage(true);
              console.log('Upload URL file to server error', error);
            });
        }
      });
    },
  });
  const FileTypeImage = new Map([
    ['pdf', pdf],
    ['word', word],
    ['docx', word],
    ['xls', xls],
    ['xlsx', xls],
    ['xlsm', xls],
    ['xltx', xls],
    ['xltm', xls],
  ]);
  const campus = useStorage((state) => state.currentCampus);

  useEffect(() => {
    callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/request/files/list/${requestId}`,
      params: { campusId: campus?.isCentral ? undefined : campus?.id },
    })
      .then((res) => {
        if (res.data.success) {
          if (res.data.data.objects?.length >= 0) {
            setListFile(res.data.data.objects);
          }
        } else {
          setToastMessage({
            status: 'error',
            title: 'Fetch Data Failed',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Get task information error', error);
      });
  }, [refeshData]);

  const files = listFile.map((file) => {
    const date =
      JSON.stringify(file?.dateCreated).substr(1, 10) + ' By ' + file?.createdBy?.name;
    const typeFile = file?.fileName?.split('.').pop();

    const handleDelete = (file) => {
      const updateFile = callApi({
        method: 'delete',
        url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/request/files/${file.id}`,
        params: { campusId: campus?.isCentral ? undefined : campus?.id },
      });
      updateFile
        .then((response) => {
          const { data } = response;
          if (data.success) {
            setRefeshData((prevState) => prevState + 1);
          } else {
            setToastMessage({
              status: 'error',
              title: 'Upload File Failed',
              message: response.data.message,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Upload File Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Upload URL file to server error', error);
        });
    };
    return (
      <li key={file?.id} className="flex items-center mt-0.5">
        <img
          src={FileTypeImage.get(typeFile) ? FileTypeImage.get(typeFile) : pdf}
          alt={typeFile}
          className="w-10 h-10 mr-3"
        />
        <div className="mb-2 w-full">
          <a
            className="text-xs text-main-black font-semibold mb-0.5"
            href={file?.fileURL}
            target="_blank"
            rel="noreferrer"
          >
            {file?.fileName}
          </a>
          <p className="text-second-gray text-10px">{date}</p>
        </div>
        <button className="w-10 h-10 ml-3" onClick={() => handleDelete(file)}>
          <Close />
        </button>
      </li>
    );
  });

  return (
    <>
      <div className="upload-file-container-dropzone mt-4 mb-2.5">
        <div id="dropzone" {...getRootProps({ className: 'dropzone h-[8rem]' })}>
          <input {...getInputProps()} />
          <img src={uploadFile} alt="uploadFile" className="h-[8rem]" />
        </div>
      </div>
      <div className="ml-5">
        <p className="font-semibold text-xl mb-2 text-center">
          {isDragActive
            ? 'Please drop PDF, DOC, DOCX , XLS , XLSX, XLSM, XLTX, XLTM'
            : 'Drag and drop or add files here'}
        </p>
        <p className="text-sm text-second-gray text-center">
          Upload, comment and review all files in this item to easily collaborate in
          context
        </p>
        <button
          className="edit-task-add-item mt-2"
          onClick={() => {
            const dropzone = document.getElementById('dropzone');
            dropzone.click();
          }}
        >
          <div className="edit-task_btn-plus">
            <span>+</span>
          </div>
          Add File
        </button>
      </div>

      {listFile.length > 0 && (
        <div className="ml-5">
          <aside>
            <h4 className="text-sm font-semibold text-main-black mt-0.5">
              Uploaded Files
            </h4>
            <ul className="overflow-x-auto overflow-y-auto h-[24vh]">{files}</ul>
          </aside>
        </div>
      )}
    </>
  );
};

export default BodyFile;

const Close = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 1.4L14.6 0L8 6.6L1.4 0L0 1.4L6.6 8L0 14.6L1.4 16L8 9.4L14.6 16L16 14.6L9.4 8L16 1.4Z"
        fill="#2F80ED"
      />
    </svg>
  );
};
