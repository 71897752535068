import { useState } from "react";
import clsx from "clsx";
import { logos_whatsapp_icon } from "../image";
import convertDateToFormat from "@helper/convertDateToFormat";
import { getPrefixUserName } from "@helper/application";
import DefaultAvatar from "@components/default-avatar";

const SingleWhatsapp = (props) => {
  const { data, objParent } = props
  const [isReadMore, setIsReadMore] = useState(false)

  const from = objParent[data.from]
  // const to = objApplicationParents[data.to]
  const time = convertDateToFormat(new Date(data.time * 1000), 'LT').replace(' ', '')
  let parentName = 'Bloomingdale Support'
  if (from?.relationship) {
    parentName = `${getPrefixUserName(from?.relationship)}${from?.fullName || ''} (${from?.relationship || ''})`;
  }

  let receiver
  if (data?.to && objParent[data.to])
    receiver = objParent[data.to]?.fullName
  if (data?.to === '84935708902') receiver = 'Bloomingdale Support'

  const dataMessage = data?.data ? JSON.parse(data?.data) : {}

  const onReadMore = () => {
    setIsReadMore(prev => !prev)
  }

  const messageDisplay = () => {
    if (!dataMessage?.content) return ''
    if (dataMessage.content.length <= 182) {
      return dataMessage.content;
    } else {
      if (isReadMore) return dataMessage.content;
      return `${dataMessage.content.slice(0, 180)}...`;
    }
  }

  const renderMessageText = () => {
    return (
      <div className="ml-10 relative">
        <p className="text-xs text-[#808284] overflow-hidden duration-1000 h-fit">
          {messageDisplay()}
        </p>
        {dataMessage?.content?.length > 182 && (
          <button
            className={clsx(
              'text-[#2563eb] absolute text-xs font-medium right-0',
              !isReadMore && 'bottom-0',
            )}
            onClick={onReadMore}
          >
            {isReadMore ? 'Show Less' : 'Read More'}
          </button>
        )}
      </div>
    );
  }

  const renderMessageAudio = () => {
    return null
  }

  const renderMessageReaction = () => {
    return null
  }

  const mapMessageType = {
    text: renderMessageText,
    audio: renderMessageAudio,
    reaction: renderMessageReaction,
  };

  return (
    <div className="mb-3">
      <div className="flex">
        {from?.photoURL ? (
          <img
            src={from.photoURL}
            className="rounded-full w-8 aspect-square mr-2"
            alt="avatar"
          />
        ) : (
          <DefaultAvatar
            title={from?.fullName || parentName}
            className="rounded-full w-8 aspect-square mr-2"
          />
        )}

        <div className="flex items-center text-xs font-medium">
          <p className="text-[#212B36] max-w-[20rem] mr-2">{parentName}</p>
          <span className="w-[5px] aspect-square rounded bg-[#8C9094] mr-2" />
          <p className="text-[#8C9094] max-w-[4rem] mr-2">{time}</p>
          <span className="w-[5px] aspect-square rounded bg-[#8C9094] mr-2" />
          <p className="text-[#8C9094] max-w-[3rem] mr-2">Via</p>
          <img src={logos_whatsapp_icon} alt="whatsapp-icon" className="mr-2" />
          <p className="text-[#8C9094] max-w-[4rem]">WhatsApp</p>
        </div>
      </div>
      {dataMessage?.type && mapMessageType[dataMessage.type] ? mapMessageType[dataMessage.type]() : null}

      <p className="text-[#24272B] text-[10px] font-bold ml-10 mt-2">
        To: {receiver}
      </p>
    </div>
  );
}

export default SingleWhatsapp;