import React, { memo } from 'react';
import { backarrow, details } from '@pages/bloomingdale/image';
import StudentInfo from './student-info/student-info';
import ParentInfo from './parent-information/parent-information';
import AddressInfo from '@pages/bloomingdale/details/first-step/address-information/address-information';
import FooterDetail from '../footer-detail/footer-detail';

const FirstStep = memo((props) => {
  const {
    info,
    enquiryId,
    setInfo,
    listField,
    parentUserIdChosen,
    handleChange,
    handleAdd,
    handleCancel,
    handleNext,
    handleRemove,
  } = props;
  const { studentInfo, parentInfo, addressInfo } = info;

  return (
    <section className='classForScroll'>
      <div className="w-full flex items-start pl-20 pr-20">
        <div className="flex w-full justify-between">
          <div className="w-[32.25rem]">
            <h3 className="font-semibold text-lg mb-10 text-main-blue">
              Application Form | General Info
            </h3>
            <div>
              <div className="flex items-center pl-5 font-semibold mb-4 h-[3rem] bg-[#f5fcf9]">
                Student Information
              </div>
              <div className="space-left">
                <StudentInfo
                  info={studentInfo}
                  listField={listField.student}
                  handleChange={handleChange}
                />
              </div>
            </div>
            <div>
              <div className="flex items-center pl-5 font-semibold mb-4 h-[3rem] bg-[#f5fcf9]">
                Family Members Details
              </div>
              <div className="space-left">
                {/* {parentInfo.length !== 0 && ( */}
                <ParentInfo
                  info={parentInfo}
                  setInfo={setInfo}
                  listField={listField.parent}
                  parentUserIdChosen={parentUserIdChosen}
                  handleAdd={handleAdd}
                  handleChange={handleChange}
                  handleRemove={handleRemove}
                />
                {/* )} */}
              </div>
            </div>

            <div>
              <div className="flex items-center pl-5 font-semibold mb-4 h-[3rem] bg-[#f5fcf9]">
                Address Information
              </div>
              <div className="space-left">
                {addressInfo.length !== 0 && (
                  <AddressInfo
                    info={addressInfo}
                    listField={listField.address}
                    handleChange={handleChange}
                    handleAdd={handleAdd}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="pr-8 pt-40">
            <img
              src={details}
              style={{ width: '28.125rem', height: '25rem' }}
              alt="persona"
            />
          </div>
        </div>
      </div>
      <FooterDetail
        handleCancel={handleCancel}
        handleNext={handleNext}
      />
    </section>
  );
});
FirstStep.displayName = 'FirstStep';
export default FirstStep;
