import axios from "axios";

function useMap() {
  const getPlaces = async ({ latitude, longitude }) => {
    try {
      const res = await axios({
        method: 'get',
        url: 'https://maps.googleapis.com/maps/api/geocode/json',
        params: {
          latlng: `${latitude}, ${longitude}`,
          key: process.env.REACT_APP_MAPS_API_KEY,
        },
      });
      return { data: res }
    } catch (error) {
      return { error }
    }
  }

  return {
    getPlaces
  }
}

export default useMap;