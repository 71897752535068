import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { selectGroup } from '../../../../entities/select-style';
import { cancel } from '../../../../static/image';
import { Button, Input, SelectGroup } from '../../../../stories';
import './add-document-setting-modal.scss';

export default function AddDocumentSettingModal({ open, isOpen, setDocumentSettings }) {
  const [tableOpen, setTableOpen] = useState(false);
  const [name, setName] = useState('');
  const [category, setCategory] = useState({ value: 'Admissions', label: 'Admissions' });

  const styles = {
    closeBtn: {
      backgroundColor: '#fff',
      color: '#202223',
      border: '1px solid #E1E3E5',
      marginRight: '0.5rem',
    },
    createBtn: { backgroundColor: '#008060' },
  };

  const allClose = () => {
    setTableOpen(false);
  };

  const handleOnClickCreate = () => {
    setDocumentSettings((prev) => [
      ...prev,
      {
        isNew: true,
        name: name,
        usedIn: category.value,
        status: 'Disabled',
        documentType: 'Identification',
        atLeastDocument: 1,
        maximumDocument: 2,
        multipleDocuments: 'Allowed',
        isActiveLockFieldsApproval: true,
        fileRequirement: [],
      },
    ]);
    isOpen();
  };

  return (
    <div
      className={
        open
          ? 'document-setting-modal document-setting-modal-active'
          : 'document-setting-modal'
      }
    >
      <div
        className="document-setting-modal_bg"
        onClick={() => {
          isOpen();
        }}
      />
      <div
        className="document-setting-modal-card"
        onClick={tableOpen === true ? allClose : null}
      >
        <div className="document-setting-modal-card-header">
          <h2 className="text-2xl ml-6">Add Document Setting</h2>
          <button
            className="mr-4"
            onClick={() => {
              isOpen();
            }}
          >
            <img src={cancel} alt="closed modal" />
          </button>
        </div>
        <div className="document-setting-modal-card-row">
          <div className="document-setting-modal-card-row_label"></div>
          <div className="document-setting-modal-card-row_selects">
            <SelectGroup
              classNameGroup="ml-10 mb-7 w-80"
              title="Select Category"
              value={category}
              onChange={(e) => setCategory(e)}
              isMulti={false}
              styles={selectGroup}
            />

            <div className="ml-10 w-80">
              <Input
                label="Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                customStyle={{
                  paddingLeft: '1rem',
                  width: '100%',
                  height: '2.65rem',
                  fontSize: '1rem',
                  borderRadius: '0.5rem',
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end pt-4 pr-4">
          <Button
            text="Close"
            customStyle={styles.closeBtn}
            onClick={() => {
              isOpen();
            }}
          />
          <Button
            text="Create"
            customStyle={styles.createBtn}
            onClick={handleOnClickCreate}
          />
        </div>
      </div>
    </div>
  );
}

AddDocumentSettingModal.propTypes = {
  open: PropTypes.bool,
  isOpen: PropTypes.func,
};
