import React, { useContext, useEffect, useState } from 'react'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import StaticDatePicker from '@mui/lab/StaticDatePicker'
import { TextField } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Zoom, Fade } from 'react-reveal'
import clsx from 'clsx'
import { group } from '../image'
import ButtonBottom from "@components/application-panel/sub-components/button-bottom";
import SingleSchedule from "@components/application-panel/sub-components/single-schedule";
import AddEvent from './add-event'
import { checkErrorApiFetch } from '@utils/check-error/api-error'
import { ToastMessageContext } from 'context/toast-context'
import PickersDay from '@mui/lab/PickersDay'
import Badge from '@mui/material/Badge';
import convertDateToFormat from '@helper/convertDateToFormat'
import authApi from 'api/auth'
import admissionApi from 'api/admission'

const theme = createTheme({
  palette: {
    primary: { main: '#27C59A' }
  }
})

const CalendarPick = (props) => {
  const { visible, setVisible, enquiry, parents } = props
  const [value, setValue] = useState(new Date())
  const [schedules, setSchedules] = useState([])
  const [scheduleByDate, setScheduleByDate] = useState({})
  const [refresh, setRefresh] = useState(false)
  const [users, setUsers] = useState([])
  const { toastMessage, setToastMessage, isShowToastMessage, setIsShowToastMessage } =
    useContext(ToastMessageContext);

  const closeAddEvent = () => {
    setVisible(prev => ({ ...prev, addEvent: false }))
  }

  const onToggleAddEvent = () => {
    setVisible(prev => ({ ...prev, addEvent: !prev?.addEvent }))
  }

  const fetchSchedule = async (enquiry, signal) => {
    const res = await admissionApi.getListAdmissionCalendar({
      campusId: enquiry?.campusId,
      enquiryId: enquiry?.id,
      signal
    })

    if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, '', false)) {
      const data = res?.data?.data
      if (!data) return
      const scheduleDate = {}
      for (let schedule of data) {
        const { date, time } = schedule
        const newDate = convertDateToFormat(date, 'MM/DD/YYYY')
        if (!scheduleDate[newDate]) {
          scheduleDate[newDate] = [time]
        } else {
          scheduleDate[newDate].push(time)
        }
      }
      const tmp = data.sort((a, b) => {
        return new Date(`${convertDateToFormat(a.date, 'MM/DD/YYYY')} ${a.time}`).getTime() - new Date(`${convertDateToFormat(b.date, 'MM/DD/YYYY')} ${b.time}`).getTime()
      })
      setSchedules(tmp)
      setScheduleByDate(scheduleDate)
    } else {
      setToastMessage({
        status: 'error',
        title: 'Fetch Schedule Failed',
      });
      setIsShowToastMessage(true);
    }
  }

  useEffect(() => {
    const controller = new AbortController();
    if (enquiry?.id)
      fetchSchedule(enquiry, controller.signal)
    return () => {
      controller.abort();
    }
  }, [enquiry, refresh])

  const fetchUser = async () => {
    const res = await authApi.getUserOfCompanyOrCampus({ excludeRoleNames: 'Student;Parent', campusId: enquiry.campusId })
    if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, '', false)) {
      const tmp = res.data.data.objects.map(item => ({ info: { ...item }, value: item.id, label: item.name }))
      setUsers(tmp)
    } else {
      setToastMessage({
        status: 'error',
        title: 'Fetch Host Failed',
        message: res?.data?.message || '',
      });
      setIsShowToastMessage(true);
    }
  }

  useEffect(() => {
    if (enquiry?.id)
      fetchUser()
  }, [enquiry])

  const renderDay = (date, selectedDays, pickersDayProps,) => {
    const isHighlight = convertDateToFormat(date, 'MM/DD/YYYY') in scheduleByDate
    return (
      <Badge key={date.toString()}
        overlap="circular"
        variant="dot"
        invisible={!isHighlight}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          "& .MuiBadge-badge": {
            backgroundColor: '#F2994A',
            // right: '19.2px',
          }
        }}
      >
        <PickersDay {...pickersDayProps} day={date} />
      </Badge>
    )
  }


  return (
    <>
      <Zoom bottom duration={700} when={visible.calendar}>
        <div
          className={clsx('windows-field', visible.calendar ? 'pointer-events-auto' : 'pointer-events-none hidden')}>
          <div className='h-full'>
            <div className='flex items-center justify-between pt-4 pl-5 pr-5'>
              <div className='flex item'>
                <div
                  className='w-10 h-10 mr-3 flex items-center justify-center rounded-lg'
                  style={{ background: '#FFE7D9' }}
                >
                  <img src={group} alt='' />
                </div>
                <p className='font-semibold mt-2.5'>Calendar</p>
              </div>
              <button className='bg-main-blue w-6 h-6 text-white text-xl flex items-center justify-center rounded-full'
                onClick={onToggleAddEvent}
              >
                +
              </button>
            </div>
            <div style={{ height: 300, overflow: 'hidden' }}>
              <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <StaticDatePicker
                    displayStaticWrapperAs='desktop'
                    openTo='day'
                    minDate={new Date(2019, 11, 24)}
                    maxDate={new Date(2025, 11, 24)}
                    value={value}
                    shouldDisableYear={null}
                    onChange={(newValue) => {
                      setValue(newValue)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    renderDay={renderDay}
                  />
                </LocalizationProvider>
              </ThemeProvider>
            </div>
            <p className='text-base pl-5 mb-2'>Schedule</p>
            <div className='w-full flex items-center flex-col'>
              {schedules ? (
                <div className='w-full flex items-center flex-col px-5 pt-3 overflow-y-auto'
                  style={{ height: 'calc(100vh - 31.75rem)' }}>
                  {schedules?.filter(item => convertDateToFormat(item.date, 'MM/DD/YYYY') === convertDateToFormat(value, 'MM/DD/YYYY'))?.map(item => {
                    return <SingleSchedule key={item.id} className={'mb-5'} data={item} />
                  })}
                </div>
              ) : (
                <p className='mb-2 text-sm text-second-gray'>Nothing on schedule.</p>
              )}
            </div>
          </div>
          <ButtonBottom titleButton={'Schedule an Activity'} onClick={onToggleAddEvent} />
        </div>
      </Zoom>

      <Fade right when={visible.addEvent} duration={500}>
        <div className={clsx('shadow bg-white rounded-md', visible.addEvent ? "block" : "hidden pointer-events-none")}
          style={{
            width: '31.7rem',
            // height: '40.75rem',
            position: "fixed",
            zIndex: "20",
            top: `calc(50% - 18.375rem)`,
            left: `calc(50% - 15.85rem)`,
            boxSizing: 'border-box'
          }}
        >
          <AddEvent closeAddEvent={closeAddEvent}
            users={users}
            parents={parents}
            setRefresh={setRefresh}
            enquiry={enquiry}
            scheduleByDate={scheduleByDate} />
        </div>
      </Fade>
    </>
  )
}

export default CalendarPick
