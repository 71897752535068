import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import Zoom from 'react-reveal/Zoom';

import './edit-panel-new.scss';
import EditPanelContent from '@components/edit-panel/edit-panel-content';

export default function EditPanelNew({
  visible,
  setVisible,
  draftsList,
  setIsRenderDraft = () => {},
  setDraftId = () => {},
}) {
  const tabTypes = ['Condenced Log', 'Detailed Log', 'Versions'];
  const [tabType, setTabType] = useState(tabTypes && tabTypes[0]);

  const [draft, setDraft] = useState([]);
//   useEffect(() => {
//     if (draftsList?.length > 0) {
//       const date = handleDateTime(draftsList[draftsList.length - 1].dateCreated);
//       const draftConvert = [
//         {
//           date,
//           cards: ((data) => {
//             return data.map((o) => {
//               const info = JSON.parse(o.info);
//               return {
//                 draftId: o?.id,
//                 title: info.name || `Draft on ${date}`,
//                 persona: o?.createdBy?.name,
//                 image: o?.createdBy?.photoURL,
//                 edits: [...info.changes],
//               };
//             });
//           })(draftsList),
//         },
//       ];
//       setDraft(draftConvert);
//     }
//   }, [draftsList]);

  const handleDateTime = (date) => {
    if (!date) {
      return '';
    }
    const data = new Date(date).toDateString();
    return data.slice(4, 10) + ', ' + data.slice(11);
  };

  const tabRenderComponent = [
    {
      type: 'Condenced Log',
      component: (key) => (
        <CondencedLog
          key={key}
          draft={draftsList}
          setDraftId={setDraftId}
          setIsRenderDraft={setIsRenderDraft}
        />
      ),
    },
    { type: 'Detailed Log', component: (key) => <div key={key} /> },
    { type: 'Versions', component: (key) => <div key={key} /> },
  ];

  return (
    <Zoom bottom duration={700} when={visible}>
      <div
        className={clsx(
          'edit-panel-new',
          visible ? 'pointer-events-auto' : 'pointer-events-none hidden',
        )}
      >
        <div className="flex items-center justify-between">
          <h1 className="font-medium text-4xl">Edits</h1>
          <button onClick={() => setVisible(false)}>
            <Close />
          </button>
        </div>
        <div>
          {tabTypes.map((type, key) => (
            <button
              className={clsx(
                'edit-panel-new--tab none-after',
                type === tabType && 'edit-panel-new--tab_active',
              )}
              key={key}
              onClick={() => setTabType(type)}
            >
              {type}
            </button>
          ))}
        </div>
        {tabRenderComponent.map(
          (item, key) => item.type === tabType && item.component(key),
        )}
      </div>
    </Zoom>
  );
}

const CondencedLog = ({ draft, setIsRenderDraft, setDraftId }) => {
  return (
    <div
      className="mt-4 overflow-auto w-full"
      style={{ height: 'calc(100vh - 14rem)', width: '26.8rem' }}
    >
      {draft.length > 0 &&
        draft.map((item, key) => (
          <React.Fragment key={key}>
            <p
              className="text-xs font-medium mb-3"
              style={{ color: 'rgba(99, 115, 129, 0.6)' }}
            >
              {item.date}
            </p>
            {item.cards.map((card, idx) => (
              <EditPanelContent
                key={idx}
                card={card}
                setDraftId={setDraftId}
                setIsRenderDraft={setIsRenderDraft}
              />
            ))}
          </React.Fragment>
        ))}
    </div>
  );
};

const Close = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 1.4L14.6 0L8 6.6L1.4 0L0 1.4L6.6 8L0 14.6L1.4 16L8 9.4L14.6 16L16 14.6L9.4 8L16 1.4Z"
        fill="#2F80ED"
      />
    </svg>
  );
};
