import React, { useState, useRef, useCallback, useLayoutEffect, memo } from 'react';

import FormPageBreak from '../components/form-PageBreak';
import FormParagraph from '../components/form-Paragraph';
import FormComponentUser from '../components/FormComponentUser';
import {
  categoryMap,
  variantName,
  componentCustom,
  componentSpecial,
  componentFieldName,
  componentSpecialName,
} from '../utils';
import './style.scss';

const RenderSingleForm = memo((props) => {
  const { data, isEdit, refreshFromOutside, styleForm } = props;
  const { dataUi, info } = data;
  const [renderUi, setRenderUi] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const refDataUi = useRef();
  refDataUi.current = dataUi;

  const extractValue = useCallback(({ variationOf, e }) => {
    switch (variationOf) {
      case variantName.textArea:
        const val = e.target.value;
        return val;
      case variantName.photo:
        if (e.target.files?.[0]) {
          let selected = e.target.files[0];
          return selected;
        }
        break;
      case variantName.checkbox:
        return e?.target?.checked;
      default:
        return e;
    }
  }, []);

  const handleOnChange = useCallback(
    ({ index, variationOf, e }) => {
      const value = extractValue({ variationOf, e });
      if (variationOf === variantName.photo && !value) return;
      if (variationOf === variantName.datePicker) {
        info[index].value = String(value);
      } else info[index].value = value;
      setRefresh((prev) => prev + 1);
    },
    [extractValue, info],
  );

  const handleRenderSingleForm = useCallback(
    ({ dataUi, info }) => {
      if (!dataUi || dataUi.length === 0) return null;
      return dataUi.map((item, index) => {
        if (!item) return null;
        const { id, category, variationOf, name: componentName, traits, styles } = item;
        let attributes = {};
        attributes.key = id;
        attributes.id = id;
        if (componentName === componentFieldName.pageBreak) {
          attributes.formStyle = { height: '0px', borderWidth: '0px' };
          return <FormPageBreak {...attributes} />;
        }
        attributes.formStyle = styles;
        if (traits.length !== 0) {
          traits.forEach((trait) => {
            const { name, value } = trait;
            const val = value;
            if (componentName === componentFieldName.multiselect && name === 'options') {
              // handle for multiselect
              attributes[name] = val
                .split(';')
                .map((item) => ({ label: item, value: item }));
            } else attributes[name] = val;
          });
        }
        if (variationOf === variantName.datePicker) {
          const today = String(new Date());
          if (!info[index]?.value) info[index].value = today;
          attributes.pValue = info?.[index]?.value || today;
          attributes.psetValue = (e) => {
            handleOnChange({ index, variationOf, e });
          };
        } else {
          attributes.value = info?.[index]?.value;
          if (variationOf === variantName.checkbox)
            attributes.value = info?.[index]?.value || false;
          attributes.onChange = (e) => {
            handleOnChange({ index, variationOf, e });
          };
        }
        attributes.isEdit = isEdit;
        attributes.error = info?.[index]?.error;
        attributes.textForError = info?.[index]?.textForError;
        if (category === categoryMap.field) {
          if (componentName === componentFieldName.paragraph)
            return <FormParagraph {...attributes} />;
          return <FormComponentUser component={componentName} {...attributes} />;
        } else if (category === categoryMap.custom) {
          return componentCustom[componentName](attributes);
        } else if (category === categoryMap.special) {
          if (componentName === componentSpecialName.languageCard) {
            attributes.value = info?.[index].value;
          }
          return componentSpecial[componentName](attributes);
        }
      });
    },
    [isEdit, handleOnChange],
  );

  useLayoutEffect(() => {
    const UI = handleRenderSingleForm({ dataUi, info });
    setRenderUi(UI);
  }, [refresh, dataUi, info, refreshFromOutside]);

  if (!dataUi) return <div />;

  return (
    <div id="render-form" className="flex flex-wrap" style={styleForm}>
      {renderUi}
    </div>
  );
});
RenderSingleForm.displayName = 'RenderSingleForm';
export default RenderSingleForm;
