import clsx from 'clsx';
import { useState } from 'react';

import { ar, filter, search, sort } from './image';
import { asc, desc } from '@static/image';

import './tasks.scss';

const listDataSort = [
  { status: 'Name', value: 'name' },
  { status: 'Date Created', value: 'dateCreated' },
  { status: 'Date Updated', value: 'dateUpdated' },
  { status: 'Time Estimate', value: 'minutesEstimate' },
  { status: 'Due on Dependency', value: 'dueDateDependency' },
];

function NavbarTask(props) {
  const {
    searchValue,
    active,
    types,
    setPage = () => {},
    setActive = () => {},
    setListTasks = () => {},
    setIsSearch = () => {},
    setOrderBy = () => {},
    setOrderDirection = () => {},
    setIsSort = () => {},
    setSearchValue = () => {},
    setRefresh = () => {},
  } = props;
  const [sortValue, setSortValue] = useState('dateCreated');
  const [orderByValue, setOrderByValue] = useState('asc');
  const [isOpenModalSort, setIsOpenModalSort] = useState(false);

  const handleChangeInput = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const searchValueInput = e.target.value;
    if (searchValueInput.startsWith(' ')) {
      return;
    }
    setListTasks([]);
    setPage(0);
    setRefresh({});
    setSearchValue(e.target.value);
  };

  return (
    <>
      <div className="flex" style={{ width: '100%' }}>
        {types.map((type, idx) => (
          <button
            key={idx}
            onClick={() => setActive(type.title)}
            className={clsx(
              'tasks-page-tab',
              type.title === active && 'tasks-page-tab_active',
            )}
            style={{ alignItems: 'center' }}
          >
            <img src={type.svg} className="mr-2" alt="" />
            {type.title}
          </button>
        ))}
      </div>
      <div
        className="flex relative bottom-1 2xl"
        style={{ float: 'right', paddingRight: '2rem', marginLeft: 'auto' }}
      >
        <div className='flex items-start'>
          <img
            src={search}
            // style={{ maxWidth: '1.5rem' }}
            className="h-[100%] mr-2 cursor-pointer 2xl:mr-2 w-5 2xl:w-7"
            alt=""
          />
          <input
            value={searchValue}
            onChange={(e) => {
              handleChangeInput(e);
            }}
            onFocus={() => {
              setIsSearch(true);
              setIsOpenModalSort(false);
            }}
            onBlur={() => {
              setIsSearch(false);
            }}
            placeholder="Search"
            className="w-32 h-[1.875rem] text-base focus:outline-none"
          />
        </div>
        <div
          onClick={() => {
            setIsOpenModalSort((prev) => !prev);
          }}
          className="flex relative text-second-gray cursor-pointer bottom-1"
          style={{ alignItems: 'center', marginRight: '1rem' }}
        >
          <img
            alt=""
            src={sort}
            className="mr-1 2xl:mr-2 w-5 h-5 2xl:w-7 2xl:h-7 relative top-1 2xl:top-0 2xl:bottom-0.5"
          />
          <p className="relative top-0.5 text-base">Sort</p>
          {isOpenModalSort && (
            <div className="absolute top-[4rem] left-[-4rem] w-40 z-20 bg-[#fff] shadow-2xl rounded">
              <ul className="p-1">
                <li>
                  <div className="flex justify-around">
                    {/* <p
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOrderByValue('asc');
                        }}
                        className={clsx(
                          'w-[5rem] m-2 text-center rounded text-sm py-1',
                          orderByValue === 'asc'
                            ? 'border-2 border-indigo-600 text-[#404eed]'
                            : 'border-2 border-slate-400',
                        )}
                      >
                        ASC
                      </p> */}
                    <img
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOrderByValue('asc');
                        setSortValue('');
                      }}
                      className={clsx(
                        'w-[2rem] m-2 text-center rounded text-sm py-1',
                        orderByValue === 'asc' ? 'opacity-100' : 'opacity-30',
                      )}
                      src={asc}
                      alt="asc"
                    />
                    <img
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOrderByValue('desc');
                        setSortValue('');
                      }}
                      className={clsx(
                        'w-[2rem] m-2 text-center rounded text-sm py-1 rotate-90',
                        orderByValue === 'desc' ? 'opacity-100' : 'opacity-30',
                      )}
                      src={desc}
                      alt="desc"
                    />
                    {/* <p
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOrderByValue('desc');
                        }}
                        className={clsx(
                          'w-[5rem] m-2 text-center rounded text-sm py-1',
                          orderByValue === 'desc'
                            ? 'border-2 border-indigo-600 text-[#404eed]'
                            : 'border-2 border-slate-400',
                        )}
                      >
                        DESC
                      </p> */}
                  </div>
                </li>
                <li>
                  <hr />
                </li>
                {listDataSort.map((data, index) => {
                  return (
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSortValue(data.value);
                        setOrderBy(data.value);
                        setOrderDirection(orderByValue);
                        setIsOpenModalSort(false);
                        setIsSort((prev) => !prev);
                        setListTasks([]);
                        setPage(0);
                      }}
                      key={index}
                      className={clsx(
                        'm-2 rounded text-xs py-1',
                        data.value === sortValue ? 'text-[#404eed]' : '',
                      )}
                      style={{ color: '#333' }}
                    >
                      {data.status}
                    </li>
                  );
                })}
                <li>
                  <hr />
                </li>
                {/* <li>
                    <div className="flex justify-center">
                      <Button
                        className="text-[0.8rem]"
                        text="Cancel"
                        customStyle={{
                          ...toggleButtonStyles.btnWhite,
                          width: '7rem',
                          height: '1.5rem',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setIsOpenModalSort(false);
                          setSortValue('');
                          setOrderByValue('');
                          setIsSort((prev) => !prev);
                          setPage(0);
                          setListTasks([]);
                        }}
                      />
                      <Button
                        className="text-[0.8rem]"
                        text="Save"
                        customStyle={{
                          ...toggleButtonStyles.btnBlue,
                          width: '7rem',
                          height: '1.5rem',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOrderBy(sortValue);
                          setOrderDirection(orderByValue);
                          setIsOpenModalSort(false);
                          setIsSort((prev) => !prev);
                          setListTasks([]);
                          setPage(0);
                          // handleUpdateTaskGoal();
                        }}
                      />
                    </div>
                  </li> */}
              </ul>
            </div>
          )}
        </div>
        <div style={{ justifyContent: 'center', alignItems: 'center', height: '30px' }}>
          <div className="button-chuka">
            <img src={filter} className="ml-2 mr-3" alt="" />
            <p className="w-20">Filter/1</p>
            <img src={ar} className="ml-2 mr-2" alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default NavbarTask;
