import React, { memo, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastMessageContext } from '../../../../context/toast-context';

import { arrow_right, done, needInfoNew, rejected } from '../../../../static/image';
import UpdateFormName from '../update-form-name';
import UpdateFormStatus from '../update-form-status';
import { urls } from 'entities/urls';

const FormItem = memo((props) => {
  const { info, index, setListForm, setRefresh } = props;
  const style = {
    color: '#2E3338',
    title: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: '600',
    },
    label: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      color: '#0E353D',
      fontWeight: '700',
    },
  };

  const [isEditName, setIsEditName] = useState(false);
  const [isEditStatus, setIsEditStatus] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const history = useHistory();

  return (
    <div
      className="flex items-center bg-white w-full justify-around mb-8 text-center py-2"
      style={{
        boxShadow:
          '0px 0px 2px rgba(145, 158, 171, 0.24), 0px 16px 32px -4px rgba(145, 158, 171, 0.24)',
        borderRadius: '0.5rem',
      }}
    >
      <div
        className="w-[32%] h-full text-left font-semibold relative"
        onDoubleClick={() => {
          if (!info.id) {
            setToastMessage({
              status: 'warning',
              title: 'Validation Rule',
              message: 'You cannot edit the default form',
            });
            setIsShowToastMessage(true);
          } else {
            setIsEditName(true);
          }
        }}
      >
        <p style={{ color: style.color, fontSize: '1rem', lineHeight: '1.5rem' }}>
          {info.name}
        </p>
        {isEditName && (
          <UpdateFormName
            setIsEditName={setIsEditName}
            name={info.name}
            id={info.id}
            setListForm={setListForm}
            index={index}
          />
        )}
      </div>

      <div className="w-[15%] h-full">
        <p style={{ color: style.color, ...style.title }}>Form Type</p>
        <p style={{ ...style.label }}>{info.formType}</p>
      </div>

      <div className="w-[15%] h-full">
        <p style={{ color: style.color, ...style.title }}>Used in</p>
        <p style={{ ...style.label }}>{info.usedIn}</p>
      </div>

      <div
        className="h-full relative"
        onDoubleClick={() => {
          if (!info.id) {
            setToastMessage({
              status: 'warning',
              title: 'Validation Rule',
              message: 'You cannot edit the default form',
            });
            setIsShowToastMessage(true);
          } else {
            setIsEditStatus(true);
          }
        }}
      >
        <p style={{ color: style.color, ...style.title }}>Status</p>
        <StatusItem status={info.status} />
        {isEditStatus && (
          <UpdateFormStatus
            setIsEditStatus={setIsEditStatus}
            status={info.status}
            id={info.id}
            setRefresh={setRefresh}
          />
        )}
      </div>

      <div className="w-[2%] h-full">
        <img
          src={arrow_right}
          className="cursor-pointer"
          onClick={() => {
            history.push(urls.campus.admission.setting.formBuilder, { info });
          }}
          alt="arrow"
        />
      </div>
    </div>
  );
});

FormItem.displayName = 'FormItem';
export default FormItem;

const StatusItem = memo((props) => {
  const { status } = props;

  const mapStatus = useMemo(
    () => ({
      Active: {
        bgColor: '#AEE9D1',
        color: '#219653',
        icon: done,
        label: 'Active',
      },
      Inactive: {
        bgColor: '#FFE9E8',
        color: '#FD5749',
        icon: rejected,
        label: 'Inactive',
      },
      Draft: {
        bgColor: '#FFF2E2',
        color: '#F2C94C',
        icon: needInfoNew,
        label: 'Draft',
      },
    }),
    [],
  );

  return (
    <div
      className="flex items-center justify-around w-full h-[1.5rem]"
      style={{
        backgroundColor: mapStatus[status].bgColor,
        borderRadius: '2.5rem',
      }}
    >
      <img
        src={mapStatus[status].icon}
        className="block ml-1.5 mr-1.5 w-4 h-4"
        alt="status"
      />
      <p
        className="text-xs mr-2.5"
        style={{
          color: mapStatus[status].color,
        }}
      >
        {mapStatus[status].label}
      </p>
    </div>
  );
});
StatusItem.displayName = 'StatusItem';
