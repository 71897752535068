import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { cancel } from '@static/image';
import { Button, Checkbox, SelectGroup } from '@stories/index';
import { ToastMessageContext } from 'context/toast-context';
import './feebook-modal.scss';
import financeApi from 'api/finance';
import useStorage from 'store/storage';

export default function FeeBookModal({ open, isOpen, setRefresh }) {
  const styles = {
    saveBtn: {
      backgroundColor: '#fff',
      color: '#202223',
      border: '1px solid #E1E3E5',
      marginRight: '0.5rem',
    },
    createBtn: { backgroundColor: '#008060' },
  };
  const [listProrams, setListPrograms] = useState([]);
  const [listAcademicYears, setListAcademicYears] = useState([]);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState();
  const [selectedProgram, setSelectedProgram] = useState();
  const [usePreviousYear, setUsePreviousYear] = useState(false);
  const [academicOpen, setAcademicOpen] = useState(false);
  const [programOpen, setProgramOpen] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campus = useStorage((state) => state.currentCampus);
  const currentYear = useStorage((state) => state.currentYear);
  const optionYear = useStorage((state) => state.years)

  const allClose = () => {
    setAcademicOpen(false);
    setProgramOpen(false);
  };

  useEffect(() => {
    if (optionYear && optionYear?.length) {
      const listAcademicYearOptions = optionYear.map((academicYear) => ({
        label: academicYear,
        value: academicYear,
      }));
      const findAcademicYear = listAcademicYearOptions.find(academicYear => {
        return academicYear.value === currentYear;
      });
      setSelectedAcademicYear(findAcademicYear || listAcademicYearOptions[0]);
      setListAcademicYears(listAcademicYearOptions);
    }
  }, []);

  useEffect(() => {
    if (selectedAcademicYear) {
      financeApi.getListProgram({ campusId: campus?.isCentral ? '' : campus?.id, academicYearName: selectedAcademicYear?.value || '' })
        .then((res) => {
          if (res.data.success) {
            const listPros = (res.data?.data || []).map((program) => {
              return {
                label: program.name,
                value: program.id,
                academicYear: program.academicYear?.[0] || {}
              };
            });
            // after retriving programs, set the first program to Option
            // and save list programs into state
            if (listPros.length > 0) {
              setSelectedProgram(listPros[0]);
              setListPrograms(listPros);
            }
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Fetch Data Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Program Error', error);
        });
    }
  }, [selectedAcademicYear]);

  const handleSelectProgram = (e) => {
    setSelectedProgram(e);
  };
  const handleSelectAcademicYear = (e) => {
    setSelectedAcademicYear(e);
    setSelectedProgram();
  };
  const handleCreateFeeBook = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // check select program and academic year or not
    if (!selectedProgram || !selectedProgram.value) {
      setToastMessage({
        status: 'warning',
        title: 'Validate Rule',
        message: 'Please select program',
      });
      setIsShowToastMessage(true);
      return;
    }
    if (!selectedAcademicYear || !selectedAcademicYear.value || !selectedProgram?.academicYear?.id) {
      setToastMessage({
        status: 'warning',
        title: 'Validate Rule',
        message: 'Please select academic year',
      });
      setIsShowToastMessage(true);
      return;
    }
    // end region checking program and year
    financeApi.createFeebook({
      usePreviousYear: usePreviousYear,
      campusId: campus?.isCentral ? undefined : campus?.id,
    }, { programId: selectedProgram.value, academicYearId: selectedProgram?.academicYear?.id })
      .then((res) => {
        if (res.data.success) {
          setRefresh((prev) => !prev);
          isOpen();
          setToastMessage({
            status: 'success',
            title: 'Create New FeeBook Successfully',
            message: res.data.success,
          });
          setIsShowToastMessage(true);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Create New FeeBook Failed',
            message: res.data?.message || '',
          });
          setIsShowToastMessage(true);
          console.log('Create New FeeBook', res.data?.message);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Create New FeeBook Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Create New FeeBook', error);
      });
  };

  return (
    <div className={open ? 'feebooks-modal feebooks-modal-active' : 'feebooks-modal'}>
      <div
        className="feebooks-modal_bg"
        onClick={() => {
          isOpen();
          allClose();
        }}
      />
      <div
        className="feebooks-modal-card"
        onClick={academicOpen === true || programOpen === true ? allClose : null}
      >
        <div className="feebooks-modal-card-header">
          <h2 className="text-2xl font-bold ml-6">Create New Fee-Book</h2>
          <button
            className="mr-4"
            onClick={() => {
              isOpen();
              allClose();
            }}
          >
            <img src={cancel} alt="closed modal" />
          </button>
        </div>
        <div className="feebooks-modal-card-row">
          <div className="feebooks-modal-card-row_label">
            {selectedAcademicYear?.label}
          </div>
          <div className="feebooks-modal-card-row_selects ml-6 mt-4">
            {/* <Select
              closedSelect={academicClose}
              openSelect={academicOpen}
              title="Select Academic Year"
              titleClassName="-translate-x-3.5"
              className="ml-10 mb-6 mt-10"
              optionsArray={academicYear}
              selected={selectedAcademicYear}
              setSelected={setSelectedAcademicYear}
            /> */}
            {/* <Select
              closedSelect={programClose}
              openSelect={programOpen}
              title="Program"
              optionsArray={listProrams}
              className="ml-10 mb-1"
              selected={selectedProgram}
              setSelected={setSelectedProgram}
            /> */}
            <SelectGroup
              title="Academic Year"
              classNameGroup="mb-6"
              options={listAcademicYears}
              value={selectedAcademicYear || null}
              onChange={(e) => handleSelectAcademicYear(e)}
              isMulti={false}
              classNamePrefix="feebooks-modal"
            />
            <SelectGroup
              title="Program"
              // classNameGroup="mb-5"
              options={listProrams}
              value={selectedProgram || null}
              onChange={(e) => handleSelectProgram(e)}
              isMulti={false}
              classNamePrefix="feebooks-modal"
            />
            <div className="flex items-center mt-3.5">
              <Checkbox
                value={usePreviousYear}
                onChange={() => {
                  setUsePreviousYear((prev) => !prev);
                }}
                customClass="checkbox-fix"
              />
              <span className="text-sm">Use Previous years Fee Book</span>
            </div>
            <div className="feebooks-modal-card-row_selects_checkbox"></div>
          </div>
        </div>
        <div className="flex justify-end pt-4 pr-4">
          <Button text="Save as Draft" customStyle={styles.saveBtn} />
          <Button
            text="Create"
            customStyle={styles.createBtn}
            onClick={(e) => {
              handleCreateFeeBook(e);
            }}
          />
        </div>
      </div>
    </div>
  );
}

FeeBookModal.propTypes = {
  open: PropTypes.bool,
  isOpen: PropTypes.func,
};
