import { memo } from 'react';
import UploadPhoto from '../../../../../components/admission-module/application/information/subcomponents/upload-photo/upload-photo';
import ContentGroup from '@components/admission-module/application/information/subcomponents/content-group/content-group';

const StudentInfo = memo((props) => {
  const { info, listField, handleChange } = props;
  return (
    <>
      <UploadPhoto
        id="studentInfo"
        info={info?.avatar}
        handleChange={(e) => {
          handleChange({ name: 'studentInfo', key: 'photoURL', e });
        }}
      />
      {listField.map((item, key) => {
        return (
          <ContentGroup
            key={key}
            data={item}
            info={info?.[item.name]}
            handleChange={(e) => {
              handleChange({ name: 'studentInfo', key: item.name, value: e });
            }}
          />
        );
      })}
    </>
  );
});
StudentInfo.displayName = 'StudentInfo';
export default StudentInfo;
