import { close, close_x_gray, create_icon } from '@static/image';
import { Input, SelectGroup } from '@stories/index';
import { Fragment } from 'react';

const listOperator = [
  { label: 'Text', value: 'text' },
  { label: 'Date', value: 'date' },
]

export default function MandatoryFields({
  indexFileRequirement,
  mandatoryFields,
  setItemSetting,
}) {
  const styleInput = {
    title: {
      width: '2.5rem',
      // marginRight: '0.5rem',
      height: '2.25rem',
      textAlign: 'center',
      backgroundColor: '#404EED',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      color: '#fff',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    content: {
      width: '8.75rem',
      height: '2.25rem',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      borderRadius: '0.25rem',
    },
  };

  const handleOnDelete = (indexFileRequirement, indexField) => {
    setItemSetting((prev) => {
      const fileRequirement = prev.fileRequirement;
      fileRequirement[indexFileRequirement].mandatoryFields.splice(indexField, 1);
      return {
        ...prev,
        fileRequirement,
      };
    });
  };

  const handleOnAdd = () => {
    setItemSetting((prev) => {
      const fileRequirement = prev.fileRequirement;
      fileRequirement[indexFileRequirement].mandatoryFields.push({
        fieldName: '',
        operator: '',
      });
      return {
        ...prev,
        fileRequirement,
      };
    });
  };

  return (
    <>
      <table className="mandatory-conditions">
        <thead>
          <tr className="">
            <th align="left" className="mandatory-conditions_title w-[3rem]"></th>
            <th align="left" className="mandatory-conditions_title w-[9.75rem]">
              Field
            </th>
            <th align="left" className="mandatory-conditions_title w-[9.75rem]">
              Operator
            </th>
            <th align="left" className="mandatory-conditions_title"></th>
          </tr>
        </thead>
        <tbody>
          {mandatoryFields?.map((field, indexField) => {
            const { fieldName, operator } = field;
            return (
              <Fragment key={indexField}>
                <tr className="font-medium text-sm mt-2">
                  <td>
                    <Input
                      label={''}
                      value={indexField + 1}
                      disabled={true}
                      onChange={(e) => { }}
                      customStyle={styleInput.title}
                    />
                  </td>
                  <td>
                    <Input
                      label=''
                      value={fieldName}
                      placeholder='Field Name'
                      customStyle={styleInput.content}
                      onChange={(e) => {
                        setItemSetting((prev) => {
                          const fileRequirement = prev.fileRequirement;
                          fileRequirement[indexFileRequirement].mandatoryFields[
                            indexField
                          ]['fieldName'] = e.target.value;
                          return {
                            ...prev,
                            fileRequirement,
                          };
                        });
                      }}
                    />
                  </td>
                  <td>
                    <SelectGroup
                      label=''
                      options={listOperator}
                      value={listOperator.find(item => item.value === operator) || null}
                      customStyle={styleInput.content}
                      onChange={(e) => {
                        setItemSetting((prev) => {
                          const fileRequirement = prev.fileRequirement;
                          fileRequirement[indexFileRequirement].mandatoryFields[
                            indexField
                          ]['operator'] = e.value;
                          return {
                            ...prev,
                            fileRequirement,
                          };
                        });
                      }}
                    />
                    {/* <Input
                      label={''}
                      value={operator}
                      customStyle={styleInput.content}
                      onChange={(e) => {
                        setItemSetting((prev) => {
                          const fileRequirement = prev.fileRequirement;
                          fileRequirement[indexFileRequirement].mandatoryFields[
                            indexField
                          ]['operator'] = e.target.value;
                          return {
                            ...prev,
                            fileRequirement,
                          };
                        });
                      }}
                    /> */}
                  </td>
                  <td>
                    <button
                      className="flex items-center"
                      onClick={() => {
                        handleOnDelete(indexFileRequirement, indexField);
                      }}
                    >
                      <img
                        src={close_x_gray}
                      />
                    </button>
                  </td>
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
      <button className="flex items-center mt-4" onClick={handleOnAdd}>
        <img
          src={create_icon}
        />
        <span className="criteria-btn-content">Add Row</span>
      </button>
    </>
  );
}
