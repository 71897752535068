import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import { HeaderField, TextFieldCard } from '../subcomponents';
import clsx from 'clsx';

export default function AddressInformation(props) {
  const { burger, listField, data } = props;
  const [active, setActive] = useState();
  const [addressInfo, setAddressInfo] = useState({});

  const addressTypes = useMemo(() => {
    const types = [];
    data?.forEach((address) => {
      types.push(address.locationName);
    });

    setActive(types && types[0]);
    setAddressInfo(data[0]);
    return types;
  }, [data]);

  const changeActive = useCallback(
    (type, index) => {
      setActive(type);
      setAddressInfo(data[index]);
    },
    [data],
  );

  return (
    <div className='px-8'>
      <div className={clsx('information-field_address pb-4', burger && '1599px:w-[56.25rem]')}>
        <div className="w-full flex items-center justify-between mb-2 pt-5 pl-10 pr-10">
          <p className="font-bold text-main-black text-base">Address Information</p>
        </div>
        <HeaderField
          types={addressTypes}
          active={active}
          changeActive={changeActive}
          className="mb-5"
        />
        <div className="grid grid-cols-4 pl-12 gap-x-4">
          {listField.map((field) => {
            const { label, name } = field;
            if (name === 'locationName') return null;
            return <TextFieldCard title={label} body={addressInfo[name]} key={name} />;
          })}
        </div>
      </div>
    </div>
  );
}

AddressInformation.propTypes = {
  /**  @param Type An array of objects.
   * @param Object Keys for objects - title: string, body: string
   * @param Example [
  { title: 'Address 1', body: 'Galieleo Campus' },
  { title: 'Address 2', body: 'Galieleo Campus' },
  { title: 'State', body: 'Andhra Pradesh' },
  { title: 'Country', body: 'India' }
]
   **/
  data: PropTypes.array,
};
