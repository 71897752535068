import React, { memo } from 'react';
import clsx from 'clsx';
import _ from 'lodash';

import ResidentialCardFee from './ResidentialCardFee';

const SingleCategoryFee = memo((props) => {
  const {
    residentialFeeSave = [],
    numberOfMonthsInAcademicyear = 1,
    options = [],
    termsLength,
    residentialFee,
    setResidentialFee = () => { },
    listClasses = [],
    burger,
    refresh = () => { },
    title,
    razorpayBankAccount,
    listBankAccount = [],
    listAcademicYearTerm = []
  } = props;
  const types = ['Monthly', 'Term Dates', 'Custom'];

  const getClassFeeBookInfo = (classId, tutionFees) => {
    if (!classId || !tutionFees) {
      return {
        feeInfo: '',
        razorpayBankAccountId: ''
      };
    }
    const result = tutionFees.find((fee) => {
      return fee.classId === classId;
    });
    if (!result) {
      return {
        feeInfo: '',
        razorpayBankAccountId: ''
      };
    }
    // return JSON.parse(result.info);
    return {
      feeInfo: result.info,
      razorpayBankAccountId: result.razorpayBankAccountId
    };
  };

  return (
    <div className={clsx('w-full h-[70vh]')}>
      <p className="text-lg font-bold mt-8 mb-4">{title}</p>
      <div className={clsx('w-full max-h-[60vh] overflow-y-auto')}>
        {listClasses.map((data) => {
          const classFeeInfo = getClassFeeBookInfo(data.id, residentialFee).feeInfo;
          const classOldFeeInfo = getClassFeeBookInfo(data.id, residentialFeeSave).feeInfo;
          const oldTerms = _.cloneDeep(classOldFeeInfo.terms || []);
          const razorpayBankAccountId = getClassFeeBookInfo(data.id, residentialFee).razorpayBankAccountId;
          return (
            <div key={data.id} className="mb-4">
              <ResidentialCardFee
                numberOfMonthsInAcademicyear={numberOfMonthsInAcademicyear}
                options={options}
                oldTerms={oldTerms}
                classId={data.id}
                classFeeInfo={classFeeInfo}
                classOldFeeInfo={classOldFeeInfo}
                termsLength={termsLength}
                setResidentialFee={setResidentialFee}
                types={types}
                data={data}
                burger={burger}
                refresh={refresh}
                listBankAccount={listBankAccount}
                razorpayBankAccount={razorpayBankAccount}
                razorpayBankAccountId={razorpayBankAccountId}
                listAcademicYearTerm={listAcademicYearTerm}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
});
SingleCategoryFee.displayName = 'SingleCategoryFee';
export default SingleCategoryFee;
