export function compareString(stringA, stringB) {
  stringA = stringA
    ?.toLowerCase()
    .trim()
    .replace(/ +(?= )/g, '');
  stringB = stringB
    ?.toLowerCase()
    .trim()
    .replace(/ +(?= )/g, '');
  return stringA === stringB;
}
