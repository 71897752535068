import React, { useEffect, useState, useContext } from 'react';
import { Fade } from 'react-reveal';
import { Link, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { selectGroup } from '../../entities/select-style';
import Papa from 'papaparse';
import { dataLinks } from '../../entities/routes';
import { Button } from '../../stories';
import { NavLinks } from '../Header/components/index';
import DragDropFile from './component/drag-drop-file';
import './import-user.scss';
import { checkErrorSingleApi } from '../../utils/check-error/api-error';
import { ToastMessageContext } from '../../context/toast-context';
import authApi from 'api/auth';

export default function ImportUser({ burger }) {
  const USER_ROLE = ['Teacher', 'Student', 'Parent'];

  const styles = {
    btnBlue: {
      width: '6.563rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      backgroundColor: '#404eed',
      height: '2.563rem',
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    btnWhite: {
      width: '6.563rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#404eed',
      backgroundColor: 'white',
      height: '2.563rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      border: '1px solid #404eed',
    },
  };

  const allowedExtensions = ['csv'];

  const [file, setFile] = useState('');
  const [campusId, setCampusId] = useState('');
  const [rolesData, setRolesData] = useState([]);
  const [roleSelected, setRoleSelected] = useState({});
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const location = useLocation();

  useEffect(() => {
    const getRole = async () => {
      try {
        if (!location.state?.data) return;
        const id = location.state.data.id;
        setCampusId(id);
        const res = await authApi.getAllRoleOfCompanyOrCampus({ campusId: id });
        if (checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, 'Get Roles')) {
          const roles = res.data.data.filter((item) => {
            return USER_ROLE.includes(item.name);
          });
          setRolesData(roles);
        }
      } catch (error) {
        setToastMessage({
          status: 'error',
          title: 'Get Roles Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Import User Error', error);
      }
    }
    getRole();
  }, []);

  const rolesRender = rolesData.map((item) => {
    return { value: item.name, label: item.name, info: item };
  });

  const handleFileChange = (files) => {
    if (files.length) {
      const inputFile = files[0];

      const fileExtension = inputFile?.type.split('/')[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setToastMessage({
          status: 'warning',
          title: 'Missing File',
          message: 'Please input a csv file',
        });
        setIsShowToastMessage(true);
        return;
      }

      setFile(inputFile);
    }
  };

  const handleOnClickSave = async () => {
    if (!file) {
      setToastMessage({
        status: 'error',
        title: 'File Error',
        message: 'Enter a valid file',
      });
      setIsShowToastMessage(true);
      return;
    }

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        let users = [];
        let recordError = [];

        if (!Object.keys(roleSelected).length) {
          setToastMessage({
            status: 'warning',
            title: 'Missing Field',
            message: 'Please select role',
          });
          setIsShowToastMessage(true);
          return;
        }

        results.data.forEach((item, index) => {
          if (
            item['prefixed'] ||
            item['firstName'] ||
            item['lastName'] ||
            item['email'] ||
            item['phoneNumber']
          ) {
            users.push({
              ...item,
              roleIds: [roleSelected.info.id],
            });
          } else {
            recordError.push(index + 1);
          }
        });

        if (recordError.length) {
          setToastMessage({
            status: 'warning',
            title: 'Data Invalid',
            message: `Record ${recordError.join(', ')} is invalid. Please check again`,
          });
          setIsShowToastMessage(true);
        } else {
          authApi.importUser({ campusId: campusId }, { users })
            .then((res) => {
              checkErrorSingleApi(
                res,
                setToastMessage,
                setIsShowToastMessage,
                'Import Users',
              );
            })
            .catch((error) => {
              setToastMessage({
                status: 'error',
                title: 'Import Users Failed',
                message: error.response?.data?.message || error,
              });
              setIsShowToastMessage(true);
              console.log('Import User Error', error);
            });
        }
      },
    });
  };

  return (
    <div className="import-user px-8 w-full">
      <div className="min-w-[50%]">
        <Fade clear duration={300}>
          <NavLinks urls={dataLinks.importUserLinks} />
          <h1 className="namepage">Bulk Import</h1>
          <div className="import-user-form">
            <div className="import-user-form_section import-user-section">
              <div className="import-user-section_header">
                <h2 className="import-user-section_title">1. Type of Import</h2>
                <div className="flex items-center">
                  <span className="import-user-section_text">Would like to Add/Edit</span>
                  <Select
                    className="ml-2 mr-2 text-sm w-40"
                    placeholder="Role"
                    defaultValue={Object.keys(roleSelected).length ? roleSelected : null}
                    value={Object.keys(roleSelected).length ? roleSelected : null}
                    styles={{ selectGroup }}
                    options={rolesRender}
                    isSearchable={true}
                    onChange={(e) => {
                      setRoleSelected(e);
                    }}
                  />
                  <span className="import-user-section_text">
                    details including name, address and other essentials.
                  </span>
                </div>
              </div>
              <div className="import-user-section_header">
                <h2 className="import-user-section_title">
                  2. Download Our CSV Template
                </h2>
                <small className="import-user-section_text leading-5 text-sm font-medium">
                  Download the&nbsp;
                  <Link
                    className="font-normal underline cursor-pointer"
                    style={{ color: '#0E4DA4' }}
                    to="/files/CSV_ImportUser_Template.csv"
                    target="_blank"
                    download
                  >
                    CSV File
                  </Link>
                  &nbsp;and add the details in the following format
                </small>
              </div>
            </div>
            <div className="import-user-form_section import-user-section">
              <div className="import-user-section_header">
                <h2 className="import-user-section_title">3. Upload Document</h2>
                <div className="import-user-section_upload mt-7 p-6 rounded-2xl">
                  <DragDropFile
                    title={'Scan and Upload Test Results'}
                    multiple={false}
                    handleFile={handleFileChange}
                  />
                  {file && (
                    <div className="import-user-section_upload_file">
                      <span className="import-user-section_upload_file_name">
                        {file.name}
                      </span>
                      <span className="import-user-section_upload_file_size">
                        {' '}
                        - {file.size} bytes
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="import-user-section_footer">
                <Button text="Cancel" customStyle={styles.btnWhite} />
                <Button
                  text="Save"
                  customStyle={styles.btnBlue}
                  onClick={(e) => {
                    handleOnClickSave();
                  }}
                />
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}
