import React, { memo, useState, useCallback, useMemo } from 'react';
import clsx from 'clsx';

import { listBlockCustom, listBlockField, listBlockSpecial, Block } from '../../blocks';

const BlockSection = memo((props) => {
  const { handleDragStart, isAdmin } = props;

  let listBockShow = [...listBlockField, ...listBlockCustom];
  if (isAdmin) {
    listBockShow = listBockShow.concat(listBlockSpecial);
  }

  const objCategoryName = useMemo(() => {
    const result = {};
    listBockShow.forEach(({ category }) => {
      result[category] = false;
    });
    return result;
  }, []);
  const [open, setOpen] = useState(objCategoryName);

  const handleToggleOpen = useCallback((key) => {
    setOpen((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  }, []);

  return listBockShow.map(({ category, blocks }, index) => {
    return (
      <div key={category} className={clsx(index === 0 ? 'mt-0' : 'mt-6')}>
        <BlockCategory
          listBlock={blocks}
          categoryName={category}
          handleDragStart={handleDragStart}
          isOpen={open[category]}
          handleToggleOpen={handleToggleOpen}
        />
      </div>
    );
  });
});
BlockSection.displayName = 'BlockSection';
export default BlockSection;

const BlockCategory = memo((props) => {
  const { listBlock, categoryName, handleDragStart, isOpen, handleToggleOpen } = props;

  return (
    <div>
      <div className="flex" onClick={() => handleToggleOpen(categoryName)}>
        <p className="text-sm font-semibold text-[#637381]">{categoryName}</p>
      </div>
      <div
        className={clsx(
          'justify-between flex-wrap gap-y-4 duration-300 flex mt-6')}
      >
        {listBlock.map((block) => {
          const { name } = block;
          return <Block key={name} block={block} onDragStart={handleDragStart} />;
        })}
      </div>
    </div>
  );
});
BlockCategory.displayName = 'BlockCategory';
