import { memo, useContext, useEffect, useState } from 'react';
import Add from '../add/add';
import { ic_close_black } from '@static/image';
import gradeApi from 'api/gradeApi';
import { checkErrorApiFetch } from '@utils/check-error/api-error';
import { Input, SelectGroup } from '@stories/index';
import { ToastMessageContext } from 'context/toast-context';

const HistorySchoolInfo = memo((props) => {
  const { listField, info, handleChange, handleRemove, handleAdd } = props;
  return (
    <>
      {info.map((item, index) => {
        if (item.isDelete) return null
        return (
          <SingleSchool
            key={`school${index}`}
            listField={listField}
            info={item}
            order={index}
            handleChange={handleChange}
            handleRemove={handleRemove}
          />
        );
      })}
      <div className="flex items-center justify-center">
        <button
          onClick={() => {
            handleAdd({ name: 'schoolInfo' });
          }}
          className="mt-2 mb-6 none-after flex items-center font-semibold text-sm"
          style={{ color: '#ababcd' }}
        >
          <Add />
          Add Additional School
        </button>
      </div>
    </>
  );
});
HistorySchoolInfo.displayName = 'HistorySchoolInfo';
export default HistorySchoolInfo;

const SingleSchool = memo((props) => {
  const { listField, info, order, handleRemove, handleChange } = props;
  const [grades, setGrades] = useState()
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);


  useEffect(() => {
    const fetch = async () => {
      const { res, error } = await gradeApi.getGrade()

      if (error) {
        setToastMessage({
          status: 'error',
          title: 'Get Grades',
          message: error?.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      }

      if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, false)) {
        const tmpGrades = res.data.data.objects.map(item => ({ value: item.id, label: item.name }))
        setGrades(tmpGrades)
      }
    }

    fetch();
  }, [])

  const style = {
    container: (styles) => ({ ...styles, width: '100%' }),
    control: (styles) => ({
      ...styles,
      width: '100%',
      border: '1px solid  rgba(145, 158, 171, 0.32)',
      boxShadow: 'none',
      cursor: 'pointer',
      borderRadius: '0.25rem',
      padding: '0 0 0 0',
      marginLeft: '0',
    }),
    indicatorSeparator: () => null,
    input: (styles) => ({ ...styles, height: '2.5rem', width: '6.25rem', color: '#212B36' }),
    indicatorsContainer: () => null,
    option: (styles, state) => ({
      ...styles,
      height: 'fit-content',
      width: '90%',
      padding: '0.438rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
      backgroundColor: state.isSelected ? '#e2e2e2' : 'white',
      borderRadius: '0.25rem',
      color: '#212B36',
      cursor: 'pointer',
      '&:hover': state.isSelected
        ? { backgroundColor: '#e2e2e2' }
        : { backgroundColor: '#404eed', color: 'white' },
    }),
    menu: (styles) => ({ ...styles, width: '100%', zIndex: '2' }),
    singleValue: (styles) => ({
      ...styles,
      color: '#212B36',
      fontSize: '1rem',
      paddingLeft: '0.25rem',
    }),
  };

  return (
    <div className="flex item-center gap-x-[3rem]">

      <div className="mb-4 h-fit">
        <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
          {listField[0].label}
        </p>
        <Input
          customStyle={{ height: 'unset', borderRadius: 0 }}
          type="text"
          label=''
          value={info?.schoolName || ''}
          onChange={(e) => {
            const val = e.target.value;
            handleChange({ name: 'schoolInfo', order, key: listField[0].name, value: val })
          }}
        />
      </div>

      <div className="mb-4 h-fit w-[25%]">
        <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
          {listField[1].label}
        </p>
        <SelectGroup
          isMulti={false}
          options={grades}
          styles={style}
          value={grades ? grades.find(item => item.label === info.startGrade) : null}
          onChange={(e) => {
            handleChange({
              name: 'schoolInfo',
              order,
              key: 'startGrade',
              value: e.label,
            })
          }}
        />

      </div>

      <div className="mb-4 h-fit w-[25%]">
        <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
          {listField[2].label}
        </p>
        <SelectGroup
          isMulti={false}
          options={grades}
          styles={style}
          value={grades ? grades.find(item => item.label === info.endGrade) : null}
          onChange={(e) => {
            handleChange({
              name: 'schoolInfo',
              order,
              key: 'endGrade',
              value: e.label,
            })
          }}
        />
      </div>
      <button title='remove' onClick={() => {
        handleRemove({ name: 'schoolInfo', order })
      }}>
        <img src={ic_close_black} alt='remove' />
      </button>
    </div>
  );
});
SingleSchool.displayName = 'SingleSchool';
