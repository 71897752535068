import { useCallback } from 'react';
import { Pagination } from '@mui/material';

import CashierSideBar from '../CashierSideBar';
import Loading from '@components/loading';
import { search } from '@static/image';
import { makeStyles } from '@material-ui/styles';
import UserCard from '../UserCard';


function PaymentSideBar(props) {
  const {
    handleSelecttedApplication = () => { },
    listEnquiry = [],
    totalEnquiry,
    pageSize,
    currentPage,
    handleChangePage = () => { },
    isLoading,
    handleSetTextSearch = () => { },
    textSearch = '',
  } = props;

  // const [currentPage, setCurrentPage] = useState(0);
  // const [pageSize, setPageSize] = useState(5);
  // const [totalEnquiry, setTotalEnquiry] = useState(0);
  // const [listEnquiry, setListEnquiry] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);

  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiPagination-ul': {
        flexWrap: 'nowrap',
      },
    },
  }));
  const classes = useStyles();

  const handleClick = useCallback((applicationId, name, avatar) => {
    const user = {
      avatar: avatar,
      name: name,
    };
    handleSelecttedApplication(user, applicationId);
  }, []);

  return (
    // <CashierSideBar title='Payments' setTabActive={setTabActive} tabActive={tabActive}>
    <CashierSideBar title="Payments">
      <div className="w-full flex items-center justify-start rounded-lg mt-5 border-2 focus-within:border-blue-700">
        <img className="ml-5" src={search} alt="Search" />
        <input
          value={textSearch}
          onChange={(e) => {
            handleSetTextSearch(e.target.value);
          }}
          className="outline-0 border-0 px-2 py-3 w-10/12 text-sm"
          placeholder="Search Name, Email or Phone"
        ></input>
      </div>
      {isLoading ? (
        <div className="mt-10 flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        <div>
          {listEnquiry.length > 0 ? (
            <div className="w-full">
              {listEnquiry.map((enquiry) => {
                const info = JSON.parse(enquiry.info);
                let name;
                if (info.middleName) {
                  name = `${info.firstName} ${info.middleName} ${info.lastName}`;
                } else {
                  name = `${info.firstName} ${info.lastName}`;
                }
                const user = {
                  name,
                  avatar: info.avatar,
                };
                return (
                  <div
                    onClick={() => {
                      handleClick(enquiry.application?.id, name, info.avatar);
                    }}
                    key={enquiry.id}
                    className="mt-6 hover:bg-slate-50 cursor-pointer"
                  >
                    <UserCard user={user} applicationId={enquiry.application?.id} />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="mt-6">Enquiry not found. Please try again</div>
          )}
        </div>
      )}
      <div
        className="absolute bottom-7 left-[50%]"
        style={{ transform: 'translateX(-50%)' }}
      >
        <Pagination
          className={classes.root}
          count={Math.floor(totalEnquiry / pageSize) + 1}
          showFirstButton
          showLastButton
          page={currentPage + 1}
          size="small"
          siblingCount={0}
          boundaryCount={1}
          onChange={(e, value) => {
            handleChangePage(value - 1);
          }}
        />
      </div>
    </CashierSideBar>
  );
}

export default PaymentSideBar;
