import { useContext, useEffect, useRef, useState } from 'react';
import { cancel, private_img, public_img, staticsize } from '@static/image';
import { Button, Input, Upload } from '@stories/index';
import WorldCard from '@stories/worldcard/worldcard';
import { ToastMessageContext } from 'context/toast-context';
import './create-new-board-or-team-modal.scss';
import taskApi from 'api/task';
import useStorage from 'store/storage';

const initialTeam = {
  name: '',
  visibility: '',
};

const VISIBILITY = {
  PUBLIC: 'Public',
  PRIVATE: 'Private',
};

export default function CreateNewBoardOrTeamModal({
  open,
  isOpen,
  setIsAddTeamSuccess,
  setNewTeamId,
}) {
  const [imgPreview, setImgPreview] = useState(false);
  const [isLogo, setIsLogo] = useState(false);
  const [formData, setFormData] = useState(new FormData());
  const [avatar, setAvatar] = useState('');
  const [tableOpen, setTableOpen] = useState(false);
  const [newTeam, setNewteam] = useState();
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campus = useStorage((state) => state.currentCampus);
  const teamNameRef = useRef();

  useEffect(() => {
    setNewteam(initialTeam);
  }, []);

  const styles = {
    closeBtn: {
      backgroundColor: '#fff',
      color: '#202223',
      border: '1px solid #E1E3E5',
      marginRight: '0.5rem',
    },
    createBtn: { backgroundColor: '#404eed' },
  };

  const allClose = () => {
    setTableOpen(false);
  };

  const handleOnClickCreate = async () => {
    const name = teamNameRef.current.value.trim();
    if (!name) {
      setToastMessage({
        status: 'warning',
        title: 'Data Validation',
        message: 'Team name is not empty',
      });
      setIsShowToastMessage(true);
      return;
    }

    let logo = '';
    if (isLogo) {
      try {
        // const resultUploadImage = await callApi({
        //   method: 'post',
        //   url: `${process.env.REACT_APP_URL_API_TASK}/api/v1/media`,
        //   data: formData,
        //   contentType: 'multipart/form-data',
        // });
        const resultUploadImage = await taskApi.uploadMedia(formData, 'multipart/form-data')
        if (resultUploadImage.data.success) {
          logo = resultUploadImage.data.data;
        } else {
          setToastMessage({
            status: 'error',
            title: 'Upload Logo Failed',
            message: resultUploadImage.data.message,
          });
          setIsShowToastMessage(true);
        }
      } catch (error) {
        setToastMessage({
          status: 'error',
          title: 'Upload Logo Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Upload logo Error', error);
      }
    }
    const data = {
      ...newTeam,
      name: name,
      logoURL: logo,
    };
    // callApi({
    //   method: 'post',
    //   url: `${process.env.REACT_APP_URL_API_TASK}/api/v1/task/team`,
    //   params: { campusId: campus?.isCentral ? undefined : campus?.id },
    //   data: data,
    // })
    taskApi.createNewTeam({ campusId: campus?.isCentral ? undefined : campus?.id }, data)
      .then((res) => {
        const { data } = res;
        if (data.success) {
          setToastMessage({
            status: 'success',
            title: 'Create New Team Successfully',
            message: data.message,
          });
          setIsShowToastMessage(true);
          setNewTeamId(data?.data?.id);
          setIsAddTeamSuccess((prevState) => {
            return !prevState;
          });
          isOpen(false);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Create New Team Failed',
            message: data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Create New Team Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Create User Error', error);
      });
    // isOpen(false);
  };

  return (
    <div className={open ? 'create-board create-board-active' : 'create-board'}>
      <div
        className="create-board_bg"
        onClick={() => {
          isOpen();
        }}
      />
      <div
        className="create-board-card w-[60vw] 2xl:w-[50vw] 1400px:w-[48vw] 1620px:w-[44vw] 1920px:w-[40vw]"
        onClick={tableOpen === true ? allClose : null}
      >
        <div className="create-board-card-header">
          <h2 className="text-2xl ml-6">Create New Board/Team</h2>
          <button
            className="mr-4"
            onClick={() => {
              isOpen();
            }}
          >
            <img src={cancel} alt="closed modal" />
          </button>
        </div>
        <div className="create-board-card-row">
          <div className="create-board-card-row_label ml-[1.5%]"></div>
          <div className="create-board-card-row_selects items-center">
            <div className="flex">
              <div className="ml-[1.5%]">
                <p className="create-board-text">Board Image</p>
                <div className="create-user-upload mt-7">
                  <img
                    className="create-user-upload_image"
                    src={avatar ? `${avatar}` : staticsize}
                    alt="uploaded img"
                  />
                  <Upload
                    text="Upload Image"
                    id="image-upload"
                    accept="image/*"
                    customStyle={{
                      width: '12rem',
                    }}
                    imageChange={setImgPreview}
                    onChange={(e) => {
                      let selected = e.target.files;
                      if (selected && selected[0]) {
                        let file = selected[0];
                        setIsLogo(true);
                        formData.set('file', file);
                        formData.set('mediaId', file.name);
                        setFormData(formData);
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                          setAvatar(reader.result);
                        };
                        reader.onerror = function (error) {
                          console.log('Error: ', error);
                        };
                      }
                    }}
                  />
                </div>
              </div>
              <div className="mr-[1.5%]">
                <p className="ml-10 create-board-text">Visibility</p>
                <div className="ml-10 mt-2 flex">
                  <div
                    onClick={() => {
                      setNewteam((prevState) => {
                        return {
                          ...prevState,
                          visibility: VISIBILITY.PUBLIC,
                        };
                      });
                    }}
                    className="mr-6"
                  >
                    <WorldCard
                      img={public_img}
                      label="Public"
                      size={'small'}
                      isFocus={newTeam?.visibility === VISIBILITY.PUBLIC}
                      customStyleDiv={{ width: '5rem', height: '5rem' }}
                      customStyleImg={{ width: '2rem', height: '2rem' }}
                      customStyleLabel={{ fontSize: '0.75rem' }}
                    />
                  </div>
                  <div
                    onClick={() => {
                      setNewteam((prevState) => {
                        return {
                          ...prevState,
                          visibility: VISIBILITY.PRIVATE,
                        };
                      });
                    }}
                  >
                    <WorldCard
                      img={private_img}
                      label="Private"
                      size={'small'}
                      isFocus={newTeam?.visibility === VISIBILITY.PRIVATE}
                      customStyleDiv={{ width: '5rem', height: '5rem' }}
                      customStyleImg={{ width: '2rem', height: '2rem' }}
                      customStyleLabel={{ fontSize: '0.75rem' }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <Input
                refCustom={teamNameRef}
                label="Team or Board Name"
                name="add-task-name"
                type="text"
                customStyle={{
                  width: '30.25rem',
                }}
                onChange={() => { }}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end pt-4 pr-4">
          <Button
            text="Cancel"
            customStyle={styles.closeBtn}
            onClick={() => {
              isOpen();
            }}
          />
          <Button
            text="Create"
            customStyle={styles.createBtn}
            onClick={handleOnClickCreate}
          />
        </div>
      </div>
    </div>
  );
}
