import * as _ from 'lodash';
import { useCallback, useContext, useState } from 'react';
import { NavLinks } from '../Header/components/index';
import { Button, Checkbox, Input, SelectGroup } from '@stories/index';
import { checkErrorSingleApi } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { dataLinks } from 'entities/routes';
import { selectStylesWithNoTitle, toggleButtonStyles } from 'entities/select-style';
import { useHistory, useLocation } from 'react-router-dom';
import Criteria from './components/criteria';
import './edit-document-settings.scss';
import clsx from 'clsx';
import { urls } from 'entities/urls';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';

const quantityDocument = [
  { value: 0, label: '0 Document' },
  { value: 1, label: '1 Document' },
  { value: 2, label: '2 Documents' },
  { value: 3, label: '3 Documents' },
  { value: 4, label: '4 Documents' },
  { value: 5, label: '5 Documents' },
  { value: 6, label: '6 Documents' },
  { value: 7, label: '7 Documents' },
  { value: 8, label: '8 Documents' },
  { value: 9, label: '9 Documents' },
  { value: 10, label: '10 Documents' },
];

const documentType = [
  { value: 'Identification', label: 'Identification' },
  { value: 'Health Reports', label: 'Health Reports' },
  { value: 'Academic Reports', label: 'Academic Reports' },
  { value: 'Financial', label: 'Financial' },
  { value: 'Age Proof', label: 'Age Proof' },
  { value: 'Others', label: 'Others' },
];

export default function EditDocumentSettings(props) {
  const { burger } = props
  const campus = useStorage((state) => state.currentCampus);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const location = useLocation();
  const [itemSetting, setItemSetting] = useState(location.state?.info);
  const programId = location.state?.programId;
  const history = useHistory();

  const handleOnClickCancel = () => {
    history.push(urls.documentSetting);
  };

  const checkError = useCallback((itemSetting) => {
    const { name, maximumDocument, fileRequirement } = itemSetting
    if (!name.trim())
      return 'Please fill out category name'

    if (maximumDocument !== fileRequirement.length)
      return 'Maximum document and file requirement number is not match'

    for (let file of itemSetting.fileRequirement) {
      const { format, fileName, mandatoryFields } = file

      if (!format)
        return 'Please fill out format file'

      if (!fileName)
        return 'Please fill out file name'

      for (let field of mandatoryFields) {
        const { fieldName, operator } = field

        if (!fieldName)
          return 'Please fill out field name'

        if (!operator)
          return 'Please fill out field operator'
      }
    }

    return false
  }, [])

  const handleOnClickSave = async () => {
    const campusId = campus?.isCentral ? undefined : campus?.id
    const error = checkError(itemSetting)
    if (error) {
      setToastMessage({
        status: 'warning',
        title: 'Edit Document Settings warning',
        message: error,
      });
      setIsShowToastMessage(true);
      return;
    }

    const data = _.cloneDeep(itemSetting);
    data.fileRequirement = data.fileRequirement && JSON.stringify(data.fileRequirement);
    const dataCallAPI = (({ dateCreated, dateUpdated, id, multipleDocuments, isNew, ...o }) => {
      return o;
    })(data);

    let api = centralAdminApi.updateDocumentSetting({ campusId }, dataCallAPI, itemSetting.id)
    if (itemSetting?.isNew)
      api = centralAdminApi.createDocumentSetting({ campusId }, { ...dataCallAPI, programId })

    api.then((res) => {
      if (
        checkErrorSingleApi(
          res,
          setToastMessage,
          setIsShowToastMessage,
          'Edit Document Settings',
        )
      ) {
        history.push(urls.documentSetting);
      }
    })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Edit Document Settings Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Edit Document Settings', error);
      });
  };

  const handleChangeItemSetting = useCallback((key, value) => {
    setItemSetting((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  return (
    <div className={clsx('settings px-20 transition-all-300', burger ? '1400px:pl-80' : '1400px:pl-40')}>
      <div>
        <NavLinks urls={dataLinks.admissionDocumentSettingsLinks} />
        <div className="settings__tittle">
          <div className="namepage">Edit Document Settings</div>
        </div>
        <p>Please select relevant options for users to upload</p>
      </div>

      <div className="w-full mb-12 ml-4">
        <div className="settings__tabs flex w-full mb-8">
          <div className="w-full h-[calc(100%-7rem)]">
            <div className="flex-row overflow-y-auto overflow-x-hidden h-full w-[70%] max-w-[46.125rem]">
              <div>
                <div className="flex flex-wrap justify-between mt-8">
                  <div className="document-settings-left">
                    <Input
                      labelFix="Category Name"
                      label="Category Name"
                      name="category-name"
                      type="text"
                      value={itemSetting?.name}
                      onChange={(e) => {
                        handleChangeItemSetting('name', e.target.value);
                      }}
                      customStyle={{
                        marginTop: '0.5rem',
                        width: '100%',
                        height: '2.8rem',
                        fontSize: '0.875rem',
                        borderRadius: '0.25rem',
                      }}
                    />
                  </div>
                  <div className="document-settings-right">
                    <SelectGroup
                      isSearchable={true}
                      title="Document Type"
                      value={
                        itemSetting?.documentType
                          ? {
                            value: itemSetting?.documentType,
                            label: itemSetting?.documentType,
                          }
                          : documentType[0]
                      }
                      options={documentType}
                      onChange={(e) => {
                        handleChangeItemSetting('documentType', e.value);
                      }}
                      styles={{
                        control: (style) => ({
                          ...style,
                          height: '2.8rem'
                        })
                      }}
                      customStyle={{
                        marginTop: '0.5rem',
                        width: '100%',
                        borderRadius: '0.375rem',
                        fontSize: 14,
                        fontSize: '0.875rem',
                      }}
                    />
                  </div>
                </div>
                <div className="flex items-center text-sm mt-4">
                  <span className="mr-3 text-[#212B36]">
                    Users need to submit at least{' '}
                  </span>
                  <div className="w-[9.75rem]">
                    <SelectGroup
                      value={
                        itemSetting?.atLeastDocument
                          ? {
                            value: itemSetting?.atLeastDocument,
                            label: `${itemSetting?.atLeastDocument} ${itemSetting?.atLeastDocument === 1
                              ? 'Document'
                              : 'Documents'
                              }`,
                          }
                          : quantityDocument[0]
                      }
                      styles={selectStylesWithNoTitle}
                      options={quantityDocument}
                      isSearchable={true}
                      onChange={(e) => {
                        handleChangeItemSetting('atLeastDocument', e.value);
                      }}
                    />
                  </div>
                  <span className="mr-3 ml-3 text-[#212B36]">and maximum of </span>
                  <div className="area w-[9.75rem]">
                    <SelectGroup
                      value={
                        itemSetting?.maximumDocument
                          ? {
                            value: itemSetting?.maximumDocument,
                            label: `${itemSetting?.maximumDocument} ${itemSetting?.maximumDocument === 1
                              ? 'Document'
                              : 'Documents'
                              }`,
                          }
                          : quantityDocument[0]
                      }
                      styles={selectStylesWithNoTitle}
                      options={quantityDocument}
                      isSearchable={true}
                      onChange={(e) => {
                        handleChangeItemSetting('maximumDocument', e.value);
                      }}
                    />
                  </div>
                </div>
                <div className="document-settings-right flex my-4 pl-4">
                  <Checkbox
                    value={itemSetting?.isActiveLockFieldsApproval}
                    onChange={(e) => {
                      handleChangeItemSetting(
                        'isActiveLockFieldsApproval',
                        e.target.checked,
                      );
                    }}
                  />
                  <span className="document-settings-sub-content">
                    Lock the fields on Approval
                  </span>
                </div>
                <div className="flex justify-center mb-4">
                  <button
                    className="button-add flex justify-center items-center"
                    onClick={() => {
                      setItemSetting((prev) => {
                        const fileRequirement = prev.fileRequirement;
                        fileRequirement.push({
                          format: 'Passport',
                          fileName: '',
                          mandatoryFields: [],
                        });
                        return {
                          ...prev,
                          fileRequirement,
                        };
                      });
                    }}
                  >
                    <div className="document-settings_btn-plus mr-2">
                      <span>+</span>
                    </div>
                    Add File Requirement
                  </button>
                </div>
              </div>
              {/* <div> */}
              {!!itemSetting?.fileRequirement.length &&
                itemSetting?.fileRequirement?.map((file, index) => {
                  return (
                    <Criteria
                      key={index}
                      indexFile={index}
                      file={file}
                      itemSetting={itemSetting}
                      setItemSetting={setItemSetting}
                    />
                  );
                })}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="footer z-[1]">
        <div className="footer-row">
          <Button
            text="Cancel"
            customStyle={toggleButtonStyles.btnWhite}
            onClick={handleOnClickCancel}
          />
          <Button
            text="Save"
            customStyle={toggleButtonStyles.btnBlue}
            onClick={handleOnClickSave}
          />
        </div>
      </div>
    </div>
  );
}
