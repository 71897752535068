import { memo } from 'react';

import AdmissionCriteria from './AdmissionCriteria';
import SingleCategoryFee from './SingleCategoryFee';

const TutionFee = memo((props) => {
  const {
    tutionFeesSave = [],
    termsLength,
    options,
    followingFees,
    admissionFee,
    applicationFee,
    tutionFees,
    setFollowingFees,
    setAdmissionFee,
    setApplicationFee,
    setTutionFees,
    listClasses,
    burger,
    refresh,
    razorpayBankAccount,
    listBankAccount = [],
    setRazorpayBankAccount = () => { },
    listAcademicYearTerm = []
  } = props;

  return (
    <div className="block w-full">
      <AdmissionCriteria
        followingFees={followingFees}
        admissionFee={admissionFee}
        applicationFee={applicationFee}
        setFollowingFees={setFollowingFees}
        setAdmissionFee={setAdmissionFee}
        setApplicationFee={setApplicationFee}
        options={options}
        setTutionFees={setTutionFees}
        razorpayBankAccount={razorpayBankAccount}
        setRazorpayBankAccount={setRazorpayBankAccount}
        listBankAccount={listBankAccount}
      />
      <SingleCategoryFee
        tutionFeesSave={tutionFeesSave}
        admissionFee={admissionFee}
        applicationFee={applicationFee}
        options={options}
        tutionFees={tutionFees}
        setTutionFees={setTutionFees}
        termsLength={termsLength}
        refresh={refresh}
        burger={burger}
        listClasses={listClasses}
        listBankAccount={listBankAccount}
        razorpayBankAccount={razorpayBankAccount}
        listAcademicYearTerm={listAcademicYearTerm}
        title="Tution Fee"
      />
    </div>
  );
});
TutionFee.displayName = 'TutionFee';
export default TutionFee;
