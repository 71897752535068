import { useContext, useEffect, useState } from 'react';
import { mainavatar } from '@static/image';
import { Button, Chip, SelectGroup } from '@stories/index';
import { checkErrorSingleApi } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import {
  selectStylesApprovers,
  toggleButtonStyles,
} from '../../../../entities/select-style';
import taskApi from 'api/task';
import useStorage from 'store/storage';

function UpdateTaskOwner(props) {
  const {
    initialSelect,
    isSelect,
    setIsSelect,
    task,
    index,
    setRefresh,
    members = [],
    setIsUpdate = () => { },
    setPage = () => { },
  } = props;
  const [listMembers, setListMembers] = useState([]);
  const [listMembersSelected, setListMembersSelected] = useState([]);
  const [listMembersDeleted, setListMembersDeleted] = useState([]);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campus = useStorage((state) => state.currentCampus);

  useEffect(() => {
    const listMemberOption = members.map((user) => {
      return {
        label: user.user.name,
        value: user.user.id,
        info: user.user,
        memberId: user.id,
        photoURL: user.user.photoURL,
      };
    });
    setListMembers(listMemberOption);
  }, [members]);

  useEffect(() => {
    if (!task || task.assignees.length === 0) {
      return;
    }
    const listMemberSelect = task.assignees.map((user) => {
      return {
        label: user.member.user.name,
        value: user.member.user.id,
        info: user.member.user,
        memberId: user.member.id,
        photoURL: user.member.user.photoURL,
      };
    });
    setListMembersSelected(listMemberSelect);
    const listMemberDelete = task.assignees.map((user) => {
      return {
        id: user.id,
        isDbDelete: true,
      };
    });
    setListMembersDeleted(listMemberDelete);
  }, []);

  const formatOptionLabelUser = ({ value, label, info }) => (
    <div className="block">
      <Chip
        key={info?.id}
        label={label}
        image={info?.photoURL || mainavatar}
        chipAvatarStyle={{ width: 18, height: 18 }}
        styleChip={'object-cover'}
        customStyle={{ padding: '0 !important', margin: '0', fontSize: '0.875rem' }}
      />
    </div>
  );

  const handleUpdateTaskOwner = () => {
    const data = {};
    const members = listMembersSelected.map((member) => {
      return { teamMemberId: member.memberId };
    });
    data.owners = [...listMembersDeleted, ...members];
    taskApi.updateTaskById({ campusId: campus?.isCentral ? undefined : campus?.id }, data, task.id)
      .then((res) => {
        if (
          checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, 'Update Task')
        ) {
          // console.log('res.data.data', res.data.data);
          setIsSelect(initialSelect);
          setRefresh((prev) => ({
            ...prev,
            count: (prev.count || 0) + 1,
            index,
          }));
          setIsUpdate((prev) => !prev);
          setPage(0);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Update Task Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Update task error', error.message);
      });
  };

  return (
    <>
      {isSelect.owner && (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="absolute top-8 left-[-8rem] h-[11.5rem] w-[25rem] z-20 bg-[#fff] shadow-2xl rounded"
        >
          <SelectGroup
            title="Owners"
            defaultValue={!!listMembersSelected[0]?.value ? listMembersSelected : null}
            value={!!listMembersSelected[0]?.value ? listMembersSelected : null}
            styles={selectStylesApprovers}
            options={listMembers}
            formatOptionLabel={formatOptionLabelUser}
            isMulti={true}
            isSearchable={true}
            onChange={(e) => {
              const owners = e.map((item) => {
                return {
                  id: item.memberId,
                  photoURL: item.photoURL,
                };
              });
              setListMembersSelected(e);
              //   setTaskInfo((prevState) => {
              //     return {
              //       ...prevState,
              //       assignees: owners,
              //     };
              //   });
            }}
          />
          <div className="flex justify-center mt-10">
            <Button
              className="text-[0.8rem]"
              text="Cancel"
              customStyle={{
                ...toggleButtonStyles.btnWhite,
                width: '7rem',
                height: '1.5rem',
              }}
              onClick={() => {
                setIsSelect(initialSelect);
                // setEnteredTaskName('');
              }}
            />
            <Button
              className="text-[0.8rem]"
              text="Save"
              customStyle={{
                ...toggleButtonStyles.btnBlue,
                width: '7rem',
                height: '1.5rem',
              }}
              onClick={handleUpdateTaskOwner}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default UpdateTaskOwner;
