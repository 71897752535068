import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { ToastMessageContext } from '../../../../../context/toast-context';
import { uploadFileSVG } from '../../../../../static/image';
import { doc, pdf, word, xls } from '../image';
import mediaApi from 'api/media';

export default function Accept(props) {
  const {
    urlResultFile,
    dateUpdate,
    isHaveResult,
    // applicationId = '2652a2ca-8057-4d6b-b610-177f7b1b6e35',
    setIsUpdateResultFile = () => { },
    setUrlResultFile = () => { },
  } = props;
  const [currentFile, setCurrentFile] = useState();
  // const [isUpdateResultFile, setIsUpdateResultFile] = useState(false);
  // const [urlResultFile, setUrlResultFile] = useState('');
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  useEffect(() => {
    setCurrentFile(urlResultFile);
  }, [urlResultFile]);

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    accept: '.pdf,.doc,.docx,.xls , .xlsx, .xlsm, .xltx, .xltm',
    onDrop: !isHaveResult
      ? async (files) => {
        const rawQuery = [];
        await Promise.all(
          files.map(async (file) => {
            const formData = new FormData();
            formData.set('file', file);
            formData.set('mediaId', file.name);
            await mediaApi.uploadFile(formData)
              .then((response) => {
                const { data } = response;
                if (data.success) {
                  rawQuery.push({ fileURL: data.data, fileName: file.name });
                } else {
                  setToastMessage({
                    status: 'error',
                    title: 'Upload File Failed',
                    message: response.data.message,
                  });
                  setIsShowToastMessage(true);
                }
              })
              .catch((error) => {
                setToastMessage({
                  status: 'error',
                  title: 'Upload File Failed',
                  message: error.response?.data?.message || error,
                });
                setIsShowToastMessage(true);
                console.log('Upload to server error', error);
              });
          }),
        ).then(() => {
          if (rawQuery.length > 0) {
            setIsUpdateResultFile(true);
            setUrlResultFile(rawQuery[0].fileURL);
            // setCurrentFile(rawQuery[0].fileURL);
          }
        });
      }
      : () => {
        setToastMessage({
          status: 'warning',
          title: 'Validate',
          message: 'You Can Not Change Test Results File',
        });
        setIsShowToastMessage(true);
      },
  });

  // const files = acceptedFiles.map((file) => {
  //   const date = JSON.stringify(file.lastModifiedDate).substr(1, 10) + ' By Mrs. Meena Atluri'
  //   const typeFile = file.path.search('pdf') !== -1 ? pdf : doc

  //   return (
  //     <li key={file.path} className='flex items-center mt-2'>
  //       <img src={typeFile} alt={typeFile} className='w-10 h-10 mr-3' />
  //       <div>
  //         <p className='text-sm text-main-black font-semibold mb-1'>{file.path}</p>
  //         <p className='text-second-gray text-10px'>{date}</p>
  //       </div>
  //     </li>
  //   )
  // })
  const FileTypeImage = new Map([
    ['pdf', pdf],
    ['word', word],
    ['docx', word],
    ['xls', xls],
    ['xlsx', xls],
    ['xlsm', xls],
    ['xltx', xls],
    ['xltm', xls],
  ]);
  const typeFile = currentFile && currentFile.split('.').pop();
  const date = dateUpdate && new Date(dateUpdate).toUTCString().slice(5, 17);

  return (
    <div className="container-dropzone">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <img
          src={uploadFileSVG}
          alt="illustrations"
          className="w-20 h-20 2xl:w-32 2xl:h-32"
        />
        <div className="ml-5">
          <p className="font-semibold text-xl mb-2">
            {isDragActive ? 'Please drop PDF, DOC, DOCX' : 'Scan and Upload Test Results'}
          </p>
          <p className="text-sm text-second-gray">
            Drag 'n' drop some files here, or click to select files
          </p>
        </div>
      </div>
      {currentFile && (
        <aside>
          <h4 className="text-base font-semibold text-main-black mt-5">Uploaded Files</h4>
          <ul>
            <li className="flex items-center mt-2">
              <img src={pdf} alt={typeFile} className="w-10 h-10 mr-3" />
              <div>
                {/* <p className="text-sm text-main-black font-semibold mb-1">
                  {'Test Results'}
                </p> */}
                <a
                  className="text-xs text-main-black font-semibold mb-0.5"
                  href={currentFile}
                  rel="help noreferrer"
                  target="_blank"
                >
                  {'Test Results'}
                </a>
                <p className="text-second-gray text-10px">{date}</p>
              </div>
            </li>
          </ul>
        </aside>
      )}
    </div>
  );
}
