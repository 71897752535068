import admissionApi from 'api/admission';
import authApi from 'api/auth';
import centralAdminApi from 'api/central-admin';
import { useEffect, useState } from 'react';

function useSuggestUser({ textSearch }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const fetch = () => {
      authApi
        .findUsers({ textSearch, isGetAll: true }, controller.signal)
        .then((res) => {
          const tmp = res.data.data.objects.map((item) => {
            const { id, name, email, photoURL, phoneNumber } = item;
            return { id, name, email, photoURL, phoneNumber };
          });
          setData(tmp);
          setError(!res.data.success);
          setLoading(false);
        })
        .catch(() => {
          setData([]);
          setError(true);
          setLoading(false);
        });
    };
    let handle;
    if (textSearch) {
      handle = setTimeout(() => {
        fetch();
      }, 500);
    }

    return () => {
      if (handle) clearTimeout(handle);
      controller.abort();
    };
  }, [textSearch]);

  return { data, loading, error };
}

function useUser({ id }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetch = () => {
      // authApi
      //   .findUser({ id, roleName: 'parent' })
      authApi
        .getUserById(id)
        .then((res) => {
          setError(!res.data.success);
          setData(res.data.data);
          setLoading(false);
        })
        .catch(() => {
          setData(null);
          setError(true);
          setLoading(false);
        });
    };
    if (id) fetch();
  }, [id]);
  if (!id) return { data: null, setLoading: true, error: '' };
  return { data, loading, error };
}

function useListProgramAndClass({ campusId, year }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetch = () => {
      centralAdminApi
        .getListProgramAndClass(campusId, year)
        .then((res) => {
          const listProgramsAndClasses = res.data.data.map((data) => {
            const listClasses = [];
            (data?.programGrade || []).forEach((programGrade) => {
              if (programGrade?.class) {
                listClasses.push({
                  value: programGrade.id,
                  label: `${programGrade.class.name} (${programGrade.grade?.name})`,
                });
              }
            });
            return {
              value: data.id,
              label: data.name,
              childrens: listClasses,
            };
          });
          setData(listProgramsAndClasses);
          setError(!res.data.success);
          setLoading(false);
        })
        .catch(() => {
          setData(null);
          setError(true);
          setLoading(false);
        });
    };

    if (campusId) fetch();
  }, [campusId, year]);
  return { data, loading, error };
}

function useListYear({ campusId }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetch = () => {
      centralAdminApi
        .getListAcademicYear({ campusId })
        .then((res) => {
          const listYear = res.data.data.objects.map((academicYear) => ({
            value: academicYear.name,
            label: academicYear.name,
          }));
          setData(listYear);
          setError(!res.data.success);
          setLoading(false);
        })
        .catch(() => {
          setData(null);
          setError(true);
          setLoading(false);
        });
    };
    if (campusId) fetch();
  }, [campusId]);
  return { data, loading, error };
}

function useListStage(campusId) {
  const [result, setResult] = useState();
  const [index, setIndex] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    campusId &&
      admissionApi
        .getListStage({ campusId })
        .then((res) => {
          if (res?.data?.success) {
            if (Array.isArray(res.data.data)) {
              let applicationIndex, admissionTestIndex, principalMeetingIndex;
              res.data.data.forEach((item, idx) => {
                if (item.middle?.textDefault === 'Application') {
                  applicationIndex = idx;
                }
                if (item.middle?.textDefault === 'Admission Test') {
                  admissionTestIndex = idx;
                }
                if (item.middle?.textDefault === 'Principal Meeting') {
                  principalMeetingIndex = idx;
                }
              });
              setResult(res.data.data);
              setIndex((prev) => ({
                ...prev,
                applicationIndex,
                admissionTestIndex,
                principalMeetingIndex,
              }));
            }
          } else {
            setError(res.data.message);
          }
        })
        .catch((rej) => {
          setError(rej);
        });
  }, [campusId]);

  return {
    result,
    error,
    applicationIndex: index?.applicationIndex,
    admissionTestIndex: index?.admissionTestIndex,
    principalMeetingIndex: index?.principalMeetingIndex,
  };
}

export { useSuggestUser, useUser, useListProgramAndClass, useListYear, useListStage };
