import { callApi } from '@helper/call-api';

class ApprovalApi {
  // constructor() {
  //   this.baseUrl = `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/media`;
  // }

  getListProcess = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/process/list`,
      params,
    });
  };

  getListAction = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/action/list`,
      params,
    });
  };

  getListActionNotUsed = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/action/list?isUsed=false`,
      params,
    });
  };

  createApprovalRequest = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/request`,
      params,
      data,
    });
  };

  getApprovalProcess = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/process/search`,
      params,
    });
  };

  createApprovalProcess = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/process/check-permission-add`,
      params,
      data,
    });
  };
}

const approvalApi = new ApprovalApi();
export default approvalApi;
