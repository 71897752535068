import { languages, nationalities } from '../../../../../utils/utils';
import {
  categoryMap,
  componentCustomName,
  componentFieldName,
  componentSpecialName,
  validateName,
  variantName,
} from '../../../utils/index';

const componentMap = {
  [componentFieldName.drag]: (props) => {
    const { width, height } = props;
    return {
      type: categoryMap.user, // Type of the block
      name: componentFieldName.drag,
      traits: [],
      styles: {
        width: `${width}px`,
        height: `${height}px`,
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
        marginRight: '0.5rem',
        marginLeft: '0.5rem',
      },
    };
  },
  [componentFieldName.multiselect]: (type) => ({
    permission: type, // Type of the block
    category: categoryMap.field,
    variationOf: variantName.multiselect,
    name: componentFieldName.multiselect,
    error: false,
    textForError: '',
    traits: [
      {
        type: 'text', // Type of the trait
        name: 'title', // the name of attribute/property to use on component
        label: 'Label', // The label will see on Settings
        placeholder: 'This field is required', // The default value
        // defaultValue: 'Label', // The default value
        value: '', // value of the trait
        modifiable: true, // The trait modify
      },
      {
        type: 'text',
        name: 'options',
        label: 'Options',
        placeholder: "separate by ';'",
        value: 'Option1;Option2',
        modifiable: true,
      },
      {
        type: 'checkbox',
        name: 'isRequired',
        label: 'Required',
        value: true,
        modifiable: true,
      },
    ],
    styles: {
      width: '50%',
      // height: '3rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
    },
  }),
  [componentFieldName.dropdown]: (type) => ({
    permission: type, // Type of the block
    category: categoryMap.field,
    variationOf: variantName.dropdown,
    name: componentFieldName.dropdown,
    error: false,
    textForError: '',
    traits: [
      {
        type: 'text',
        name: 'title',
        label: 'Label',
        placeholder: 'This field is required',
        value: '',
        modifiable: true,
      },
      {
        type: 'text',
        name: 'options',
        label: 'Options',
        placeholder: "separate by ';'",
        value: 'option1;option2',
        modifiable: true,
      },
      {
        type: 'checkbox',
        name: 'isRequired',
        label: 'Required',
        value: true,
        modifiable: true,
      },
    ],
    styles: {
      width: '50%',
      // height: '3rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
    },
  }),
  [componentFieldName.radioGroup]: (type) => ({
    permission: type, // Type of the block
    category: categoryMap.field,
    variationOf: variantName.radioGroup,
    name: componentFieldName.radioGroup,
    error: false,
    textForError: '',
    traits: [
      {
        type: 'text',
        name: 'label',
        label: 'Label',
        value: '',
        modifiable: true,
      },
      {
        type: 'text',
        name: 'options',
        label: 'Options',
        placeholder: "separate by ';'",
        value: 'option1;option2',
        modifiable: true,
      },
      {
        type: 'checkbox',
        name: 'isRequired',
        label: 'Required',
        value: true,
        modifiable: true,
      },
    ],
    styles: {
      width: '25%',
      height: '4rem',
      paddingTop: '0rem',
      paddingBottom: '0rem',
      paddingRight: '0rem',
      paddingLeft: '0rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
      backgroundColor: '#ffffff',
    },
  }),
  [componentFieldName.textArea]: (type) => ({
    permission: type,
    category: categoryMap.field,
    variationOf: variantName.textArea,
    name: componentFieldName.textArea,
    error: false,
    textForError: '',
    traits: [
      {
        type: 'text',
        label: 'Label',
        name: 'label',
        placeholder: 'This field is required',
        value: '',
        modifiable: true,
      },
      {
        type: 'checkbox',
        name: 'isRequired',
        label: 'Required',
        value: true,
        modifiable: true,
      },
      {
        type: 'select',
        label: 'Validation',
        name: 'validate',
        options: Object.keys(validateName).map((item) => ({ label: item, value: item })),
        value: null,
        modifiable: true,
      },
    ],
    styles: {
      width: '40%',
      height: 'unset',
      paddingTop: '0rem',
      paddingBottom: '0rem',
      paddingRight: '0rem',
      paddingLeft: '0rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
      backgroundColor: '#ffffff',
    },
  }),
  [componentFieldName.paragraph]: (type) => ({
    permission: type,
    category: categoryMap.field,
    variationOf: variantName.paragraph,
    name: componentFieldName.paragraph,
    error: false,
    textForError: '',
    traits: [
      {
        type: 'text',
        label: 'Label',
        name: 'label',
        placeholder: 'This field is required',
        value: '',
        disabled: true,
        modifiable: true,
      },
      {
        type: 'text',
        label: 'Value',
        name: 'value',
        value: 'Paragraph',
        modifiable: true,
      },
    ],
    styles: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      paddingTop: '0rem',
      paddingBottom: '0rem',
      paddingRight: '0rem',
      paddingLeft: '0rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
      backgroundColor: '#ffffff',
    },
  }),
  [componentFieldName.photo]: (type) => ({
    permission: type,
    category: categoryMap.field,
    variationOf: variantName.photo,
    name: componentFieldName.photo,
    error: false,
    textForError: '',
    traits: [
      {
        type: 'text',
        name: 'label',
        label: 'Label',
        placeholder: 'This field is required',
        value: '',
        modifiable: true,
      },
      {
        type: 'checkbox',
        name: 'isRequired',
        label: 'Required',
        value: true,
        modifiable: true,
      },
    ],
    styles: {
      width: '5rem',
      height: '5rem',
      paddingTop: '0rem',
      paddingBottom: '0rem',
      paddingRight: '0rem',
      paddingLeft: '0rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
      backgroundColor: '#ffffff',
    },
  }),
  [componentFieldName.checkbox]: (type) => ({
    permission: type,
    category: categoryMap.field,
    variationOf: variantName.checkbox,
    name: componentFieldName.checkbox,
    error: false,
    textForError: '',
    traits: [
      {
        type: 'text',
        name: 'label',
        label: 'Label',
        placeholder: 'This field is required',
        value: '',
        modifiable: true,
      },
      {
        type: 'select',
        name: 'labelSide',
        label: 'Label Side',
        placeholder: 'This field is required',
        value: 'left',
        options: 'left;right',
        modifiable: true,
      },
    ],
    styles: {
      width: '30%',
      height: '2.5rem',
      paddingTop: '0rem',
      paddingBottom: '0rem',
      paddingRight: '0rem',
      paddingLeft: '0rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
      backgroundColor: '#ffffff',
    },
  }),
  [componentFieldName.datePicker]: (type) => ({
    permission: type,
    category: categoryMap.field,
    variationOf: variantName.datePicker,
    name: componentFieldName.datePicker,
    error: false,
    textForError: '',
    traits: [
      {
        type: 'text',
        name: 'label',
        label: 'Label',
        placeholder: 'This field is required',
        value: '',
        modifiable: true,
      },
      {
        type: 'checkbox',
        name: 'isRequired',
        label: 'Required',
        value: true,
        modifiable: true,
      },
    ],
    styles: {
      width: '30%',
      height: '1.2rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
    },
  }),
  [componentFieldName.image]: (type) => ({
    permission: type,
    category: categoryMap.field,
    variationOf: variantName.photo,
    name: componentFieldName.image,
    error: false,
    textForError: '',
    traits: [
      {
        type: 'text',
        name: 'label',
        label: 'Label',
        placeholder: 'This field is required',
        value: '',
        modifiable: true,
      },
      {
        type: 'checkbox',
        name: 'isRequired',
        label: 'Required',
        value: true,
        modifiable: true,
      },
    ],
    styles: {
      width: '15rem',
      height: '10rem',
      paddingTop: '0rem',
      paddingBottom: '0rem',
      paddingRight: '0rem',
      paddingLeft: '0rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
      backgroundColor: '#ffffff',
    },
  }),

  [componentFieldName.pageBreak]: (type) => ({
    permission: type,
    category: categoryMap.field,
    variationOf: variantName.pageBreak,
    name: componentFieldName.pageBreak,
    error: false,
    textForError: '',
    traits: [],
    styles: {
      height: '0.5rem',
    },
  }),
  [componentSpecialName.parentGuardian]: (type) => ({
    permission: type,
    category: categoryMap.special,
    variationOf: variantName.dropdown,
    name: componentSpecialName.parentGuardian,
    isHiddenOnHasValue: true,
    error: false,
    textForError: '',
    traits: [
      {
        type: 'text', // Type of the trait
        name: 'title', // the name of attribute/property to use on component
        label: 'Label', // The label will see on Settings
        placeholder: 'This field is required', // placeholder
        value: 'Parent/Guardian', // the value of  trait
        disabled: true,
        modifiable: true, // The trait modify
      },
      {
        type: 'checkbox',
        name: 'isRequired',
        label: 'Required',
        value: false,
        disabled: false,
        modifiable: true,
      },
    ],
    styles: {
      width: '50%',
      // height: '3rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
      backgroundColor: '#ffffff',
    },
  }),
  [componentCustomName.avatar]: (type) => ({
    permission: type,
    category: categoryMap.custom,
    variationOf: variantName.photo,
    name: componentCustomName.avatar,
    error: false,
    textForError: '',
    traits: [
      {
        type: 'text', // Type of the trait
        name: 'label', // the name of attribute/property to use on component
        label: 'Label', // The label will see on Settings
        placeholder: 'This field is required', // placeholder
        // defaultValue: 'Label', // The default value
        value: 'Avatar', // the value of  trait
        disabled: true,
        modifiable: true, // The trait modify
      },
      {
        type: 'checkbox',
        name: 'isRequired',
        label: 'Required',
        value: false,
        disabled: false,
        modifiable: true,
      },
    ],
    styles: {
      width: '5rem',
      height: '5rem',
      paddingTop: '0rem',
      paddingBottom: '0rem',
      paddingRight: '0rem',
      paddingLeft: '0rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
      backgroundColor: '#ffffff',
    },
  }),
  [componentCustomName.language]: (type) => ({
    permission: type,
    category: categoryMap.custom,
    variationOf: variantName.multiselect,
    name: componentCustomName.language,
    error: false,
    textForError: '',
    traits: [
      {
        type: 'text',
        name: 'title',
        label: 'Label',
        placeholder: 'This field is required',
        value: 'Language',
        disabled: true,
        modifiable: true,
      },
      {
        type: 'text',
        name: 'options',
        label: 'Options',
        placeholder: "separate by ';'",
        value: languages.map((item) => item.label).join(';'),
        disabled: false,
        modifiable: true,
      },
      {
        type: 'checkbox',
        name: 'isRequired',
        label: 'Required',
        value: false,
        disabled: false,
        modifiable: true,
      },
    ],
    styles: {
      width: '50%',
      // height: '3rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
    },
  }),

  [componentCustomName.nationality]: (type) => ({
    permission: type,
    category: categoryMap.custom,
    variationOf: variantName.dropdown,
    name: componentCustomName.nationality,
    error: false,
    textForError: '',
    traits: [
      {
        type: 'text',
        name: 'title',
        label: 'Label',
        placeholder: 'This field is required',
        value: 'Nationality',
        modifiable: true,
      },
      {
        type: 'text',
        name: 'options',
        label: 'Options',
        placeholder: "separate by ';'",
        value: nationalities.map((item) => item.label).join(';'),
        disabled: false,
        modifiable: true,
      },
      {
        type: 'checkbox',
        name: 'isRequired',
        label: 'Required',
        value: false,
        modifiable: true,
      },
    ],
    styles: {
      width: '50%',
      // height: '3rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
    },
  }),
  [componentCustomName.gender]: (type) => ({
    permission: type,
    category: categoryMap.custom,
    variationOf: variantName.radioGroup,
    name: componentCustomName.gender,
    error: false,
    textForError: '',
    traits: [
      {
        type: 'text',
        name: 'label',
        label: 'Label',
        value: 'Gender',
        disabled: true,
        modifiable: true,
      },
      {
        type: 'text',
        name: 'options',
        label: 'Options',
        placeholder: "separate by ';'",
        value: 'Male;Female',
        modifiable: true,
      },
      {
        type: 'checkbox',
        name: 'isRequired',
        label: 'Required',
        value: true,
        modifiable: true,
      },
    ],
    styles: {
      width: '25%',
      height: '4rem',
      paddingTop: '0rem',
      paddingBottom: '0rem',
      paddingRight: '0rem',
      paddingLeft: '0rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
      backgroundColor: '#ffffff',
    },
  }),
  [componentCustomName.relationship]: (type) => ({
    permission: type,
    category: categoryMap.custom,
    variationOf: variantName.dropdown,
    name: componentCustomName.relationship,
    error: false,
    textForError: '',
    traits: [
      {
        type: 'text',
        name: 'title',
        label: 'Label',
        placeholder: 'This field is required',
        value: 'Relationship',
        disabled: true,
        modifiable: true,
      },
      {
        type: 'text',
        name: 'options',
        label: 'Options',
        placeholder: "separate by ';'",
        value:
          'Father;Mother;Sibling;Brother;Sister;Grandmother;Grandfather;Uncle;Aunt;Cousin;Relative',
        disabled: false,
        modifiable: true,
      },
      {
        type: 'checkbox',
        name: 'isRequired',
        label: 'Required',
        value: true,
        disabled: false,
        modifiable: true,
      },
    ],
    styles: {
      width: '50%',
      // height: '3rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
    },
  }),

  [componentCustomName.phoneNumber]: (type) => ({
    permission: type,
    category: categoryMap.custom,
    variationOf: variantName.textArea,
    name: componentCustomName.phoneNumber,
    error: false,
    textForError: '',
    traits: [
      {
        type: 'text',
        name: 'label',
        label: 'Label',
        value: 'Phone Number',
        disabled: false,
        modifiable: true,
      },
      {
        type: 'checkbox',
        name: 'isRequired',
        label: 'Required',
        value: true,
        // disabled: true,
        modifiable: true,
      },
      {
        type: 'select',
        label: 'Validation',
        name: 'validate',
        options: Object.keys(validateName).map((item) => ({ label: item, value: item })),
        value: { label: validateName['Phone Number'], value: validateName['Phone Number'] },
        disabled: true,
        modifiable: true,
      },
    ],
    styles: {
      width: '40%',
      height: '2.3rem',
      paddingTop: '0rem',
      paddingBottom: '0rem',
      paddingRight: '0rem',
      paddingLeft: '0rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
      backgroundColor: '#ffffff',
    },
  }),
  [componentCustomName.email]: (type) => ({
    permission: type,
    category: categoryMap.custom,
    variationOf: variantName.textArea,
    name: componentCustomName.email,
    error: false,
    textForError: '',
    traits: [
      {
        type: 'text',
        name: 'label',
        label: 'Label',
        value: 'Email',
        disabled: false,
        modifiable: true,
      },
      {
        type: 'checkbox',
        name: 'isRequired',
        label: 'Required',
        value: true,
        disabled: true,
        modifiable: true,
      },
      {
        type: 'select',
        label: 'Validation',
        name: 'validate',
        options: Object.keys(validateName).map((item) => ({ label: item, value: item })),
        value: { label: validateName.Email, value: validateName.Email },
        disabled: true,
        modifiable: true,
      },
    ],
    styles: {
      width: '40%',
      height: '2.3rem',
      paddingTop: '0rem',
      paddingBottom: '0rem',
      paddingRight: '0rem',
      paddingLeft: '0rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
      backgroundColor: '#ffffff',
    },
  }),
  [componentCustomName.dateOfBirth]: (type) => ({
    permission: type,
    category: categoryMap.custom,
    variationOf: variantName.datePicker,
    name: componentCustomName.dateOfBirth,
    error: false,
    textForError: '',
    traits: [
      {
        type: 'text',
        name: 'label',
        label: 'Label',
        value: 'Date of Birth',
        disabled: false,
        modifiable: true,
      },
      {
        type: 'checkbox',
        name: 'isRequired',
        label: 'Required',
        value: false,
        disabled: false,
        modifiable: true,
      },
    ],
    styles: {
      width: '30%',
      height: '1.2rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
    },
  }),
  [componentCustomName.identityIdType]: (type) => ({
    permission: type,
    category: categoryMap.custom,
    variationOf: variantName.dropdown,
    name: componentCustomName.identityIdType,
    error: false,
    textForError: '',
    traits: [
      {
        type: 'text',
        name: 'title',
        label: 'Label',
        placeholder: 'This field is required',
        value: 'ID Type',
        disabled: true,
        modifiable: true,
      },
      {
        type: 'text',
        name: 'options',
        label: 'Options',
        placeholder: "separate by ';'",
        value:
          'Passport;Overseas Citizenship;PAN Card;Driving license;Ration card;Identity Certificate;Birth certificate',
        disabled: false,
        modifiable: true,
      },
      {
        type: 'checkbox',
        name: 'isRequired',
        label: 'Required',
        value: false,
        disabled: false,
        modifiable: true,
      },
    ],
    styles: {
      width: '50%',
      // height: '3rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
    },
  }),

  [componentSpecialName.anticipatedGradeOfEntry]: (type) => ({
    permission: type,
    category: categoryMap.special,
    variationOf: variantName.dropdown,
    name: componentSpecialName.anticipatedGradeOfEntry,
    cascadeName: componentSpecialName.enrollmentYear,
    error: false,
    textForError: '',
    traits: [
      {
        type: 'text',
        name: 'title',
        label: 'Label',
        placeholder: 'This field is required',
        value: 'Anticipated Grade of Entry',
        modifiable: true,
        disabled: true,
      },
      {
        type: 'checkbox',
        name: 'isRequired',
        label: 'Required',
        value: true,
        modifiable: true,
      },
    ],
    styles: {
      width: '50%',
      // height: '3rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
    },
  }),
  [componentSpecialName.enrollmentYear]: (type) => ({
    permission: type,
    category: categoryMap.special,
    variationOf: variantName.dropdown,
    name: componentSpecialName.enrollmentYear,
    cascadeValue: {},
    error: false,
    textForError: '',
    traits: [
      {
        type: 'text',
        name: 'title',
        label: 'Label',
        placeholder: 'This field is required',
        value: 'Enrollment Year',
        modifiable: true,
        disabled: true,
      },
      {
        type: 'checkbox',
        name: 'isRequired',
        label: 'Required',
        value: true,
        modifiable: true,
      },
    ],
    styles: {
      width: '50%',
      // height: '3rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
    },
  }),
  [componentSpecialName.campusName]: (type) => ({
    permission: type,
    category: categoryMap.special,
    variationOf: variantName.textArea,
    name: componentSpecialName.campusName,
    error: false,
    textForError: '',
    traits: [
      {
        type: 'text', // Type of the trait
        name: 'label', // the name of attribute/property to use on component
        label: 'Label', // The label will see on Settings
        placeholder: 'This field is required', // placeholder
        value: 'Campus', // the value of  trait
        modifiable: true, // The trait modify
        disabled: true,
      },
    ],
    styles: {
      width: '40%',
      height: '2.3rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
    },
  }),

  [componentSpecialName.document]: (type) => ({
    permission: type,
    category: categoryMap.special,
    variationOf: variantName.document,
    name: componentSpecialName.document,
    error: false,
    textForError: '',
    traits: [
      {
        type: 'text',
        name: 'label',
        label: 'Label',
        placeholder: 'This field is required',
        value: 'Upload documents',
        disabled: false,
        modifiable: true,
      },
      {
        type: 'checkbox',
        name: 'isRequired',
        label: 'Required',
        value: false,
        disabled: false,
        modifiable: true,
      },
    ],
    styles: {
      width: '50%',
      height: '6rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
    },
  }),
};

export { componentMap };
