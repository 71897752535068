import { useEffect, useMemo, useState, useContext } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import './App.css';

import BasicPanel from './components/BasicPanel/index.jsx';
import Communication from '@pages/admission-module/communication';
import AdmissionsOverview from '@pages/admission-module/admissions-overview';
import Application from '@pages/admission-module/application';
import AdmissionsWorld from '@pages/admissions-world';
import AdmissionApprovals from '@pages/approvals/process';
import Approvals from '@pages/approvals/request';
import MyRequests from '@pages/approvals/request/my-request';
import CampusDetail from '@pages/campus-details';
import CampusInformation from '@pages/campus-information';
import RoleAndPermissions from '@pages/campus-information/components/roles-permissions/index';
import CampusList from '@pages/campus-list';
import AcademicTerms from '@pages/campus-module/academic-terms';
import GradesPrograms from '@pages/campus-module/grades-programs';
import ProgramClasses from '@pages/campus-module/program-classes';
import Programmes from '@pages/campus-module/programmes';
import CampusListUsers from '@pages/campus-users/';
import Company from '@pages/company';
import CompanySettings from '@pages/company-settings';
import CreateUser from '@pages/create-user';
import Edit from '@pages/edit-user';
import UserSettings from '@pages/user-settings';
import UserList from '@pages/user-list';
import ImportUser from '@pages/import-user';
import Distribution from '@pages/distribution';
import EditTemplate from '@pages/edit-template';
import GiikiAdminReports from '@pages/giiki-admin-reports';
import GiikiWorld from '@pages/giiki-world';
import Header from '@pages/Header';
import Institutions from '@pages/Institutions/index.jsx';
import { ChangePassword } from '@pages/Login/components';
import Login from '@pages/Login/index.jsx';
import Managment from '@pages/managment';
import NotificationsPage from '@pages/notifications';
import Stages from '@pages/stages';
import TasksSettings from '@pages/tasks-settings';
import Tasks from '@pages/tasks';
import Dashboard from '@pages/dashboard';
import FinanceDashboard from '@pages/dashboard/FinanceDashboard';
import NewEnquiry from '@pages/enquiry/NewEnquiry';
import WhatsappCredentials from '@pages/whatsapp-credentials';
import { TeamBoardSetting } from '@pages/tasks/components/team';
import Forms from '@pages/forms';
import FormBuilder from '@pages/form-builder';
import { BankAccount, NewBankAccount } from '@pages/bank-account';
//Cashier
import { CashierHome, CashierPayment } from '@pages/cashier';
import {
  BloomWelcome,
  BloomCabinet,
  BloomForm,
  BloomDashboard,
  BloomDetails,
  BloomReview,
  BloomPayment,
  BloomFormMore,
  BloomChildAssessment,
  BloomChildPrincipal,
  BloomChildDocument,
  BloomChildFinance,
  BloomChildComplete,
} from '@pages/bloomingdale';
import GradesFeePayment from '@pages/campus-module/grades-fee-payment';
import FeeBooks from '@pages/finance-module/feebooks';
import FeebookEdit from '@pages/finance-module/feebook-edit';
import Scholarship from '@pages/finance-module/scholarship';
import AdmissionDocumentSettings from '@pages/admission-document-settings';
import ChildCustomStage from '@pages/bloomingdale/custom-stage/child-custom-stage';
import EditDocumentSettings from '@pages/edit-document-settings';
import BloomingdaleStage from '@pages/bloomingdale/bloomingdale-stage';
import ToastMessage from '@components/toast-message/toast-message';
import RoleAndPermissionsCentral from '@pages/campus-information/components/roles-permissions/central-role';
import EnquiryForm from '@pages/enquiry-form/EnquiryForm';
import GiikiHome from '@pages/Home';
import UnderConstruction from '@pages/under-construction';

import { centralPath, USER_ROLE } from './utils/utils';
import { campusAdmissionPath, campusSettingPath, campusFinancePath } from './utils/utils';
import { ToastMessageContext } from 'context/toast-context';
import { HOME_PATH } from './constant';
import { urls } from 'entities/urls';
import authApi from 'api/auth';
import useStorage from 'store/storage';
import { getPanelTitleAndRoutes } from '@helper/panel';
import financeApi from 'api/finance';
import { checkErrorApiFetch } from '@utils/check-error/api-error';
import Loading from '@components/loading';
import EditUser from '@pages/edit-user/edit-user';
import AdmissionTeamSettings from '@pages/admission-team-settings/admisson-team-settings';
import CreateCampus from '@pages/create-campus/create-campus';
import { useHistory } from 'react-router-dom';

// const BasicPanel = lazy(() => import('./components/BasicPanel/index.jsx'));
// const Communication = lazy(() => import('@pages/admission-module/communication'));
// const AdmissionsOverview = lazy(() => import('@pages/admission-module/admissions-overview'));
// const Application = lazy(() => import('@pages/admission-module/application'));
// const AdmissionTeamSettings = lazy(() => import('@pages/admission-team-settings'));
// const AdmissionsWorld = lazy(() => import('@pages/admissions-world'));
// const AdmissionApprovals = lazy(() => import('@pages/approvals/process'));
// const Approvals = lazy(() => import('@pages/approvals/request'));
// const MyRequests = lazy(() => import('@pages/approvals/request/my-request'));
// const CampusDetail = lazy(() => import('@pages/campus-details'));
// const CampusInformation = lazy(() => import('@pages/campus-information'));
// const RoleAndPermissions = lazy(() => import('@pages/campus-information/components/roles-permissions/index'));
// const CampusList = lazy(() => import('@pages/campus-list'));
// const AcademicTerms = lazy(() => import('@pages/campus-module/academic-terms'));
// const GradesPrograms = lazy(() => import('@pages/campus-module/grades-programs'));
// const ProgramClasses = lazy(() => import('@pages/campus-module/program-classes'));
// const Programmes = lazy(() => import('@pages/campus-module/programmes'));
// const CampusListUsers = lazy(() => import('@pages/campus-users/'));
// const Company = lazy(() => import('@pages/company'));
// const CompanySettings = lazy(() => import('@pages/company-settings'));
// const CreateUser = lazy(() => import('@pages/create-user'));
// const Edit = lazy(() => import('@pages/edit-user'));
// const UserSettings = lazy(() => import('@pages/user-settings'));
// const UserList = lazy(() => import('@pages/user-list'));
// const ImportUser = lazy(() => import('@pages/import-user'));
// const Distribution = lazy(() => import('@pages/distribution'));
// const EditTemplate = lazy(() => import('@pages/edit-template'));
// const GiikiAdminReports = lazy(() => import('@pages/giiki-admin-reports'));
// const GiikiWorld = lazy(() => import('@pages/giiki-world'));
// const Header = lazy(() => import('@pages/Header'));
// const Institutions = lazy(() => import('@pages/Institutions/index.jsx'));
// const ChangePassword = lazy(() => import('@pages/Login/components/ForgotPassword/index.jsx'));
// const Login = lazy(() => import('@pages/Login/index.jsx'));
// const Managment = lazy(() => import('@pages/managment'));
// const NotificationsPage = lazy(() => import('@pages/notifications'));
// const Stages = lazy(() => import('@pages/stages'));
// const TasksSettings = lazy(() => import('@pages/tasks-settings'));
// const Tasks = lazy(() => import('@pages/tasks'));
// const Dashboard = lazy(() => import('@pages/dashboard'));
// const FinanceDashboard = lazy(() => import('@pages/dashboard/FinanceDashboard'));
// const NewEnquiry = lazy(() => import('@pages/enquiry/NewEnquiry'));
// const WhatsappCredentials = lazy(() => import('@pages/whatsapp-credentials'));
// const TeamBoardSetting = lazy(() => import('@pages/tasks/components/team/team-board-setting'));
// const Forms = lazy(() => import('@pages/forms'));
// const FormBuilder = lazy(() => import('@pages/form-builder'));
// const BankAccount = lazy(() => import('@pages/bank-account/bank-account'));
// const NewBankAccount = lazy(() => import('@pages/bank-account/new-bank-account'));
// const CashierHome = lazy(() => import('@pages/cashier/CashierHome'));
// const CashierPayment = lazy(() => import('@pages/cashier/CashierPayment'));
// const BloomWelcome = lazy(() => import('@pages/bloomingdale/welcome/welcome'));
// const BloomCabinet = lazy(() => import('@pages/bloomingdale/cabinet/cabinet'));
// const BloomForm = lazy(() => import('@pages/bloomingdale/form/form'));
// const BloomDashboard = lazy(() => import('@pages/bloomingdale/dashboard/dashboard'));
// const BloomDetails = lazy(() => import('@pages/bloomingdale/details/details'));
// const BloomReview = lazy(() => import('@pages/bloomingdale/review/review'));
// const BloomPayment = lazy(() => import('@pages/bloomingdale/payment/payment'));
// const BloomFormMore = lazy(() => import('@pages/bloomingdale/form-more/form-more'));
// const BloomChildAssessment = lazy(() => import('@pages/bloomingdale/child-assessment/child-assessment'));
// const BloomChildPrincipal = lazy(() => import('@pages/bloomingdale/child-principal/child-principal'));
// const BloomChildDocument = lazy(() => import('@pages/bloomingdale/child-document/child-document'));
// const BloomChildFinance = lazy(() => import('@pages/bloomingdale/finance/child-finance-approval'));
// const BloomChildComplete = lazy(() => import('@pages/bloomingdale/child-complete/child-complete'));
// const GradesFeePayment = lazy(() => import('@pages/campus-module/grades-fee-payment'));
// const FeeBooks = lazy(() => import('@pages/finance-module/feebooks'));
// const FeebookEdit = lazy(() => import('@pages/finance-module/feebook-edit'));
// const Scholarship = lazy(() => import('@pages/finance-module/scholarship'));
// const AdmissionDocumentSettings = lazy(() => import('@pages/admission-document-settings'));
// const ChildCustomStage = lazy(() => import('@pages/bloomingdale/custom-stage/child-custom-stage'));
// const EditDocumentSettings = lazy(() => import('@pages/edit-document-settings'));
// const BloomingdaleStage = lazy(() => import('@pages/bloomingdale/bloomingdale-stage'));
// const ToastMessage = lazy(() => import('@components/toast-message/toast-message'));
// const RoleAndPermissionsCentral = lazy(() => import('@pages/campus-information/components/roles-permissions/central-role'));
// const EnquiryForm = lazy(() => import('@pages/enquiry-form/EnquiryForm'));
// const GiikiHome = lazy(() => import('@pages/Home'));
// const UnderConstruction = lazy(() => import('@pages/under-construction'));

const PERIOD_24H = 24 * 60 * 60;
const PERIOD_30_DAYS = 30 * PERIOD_24H;

function App() {
  const [burger, setBurger] = useState(false);
  const [tenantId, setTenantId] = useState('giiki-test-1-a9hhl-2uj1p');
  const location = useLocation();
  const pathname = location.pathname;
  const {
    getAuth,
    getIsGiikiAdmin,
    getPeriod,
    getRememberMe,
    updateNavbarTitle,
    updateRoutes,
    updateCampuses,
    updateCurrentCampus,
    getCurrentCampus,
    updateYears,
    updateCurrentYear,
    updatePreviousYear,
    resetStorage,
  } = useStorage();
  const [isHover, setIsHover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowHomePage, setShowHomePage] = useState(false);
  const userLogin = getAuth();
  const campus = getCurrentCampus();
  const companyId = userLogin?.companyId;
  const isGiikiAdmin = getIsGiikiAdmin();
  const period = getPeriod();
  const rememberMe = getRememberMe();
  const history = useHistory();
  const { toastMessage, setToastMessage, isShowToastMessage, setIsShowToastMessage } =
    useContext(ToastMessageContext);

  const fetchCampuses = async () => {
    setIsLoading(true);
    const res = await authApi.getListCampusUser({ isGetAll: true });
    if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, '', false)) {
      updateCampuses(res.data.data.objects);
      const noChangeCampus = res.data.data.objects.findIndex(
        (item) => item.id === campus?.id,
      );
      if (noChangeCampus === -1) {
        updateCurrentCampus(res.data.data.objects[0]);
      }
    } else {
      updateCampuses([]);
      updateCurrentCampus(null);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    // const isGiikiAdmin = userLogin?.roles?.some((role) => role.id === giikiAdminId);
    // updateIsGiikiAdmin(isGiikiAdmin);
    if (userLogin && !isGiikiAdmin) fetchCampuses();
  }, [userLogin]);

  const fetchYears = async () => {
    const res = await financeApi.getListAcademic({ campusId: campus.id });
    if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, '', false)) {
      updateYears(res.data?.data?.objects);
      updateCurrentYear(res.data?.data?.objects?.[0]);
      if (res.data?.data?.objects?.[1]) {
        updatePreviousYear(res.data?.data?.objects?.[1]);
      } else {
        updatePreviousYear();
      }
    }
  };

  useEffect(() => {
    if (campus?.id) fetchYears();
  }, [campus]);

  const enquiryPathName = '/enquiry-form';
  let isEnquiryForm;
  // remember to shorten this conditions
  if (window?.location?.origin?.includes(process.env.REACT_APP_ALLOWED_ORIGIN)) {
    if (window.location.pathname === enquiryPathName) {
      isEnquiryForm = true;
    }
  }

  useEffect(() => {
    if (period) {
      let expired = PERIOD_24H;
      if (rememberMe) expired = PERIOD_30_DAYS;
      if (Math.floor(Date.now() / 1000) - period > expired) {
        resetStorage();
        history.push('/login/type');
      }
    }
    if (pathname === HOME_PATH) {
      updateNavbarTitle(null);
      updateRoutes([]);
    } else {
      const { title, routes: dataRoutes } = getPanelTitleAndRoutes(pathname);
      updateNavbarTitle(title);
      updateRoutes(dataRoutes);
    }
    setBurger(false);
  }, [pathname]);

  const isOpen = () => {
    if (location.pathname === HOME_PATH) return;
    setBurger(!burger);
  };

  useEffect(() => {
    const href = process.env.REACT_APP_HREF;
    if (window.location.hostname.startsWith(href)) {
      setShowHomePage(true);
      return;
    }
    const subdomain = window.location.hostname.split('.')[0];
    if (!!subdomain) {
      authApi
        .checkDomain({ domainName: subdomain })
        .then((results) => {
          const tenantId = results.data.data?.tenantId;
          if (tenantId) {
            setTenantId(tenantId);
          } else {
            setShowHomePage(true);
          }
        })
        .catch((error) => {
          setShowHomePage(true);
        });
    } else {
      setShowHomePage(true);
    }
  }, []);

  const isShowParentPortal = useMemo(() => {
    return userLogin?.roles?.some((role) => role.name === USER_ROLE.PARENT);
  }, []);

  const getClassCommon = () => {
    if (isShowParentPortal) return '';
    // if (location.pathname === HOME_PATH) return 'navbar-menu-close';
    // if (burger) return 'navbar-menu-open';
    // else return 'navbar-menu-close';
    return '';
  };

  // let app = <></>;
  // if (isEnquiryForm) {
  //   app = <EnquiryForm />;
  // } else if (isShowHomePage) {
  //   app = <></>;
  // } else if (userLogin) {
  //   if (isShowParentPortal) {
  //     app = (
  //       <>
  //         {/* <div onMouseMove={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
  //         <ToastMessage
  //           setIsShowToastMessage={setIsShowToastMessage}
  //           status={toastMessage.status}
  //           title={toastMessage.title}
  //           message={toastMessage.message}
  //           isHover={isHover}
  //           setIsHover={setIsHover}
  //         />
  //         </div> */}
  //         <Switch>
  //           <Route
  //             exact
  //             path=urls.parent.stage
  //             render={() => <BloomingdaleStage />}
  //           />
  //           <Route
  //             exact
  //             path=urls.parent.welcome
  //             render={() => <BloomWelcome />}
  //           />
  //           <Route
  //             exact
  //             path=urls.parent.cabinet
  //             render={() => <BloomCabinet />}
  //           />
  //           <Route
  //             exact
  //             path=urls.parent.form
  //             render={() => <BloomForm />}
  //           />
  //           <Route
  //             exact
  //             path=urls.parent.dashboard
  //             render={() => <BloomDashboard />}
  //           />
  //           <Route
  //             exact
  //             path=urls.parent.details
  //             render={() => <BloomDetails />}
  //           />
  //           <Route
  //             exact
  //             path=urls.parent.review
  //             render={() => <BloomReview />}
  //           />
  //           <Route
  //             exact
  //             path=urls.parent.payment
  //             render={() => <BloomPayment />}
  //           />
  //           <Route
  //             exact
  //             path=urls.parent.payment
  //             render={() => <BloomFormMore />}
  //           />
  //           <Route
  //             exact
  //             path=urls.parent.childAssessment
  //             render={() => <BloomChildAssessment />}
  //           />
  //           <Route
  //             exact
  //             path=urls.parent.childPrincipal
  //             render={() => <BloomChildPrincipal />}
  //           />
  //           <Route
  //             exact
  //             path=urls.parent.childDocument
  //             render={() => <BloomChildDocument />}
  //           />
  //           <Route
  //             exact
  //             path=urls.parent.childFinance
  //             render={() => <BloomChildFinance />}
  //           />
  //           <Route
  //             exact
  //             path=urls.parent.childComplete
  //             render={() => <BloomChildComplete />}
  //           />
  //           <Route
  //             exact
  //             path=urls.parent.customStage
  //             render={() => <ChildCustomStage />}
  //           />
  //         </Switch>
  //       </>
  //     );
  //   } else {
  //     app = (
  //       <>
  //         {/* <div onMouseMove={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
  //           <ToastMessage
  //             setIsShowToastMessage={setIsShowToastMessage}
  //             status={toastMessage.status}
  //             title={toastMessage.title}
  //             message={toastMessage.message}
  //             isHover={isHover}
  //             setIsHover={setIsHover}
  //           />
  //         </div> */}
  //         <BasicPanel
  //           burger={burger}
  //           setBurger={setBurger}
  //           isOpen={isOpen}
  //           // isCentralRole={isCentralRole}
  //           // campuses={campuses}
  //         />
  //         <Header
  //           burger={burger}
  //           isOpen={isOpen}
  //           header={getHeaderName()}
  //           // campuses={campuses}
  //         />
  //         <div className={`max-w-[100vw] ${getClassCommon()}`}>
  //           <Switch>
  //             <Route exact path="/" render={() => <Redirect to={'/home'} />} />
  //             {/* finance module */}
  //             <Route
  //               exact
  //               path="/finance/dashboard"
  //               render={() => <FinanceDashboard burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path={urls.central.finance.feebooks}
  //               render={() => <FeeBooks burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path={urls.central.finance.feebookEdit}
  //               render={() => <FeebookEdit burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path={urls.central.finance.scholarships}
  //               render={() => <Scholarship burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               // path={urls.central.admission.forms}
  //               path={urls.forms}
  //               render={() => <Forms burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path={urls.formBuilder}
  //               render={() => <FormBuilder burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               // path="/giiki/institutions"
  //               path={urls.giiki.institutions.base}
  //               render={() => <Company burger={burger} isOpen={isOpen} />}
  //             />
  //             <Route
  //               exact
  //               path="/giiki/institutions/:id"
  //               // path={`${urls.giiki.createInstitution}/:id`}
  //               render={() => <Institutions burger={burger} isOpen={isOpen} />}
  //             />
  //             <Route
  //               exact
  //               path={urls.campus.admission.dashboard}
  //               render={() => <Dashboard burger={burger} />}
  //             />
  //             {/* <Route
  //               exact
  //               path="/giiki"
  //               render={() => <Redirect to={'/giiki/company'} />}
  //             /> */}
  //             {/* <Route
  //               exact
  //               // path="/giiki/home"
  //               path={urls.giiki.home}
  //               render={() => (
  //                 <GiikiHome
  //                   burger={burger}
  //                   setBurger={setBurger}
  //                   campuses={campuses}
  //                 />
  //               )}
  //             /> */}
  //             {/* <Route
  //               exact
  //               path="/giiki/company/settings"
  //               render={() => <Redirect to={'/giiki/company'} />}
  //             />
  //             <Route
  //               exact
  //               path="/giiki/company"
  //               render={() => <Company burger={burger} isOpen={isOpen} />}
  //             /> */}
  //             <Route
  //               exact
  //               // path="/giiki/reports/bug"
  //               path={urls.giiki.report.bug}
  //               render={() => <GiikiAdminReports burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path={urls.giiki.report.issue}
  //               // path="/giiki/reports/issue"
  //               render={() => <GiikiAdminReports burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path={urls.giiki.report.user}
  //               // path="/giiki/reports/user"
  //               render={() => <GiikiAdminReports burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               // path="/giiki/users1"
  //               path={urls.giiki.users}
  //               render={() => <UserList burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path={urls.giiki.institutions.company.base}
  //               // path="/giiki/settings"
  //               render={() => {
  //                 if (companyId) return <CompanySettings burger={burger} />;
  //                 // return <Redirect to={'/giiki/company'} />;
  //                 return <Redirect to={urls.giiki.institutions.base} />;
  //               }}
  //             />
  //             {/* Campus UI */}
  //             <Route exact path="/detail">
  //               <Redirect to="/detail/company" />
  //             </Route>
  //             <Route
  //               exact
  //               path={urls.campus.setting.users}
  //               render={() => <CampusListUsers burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               // path='/campus/setting/academic-year/:id'
  //               path={urls.campus.setting.academicYears}
  //               render={() => <AcademicTerms burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path="/campus/grades"
  //               render={() => <GradesPrograms burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path={urls.campus.setting.programClasses}
  //               render={() => <ProgramClasses burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path="/central-admin/campus/fee-payment"
  //               render={() => <GradesFeePayment burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path="/central-admin/campus/programmes/:id"
  //               render={() => <Programmes burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               // path="/central/setup/bank-accounts"
  //               path={urls.campus.finance.scholarships}
  //               render={() => <Scholarship burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               // path="/central/setup/bank-accounts"
  //               path={urls.campus.finance.feebooks}
  //               render={() => <FeeBooks burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               // path="/central/setup/bank-accounts"
  //               path={urls.campus.finance.feebookEdit}
  //               render={() => <FeebookEdit burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               // path="/central/setup/bank-accounts"
  //               path={urls.central.finance.bankAccounts}
  //               render={() => <BankAccount burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               // path="/central-admin/new-bank-account"
  //               path={urls.central.finance.newBankAccount}
  //               render={() => <NewBankAccount burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path="/central-admin/dashboard"
  //               render={() => <FinanceDashboard burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path="/detail/campus-information"
  //               render={() => <CampusInformation burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path="/central-admin/campus/general"
  //               render={() => <CampusInformation burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path={urls.central.setup.users}
  //               render={() => <UserList burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path="/central-admin/settings/whatsapp-credentials"
  //               render={() => <WhatsappCredentials burger={burger} />}
  //             />
  //             {/* <Route
  //               exact
  //               path="/detail/approvals"
  //               render={() => <AdmissionApprovals burger={burger} />}
  //             /> */}
  //             <Route
  //               path="/detail/:id"
  //               render={() => <CampusDetail burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path={urls.campus.admission.enquiriesOverview}
  //               render={() => <AdmissionsOverview burger={burger} />}
  //             />

  //             <Route
  //               exact
  //               path={urls.campus.setting.roles}
  //               render={() => <RoleAndPermissions burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path="/campus/campus-information"
  //               render={() => <CampusInformation burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path="/central/setup/campuses"
  //               render={() => <CampusList burger={burger} isOpen={isOpen} />}
  //             />
  //             <Route
  //               exact
  //               path="/detail/campus-information"
  //               render={() => <CampusInformation burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path="/central-admin/campuses/general"
  //               render={() => <CampusInformation burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path={urls.central.setup.roles}
  //               render={() => <RoleAndPermissionsCentral burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path={urls.central.approval.baseetting}
  //               render={() => <AdmissionApprovals burger={burger} />}
  //             />
  //             <Route
  //               path="/detail/:id"
  //               render={() => <CampusDetail burger={burger} />}
  //             />
  //             {/* <Route path="/admin/dashboard" render={() => <GiikiAdminDashBoard />} /> */}
  //             <Route
  //               // path="/giiki/dashboard"
  //               path={urls.giiki.dashboard}
  //               render={() => <UnderConstruction burger={burger} />}
  //             />
  //             <Route
  //               // path="/giiki/role-permission"
  //               path={urls.giiki.roles}
  //               render={() => <RoleAndPermissions burger={burger} />}
  //             />
  //             {/* <Route exact path="/worlds">
  //               <Redirect to="/worlds/giiki" />
  //             </Route>
  //             <Route
  //               exact
  //               path="/worlds/giiki"
  //               render={() => <GiikiWorld burger={burger} />}
  //             /> */}
  //             {/* Admission settings */}
  //             <Route
  //               path={urls.campus.admission.applications}
  //               // path="/campus/admission/application/:id"
  //               render={() => <Application burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path="/worlds/admissions"
  //               render={() => <AdmissionsWorld burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path="/managment"
  //               render={() => <Managment burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path={urls.campus.admission.setting.workflow}
  //               render={() => <Stages burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               // path="/central-admin/settings/notification"
  //               // path='/central/communication-notification/notifications'
  //               path={urls.central.communicationNotification.notifications}
  //               render={() => <NotificationsPage burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path="/central/integration/whatsapps"
  //               // path="/settings/whatsapp-credentials"
  //               render={() => <WhatsappCredentials burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path="/settings/tasks-settings"
  //               render={() => <TasksSettings burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path="/settings/user-settings"
  //               render={() => <UserSettings burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path="/settings/change-password"
  //               render={() => <ChangePassword burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path={
  //                 urls.central.communicationNotification.communicationTemplates
  //               }
  //               render={() => <Communication burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path={urls.central.communicationNotification.templateEdit}
  //               render={() => <EditTemplate burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path={urls.campus.admission.setting.admissionTeams}
  //               render={() => <AdmissionTeamSettings burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               // path={urls.campus.admission.setting.documents}
  //               path={urls.documentSetting}
  //               render={() => <AdmissionDocumentSettings burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               // path="/admission/setting/document/edit"
  //               path={urls.documentEdit}
  //               render={() => <EditDocumentSettings burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path={urls.campus.admission.setting.distributions}
  //               render={() => <Distribution burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               // path="/approvals"
  //               path={urls.central.approval.base}
  //               render={() => <Approvals burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path="/approvals/my-requests"
  //               render={() => <MyRequests burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               // path="/create-user"
  //               path={urls.createUser}
  //               render={() => <CreateUser burger={burger} />}
  //             />
  //             {/* <Route
  //               exact
  //               path="/create-user-for-company"
  //               render={() => <CreateUser burger={burger} />}
  //             /> */}
  //             <Route
  //               exact
  //               // path="/import-user"
  //               path={urls.importUser}
  //               render={() => <ImportUser burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               // path="/edit-user"
  //               path={urls.editUser}
  //               render={() => <Edit burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               // path="/enquiry/new"
  //               path={urls.createEnquiry}
  //               render={() => <NewEnquiry burger={burger} />}
  //             />

  //             <Route
  //               exact
  //               path="/tasks/team-setting"
  //               render={() => <TeamBoardSetting burger={burger} />}
  //             />
  //             <Route path="/tasks" render={() => <Tasks burger={burger} />} />
  //             <Route
  //               exact
  //               path={urls.campus.admission.cashierPayments}
  //               render={() => <CashierPayment burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path={urls.campus.finance.cashierPayment}
  //               render={() => <CashierPayment burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path={urls.central.finance.cashierScreen}
  //               render={() => <CashierHome burger={burger} />}
  //             />
  //             <Route
  //               exact
  //               path={urls.home}
  //               render={() => (
  //                 <GiikiHome
  //                   burger={burger}
  //                   setBurger={setBurger}
  //                   // campuses={campuses}
  //                 />
  //               )}
  //             />
  //             <Route
  //               exact
  //               path={campusAdmissionPath}
  //               render={() => <UnderConstruction />}
  //             />
  //             <Route
  //               exact
  //               path={campusSettingPath}
  //               render={() => <UnderConstruction />}
  //             />
  //             <Route
  //               exact
  //               path={campusFinancePath}
  //               render={() => <UnderConstruction />}
  //             />
  //             <Route
  //               exact
  //               path={campusFinancePath}
  //               render={() => <UnderConstruction />}
  //             />

  //             <Route
  //               exact
  //               path="/campus/admission-settings"
  //               render={() => <UnderConstruction />}
  //             />
  //             <Route
  //               exact
  //               path={centralPath.finance}
  //               render={() => <UnderConstruction />}
  //             />
  //             <Route
  //               exact
  //               path={centralPath.setting}
  //               render={() => <UnderConstruction />}
  //             />

  //             <Route
  //               exact
  //               path={urls.home}
  //               render={() => <GiikiHome burger={burger} />}
  //             />

  //             <Route path="*" element={<Redirect to={urls.home} />} />
  //           </Switch>
  //         </div>
  //       </>
  //     )
  //   }
  // } else {
  //   app = (
  //     <>
  //       <Route exact
  //           path="/"
  //           render={() => <Redirect to={'/login/type'} />}
  //       />
  //       <Route exact
  //         path="/login/:id"
  //         render={() => <Login burger={burger} tenantId={tenantId} />}
  //       />
  //     </>
  //   )
  // }
  // return (
  //   <Box>
  //     <Suspense fallback={<div>Loading...</div>}>
  //     {isShowToastMessage && (
  //       <div onMouseMove={() => {setIsHover(true)}} onMouseLeave={() => {setIsHover(false)}}>
  //         <ToastMessage
  //           setIsShowToastMessage={setIsShowToastMessage}
  //           status={toastMessage.status}
  //           title={toastMessage.title}
  //           message={toastMessage.message}
  //           isHover={isHover}
  //           setIsHover={setIsHover}
  //         />
  //       </div>
  //     )}
  //       {app}
  //     </Suspense>
  //   </Box>
  // )

  if (isLoading)
    return (
      <div className="w-screen h-screen justify-center items-center flex">
        <Loading />
      </div>
    );

  if (isEnquiryForm) return <EnquiryForm />;
  if (isShowHomePage) return <></>;
  const toast = (
    <div
      onMouseMove={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      <ToastMessage
        setIsShowToastMessage={setIsShowToastMessage}
        status={toastMessage.status}
        title={toastMessage.title}
        message={toastMessage.message}
        isHover={isHover}
        setIsHover={setIsHover}
      />
    </div>
  );
  if (!userLogin || !userLogin?.roles)
    return (
      <>
        {isShowToastMessage && toast}
        <Switch>
          <Route exact path="/" render={() => <Redirect to={'/login/type'} />} />
          <Route
            exact
            path="/login/:id"
            render={() => <Login burger={burger} tenantId={tenantId} />}
          />
        </Switch>
      </>
    );
  if (isGiikiAdmin)
    return (
      <>
        {isShowToastMessage && toast}
        <BasicPanel burger={burger} setBurger={setBurger} isOpen={isOpen} />
        <Header burger={burger} isOpen={isOpen} />
        <Switch>
          <Route
            path={urls.giiki.dashboard}
            render={() => <UnderConstruction burger={burger} />}
          />
          <Route
            path={urls.giiki.roles}
            render={() => <RoleAndPermissions burger={burger} />}
          />
          <Route
            exact
            path={urls.giiki.institutions.base}
            render={() => <Company burger={burger} isOpen={isOpen} />}
          />
          <Route
            exact
            path={urls.giiki.report.bug}
            render={() => <GiikiAdminReports burger={burger} />}
          />
          <Route
            exact
            path={urls.giiki.report.issue}
            render={() => <GiikiAdminReports burger={burger} />}
          />
          <Route
            exact
            path={urls.giiki.report.user}
            render={() => <GiikiAdminReports burger={burger} />}
          />
          <Route
            exact
            path={urls.giiki.users.base}
            render={() => <UserList burger={burger} />}
          />
          <Route
            exact
            path={urls.giiki.users.create}
            render={() => <CreateUser burger={burger} />}
          />
          <Route
            exact
            path={urls.giiki.users.edit}
            render={() => <Edit burger={burger} />}
          />
          <Route exact path={urls.giiki.setting} render={() => <UnderConstruction />} />
          <Route
            exact
            path={urls.giiki.institutions.company.base}
            render={() => {
              if (companyId) return <CompanySettings burger={burger} />;
              return <Redirect to={urls.giiki.institutions.base} />;
            }}
          />
          <Route
            exact
            path={urls.giiki.institutions.company.campus.general}
            render={() => <CampusInformation burger={burger} />}
          />
          <Route
            exact
            path={urls.giiki.institutions.company.campus.edit}
            render={() => <CampusDetail burger={burger} />}
          />
          <Route
            exact
            path={urls.giiki.institutions.company.campus.importUser}
            render={() => <ImportUser burger={burger} />}
          />
          <Route
            exact
            path={urls.giiki.institutions.company.campus.create}
            render={() => <CreateCampus burger={burger} />}
          />
          <Route
            exact
            path={urls.giiki.institutions.company.createUser}
            render={() => <CreateUser burger={burger} />}
          />
          <Route
            exact
            path="/giiki/institutions/:id"
            render={() => <Institutions burger={burger} isOpen={isOpen} />}
          />
          <Route exact path="*" render={() => <Redirect to={urls.giiki.dashboard} />} />
        </Switch>
      </>
    );

  if (isShowParentPortal)
    return (
      <>
        {isShowToastMessage && toast}
        <Switch>
          <Route exact path={urls.parent.stage} render={() => <BloomingdaleStage />} />
          <Route exact path={urls.parent.welcome} render={() => <BloomWelcome />} />
          <Route exact path={urls.parent.cabinet} render={() => <BloomCabinet />} />
          <Route exact path={urls.parent.form} render={() => <BloomForm />} />
          <Route exact path={urls.parent.dashboard} render={() => <BloomDashboard />} />
          <Route exact path={urls.parent.details} render={() => <BloomDetails />} />
          <Route exact path={urls.parent.review} render={() => <BloomReview />} />
          <Route exact path={urls.parent.payment} render={() => <BloomPayment />} />
          <Route exact path={urls.parent.formMore} render={() => <BloomFormMore />} />
          <Route
            exact
            path={urls.parent.childAssessment}
            render={() => <BloomChildAssessment />}
          />
          <Route
            exact
            path={urls.parent.childPrincipal}
            render={() => <BloomChildPrincipal />}
          />
          <Route
            exact
            path={urls.parent.childDocument}
            render={() => <BloomChildDocument />}
          />
          <Route
            exact
            path={urls.parent.childFinance}
            render={() => <BloomChildFinance />}
          />
          <Route
            exact
            path={urls.parent.childComplete}
            render={() => <BloomChildComplete />}
          />
          <Route
            exact
            path={urls.parent.customStage}
            render={() => <ChildCustomStage />}
          />
          <Route exact path="/" render={() => <Redirect to={urls.parent.welcome} />} />
        </Switch>
      </>
    );
  return (
    <>
      {isShowToastMessage && toast}
      {pathname !== HOME_PATH && (
        <>
          <BasicPanel burger={burger} setBurger={setBurger} isOpen={isOpen} />
          <Header burger={burger} isOpen={isOpen} />
        </>
      )}
      <div className={`max-w-[100vw] ${getClassCommon()}`}>
        <Switch>
          <Route exact path="/" render={() => <Redirect to={'/home'} />} />
          {/* finance module */}
          <Route
            exact
            path="/finance/dashboard"
            render={() => <FinanceDashboard burger={burger} />}
          />
          <Route
            exact
            path={urls.central.finance.feebooks}
            render={() => <UnderConstruction />}
          />
          <Route
            exact
            path={urls.central.finance.feebookEdit}
            render={() => <FeebookEdit burger={burger} />}
          />
          <Route
            exact
            path={urls.central.finance.scholarships}
            render={() => <UnderConstruction />}
          />

          <Route
            exact
            path={urls.central.admission.forms}
            render={() => <Forms burger={burger} />}
          />
          <Route
            exact
            path={urls.central.admission.formBuilder}
            render={() => <FormBuilder burger={burger} />}
          />
          <Route
            exact
            path={urls.central.admission.documents}
            render={() => <AdmissionDocumentSettings burger={burger} />}
          />
          <Route
            exact
            path={urls.central.admission.editDocument}
            render={() => <AdmissionDocumentSettings burger={burger} />}
          />
          <Route
            exact
            path={urls.campus.admission.dashboard}
            render={() => <Dashboard burger={burger} />}
          />

          {/* Campus UI */}
          <Route exact path="/detail">
            <Redirect to="/detail/company" />
          </Route>
          <Route
            exact
            path={urls.campus.setting.users.base}
            render={() => <CampusListUsers burger={burger} />}
          />
          <Route
            exact
            path={urls.campus.setting.users.create}
            render={() => <CreateUser burger={burger} />}
          />
          <Route
            exact
            path={urls.campus.setting.users.edit}
            render={() => <Edit burger={burger} />}
          />
          <Route
            exact
            path={urls.campus.setting.academicYears}
            render={() => <AcademicTerms burger={burger} />}
          />
          <Route
            exact
            path="/campus/grades"
            render={() => <GradesPrograms burger={burger} />}
          />
          <Route
            exact
            path={urls.campus.setting.programClasses}
            render={() => <ProgramClasses burger={burger} />}
          />
          <Route
            exact
            path="/central-admin/campus/fee-payment"
            render={() => <GradesFeePayment burger={burger} />}
          />
          <Route
            exact
            path="/central-admin/campus/programmes/:id"
            render={() => <Programmes burger={burger} />}
          />
          <Route
            exact
            path={urls.campus.finance.scholarships}
            render={() => <Scholarship burger={burger} />}
          />
          <Route
            exact
            path={urls.campus.finance.feebooks}
            render={() => <FeeBooks burger={burger} />}
          />
          <Route
            exact
            path={urls.campus.finance.feebookEdit}
            render={() => <FeebookEdit burger={burger} />}
          />
          <Route
            exact
            path={urls.central.finance.bankAccounts}
            render={() => <BankAccount burger={burger} />}
          />
          <Route
            exact
            path={urls.central.finance.newBankAccount}
            render={() => <NewBankAccount burger={burger} />}
          />
          <Route
            exact
            path="/central-admin/dashboard"
            render={() => <FinanceDashboard burger={burger} />}
          />
          <Route
            exact
            path="/detail/campus-information"
            render={() => <CampusInformation burger={burger} />}
          />
          <Route
            exact
            path={urls.central.setup.campus.general}
            render={() => <CampusInformation burger={burger} />}
          />
          <Route
            exact
            path={urls.central.setup.users.base}
            render={() => <UserList burger={burger} />}
          />
          <Route
            exact
            path={urls.central.setup.users.create}
            render={() => <CreateUser burger={burger} />}
          />
          <Route
            exact
            path={urls.central.setup.users.edit}
            render={() => <Edit burger={burger} />}
          />
          <Route
            exact
            path="/central-admin/settings/whatsapp-credentials"
            render={() => <WhatsappCredentials burger={burger} />}
          />
          {/* <Route
            exact
            path="/detail/approvals"
            render={() => <AdmissionApprovals burger={burger} />}
          /> */}
          <Route
            exact
            path={urls.central.setup.campus.edit}
            render={() => <CampusDetail burger={burger} />}
          />
          <Route
            exact
            path={urls.central.setup.campus.create}
            render={() => <CreateCampus burger={burger} />}
          />
          <Route
            exact
            path={urls.campus.admission.enquiriesOverview}
            render={() => <AdmissionsOverview burger={burger} />}
          />

          <Route
            exact
            path={urls.campus.setting.roles}
            render={() => <RoleAndPermissions burger={burger} />}
          />
          <Route
            exact
            path={urls.campus.setting.keyRoles}
            render={() => <UnderConstruction />}
          />
          <Route
            exact
            path="/campus/campus-information"
            render={() => <CampusInformation burger={burger} />}
          />
          <Route
            exact
            path="/central/setup/campuses"
            render={() => <CampusList burger={burger} isOpen={isOpen} />}
          />
          <Route
            exact
            path="/detail/campus-information"
            render={() => <CampusInformation burger={burger} />}
          />

          <Route
            exact
            path={urls.central.setup.campus.general}
            render={() => <CampusInformation burger={burger} />}
          />
          <Route
            exact
            path={urls.central.setup.roles}
            render={() => <RoleAndPermissionsCentral burger={burger} />}
          />
          <Route
            exact
            path={urls.central.approval.settings}
            render={() => <AdmissionApprovals burger={burger} />}
          />
          {/* <Route path="/detail/:id" render={() => <CampusDetail burger={burger} />} /> */}

          {/* <Route exact path="/worlds">
            <Redirect to="/worlds/giiki" />
          </Route>
          <Route
            exact
            path="/worlds/giiki"
            render={() => <GiikiWorld burger={burger} />}
          /> */}
          {/* Admission settings */}
          <Route
            path={urls.campus.admission.applications}
            render={() => <Application burger={burger} />}
          />
          <Route
            exact
            path="/worlds/admissions"
            render={() => <AdmissionsWorld burger={burger} />}
          />
          <Route exact path="/managment" render={() => <Managment burger={burger} />} />
          <Route
            exact
            path={urls.campus.admission.setting.workflow}
            render={() => <Stages burger={burger} />}
          />
          <Route
            exact
            path={urls.central.communicationNotification.notifications}
            render={() => <NotificationsPage burger={burger} />}
          />
          <Route
            exact
            path="/central/integration/whatsapps"
            render={() => <WhatsappCredentials burger={burger} />}
          />
          <Route
            exact
            path="/settings/tasks-settings"
            render={() => <TasksSettings burger={burger} />}
          />
          <Route
            exact
            path="/settings/user-settings"
            render={() => <UserSettings burger={burger} />}
          />
          <Route
            exact
            path="/settings/change-password"
            render={() => <ChangePassword burger={burger} />}
          />
          <Route
            exact
            path={urls.central.communicationNotification.communicationTemplates}
            render={() => <Communication burger={burger} />}
          />
          <Route
            exact
            path={urls.central.communicationNotification.templateEdit}
            render={() => <EditTemplate burger={burger} />}
          />
          <Route
            exact
            path={urls.central.approval.base}
            render={() => <Approvals burger={burger} />}
          />
          <Route
            exact
            path={urls.campus.admission.setting.teams}
            render={() => <AdmissionTeamSettings burger={burger} />}
          />
          <Route
            exact
            path={urls.documentEdit}
            render={() => <EditDocumentSettings burger={burger} />}
          />
          <Route
            exact
            path={urls.campus.admission.setting.distributions}
            render={() => <Distribution burger={burger} />}
          />
          <Route
            exact
            path={urls.campus.admission.setting.forms}
            render={() => <Forms burger={burger} />}
          />
          <Route
            exact
            path={urls.campus.admission.setting.formBuilder}
            render={() => <FormBuilder burger={burger} />}
          />
          <Route
            exact
            path={urls.campus.admission.setting.documents}
            render={() => <AdmissionDocumentSettings burger={burger} />}
          />
          <Route
            exact
            path={urls.campus.admission.setting.editDocument}
            render={() => <EditDocumentSettings burger={burger} />}
          />
          {/* <Route
            exact
            path="/approvals/my-requests"
            render={() => <MyRequests burger={burger} />}
          /> */}
          {/* <Route
            exact
            path={urls.createUser}
            render={() => <CreateUser burger={burger} />}
          /> */}
          <Route
            exact
            path={urls.central.setup.campus.importUser}
            render={() => <ImportUser burger={burger} />}
          />
          <Route
            exact
            path={urls.campus.setting.users.edit}
            render={() => <Edit burger={burger} />}
          />
          <Route
            exact
            path={urls.createEnquiry}
            render={() => <NewEnquiry burger={burger} />}
          />
          <Route
            exact
            path="/tasks/team-setting"
            render={() => <TeamBoardSetting burger={burger} />}
          />
          <Route path="/tasks" render={() => <Tasks burger={burger} />} />
          <Route
            exact
            path={urls.campus.admission.cashierPayments}
            render={() => <CashierPayment burger={burger} />}
          />
          <Route
            exact
            path={urls.campus.finance.cashierPayment}
            render={() => <CashierPayment burger={burger} />}
          />
          <Route
            exact
            path={urls.central.finance.cashierScreen}
            render={() => <UnderConstruction />}
          />
          <Route
            exact
            path={urls.central.finance.cashierReports}
            render={() => <UnderConstruction />}
          />
          <Route exact path={urls.home} render={() => <GiikiHome />} />
          <Route exact path={campusAdmissionPath} render={() => <UnderConstruction />} />
          <Route exact path={campusSettingPath} render={() => <UnderConstruction />} />
          <Route exact path={campusFinancePath} render={() => <UnderConstruction />} />
          <Route exact path={campusFinancePath} render={() => <UnderConstruction />} />
          <Route
            exact
            path="/campus/admission-settings"
            render={() => <UnderConstruction />}
          />
          <Route exact path={centralPath.finance} render={() => <UnderConstruction />} />
          <Route exact path={centralPath.setting} render={() => <UnderConstruction />} />
          <Route exact path={urls.home} render={() => <GiikiHome burger={burger} />} />
        </Switch>
      </div>
    </>
  );

  // return (
  //   <>
  //     {isEnquiryForm ? (
  //       <>
  //         <EnquiryForm />
  //       </>
  //     ) : (
  //       <>
  //         {isShowHomePage ? (
  //           <></>
  //         ) : (
  //           <>
  //             {isShowToastMessage && (
  //               <div
  //                 onMouseMove={() => {
  //                   setIsHover(true);
  //                 }}
  //                 onMouseLeave={() => {
  //                   setIsHover(false);
  //                 }}
  //               >
  //                 <ToastMessage
  //                   setIsShowToastMessage={setIsShowToastMessage}
  //                   status={toastMessage.status}
  //                   title={toastMessage.title}
  //                   message={toastMessage.message}
  //                   isHover={isHover}
  //                   setIsHover={setIsHover}
  //                 />
  //               </div>
  //             )}
  //             {userLogin ? (
  //               <>
  //                 {!isShowParentPortal && pathname !== HOME_PATH && (
  //                   <>
  //                     <BasicPanel burger={burger} setBurger={setBurger} isOpen={isOpen} />
  //                     <Header burger={burger} isOpen={isOpen} header={getHeaderName()} />
  //                   </>
  //                 )}
  //                 <div className={`max-w-[100vw] ${getClassCommon()}`}>
  //                   <Switch>
  //                     <Route exact path="/" render={() => <Redirect to={'/home'} />} />
  //                     {/* finance module */}
  //                     <Route
  //                       exact
  //                       path="/finance/dashboard"
  //                       render={() => <FinanceDashboard burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.central.finance.feebooks}
  //                       // render={() => <FeeBooks burger={burger} />}
  //                       render={() => <UnderConstruction />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.central.finance.feebookEdit}
  //                       render={() => <FeebookEdit burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.central.finance.scholarships}
  //                       // render={() => <Scholarship burger={burger} />}
  //                       render={() => <UnderConstruction />}
  //                     />

  //                     {/* Parent portal */}
  //                     <Route
  //                       exact
  //                       path=urls.parent.stage
  //                       render={() => <BloomingdaleStage />}
  //                     />
  //                     <Route
  //                       exact
  //                       path=urls.parent.welcome
  //                       render={() => <BloomWelcome />}
  //                     />
  //                     <Route
  //                       exact
  //                       path=urls.parent.cabinet
  //                       render={() => <BloomCabinet />}
  //                     />
  //                     <Route
  //                       exact
  //                       path=urls.parent.form
  //                       render={() => <BloomForm />}
  //                     />
  //                     <Route
  //                       exact
  //                       path=urls.parent.dashboard
  //                       render={() => <BloomDashboard />}
  //                     />
  //                     <Route
  //                       exact
  //                       path=urls.parent.details
  //                       render={() => <BloomDetails />}
  //                     />
  //                     <Route
  //                       exact
  //                       path=urls.parent.review
  //                       render={() => <BloomReview />}
  //                     />
  //                     <Route
  //                       exact
  //                       path=urls.parent.payment
  //                       render={() => <BloomPayment />}
  //                     />
  //                     <Route
  //                       exact
  //                       path=urls.parent.payment
  //                       render={() => <BloomFormMore />}
  //                     />
  //                     <Route
  //                       exact
  //                       path=urls.parent.childAssessment
  //                       render={() => <BloomChildAssessment />}
  //                     />
  //                     <Route
  //                       exact
  //                       path=urls.parent.childPrincipal
  //                       render={() => <BloomChildPrincipal />}
  //                     />
  //                     <Route
  //                       exact
  //                       path=urls.parent.childDocument
  //                       render={() => <BloomChildDocument />}
  //                     />
  //                     <Route
  //                       exact
  //                       path=urls.parent.childFinance
  //                       render={() => <BloomChildFinance />}
  //                     />
  //                     <Route
  //                       exact
  //                       path=urls.parent.childComplete
  //                       render={() => <BloomChildComplete />}
  //                     />
  //                     <Route
  //                       exact
  //                       path=urls.parent.customStage
  //                       render={() => <ChildCustomStage />}
  //                     />

  //                     {/* end parent portal */}
  //                     <Route
  //                       exact
  //                       // path={urls.central.admission.forms}
  //                       path={urls.central.admission.forms}
  //                       render={() => <Forms burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.central.admission.formBuilder}
  //                       render={() => <FormBuilder burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.central.admission.documents}
  //                       render={() => <AdmissionDocumentSettings burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.central.admission.editDocument}
  //                       render={() => <AdmissionDocumentSettings burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       // path="/giiki/institutions"
  //                       path={urls.giiki.institutions.base}
  //                       render={() => <Company burger={burger} isOpen={isOpen} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path="/giiki/institutions/:id"
  //                       // path={`${urls.giiki.createInstitution}/:id`}
  //                       render={() => <Institutions burger={burger} isOpen={isOpen} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.campus.admission.dashboard}
  //                       render={() => <Dashboard burger={burger} />}
  //                     />
  //                     {/* <Route
  //                       exact
  //                       path="/giiki"
  //                       render={() => <Redirect to={'/giiki/company'} />}
  //                     /> */}
  //                     {/* <Route
  //                       exact
  //                       // path="/giiki/home"
  //                       path={urls.giiki.home}
  //                       render={() => (
  //                         <GiikiHome
  //                           burger={burger}
  //                           setBurger={setBurger}
  //                           campuses={campuses}
  //                         />
  //                       )}
  //                     /> */}
  //                     {/* <Route
  //                       exact
  //                       path="/giiki/company/settings"
  //                       render={() => <Redirect to={'/giiki/company'} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path="/giiki/company"
  //                       render={() => <Company burger={burger} isOpen={isOpen} />}
  //                     /> */}
  //                     <Route
  //                       exact
  //                       // path="/giiki/reports/bug"
  //                       path={urls.giiki.report.bug}
  //                       render={() => <GiikiAdminReports burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.giiki.report.issue}
  //                       // path="/giiki/reports/issue"
  //                       render={() => <GiikiAdminReports burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.giiki.report.user}
  //                       // path="/giiki/reports/user"
  //                       render={() => <GiikiAdminReports burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       // path="/giiki/users1"
  //                       path={urls.giiki.users}
  //                       render={() => <UserList burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.giiki.institutions.company.base}
  //                       // path="/giiki/settings"
  //                       render={() => {
  //                         if (companyId) return <CompanySettings burger={burger} />;
  //                         // return <Redirect to={'/giiki/company'} />;
  //                         return <Redirect to={urls.giiki.institutions.base} />;
  //                       }}
  //                     />
  //                     {/* Campus UI */}
  //                     <Route exact path="/detail">
  //                       <Redirect to="/detail/company" />
  //                     </Route>
  //                     <Route
  //                       exact
  //                       path={urls.campus.setting.users}
  //                       render={() => <CampusListUsers burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       // path='/campus/setting/academic-year/:id'
  //                       path={urls.campus.setting.academicYears}
  //                       render={() => <AcademicTerms burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path="/campus/grades"
  //                       render={() => <GradesPrograms burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.campus.setting.programClasses}
  //                       render={() => <ProgramClasses burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path="/central-admin/campus/fee-payment"
  //                       render={() => <GradesFeePayment burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path="/central-admin/campus/programmes/:id"
  //                       render={() => <Programmes burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       // path="/central/setup/bank-accounts"
  //                       path={urls.campus.finance.scholarships}
  //                       render={() => <Scholarship burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       // path="/central/setup/bank-accounts"
  //                       path={urls.campus.finance.feebooks}
  //                       render={() => <FeeBooks burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       // path="/central/setup/bank-accounts"
  //                       path={urls.campus.finance.feebookEdit}
  //                       render={() => <FeebookEdit burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       // path="/central/setup/bank-accounts"
  //                       path={urls.central.finance.bankAccounts}
  //                       render={() => <BankAccount burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       // path="/central-admin/new-bank-account"
  //                       path={urls.central.finance.newBankAccount}
  //                       render={() => <NewBankAccount burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path="/central-admin/dashboard"
  //                       render={() => <FinanceDashboard burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path="/detail/campus-information"
  //                       render={() => <CampusInformation burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path="/central-admin/campus/general"
  //                       render={() => <CampusInformation burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.central.setup.users}
  //                       render={() => <UserList burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.central.setup.editUser}
  //                       render={() => <Edit burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path="/central-admin/settings/whatsapp-credentials"
  //                       render={() => <WhatsappCredentials burger={burger} />}
  //                     />
  //                     {/* <Route
  //                       exact
  //                       path="/detail/approvals"
  //                       render={() => <AdmissionApprovals burger={burger} />}
  //                     /> */}
  //                     <Route
  //                       path="/detail/:id"
  //                       render={() => <CampusDetail burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.campus.admission.enquiriesOverview}
  //                       render={() => <AdmissionsOverview burger={burger} />}
  //                     />

  //                     <Route
  //                       exact
  //                       path={urls.campus.setting.roles}
  //                       render={() => <RoleAndPermissions burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.campus.setting.keyRoles}
  //                       render={() => <UnderConstruction />}
  //                     />
  //                     <Route
  //                       exact
  //                       path="/campus/campus-information"
  //                       render={() => <CampusInformation burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path="/central/setup/campuses"
  //                       render={() => <CampusList burger={burger} isOpen={isOpen} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path="/detail/campus-information"
  //                       render={() => <CampusInformation burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path="/central-admin/campuses/general"
  //                       render={() => <CampusInformation burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.central.setup.roles}
  //                       render={() => <RoleAndPermissionsCentral burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.central.approval.settings}
  //                       render={() => <AdmissionApprovals burger={burger} />}
  //                     />
  //                     <Route
  //                       path="/detail/:id"
  //                       render={() => <CampusDetail burger={burger} />}
  //                     />
  //                     {/* <Route path="/admin/dashboard" render={() => <GiikiAdminDashBoard />} /> */}
  //                     <Route
  //                       // path="/giiki/dashboard"
  //                       path={urls.giiki.dashboard}
  //                       render={() => <UnderConstruction burger={burger} />}
  //                     />
  //                     <Route
  //                       // path="/giiki/role-permission"
  //                       path={urls.giiki.roles}
  //                       render={() => <RoleAndPermissions burger={burger} />}
  //                     />
  //                     {/* <Route exact path="/worlds">
  //                       <Redirect to="/worlds/giiki" />
  //                     </Route>
  //                     <Route
  //                       exact
  //                       path="/worlds/giiki"
  //                       render={() => <GiikiWorld burger={burger} />}
  //                     /> */}
  //                     {/* Admission settings */}
  //                     <Route
  //                       path={urls.campus.admission.applications}
  //                       render={() => <Application burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path="/worlds/admissions"
  //                       render={() => <AdmissionsWorld burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path="/managment"
  //                       render={() => <Managment burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.campus.admission.setting.workflow}
  //                       render={() => <Stages burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       // path="/central-admin/settings/notification"
  //                       // path='/central/communication-notification/notifications'
  //                       path={urls.central.communicationNotification.notifications}
  //                       render={() => <NotificationsPage burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path="/central/integration/whatsapps"
  //                       // path="/settings/whatsapp-credentials"
  //                       render={() => <WhatsappCredentials burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path="/settings/tasks-settings"
  //                       render={() => <TasksSettings burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path="/settings/user-settings"
  //                       render={() => <UserSettings burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path="/settings/change-password"
  //                       render={() => <ChangePassword burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={
  //                         urls.central.communicationNotification.communicationTemplates
  //                       }
  //                       render={() => <Communication burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.central.communicationNotification.templateEdit}
  //                       render={() => <EditTemplate burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.campus.admission.setting.teams}
  //                       render={() => <AdmissionTeamSettings burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       // path="/admission/setting/document/edit"
  //                       path={urls.documentEdit}
  //                       render={() => <EditDocumentSettings burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.campus.admission.setting.distributions}
  //                       render={() => <Distribution burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       // path="/approvals"
  //                       path={urls.campus.admission.setting.forms}
  //                       render={() => <Forms burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       // path="/approvals"
  //                       path={urls.campus.admission.setting.formBuilder}
  //                       render={() => <FormBuilder burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.campus.admission.setting.documents}
  //                       render={() => <AdmissionDocumentSettings burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.campus.admission.setting.editDocument}
  //                       render={() => <EditDocumentSettings burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       // path="/approvals"
  //                       path={urls.central.approval.base}
  //                       render={() => <Approvals burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path="/approvals/my-requests"
  //                       render={() => <MyRequests burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       // path="/create-user"
  //                       path={urls.createUser}
  //                       render={() => <CreateUser burger={burger} />}
  //                     />
  //                     {/* <Route
  //                       exact
  //                       path="/create-user-for-company"
  //                       render={() => <CreateUser burger={burger} />}
  //                     /> */}
  //                     <Route
  //                       exact
  //                       // path="/import-user"
  //                       path={urls.importUser}
  //                       render={() => <ImportUser burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       // path="/edit-user"
  //                       // path={urls.editUser}
  //                       path={urls.campus.setting.users.edit}
  //                       render={() => <Edit burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       // path="/enquiry/new"
  //                       path={urls.createEnquiry}
  //                       render={() => <NewEnquiry burger={burger} />}
  //                     />

  //                     <Route
  //                       exact
  //                       path="/tasks/team-setting"
  //                       render={() => <TeamBoardSetting burger={burger} />}
  //                     />
  //                     <Route path="/tasks" render={() => <Tasks burger={burger} />} />
  //                     <Route
  //                       exact
  //                       path={urls.campus.admission.cashierPayments}
  //                       render={() => <CashierPayment burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.campus.finance.cashierPayment}
  //                       render={() => <CashierPayment burger={burger} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.central.finance.cashierScreen}
  //                       // render={() => <CashierHome burger={burger} />}
  //                       render={() => <UnderConstruction />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={urls.central.finance.cashierReports}
  //                       // render={() => <CashierHome burger={burger} />}
  //                       render={() => <UnderConstruction />}
  //                     />
  //                     <Route exact path={urls.home} render={() => <GiikiHome />} />
  //                     <Route
  //                       exact
  //                       path={campusAdmissionPath}
  //                       render={() => <UnderConstruction />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={campusSettingPath}
  //                       render={() => <UnderConstruction />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={campusFinancePath}
  //                       render={() => <UnderConstruction />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={campusFinancePath}
  //                       render={() => <UnderConstruction />}
  //                     />

  //                     <Route
  //                       exact
  //                       path="/campus/admission-settings"
  //                       render={() => <UnderConstruction />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={centralPath.finance}
  //                       render={() => <UnderConstruction />}
  //                     />
  //                     <Route
  //                       exact
  //                       path={centralPath.setting}
  //                       render={() => <UnderConstruction />}
  //                     />

  //                     <Route
  //                       exact
  //                       path={urls.home}
  //                       render={() => <GiikiHome burger={burger} />}
  //                     />

  //                     {/* <Route path="*" element={<Redirect to={urls.home} />} /> */}
  //                   </Switch>
  //                 </div>
  //               </>
  //             ) : (
  //               <>
  //                 <Route
  //                   exact
  //                   path="/login-success"
  //                   render={() => <LoginSucess burger={burger} />}
  //                 />
  //                 {!window.location.hostname.startsWith(process.env.REACT_APP_HREF) && (
  //                   <>
  //                     <Route
  //                       exact
  //                       path="/login/:id?loginTypeId=1"
  //                       render={() => <Login burger={burger} tenantId={tenantId} />}
  //                     />
  //                     <Route
  //                       exact
  //                       path="/login/:id"
  //                       render={() => <Login burger={burger} tenantId={tenantId} />}
  //                     />
  //                     {window.location.hostname.startsWith(
  //                       `admin.${process.env.REACT_APP_HREF}`,
  //                     ) ? (
  //                       <>
  //                         <Route
  //                           exact
  //                           path="/"
  //                           render={() => <Redirect to={'/login/user?loginTypeId=1'} />}
  //                         />
  //                       </>
  //                     ) : (
  //                       <>
  //                         <Route
  //                           exact
  //                           path="/"
  //                           render={() => <Redirect to={'/login/type'} />}
  //                         />
  //                       </>
  //                     )}
  //                   </>
  //                 )}
  //               </>
  //             )}
  //           </>
  //         )}
  //       </>
  //     )}
  //   </>
  // );
}

export default App;
