import React, { memo, useState } from 'react';
import clsx from 'clsx';
import { SelectGroup } from '../../../../../stories';
import './style.scss';
import useDebounce from '@utils/custom-hook/useDebounce';
import { gender, occupation, relationship } from '@utils/utils';
import InputSearchUser from './input-suggest-user/input-suggest-user';
import { validEmail, validPhone } from '@utils/validate';
import { useSuggestUser } from '@utils/custom-hook';
import UploadPhoto from './upload-photo/upload-photo';
import { ic_close_black } from '@static/image';
import { selectAdmission } from 'entities/select-style';
import { EnquiryStatus } from 'entities/data';

const FamilyInformation = memo((props) => {
  const { enquiry, info, error, setInfo, parentUserIdChosen, handleChange, handleAdd, handleRemove } = props;
  return (
    <div className="flex items-center flex-wrap gap-x-8">
      {info &&
        info.length !== 0 &&
        info.map((item, index) => {
          if (item.isDelete) return null
          return (
            <SingleFamily
              key={`family${index}`}
              enquiry={enquiry}
              info={{ ...item }}
              order={index}
              error={error?.[index]}
              setInfo={setInfo}
              parentUserIdChosen={parentUserIdChosen}
              handleChange={handleChange}
              handleRemove={handleRemove}
            />
          );
        })}
      <div className="bottom-7 left-20 flex flex-col items-center justify-center w-[20rem] h-[20rem]">
        <div
          className="flex items-center justify-center h-16 w-16 rounded-full text-main-blue text-xl  cursor-pointer"
          style={{ background: '#E5EFFD' }}
          onClick={() => {
            handleAdd({ name: 'parentInfo' });
          }}
        >
          +
        </div>
        <p className="text-xs mt-2 text-button-disable">Add Family Member</p>
      </div>
    </div>
  );
});
FamilyInformation.displayName = 'FamilyInformation';
export default FamilyInformation;

const SingleFamily = memo((props) => {
  const { enquiry, info, order, setInfo, parentUserIdChosen, handleChange, handleRemove } = props;
  const [imgPreview, setImgPreview] = useState(false);
  const [showOption, setShowOption] = useState(false);
  const [error, setError] = useState({});
  const styleGender = { height: '1.875rem', fontSize: '0.813rem' };
  const styleInput = { height: 'unset' }
  const { data: dataSuggestByEmail } = useSuggestUser({ textSearch: info?.email });
  const { data: dataSuggestByPhone } = useSuggestUser({
    textSearch: info.phoneNumber && encodeURIComponent(info.phoneNumber),
  });
  const { data: dataSuggestByName } = useSuggestUser({
    textSearch: info?.fullName && encodeURIComponent(info.fullName),
  });
  const addParentInfo = (user) => {
    const { name, photoURL, id, email, phoneNumber, gender } = user;
    const tmp = {
      fullName: name || '',
      gender: gender || '',
      email: email || '',
      phoneNumber: phoneNumber || '',
      photoURL: photoURL || '',
      parentUserId: id,
    };
    const data = {
      ...tmp,
      infoOrigin: { ...tmp, gender: gender },
    };
    setInfo((prev) => {
      prev.parentInfo[order] = { ...data };
      return { ...prev, parentInfo: [...prev.parentInfo] };
    });
    setError({});
    setShowOption(false);
    validateEmail(user.email)
    validatePhone(user.phoneNumber)
  }

  const changeImageHandler = (e) => {
    let selected = e.target.files;
    if (selected && selected[0]) {
      handleChange({ name: 'parentInfo', order, key: 'photoURL', value: selected[0] });
      let reader = new FileReader();
      reader.onload = (e) => {
        setImgPreview(e.target.result);
      };
      reader.readAsDataURL(selected[0]);
    }
  };

  const validateEmail = useDebounce((text) => {
    setError((prev) => ({ ...prev, email: !validEmail(text) }));
  });

  const validatePhone = useDebounce((text) => {
    setError((prev) => ({ ...prev, phoneNumber: !validPhone(text) }));
  });

  return (
    <div className="information-field_family px-5 pt-4 h-fit pb-8">
      {enquiry.status !== EnquiryStatus.COMPLETE && (
        <button title='remove' className='float-right cursor-pointer' onClick={() => {
          handleRemove({ name: 'parentInfo', order })
        }}>
          <img src={ic_close_black} alt='remove' />
        </button>
      )}
      <div className="flex items-center justify-around w-full h-16">
        <div className="w-[25%] h-full">
          <UploadPhoto
            id={`photoParent${order}`}
            info={info?.photoURL}
            showLabel={false}
            handleChange={(e) => {
              handleChange({ name: 'parentInfo', order, key: 'photoURL', value: e });
            }}
          />
          <input
            type="file"
            id={`image-upload-${order}`}
            name="image-upload-2"
            className="hidden"
            accept="image/*"
            onChange={changeImageHandler}
          />
        </div>
        {/* <Input
          label="Full Name"
          classGroup="w-[70%] "
          customStyle={styleInput}
          value={info?.fullName || ''}
          onChange={(e) =>
            handleChange({
              name: 'parentInfo',
              order,
              key: 'fullName',
              value: e.target.value,
            })
          }
        /> */}
        <InputSearchUser
          label="Full Name"
          value={info?.fullName || ''}
          options={dataSuggestByName}
          exceptId={parentUserIdChosen}
          showOption={showOption}
          error={error?.fullName}
          customStyle={styleInput}
          classGroup="ml-2"
          handleChangeText={(e) => {
            handleChange({ name: 'parentInfo', order, key: 'fullName', value: e });
            setShowOption(true);
          }}
          handleChooseOption={(e) => {
            addParentInfo(e)
            parentUserIdChosen.current[e.id] = e.id
          }}
        />
      </div>
      <div className="ml-24 mt-1">
        <p
          className="text-xs font-medium text-button-disable relative"
          style={{ left: '0.375rem' }}
        >
          Gender
        </p>
        <div className="flex mt-1">
          <button
            onClick={() =>
              handleChange({ name: 'parentInfo', order, key: 'gender', value: gender[0].value })
            }
            className={clsx(
              'text-white rounded mr-3 btn',
              info?.gender?.toLowerCase() === 'male' ? 'bg-main-blue' : 'bg-blue-200',
            )}
            style={Object.assign({ width: '3.375rem' }, styleGender)}
          >
            Male
          </button>
          <button
            onClick={() =>
              handleChange({ name: 'parentInfo', order, key: 'gender', value: gender[1].value })
            }
            className={clsx(
              'text-white rounded btn',
              info?.gender?.toLowerCase() === 'female' ? 'bg-main-blue' : 'bg-blue-200',
            )}
            style={Object.assign({ width: '4.188rem' }, styleGender)}
          >
            Female
          </button>
        </div>
      </div>
      <div className="mt-8 w-full">
        <SelectGroup
          styles={selectAdmission}
          options={relationship}
          classNameGroup="mb-5"
          title="Relationship with Applicant"
          value={info?.relationship || null}
          onChange={(e) =>
            handleChange({ name: 'parentInfo', order, key: 'relationship', value: e })
          }
        />
        <SelectGroup
          options={occupation}
          classNameGroup="mb-5"
          title="Occupation"
          value={info?.occupation || null}
          styles={selectAdmission}
          onChange={(e) =>
            handleChange({ name: 'parentInfo', order, key: 'occupation', value: e })
          }
        />
        <InputSearchUser
          label="Mobile Number"
          value={info?.phoneNumber || ''}
          options={dataSuggestByPhone}
          exceptId={parentUserIdChosen}
          showOption={showOption}
          error={error?.phoneNumber}
          customStyle={styleInput}
          classGroup="mb-5"
          handleChangeText={(e) => {
            handleChange({ name: 'parentInfo', order, key: 'phoneNumber', value: e });
            setShowOption(true);
            validatePhone(e);
          }}
          handleChooseOption={(e) => {
            parentUserIdChosen.current[e.id] = e.id // update parentUserId
            addParentInfo(e)
          }}
        />
        <InputSearchUser
          label="Email"
          value={info?.email || ''}
          options={dataSuggestByEmail}
          exceptId={parentUserIdChosen}
          showOption={showOption}
          error={error?.email}
          customStyle={styleInput}
          // classGroup="h-[2.5rem]"
          handleChangeText={(e) => {
            handleChange({ name: 'parentInfo', order, key: 'email', value: e });
            setShowOption(true);
            validateEmail(e);
          }}
          handleChooseOption={(e) => {
            addParentInfo(e)
            parentUserIdChosen.current[e.id] = e.id // update parentUserId
          }}
        />
      </div>
    </div>
  );
});
SingleFamily.displayName = 'SingleFamily';
