import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { circlefill } from '../../image';
import { Button } from '../../../../../../stories';
import ContentGroup from '../content-group/content-group';
import GgMap from '../gg-map/gg-map';

const AddressEditInformation = memo((props) => {
  const { styles: styleProps, info, listField, handleChange, handleAdd } = props;
  const [active, setActive] = useState(0);
  const [infoShow, setInfoShow] = useState(info[0]);
  const styles = {
    home: { fontSize: '0.875rem', marginRight: '1.25rem' },
    parent: { fontSize: '0.875rem', borderColor: '#E4E5E7', color: '#202223' },
    grandpa: {
      borderColor: '#E4E5E7',
      color: '#202223',
      fontSize: '0.875rem',
      marginRight: '1.25rem',
    },
    mark: { position: 'relative', right: '2.5rem' },
    bottom: { marginBottom: '1.875rem' },
    input: styleProps.input,
  };

  useEffect(() => {
    setInfoShow(info[active]);
  }, [active, info]);


  return (
    <div>
      <div className="flex mb-3">
        {info.map((item, index) => {
          return (
            <Button
              key={`buttonAddress${index}`}
              text={item?.locationName || 'New Address'}
              buttonSize="large"
              buttonStyle={active === index ? 'contained' : 'outlined'}
              customStyle={styles.home}
              onClick={() => {
                setActive(index);
                setInfoShow(info[index]);
              }}
            />
          );
        })}
        <Button
          text="Add Address"
          customStyle={styles.parent}
          buttonSize="large"
          icon={circlefill}
          buttonStyle="outlined"
          onClick={() => {
            handleAdd({ name: 'addressInfo' });
          }}
        />
      </div>
      <div className="address-edit h-fit pb-10">
        <SingleAddress
          info={infoShow}
          order={active}
          styles={styles}
          listField={listField}
          handleChange={handleChange}
        />

        <GgMap
          info={info[active]}
          libraries={process.env.REACT_APP_MAPS_LIBRARIES.split(',')}
          handleChange={({ objValue, key, value }) => {
            handleChange({ name: 'addressInfo', order: active, objValue, key, value });
          }}
        />
      </div>
    </div>
  );
});
AddressEditInformation.displayName = 'AddressEditInformation';
export default AddressEditInformation;

AddressEditInformation.propTypes = {
  /**  @param Type An object.
   * @param Object Keys for object - nickName: string, parent: string, tel: string, email: string, streetFirst: string, streetSecond: string, state: string, country: string, zipCode: string
   * @param Example {
   * nickName: 'Jasmine Mathew',
   * parent: 'Mother',
   * tel: '+91 883241231',
   * email: 'mathew.jasmine@gmail.com',
   * streetFirst: 'Address',
   * streetSecond: 'Second Address',
   * state: '',
   * country: '',
   * zipCode: '354446'
   * }
   **/
  data: PropTypes.object,
};

const SingleAddress = (props) => {
  const { styles, order, info, listField, handleChange } = props;
  const [locationNameField, residentPersonField, fullAddressField, ...rest] = listField;

  return (
    <>
      <div className="w-full bg-gray-100 flex p-3 items-center justify-end">
        <Button
          text="Mark as Primary Address"
          buttonStyle={info?.isPrimaryAddress ? 'contained' : 'outlined'}
          buttonSize="small"
          customStyle={styles.mark}
          onClick={() => {
            handleChange({
              name: 'addressInfo',
              order,
              key: 'isPrimaryAddress',
              value: !info?.isPrimaryAddress,
            });
          }}
        />
      </div>
      <div className="h-fit mt-16 px-10 pb-4 ">
        <div className="grid grid-cols-2 1620px:grid-cols-3 gap-x-8 mb-4">
          {[locationNameField, residentPersonField].map((item) => {
            return (
              <div className="w-full" key={`address${order}${item.name}`}>
                <ContentGroup
                  data={item}
                  info={info?.[item.name]}
                  variation="admission"
                  handleChange={(e) => {
                    handleChange({
                      name: 'addressInfo',
                      order,
                      key: item.name,
                      value: e,
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className="w-full mb-8">
          <ContentGroup
            key={`address${order}${fullAddressField.name}`}
            data={fullAddressField}
            info={info?.[fullAddressField.name]}
            variation="admission"
            handleChange={(e) => {
              handleChange({
                name: 'addressInfo',
                order,
                key: fullAddressField.name,
                value: e,
              });
            }}
          />
        </div>
        <div className="grid grid-cols-2 1620px:grid-cols-3 gap-x-8 gap-y-4 mb-4">
          {rest.map((item) => {
            return (
              <ContentGroup
                key={`address${order}${item.name}`}
                data={item}
                info={info?.[item.name]}
                variation="admission"
                handleChange={(e) => {
                  handleChange({ name: 'addressInfo', order, key: item.name, value: e });
                }}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};
// SingleAddress.displayName = 'SingleAddress';
