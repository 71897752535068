import { useCallback, useContext, useEffect, useState } from 'react';
import { Fade } from 'react-reveal';
import { Input } from '@components/index';
import { arrow, arrow_right } from '@static/image';
import { Button } from '@stories/index';
import { checkErrorApiFetch, checkErrorSingleApi } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { dataLinks } from 'entities/routes';
import { NavLinks } from '../Header/components/index';
import './whatsapp-credentials.scss';
import clsx from 'clsx';
import notificationApi from 'api/notification';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';

const styles = {
  btnBlue: {
    width: '6.563rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    backgroundColor: '#404eed',
    height: '2.563rem',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  btnWhite: {
    width: '6.563rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#404eed',
    backgroundColor: 'white',
    height: '2.563rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    border: '1px solid #404eed',
  },
};
export default function WhatsappCredentials({ burger }) {
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [phoneNumberId, setPhoneNumberId] = useState('');
  const [businessAccountId, setBusinessAccountId] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [isShowStatus, setIsShowStatus] = useState(false);
  const [statusTemplate, setStatusTemplate] = useState('');
  const campus = useStorage((state) => state.currentCampus);
  const dataCompanyCampus = useStorage((state) => state.company);
  const [isConfigWhatsappCredential, setIsConfigWhatsappCredential] = useState(false);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    // let url = `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/company`;
    // if (campus && Object.keys(campus).length) {
    //   url = `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/campus?campusId=${campus?.id}`;
    // } else if (campus === undefined) {
    //   url = `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/campus?campusId=${dataCompanyCampus?.id}`;
    // }

    let api = centralAdminApi.getMyCompany()
    if (campus && Object.keys(campus).length) {
      api = centralAdminApi.getCampus({ campusId: campus?.id })
    } else if (campus === undefined) {
    } api = centralAdminApi.getCampus({ campusId: dataCompanyCampus?.id })
    if (
      (campus && Object.keys(campus).length) ||
      (dataCompanyCampus && Object.keys(dataCompanyCampus).length)
    ) {
      // callApi({
      //   method: 'get',
      //   url: url,
      // })
      api.then((response) => {
        if (
          checkErrorApiFetch(
            response,
            setToastMessage,
            setIsShowToastMessage,
            'Get company or campus ID',
          )
        ) {
          setIsConfigWhatsappCredential(!!response.data.data.whatsappCredentials);
        }
      })
        .catch((error) => {
          console.log('Get company or campus ID Error', error);
          setToastMessage({
            status: 'error',
            title: 'Get company or campus ID Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
        });
    }
  }, [refresh, dataCompanyCampus, campus]);

  const getStatusTemplate = useCallback(() => {
    // callApi({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_NOTIFICATION}/api/v1/notification/whatsapp-template`,
    //   params: { campusId: campus?.isCentral ? undefined : campus?.id },
    // })
    notificationApi.getWhatsappTemplate({ campusId: campus?.isCentral ? undefined : campus?.id })
      .then((response) => {
        if (
          checkErrorApiFetch(
            response,
            setToastMessage,
            setIsShowToastMessage,
            'Get Status Of Template',
          )
        ) {
          setStatusTemplate(response.data.data.status);
        }
      })
      .catch((error) => {
        console.log('Get Status Of Template Error', error);
        setToastMessage({
          status: 'error',
          title: 'Get Status Of Template Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      });
  }, []);

  const handlerOnClickCancel = () => {
    setPhoneNumberId('');
    setAccessToken('');
    setBusinessAccountId('');
  };

  const handleOnClickSave = () => {
    if (phoneNumberId === '' || accessToken === '' || businessAccountId === '') {
      setToastMessage({
        status: 'warning',
        title: 'Whatsapp Credentials warning',
        message: 'Please fill in all the fields',
      });
      setIsShowToastMessage(true);
      return;
    }

    // callApi({
    //   method: 'patch',
    //   url: `${process.env.REACT_APP_URL_API_NOTIFICATION}/api/v1/notification/whatsapp-credentials`,
    //   params: { campusId: campus?.isCentral ? undefined : campus?.id },
    //   data: {
    //     phoneNumberId: phoneNumberId,
    //     accessToken: accessToken,
    //     businessAccountId: businessAccountId,
    //   },
    // })
    notificationApi.updateWhatsappCredential({ campusId: campus?.isCentral ? undefined : campus?.id }, {
      phoneNumberId: phoneNumberId,
      accessToken: accessToken,
      businessAccountId: businessAccountId,
    })
      .then((response) => {
        if (
          checkErrorSingleApi(
            response,
            setToastMessage,
            setIsShowToastMessage,
            'Save Whatsapp Credentials',
          )
        ) {
          handlerOnClickCancel();
          setRefresh((prev) => prev + 1);
        }
      })
      .catch((error) => {
        console.log('Save Whatsapp Credentials Error', error);
        setToastMessage({
          status: 'error',
          title: 'Save Whatsapp Credentials Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      });
  };

  return (
    // <div className="whatsapp-credentials px-8 w-full">
    <div className={clsx('whatsapp-credentials px-24 transition-all-300', burger ? '1400px:pl-80' : '1400px:pl-40')}>
      <div className="min-w-[50%]">
        <Fade clear duration={300}>
          <NavLinks urls={dataLinks.whatsappCredentialsLinks} />
          <h1 className="namepage">Whatsapp Credentials</h1>
          <div className="whatsapp-credentials-form">
            <div className="whatsapp-credentials-form_section whatsapp-credentials-section">
              <div className="whatsapp-credentials-section_header">
                {isConfigWhatsappCredential && (
                  <p className="text-[#404EED] italic ">
                    Your credentials have been configured
                  </p>
                )}
                <h2 className="whatsapp-credentials-section_title">
                  1. Business account ID by Whatsapp account
                </h2>
                <Input
                  onChange={(e) => {
                    setBusinessAccountId(e.target.value);
                  }}
                  label={
                    isConfigWhatsappCredential ? 'Configured' : 'Business account ID'
                  }
                  name="Business account ID"
                  value={businessAccountId ?? ''}
                  customStyle={{
                    width: '34.25rem',
                    height: '2.5rem',
                    fontSize: '0.875rem',
                    borderRadius: '0.25rem',
                  }}
                />
              </div>
              <div className="whatsapp-credentials-section_header">
                <h2 className="whatsapp-credentials-section_title">
                  2. Phone number ID by Whatsapp account
                </h2>
                <Input
                  onChange={(e) => {
                    setPhoneNumberId(e.target.value);
                  }}
                  label={isConfigWhatsappCredential ? 'Configured' : 'Phone number ID'}
                  name="Phone number ID"
                  value={phoneNumberId ?? ''}
                  customStyle={{
                    width: '34.25rem',
                    height: '2.5rem',
                    fontSize: '0.875rem',
                    borderRadius: '0.25rem',
                  }}
                />
              </div>
              <div className="whatsapp-credentials-section_header">
                <h2 className="whatsapp-credentials-section_title">
                  3. Permanent access token by Whatsapp account
                </h2>
                <Input
                  onChange={(e) => {
                    setAccessToken(e.target.value);
                  }}
                  label={isConfigWhatsappCredential ? 'Configured' : 'Access Token'}
                  name="Access Token"
                  value={accessToken ?? ''}
                  customStyle={{
                    width: '34.25rem',
                    height: '2.5rem',
                    fontSize: '0.875rem',
                    borderRadius: '0.25rem',
                  }}
                />
              </div>
              {isConfigWhatsappCredential && (
                <div>
                  <div className="flex">
                    <img
                      alt=''
                      src={isShowStatus ? arrow_right : arrow}
                      onClick={() => {
                        setIsShowStatus(!isShowStatus);
                        if (!isShowStatus) {
                          getStatusTemplate();
                        }
                      }}
                    />
                    <span className="ml-1">Message template status</span>
                  </div>
                  {isShowStatus && (
                    <span className="text-[#404EED]">{`Status is: ${statusTemplate}`}</span>
                  )}
                </div>
              )}
              <div className="footer" style={{ width: '30%' }}>
                <div className="whatsapp-credentials-section_footer">
                  <Button
                    text="Cancel"
                    customStyle={styles.btnWhite}
                    onClick={() => {
                      handlerOnClickCancel();
                    }}
                  />
                  <Button
                    text="Save"
                    customStyle={styles.btnBlue}
                    onClick={() => {
                      handleOnClickSave();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}
