import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { memo, useCallback, useRef } from "react";

const libraries = ["places"];

const AutocompletePlace = memo((props) => {
  const { renderInput, types, onChange } = props
  const refAutocomplete = useRef();

  const onLoad = useCallback((place) => {
    refAutocomplete.current = place
  }, [])

  const onPlaceChanged = useCallback(() => {
    const place = refAutocomplete.current.getPlace()
    onChange(place)
  }, [])

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_MAPS_API_KEY}
      libraries={libraries}
      language="en"
    >
      <Autocomplete
        id="autocomplete"
        onLoad={onLoad}
        onPlaceChanged={onPlaceChanged}
        types={types}
      >
        {renderInput()}
      </Autocomplete>
    </LoadScript >
  )
})

AutocompletePlace.displayName = 'AutocompletePlace'
export default AutocompletePlace;
