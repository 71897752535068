import React from 'react';
import { unactive, active, done } from './image';
import './admissions-links.scss';

export default function AdmissionsLinks(props) {
  const {
    indexStageShow,
    listStage,
    currentIndexStage,
    handleChangeStageShow,
  } = props;

  return (
    <div className="flex mb-5 overflow-auto justify-between min-w-[100%]">
      {listStage.map((item, index) => {
        const { isDone, isCurrent, name } = item
        let imgSrc = unactive;
        if (isDone) {
          imgSrc = done
        } else if (index === currentIndexStage) {
          imgSrc = active
        }

        return (
          <button key={`stage${index}`}
            disabled={!(isDone || isCurrent)}
            // className='admissions-links min-w-[6rem] w-fit overflow-visible mx-2'
            className='admissions-links overflow-visible'
            style={{
              borderBottomWidth: '2px',
              borderBottomStyle: 'solid',
              borderBottomColor: indexStageShow === index ? 'rgba(47, 128, 237, 1)' : '#fff'
            }}
            onClick={() => handleChangeStageShow(index)}
          >
            <img className="2xl:w-5 w-4 h-4 2xl:h-5 mr-1 2xl:mr-1" src={imgSrc} alt="" />
            {name}
          </button>
        );
      })}
    </div >
  );
}
