import clsx from 'clsx';
import React from 'react';

const RESULT = {
  PASS: 'Pass',
  FAIL: 'Fail',
};

export default function Results({
  testResult,
  setTestResult,
  isUpdateResultFile,
  urlResultFile,
  isHaveResult,
}) {
  const styles = {
    box: { width: '8.5rem', height: '2.25rem', userSelect: 'none' },
    pass: {
      background: '#F1F8F5',
      border: '1px solid #439749',
      color: '#439749',
      opacity: '0.4',
    },
    fail: {
      background: '#FFF4F4',
      border: '1px solid #D82C0D',
      color: '#D82C0D',
      opacity: '0.3',
    },
    select: {
      opacity: '1',
    },
  };

  return (
    <div className="exam-results">
      <p className="text-main-black text-base font-semibold ml-6 mr-16">
        Update Test Results
      </p>
      <div
        className={clsx(
          'rounded flex items-center justify-center mr-8 ',
          isUpdateResultFile || testResult ? 'cursor-pointer' : 'cursor-not-allowed',
        )}
        style={
          testResult === RESULT.PASS
            ? Object.assign(styles.pass, styles.box, styles.select)
            : Object.assign(styles.pass, styles.box)
        }
        onClick={() => {
          if (isHaveResult || !isUpdateResultFile) {
            return;
          }
          setTestResult(RESULT.PASS);
        }}
      >
        {RESULT.PASS}
      </div>
      <div
        className={clsx(
          'rounded flex items-center justify-center mr-4',
          isUpdateResultFile || testResult ? 'cursor-pointer' : 'cursor-not-allowed',
        )}
        style={
          testResult === RESULT.FAIL
            ? Object.assign(styles.fail, styles.box, styles.select)
            : Object.assign(styles.fail, styles.box)
        }
        onClick={() => {
          if (isHaveResult || !isUpdateResultFile) {
            return;
          }
          setTestResult(RESULT.FAIL);
        }}
      >
        {RESULT.FAIL}
      </div>
    </div>
  );
}
