import { memo, useCallback, useEffect, useState } from 'react';
import UploadPhoto from '../../../../../components/admission-module/application/information/subcomponents/upload-photo/upload-photo';
import useDebounce from '@utils/custom-hook/useDebounce';
import Add from '../../add/add';
import { validEmail, validPhone } from '@utils/validate';
import ContentGroup from '@components/admission-module/application/information/subcomponents/content-group/content-group';
import InputSearchUser from '@components/admission-module/application/information/subcomponents/input-suggest-user/input-suggest-user';
import { useSuggestUser, useUser } from '@utils/custom-hook';
import { ic_close_black } from '@static/image';
import { gender as listGender } from '@utils/utils';

const ParentInfo = memo((props) => {
  const { info, setInfo, listField, parentUserIdChosen, handleChange, handleRemove, handleAdd } = props;

  return (
    <>
      {info.map((parent, index) => {
        if (parent.isDelete) return null
        return (
          <div key={`parent${index}`}>
            {index > 0 && <hr className="h-[0.2rem] mt-8 mb-4 bg-[#1805DB]" />}
            <SingleParent
              idPhoto={`parent${index}`}
              info={parent}
              order={index}
              listField={listField}
              setInfo={setInfo}
              parentUserIdChosen={parentUserIdChosen}
              handleChange={handleChange}
              handleRemove={handleRemove}
            />
          </div>
        )
      })}
      <div className="flex items-center justify-center">
        <button
          onClick={() => {
            handleAdd({ name: 'parentInfo' });
          }}
          className="my-6 none-after flex items-center font-semibold text-sm"
          style={{ color: '#ababcd' }}
        >
          <Add />
          Add Parent/ Guardian Details
        </button>
      </div>
    </>
  );
});
ParentInfo.displayName = 'ParentInfo';
export default ParentInfo;

const SingleParent = memo((props) => {
  const { info, listField, idPhoto, order, setInfo, parentUserIdChosen, handleRemove, handleChange } = props;
  const [userId, setUserId] = useState('');
  const [showOption, setShowOption] = useState(false);
  const [error, setError] = useState({});

  const {
    data: dataUser,
    error: errorUser,
    loading: loadingUser,
  } = useUser({ id: userId });
  const { data: dataSuggestByEmail } = useSuggestUser({ textSearch: info?.email });
  const { data: dataSuggestByPhone } = useSuggestUser({
    textSearch: encodeURIComponent(info.phoneNumber),
  });

  useEffect(() => {
    if (!errorUser && !loadingUser && dataUser) {
      const { name, photoURL, detail, id } = dataUser;
      const { gender, email, phoneNumber } = detail;
      const data = {
        fullName: name,
        gender: listGender.find(item => item.value === gender),
        email,
        phoneNumber,
        photoURL,
        parentUserId: id,
        infoOrigin: dataUser,
      };
      setInfo((prev) => {
        prev.parentInfo[order] = { ...data };
        return { ...prev, parentInfo: [...prev.parentInfo] };
      });
      setError({});
      setShowOption(false);
    }
  }, [dataUser, errorUser, loadingUser, order, setInfo]);

  const validateEmail = useDebounce((text) => {
    setError((prev) => ({ ...prev, email: !validEmail(text) }));
  });

  const validatePhone = useDebounce((text) => {
    setError((prev) => ({ ...prev, phoneNumber: !validPhone(text) }));
  });

  return (
    <>
      <button title='remove' className='float-right' onClick={() => {
        handleRemove({ name: 'parentInfo', order })
      }}>
        <img src={ic_close_black} alt='remove' />
      </button>
      <UploadPhoto
        info={info?.photoURL}
        id={idPhoto}
        handleChange={(val) => {
          handleChange({ name: 'parentInfo', order, key: 'photoURL', value: val });
        }}
      />
      {listField?.map((item, key) => {
        return (
          <ContentGroup
            key={`field${key}`}
            data={item}
            variation="parent"
            info={info?.[item.name]}
            handleChange={(val) => {
              handleChange({ name: 'parentInfo', order, key: item.name, value: val });
            }}
          />
        );
      })}
      <div className="mb-4">
        <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
          Mobile Number
        </p>
        <InputSearchUser
          value={info?.phoneNumber || ''}
          label=""
          customStyle={{ borderRadius: 0 }}
          error={error?.phoneNumber}
          showOption={showOption}
          options={dataSuggestByPhone || []}
          exceptId={parentUserIdChosen}
          handleChangeText={(e) => {
            handleChange({ name: 'parentInfo', order, key: 'phoneNumber', value: e });
            setShowOption(true);
            validatePhone(e);
          }}
          handleChooseOption={(e) => {
            parentUserIdChosen.current[e.id] = e.id
            setUserId(e.id);
          }}
        />
      </div>
      <div>
        <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
          Email
        </p>
        <InputSearchUser
          value={info?.email || ''}
          label=""
          customStyle={{ borderRadius: 0, height: 'unset' }}
          error={error?.email}
          showOption={showOption}
          options={dataSuggestByEmail || []}
          exceptId={parentUserIdChosen}
          handleChangeText={(e) => {
            handleChange({ name: 'parentInfo', order, key: 'email', value: e });
            setShowOption(true);
            validateEmail(e);
          }}
          handleChooseOption={(e) => {
            parentUserIdChosen.current[e.id] = e.id
            setUserId(e.id);
          }}
        />
      </div>
    </>
  );
});
SingleParent.displayName = 'SingleParent';
