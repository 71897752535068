import clsx from 'clsx';
import { useContext, useEffect, useMemo, useState } from 'react';
import useDebounce from '@hooks/useDebounce';
import { delegate, search } from '@pages/tasks/image';
import { cancel, mainavatar } from '@static/image';
import { Button } from '@stories/index';
import { ToastMessageContext } from 'context/toast-context';
import './menu-task.scss';
import taskApi from 'api/task';
import useStorage from 'store/storage';

const styles = {
  closeBtn: {
    backgroundColor: '#fff',
    color: '#202223',
    border: '1px solid #E1E3E5',
    marginRight: '0.5rem',
  },
  createBtn: { backgroundColor: '#404eed' },
};

export default function DelegateTask(props) {
  const {
    selectedTask,
    selectedTeam,
    setVisibleTeamModal,
    setIsUpdate = () => {},
    setRefresh = () => {},
    setPage = () => {},
    index = 0,
    checkManager,
  } = props;
  const [listUsersForSearch, setListUsersForSearch] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [listSearchResults, setListSearchResults] = useState([]);
  const [listSelectedUsers, setListSelectedUsers] = useState([]);
  const [listAssigners, setListAssigners] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const debouncedSearch = useDebounce(searchValue, 500);
  const campus = useStorage((state) => state.currentCampus);
  const user = useStorage((state) => state.auth);

  const checkTaskAssignees = useMemo(() => {
    return (selectedTask?.assignees ?? []).some(
      (assignee) => assignee?.member?.user?.id === user?.uid,
    );
  }, [user, selectedTask]);

  useEffect(() => {
    if (!!selectedTeam) {
      setListUsersForSearch(selectedTeam.members);
      setListSearchResults(selectedTeam.members);
    }
  }, [selectedTeam]);

  useEffect(() => {
    if (
      !!selectedTask &&
      Array.isArray(selectedTask?.assignees) &&
      selectedTask.assignees.length > 0
    ) {
      setListAssigners(selectedTask.assignees);
    }
  }, [selectedTask]);

  useEffect(() => {
    const map = new Map();
    listAssigners.forEach((member) => {
      map.set(member.member.id, member);
    });
    const listUsersFilter = [];
    selectedTeam.members.forEach((user) => {
      if (!map.has(user.id)) {
        listUsersFilter.push(user);
      }
    });
    setListUsersForSearch(listUsersFilter);
  }, [listAssigners, selectedTeam]);

  useEffect(() => {
    if (!debouncedSearch.trim()) {
      setListSearchResults(listUsersForSearch);
      return;
    }
    const result = listUsersForSearch.filter((data) => {
      return (
        data.user.name.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
        data.user.email.toLowerCase().includes(debouncedSearch.toLowerCase())
      );
    });
    setListSearchResults(result);
  }, [debouncedSearch, listUsersForSearch]);

  const handleDelegateUser = () => {
    if (listAssigners.length === 0) {
      setToastMessage({
        status: 'warning',
        title: 'Please Select User To Assign Task!',
        message: '',
      });
      setIsShowToastMessage(true);
      return;
    }
    const data = listAssigners.map((user) => {
      return user.member.id;
    });
    taskApi
      .delegateTaskById(
        { campusId: campus?.isCentral ? undefined : campus?.id },
        { owners: data },
        selectedTask.id,
      )
      .then((results) => {
        if (results?.data?.success) {
          setToastMessage({
            status: 'success',
            title: 'Delegate Task Successfully',
            message: results.data.message,
          });
          setIsShowToastMessage(true);
          setVisibleTeamModal(false);
          setRefresh((prev) => ({
            ...prev,
            count: (prev.count || 0) + 1,
            index,
          }));
          setIsUpdate((prev) => !prev);
          setPage(0);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Delegate Task Failed',
            message: results.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Delegate Task Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Delegate Task Error', error);
      });
  };

  const checkUserInList = (user, listUsers) => {
    const result = listUsers.find((data) => {
      return data.id === user.id;
    });
    return !!result;
  };

  return (
    <div className={'menu-task menu-task-active relative'}>
      <div
        className="menu-task_bg"
        onClick={() => {
          setVisibleTeamModal(false);
        }}
      />
      <div className="menu-task-card w-[50vw] 1920px:w-[40vw]">
        <div className="menu-task-card-header w-[100%]">
          <div className="flex items-center text-2xl ml-6">
            <img src={delegate} alt="delegate" className="w-6 h-6"></img>
            <h2 className="ml-2">Delegate Task</h2>
          </div>
          <button
            className="mr-4"
            onClick={() => {
              setVisibleTeamModal((prevState) => !prevState);
            }}
          >
            <img src={cancel} alt="closed modal" />
          </button>
        </div>
        <div className="h-[0.5px] mb-2 mx-6 border relative">
          {isSearch && (
            <div className="absolute top-12 w-[100%] h-[14rem] max-h-[14.25rem] bg-[#fff] overflow-x-hidden overflow-y-scroll">
              <div className="m-3">
                {listSearchResults.length > 0 && (
                  <>
                    {listSearchResults.map((user) => {
                      return (
                        <div key={user.id} className="flex flex-col">
                          <div
                            onClick={() => {
                              setListSelectedUsers((prevState) => {
                                let data = [];
                                if (!checkUserInList(user, prevState)) {
                                  data = [...prevState, user];
                                } else {
                                  data = prevState.filter((state) => {
                                    return state.id !== user.id;
                                  });
                                }
                                return [...data];
                              });
                            }}
                            className={clsx(
                              'flex flex-1 items-center ml-1 my-2 p-2 border rounded-lg cursor-pointer',
                              checkUserInList(user, listSelectedUsers)
                                ? 'border-indigo-500/100'
                                : '',
                            )}
                          >
                            <img
                              src={user?.user?.photoURL || mainavatar}
                              alt="avatar"
                              className="w-8 h-8 rounded-full"
                            />
                            <p className="ml-4">{user?.user?.name || 'User Name'}</p>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
                {listSearchResults.length === 0 && <div>User not found</div>}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col mb-[1.5rem]">
          <div className="w-[96%] flex px-4 py-2">
            <img
              onClick={() => {}}
              src={search}
              className="mx-2 cursor-pointer text-[#000000]"
              alt=""
            />
            <input
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                setIsSearch(true);
              }}
              onBlur={() => {
                // setSearchValue('');
                // setIsSearch(false);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                }
              }}
              placeholder="Search by Name or Email"
              className="focus:outline-none"
            />
          </div>
          {listAssigners.length !== 0 && (
            <ul className="w-[98%] m-2 h-[24vh] overflow-x-hidden overflow-y-scroll">
              {listAssigners.map((data, idx) => {
                return (
                  <li
                    key={idx}
                    className="flex justify-start items-center ml-5 rounded text-sm my-2 cursor-pointer"
                  >
                    <div className="flex flex-1 items-center">
                      <img
                        src={data?.member?.user?.photoURL || mainavatar}
                        alt="avatar"
                        className="w-8 h-8 rounded-full"
                      />
                      <p className="ml-4">
                        {data?.member?.user?.name || 'User Name'} |{' '}
                        {data?.member?.user?.roles?.[0]?.name || 'Position'}
                      </p>
                    </div>
                    <div className="flex items-center justify-end min-w-[15%]">
                      <div></div>
                      <div
                        className="mx-1 p-1"
                        onClick={() => {
                          const dataFilter = [...listAssigners];
                          dataFilter.splice(idx, 1);
                          setListAssigners(dataFilter);
                        }}
                      >
                        <img src={cancel} alt="closed modal" />
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
          {listAssigners.length === 0 && (
            <div className="w-[98%] m-4 h-[24vh] ml-10">
              Please assign member to do task
            </div>
          )}
        </div>
        <div className="h-[0.5px] my-2 mx-6 mt-4 border"></div>
        {!isSearch && (
          <div className="flex justify-end pr-4 absolute right-6 bottom-[1.5rem]">
            <Button
              text="Cancel"
              customStyle={styles.closeBtn}
              onClick={() => {
                setVisibleTeamModal((prevState) => !prevState);
              }}
            />
            <Button
              text="Save"
              customStyle={styles.createBtn}
              onClick={handleDelegateUser}
            />
          </div>
        )}
        {isSearch && (
          <div className="flex justify-end pr-4 absolute right-6 bottom-[1.5rem]">
            <Button
              text="Cancel"
              customStyle={styles.closeBtn}
              onClick={() => {
                setSearchValue('');
                setListSelectedUsers([]);
                setIsSearch(false);
              }}
            />
            <Button
              text="Delegate User"
              customStyle={styles.createBtn}
              onClick={() => {
                if (listSelectedUsers.length > 0) {
                  setListAssigners((prevState) => {
                    const dataAdd = listSelectedUsers.map((user) => {
                      return { member: user };
                    });
                    return [...prevState, ...dataAdd];
                  });
                  setSearchValue('');
                  setIsSearch(false);
                  setListSelectedUsers([]);
                  setListSearchResults([]);
                } else {
                  setToastMessage({
                    status: 'error',
                    title: 'Validation Rule',
                    message: 'Please select at least one user',
                  });
                  setIsShowToastMessage(true);
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
