import React, { useState, useEffect, useRef } from 'react';
import { deleteIcon, editIcon, move } from './icon';
// import './style.scss';

export default function FormImage(props) {
  const {
    index,
    value,
    customStyle,
    label,
    isEdit,
    isFocus,
    isRequired,
    isBuild,
    deletable,
    onChange,
    error,
    textForError,
    handleDragStart,
    handleFocus,
    handleDelete,
    handleDragOver,
    formStyle,
    ...rest
  } = props;
  const [imgPreview, setImgPreview] = useState('');
  const refInput = useRef();

  useEffect(() => {
    if (value) {
      if (typeof value === 'object' && value.name) {
        let reader = new FileReader();
        reader.onload = (e) => {
          setImgPreview(e.target.result);
        };
        reader.readAsDataURL(value);
      }
    }
  }, [value]);

  return (
    <div
      className="component"
      draggable
      onDragStart={(e) => {
        handleDragStart(e, index);
      }}
      onDragOver={handleDragOver}
      style={{
        ...formStyle,
        outline: error ? '1px #ff4842 solid' : isFocus ? '1px black solid' : '',
        outlineOffset: '0rem',
      }}
    >
      <div className="setting items-center absolute bg-white px-[0.15rem] right-2 top-[-0.5rem] z-10">
        {isBuild && (
          <>
            <img
              className="cursor-pointer"
              title="edit"
              src={editIcon}
              onClick={() => {
                handleFocus(index);
              }}
            />
            <img
              className="ml-2 cursor-pointer"
              title="delete"
              src={deleteIcon}
              onClick={() => {
                handleDelete(index);
              }}
            />
          </>
        )}
        {isEdit && (
          <>
            <img
              className="cursor-pointer"
              title="edit"
              src={editIcon}
              onClick={() => {
                handleFocus(index);
              }}
            />
            {deletable && (
              <img
                className="ml-2 cursor-pointer"
                title="delete"
                src={deleteIcon}
                onClick={() => {
                  handleDelete(index);
                }}
              />
            )}
          </>
        )}
      </div>
      <p className="w-full h-4 text-sm text-left">{label}</p>
      <div
        className="w-full bg-[#e5effd] flex items-center justify-center cursor-pointer"
        style={{ height: 'calc(100% - 2rem)' }}
        onClick={() => {
          if (refInput.current) refInput.current.click();
        }}
      >
        {value ? (
          <img
            className="image"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'fill',
            }}
            src={imgPreview || value}
            alt="uploaded image"
          />
        ) : (
          <p className="image text-main-blue text-xl">+</p>
        )}
        {/* {value &&
          value.map((file, index) => <ShowSingleDocument key={index} file={file} />)} */}
        <p className="image text-main-blue text-xl">+</p>
        <input
          ref={refInput}
          type="file"
          id="image-upload-2"
          name="image-upload-2"
          className="hidden"
          accept="image/*"
          onChange={onChange}
        />
        {error && textForError && (
          <p className="text-sm" style={{ color: '#ff4842', marginLeft: 10 }}>
            {textForError}
          </p>
        )}
      </div>
    </div>
  );
}

// const ShowMultiDocument = (props) => {
//   const { files } = props;
//   return (
//     <div>
//       {files.map((file, index) => (
//         <ShowSingleDocument key={`${file.name}${index}`} file={file} />
//       ))}
//     </div>
//   );
// };

const ShowSingleDocument = (props) => {
  const { file } = props;
  return <a href={file.pathName}>{file.name}</a>;
};
