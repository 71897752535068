import React, { useState } from 'react';
import clsx from 'clsx';

export default function RadioGroup(props) {
  const { label, value, options, onChange, customStyle } = props;
  const [pValue, setPvalue] = useState();
  const style = { height: '1.875rem', fontSize: '0.813rem' };

  const handleOption = (() => {
    if (typeof options === 'string') {
      return options.split(';');
    }
    return options;
  })();

  const handleOnChange = onChange
    ? onChange
    : (val) => {
        setPvalue((prev) => val);
      };

  return (
    <div style={customStyle}>
      <p className="text-xs font-medium text-button-disable relative">{label}</p>
      <div className="flex mt-2 items-center justify-around">
        {handleOption.map((item, index) => {
          return (
            <SingleButton
              key={`${item + index}`}
              item={item}
              style={style}
              onClick={handleOnChange}
              value={value || pValue}
            />
          );
        })}
      </div>
    </div>
  );
}

function SingleButton(props) {
  const { style, item, value, onClick } = props;
  return (
    <button
      onClick={() => {
        onClick(item);
      }}
      value={item}
      className={clsx(
        'text-white rounded btn',
        value && value.toLowerCase() === item.toLowerCase() ? 'bg-main-blue' : 'bg-blue-200',
      )}
      style={{ ...style }}
    >
      {item}
    </button>
  );
}
