import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import './input.scss';

const Input = (props) => {
  const {
    refCustom,
    isHideLabel,
    classGroup,
    type,
    label,
    name,
    labelFix,
    placeholder,
    error,
    value,
    customStyle,
    inputSize,
    disabled,
    textForError,
    onChange,
    ...rest
  } = props;
  return (
    <React.Fragment>
      <div className={`input ${classGroup}`}>
        <input
          ref={refCustom}
          disabled={disabled}
          value={value}
          type={type}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          className={clsx(
            'input-styled',
            error ? `error` : ``,
            `input-size-${inputSize}`,
          )}
          style={{ width: '100%', height: '100%', ...customStyle }}
          autoComplete="off"
          required
          {...rest}
          min={type === "number" ? 0 : undefined}
        />
        {/* {!disabled && (
          <label htmlFor={name} className={`label ${labelFix}`}>
            {label}
          </label>
        )} */}
        {disabled ? (
          isHideLabel ? (
            <label htmlFor={name} className={`label ${labelFix}`} style={{ top: '0px' }}>
              {/* {label} */}
            </label>
          ) : (
            <label htmlFor={name} className={`label ${labelFix}`} style={{ top: '0px' }}>
              {label}
            </label>
          )

        ) : (
          !isHideLabel && (
            <label htmlFor={name} className={`label whitespace-nowrap ${labelFix}`}>
              {label}
            </label>
          )
        )}
      </div>
      {error && textForError && (
        <p style={{ color: '#ff4842', marginLeft: 10 }}>{textForError}</p>
      )}
    </React.Fragment>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  textForError: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  customStyle: PropTypes.object,
  inputSize: PropTypes.string,
  onChange: PropTypes.func,
};

Input.defaultProps = {
  type: 'text',
  label: 'Text Input',
  onChange: () => { },
  // value: '',
  name: 'thatinput',
  placeholder: '',
  error: false,
  customStyle: {},
  inputSize: 'medium',
};

export default Input;
