import React, { memo, useEffect, useMemo, useState } from 'react';
import RenderSingleForm from './RenderSingleForm';

const RenderForm = memo((props) => {
  const { data, refreshOutside } = props;
  const [renderListForm, setRenderListForm] = useState([]);

  const style = useMemo(
    () => ({
      marginBottom: '2rem',
      padding: '1rem 0.5rem',
      borderRadius: '0.5rem',
      boxShadow:
        '0.2rem 0.2rem 1rem 0.2rem rgba(145, 158, 171, 0.24), -0.2rem 0px 1rem 0.2rem rgba(145, 158, 171, 0.24)',
    }),
    [],
  );

  useEffect(() => {
    const tmp = Object.keys(data).map((key) => {
      return (
        <RenderSingleForm
          key={key}
          data={data[key]}
          styleForm={style}
          refreshFromOutside={refreshOutside}
        />
      );
    });
    setRenderListForm(tmp);
  }, [data, refreshOutside, style]);

  if (!data || Object.keys(data).length === 0) return <div></div>;

  return <div>{renderListForm}</div>;
});
RenderForm.displayName = 'RenderForm';
export default RenderForm;
