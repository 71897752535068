export const RadioButton = ({ onChange, id, checked, label, value, className }) => {
  return (
    <div className="RadioButton">
      <input
        id={id}
        onChange={onChange}
        value={value}
        type="radio"
        checked={checked}
      />
      <label htmlFor={id} className={className}>{label}</label>
    </div>
  );
};
