import React, { memo } from 'react';

const Popup = memo((props) => {
  const { handleEdit, handleDelete, customClass } = props;
  return (
    <div
      className={`absolute top-[35%] right-0 max-w-[20%] px-[2%] py-[1%] text-white text-center rounded ${customClass}`}
      style={{ backgroundColor: '#8080806b' }}
    >
      <ul className="text-xs">
        <li
          className="cursor-pointer px-[8px] py-[2px] rounded hover:bg-sky-500/100"
          onClick={() => {
            handleEdit();
          }}
        >
          edit
        </li>
        <li>
          <hr className="my-[2px]" />
        </li>
        {/* <li
          className="cursor-pointer px-[8px] py-[2px] rounded hover:bg-sky-500/100"
          onClick={() => {
            handleDelete();
          }}
        >
          delete
        </li> */}
      </ul>
    </div>
  );
});
Popup.displayName = 'Popup';
export default Popup;
