import clsx from 'clsx';
import { ToastMessageContext } from 'context/toast-context';
import { EnquiryStatus } from 'entities/data';
import { dataLinks } from 'entities/routes';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Fade from 'react-reveal';
import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import AdmissionsLinks from '@components/admission-module/admissions-links/admissions-links';
import {
  ApplicationHeader,
  ApplicationWindow,
  Complete,
  CustomStage,
  Documents,
  Exam,
  Information,
  PrincipalApproval,
  Signature,
} from '@components/admission-module/application';
import NewAdmissionPayment from '@components/admission-module/application/payment/new-admission-payment';
import ApplicationPanel from '@components/application-panel/application-panel';
import Loading from '@components/loading';
import { getListUrlStage } from '@helper/admission';
import { NavLinks } from '@pages/Header/components';
import { mainavatar } from '@static/image';
import {
  checkErrorApiFetch,
  checkErrorMultipleApiFetch,
} from '@utils/check-error/api-error';
import './application.scss';
import admissionApi from 'api/admission';
import useStorage from 'store/storage';

const filterEnquiriesOptions = [
  { value: 'All', label: 'All Enquiries' },
  { value: 'New', label: 'New' },
  { value: 'Application', label: 'Application' },
  { value: 'Referral applications', label: 'Referral' },
  { value: 'Sibling applications', label: 'Sibling' },
];

const listDataSort = [
  { status: 'Date Created', value: 'dateCreated' },
  { status: 'Date Updated', value: 'dateUpdated' },
  { status: 'Chance of conversion', value: 'Chance of conversion' },
];

const pageSize = 8;

export default function Application({ burger }) {
  const { id } = useParams();
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [listEnquiry, setListEnquiry] = useState([]);
  const [currentEnquiry, setCurrentEnquiry] = useState();
  const [currentEnquiryId, setCurrentEnquiryId] = useState('');
  const [listStage, setListStage] = useState([]);
  const [refreshCurrentEnquiry, setRefreshCurrentEnquiry] = useState(false);
  const [listStageDefault, setListStageDefault] = useState();
  const [totalEnquiry, setTotalEnquiry] = useState(0);
  const [indexStageShow, setIndexStageShow] = useState(0);
  const [sort, setSort] = useState({ value: listDataSort[0], order: 'desc' });
  const [filter, setFilter] = useState({ status: filterEnquiriesOptions[0], name: '' });
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [enquiryParent, setEnquiryParent] = useState();
  const [counselors, setCounselors] = useState([]);
  const [refreshEnquiries, setRefreshEnquiries] = useState(false);
  const refCurrentIndexStage = useRef();
  const searchParams = new URLSearchParams(document.location.search);
  const currentStepParam = searchParams.get('currentStep');
  const campusIdParam = searchParams.get('campusId');
  const programGradeIdParam = searchParams.get('programGradeId');
  const campus = useStorage((state) => state.currentCampus);

  const fetchListEnquiryAndStage = useCallback(
    async ({
      filterBy,
      orderBy,
      orderDirection,
      textSearch,
      campusId,
      pageSize,
      programGradeId,
      currentStep,
      signal,
    }) => {
      try {
        const apiStage = admissionApi.getListStage({ campusId, signal });
        const apiListEnquiry = admissionApi.getListEnquiry(
          {
            filterBy,
            orderBy,
            orderDirection,
            textSearch,
            page: 0,
            pageSize,
            campusId,
            programGradeId,
            currentStep,
          },
          signal,
        );
        const res = await Promise.all([apiListEnquiry, apiStage]);

        if (
          checkErrorMultipleApiFetch(
            res,
            setToastMessage,
            setIsShowToastMessage,
            'Get Enquiries',
          )
        ) {
          setTotalEnquiry(res[0].data.data.total);
          setListEnquiry(res[0].data.data.objects);
          setCurrentEnquiryId(res[0].data.data.objects?.[0]?.id);
          setListStageDefault(res[1].data.data);
        }
        setIsLoading(false);
      } catch (error) {
        setToastMessage({
          status: 'error',
          title: 'Get Enquiries Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        setIsLoading(false);
      }
    },
    [],
  );

  // run the first time mount component
  useEffect(() => {
    setIsLoading(true);
    const controller = new AbortController();
    fetchListEnquiryAndStage({
      campusId: campusIdParam || campus?.id,
      filterBy: filter.status.value,
      orderBy: sort.value.value,
      orderDirection: sort.order,
      textSearch: filter.name,
      pageSize,
      programGradeId: programGradeIdParam,
      currentStep: currentStepParam,
      signal: controller.signal,
    });

    return () => {
      controller.abort();
    };
  }, [campus, programGradeIdParam, campusIdParam, currentStepParam]);

  const fetchListEnquiry = async ({
    filterBy,
    orderBy,
    orderDirection,
    textSearch,
    currentPage,
    pageSize,
    campusId,
    programGradeId,
    currentStep,
    signal,
  }) => {
    try {
      const res = await admissionApi.getListEnquiry(
        {
          filterBy,
          orderBy,
          orderDirection,
          textSearch,
          page: currentPage,
          pageSize,
          campusId,
          programGradeId,
          currentStep,
        },
        signal,
      );
      if (
        checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, 'Get enquiries')
      ) {
        setTotalEnquiry(res.data.data.total);
        setListEnquiry(res.data.data.objects);

        if (!res.data.data.total) {
          setToastMessage({
            status: 'info',
            title: 'Enquiry not found',
            message: 'Enquiry not found. Please try again',
          });
          setIsShowToastMessage(true);
        }
      }
    } catch (error) {
      console.log('Get enquiries error', error);
      setToastMessage({
        status: 'error',
        title: 'Get Enquiries Failed',
        message: error.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
    }
  };

  // run every time change page number
  useEffect(() => {
    const controller = new AbortController();
    fetchListEnquiry({
      campusId: campusIdParam || campus?.id,
      filterBy: filter.status.value,
      orderBy: sort.value.value,
      orderDirection: sort.order,
      textSearch: filter.name,
      currentPage,
      pageSize,
      programGradeId: programGradeIdParam,
      currentStep: currentStepParam,
      signal: controller.signal,
    });
    return () => {
      controller.abort();
    };
  }, [
    currentPage,
    programGradeIdParam,
    campusIdParam,
    currentStepParam,
    refreshEnquiries,
  ]);

  // run every time sort or filter change
  useEffect(() => {
    const controller = new AbortController();
    setTimeout(() => {
      fetchListEnquiry({
        campusId: campusIdParam || campus?.id,
        filterBy: filter.status.value,
        orderBy: sort.value.value,
        orderDirection: sort.order,
        textSearch: filter.name,
        currentPage: 0,
        pageSize,
        programGradeId: programGradeIdParam,
        currentStep: currentStepParam,
        signal: controller.signal,
      });
    }, 300);
    return () => {
      controller.abort();
    };
  }, [sort, filter, campusIdParam, programGradeIdParam, currentStepParam]);

  const getEnquiry = useCallback(
    async ({ enquiryId, campusId, signal, listStageDefault }) => {
      try {
        const responses = await Promise.all([
          admissionApi.getEnquiry({ enquiryId, campusId, signal }),
          admissionApi.getParentOfEnquiry({ enquiryId, signal }),
        ]);
        if (
          checkErrorMultipleApiFetch(
            responses,
            setToastMessage,
            setIsShowToastMessage,
            'Get enquiry 1',
          )
        ) {
          const enquiry = responses[0].data.data;
          setCurrentEnquiry(enquiry);
          responses[0].data?.data?.enquiry_counselors &&
            setCounselors(responses[0].data.data.enquiry_counselors);
          const listUrl = getListUrlStage({ enquiry, stages: listStageDefault });
          setListStage(listUrl);
          let current = listUrl.findIndex((item) => item.isCurrent);
          if (current === -1) {
            if (listUrl?.every((url) => url?.isDone)) {
              current = (listUrl?.length || 1) - 1;
            } else {
              current = 0;
            }
          }
          refCurrentIndexStage.current = current;
          setIndexStageShow(current);
          if (responses[1].data.data)
            setEnquiryParent({
              relationship: responses[1].data.data?.relationship,
              fullName: responses[1].data.data?.users?.name,
              email: responses[1].data.data?.users?.email,
              phoneNumber: responses[1].data.data?.users?.phoneNumber,
              parentUserId: responses[1].data.data?.parentId,
              gender: null,
            });
        }
        setIsLoading(false);
      } catch (error) {
        console.log('Get enquiries error', error);
        setToastMessage({
          status: 'error',
          title: 'Get Enquiries Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        setIsLoading(false);
      }
    },
    [],
  );

  // run every time change enquiry
  useEffect(() => {
    const controller = new AbortController();
    if (currentEnquiryId) {
      getEnquiry({
        enquiryId: currentEnquiryId,
        campusId: campus?.isCentral ? undefined : campus?.id,
        listStageDefault,
        signal: controller.signal,
      });
    }
    return () => {
      controller.abort();
    };
  }, [currentEnquiryId, refreshCurrentEnquiry]);

  // const counselors = useMemo(() => {
  //   const result =
  //     currentEnquiry &&
  //     currentEnquiry.programGrade.admissionTeamSetting?.counselors?.map((item) => ({
  //       id: item.counselor.id,
  //       name: item.counselor.name,
  //       photoURL: item.counselor.photoURL,
  //     }));
  //   return result;
  // }, [currentEnquiry, refreshCurrentEnquiry]);
  const isCompletedOrClosed =
    [EnquiryStatus.CLOSED, EnquiryStatus.COMPLETE].includes(currentEnquiry?.status) ||
    currentEnquiry?.enquiryStatus === EnquiryStatus.FAILED.toLowerCase();

  const mapStepDefault = useMemo(
    () => ({
      Application: (stageName) => (
        <Fade key={stageName} clear duration={100}>
          <Information
            burger={burger}
            stageName={stageName}
            enquiry={currentEnquiry}
            enquiryParent={{ ...enquiryParent }}
            isCompletedOrClosed={isCompletedOrClosed}
            handleNextStage={handleNextStage}
            handleRefreshEnquiry={handleRefreshEnquiry}
          />
        </Fade>
      ),
      Signature: (stageName) => (
        <Fade key={stageName} clear duration={100}>
          <Signature
            isCompletedOrClosed={isCompletedOrClosed}
            enquiry={currentEnquiry}
            handleNextStage={handleNextStage}
            setRefreshCurrentEnquiry={setRefreshCurrentEnquiry}
          />
        </Fade>
      ),
      Exam: (stageName) => (
        <Fade key={stageName} clear duration={100}>
          <Exam
            enquiry={currentEnquiry}
            isCompletedOrClosed={isCompletedOrClosed}
            handleNextStage={handleNextStage}
            handleRefreshEnquiry={handleRefreshEnquiry}
            handleRefreshAndNext={handleRefreshAndNext}
          />
        </Fade>
      ),
      'Principal Approval': (stageName) => (
        <Fade key={stageName} clear duration={100}>
          <PrincipalApproval
            enquiry={currentEnquiry}
            isCompletedOrClosed={isCompletedOrClosed}
            handleNextStage={handleNextStage}
            handleRefreshEnquiry={handleRefreshEnquiry}
            handleRefreshAndNext={handleRefreshAndNext}
          />
        </Fade>
      ),
      'Document Verification': (stageName) => (
        <Fade key={stageName} clear duration={100}>
          <Documents
            isParent={false}
            enquiry={currentEnquiry}
            isCompletedOrClosed={isCompletedOrClosed}
            handleNextStage={handleNextStage}
            handleRefreshAndNext={handleRefreshAndNext}
            handleRefreshEnquiry={handleRefreshEnquiry}
          />
        </Fade>
      ),
      'Fee Payment': (stageName) => (
        <Fade key={stageName} clear duration={100}>
          {/* <AdmissionPayment
          stageName={stageName}
          isParent={false}
          fee={currentEnquiry?.programGrade?.programGradeFee?.feePayment}
          currency={currentEnquiry?.programGrade?.programGradeFee?.currency}
          enquiry={currentEnquiry}
          isCompletedOrClosed={isCompletedOrClosed}
          handleNextStage={handleNextStage}
          handleRefreshAndNext={handleRefreshAndNext}
        /> */}
          <NewAdmissionPayment enquiry={currentEnquiry} />
        </Fade>
      ),
      Complete: (stageName) => (
        <Fade key={stageName} clear duration={100} style={{ width: '100%' }}>
          <Complete stageName={stageName} enquiry={currentEnquiry} />
        </Fade>
      ),
    }),
    [currentEnquiry, isCompletedOrClosed],
  );

  const result = useCallback(() => {
    if (listStage.length !== 0) {
      const stage = listStage[indexStageShow];
      const { formId, name, textDefault } = stage;
      if (textDefault) {
        return mapStepDefault[textDefault](name);
      } else {
        return (
          <Fade key={name} clear duration={100} style={{ width: '100%' }}>
            <CustomStage
              formId={formId}
              stageName={name}
              enquiry={currentEnquiry}
              listStage={listStage}
              handleNextStage={handleNextStage}
              handleRefreshAndNext={handleRefreshAndNext}
            />
          </Fade>
        );
      }
    }
  }, [indexStageShow, listStage]);

  const handleNextStage = (index) => {
    setIndexStageShow((prev) => prev + 1);
  };

  const handleChangeStageShow = (index) => {
    setIndexStageShow(index);
  };

  const handleRefreshEnquiry = () => {
    setRefreshCurrentEnquiry((prev) => !prev);
  };

  const handleRefreshAndNext = (index) => {
    handleRefreshEnquiry();
    handleNextStage(index);
  };

  const handleChangeEnquiry = (enquiryId) => {
    if (enquiryId === currentEnquiryId) return;
    setIsLoading(true);
    setCurrentEnquiryId(enquiryId);
    refCurrentIndexStage.current = undefined;
    setIndexStageShow(0);
  };

  const handleChangeSort = ({ key, value }) => {
    setSort((prev) => ({ ...prev, [key]: value }));
  };

  const handleChangeFilter = ({ key, value }) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  const handleChangeInputFilter = (e) => {
    setFilter((prev) => ({ ...prev, name: e.target.value.trim() }));
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleRefreshEnquiries = () => {
    setRefreshEnquiries((prev) => !prev);
  };

  return (
    <>
      <div
        className={clsx(
          'application transition-all-300 pr-28',
          burger ? '1400px:pl-[15rem]' : '1400px:pl-[4.5rem]',
        )}
      >
        <ApplicationWindow
          fixed={true}
          listStage={listStage}
          burger={burger}
          currentEnquiry={currentEnquiry}
          listEnquiry={listEnquiry}
          sort={sort}
          filter={filter}
          pageSize={pageSize}
          currentPage={currentPage}
          totalEnquiry={totalEnquiry}
          handleChangePage={handleChangePage}
          handleChangeEnquiry={handleChangeEnquiry}
          handleChangeSort={handleChangeSort}
          handleChangeFilter={handleChangeFilter}
          handleChangeInputFilter={handleChangeInputFilter}
          currentEnquiryId={currentEnquiryId}
        />
        <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
        <div className="ml-[18.4rem]">
          <div className="flex items-center justify-between">
            <NavLinks urls={dataLinks.applicationLinks} idLocation={id} />
            <div className="flex -space-x-2 ml-2 items-center">
              <h3 className="relative right-10 text-main-black text-sm font-medium">
                Counselors
              </h3>
              {counselors?.length < 4 ? (
                counselors.map((item) => {
                  return (
                    <img
                      data-for="main"
                      data-tip={item.name || 'User Name'}
                      data-iscapture="true"
                      key={item.id}
                      className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                      src={item.photoURL || mainavatar}
                      alt=""
                    />
                  );
                })
              ) : counselors?.length >= 4 ? (
                [0, 1, 2, 3].map((key) => {
                  return key !== 3 ? (
                    <img
                      data-for="main"
                      data-tip={counselors[key]?.name || 'User Name'}
                      data-iscapture="true"
                      key={key}
                      className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                      src={counselors[key]?.photoURL || mainavatar}
                      alt=""
                    />
                  ) : (
                    <div
                      key={key}
                      className="flex items-center justify-center bg-green-200 text-sm h-8 w-8 rounded-full ring-2 ring-white"
                    >
                      +{counselors.length - 3}
                    </div>
                  );
                })
              ) : (
                <div>-</div>
              )}
            </div>
          </div>
          {currentEnquiry && (
            <ApplicationHeader
              setIsLoading={setIsLoading}
              enquiry={currentEnquiry}
              handleNextStage={handleNextStage}
              handleRefreshEnquiries={handleRefreshEnquiries}
            />
          )}
          {currentEnquiry && (
            <AdmissionsLinks
              enquiry={currentEnquiry}
              indexStageShow={indexStageShow}
              listStage={listStage}
              currentIndexStage={refCurrentIndexStage.current}
              handleChangeStageShow={handleChangeStageShow}
            />
          )}
          {/* <div className="flex items-center justify-center"> */}
          <div>
            {/* {enquiry &&
            renderComponents.map((item, index) => {
              return id === item.linkId && item.componentRender(index);
            })} */}
            {/* {enquiry &&
            renderComponentDynamic.map((item, index) => {
              return (
                id === item.linkId && (
                  <Fade key={index} clear duration={100}>
                    <CustomStage
                      burger={burger}
                      enquiry={enquiry}
                      idSelectedEnquiry={idSelectedEnquiry}
                      setIsCallAPI={setIsCallAPI}
                      formId={item.formId}
                      stageName={item.stageName}
                    />
                  </Fade>
                )
              );
            })} */}
            {result()}
          </div>
        </div>
        <ApplicationPanel enquiry={currentEnquiry} enquiryParent={enquiryParent} />
      </div>
      {isLoading && (
        <div className="fixed inset-0 bg-[#33333380] flex items-center justify-center z-[9999]">
          <Loading />
        </div>
      )}
    </>
  );
}

Application.propTypes = {
  burger: PropTypes.bool,
};
