import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  //   page: {
  //     marginRight: '30pt',
  //   },
  header_container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '20px',
    marginTop: '20px',
    marginLeft: '30px',
    fontFamily: 'Helvetica-Bold',
  },
  name_text: {
    paddingBottom: '5px',
    fontSize: '17px',
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  profession_text: {
    fontSize: '13px',
    fontWeight: '400',
  },
  header_img: {
    width: '60px',
    height: '60px',
    marginRight: '20px',
  },
  campus_container: {
    marginLeft: '30px',
    marginRight: '30px',
    marginBottom: '10px',
    fontSize: '13px',
  },
});
