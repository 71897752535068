import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Input, Checkbox } from '../../../stories'
import Footer from '../../../components/footer/footer'

export default function ProgramTable({ burger }) {
  const styles = {
    tr: { top: 0, position: 'relative' },
    th: { display: 'flex', top: 25, position: 'relative' },
    input: { height: 43 }
  }
  const tableTitle = ['', 'Grade Name', 'Custome Name', 'Curriculum', 'Max Students per class', 'No of sections', 'Min Age']
  const listProgrammes = [
    {
      select: true,
      gradeName: 'Play Group',
      customeName: 'Play Group',
      curriculum: 'PYP',
      maxStudent: '',
      noOfSection: '',
      minAge: ''
    },
    {
      select: false,
      gradeName: 'Pre-K',
      customeName: 'Nursery',
      curriculum: 'PYP',
      maxStudent: '',
      noOfSection: '',
      minAge: ''
    },
    {
      select: false,
      gradeName: 'Grade 1',
      customeName: 'EY 1',
      curriculum: 'PYP',
      maxStudent: '',
      noOfSection: '',
      minAge: ''
    },
    {
      select: true,
      gradeName: 'Grade 2',
      customeName: 'EY 2',
      curriculum: 'PYP',
      maxStudent: '',
      noOfSection: '',
      minAge: ''
    },
    {
      select: false,
      gradeName: 'Grade 3',
      customeName: 'EY 3',
      curriculum: 'PYP',
      maxStudent: '',
      noOfSection: '',
      minAge: ''
    },
    {
      select: true,
      gradeName: 'Grade 4',
      customeName: 'EY 4',
      curriculum: 'PYP',
      maxStudent: '',
      noOfSection: '',
      minAge: ''
    },
    {
      select: false,
      gradeName: 'Grade 5',
      customeName: 'EY 5',
      curriculum: 'PYP',
      maxStudent: '',
      noOfSection: '',
      minAge: ''
    }
  ]

  const onChangeCustomeName = (e) => {
  }

  return (
    <div className={clsx('flex-col', burger ? 'mr-6' : 'mr-10')}>
      <div className="flex text-center items-center border-b-[1px] border-solid border-black pb-4">
        <div className='w-10'></div>
        <div className='w-48'>Grade Name</div>
        <div className='w-64'>Custome Name</div>
        <div className='w-32'>Curriculum</div>
        <div className='w-60'>Max Students per class</div>
        <div className='w-32'>No of sections</div>
        <div className='w-32'>Min Age</div>

        {/* {tableTitle.map((title, idx) => {
            return (
                <div key={idx}>
                    {title}
                </div>
            )
        })} */}
      </div>
      {listProgrammes.map((program, idx) => {
        return <ShowProgram key={idx} program={program} onChangeCustomeName={onChangeCustomeName} />
      })}
      <div className='h-20'></div>
      {/* <div style={{display: 'block'}}> */}
      <Footer burger={burger} />
      {/* </div> */}
    </div>
  )
}

const ShowProgram = ({ program, onChangeCustomeName }) => {
  return (
    <div className='flex text-center items-center mt-5'>
      <div className='w-10'><Checkbox onChange={() => { }} value={program.select} /></div>
      <div className='w-48'>{program.gradeName}</div>
      <div className='w-64'>
        <Input
          inputSize='small'
          disabled={program.lock}
          label={program.customeName}
          name={program.customeName}
          value=''
          customStyle={{ width: '100%', paddingBottom: '20px', paddingTop: '8px' }}
          onChange={onChangeCustomeName}
        />
      </div>
      <div className='w-32'>{program['curriculum']}</div>
      <div className='w-60'>{program.maxStudent}</div>
      <div className='w-32'>{program.noOfSection}</div>
      <div className='w-32'>{program.minAge}</div>
    </div>
  )
}

ProgramTable.propTypes = {
  burger: PropTypes.bool
}
