import React from 'react'
import PropTypes from 'prop-types'

import DataWidget from '../data-widget/data-widget.jsx'

export default function TotalCards(props) {
  const { dataTotal = {} } = props;

  return (
    <div className='flex relative justify-between right-1.5 grid-container__item--1'>
      {dataTotal.enquiry && <DataWidget title='Total Enquiries' count={dataTotal.enquiry?.total} percents={dataTotal.enquiry?.percent} />}
      {dataTotal.application && <DataWidget title='Total Applications' count={dataTotal.application?.total} percents={dataTotal.application?.percent} />}
      {dataTotal.enrolment && <DataWidget title='Total Enrolment' count={dataTotal.enrolment?.total} percents={dataTotal.enrolment?.percent} />}
    </div>
  )
}

TotalCards.propTypes = {
  /**  @param Type An array of objects.
   * @param Object Keys for objects - title: string, count: string, percents: string
   * @param Example [
  { title: 'Total Enquiries', count: '1,246', percents: '2,6' },
  { title: 'Total Enrolment', count: '1,567', percents: '4,6' },
  { title: 'Total Enrolment', count: '1,567', percents: '4,6' }
]
   **/
  data: PropTypes.array
}
