import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Fade } from 'react-reveal';
import { Button, Scheduled, StaticPicker } from '../../../../stories';
import { icchevron } from '../principal-approval/image';
import { Accept, ButtonDatePicker, Results } from './sub-components';
import { listTime } from '@constant/list-time';
import { checkErrorSingleApi } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import './exam.scss';
import { calendar, clock, students } from './image';
import admissionApi from 'api/admission';
import useStorage from 'store/storage';

export default function Exam(props) {
  const { enquiry, isCompletedOrClosed, handleNextStage, handleRefreshEnquiry, handleRefreshAndNext } = props
  const styles = {
    button: {
      paddingLeft: '3rem',
      paddingRight: '1.875rem',
      position: 'absolute',
      right: '0.313rem',
    },
  };
  const [schedule, setSchedule] = useState(false);
  const [testDate, setTestDate] = useState();
  const [exam, setExam] = useState({});
  const [applicationId, setApplicationId] = useState(null);
  const [time, setTime] = useState({});
  const [testResult, setTestResult] = useState('');
  const [isUpdateResultFile, setIsUpdateResultFile] = useState(false);
  const [urlResultFile, setUrlResultFile] = useState('');
  const [isUpdateExam, setIsUpdateExam] = useState(false);
  const campus = useStorage(state => state.currentCampus)
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  useEffect(() => {
    if (enquiry?.admissionResult?.info) {
      const info = JSON.parse(enquiry.admissionResult.info);
      const exam = info?.exam;
      setExam(exam);
      setTime([exam?.startTimeSlot, exam?.endTimeSlot]);
      setTestDate(exam?.testDate || new Date());
      setApplicationId(info?.application?.id);
      setUrlResultFile(exam?.testResultFileURL);
      setTestResult(exam?.testResult);
      if (!!exam) {
        setIsUpdateExam(true);
        setSchedule(true);
      } else {
        setIsUpdateExam(false);
        setSchedule(false);
      }
    }
  }, [enquiry]);

  const createExamHandle = () => {
    if (isCompletedOrClosed) {
      handleNextStage()
      return
    }
    const data = {
      applicationId: applicationId,
      testDate: testDate,
      startTimeSlot: time[0],
      endTimeSlot: time[1],
    };
    admissionApi.createApplicationExam({ campusId: campus?.isCentral ? undefined : campus?.id }, data)
      .then((response) => {
        if (
          checkErrorSingleApi(
            response,
            setToastMessage,
            setIsShowToastMessage,
            'Scheduled Exam',
          )
        ) {
          handleRefreshEnquiry()
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Scheduled Exam Error',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Scheduled Exam Error', error);
      });
  };

  const updateExamHandle = () => {
    if (isCompletedOrClosed) {
      handleNextStage()
      return
    }
    const data = {
      testDate: testDate,
      startTimeSlot: time[0],
      endTimeSlot: time[1],
    };
    admissionApi.updateApplicationExam({ campusId: campus?.isCentral ? undefined : campus?.id }, data, exam.id)
      .then((response) => {
        if (
          checkErrorSingleApi(
            response,
            setToastMessage,
            setIsShowToastMessage,
            'Update Exam Schedule',
          )
        ) {
          handleRefreshEnquiry()
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Update Exam Schedule Error',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Update Exam Error', error);
      });
  };

  const updateResultHandle = (e) => {
    e.preventDefault();
    if (isCompletedOrClosed) {
      handleNextStage()
      return
    }
    if (!!exam?.testResult) {
      setToastMessage({
        status: 'warning',
        title: 'Validate Rule',
        message: 'You cannot change the Exam result',
      });
      setIsShowToastMessage(true);
      return;
    }
    if (!testResult) {
      setToastMessage({
        status: 'warning',
        title: 'Validate Rule',
        message: 'Please select the Exam result',
      });
      setIsShowToastMessage(true);
      return;
    }
    if (!urlResultFile) {
      setToastMessage({
        status: 'warning',
        title: 'Validate Rule',
        message: 'Missing Exam result file',
      });
      setIsShowToastMessage(true);
      return;
    }
    const data = {
      testResult: testResult,
      testResultFileURL: urlResultFile,
    };
    admissionApi.updateApplicationExamResult({ campusId: campus?.isCentral ? undefined : campus?.id }, data, exam.id)
      .then((res) => {
        if (
          checkErrorSingleApi(
            res,
            setToastMessage,
            setIsShowToastMessage,
            'Update Exam Result',
          )
        ) {
          // const { data } = res;
          handleRefreshAndNext()
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Update Exam Result Error',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Update Exam Result Error', error);
      });
  };

  return schedule ? (
    <div className='flex justify-center'>
      <Fade clear duration={300}>
        <div className="flex flex-col items-center mt-10 relative px-16">
          <img src={students} alt="" className="mb-10" />
          <Scheduled
            isCompletedOrClosed={isCompletedOrClosed}
            exam={exam}
            applicationId={applicationId}
            setExam={setExam}
            setSchedule={setSchedule}
            isHaveResult={!!exam?.testResult}
            campusId={campus?.id}
          />
          <Accept
            urlResultFile={urlResultFile}
            dateUpdate={exam?.dateUpdated}
            setIsUpdateResultFile={setIsUpdateResultFile}
            setUrlResultFile={setUrlResultFile}
            isHaveResult={!!exam?.testResult}
          />
          <Results
            testResult={testResult}
            setTestResult={setTestResult}
            isUpdateResultFile={isUpdateResultFile}
            urlResultFile={urlResultFile}
            isHaveResult={!!exam?.testResult}
          />
          <div className="relative h-16 w-full">
            <Button
              text="Update Results"
              onClick={(e) => {
                updateResultHandle(e);
              }}
              leftIcon={icchevron}
              customStyle={styles.button}
            />
          </div>
        </div>
      </Fade>
    </div>
  ) : (
    <div className="flex flex-col items-center mt-5 relative">
      <div className="w-full">
        <div className='w-fit m-auto'>
          <p className="font-semibold text-main-black text-base">Schedule Test Date</p>
          <div className="exam-card" style={{ minHeight: '29.375rem' }}>
            <div
              className="flex flex-col items-center mt-6 2xl:pt-8 2xl:ml-10 ml-8 mr-10 2xl:border-b-0 border-b "
              style={{ width: '24.375rem', height: '25rem' }}
            >
              <p className="flex text-sm items-center">
                <img src={calendar} alt="calendar" className="mr-3" />
                Select Date From Below
              </p>
              <StaticPicker
                pValue={
                  testDate ? new Date(testDate).toString() : new Date().toString()
                }
                psetValue={(e) => {
                  setTestDate(e)
                }}
              />
            </div>
            <div
              className="flex flex-col items-center mt-10 2xl:mt-8  2xl:pt-6  2xl:ml-0 ml-8 mr-10"
              style={{ width: '24.375rem', height: '25rem' }}
            >
              <p className="flex text-sm items-center">
                <img src={clock} alt="calendar" className="mr-3" />
                Select time slot
              </p>
              <ButtonDatePicker listTime={listTime} time={time} setTime={setTime} />
            </div>
          </div>
        </div>
      </div>
      <div className="relative h-16 w-full">
        <Button
          text="Schedule"
          onClick={() => {
            if (!isUpdateExam) {
              createExamHandle();
            } else {
              updateExamHandle();
            }
          }}
          leftIcon={icchevron}
          customStyle={styles.button}
        />
      </div>
    </div>
  );
}

Exam.propTypes = {
  data: PropTypes.object,
};
