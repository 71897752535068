import React, { memo, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';

import { FeeTermsCard, TypesOfFees } from '@stories';
import { anal, analarrow, analarrow_down, anal_down, arrow } from '@static/image';
import { formatMoney } from '@helper/format-money';
import { calcPercent } from '@utils/utils';
import { SelectGroup } from '@stories/index';

let isStart = true;

const styles = {
  p: { color: '#81878c', fontSize: '0.875rem', marginBottom: '0.313rem' },
  img: { marginBottom: '0.125rem', marginLeft: '0.313rem' },
  span: { fontSize: '0.75rem', marginBottom: '0.063rem' },
};
const listFee = [
  'Residential fee',
];

const ResidentialCardFee = memo((props) => {
  const {
    numberOfMonthsInAcademicyear = 1,
    options = [],
    classId,
    classFeeInfo,
    classOldFeeInfo,
    termsLength = 1,
    setResidentialFee = () => { },
    types,
    data,
    burger,
    refresh = () => { },
    oldTerms = [],
    listBankAccount = [],
    razorpayBankAccount = {},
    razorpayBankAccountId,
    listAcademicYearTerm = []
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [numberTerms, setNumberTerms] = useState(1);
  const [termsArray, setTermsArray] = useState([]);
  const [feeType, setFeeType] = useState(types && types[0]);
  const [feeTypeFeeValue, setFeeTypeFeeValue] = useState(0);
  const [estAnnualFee, setEstAnnualFee] = useState(0);
  const [approxTermFee, setApproxTermFee] = useState(0);
  const [listBankAccountsSelected, setListBankAccountsSelected] = useState({
    // 'Book fee': null,
    'Residential fee': null,
    // 'Admission fee': null,
    // 'Application fee': null,
  });

  useEffect(() => {
    const selectedBankAccount = listBankAccount.find(acc => acc.value === razorpayBankAccountId)
    selectedBankAccount && setListBankAccountsSelected(prev => {
      return {
        ...prev,
        "Residential fee": selectedBankAccount
      }
    })
  }, [razorpayBankAccountId]);

  useEffect(() => {
    if (!isStart) {
      if (numberTerms) {
        if (numberTerms === 0) {
          setTermsArray([]);
        } else if (numberTerms > termsArray.length) {
          setTermsArray((prev) => {
            const arr = [...prev];
            for (let i = termsArray.length; i < numberTerms; i++) {
              arr.push({
                name: `Term ${i + 1} Fee`,
                fee: 0,
                dueFee: new Date(),
              });
            }
            return arr;
          });
        } else if (numberTerms < termsArray.length) {
          setTermsArray((prev) => {
            return prev.slice(0, numberTerms);
          });
        }
      }
    } else {
      isStart = false;
    }
  }, [numberTerms]);

  useEffect(() => {
    if (!!classFeeInfo) {
      setFeeType(classFeeInfo.feeType);
      classFeeInfo.feeTypeFee?.value && setFeeTypeFeeValue(classFeeInfo.feeTypeFee.value);
      classFeeInfo.approxTermFee?.value &&
        setApproxTermFee(classFeeInfo.approxTermFee.value);
      classFeeInfo.estAnnualFee?.value &&
        setEstAnnualFee(classFeeInfo.estAnnualFee.value);
      const dataTerm = classFeeInfo.terms || [];
      if (classFeeInfo?.feeType === 'Custom') {
        const numberOfTerms = classFeeInfo.numberOfTerms || 0;
        numberOfTerms && setNumberTerms(numberOfTerms);
        setTermsArray(dataTerm);
      } else if (classFeeInfo?.feeType === 'Term Dates') {
        setNumberTerms(termsLength);
        setTermsArray(dataTerm);
      } else {
        const newDataTerm = listAcademicYearTerm?.map(academicYear => {
          return {
            fee: 0,
            name: `${academicYear.name} Fee`,
            dueFee: academicYear.startDate
          }
        }) || [];
        setTermsArray(newDataTerm);
        setNumberTerms(termsLength);
      }
    } else {
      const newDataTerm = listAcademicYearTerm?.map(academicYear => {
        return {
          fee: 0,
          name: `${academicYear.name} Fee`,
          dueFee: academicYear.startDate
        }
      }) || [];
      setTermsArray(newDataTerm);
      setNumberTerms(termsLength);
    }
  }, [classFeeInfo, refresh, listAcademicYearTerm]);

  const stringValidation = (val) => {
    return formatMoney(val);
  };

  const calcCustomFee = (termsArray = []) => {
    let sum = 0;
    termsArray.forEach((term) => {
      sum += +term.fee;
    });
    return sum;
  };

  const calcApproxTermFee = (property, value) => {
    switch (property) {
      case 'feeType':
        if (value === 'Monthly') {
          return (feeTypeFeeValue * numberOfMonthsInAcademicyear) / termsLength;
        }
        const terms = handleChangeTermsArray(termsLength, termsArray);
        return calcCustomFee(terms) / termsLength;
      case 'feeTypeFee':
        return (value * numberOfMonthsInAcademicyear) / termsLength;
      case 'terms':
        return calcCustomFee(value) / value.length;
      default:
        return approxTermFee;
    }
  };

  // let termsArray = [...Array(Number(numberTerms)).keys()];
  const handleChangeTermsArray = useCallback((length, arr = []) => {
    if (length === 0) return [];
    if (length === arr.length) return arr;
    if (length > arr.length) {
      for (let i = arr.length; i < length; i++) {
        arr.push({
          name: `Term ${i + 1} Fee`,
          fee: 0,
          dueFee: options[i]?.startDate || new Date(),
        });
      }
      return arr;
    }
    if (length < arr.length) {
      return arr.slice(0, length);
    }
  }, []);

  const handleSetResidentialFee = (property, value, isHaveValueProperty) => {
    const approxFee = calcApproxTermFee(property, value);
    const initResidentialFee = {
      classId,
      info: {
        approxTermFee: { value: approxFee },
        estAnnualFee: { value: estAnnualFee },
        feeTypeFee: { value: feeTypeFeeValue },
        feeType: feeType,
        numberOfTerms: numberTerms,
        terms: termsArray,
      },
    };
    if (property === 'feeType') {
      if (value !== 'Monthly') {
        const customFee = calcCustomFee(handleChangeTermsArray(termsLength, termsArray));
        const feeTypeFee = customFee / numberOfMonthsInAcademicyear;
        initResidentialFee.info.feeTypeFee.value = feeTypeFee;
        initResidentialFee.info.estAnnualFee.value = customFee;
      } else {
        initResidentialFee.info.estAnnualFee.value =
          feeTypeFeeValue * numberOfMonthsInAcademicyear;
      }
      setNumberTerms(termsLength);
      initResidentialFee.info.numberOfTerms = termsLength;
      initResidentialFee.info.terms = handleChangeTermsArray(termsLength, termsArray);
    }
    if (property === 'terms') {
      const estFee = calcCustomFee(value);
      initResidentialFee.info.estAnnualFee.value = estFee;
      setEstAnnualFee(estFee);
      if (value.length === termsLength) {
        const feeTypeFee = calcCustomFee(value) / numberOfMonthsInAcademicyear;
        initResidentialFee.info.feeTypeFee.value = feeTypeFee;
        setFeeTypeFeeValue(feeTypeFee);
      } else {
        initResidentialFee.info.feeTypeFee.value = NaN;
        setFeeTypeFeeValue(NaN);
      }
      initResidentialFee.info.numberOfTerms = value.length;
    }
    if (property === 'feeTypeFee') {
      setEstAnnualFee(value * numberOfMonthsInAcademicyear);
      initResidentialFee.info.estAnnualFee.value = value * numberOfMonthsInAcademicyear;
    }
    let residentialFee;
    if (isHaveValueProperty) {
      initResidentialFee.info[property] = { value: value };
      residentialFee = { ...initResidentialFee };
    } else {
      residentialFee = {
        ...initResidentialFee,
        info: { ...initResidentialFee.info, [property]: value },
      };
    }
    setResidentialFee((prev) => {
      if (!prev) {
        return [residentialFee];
      }
      const index = prev.findIndex((fee) => {
        return classId === fee.classId;
      });
      if (index === -1) return [...prev, residentialFee];
      const residentialFeeInfo = _.cloneDeep(prev);
      residentialFeeInfo[index] = { ...residentialFeeInfo[index], ...residentialFee };
      return residentialFeeInfo;
    });
  };

  return (
    <React.Fragment>
      <div
        className={clsx(
          'feebook-edit-card py-2',
          isOpen ? 'feebook-edit-card_open' : 'feebook-edit-card_closed',
        )}
      >
        <div className="feebook-edit-card_row w-full" onClick={() => setIsOpen(!isOpen)}>
          <p
            className={clsx(
              'font-normal text-base ml-8',
              burger ? 'min-w-[8rem]' : 'min-w-[9rem]',
            )}
          >
            {data.name}
          </p>
          <TypesOfFees
            title={feeType === 'Monthly' ? `Monthly Fee` : 'Approx Monthly Fee'}
            sum={
              isNaN(feeTypeFeeValue) ||
                (feeType === 'Custom' && termsLength != numberTerms)
                ? `--`
                : `${stringValidation(feeTypeFeeValue)}`
            }
          />
          <TypesOfFees
            title="Approx Term Fee"
            sum={`${stringValidation(approxTermFee)}`}
          />
          <TypesOfFees
            title="Terms"
            sum={feeType === 'Monthly' ? `${termsLength}` : `${numberTerms}`}
          />
          <TypesOfFees
            title="Est. Annual Fee"
            sum={`${stringValidation(estAnnualFee)}`}
          />
          <div className="flex items-center">
            {(!!classOldFeeInfo?.estAnnualFee?.percentageChange ||
              classOldFeeInfo?.estAnnualFee?.percentageChange == 0) && (
                <img
                  src={
                    calcPercent(
                      classOldFeeInfo.estAnnualFee.percentageChange,
                      classOldFeeInfo.estAnnualFee.value,
                      estAnnualFee,
                    ) < 0
                      ? anal_down
                      : anal
                  }
                  alt=""
                  className={clsx(
                    calcPercent(
                      classOldFeeInfo.estAnnualFee.percentageChange,
                      classOldFeeInfo.estAnnualFee.value,
                      estAnnualFee,
                    ) < 0
                      ? 'rotate-90'
                      : '',
                  )}
                />
              )}
            <span
              className={clsx(
                'ml-1',
                (!!classOldFeeInfo?.estAnnualFee?.percentageChange ||
                  classOldFeeInfo?.estAnnualFee?.percentageChange == 0) &&
                  calcPercent(
                    classOldFeeInfo.estAnnualFee.percentageChange,
                    classOldFeeInfo.estAnnualFee.value,
                    estAnnualFee,
                  ) < 0
                  ? 'text-[#ff4343]'
                  : '',
              )}
            >
              {!!classOldFeeInfo?.estAnnualFee?.percentageChange ||
                classOldFeeInfo?.estAnnualFee?.percentageChange == 0
                ? '' +
                calcPercent(
                  classOldFeeInfo.estAnnualFee.percentageChange,
                  classOldFeeInfo.estAnnualFee.value,
                  estAnnualFee,
                ) +
                '%'
                : '--'}
            </span>
          </div>
          <button
            className={clsx('feebook-edit-card_row_button', burger ? 'mr-4' : 'mr-8')}
          >
            <img src={arrow} alt="" />
          </button>
        </div>
        {isOpen && (
          <React.Fragment>
            <div className="feebook-edit-card_content w-[95%]">
              <h3 className=" font-normal text-sm">Fee Type</h3>
              <div className="feebook-edit-card_content_buttons flex">
                {types.map((type) => (
                  <button
                    key={type}
                    onClick={() => {
                      setFeeType(type);
                      handleSetResidentialFee('feeType', type);
                    }}
                    className={clsx('tab-button', type === feeType && 'tab-button_active')}
                  >
                    {type}
                  </button>
                ))}
              </div>
              <h3 className=" font-normal text-sm">Fee Distribution</h3>
              <div className="flex ml-1.5 mb-3">
                <div className="flex flex-col w-40">
                  <p style={styles.p}>
                    {feeType === 'Custom' || feeType === 'Term Dates'
                      ? 'Number of Terms'
                      : `${feeType} Fee`}
                  </p>
                  <p className="flex items-center">
                    {feeType === 'Custom' || feeType === 'Term Dates' ? (
                      <input
                        disabled={feeType === 'Term Dates'}
                        value={numberTerms || undefined}
                        type="number"
                        className="w-14 border-none outline-none"
                        onChange={(e) => {
                          setNumberTerms(+e.target.value);
                          if (+e.target.value > 0) {
                            const terms = handleChangeTermsArray(
                              +e.target.value,
                              termsArray,
                            );
                            handleSetResidentialFee('terms', terms);
                          }
                        }}
                      />
                    ) : (
                      <React.Fragment>
                        ₹{' '}
                        <input
                          value={feeTypeFeeValue || undefined}
                          type="number"
                          className="ml-1 border-none outline-none"
                          style={{ maxWidth: '3.5rem' }}
                          onChange={(e) => {
                            setFeeTypeFeeValue(+e.target.value);
                            handleSetResidentialFee('feeTypeFee', +e.target.value, true);
                          }}
                        />{' '}
                        {(!!classOldFeeInfo?.feeTypeFee?.percentageChange ||
                          classOldFeeInfo?.feeTypeFee?.percentageChange == 0) &&
                          !isNaN(
                            calcPercent(
                              classOldFeeInfo.feeTypeFee.percentageChange,
                              classOldFeeInfo.feeTypeFee.value,
                              feeTypeFeeValue,
                            ),
                          ) && (
                            <img
                              src={
                                calcPercent(
                                  classOldFeeInfo.feeTypeFee.percentageChange,
                                  classOldFeeInfo.feeTypeFee.value,
                                  feeTypeFeeValue,
                                ) < 0
                                  ? analarrow_down
                                  : analarrow
                              }
                              style={styles.img}
                              alt=""
                              className={clsx(
                                (!!classOldFeeInfo?.feeTypeFee?.percentageChange ||
                                  classOldFeeInfo?.feeTypeFee?.percentageChange == 0) &&
                                  calcPercent(
                                    classOldFeeInfo.feeTypeFee.percentageChange,
                                    classOldFeeInfo.feeTypeFee.value,
                                    feeTypeFeeValue,
                                  ) < 0
                                  ? 'rotate-180'
                                  : '',
                              )}
                            />
                          )}
                        <span
                          style={styles.span}
                          className={clsx(
                            (!!classOldFeeInfo?.feeTypeFee?.percentageChange ||
                              classOldFeeInfo?.feeTypeFee?.percentageChange == 0) &&
                              calcPercent(
                                classOldFeeInfo.feeTypeFee.percentageChange,
                                classOldFeeInfo.feeTypeFee.value,
                                feeTypeFeeValue,
                              ) < 0
                              ? 'text-[#ff4343]'
                              : 'text-[#2ED775]',
                          )}
                        >
                          {(!!classOldFeeInfo?.feeTypeFee?.percentageChange ||
                            classOldFeeInfo?.feeTypeFee?.percentageChange == 0) &&
                            !isNaN(
                              calcPercent(
                                classOldFeeInfo.feeTypeFee.percentageChange,
                                classOldFeeInfo.feeTypeFee.value,
                                feeTypeFeeValue,
                              ),
                            )
                            ? '' +
                            calcPercent(
                              classOldFeeInfo.feeTypeFee.percentageChange,
                              classOldFeeInfo.feeTypeFee.value,
                              feeTypeFeeValue,
                            ) +
                            '%'
                            : '--'}
                        </span>
                      </React.Fragment>
                    )}
                  </p>
                </div>
              </div>
              {(feeType === 'Custom' || feeType === 'Term Dates') &&
                termsArray.map((item, key) => (
                  <FeeTermsCard
                    handleSetFee={handleSetResidentialFee}
                    isSetTime={feeType === 'Term Dates' ? false : true}
                    setTermsArray={setTermsArray}
                    termsArray={termsArray}
                    item={item}
                    key={key}
                    index={key}
                    maxValue={termsArray.length}
                    oldFee={key >= oldTerms.length ? {} : oldTerms[key]}
                  />
                ))}
            </div>
            {razorpayBankAccount.type === 'Different' && (
              <div className="px-8 flex flex-col gap-2.5 my-5">
                <p className="text-[#81878C] text-sm font-semibold">Bank Account</p>
                {listFee.map((fee) => (
                  <div key={fee} className="flex gap-5 items-center justify-start">
                    <p className="min-w-[120px] text-[#919EAB] font-normal text-sm">
                      {fee}
                    </p>
                    <SelectGroup
                      value={listBankAccountsSelected[fee]}
                      options={listBankAccount}
                      onChange={(e) => {
                        setListBankAccountsSelected(prev => ({ ...prev, [fee]: e }));
                        setResidentialFee(prev => {
                          const index = prev.findIndex(f => f.classId === classId)
                          if (index === -1) return prev
                          const resFee = _.cloneDeep(prev)
                          resFee[index].razorpayBankAccountId = e.value
                          return resFee
                        })
                      }}
                      classNameGroup="w-[20rem] mx-3"
                    />
                  </div>
                ))}
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
});

export default ResidentialCardFee;

ResidentialCardFee.displayName = 'ResidentialCardFee';
