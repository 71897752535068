import { memo } from 'react';
import Add from '../../add/add';
import ContentGroup from '@components/admission-module/application/information/subcomponents/content-group/content-group';
import GgMap from '@components/admission-module/application/information/subcomponents/gg-map/gg-map';

const AddressInfo = memo((props) => {
  const { info, error, listField, handleChange, handleAdd } = props;

  return (
    <>
      {info.map((address, index) => (
        <SingleAddress
          key={`address${index}`}
          idPhoto={`address${index}`}
          info={address}
          error={error}
          order={index}
          listField={listField}
          handleChange={handleChange}
        />
      ))}
      <div className="flex items-center justify-center">
        <button
          onClick={() => {
            handleAdd({ name: 'addressInfo' });
          }}
          className="mb-10 none-after flex items-center font-semibold text-sm"
          style={{ color: '#ababcd' }}
        >
          <Add />
          Add Additional Address
        </button>
      </div>
    </>
  );
});
AddressInfo.displayName = 'AddressInfo';
export default AddressInfo;

const SingleAddress = memo((props) => {
  const { info, listField, error, order, handleChange } = props;

  return (
    <>
      <div className="mb-4 h-fit">
        <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
          Location
        </p>
        <GgMap
          info={info}
          customStyle={{ width: '100%', marginRight: '0px', marginLeft: '0px' }}
          libraries={process.env.REACT_APP_MAPS_LIBRARIES.split(',')}
          handleChange={({ objValue, key, value }) => {
            handleChange({ name: 'addressInfo', order, objValue, key, value });
          }}
        />
      </div>
      {listField.map((item, key) => {
        return (
          <ContentGroup
            key={`${order}${key}`}
            data={item}
            info={info?.[item.name]}
            error={error?.[item.name]}
            handleChange={(val) => {
              handleChange({ name: 'addressInfo', order, key: item.name, value: val });
            }}
          />
        );
      })}
    </>
  );
});
SingleAddress.displayName = 'SingleAddress';
