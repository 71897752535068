import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import Footer from '@components/footer/footer';
import { NavLinks } from '@pages/Header/components';
import { icsettings, icusergroup } from '@pages/Login/components/image';
import { icuser } from '@static/image';
import { Button } from '@stories/index';
import { ToastMessageContext } from 'context/toast-context';
import { dataLinks } from 'entities/routes';
import { RolesPermissions } from '..';
import PopupRole from './popup-role';
import authApi from 'api/auth';
import useStorage from 'store/storage';
import { callApi } from '@helper/call-api';
import './role-permission.scss';
import approvalApi from 'api/approval';

const updateRoleActionId = 2;

export default function RoleAndPermissionsCentral({ burger }) {
  const [showPopupCreate, setShowPopupCreate] = useState(false);
  const [loginTypes, setLoginTypes] = useState([]);
  const [edited, setEdited] = useState(false);
  const [permissionOrigin, setPermissionOrigin] = useState('');
  const [permissionOriginSave, setPermissionOriginSave] = useState('[]');
  const [listRole, setListRole] = useState([]);
  const [listModule, setListModule] = useState([]);
  const [listPermission, setListPermission] = useState([]);
  const [openPage, setOpenPage] = useState([]);
  const [openPermission, setOpenPermission] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isUpdateRole, setIsUpdateRole] = useState(false);
  const [isCreateRole, setIsCreateRole] = useState(false);
  const [isHaveProcessCreateRole, setIsHaveProcessCreateRole] = useState(false);
  const [isHaveProcessUpdateRole, setIsHaveProcessUpdateRole] = useState(false);
  const [changes, setChanges] = useState({});
  const [numberOfEdits, setNumberOfEdits] = useState(0);
  const [draftsList, setDraftsList] = useState([]);
  const [isRenderDraft, setIsRenderDraft] = useState(false);
  const [draftId, setDraftId] = useState();
  const [dataChange, setDataChange] = useState({});
  const [dataRoleDraft, setDataRoleDraft] = useState([]);
  // role's detail when you click on its crumbs:
  const [roleDetail, setRoleDetail] = useState(null);

  const isAdmin = useStorage((state) => state.isGiikiAdmin);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const userLogin = useStorage((state) => state.auth);

  const permissionInit = {
    create: 'create',
    edit: 'edit',
    read: 'read',
  };

  const listLoginType = useMemo(
    () => [
      {
        id: 1,
        title: 'Access Level',
        subtitle: 'Low',
        image: icuser,
        opinio: 1,
      },
      {
        id: 2,
        title: 'Access Level',
        subtitle: 'Medium',
        image: icusergroup,
        opinio: 2,
      },
      {
        id: 3,
        title: 'Access Level',
        subtitle: 'Medium +',
        image: icsettings,
        opinio: 3,
      },
      {
        id: 4,
        title: 'Access Level',
        subtitle: 'High',
        image: icsettings,
        opinio: 4,
      },
    ],
    [],
  );

  const isShowTextApproval = useMemo(() => {
    if (isCreateRole) {
      return isCreateRole && isHaveProcessCreateRole;
    }
    if (isUpdateRole) {
      return isUpdateRole && isHaveProcessUpdateRole;
    }
  }, [isCreateRole, isUpdateRole, isHaveProcessCreateRole, isHaveProcessUpdateRole]);

  // this function to get 2 lists
  // 1 is list of Modules, each module will have list pages under that module
  // 2 is list of Roles
  const getRoleNameAndModule = (dataApi) => {
    const listModule = [];
    const listRoleName = [];
    dataApi.forEach((data) => {
      const { module, pages } = data;
      const pageModule = [];

      pages.forEach((page) => {
        const { name, roles } = page;
        if (!pageModule.includes(name)) pageModule.push(name);

        roles.forEach((role) => {
          const { name, loginTypeId, id, isDefault } = role;
          const tmp = { name, loginTypeId, id, isDefault };
          if (!listRoleName.some((item) => item.name === tmp.name))
            listRoleName.push(tmp);
        });
      });
      listModule.push({ module, pageModule });
    });
    return { modules: listModule, roleNames: listRoleName };
  };

  const newGetDataPermission = (dataApi, listRole, dataDraft) => {
    const objResult = {};
    listRole.forEach(({ name }) => (objResult[name] = []));
    if (!dataDraft?.length) {
      dataApi.forEach((data) => {
        const { pages, module } = data;
        pages.forEach(({ roles, id: pageId, name: pageName }) => {
          const roleHasPer = [];
          roles.forEach((role) => {
            const { name: roleName, permissions, id: roleId } = role;
            if (objResult[roleName]) {
              roleHasPer.push(roleName);
              const tmp = {};
              ['read', 'edit', 'create'].forEach((permission) => {
                ['read', 'edit', 'create'].forEach((per) => {
                  tmp[per] = {
                    pageId,
                    name: per,
                    permissionId: per,
                    active: permissions.includes(permissionInit[per]),
                    uidPermission: per,
                    data: {
                      module,
                      page: {
                        pageId,
                        pageName,
                      },
                      role: {
                        roleId,
                        roleName,
                      },
                      permission: {
                        permissionId: permission,
                        permissionName: permission,
                      },
                      rolePagePermission: {
                        uidPermission: permission,
                        oldActive: permissions.includes(per),
                      },
                    },
                  };
                });
              });
              objResult[roleName].push(tmp);
            }
          });
          if (roleHasPer.length < listRole.length) {
            listRole.forEach(({ name }) => {
              if (!roleHasPer.includes(name)) objResult[name].push({});
            });
          }
        });
      });
    } else {
      dataApi.forEach((data) => {
        const { pages, module } = data;
        pages.forEach(({ roles, id: pageId, name: pageName }) => {
          const roleHasPer = [];
          roles.forEach((role) => {
            const { name: roleName, permissions, id: roleId } = role;
            if (objResult[roleName]) {
              roleHasPer.push(roleName);
              const tmp = {};
              ['read', 'edit', 'create'].forEach((permission) => {
                const data = {
                  module,
                  page: {
                    pageId,
                    pageName,
                  },
                  role: {
                    roleId,
                    roleName,
                  },
                  permission: {
                    permissionId: permission,
                    permissionName: permission,
                  },
                  rolePagePermission: {
                    uidPermission: permission,
                    oldActive: permissions.includes(permission),
                  },
                };
                ['read', 'edit', 'create'].forEach((per) => {
                  tmp[per] = {
                    pageId,
                    name: per,
                    permissionId: per,
                    active: permissions.includes(permissionInit[per]),
                    uidPermission: per,
                    data,
                  };
                });
              });
              objResult[roleName].push(tmp);
            }
          });
          if (roleHasPer.length < listRole.length) {
            listRole.forEach(({ name }) => {
              if (!roleHasPer.includes(name)) objResult[name].push({});
            });
          }
        });
      });

      dataDraft.forEach((change) => {
        const {
          data: {
            module,
            role: { roleId, roleName },
          },
          pageId,
          name,
          active,
        } = change;
        const roleChange = objResult[roleName];
        const pageChange = roleChange.find((item) => item.create.pageId === pageId);
        pageChange[name].active = active;
      });
    }
    const result = [];
    listRole.forEach(({ name }) => result.push(...objResult[name]));
    return result;
  };

  const getDraftsList = async () => {
    await authApi
      .getListRoleDraft({ comapanyId: userLogin.comapanyId })
      .then((res) => {
        if (res.data.success) {
          const drafts = res.data.data.objects;
          setDraftsList(drafts);
          setNumberOfEdits(res.data.data.total);
        }
      })
      .catch((error) => {
        console.log('Get list draft fail', error);
      });
  };

  useEffect(() => {
    getDraftsList();
  }, []);

  const sendDraft = async (draft) => {
    await authApi
      .createRoleDraft({ companyId: userLogin?.companyId }, draft)
      .then((res) => {
        if (res.data.success) {
          setToastMessage({
            status: 'success',
            title: 'Save as Draft Successfully',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Save as Draft Failed',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .finally(() => {
        setRefresh((prev) => !prev);
      });
  };

  const handleOnChange = (roleName, index, key, isActive) => {
    setDataChange((prev) => {
      const data = { ...prev };
      data[index] = roleName;
      return data;
    });
    const keyword = `${roleName}-${index}-${key}`;
    const value = {
      roleName,
      index,
      key,
    };
    const dictChanges = cloneDeep(changes);
    if (dictChanges[keyword]) {
      delete dictChanges[keyword];
    } else {
      dictChanges[keyword] = value;
    }
    setChanges(dictChanges);

    setListPermission((prev) => {
      const tmp = [...prev];
      const active = tmp[index][key].active;
      tmp[index][key].active = !active;
      return tmp;
    });
    setIsUpdateRole(true);
  };

  // handle the the arrow on left block to collapse or expand module
  // to show or hide pages under that module
  const handleClickArrow = (module) => {
    setOpenPage((prev) => ({
      ...prev,
      [module]: !prev[module],
    }));
    setOpenPermission((prev) => ({
      ...prev,
      [module]: !prev[module],
    }));
  };

  const handleClickCreate = () => {
    setShowPopupCreate((prev) => !prev);
  };

  const handleSaveAsDraft = async () => {
    if (!Object.keys(changes).length) return;

    const infoDraftChange = [];
    const dataDraftChange = [];

    const now = new Date();
    const timeString = now.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    Object.keys(changes).forEach((keyWord) => {
      const { index, key } = changes[keyWord];

      const objChange = {
        [key]: { ...listPermission[index][key] },
        [keyWord]: { ...changes[keyWord] },
      };

      const { role, page, module } = objChange[key].data;
      const content = objChange[key].active ? 'Enable' : 'Disable';
      const info = {
        title: `Edit role: ${role.roleName}`,
        subtitle: `${content} ${key} permission of page: ${page.pageName} (Module: ${module})`,
        time: timeString,
        type: 'Edit',
      };

      dataDraftChange.push(objChange[key]);
      infoDraftChange.push(info);
    });

    const info = {
      changes: [...infoDraftChange],
      name: 'Change permission',
    };
    info['companyId'] = userLogin?.companyId;
    await sendDraft({ info, data: dataDraftChange });
    await getDraftsList();
  };

  const handleClickCancel = () => {
    setListPermission(JSON.parse(permissionOriginSave));
    setChanges({});
    setDataChange({});
    setIsRenderDraft(false);
  };

  const handelSendForApproval = async () => {
    const dataCallApi = [];
    if (Object.keys(dataChange)?.length) {
      Object.keys(dataChange).forEach((key) => {
        const data = listPermission[key];
        if (
          data?.create?.active !== data?.create?.data?.rolePagePermission?.oldActive ||
          data?.read?.active !== data?.read?.data?.rolePagePermission?.oldActive ||
          data?.edit?.active !== data?.edit?.data?.rolePagePermission?.oldActive
        ) {
          const permission = [];
          if (data?.create?.active) permission.push('create');
          if (data?.read?.active) permission.push('read');
          if (data?.edit?.active) permission.push('edit');
          dataCallApi.push({
            pageId: data?.create?.pageId || data?.read?.pageId || data?.edit?.pageId,
            roleId:
              data?.create?.data?.role?.roleId ||
              data?.read?.data?.role?.roleId ||
              data?.edit?.data?.role?.roleId,
            permission: permission.join(';'),
          });
        }
      });
    } else {
      const defaultListRole = JSON.parse(permissionOriginSave);
      const dataDraftChange =
        defaultListRole?.filter((dfRole) => {
          return Object.keys(dfRole ?? '{}')?.some((key) => {
            return dataRoleDraft?.some((drd) => {
              return (
                drd?.pageId === dfRole?.[key]?.pageId &&
                drd?.permissionId === dfRole?.[key]?.permissionId &&
                drd?.data?.role?.roleId === dfRole?.[key]?.data?.role?.roleId
              );
            });
          });
        }) ?? [];
      if (!dataDraftChange?.length) return;
      const flatDataDraftChange = [];
      dataDraftChange.forEach((dt) => {
        Object.values(dt)?.forEach((val) => {
          const newData = dataRoleDraft?.find(
            (dtDraft) =>
              dtDraft?.pageId === val?.pageId &&
              dtDraft?.permissionId === val?.permissionId &&
              dtDraft?.data?.role?.roleId === val?.data?.role?.roleId,
          );
          newData ? flatDataDraftChange.push(newData) : flatDataDraftChange.push(val);
        });
      });
      flatDataDraftChange.forEach((flatData) => {
        if (flatData?.active) {
          const index = dataCallApi.findIndex(
            (dtCall) =>
              dtCall?.pageId === flatData?.pageId &&
              dtCall?.roleId === flatData?.data?.role?.roleId,
          );
          if (index !== -1) {
            dataCallApi[index] = {
              ...(dataCallApi[index] ?? {}),
              permission: [
                ...(dataCallApi[index]?.permission ?? []),
                flatData?.permissionId,
              ],
            };
          } else {
            dataCallApi.push({
              pageId: flatData?.pageId,
              roleId: flatData?.data?.role?.roleId,
              permission: [flatData?.permissionId],
            });
          }
        }
      });
      dataCallApi.forEach((data) => {
        data.permission = data.permission?.join(';');
      });
    }
    if (!dataCallApi.length) {
      return;
    }

    if (isAdmin) {
      authApi
        .updateRole({ data: dataCallApi })
        .then((res) => {
          if (res.data.success) {
            setToastMessage({
              status: 'success',
              title: 'Update Successfully',
              message: 'Update role Successfully',
            });
            setIsShowToastMessage(true);
          } else {
            setToastMessage({
              status: 'error',
              title: 'Submit Failed',
              // message: error.response?.data?.message || error,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Submit Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Campus Information Role Permissions Error', error);
        })
        .finally(() => {
          setRefresh((prev) => !prev);
          setIsRenderDraft(false);
          setDataChange({});
        });
    } else {
      const resProcess = await approvalApi.getApprovalProcess({
        actionId: updateRoleActionId,
      });
      const isExistsProcess = !!resProcess.data?.data?.id;
      if (!isExistsProcess) {
        setToastMessage({
          status: 'warning',
          title: 'Send for Approvals',
          message:
            "Don't have process to Edit roles. Please contact admin to create Process to Edit Roles",
        });
        setIsShowToastMessage(true);
        return;
      }
      callApi({
        method: 'post',
        url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/request`,
        // params: { campusId: isCentral ? undefined : campus?.id },
        data: {
          name: `Update roles`,
          actionId: updateRoleActionId, // action id update
          changeContent: {
            actionName: 'Update role',
            apiURL: process.env.REACT_APP_URL_API_AUTH,
            endpoint: '/api/v2/role',
            apiMethod: 'PATCH',
            content: {
              data: dataCallApi,
            },
            // params: { campusId: isCentral ? undefined : campus?.id },
          },
          keyFields: [],
        },
      })
        .then((res) => {
          if (res.data.success) {
            setToastMessage({
              status: 'success',
              title: 'Send for Approvals Successfully',
              message: 'Update role Successfully',
            });
            setIsShowToastMessage(true);
          } else {
            setToastMessage({
              status: 'error 2',
              title: 'Submit Failed 2',
              // message: error.response?.data?.message || error,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error 3',
            title: 'Submit Failed 3',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Campus Information Role Permissions Error', error);
        })
        .finally(() => {
          setRefresh((prev) => !prev);
          setIsRenderDraft(false);
          setDataChange({});
        });
    }
  };

  useEffect(() => {
    const getRolePagePermission = authApi.getPageWithRolePermission();
    const listAPI = [getRolePagePermission];
    if (isRenderDraft) {
      const getDraftRolePagePermission = authApi.getDraftRolePagePermission(
        { companyId: userLogin?.companyId },
        draftId,
      );
      listAPI.push(getDraftRolePagePermission);
    }

    Promise.all(listAPI)
      .then((res) => {
        const { data } = res[0];
        if (!data.success) {
          setToastMessage({
            status: 'error',
            title: 'Get Pages Failed',
            message: data.message,
          });
          setIsShowToastMessage(true);
        } else {
          let dataDraft = [];

          if (isRenderDraft) {
            dataDraft = JSON.parse(res[1].data.data.data);
            setDataRoleDraft(dataDraft);
          }

          const dataApi = data.data;
          const { modules, roleNames } = getRoleNameAndModule(dataApi);
          // const dataPermission = getDataPermission(dataApi, roleNames, dataDraft);
          const dataPermission = newGetDataPermission(dataApi, roleNames, dataDraft);
          const openModule = {};
          modules.forEach(({ module }) => {
            openModule[module] = true;
          });
          setListModule(modules);
          setListRole(roleNames);
          setListPermission(dataPermission);
          setPermissionOrigin(JSON.stringify(dataPermission));
          !isRenderDraft && setPermissionOriginSave(JSON.stringify(dataPermission));
          setOpenPage(openModule);
          setOpenPermission(openModule);
          setLoginTypes(listLoginType);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Get Pages Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Campus Information Role Permissions Error', error);
      });
  }, [refresh, isRenderDraft, draftId]);

  useEffect(() => {
    if (listPermission.length !== 0) {
      setEdited(permissionOrigin !== JSON.stringify(listPermission));
      setIsCreateRole(true);
    }
  }, [listPermission]);

  // handle when you click a role's crumbs:
  const openPopupRoleDetail = (role) => {
    setShowPopupCreate((pre) => !pre);
    setRoleDetail(role);
  };

  return (
    <>
      <div
        className={clsx(
          'campus-information pr-4 pl-10 transition-all-300 pb-12',
          burger ? '1400px:pl-[17.5rem]' : '1400px:pl-[7.5rem]',
        )}
      >
        <NavLinks urls={dataLinks.rolePermissionLinks} />
        <div className="flex items-center justify-between">
          <h1 className="namepage">
            Roles and Permissions {isRenderDraft ? ' (Draft)' : ''}
          </h1>
          <Button
            text="Create a Role"
            onClick={() => {
              setShowPopupCreate((prev) => !prev);
              setRoleDetail(null);
            }}
            className="relative bottom-2 text-sm"
          />
        </div>
        {/* <div
          className={clsx(
            visiblePanel ? 'pointer-events-none' : 'pointer-events-auto w-full',
          )}
        >
          <EditCreateRole
            visible={visiblePanel}
            setVisible={setVisiblePanel}
            campus={{}}
          />
        </div> */}

        <div className="flex items-center w-full mb-12"></div>
        <RolesPermissions
          openPopupRoleDetail={openPopupRoleDetail}
          burger={burger}
          isApproval={false}
          listModule={listModule}
          listRole={listRole}
          listPermission={listPermission}
          openPage={openPage}
          openPermission={openPermission}
          handleOnChange={handleOnChange}
          handleClickArrow={handleClickArrow}
        />
      </div>
      {(numberOfEdits > 0 || edited) && (
        <Footer
          isStorybook={true}
          burger={burger}
          isApproval={!isAdmin}
          numberOfEdits={numberOfEdits}
          draftsList={draftsList}
          handleSaveAsDraft={handleSaveAsDraft}
          handleClickCancel={handleClickCancel}
          handelSendForApproval={handelSendForApproval}
          setDraftId={setDraftId}
          setIsRenderDraft={setIsRenderDraft}
        />
      )}
      {showPopupCreate && (
        <PopupRole
          roleDetail={roleDetail}
          showPopupCreate={showPopupCreate}
          isCreate
          isAdmin={isAdmin}
          handleClickClose={handleClickCreate}
          loginTypes={loginTypes}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
}
