import { callApi } from '@helper/call-api';

class NotificationApi {
  constructor() {
    this.baseUrl = `${process.env.REACT_APP_URL_API_NOTIFICATION}/api/v2/notification`;
  }
  createTaskAndReminder = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${this.baseUrl}/task-and-reminder`,
      params,
      data,
    });
  };

  getTaskAndReminderList = async (params) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/task-and-reminder/list`,
      params,
    });
  };

  getWhatsappTemplate = async (params) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/whatsapp-template`,
      params,
    });
  };

  updateWhatsappCredential = async (params, data) => {
    return callApi({
      method: 'patch',
      url: `${this.baseUrl}/whatsapp-credentials`,
      params,
      data,
    });
  };

  getListNotification = async (params) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/list`,
      params,
    });
  };

  getNotificationById = async (notificationId) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/${notificationId}`,
    });
  };

  updateNotifications = async (data) => {
    return callApi({
      method: 'patch',
      url: this.baseUrl,
      data,
    });
  };

  subscribeTopicNotification = async (data) => {
    return callApi({
      method: 'post',
      url: `${this.baseUrl}/subscribeTopic`,
      data,
    });
  };

  unsubscribeTopicNotification = async (data) => {
    return callApi({
      method: 'post',
      url: `${this.baseUrl}/unSubscribeTopic`,
      data,
    });
  };

  notificationSetting = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${this.baseUrl}/setting`,
      params,
      data,
    });
  };

  getListNotificationSetting = async (params) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/setting/list`,
      params,
    });
  };
}
const notificationApi = new NotificationApi();
export default notificationApi;
