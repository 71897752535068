import React from 'react';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import { dataTabs, dataLinks } from '../../../entities/routes';
import { NavTabs, NavLinks } from '../../Header/components/index';
import { plus } from '../../../static/image';
import { Button } from '../../../stories';
import ProgramTable from './program-table';
import CreateClassPanel from '../components/create-class/create-class';
import './programmes.scss';

export default function Programmes({ burger }) {
  return (
    <div className="programmes-terms w-full px-8">
      <Fade clear duration={300}>
        <NavLinks urls={dataLinks.programLinks} />
        <h1 className="namepage">Academic Years</h1>
        <NavTabs myClass="pb-0" tabs={dataTabs.programTabs} />
      </Fade>
      <div className="academ-terms-header mr-10">
        <Button
          text="Add Program"
          onClick={() => {
          }}
          icon={plus}
        />
      </div>
      <ProgramTable burger={burger} />
      <CreateClassPanel visible={true} />
    </div>
  );
}

Programmes.propTypes = {
  burger: PropTypes.bool,
};
