import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { Fade } from 'react-reveal';
import { useHistory, useLocation } from 'react-router-dom';
import { Input } from '@components/index';
import { NavLinks } from '@pages/Header/components';
import { private_img, public_img, staticsize } from '@static/image';
import { Button, Checkbox, SelectGroup, Upload } from '@stories/index';
import WorldCard from '@stories/worldcard/worldcard';
import { ToastMessageContext } from 'context/toast-context';
import { dataLinks } from 'entities/routes';
import { selectStylesWithTitle } from 'entities/select-style';
import './team-board-setting.scss';
import taskApi from 'api/task';
import useStorage from 'store/storage';

const VISIBILITY = {
  PUBLIC: 'Public',
  PRIVATE: 'Private',
};

const APPROVERS = [
  {
    value: 'Board Manager',
    label: 'Board Manager',
  },
  {
    value: 'Board Member',
    label: 'Board Member',
  },
];

const styles = {
  btnBlue: {
    width: '13.125rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    backgroundColor: '#404eed',
    height: '2.563rem',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  btnWhite: {
    width: '13.125rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#404eed',
    backgroundColor: 'white',
    height: '2.563rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    border: '1px solid #404eed',
    marginLeft: '3.5rem',
  },
};

export default function TeamBoardSetting({ burger }) {
  const [teamData, setTeamData] = useState({});
  const [avatar, setAvatar] = useState('');
  const [listRoleSelected, setListRoleSelected] = useState([]);
  const [isLogo, setIsLogo] = useState(false);
  const location = useLocation();
  const [formData, setFormData] = useState(new FormData());
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [optionPastDeadline, setOptionPastDeadline] = useState(false);

  const history = useHistory();
  const campus = useStorage((state) => state.currentCampus);
  // const campus = getCurrentCampus();

  useEffect(() => {
    if (location.state) {
      location.state.selectedTeam && setTeamData(location.state.selectedTeam);
      location.state.selectedTeam?.logoURL &&
        setAvatar(location.state.selectedTeam.logoURL);
      if (
        location.state.selectedTeam &&
        Array.isArray(location.state.selectedTeam.escalatedToRole) &&
        location.state.selectedTeam.escalatedToRole.length > 0
      ) {
        setListRoleSelected((prevState) => {
          const data = location.state.selectedTeam.escalatedToRole.map((role) => {
            return { label: role, value: role };
          });
          return [...prevState, ...data];
        });
        setOptionPastDeadline(location.state.selectedTeam.escalatedToRole.length > 0);
      }
    }
  }, []);

  const handlerOnClickCancel = () => {
    setAvatar('');
    setTeamData(location.state.selectedTeam);
    setOptionPastDeadline(location.state.selectedTeam.escalatedToRole.length > 0);
    setListRoleSelected(
      location.state.selectedTeam.escalatedToRole.map((role) => {
        return { label: role, value: role };
      }),
    );
    setIsLogo(false);
    setFormData(new FormData());
    history.push('/tasks', { teamId: teamData.id });
  };

  const handleOnClickSave = async () => {
    let logo = teamData.logoURL;

    if (isLogo) {
      try {
        const resultUploadImage = await taskApi.uploadMedia(formData);
        if (resultUploadImage.data.success) {
          logo = resultUploadImage.data.data;
        } else {
          setToastMessage({
            status: 'error',
            title: 'Upload Logo Failed',
            message: resultUploadImage.data.message,
          });
          setIsShowToastMessage(true);
        }
      } catch (error) {
        setToastMessage({
          status: 'error',
          title: 'Upload Logo Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Upload Logo Error', error);
      }
    }

    const data = {
      ...(({ managers, members, dateUpdated, dateCreated, id, ...res }) => res)(teamData),
      logoURL: logo,
      escalatedToRole: !optionPastDeadline
        ? []
        : listRoleSelected.map((data) => data.value),
    };
    taskApi.updateTeamById({ campusId: campus?.isCentral ? undefined : campus?.id }, data, teamData.id)
      .then((results) => {
        if (results.data.success) {
          setToastMessage({
            status: 'success',
            title: 'Edit Team/Board Successfully',
            message: results.data.message,
          });
          setIsShowToastMessage(true);
          history.push('/tasks', { teamId: results?.data?.data?.id });
        } else {
          setToastMessage({
            status: 'error',
            title: 'Edit Team/Board Failed',
            message: results.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      // .then((timeOut) => {
      //   clearTimeout(timeOut);
      // })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Edit Team/Board Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Edit Team/Board Error', error);
      });
  };

  return (
    // <div className={clsx('team-board-setting pr-24', burger ? 'pl-69' : 'pl-20')}>
    <div className="team-board-setting px-8 w-full">
      <div className="min-w-[50%]">
        <Fade clear duration={300}>
          <NavLinks urls={dataLinks.taskLinks} />
          <h1 className="namepage font-black">Team/Board Settings</h1>
          <div className="team-board-setting-form">
            <div className="team-board-setting-form_section team-board-setting-section">
              <div className="team-board-setting-section_header">
                <h2 className="team-board-setting-section_title">1. Team/Board Photo</h2>
                <p className="team-board-setting-section_text mt-2">
                  Upload an image to make it easily recognizable.
                </p>
              </div>
              <div className="team-board-setting-upload">
                <img
                  className="team-board-setting-upload_image"
                  src={avatar ? `${avatar}` : staticsize}
                  alt="uploaded img"
                />
                <Upload
                  text="Upload Image"
                  id="image-upload"
                  accept="image/*"
                  // imageChange={setImgPreview}
                  onChange={(e) => {
                    let selected = e.target.files;
                    if (selected && selected[0]) {
                      let file = selected[0];
                      setIsLogo(true);
                      formData.set('file', file);
                      formData.set('mediaId', file.name);
                      setFormData(formData);
                      let reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = () => {
                        setAvatar(reader.result);
                      };
                      reader.onerror = function (error) {
                        console.log('Error: ', error);
                      };
                    }
                  }}
                />
              </div>
            </div>
            <div className="team-board-setting-form_section team-board-setting-section">
              <div className="team-board-setting-section_header">
                <h2 className="team-board-setting-section_title">
                  2. Team/Board Details
                </h2>
                <div className="flex items-center mt-3">
                  <span className="team-board-setting-section_text">
                    Enter Team details below.
                  </span>
                </div>
              </div>
              <div className="team-board-setting-section_content">
                <Input
                  onChange={(e) => {
                    setTeamData((prevState) => {
                      return { ...prevState, name: e.target.value };
                    });
                  }}
                  label="Team Name"
                  name="team-name"
                  value={teamData?.name || ''}
                  customStyle={{
                    width: '30.25rem',
                    height: '2.5rem',
                    fontSize: '0.875rem',
                    borderRadius: '0.25rem',
                    paddingLeft: '1rem',
                  }}
                //   error={error.statusEmail}
                />
                <div className="mr-[1.5%]">
                  <p className="mb-4 create-board-text">Visibility</p>
                  <div className="my-2 flex">
                    <div
                      onClick={() => {
                        setTeamData((prevState) => {
                          return {
                            ...prevState,
                            visibility: VISIBILITY.PUBLIC,
                          };
                        });
                      }}
                      className="mr-6"
                    >
                      <WorldCard
                        img={public_img}
                        label="Public"
                        size={'small'}
                        isFocus={teamData?.visibility === VISIBILITY.PUBLIC}
                        customStyleDiv={{ width: '5rem', height: '5rem' }}
                        customStyleImg={{ width: '2rem', height: '2rem' }}
                        customStyleLabel={{ fontSize: '0.75rem' }}
                      />
                    </div>
                    <div
                      onClick={() => {
                        setTeamData((prevState) => {
                          return {
                            ...prevState,
                            visibility: VISIBILITY.PRIVATE,
                          };
                        });
                      }}
                    >
                      <WorldCard
                        img={private_img}
                        label="Private"
                        size={'small'}
                        isFocus={teamData?.visibility === VISIBILITY.PRIVATE}
                        customStyleDiv={{ width: '5rem', height: '5rem' }}
                        customStyleImg={{ width: '2rem', height: '2rem' }}
                        customStyleLabel={{ fontSize: '0.75rem' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="team-board-setting-form_section team-board-setting-section">
              <div className="team-board-setting-section_header">
                <h2 className="team-board-setting-section_title">3. Approval Settings</h2>
                <div className="flex flex-col justify-start mt-3">
                  <span className="team-board-setting-section_text mb-5">
                    Please select the approval setting
                  </span>
                  <div className="flex items-center mb-5">
                    <Checkbox
                      value={teamData?.isDelegationRequiresApproval || false}
                      //   customClass="mt-[10px]"
                      onChange={(e) => {
                        setTeamData((prevState) => {
                          return {
                            ...prevState,
                            isDelegationRequiresApproval: e.target.checked,
                          };
                        });
                      }}
                    />
                    <span>Every delegation requires Approval</span>
                  </div>
                  <div className="flex items-center mb-5">
                    <Checkbox
                      value={teamData?.isDeadlineExtensionRequiresApproval || false}
                      //   customClass="mt-[10px]"
                      onChange={(e) => {
                        setTeamData((prevState) => {
                          return {
                            ...prevState,
                            isDeadlineExtensionRequiresApproval: e.target.checked,
                          };
                        });
                      }}
                    />
                    <span>Every Deadline extension requires Approval</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="team-board-setting-form_section team-board-setting-section">
              <div className="team-board-setting-section_header">
                <h2 className="team-board-setting-section_title">4. Deadline Setting </h2>
                <div className="flex flex-col justify-start mt-3">
                  <span className="team-board-setting-section_text mb-5">
                    Please select the approval setting
                  </span>
                  <div className="flex items-center mb-5">
                    <Checkbox
                      value={optionPastDeadline}
                      //   customClass="mt-[10px]"
                      onChange={(e) => {
                        setOptionPastDeadline(!optionPastDeadline);

                        //   const approvalType = e.target.checked ? 'multiple' : 'single';
                        //   setStage({ ...stage, approvalType: approvalType });
                        //   onChangeStages(index, 'approvalType', approvalType);
                      }}
                    />
                    <span
                      className={clsx(
                        'mr-[7px]',
                        !optionPastDeadline ? 'text-[#e5e7eb]' : 'text-[#212B36]',
                      )}
                    >
                      If it is past deadline it will be escalated to{' '}
                    </span>
                    <div>
                      <SelectGroup
                        classNameGroup="text-sm z-3"
                        customStyle={{ width: '21.25rem' }}
                        title=""
                        placeholder=""
                        // labelFix={{ left: 5, top: 3, fontSize: 16 }}
                        // defaultValue={addRoleForTeacher}
                        isDisabled={!optionPastDeadline}
                        value={listRoleSelected.length > 0 ? listRoleSelected : null}
                        options={APPROVERS}
                        isMulti={true}
                        isSearchable={true}
                        styles={{
                          ...selectStylesWithTitle,
                          control: (styles, state) => ({
                            ...styles,
                            backgroundColor: state.isDisabled ? '#f2f2f2' : 'white',
                            paddingTop: '0',
                          }),
                        }}
                        onChange={(e) => {
                          setListRoleSelected(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="team-board-setting-section_footer mb-16">
              <Button
                text="Cancel"
                customStyle={styles.btnWhite}
                onClick={() => {
                  handlerOnClickCancel();
                }}
              />
              <Button
                text="Save"
                customStyle={styles.btnBlue}
                onClick={() => {
                  handleOnClickSave();
                }}
              />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}
