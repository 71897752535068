import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { selectGroupDistributionRule } from '../../../../entities/select-style';
import { cancel } from '../../../../static/image';
import { Button, Input, SelectGroup } from '../../../../stories';
import './add-distribution-modal.scss';

export default function AddDistributionModal({ open, isOpen, setListDistribution }) {
  const [tableOpen, setTableOpen] = useState(false);
  const [name, setName] = useState('');
  const [category, setCategory] = useState({ value: 'Admissions', label: 'Admissions' });

  const styles = {
    cancelBtn: {
      backgroundColor: '#fff',
      color: '#202223',
      border: '1px solid #E1E3E5',
      marginRight: '0.5rem',
    },
    createBtn: { backgroundColor: '#404EED' },
  };

  const allClose = () => {
    setTableOpen(false);
  };

  const handleOnClickCreate = () => {
    setListDistribution((prev) => [
      ...prev,
      {
        isNew: true,
        name: name,
        category: category.value,
        distributionType: '',
        ordering: '',
        isProcessOther: false,
        conditions: [],
        rotateBetweenUser: [],
        rotateBetweenRole: [],
        term: '',
        isExecuteWhenSpecifiedChanges: false,
      },
    ]);
    isOpen();
  };

  const DropdownIcon = () => (
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4 12L0 8H8L4 12ZM4 0L8 4H0L4 0Z" fill="#5C5F62"/>
    </svg>
  )

  return (
    <div className={open ? 'action-modal action-modal-active' : 'action-modal'}>
      <div
        className="action-modal_bg"
        onClick={() => {
          isOpen();
        }}
      />
      <div className="action-modal-card" onClick={tableOpen === true ? allClose : null}>
        <div className="action-modal-card-header">
          <h2 className="text-xl ml-6">Add Distribution Rule</h2>
          <button
            className="mr-4"
            onClick={() => {
              isOpen();
            }}
          >
            <img src={cancel} alt="closed modal" />
          </button>
        </div>
        <div className="action-modal-card-row">
          <div className="action-modal-card-row_label"></div>
          <div className="action-modal-card-row_selects">
            <SelectGroup
              classNameGroup="ml-10 mb-7 w-80"
              title="Select Category"
              value={category}
              onChange={(e) => setCategory(e)}
              isMulti={false}
              styles={selectGroupDistributionRule}
              dropDown={<DropdownIcon />}
            />

            <div className="ml-10 w-80">
              <Input
                label="Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                customStyle={{
                  padding: '8px 10px',
                  width: '100%',
                  height: '56px',
                  fontSize: '1rem',
                  lineHeight: '24px',
                  fontWeight: '400',
                  borderRadius: '0.5rem',
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end pt-4 pr-4">
          <Button
            text="Cancel"
            customStyle={styles.cancelBtn}
            onClick={() => {
              isOpen();
            }}
          />
          <Button
            text="Create"
            customStyle={styles.createBtn}
            onClick={handleOnClickCreate}
          />
        </div>
      </div>
    </div>
  );
}

AddDistributionModal.propTypes = {
  open: PropTypes.bool,
  isOpen: PropTypes.func,
};
