import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Zoom from 'react-reveal/Zoom';
import { pdf, uploadFile, word, xls } from '@static/image';
import { Button, Checkbox, SelectGroup } from '@stories/index';
import HeaderTabs from '@stories/tabs/tabs';
import { checkErrorSingleApi } from '@utils/check-error/api-error';
import { getCurrentCampus } from '@utils/storage';
import { ToastMessageContext } from 'context/toast-context';
import { selectStyle, toggleButtonStyles } from 'entities/select-style';
import TaskComment from '../task-comment';
import TaskInformation from '../task-information';
import './edit-task.scss';
import taskApi from 'api/task';
import notificationApi from 'api/notification';
import useStorage from 'store/storage';

const tabsData = ['Information', 'Comments', 'Files', 'Other Settings'];

export default function EditTaskPanel(props) {
  const {
    visible,
    setVisible,
    selectedTask,
    indexSelectedTask,
    setListTasks,
    selectedTeam,
    number = 0,
  } = props;
  const [typeState, setTypeState] = useState(!!tabsData && tabsData[number]);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const handleOnClickCancel = () => {
    setVisible(false);
    notificationApi.unsubscribeTopicNotification({ topic: selectedTask.id })
      .then((results) => {
        if (results.data.success) {
          console.log('UnSubscribe Topic Successfully');
        } else {
          setToastMessage({
            status: 'error',
            title: 'UnSubscribe Topic Failed',
            message: results.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'UnSubscribe Topic Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('UnSubscribe Topic Error', error);
      });
  };

  return (
    <Zoom bottom duration={700} when={visible}>
      <div
        className={clsx(
          'edit-task-panel overflow-y-hidden',
          visible ? 'pointer-events-auto' : 'pointer-events-none hidden',
        )}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center flex-auto w-32">
            <div>
              <h2 className="text-2xl font-semibold">Edit Task</h2>
            </div>
          </div>
          <button onClick={handleOnClickCancel}>
            <Close />
          </button>
        </div>
        <hr className="mt-2 mb-2" />
        <HeaderTabs
          types={tabsData}
          setTypeState={setTypeState}
          typeState={typeState}
          customStyle={{ paddingTop: '0.5rem', paddingBottom: '0.25rem' }}
        />

        {typeState === 'Information' && <TaskInformation taskId={selectedTask.id} />}
        {typeState === 'Files' && <UploadFiles selectedTask={selectedTask} />}
        {typeState === 'Other Settings' && (
          <OtherSettings
            selectedTask={selectedTask}
            setListTasks={setListTasks}
            indexSelectedTask={indexSelectedTask}
            selectedTeam={selectedTeam}
            setVisible={setVisible}
          />
        )}
        {typeState === 'Comments' && (
          <TaskComment
            taskId={selectedTask.id}
            customClass=" w-full overflow-auto h-[83%] 1340px:h-[81%] 1500px:h-[85%] 1920px:h-[88%]"
          />
        )}
      </div>
    </Zoom>
  );
}

const UploadFiles = (props) => {
  const { selectedTask } = props;
  const [listFile, setListFile] = useState([]);
  const [refeshData, setRefeshData] = useState(0);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    accept: '.pdf,.doc,.docx,.xls , .xlsx, .xlsm, .xltx, .xltm',
    onDrop: async (files) => {
      const rawQuery = [];
      await Promise.all(
        files.map(async (file) => {
          const formData = new FormData();
          formData.set('file', file);
          formData.set('mediaId', file.name);
          await taskApi.uploadMediaFile(formData)
            .then((response) => {
              const { data } = response;
              if (data.success) {
                rawQuery.push({ fileURL: data.data, fileName: file.name });
              } else {
                setToastMessage({
                  status: 'error',
                  title: 'Upload File Failed',
                  message: response.data.message,
                });
                setIsShowToastMessage(true);
              }
            })
            .catch((error) => {
              setToastMessage({
                status: 'error',
                title: 'Upload File Failed',
                message: error.response?.data?.message || error,
              });
              setIsShowToastMessage(true);
              console.log('Upload to server error', error);
            });
        }),
      ).then(() => {
        if (rawQuery.length > 0) {
          taskApi.saveTaskFile({ campusId: currentCampus?.isCentral ? undefined : currentCampus?.id }, { taskId: selectedTask.id, fileURLs: [...rawQuery] })
            .then((response) => {
              const { data } = response;
              if (data.success) {
                setRefeshData((prevState) => prevState + 1);
                setToastMessage({
                  status: 'success',
                  title: 'Upload File Successfully',
                  message: data.message,
                });
                setIsShowToastMessage(true);
              } else {
                setToastMessage({
                  status: 'error',
                  title: 'Upload File Failed',
                  message: data.message,
                });
                setIsShowToastMessage(true);
              }
            })
            .catch((error) => {
              setToastMessage({
                status: 'error',
                title: 'Upload File Failed',
                message: error.response?.data?.message || error,
              });
              setIsShowToastMessage(true);
              console.log('Upload URL file to server error', error);
            });
        }
      });
    },
  });
  const FileTypeImage = new Map([
    ['pdf', pdf],
    ['word', word],
    ['docx', word],
    ['xls', xls],
    ['xlsx', xls],
    ['xlsm', xls],
    ['xltx', xls],
    ['xltm', xls],
  ]);
  const currentCampus = useStorage((state) => state.currentCampus);

  useEffect(() => {
    taskApi.getListFile({ campusId: currentCampus?.isCentral ? undefined : currentCampus?.id, taskId: selectedTask?.id })
      .then((res) => {
        if (res.data.success) {
          if (res.data.data.objects?.length >= 0) {
            setListFile(res.data.data.objects);
          }
        } else {
          setToastMessage({
            status: 'error',
            title: 'Fetch Data Failed',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Get task information error', error);
      });
  }, [refeshData]);
  const files = listFile.map((file) => {
    const date =
      JSON.stringify(file?.dateCreated).substr(1, 10) + ' By ' + file?.createdBy?.name;
    const typeFile = file?.fileName?.split('.').pop();

    const handleDelete = (file) => {
      taskApi.deleteFileById({ campusId: currentCampus?.isCentral ? undefined : currentCampus?.id }, file.id)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            setRefeshData((prevState) => prevState + 1);
          } else {
            setToastMessage({
              status: 'error',
              title: 'Delete File Failed',
              message: response.data.message,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Delete File Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Delete URL file to server error', error);
        });
    };
    return (
      <li key={file?.id} className="flex items-center mt-0.5">
        <img
          src={FileTypeImage.get(typeFile)}
          alt={typeFile}
          className="w-10 h-10 mr-3"
        />
        <div className="mb-2 w-full">
          <a
            className="text-xs text-main-black font-semibold mb-0.5"
            href={file?.fileURL}
            target="_blank" rel="noreferrer"
          >
            {file?.fileName}
          </a>
          <p className="text-second-gray text-10px">{date}</p>
        </div>
        <button className="w-10 h-10 ml-3" onClick={() => handleDelete(file)}>
          <Close />
        </button>
      </li>
    );
  });

  const classes = ((length) => {
    switch (length) {
      case 0:
        return 'mt-16 1920px:mt-32'
      case 1:
        return 'mt-10 1920px:mt-28'
      case 2:
        return 'mt-10 1920px:mt-24'
      case 3:
        return 'mt-5 1920px:mt-20'
      default:
        return 'mt-0 1920px:mt-16'
    }
  })(listFile.length)

  return (
    <div className={`flex flex-col ${classes}`}>
      <div className="upload-file-container-dropzone mt-4 mb-2">
        <div id="dropzone" {...getRootProps({ className: 'dropzone h-[8rem]' })}>
          <input {...getInputProps()} />
          {/* <img src={taskFile} alt="uploadFile" className="h-[8rem]" /> */}
          <img src={uploadFile} alt="uploadFile" className="h-[8rem]" />
        </div>
      </div>
      <div className="ml-5">
        <p className="font-semibold text-xl text-center">
          {isDragActive
            ? 'Please drop PDF, DOC, DOCX , XLS , XLSX, XLSM, XLTX, XLTM'
            : 'Drag and drop or add files here'}
        </p>
        <p className="text-sm text-second-gray text-center mb-5">
          Upload, comment and review all files in this item to easily collaborate in
          context
        </p>
        <button
          className="edit-task-add-item mt-2"
          onClick={() => {
            const dropzone = document.getElementById('dropzone');
            dropzone.click();
          }}
        >
          {/* <div className="edit-task_btn-plus">
            <span>+</span>
          </div> */}
          + Add File
        </button>
      </div>

      {listFile.length > 0 && (
        <div className="ml-5">
          <aside>
            <h4 className="text-sm font-semibold text-main-black mt-2 1920px:mt-4">
              Uploaded Files
            </h4>
            <ul className="overflow-x-auto overflow-y-auto h-[24vh]">{files}</ul>
          </aside>
        </div>
      )}
    </div>
  );
};

const OtherSettings = (props) => {
  const { selectedTask, selectedTeam, setVisible, setListTasks, indexSelectedTask } =
    props;

  const [flagCheckin, setFlagCheckin] = useState(selectedTask.isMandatoryCheckIn);
  const [selectOptionCheckin, setSelectOptionCheckin] = useState(
    selectedTask.mandatoryCheckInEvery,
  );
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const currentCampus = useStorage((state) => state.currentCampus);
  const currentUser = useStorage((state) => state.auth);

  const optionSelect = [
    { value: 'Week', label: 'Week' },
    { value: '2 Week', label: '2 Week' },
    { value: 'Month', label: 'Month' },
  ];
  const allowChange = selectedTeam.managers.some((data) => {
    return data.user.id === currentUser.uid;
  });

  const handleSaveTaskSetting = () => {
    if (flagCheckin && !selectOptionCheckin) {
      setToastMessage({
        status: 'warning',
        title: 'Validation Rules',
        message: 'Please select period if you want to set mandatory check-in',
      });
      setIsShowToastMessage(true);
      return;
    }

    const data = {
      isMandatoryCheckIn: flagCheckin,
      mandatoryCheckInEvery: selectOptionCheckin?.value
        ? selectOptionCheckin?.value
        : selectOptionCheckin,
    };
    taskApi.updateTaskById({ campusId: currentCampus?.isCentral ? undefined : currentCampus?.id }, data, props.selectedTask.id)
      .then((res) => {
        if (
          checkErrorSingleApi(
            res,
            setToastMessage,
            setIsShowToastMessage,
            'Update Task Setting',
          )
        ) {
          setListTasks((prev) => {
            prev[indexSelectedTask].isMandatoryCheckIn = data.isMandatoryCheckIn;
            prev[indexSelectedTask].mandatoryCheckInEvery = data.mandatoryCheckInEvery;
            return prev;
          });
          setVisible(false);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Task Setting Update Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Get task information error', error);
      });
  };

  const handleCancelTaskSetting = () => {
    setVisible(false);
  };

  return (
    <div className="w-full" style={{ height: '50%' }}>
      <div className="flex-row overflow-y-auto overflow-x-auto h-full w-full">
        <hr className="mt-4" />
        <div>
          <h3 className="edit-task-heading">1. Checkin </h3>
          <p className="edit-task-content">
            Check-in is a weekly update or action on the task
          </p>
          <div className="edit-task_body_bottom edit-task-sub-content">
            <Checkbox
              value={flagCheckin}
              disabled={!allowChange}
              onChange={(e) => {
                setFlagCheckin(e.target.checked);
              }}
            />
            <span className={clsx('mr-3', !allowChange ? 'text-[#e5e7eb]' : '')}>
              There is a Mandatory check-in every
            </span>
            <div className="w-32">
              <SelectGroup
                value={
                  typeof selectOptionCheckin === 'string'
                    ? { value: selectOptionCheckin, label: selectOptionCheckin }
                    : selectOptionCheckin
                }
                styles={selectStyle}
                options={optionSelect}
                isMulti={false}
                isSearchable={false}
                isDisabled={!allowChange || !flagCheckin}
                onChange={(e) => {
                  setSelectOptionCheckin(e);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center absolute bottom-0 left-7 right-7">
        <Button
          text="Cancel"
          customStyle={toggleButtonStyles.btnWhite}
          onClick={handleCancelTaskSetting}
        />
        <Button
          text="Save"
          buttonDisabled={!allowChange}
          customStyle={toggleButtonStyles.btnBlue}
          onClick={handleSaveTaskSetting}
        />
      </div>
    </div>
  );
};

const Close = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 1.4L14.6 0L8 6.6L1.4 0L0 1.4L6.6 8L0 14.6L1.4 16L8 9.4L14.6 16L16 14.6L9.4 8L16 1.4Z"
        fill="#2F80ED"
      />
    </svg>
  );
};
