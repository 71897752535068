import React, { useState } from 'react';

import { Checkbox } from '../../../../stories';
import { phstudent } from '../../../../static/image';
import ReactTooltip from 'react-tooltip';

const styles = {
  study: { color: '#404EED' },
  playgroup: { textTransform: 'uppercase' },
  section: { color: '#919eab' },
  yrs: { top: 1 },
};

export default function GradesCard(props) {
  const {
    index,
    grade,
    listProgrammes,
    listGradeProgrammes,
    handleUpdateClass,
    // setIndex = () => {},
  } = props;
  const [checkOne, setCheckOne] = useState(false);

  const checkGradeProgram = (grade, program, listGradeProgrammes) => {
    for (const gradeProgram of listGradeProgrammes) {
      if (grade.id === gradeProgram.id) {
        for (const data of gradeProgram.programGrade) {
          if (data.program.id === program.id) {
            return true;
          }
        }
      }
    }
    return false;
  };
  const checkClass = (grade, program, listGradeProgrammes) => {
    const filterProgrammes = [];
    listGradeProgrammes.forEach((data, index) => {
      if (data.id === grade.id) {
        filterProgrammes.push({ ...data, index: index });
      }
    });

    const filterProgramGrade = filterProgrammes.find((data) => {
      const findProgramInProgramGrade = data.programGrade.findIndex((proGra) => {
        return proGra.program.id === program.id;
      });
      return findProgramInProgramGrade !== -1;
    });

    let _class;
    let idx = 0;
    filterProgramGrade?.programGrade.forEach((proGrade, index) => {
      if (proGrade.program.id === program.id) {
        _class = proGrade.class;
        idx = index;
      }
    });
    if (Array.isArray(_class)) {
      _class = _class[0];
    }
    if (!_class) {
      return {
        status: false,
        data: {
          index: filterProgramGrade?.index,
          programGradeId: filterProgramGrade?.programGrade?.[idx]?.id,
          programName: program.name,
          gradeName: grade.name,
        },
        idx: idx,
      };
    }
    return {
      status: true,
      data: {
        index: filterProgramGrade?.index,
        programGradeId: filterProgramGrade?.programGrade?.[idx]?.id,
        class: _class,
        programName: program.name,
        gradeName: grade.name,
      },
      idx: idx,
    };
  };

  return (
    <tr className="grades-class-card-row_card"
    style={{height: `calc(7.1rem + ${index * 0.11}px)`}}>
      {listProgrammes.map((item, key) => {
        const resultCheckClass = checkClass(grade, item, listGradeProgrammes);
        return !checkGradeProgram(grade, item, listGradeProgrammes) ? (
          <td key={key} className=" text-center pt-2">
            <Checkbox value={true} disabled={true} />
          </td>
        ) : resultCheckClass.status ? (
          <ClassElement
            item={resultCheckClass.data}
            key={key}
            handleUpdateClass={handleUpdateClass}
            idx={resultCheckClass.idx}
          />
        ) : (
          <td key={key} className="text-center pt-2">
            <Checkbox
              value={checkOne}
              onChange={() => {
                handleUpdateClass(
                  {
                    ...resultCheckClass.data,
                    class: {
                      isEnforceMaxStrength: false,
                      isEnforceMinAge: false,
                      isWaitingList: false,
                      minAge: 1,
                      maxStudentsPerSection: 10,
                      noSections: 5,
                      name: 'Class Name',
                    },
                  },
                  resultCheckClass.idx,
                );
              }}
            />
          </td>
        );
      })}
    </tr>
  );
}

const ClassElement = ({ item, handleUpdateClass, idx }) => {
  return (
    <td>
      <div
        onClick={() => {
          handleUpdateClass(item, idx);
        }}
        className="w-60 h-24 flex ml-1 justify-around rounded-2xl shadow-grade cursor-pointer p-2"
      >
        <div className="grades-card-section_text max-w-[65%] min-w-[65%] text-[12px] ml-6 mr-2 pt-4">
          <ReactTooltip
            id="main"
            place="top"
            type="dark"
            effect="float"
            multiline={true}
          />
          <h5
            className="text-ellipsis whitespace-nowrap overflow-hidden pb-3 font-bold"
            style={styles.playgroup}
            data-for="main"
            data-tip={item.class.name}
            data-iscapture="true"
          >
            {item.class.name}
          </h5>
          <p className="cursor-pointer" style={styles.study}>
            <img className="inline" src={phstudent} alt="study" />
            {item.class.maxStudentsPerSection} per Section
          </p>
        </div>
        <div className="grades-card-section_text max-w-[34%] min-w-[34%] text-[12px] pt-4">
          <p className="relative pb-3" style={styles.section}>
            {item.class.noSections} Section
          </p>
          <p className="relative" style={styles.yrs}>
            {item.class.minAge} - {item.class.maxAge} yrs
          </p>
        </div>
      </div>
    </td>
  );
};
