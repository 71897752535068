import { Button } from "@stories/index";
import React, { memo } from "react";

function FooterDetail(props) {
  // either handleNext or handleSave
  const { handleCancel, handleNext, handleSave } = props;

  return (
    <div className="bloom-footer justify-center">
      <Button
        buttonStyle="outlined"
        text="Cancel"
        buttonSize="medium"
        customStyle={{
          width: '12rem',
          justifyContent: 'center',
          marginRight: '1rem',
          borderRadius: '0px',
        }}
        onClick={handleCancel}
      />

      <Button
        buttonStyle="contained"
        onClick={handleNext || handleSave}
        text={handleSave ? 'Save And Next' : 'Next'}
        buttonSize="medium"
        customStyle={{
          width: '12rem',
          justifyContent: 'center',
          borderRadius: '0px',
        }}
      />
    </div>
  )
}

export default memo(FooterDetail);
FooterDetail.display = 'FooterDetail'