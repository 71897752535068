import { useContext, useRef } from 'react';
import { Button, Input } from '@stories/index';
import { checkErrorSingleApi } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { toggleButtonStyles } from 'entities/select-style';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';

function UpdateFormName(props) {
  const { setIsEditName, id, setListForm, index, name } = props;
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campus = useStorage((state) => state.currentCampus);
  const nameFormRef = useRef();

  const handleUpdateFormName = () => {
    const newName = nameFormRef.current.value.trim();
    if (newName.length === 0) {
      setToastMessage({
        status: 'error',
        title: 'Validation Rule',
        message: 'Form name is not empty',
      });
      setIsShowToastMessage(true);
      return;
    }
    // callApi({
    //   method: 'patch',
    //   url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/form-builder/${id}`,
    //   params: { campusId: campus?.isCentral ? undefined : campus?.id },
    //   data: { name: newName },
    // })
    centralAdminApi.updateFormBuilder({ campusId: campus?.isCentral ? undefined : campus?.id }, { name: newName }, id)
      .then((res) => {
        if (
          checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, 'Update Form')
        ) {
          setListForm((prev) => {
            const newList = [...prev];
            newList[index].name = newName;
            return newList;
          });
          setIsEditName(false);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Update Form Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Update Form error', error.message);
      });
  };

  return (
    <>
      <div className="flex flex-wrap justify-center absolute top-12 w-[18rem] h-32 left-[-1rem] bg-[#fff] shadow-2xl z-20 rounded">
        <div className="mt-6">
          <Input
            refCustom={nameFormRef}
            onChange={(e) => {
            }}
            label="Edit Form Name"
            name="task-name"
            type="text"
            customStyle={{
              width: '16.25rem',
              height: '2.5rem',
              fontSize: '0.875rem',
              borderRadius: '0.25rem',
            }}
          />
        </div>
        <div className="flex justify-center">
          <Button
            className="text-[0.8rem]"
            text="Cancel"
            customStyle={{
              ...toggleButtonStyles.btnWhite,
              width: '7rem',
              height: '1.5rem',
            }}
            onClick={() => {
              setIsEditName(false);
            }}
          />
          <Button
            className="text-[0.8rem]"
            text="Save"
            customStyle={{
              ...toggleButtonStyles.btnBlue,
              width: '7rem',
              height: '1.5rem',
            }}
            onClick={() => {
              handleUpdateFormName();
            }}
          />
        </div>
      </div>
    </>
  );
}

export default UpdateFormName;
