import { useState, createContext } from 'react';

const ToastMessageContext = createContext();

function ToastMessageProvider({ children }) {
  const [isShowToastMessage, setIsShowToastMessage] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    status: '',
    title: '',
    message: '',
  });

  const value = {
    toastMessage,
    setToastMessage,
    isShowToastMessage,
    setIsShowToastMessage,
  };

  return (
    <ToastMessageContext.Provider value={value}>{children}</ToastMessageContext.Provider>
  );
}

export { ToastMessageContext, ToastMessageProvider };
