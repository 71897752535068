import { memo, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastMessageContext } from '../../../../context/toast-context';

import { arrow_right, done, done_gray, done_green, done_yellow, needInfoNew, rejected } from '../../../../static/image';
import UpdateStatus from '../update-status';
import { urls } from 'entities/urls';

const DocumentItem = memo((props) => {
  const { info, index, setListForm, setRefresh, getProgramId } = props;

  const style = {
    color: '#2E3338',
    title: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: '600',
    },
    label: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      color: '#0E353D',
      fontWeight: '700',
    },
  };

  const [isEditName, setIsEditName] = useState(false);
  const [isEditStatus, setIsEditStatus] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const history = useHistory();
  const [visible, setVisible] = useState(false);

  function handleOpenEditPage() {
    history.push(urls.campus.admission.setting.editDocument, {
      info: info,
      programId: getProgramId,
    });
  }

  return (
    <div
      className="flex items-center bg-white w-full justify-between mb-4 text-center p-5"
      style={{
        boxShadow:
          '0px 0px 2px rgba(145, 158, 171, 0.24), 0px 16px 32px -4px rgba(145, 158, 171, 0.24)',
        borderRadius: '0.5rem',
      }}
    >
      <div className="w-[217px] h-full text-left font-semibold relative"
        onDoubleClick={() => {
          if (!info.id) {
            setToastMessage({
              status: 'warning',
              title: 'Validation Rule',
              message: 'You cannot edit the default form',
            });
            setIsShowToastMessage(true);
          } else {
            setIsEditName(true);
          }
        }}
      >
        <p style={{ color: style.color, fontSize: '1rem', lineHeight: '1.5rem' }}>
          {info.name}
        </p>
        {/* {isEditName && (
          <UpdateFormName
            setIsEditName={setIsEditName}
            name={info.name}
            id={info.id}
            setListForm={setListForm}
            index={index}
          />
        )} */}
      </div>

      <div className="w-[150px] h-full">
        <p style={{ color: style.color, ...style.title }}>Used in</p>
        <p style={{ ...style.label }}>{info.usedIn}</p>
      </div>

      <div className="w-[150px] h-full">
        <p style={{ color: style.color, ...style.title }}>Multiple Documents</p>
        <p style={{ ...style.label }}>{info.multipleDocuments}</p>
      </div>

      <div
        className="w-[100px] h-full relative cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          if (!info.id) {
            setToastMessage({
              status: 'warning',
              title: 'Validation Rule',
              message: 'You cannot edit the default form',
            });
            setIsShowToastMessage(true);
          } else {
            setIsEditStatus(true);
          }
        }}
      >
        <p style={{ color: style.color, ...style.title }}>Status</p>
        <StatusItem status={info.status} />
        {isEditStatus && (
          <UpdateStatus
            setIsEditStatus={setIsEditStatus}
            status={info.status}
            id={info.id}
            setRefresh={setRefresh}
          />
        )}
      </div>

      <div className="w-[19.5px] h-full">
        <img
          src={arrow_right}
          className="cursor-pointer"
          onClick={handleOpenEditPage}
          alt="arrow"
        />
      </div>
    </div>
  );
});

DocumentItem.displayName = 'DocumentItem';
export default DocumentItem;

const StatusItem = memo((props) => {
  const { status } = props;

  const mapStatus = useMemo(
    () => ({
      Required: {
        bgColor: '#AEE9D1',
        color: '#219653',
        icon: done_green,
        label: 'Required',
      },
      Optional: {
        bgColor: '#FFF2E2',
        color: '#FFC96B',
        icon: done_yellow,
        label: 'Optional',
      },
      Disabled: {
        bgColor: '#F2F7FE',
        color: '#8C9196',
        icon: done_gray,
        label: 'Disabled',
      },
    }),
    [],
  );

  return (
    <div
      className="flex items-center justify-center w-full h-8 gap-1.5 px-2.5 py-[5px]"
      style={{
        backgroundColor: mapStatus[status].bgColor,
        borderRadius: '2.5rem',
      }}
    >
      <img
        src={mapStatus[status].icon}
        className="block w-4 h-4"
        alt="status"
      />
      <p
        className="text-xs"
        style={{
          color: mapStatus[status].color,
        }}
      >
        {mapStatus[status].label}
      </p>
    </div>
  );
});
StatusItem.displayName = 'StatusItem';
