import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import './application-panel.scss';
import { filelistfill, group, mailunreadfill, phonefill, todofill } from './image';
import { whats_app_icon } from '@static/image';
import {
  CalendarPick,
  CallPick,
  MessagePick,
  NotePick,
  TasksPick,
} from './sub-components';
import WhatsappPick from './sub-components/whatsapp-pick';
import { ToastMessageContext } from 'context/toast-context';
import Loading from '@components/loading';
import admissionApi from 'api/admission';
import useStorage from 'store/storage';

export default function ApplicationPanel(props) {
  const { enquiry, enquiryParent } = props;
  const [labelNum, setLabelNum] = useState({
    call: 0,
    message: 0,
    tasks: 0,
    calendar: 0,
    note: 0,
  });
  const [parents, setParents] = useState([]);
  const [isVerifyEmail, setIsVerifyEmail] = useState(false);
  const [visible, setVisible] = useState({
    call: false,
    listCall: false,
    calling: false,
    callNote: false,
    message: false,
    tasks: false,
    calendar: false,
    note: false,
    addEvent: false,
    whatsapp: false,
    sendEmail: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const userLogin = useStorage((state) => state.auth)
  const status = Object.values(visible).some((item) => item);

  useEffect(() => {
    if (enquiry?.admissionResult?.info) {
      const info = JSON.parse(enquiry.admissionResult.info);

      if (info?.application?.applicationParent)
        setParents(info.application.applicationParent);
    } else
      setParents([enquiryParent])
  }, [enquiry, enquiryParent]);

  const handleSelectApplication = async (appType) => {
    if (appType !== 'message') {
      setVisible((prev) => ({ [appType]: !prev?.[appType] }));
    } else {
      await handleOpenEmail(appType);
    }
  };

  const handleOpenEmail = async (appType) => {
    if (visible.message) {
      setVisible((prev) => ({ [appType]: !prev?.[appType] }));
      // setVisible((prev) => ({ ...prev, sendEmail: !prev?.sendEmail }));
    } else {
      setIsLoading(true);
      admissionApi.checkEmailVerify(userLogin?.email)
        .then((results) => {
          if (results?.data?.success) {
            if (results?.data?.data?.isVerifyEmail) {
              setIsVerifyEmail((prev) => !prev);
              setVisible((prev) => ({ [appType]: !prev?.[appType] }));
            } else {
              const width = 600;
              const height = 800;
              const left = (window.innerWidth - width) / 2;
              const top = (window.innerHeight - height) / 2;
              const popupWindow = window.open(
                results?.data?.data?.url,
                'New Popup',
                `width=${width},height=${height},left=${left},top=${top}`,
              );
              window.addEventListener('message', receiveMessagesFromPopup);

              function receiveMessagesFromPopup(event) {
                if (event.source === popupWindow) {
                  const data = event.data;
                  if (event.data.action === 'closePopup') {
                    if (data.isVerifySuccess) {
                      setIsVerifyEmail((prev) => !prev);
                      setVisible((prev) => ({ [appType]: !prev?.[appType] }));
                    } else {
                      setToastMessage({
                        status: 'error',
                        title: 'Verify Email Failed',
                        message: data.message,
                      });
                      setIsShowToastMessage(true);
                    }
                  }
                }
              }
            }
          } else {
            setToastMessage({
              status: 'error',
              title: 'Verify Email Failed',
              message: results.data.message,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Verify Email  Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Get Email Error', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const dataStatus = [
    {
      type: 'call',
      image: phonefill,
      bg: '#FFF7CD',
      labelBg: '#FFC107',
      labelNum: labelNum.call,
      component: (
        <CallPick
          visible={visible}
          setLabelNum={setLabelNum}
          setVisible={setVisible}
          enquiry={enquiry || {}}
          parents={parents}
        />
      ),
    },
    {
      type: 'message',
      image: mailunreadfill,
      bg: '#C8FACD',
      labelBg: '#FFC107',
      labelNum: labelNum.message,
      component: (
        <MessagePick
          isVerifyEmail={isVerifyEmail}
          visible={visible}
          setVisible={setVisible}
          handleOpenEmail={handleOpenEmail}
          enquiry={enquiry || {}}
          parents={parents}
        />
      ),
    },
    {
      type: 'tasks',
      image: todofill,
      bg: '#D0F2FF',
      labelBg: '#FFC107',
      labelNum: labelNum.tasks,
      component: <TasksPick visible={visible.tasks} setVisible={setVisible} />,
    },
    {
      type: 'calendar',
      image: group,
      bg: '#FFE7D9',
      labelBg: '#B72136',
      labelNum: labelNum.calendar,
      component: (
        <CalendarPick visible={visible}
          setVisible={setVisible}
          enquiry={enquiry || {}}
          parents={parents}
        />
      ),
    },
    {
      type: 'note',
      image: filelistfill,
      bg: '#F4F6F8',
      labelBg: '#F4F6F8',
      labelNum: labelNum.note,
      component: (
        <NotePick visible={visible.note} setVisible={setVisible} enquiry={enquiry} />
      ),
    },
    {
      type: 'whatsapp',
      image: whats_app_icon,
      bg: '#AEE9D1',
      labelBg: '#AEE9D1',
      labelNum: labelNum.whatsapp,
      component: (
        <WhatsappPick
          visible={visible}
          setLabelNum={setLabelNum}
          setVisible={setVisible}
          enquiry={enquiry || {}}
          parents={parents}
        />
      ),
    },
  ];

  const onClickOutside = () => {
    setVisible((prev) => {
      if (prev.calendar) {
        if (prev.addEvent) return { ...prev, addEvent: false };
        return { ...prev, calendar: false };
      }

      if (prev.call) {
        if (prev.listCall) return { ...prev, listCall: false };
        if (prev.callNote) return prev;
        if (prev.calling) return prev;
        return { ...prev, call: false };
      }

      return { ...prev, message: false, note: false, tasks: false, whatsapp: false };
    });
  };

  return (
    <>
      <div className="application-panel">
        <div
          className={clsx('application-panel-closed', status ? '' : 'hidden')}
          onClick={onClickOutside}
        />
        {dataStatus.map((data) => {
          const styles = {
            button: { background: data.bg },
            label: {
              background: data.labelBg,
              bottom: '3.563rem',
              left: '1.625rem',
            },
          };

          return (
            <React.Fragment key={data.type}>
              <div className={clsx(status ? 'pointer-events-auto' : 'pointer-events-none')}>
                {data.component}
              </div>
              <div className="relative">
                <button
                  className="application-panel-button"
                  style={styles.button}
                  onClick={() => {
                    handleSelectApplication(data.type);
                  }}
                >
                  <img src={data.image} alt={data.type} />
                </button>
                {data.labelNum > 0 && (
                  <label className="application-panel-label" style={styles.label}>
                    {data.labelNum}
                  </label>
                )}
              </div>
            </React.Fragment>
          );
        })}

      </div>
      {isLoading && (
        <div className="fixed inset-0 bg-[#33333380] flex items-center justify-center z-[9999]">
          <Loading />
        </div>
      )}</>
  );
}
