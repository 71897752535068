import DefaultAvatar from "@components/default-avatar";
import { getPrefixUserName } from "@helper/application";

const SingleCallCard = (props) => {
  const { data, onClick } = props;

  const parentName = `${getPrefixUserName(data?.relationship)}${data?.fullName}`

  return (
    <div className="cursor-pointer w-full h-[4rem] mt-3 shadow rounded">
      <button className="flex item-center h-full px-3 py-4" onClick={onClick}>
        {data?.photoURL ? (
          <img className="rounded-full h-full aspect-square mr-4" src={data.photoURL} />
        ) : (
          <DefaultAvatar title={data?.fullName} className='rounded-full h-full aspect-square mr-4' />
        )}
        <div className="place-self-center text-left">
          <div className="text-sm font-medium flex">
            <p className="text-ellipsis-custom max-w-[9.5rem]">{parentName}</p>
            {data?.relationship && <p>{`(${data?.relationship})`}</p>}
          </div>
          <p className="text-xs text-gray">{data?.phoneNumber || ''}</p>
        </div>
      </button>
    </div>
  )
}

export default SingleCallCard;