import React, { useContext, useEffect, useState } from 'react';
import { ToastMessageContext } from '../../../../context/toast-context';
import { EnquiryStatus } from '../../../../entities/data';
import { Button, StaticPicker } from '../../../../stories';
import { checkErrorSingleApi } from '../../../../utils/check-error/api-error';
import { calendar, clock } from '../exam/image';
import { ButtonDatePicker } from '../exam/sub-components';
import { approved, iccheck, icchevron, waiting } from './image';
import { listTime } from '@constant/list-time';
import admissionApi from 'api/admission';

export default function PrincipalApproval(props) {
  const { enquiry, isCompletedOrClosed, handleNextStage, handleRefreshEnquiry, handleRefreshAndNext } = props
  const styles = {
    button: {
      paddingLeft: '3rem',
      paddingRight: '1.875rem',
      position: 'absolute',
      right: '0.313rem',
    },
  };
  const [principalApproval, setPrincipalApproval] = useState();
  const [isPreview, setIsPreview] = useState(false);
  const [isMeeting, setIsMeeting] = useState(false);
  const [isApproval, setIsApproval] = useState(false);
  const [meetingDate, setMeetingDate] = useState(new Date().toUTCString());
  const [time, setTime] = useState({});
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [idSchedulePrincipal, setIdSchedulePrincipal] = useState();
  const [idApplication, setIdApplication] = useState();

  useEffect(() => {
    if (enquiry?.admissionResult?.info) {
      const info = JSON.parse(enquiry.admissionResult.info);
      setIdApplication(info.application.id);
      const { principalApproval } = info;
      setPrincipalApproval(principalApproval);
      setMeetingDate(principalApproval?.meetingDate || new Date());
      setTime(prev => ({
        ...prev,
        value: [principalApproval?.startTimeSlot, principalApproval?.endTimeSlot]
      }));
      if (principalApproval?.status === EnquiryStatus.WAITING_FOR_PRINCIPAL_APPROVAL) {
        setIsApproval(true);
      } else if (
        principalApproval?.status === EnquiryStatus.PRINCIPAL_APPROVAL_COMPLETE
      ) {
        setIsPreview(true);
      }
    }
  }, [enquiry]);

  const handleSchedule = () => {
    if (isCompletedOrClosed) {
      handleNextStage()
      return
    }
    if (!time[0] || !time[1]) {
      setToastMessage({
        status: 'warning',
        title: 'Please pick time schedule',
        message: 'Please choose a time',
      });
      setIsShowToastMessage(true);
      return
    }
    const data = {
      applicationId: idApplication,
      meetingDate: new Date(meetingDate).toISOString(),
      startTimeSlot: time[0],
      endTimeSlot: time[1],
    };
    admissionApi.createApplicationPrincipal(data)
      .then((res) => {
        if (
          checkErrorSingleApi(
            res,
            setToastMessage,
            setIsShowToastMessage,
            'Principal Schedule Approval Result',
          )
        ) {
          setIsPreview(true);
          setIdSchedulePrincipal(res.data.id);
          handleRefreshEnquiry();
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Principal Schedule Approval Result Error',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Principal Schedule Approval Result Error', error);
      });
  };

  const handlePrincipalComplete = () => {
    if (isCompletedOrClosed) {
      handleNextStage()
      return
    }

    admissionApi.updateApplicationPrincipalComplete(idSchedulePrincipal ?? principalApproval.id)
      .then((res) => {
        if (
          checkErrorSingleApi(
            res,
            setToastMessage,
            setIsShowToastMessage,
            'Principal Approval Result',
          )
        ) {
          // setIsPreview(true);
          handleRefreshAndNext();
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Principal Approval Result Error',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Principal Approval Result Error', error);
      });
  };

  if (isPreview) {
    return (
      <div className="flex flex-col items-center justify-center mb-16">
        {/* <Fade clear duration={300}> */}
        <img className="w-[20rem] h-[20rem]" src={approved} alt="approved" />
        <img className="w-[3rem] h-[3rem]" src={iccheck} alt="approved check" />
        <p className="text-main-black text-base text-center font-semibold mt-4 mb-4">
          Principal Approval Complete
        </p>
        <Button
          text="Next Stage"
          onClick={() => {
            handlePrincipalComplete();
          }}
          leftIcon={icchevron}
          customStyle={{ paddingLeft: '3rem', paddingRight: '1.875rem' }}
        />
        {/* </Fade> */}
      </div>
    );
  }

  if (isApproval) {
    return (
      <div className="flex flex-col items-center mb-16">
        <img src={waiting} alt="approved" />
        <p className="text-main-black text-base font-semibold mt-4 mb-4">
          Waiting for Principal Approval
        </p>
        <div className="flex ">
          <Button
            text="Reschedule"
            onClick={() => {
              if (isCompletedOrClosed) {
                handleNextStage()
                return
              }
              setIsApproval(false);
              setIsMeeting(true);
            }}
            customStyle={{
              paddingLeft: '2.5rem',
              paddingRight: '2.5rem',
              marginRight: '1.5rem',
            }}
          />

          <Button
            text="Principal Approval"
            onClick={() => {
              // console.log('send principal approval');
              // handleSchedule({ isNextStage: true });
              // handleSendApproval();
              handlePrincipalComplete()
            }}
            customStyle={{ paddingLeft: '2.5rem', paddingRight: '2.5rem' }}
          />
        </div>
      </div>
    );
  }

  if (isMeeting) {
    return (
      <div className="flex flex-col items-center mt-5 relative">
        <div className="w-full 2xl:w-[48rem]">
          <div className='w-fit m-auto'>
            <p className="font-semibold text-main-black text-base">Schedule Test Date</p>
            <div className="exam-card" style={{ minHeight: '29.375rem' }}>
              <div
                className="flex flex-col items-center mt-6 2xl:pt-8 2xl:border-b-0 border-b m-auto"
                style={{ width: '20.375rem', height: '25rem' }}
              >
                <p className="flex text-sm items-center">
                  <img src={calendar} alt="calendar" className="mr-3" />
                  Select Date From Below
                </p>
                <StaticPicker
                  pValue={meetingDate && meetingDate.toString()}
                  psetValue={setMeetingDate}
                />
              </div>
              <div
                className="flex flex-col items-center mt-10 2xl:mt-8  2xl:pt-6  2xl:ml-0 ml-8 mr-10"
                style={{ width: '24.375rem', height: '25rem' }}
              >
                <p className="flex text-sm items-center">
                  <img src={clock} alt="calendar" className="mr-3" />
                  Select time slot
                </p>
                <ButtonDatePicker listTime={listTime} time={time} setTime={setTime} />
              </div>
            </div>
          </div>
        </div>
        <div className="relative h-16 w-full">
          <Button
            text="Schedule"
            onClick={() => {
              // setIsApproval(true);
              handleSchedule();
            }}
            leftIcon={icchevron}
            customStyle={styles.button}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center mb-16">
      <img src={waiting} alt="approved" />
      <p className="text-main-black text-base font-semibold mt-4 mb-4">
        Waiting for Principal Approval
      </p>
      <Button
        text="Schedule a meeting"
        onClick={() => {
          if (isCompletedOrClosed) {
            handleNextStage()
            return
          }
          setIsMeeting(true)
        }}
        customStyle={{ paddingLeft: '2.5rem', paddingRight: '2.5rem' }}
      />
    </div>
  );
}
