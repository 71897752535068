import { memo, useCallback, useContext, useEffect, useState } from 'react';
import Pagination from '@stories/pagination/pagination';
import { checkErrorApiFetch } from '@utils/check-error/api-error';
import { rowsPerPageCount } from '@utils/storage';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_START } from '@utils/utils';
import { ToastMessageContext } from 'context/toast-context';
import { dataLinks } from 'entities/routes';
import { NavLinks } from '../Header/components';
import FormItem from './components/form-item';
import PopupCreate from './components/popup-create';
import clsx from 'clsx';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';

const Forms = memo((props) => {
  const { burger } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [listForm, setListForm] = useState([]);
  const [isAddForm, setIsAddForm] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const campus = useStorage((state) => state.currentCampus);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_START);
  const [totalObjectsFromApi, setTotalObjectsFromApi] = useState(0);

  const handleChangeRowsPerPage = (event) => {
    setSelectedRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const toggleOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    centralAdminApi.getListFormBuilder({ campusId: campus?.id, page: currentPage, pageSize: selectedRowsPerPage })
      .then((res) => {
        if (
          checkErrorApiFetch(
            res,
            setToastMessage,
            setIsShowToastMessage,
            'Get Forms Builder',
          )
        ) {
          setListForm(res.data.data.objects);
          setTotalObjectsFromApi(res.data.data.total);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      });
  }, [isAddForm, currentPage, selectedRowsPerPage, refresh, campus]);

  return (
    <>
      <div className={clsx('pr-20 mt-20 pl-0 transition-all-300', burger ? '1400px:pl-80' : '1400px:pl-40')}>
        <NavLinks urls={dataLinks.formLinks} />
        <div className="w-full flex mb-10 items-center justify-between">
          <h1 className="namepage">Forms</h1>
          <button
            className="bg-main-blue text-white p-2 pr-3.5 pl-3.5 rounded"
            onClick={toggleOpen}
          >
            Add Custom Form
          </button>
        </div>
        <div className="w-full">
          {listForm.map((item, index) => (
            <FormItem
              key={index}
              info={item}
              index={index}
              setListForm={setListForm}
              setRefresh={setRefresh}
            />
          ))}
        </div>
      </div>
      <Pagination
        customClass="fixed bottom-0 right-0 z-[3] pr-4"
        customStyle={{ height: '3rem' }}
        page={currentPage}
        rowsPerPage={selectedRowsPerPage}
        rowsPerPageCount={rowsPerPageCount}
        totalRows={totalObjectsFromApi}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
      />

      {isOpen && (
        <PopupCreate
          toggleOpen={toggleOpen}
          isOpen={isOpen}
          setIsAddForm={setIsAddForm}
        />
      )}
    </>
  );
});
Forms.displayName = 'Forms';
export default Forms;
