import { Chip } from '@stories/index';
import { useContext, useRef, useState } from 'react';
import Picker from '@emoji-mart/react';
import dataPicker from '@emoji-mart/data';
import { mainavatar } from '@static/image';
import { ToastMessageContext } from 'context/toast-context';
import DefaultAvatar from '@components/default-avatar';
import admissionApi from 'api/admission';
import useStorage from 'store/storage';

function SendEmailPopup(props) {
  const {
    onToggleAddEvent = () => { },
    parents = [],
    enquiryId = '',
    handleRefresh = () => { },
  } = props;

  const [action, setAction] = useState('');
  const [subjectValue, setSubjectValue] = useState('');
  const [emailContent, setEmailContent] = useState('');
  const [listEmailTemplate, setListEmailTemplate] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isOpenPopupListTemplate, setIsOpenPopupListTemplate] = useState(false);
  const refEnclose = useRef();
  const refMessage = useRef();
  const userLogin = useStorage((state) => state.auth);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campus = useStorage((state) => state.currentCampus);

  const handleChangeAction = (e) => {
    setAction((prev) => {
      if (prev === e) return '';
      return e;
    });
  };
  const handleEmojiSelect = (e) => {
    // let val = refMessage.current.value;
    // val += e.native;
    // refMessage.current.value = val;
  };
  const onSelect = (name, e) => {
    // setInfo(prev => ({ ...prev, [name]: e }))
  };
  const handleGetListTemplate = () => {
    if (isOpenPopupListTemplate) {
      setIsOpenPopupListTemplate(false);
      return;
    }
    if (campus?.isCentral) {
      setListEmailTemplate([]);
    } else {
      admissionApi.getListEmailTemplate({ campusId: campus?.isCentral ? undefined : campus?.id })
        .then((results) => {
          if (results?.data?.success) {
            setListEmailTemplate(results?.data?.data || []);
            setIsOpenPopupListTemplate(true);
          } else {
            setToastMessage({
              status: 'error',
              title: 'Get List Email Template Failed',
              message: results.data.message,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Get List Email Template Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Get List Email Template Error', error);
        });
    }
  };

  const handleSelectedTemplate = (e, template) => {
    e.stopPropagation();
    setSubjectValue(template?.subject || '');
    setEmailContent(template?.content?.replace(/<br\s*\/?>/gi, '\n') || '');
    setIsOpenPopupListTemplate(false);
  };

  const handleSubmit = () => {
    if (isDisabled) return;
    setIsDisabled(true);
    admissionApi.sendMail({
      to: parents?.map((dt) => dt?.parentUserId),
      enquiryId: enquiryId,
      subject: subjectValue,
      body: emailContent.replaceAll(/\n/g, '<br />'),
    })
      .then((results) => {
        if (results?.data?.success) {
          setSubjectValue('');
          setEmailContent('');
          handleRefresh();
        } else {
          setToastMessage({
            status: 'error',
            title: 'Send Email Failed',
            message: results.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Send Email Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Send Email Error', error);
      })
      .finally(() => {
        setIsDisabled(false);
      });
  };
  const formatOptionLabelVia = ({ value, label, symbol }) => {
    return (
      <div className="block">
        {/* <Chip
          key={value}
          label={label || ''}
          image={symbol}
          chipAvatarStyle={{ width: 18, height: 18 }}
          styleChip={'object-cover'}
          customLabelClass="text-xs font-medium text-[#212B36]"
        /> */}
        <Chip
          deleteChip={false}
          key={value}
          label={label}
          image={symbol || mainavatar}
          chipAvatarStyle={{ width: 18, height: 18 }}
          styleChip={'object-cover'}
          customStyle={{ padding: '0 !important', margin: '0', fontSize: '0.875rem' }}
        />
      </div>
    );
  };
  return (
    <div className="relative rounded-lg border-2 border-color-[#919EAB52] flex flex-col gap-5 bg-white">
      <div className="p-2.5 bg-discord-black flex items-center justify-between rounded-t-lg">
        <p className="text-xs leading-[22px] text-white">Compose Email</p>
        <p className="px-2.5 cursor-pointer" onClick={onToggleAddEvent}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              d="M7.90903 6L11.6046 2.30441C12.1318 1.77724 12.1318 0.922547 11.6046 0.395377C11.0774 -0.131792 10.2228 -0.131792 9.69559 0.395377L6 4.09097L2.30441 0.395377C1.77724 -0.131792 0.922547 -0.131792 0.395377 0.395377C-0.131792 0.922547 -0.131792 1.77724 0.395377 2.30441L4.09097 6L0.395377 9.69559C-0.131792 10.2228 -0.131792 11.0775 0.395377 11.6046C0.658962 11.8682 1.00442 12 1.34988 12C1.69534 12 2.04083 11.8682 2.30438 11.6046L6 7.90903L9.69559 11.6046C9.95917 11.8682 10.3046 12 10.6501 12C10.9956 12 11.341 11.8682 11.6046 11.6046C12.1318 11.0775 12.1318 10.2228 11.6046 9.69559L7.90903 6Z"
              fill="white"
            />
          </svg>
        </p>
      </div>
      <div className="px-2.5 flex flex-col gap-2.5">
        <div className="flex text-[#8C9094] items-center text-xs gap-2.5">
          <p className="mr-2">From</p>
          <div className="flex items-center justify-start gap-2.5 mr-5">
            <img
              className="block w-6 h-6 rounded-full"
              alt=""
              src={userLogin?.photoURL || mainavatar}
            />
            <div>
              <p className="text-main-black font-medium text-xs">
                {userLogin?.displayName}
              </p>
              <p className="text-second-gray font-medium text-xs">Admissions Head</p>
            </div>
          </div>
          <p className="mx-2">To:</p>
          <div className="flex items-center justiffy-start gap-2.5">
            {/* <SelectGroup
              isMulti={false}
              formatOptionLabel={formatOptionLabelVia}
              styles={whatsappChatVia}
              options={optionVia}
              value={optionVia}
              onChange={(e) => onSelect('to', e)}
            /> */}
            {parents?.map((item, idx) => (
              <Chip
                deleteChip={false}
                key={item?.parentUserId}
                label={item?.fullName}
                image={item?.photoURL}
                avatarElement={<DefaultAvatar title={item?.fullName} />}
                styleChip="contained"
                customLabelClass="pr-2.5"
                customClass={idx % 2 === 0 ? 'bg-[#454f5b]' : ''}
              />
            ))}
          </div>
        </div>
        <div className="w-full h-px bg-[#B0B6BE]"></div>
        <div className="flex items-center justify-start">
          <label htmlFor="subject" className="mr-1 shrink-0 text-second-gray text-xs">
            Subject
          </label>
          <input
            value={subjectValue}
            onChange={(e) => {
              setSubjectValue(e.target.value);
            }}
            id="subject"
            className="w-full px-1 border-none outline-none text-xs"
          />
        </div>
        <div className="w-full h-[0.5px] bg-[#B0B6BE]"></div>
      </div>
      <textarea
        value={emailContent}
        onChange={(e) => {
          setEmailContent(e.target.value);
        }}
        placeholder="Your Email"
        ref={refMessage}
        className="resize-none p-2.5 w-full h-[10rem] text-xs text-[#808284] focus:outline-none"
      />
      <div className="flex flex-col gap-2.5">
        <div className="w-full h-px bg-[#B0B6BE]"></div>
        <div className="py-2.5 px-1.5 flex items-center justify-between">
          <div
            onClick={handleGetListTemplate}
            className="select-none relative px-2.5 py-1.5 rounded-lg flex items-center justify-start gap-2.5 cursor-pointer border border-[#919EAB52] active:bg-slate-50"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.125 14.375L0 15.5V0.5L1.125 1.625L2.25 0.5L3.375 1.625L4.5 0.5L5.625 1.625L6.75 0.5L7.875 1.625L9 0.5L10.125 1.625L11.25 0.5L12.375 1.625L13.5 0.5V15.5L12.375 14.375L11.25 15.5L10.125 14.375L9 15.5L7.875 14.375L6.75 15.5L5.625 14.375L4.5 15.5L3.375 14.375L2.25 15.5L1.125 14.375ZM11.25 4.25V5.75H2.25V4.25H11.25ZM2.25 7.25V8.75H11.25V7.25H2.25ZM2.25 10.25V11.75H11.25V10.25H2.25Z"
                fill="black"
                fillOpacity="0.5"
              />
            </svg>
            <p className="text-discord-black text-xs font-medium leading-5">
              Select Template
            </p>
            {isOpenPopupListTemplate && (
              <div className="absolute bottom-10 left-0 w-[300px] bg-white single-mail flex flex-col max-h-[200px] overflow-auto">
                {(listEmailTemplate || []).map((dt) => {
                  return (
                    <p
                      onClick={(e) => handleSelectedTemplate(e, dt)}
                      className="hover:bg-slate-50 p-2"
                      key={dt?.id}
                    >
                      {dt?.name}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
          <div className="shrink-0">
            <div className="w-full flex justify-end h-[2rem] rounded-lg">
              <div className="flex items-center justify-around">
                {/* emoji */}
                <svg
                  className="block object-fill cursor-pointer mr-3"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    handleChangeAction('emoji');
                  }}
                >
                  <path
                    d="M18.364 5.636a9 9 0 0 1 0 12.728 9 9 0 1 1 0-12.728M9 9.5H8m7.5-.5v1"
                    stroke={action === 'emoji' ? '#27C59A' : '#8C9094'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.5 14.688S14.187 16 12 16c-2.188 0-3.5-1.312-3.5-1.312"
                    stroke={action === 'emoji' ? '#27C59A' : '#8C9094'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                {/* enclose */}
                <svg
                  className="cursor-pointer mr-3"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    handleChangeAction('enclose');
                    if (refEnclose) refEnclose.current.click();
                  }}
                >
                  <path
                    d="m17.478 14.962 2.192-2.192a4.554 4.554 0 0 0-6.44-6.44l-2.192 2.192m4.63 1.81-5.336 5.336m-1.81-4.63L6.33 13.23a4.554 4.554 0 0 0 6.44 6.44l2.192-2.192"
                    stroke={action === 'enclose' ? '#60a5fa' : '#8C9094'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                {/* audio */}
                <svg
                  className="cursor-pointer mr-3"
                  width="14"
                  height="20"
                  viewBox="0 0 14 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handleChangeAction('audio')}
                >
                  <path
                    d="M1.375 8.125A.625.625 0 0 1 2 8.75V10a5 5 0 1 0 10 0V8.75a.625.625 0 1 1 1.25 0V10a6.25 6.25 0 0 1-5.625 6.219v2.531h3.75a.624.624 0 1 1 0 1.25h-8.75a.625.625 0 1 1 0-1.25h3.75v-2.531A6.25 6.25 0 0 1 .75 10V8.75a.625.625 0 0 1 .625-.625Z"
                    fill={action === 'audio' ? '#B72136' : '#8C9094'}
                  />
                  <path
                    d="M9.5 10a2.5 2.5 0 0 1-5 0V3.75a2.5 2.5 0 1 1 5 0V10ZM7 0a3.75 3.75 0 0 0-3.75 3.75V10a3.75 3.75 0 0 0 7.5 0V3.75A3.75 3.75 0 0 0 7 0Z"
                    fill={action === 'audio' ? '#B72136' : '#8C9094'}
                  />
                </svg>

                {/* <img className="block object-fill cursor-pointer" src={tag} alt='tag' /> */}

                <button
                  disabled={isDisabled}
                  type="button"
                  className="text-xs text-white bg-black px-2.5 py-1 rounded h-[1.875rem] w-20 ml-1"
                  onClick={handleSubmit}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
        {action === 'emoji' && (
          <div className="absolute w-fit h-fit shadow overflow-hidden bottom-[4rem] left-2.5 h[5rem] rounded-lg">
            <Picker
              data={dataPicker}
              icons="outline"
              previewPosition="top"
              perLine={15}
              maxFrequentRows={5}
              onEmojiSelect={(e) => {
                handleEmojiSelect(e);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default SendEmailPopup;
