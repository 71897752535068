import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@stories/index';
import './style.scss';

const InputSearchUser = (props) => {
  const {
    value,
    label,
    options,
    showOption,
    error,
    customStyle,
    classGroup,
    exceptId,
    handleChangeText,
    handleChooseOption,
  } = props;
  const [isShow, setIsShow] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setIsShow);

  useEffect(() => {
    setIsShow(options.length !== 0);
  }, [options]);

  return (
    <div id="input-suggest-user">
      <Input
        disabled={false}
        customStyle={customStyle}
        classGroup={classGroup}
        type="text"
        label={label}
        value={value}
        error={error}
        onChange={(e) => {
          const val = e.target.value;
          handleChangeText(val);
        }}
      />
      {isShow && showOption && (
        <ul className="w-full h-[11rem] options" ref={wrapperRef}>
          {options.map((item) => {
            const { id } = item;
            return (
              <li key={id}>
                <OptionElement
                  exceptId={exceptId}
                  data={item}
                  handleClick={() => {
                    handleChooseOption(item);
                    setIsShow(false);
                  }}
                />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

InputSearchUser.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  handleChangeText: PropTypes.func,
  handleChooseOption: PropTypes.func,
  //   optionElementCustom: PropTypes.func,
};

InputSearchUser.defaultProps = {
  value: '',
  label: '',
  options: [],
  handleChangeText: () => { },
  handleChooseOption: () => { },
  //   optionElementCustom: () => {},
};
export default InputSearchUser;

const OptionElement = memo((props) => {
  const { data, exceptId, handleClick } = props;
  const { id, name, email, phoneNumber, photoURL } = data;

  return (
    <button
      disabled={exceptId?.current?.hasOwnProperty(id)}
      style={{ cursor: exceptId?.current?.hasOwnProperty(id) ? 'not-allowed' : 'pointer' }}
      className="w-full h-[2.5rem] flex items-center pl-[10px] text-left"
      onClick={() => {
        handleClick(data);
      }}
    >
      <img src={photoURL} alt="avatar" className="w-[2rem] aspect-square rounded-full" />
      <div className="ml-4 py-1">
        <p className="text-base">{name}</p>
        <section className="text-sm text-[#637381] flex item-center">
          <p className="mr-4">{phoneNumber}</p>
          <p>{email}</p>
        </section>
      </div>
    </button>
  );
});
OptionElement.displayName = 'OptionElement';

function useOutsideAlerter(ref, setIsShow) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsShow(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
