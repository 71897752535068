import React, { useState } from 'react'
import Zoom from 'react-reveal/Zoom'
import clsx from 'clsx'

import { todofill, women } from '../image'
import ButtonBottom from "@components/application-panel/sub-components/button-bottom";
import SingleTask from "@components/application-panel/sub-components/single-task";

const TasksPick = (props) => {
  const { visible } = props
  const [tasks, setTasks] = useState([1, 2, 3, 4, 5, 6])

  return (
    <Zoom bottom duration={700} when={visible}>
      <div className={clsx('windows-field h-full', visible ? 'pointer-events-auto' : 'pointer-events-none hidden')}>
        <div className='flex items-center justify-between pt-5 px-5'>
          <div className='flex item'>
            <div
              className='w-10 h-10 mr-3 flex items-center justify-center rounded-lg mb-7'
              style={{ background: '#D0F2FF' }}
            >
              <img src={todofill} alt='' />
            </div>
            <p className='font-semibold mt-2.5'>My Tasks</p>
          </div>
          <button
            className='bg-main-blue w-6 h-6 text-white text-xl flex items-center justify-center rounded-full mb-9'>
            +
          </button>
        </div>
        {tasks ? (
          <div className='w-full flex items-center flex-col overflow-y-auto px-5 pt-1' style={{ height: 'calc(100vh - 13rem)' }}>
            {[...tasks, ...tasks].map((item, index) => {
              return <SingleTask key={index} className={'mb-5'} />
            })}
          </div>
        ) : (
          <div className={'px-5'}>
            <p className='text-main-black text-base mb-12'>Upcoming</p>
            <div className='flex flex-col items-center justify-center'>
              <img src={women} className='mb-3' />
              <p className='text-base font-medium'>You’re all caught up on tasks.</p>
              <p className='text-sm'>👍🏻 Nice work.</p>
            </div>
          </div>
        )}
        <ButtonBottom titleButton={'Add Task'} onClick={() => {
        }} />

      </div>
    </Zoom>
  )
}

export default TasksPick

