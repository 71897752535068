import { useContext, useState } from 'react';
import { Button, Datepicker, Input } from '@stories/index';
import { checkErrorSingleApi } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { toggleButtonStyles } from '../../../../entities/select-style';
import taskApi from 'api/task';
import useStorage from 'store/storage';

function UpdateTaskDependency(props) {
  const {
    initialSelect,
    isSelect,
    setIsSelect,
    task,
    index,
    setRefresh,
    setIsUpdate = () => { },
    setPage = () => { },
  } = props;
  const [dependency, setDependency] = useState(task?.dependency);
  const [dueDateDependency, setDueDateDependency] = useState(task?.dueDateDependency ?? new Date());
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campus = useStorage((state) => state.currentCampus);

  const handleUpdateTaskDependency = () => {
    const data = {};
    if (!!dependency) {
      data.dependency = dependency;
    }
    if (!!dueDateDependency) {
      data.dueDateDependency = dueDateDependency;
    }
    if (Object.keys(dependency).length === 0) {
      setToastMessage({
        status: 'warning',
        title: 'Validation Rule',
        message: 'Please entered dependency or selected due date to update',
      });
      setIsShowToastMessage(true);
      return;
    }
    taskApi.updateTaskById({ campusId: campus?.isCentral ? undefined : campus?.id }, data, task.id)
      .then((res) => {
        if (
          checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, 'Update Task')
        ) {
          setIsSelect(initialSelect);
          setRefresh((prev) => ({
            ...prev,
            count: (prev.count || 0) + 1,
            index,
          }));
          setIsUpdate((prev) => !prev);
          setPage(0);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Update Task Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Update task error', error.message);
      });
  };

  return (
    <>
      {isSelect.dependency && (
        <div className="flex flex-col items-center absolute top-8 left-[-6rem] h-52 w-72 z-20 bg-[#fff] shadow-2xl rounded">
          <div className="mt-4 mb-8">
            <Input
              onChange={(e) => {
                setDependency(e.target.value);
              }}
              //   labelFix="top-[50%] custom"
              label="Dependency"
              name="dependency"
              type="text"
              value={dependency}
              customStyle={{
                width: '12.25rem',
                height: '2rem',
                // marginLeft: '1.75rem',
                fontSize: '0.675rem',
                borderRadius: '0.25rem',
              }}
            // error={error.statusLastName}
            />
          </div>
          <div className="w-[12.25rem] h-[1rem] ">
            <Datepicker
              label="Due on"
              disabled={false}
              pValue={
                dueDateDependency
                  ? new Date(dueDateDependency).toUTCString()
                  : new Date().toUTCString()
              }
              psetValue={(value) => {
                setDueDateDependency(value);
              }}
            />
          </div>
          <div className="flex justify-center mt-12">
            <Button
              className="text-[0.8rem]"
              text="Cancel"
              customStyle={{
                ...toggleButtonStyles.btnWhite,
                width: '7rem',
                height: '1.5rem',
              }}
              onClick={() => {
                setIsSelect(initialSelect);
                // setEnteredTaskName('');
              }}
            />
            <Button
              className="text-[0.8rem]"
              text="Save"
              customStyle={{
                ...toggleButtonStyles.btnBlue,
                width: '7rem',
                height: '1.5rem',
              }}
              onClick={() => {
                handleUpdateTaskDependency();
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default UpdateTaskDependency;
