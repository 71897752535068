import React, { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../../../stories';
import { HeaderField, TextFieldCard } from '../subcomponents';
import clsx from 'clsx';

export default function PersonalInformation(props) {
  const { burger, data, setEdit, listField, isDisableEditApplication, hasAdmissionResult } =
    props;
  const { studentInfo, schoolInfo } = data;
  const [active, setActive] = useState();
  const [info, setInfo] = useState({});

  const { informationTypes, mapShowInfo } = useMemo(() => {
    const mapShowInfo = {};
    const types = [];
    const person = 'Personal Details';
    types.push(person);
    mapShowInfo[person] = <ShowInfo listField={listField.student} info={studentInfo} />;
    if (schoolInfo) {
      schoolInfo?.forEach((school, index) => {
        if (Object.keys(school).length !== 0) {
          const alias = `School History ${index + 1}`;
          types.push(alias);
          mapShowInfo[alias] = <ShowInfo listField={listField.school} info={school} />;
        }
      });
    }

    setActive(types && types[0]);
    setInfo(studentInfo);
    return { mapShowInfo, informationTypes: types };
  }, [listField.school, listField.student, schoolInfo, studentInfo]);

  const changeActive = useCallback(
    (type, index) => {
      setActive(type);
      if (type === 'Personal Details') {
        setInfo(studentInfo);
      }
      // else {
      //   setInfo(schoolInfo[index - 1]);
      // }
    },
    [schoolInfo, studentInfo],
  );

  return (
    <div className={clsx('information-field_personal h-fit pb-4', burger && '1599px:w-[60.188rem]')}>
      <div className="w-full flex items-center justify-between mb-2 pt-5 px-10">
        <p className="font-bold text-main-black text-base">Student Information</p>
        <Button
          onClick={() => setEdit(true)}
          text="Edit Application"
          buttonSize="small"
          buttonStyle="outlined"
          buttonDisabled={isDisableEditApplication}
        />
      </div>
      <HeaderField
        types={informationTypes}
        active={active}
        changeActive={changeActive}
        className="mb-10"
      />
      {mapShowInfo[active]}
    </div>
  );
}

PersonalInformation.propTypes = {
  data: PropTypes.object,
  setEdit: PropTypes.func,
};

const ShowInfo = memo((props) => {
  const { listField, info } = props;
  return (
    <div className="grid grid-cols-4 pl-[5rem] pr-8 gap-x-8">
      {listField.map((item) => {
        const { label, name, type } = item;
        //  const value = info[name]?.label || info[name]
        let value = "";
        if (name === "year") {
          const academicYear = info[name]?.label || info[name];
          const startDate = academicYear.slice(0, 4);
          const endDate = Number(startDate) + 1
          value = `${startDate}-${endDate} ${academicYear.slice(9)}`;
        } else {
          value = info[name]?.label || info[name]
        }
        return <ShowField key={name} label={label} value={value} showType={type} />;
      })}
    </div>
  );
});
ShowInfo.displayName = 'ShowInfo';

const ShowField = memo((props) => {
  const { label, value, showType } = props;

  let val = value?.label || value || '';
  if (showType === 'date') {
    if (value) {
      const temp = new Date(value);
      const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(temp);
      const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(temp);
      const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(temp);
      val = `${day} ${month}, ${year}`;
    }
  }

  return <TextFieldCard title={label} body={val} />;
});
ShowField.displayName = 'ShowField';
