import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BloomLinks from '../../../components/bloomingdale/links/links';
import { ToastMessageContext } from '../../../context/toast-context';
import { Button } from '../../../stories';
import {
  checkErrorApiFetch,
  checkErrorSingleApi,
} from '../../../utils/check-error/api-error';
import useDebounce from '../../../utils/custom-hook/useDebounce';
import { validEmail, validPhone } from '../../../utils/validate';
import { backarrow } from '../image';
import ContentGroup from '@components/admission-module/application/information/subcomponents/content-group/content-group';
import './form.scss';
import admissionApi from 'api/admission';
import useStorage from 'store/storage';
import authApi from 'api/auth';
import centralAdminApi from 'api/central-admin';

export default function BloomForm() {
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [listCampus, setListCampus] = useState([]);
  const [optionProgramsAndClasses, setOptionProgramsAndClasses] = useState([]);
  const [listYear, setListYear] = useState([]);
  const [error, setError] = useState({});
  const [imgPreview, setImgPreview] = useState(false);
  const [formData, setFormData] = useState(new FormData());
  const history = useHistory();
  const auth = useStorage(state => state.auth);
  const parentName = auth?.displayName;
  const [enquiryInfo, setEnquiryInfo] = useState({ parentName });

  const initState = {
    enquiry: {
      avatar: '',
      firstName: '',
      lastName: '',
      grade: '',
      year: '',
      campus: '',
      phoneNumber: '',
      email: '',
      parentName: parentName,
      filePhoto: '',
    },
  };

  const enquiryField = useMemo(
    () => [
      { label: 'Students First Name', type: 'input', name: 'firstName' },
      { label: 'Students Last Name', type: 'input', name: 'lastName' },
      { label: 'Campus', name: 'campus', type: 'button', data: listCampus },
      {
        label: 'Enrollment Year',
        name: 'year',
        type: 'select',
        data: listYear,
        isMulti: false,
      },
      {
        label: 'Anticipated Grade of Entry',
        name: 'programGrade',
        type: 'new-select',
        data: optionProgramsAndClasses,
        isMulti: false,
      },
      {
        label: 'Parent/ Guardian Name',
        type: 'input',
        name: 'parentName',
        disabled: true,
      },
      { label: 'Phone Number', type: 'input', name: 'phoneNumber' },
      { label: 'Email', type: 'input', name: 'email' },
    ],
    [listCampus, optionProgramsAndClasses, listYear],
  );

  const fetchData = useCallback(async () => {
    try {
      // const res = await callApi({
      //   method: 'get',
      //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/campus/user`,
      // });
      // const res = await admissionApi.getListCampusOfUser()
      const res = await authApi.getListCampus();
      if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage)) {
        const tmp = res.data.data.objects.map(({ id, name }) => ({
          value: id,
          label: name,
        }));
        setListCampus(tmp);
      } else throw res;
    } catch (error) {
      setToastMessage({
        status: 'error',
        title: 'Fetch Data Failed',
        message: error?.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
      console.log('Enquiry Error', error);
    }
  }, [setIsShowToastMessage, setToastMessage]);

  const fetchProgramsAndClasses = async (campusId, academicYearName) => {
    try {
      // const listProgramsAndClassesCallApi = await callApi({
      //   method: 'get',
      //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/grade/list-programs-and-classes?campusId=${campusId}&academicYearName=${academicYearName}`,
      // });
      // const listProgramsAndClassesCallApi = admissionApi.getListClass({ campusId, academicYearName })
      const res = await centralAdminApi.getListProgramAndClass(campusId, enquiryInfo.year.value)
      if (
        checkErrorApiFetch(
          res,
          setToastMessage,
          setIsShowToastMessage,
        )
      ) {
        const listProgramsAndClasses = res.data.data.map(
          (data) => {
            const listClasses = [];
            (data?.programGrade || []).forEach((programGrade) => {
              if (programGrade?.class) {
                listClasses.push({
                  value: programGrade.id,
                  label: `${programGrade.class.name} (${programGrade.grade?.name})`,
                });
              }
            });
            return {
              value: data.id,
              label: data.name,
              childrens: listClasses,
            };
          },
        );
        listProgramsAndClasses?.length &&
          setOptionProgramsAndClasses(listProgramsAndClasses);
      } else throw res;
    } catch (error) {
      setToastMessage({
        status: 'error',
        title: 'Fetch Grade Failed',
        message: error?.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
      console.log('Enquiry Error', error);
    }
  };

  const fetchYear = useCallback(
    async (campusId) => {
      try {
        // const apiYear = await callApi({
        //   method: 'get',
        //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/academic/list?campusId=${campusId}`,
        // });
        // const apiYear = await admissionApi.getListAcademicYear({ campusId })
        const apiYear = await centralAdminApi.getListAcademicYear({ campusId: campusId })
        if (checkErrorApiFetch(apiYear, setToastMessage, setIsShowToastMessage)) {
          const listYear = apiYear.data.data.objects.map((academicYear) => ({
            value: academicYear.name,
            label: academicYear.name,
          }));
          setListYear(listYear);
        } else throw apiYear;
      } catch (error) {
        setToastMessage({
          status: 'error',
          title: 'Get List Academic Year Failed',
          message: error?.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Get List Academic Year Error', error);
      }
    },
    [setIsShowToastMessage, setToastMessage],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (enquiryInfo?.campus?.value) fetchYear(enquiryInfo.campus.value);
  }, [enquiryInfo?.campus?.value, fetchYear]);

  useEffect(() => {
    if (enquiryInfo?.year?.value && enquiryInfo?.campus?.value) {
      fetchProgramsAndClasses(enquiryInfo?.campus?.value, enquiryInfo?.year?.value);
    }
  }, [enquiryInfo?.year?.value, enquiryInfo?.campus?.value]);

  const debouncePhone = useDebounce((text) => {
    setError((prev) => ({
      ...prev,
      phoneNumber: !validPhone(text),
    }));
  });

  const debounceEmail = useDebounce((text) => {
    setError((prev) => ({
      ...prev,
      email: !validEmail(text),
    }));
  });

  const handleChangeEnquiryInfo = useCallback(
    ({ key, value }) => {
      if (key === 'campus') {
        setEnquiryInfo((prev) => ({
          ...prev,
          year: null,
          programGrade: null,
          [key]: value,
        }));
      } else {
        setEnquiryInfo((prev) => ({
          ...prev,
          [key]: value,
        }));
      }
      if (key === 'email') debounceEmail(value);
      if (key === 'phoneNumber') debouncePhone(value);
    },
    [debounceEmail, debouncePhone],
  );

  const changeImageHandler = (e) => {
    let selected = e.target.files;
    if (selected && selected[0]) {
      let file = selected[0];
      formData.set('file', file);
      formData.set('mediaId', file.name);
      setFormData(formData);
      setEnquiryInfo((prev) => ({ ...prev, filePhoto: file }));
      let reader = new FileReader();
      reader.onload = (e) => {
        setImgPreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCancel = useCallback(() => {
    setEnquiryInfo(initState.enquiry);
    setOptionProgramsAndClasses([]);
    setListYear([]);
    setError({});
    setImgPreview(false);
  }, [initState.enquiry]);

  const handleSave = useCallback(async () => {
    try {
      if (enquiryInfo.filePhoto) {
        // const res = await callApi({
        //   method: 'post',
        //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/media`,
        //   data: formData,
        //   contentType: 'multipart/form-data',
        // });
        const res = await admissionApi.uploadMedia(formData)
        if (res.data.success) {
          delete enquiryInfo.filePhoto;
          enquiryInfo.avatar = res.data.data;
        } else throw res;
      }
      const { firstName, lastName, email, phoneNumber, avatar, programGrade, year, campus } =
        enquiryInfo;
      const data = {
        info: {
          firstName,
          lastName,
          email,
          phoneNumber,
          avatar,
        },
        parent: {
          email: auth?.auth?.currentUser?.email,
          fullName: parentName,
          id: auth?.auth?.currentUser?.uid,
          phoneNumber: enquiryInfo.phoneNumber,
        },
        campusId: campus.value,
        programGradeId: programGrade.value,
        academicYear: year.value,
      };
      // const res = await callApi({
      //   method: 'post',
      //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/enquiry`,
      //   data,
      //   params: { campusId: enquiryInfo?.campus?.value },
      // });
      const res = await admissionApi.createNewEnquiry({ campusId: enquiryInfo?.campus?.value }, data)
      if (checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage)) {
        handleCancel();
      } else throw res;
    } catch (error) {
      setToastMessage({
        status: 'error',
        title: 'Submit Enquiry Failed',
        message: error?.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
      console.log('Enquiry Error', error);
    }
  }, [enquiryInfo, formData, handleCancel, setIsShowToastMessage, setToastMessage]);

  return (
    <div className="w-[99vw] flex items-start mt-24 pl-20 pr-20">
      <button className="w-6 h-8 none-after cursor-pointer mr-20 mt-5">
        <img
          src={backarrow}
          className="w-4 h-4"
          alt="arrow"
          onClick={() => history.goBack()}
        />
      </button>
      <div className="flex w-full justify-between">
        <div className="mt-5 w-[45%]">
          <h1 className="font-semibold text-lg mb-10" style={{ color: '#2b2c34' }}>
            Enquiry Form
          </h1>
          <div className="mb-4 pl-3">
            <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
              Photo
            </p>
            <div className="w-16">
              <label htmlFor="image-upload" className="cursor-pointer">
                {imgPreview ? (
                  <img
                    className="w-16 h-16 rounded-full border border-main-blue border-dashed"
                    src={imgPreview}
                    alt="uploaded"
                  />
                ) : (
                  <div className="flex items-center justify-center w-16 h-16 border border-main-blue border-dashed rounded-full text-main-blue text-xl">
                    <p className="bg-main-blue rounded-full text-white w-4 flex items-center justify-center h-4 text-sm">
                      +
                    </p>
                  </div>
                )}
              </label>
              <input
                type="file"
                id="image-upload"
                name="image-upload"
                className="hidden"
                accept="image/*"
                onChange={changeImageHandler}
              />
            </div>
          </div>
          {enquiryField.map((item, key) => (
            <ContentGroup
              key={key}
              data={item}
              variation="parent"
              handleChange={(e) => {
                handleChangeEnquiryInfo({ key: item.name, value: e });
              }}
              info={enquiryInfo[item.name]}
              error={error[item.name]}
            />
          ))}
          <div className="w-full flex justify-between pb-4">
            <Button
              text="Cancel"
              buttonStyle="outlined"
              className="w-[45%] block rounded-none"
              onClick={() => {
                handleCancel();
              }}
            />
            <Button
              text="Submit"
              buttonStyle="contained"
              className="w-[45%] block rounded-none"
              onClick={() => {
                handleSave();
              }}
            />
          </div>
        </div>
        <BloomLinks />
      </div>
    </div>
  );
}
