import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { componentMap } from './components/components/index';
import './style.scss';
import {
  categoryMap,
  componentCustom,
  componentSpecial,
  componentFieldName,
  componentSpecialName,
  permissionMap,
} from '../utils';
import FormPageBreak from '../components/form-PageBreak';
import FormComponentUser from '../components/FormComponentUser';
import FormDrag from '../components/form-drag';
import FormParagraph from '../components/form-Paragraph';
import { close_x_red } from '@static/image';

export default function BuildForm(props) {
  const {
    order,
    dataUi,
    focus,
    setListForm,
    setInfoFocus,
    setEleFocus,
    setRefreshProperty,
    countComponent,
    refresh,
    refreshError,
    setRefresh,
    isBuild,
    permission,
    onRemoveSection,
  } = props;
  const signature = useRef('component');
  const [renderUi, setRenderUi] = useState([]); // list component render
  const refCount = useRef(countComponent || 0);

  const handleDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleFocus = useCallback(
    (order, index) => {
      setEleFocus({ order, index });
      const itemFocus = dataUi[index];
      const { traits, styles } = itemFocus;

      setInfoFocus((prev) => ({
        ...prev,
        order,
        traits,
        styles,
      }));

      setRefresh((prev) => ({ ...prev, [order]: prev[order] + 1 }));
      setRefreshProperty((prev) => prev + 1);
    },
    [dataUi, setEleFocus, setInfoFocus, setRefresh, setRefreshProperty],
  );

  const handleDelete = useCallback(
    (order, index) => {
      setListForm((prev) => {
        prev[order][index].deleted = true;
        return prev;
      });

      setRefresh((prev) => ({
        ...prev,
        [order]: prev[order] + 1,
      }));
    },
    [setListForm, setRefresh],
  );

  const traitInitComponent = useMemo(
    () => [
      {
        name: 'handleDelete',
        value: handleDelete,
        modifiable: false,
      },
      {
        name: 'handleFocus',
        value: handleFocus,
        modifiable: false,
      },
    ],
    [handleDelete, handleFocus],
  );

  const addTrait = useCallback((objData, traitInit) => {
    const tmp = JSON.parse(JSON.stringify(objData));
    tmp.traits = [...tmp.traits, ...traitInit];
    return tmp;
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      const name = e.dataTransfer.getData(signature.current);
      if (name) {
        // add new component
        const component = addTrait(componentMap[name](permission), traitInitComponent);
        refCount.current += 1;
        component.id = refCount.current;
        component.order = order;
        const traitLabel = component.traits.find(({ label }) => label === 'Label');
        if (traitLabel && !traitLabel.value)
          traitLabel.value = `Label${refCount.current}`;

        const idParent = e.target.id;
        if (idParent === 'drag') {
          setListForm((prev) => {
            prev[order].push(component);
            return prev;
          });
          setRefresh((prev) => ({
            ...prev,
            [order]: prev[order] + 1,
          }));
          // reflectRef.current =
          // setReflect((prev) => {
          //   return [...prev, component];
          // });
          // if (idParent == 'add-group') {
          // } else if (idParent == 'drag') {
          //   setReflect((prev) => {
          //     return [...prev, component];
          //   });
          // setRefresh((prev) => prev + 1);
        }
      } else {
        // move component
        // let oldIndex = refOldIndex.current * 1;
        // const newIndex = refMoveIndex.current * 1;
        // if (oldIndex > newIndex) {
        //   oldIndex += 1;
        // }
        // reflectRef.current[newIndex] = { ...reflectRef.current[oldIndex] };
        // reflectRef.current[oldIndex].deleted = true;
        // setRefresh((prev) => prev + 1);
      }
    },
    [addTrait, order, permission, setListForm, setRefresh, traitInitComponent],
  );

  const findById = useCallback((data, id) => {
    for (const item of data) {
      if (item) {
        const { id: itemId, children } = item;
        if (itemId === id) return item;

        if (children) {
          if (children.length !== 0) return findById(children, id);
        }
      }
    }
  }, []);

  const handleRender = useCallback(
    (data) => {
      return data.map((item, index) => {
        const {
          id,
          order,
          permission,
          category,
          name,
          traits,
          styles,
          deleted,
          cascadeValue,
          choiceSkill,
          error,
          textForError,
        } = item;
        if (deleted) return null;
        // if (name === componentFieldName.pageBreak) return <FormPageBreak key={id} />;
        let attributes = {};
        attributes.formStyle = styles;
        if (name === componentFieldName.drag) {
          refCount.current += 1;
          return <FormDrag key={refCount.current} {...attributes} />;
        }

        attributes.id = id;
        attributes.key = id;
        attributes.order = order;
        attributes.isFocus = index === focus;
        attributes.index = index;
        attributes.deletable = permission !== permissionMap.admin;
        attributes.isEdit = true;
        attributes.isBuild = isBuild;
        attributes.error = error;
        attributes.textForError = textForError;

        if (traits.length !== 0) {
          traits.forEach((trait) => {
            const { name, value, defaultValue } = trait;
            attributes[name] = value || defaultValue;
          });
        }

        if (!attributes.handleFocus) {
          attributes.handleFocus = handleFocus;
          attributes.handleDelete = handleDelete;
          // attributes.handleDragStart = handleDragStartComponent;
          // attributes.handleDragOver = handleDragOverComponent;
        }

        if (category === categoryMap.field) {
          if (name === componentFieldName.pageBreak)
            return <FormPageBreak {...attributes} />;
          if (name === componentFieldName.paragraph)
            return <FormParagraph {...attributes} />;
          return <FormComponentUser component={name} {...attributes} />;
        } else if (category === categoryMap.custom) {
          return componentCustom[name](attributes);
        } else if (category === categoryMap.special) {
          if (name === componentSpecialName.enrollmentYear) {
            attributes.cascadeValue = cascadeValue;
          }
          if (name === componentSpecialName.languageCard) {
            attributes.choiceSkill = choiceSkill;
          }
          return componentSpecial[name](attributes);
        }
      });
    },
    [focus, handleDelete, handleFocus, isBuild],
  );

  useEffect(() => {
    if (dataUi.length !== 0) setRenderUi(handleRender(dataUi));
  }, [refresh, dataUi, refreshError, handleRender]);

  return (
    <div className="mt-8 relative">
      <div className="w-full h-full flex">
        <div className="flex p-4 w-full" style={{
          borderRadius: '8px',
          boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.24), 0px 16px 32px -4px rgba(145, 158, 171, 0.24)'
        }}>
          <div
            id="drag"
            className="flex flex-wrap w-full min-h-[10rem]"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            {renderUi}
          </div>
        </div>
      </div>

      <button className='bg-white w-5 h-5 absolute right-0 top-0 p-1'
        onClick={onRemoveSection}>
        <img src={close_x_red} />
      </button>
    </div>
  );
}
