import moment from 'moment';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastMessageContext } from '../../../../context/toast-context';
import { rowsPerPageCount } from '../../../../utils/storage';
import { boxArrow, staticsize } from '../../../../static/image';
import { Button, Checkbox } from '../../../../stories';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_START } from '../../../../utils/utils';
import ActionDropdownMenu from '../../../campus-list/components/campus-list/actiondropdownmenu';
import Pagination from '../../../../stories/pagination/pagination';
import authApi from 'api/auth';
import useStorage from 'store/storage';
import { urls } from 'entities/urls';
import { checkErrorApiFetch } from '@utils/check-error/api-error';

export default function Campuses(props) {
  const { state } = props;
  const [loading, setLoading] = useState(false);
  const [campuses, setCampuses] = useState([]);
  const [company] = useState(state);
  const isGiikiAdmin = useStorage((state) => state.isGiikiAdmin);
  const history = useHistory();
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_START);
  const [totalObjectsFromApi, setTotalObjectsFromApi] = useState(0);

  const handleChangeRowsPerPage = (event) => {
    setSelectedRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    const fetchCampuses = async () => {
      setLoading(true);
      const res = await authApi.getListCampus({
        companyId: company.id,
        page: currentPage,
        pageSize: selectedRowsPerPage,
      });
      if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, false)) {
        setCampuses(res.data?.data?.objects);
        setTotalObjectsFromApi(res.data?.data?.total);
        setLoading(false);
      }
    };
    setLoading(true);
    authApi.getListCampus({
      companyId: company.id,
      page: currentPage,
      pageSize: selectedRowsPerPage,
    })
    fetchCampuses();
  }, [currentPage, selectedRowsPerPage]);

  const handleClickUsers = useCallback(
    (campus) => {
      history.push(`/central-admin/campus/users`, {
        selectCampus: campus,
      });
    },
    [currentPage, selectedRowsPerPage],
  );

  return (
    <>
      <div className="flex justify-between">
        <h1 className="font-bold text-2xl mb-10">{`Campuses (${campuses.length})`}</h1>
        <Button
          text="Create a Campus"
          customStyle={{ marginLeft: '1rem' }}
          onClick={() =>
            history.push(urls.giiki.institutions.company.campus.create, { companyId: company?.id || '' })
          }
        />
      </div>
      <table className="w-full">
        <thead>
          <tr className="font-medium text-xs">
            <th align="left" className="relative w-[5%]" style={{ left: 6 }}>
              <Checkbox />
            </th>
            <th align="left" className='w-[40%]'>Campus Name</th>
            <th align="left" className='w-[20%]'>Location</th>
            <th align="left" className='w-[15%]'>Created On</th>
            <th align="left">Status</th>
          </tr>
        </thead>
        <tbody>
          {campuses.map((campus, key) => (
            <React.Fragment key={key}>
              {/* <ReactTooltip
                id="main"
                place="top"
                type="dark"
                effect="float"
                multiline={true}
              /> */}
              <tr className="h-6" />
              <tr className="font-medium text-sm">
                <td>
                  <img
                    className="w-8 h-8 rounded-full"
                    src={campus?.logo || staticsize}
                    alt="campuses"
                  />
                </td>
                <td
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(isGiikiAdmin ? urls.giiki.institutions.company.campus.general : urls.central.setup.campus.general, {
                      selectCampus: campus,
                    });
                  }}
                >
                  {campus.name}
                </td>
                <td data-for="main" data-tip={campus.address} data-iscapture="true">
                  {campus.address}
                </td>
                <td>{moment(campus.dateCreated).format('DD MMMM, YYYY')}</td>
                <td
                  style={
                    campus.status === 'Active'
                      ? { color: '#3A8B93' }
                      : { color: '#6C0F00' }
                  }
                >
                  {campus.status === 'Active' ? 'Active' : 'Inactive'}
                </td>
                <td className="datatable-action h-fit" align="right">
                  <button
                    className="datatable-button"
                    onClick={() => { }}
                  >
                    <img className='ml-3' src={boxArrow} alt="button" />
                  </button>
                  <ActionDropdownMenu data={campus} handleClickUsers={handleClickUsers} />
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <div className="datatable-footer">
        <Pagination
          customClass="fixed bottom-0 right-0 z-[3] pr-4"
          customStyle={{ height: '3rem' }}
          page={currentPage}
          rowsPerPage={selectedRowsPerPage}
          rowsPerPageCount={rowsPerPageCount}
          totalRows={totalObjectsFromApi}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
        />
      </div>
    </>
  );
}
