import BlockSection from '@components/formbuilder/build/components/block-section';
import StyleSection from '@components/formbuilder/build/components/style-section';
import TraitSection from '@components/formbuilder/build/components/trait-section';
import { NavLinks } from '@pages/Header/components';
import { builder_wormies, globe_certificate_check_mark } from '@static/image';
import { Button } from '@stories/index';
import { checkErrorSingleApi } from '@utils/check-error/api-error';
import clsx from 'clsx';
import { ToastMessageContext } from 'context/toast-context';
import { dataLinks } from 'entities/routes';
import { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BuildForm from '../../components/formbuilder/build';
import { permissionMap } from '../../components/formbuilder/utils';
import './style.scss';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';

const FormBuilder = memo((props) => {
  const { burger } = props;
  const signature = useRef('component');

  const [section, setSection] = useState('Blocks');
  const [eleFocus, setEleFocus] = useState({});
  const [infoFocus, setInfoFocus] = useState({}); // info traits, style of the focusing component
  const [refresh, setRefresh] = useState({});
  const [refreshProperty, setRefreshProperty] = useState(0);
  const [listForm, setListForm] = useState({});
  const [listCountComponent, setListCountComponent] = useState([]);
  const [renderListForm, setRenderListForm] = useState();
  const [refreshError, setRefreshError] = useState(0);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [countForm, setCountForm] = useState(0);
  const history = useHistory();
  const formId = history.location.state?.info?.id;
  const campus = useStorage((state) => state.currentCampus);
  const campusId = campus?.id;

  const fetchData = () => {
    // callApi({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/form-builder/${formId}`,
    //   params: { campusId },
    // })
    centralAdminApi.getFormBuilderById({ campusId }, formId)
      .then((res) => {
        if (res.data.success) {
          const json = res.data.data.formContentString;
          if (json) {
            const objFormApi = JSON.parse(json);
            const tmpCount = {};
            const tmpUi = {};
            const tmpRefresh = {};
            let tmpCountForm = 0;
            Object.keys(objFormApi).forEach((key) => {
              tmpRefresh[key] = 0;
              if (key > tmpCountForm) tmpCountForm = key;
              const { dataUi, countComponent } = objFormApi[key];
              tmpCount[key] = countComponent;
              tmpUi[key] = dataUi;
            });
            setRefresh(tmpRefresh);
            setListForm(tmpUi);
            setListCountComponent(tmpCount);
            setCountForm(tmpCountForm + 1);
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Form Builder Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangeSection = useCallback((val) => {
    setSection(val);
  }, []);

  const handleDragStart = useCallback((e) => {
    e.dataTransfer.setData(signature.current, e.target.getAttribute(signature.current));
  }, []);

  const sectionMap = {
    Blocks: <BlockSection handleDragStart={handleDragStart} />,
    Traits: (
      <TraitSection
        data={infoFocus.traits}
        order={infoFocus.order}
        eleFocus={eleFocus}
        setListForm={setListForm}
        setRefresh={setRefresh}
        refreshProperty={refreshProperty}
      />
    ),
    Styles: (
      <StyleSection
        data={infoFocus.styles}
        order={infoFocus.order}
        eleFocus={eleFocus}
        setListForm={setListForm}
        setRefresh={setRefresh}
        refreshProperty={refreshProperty}
      />
    ),
  };

  const handleCancel = useCallback(() => {
    console.log('handleCancel');
  }, []);

  const getExtraData = (data) => {
    let isValid = true;
    let countComponent = 0;
    const dataUi = [];
    const validationRule = [];
    const info = [];
    const tmpLabelMap = {};

    for (let i = 0; i < data.length; i++) {
      const { id, name, traits, variationOf, deleted } = data[i];
      if (id > countComponent) countComponent = id;
      const tmpDataUi = data[i];
      if (!deleted) {
        dataUi.push(tmpDataUi);
        if (traits) {
          const newTraits = [];
          let isRequired = false;
          let label = '';
          let value = '';
          let validate = '';
          // eslint-disable-next-line no-loop-func
          traits.forEach((item) => {
            if (item.modifiable) {
              newTraits.push(item);
              if (['label', 'title'].includes(item.name)) {
                label = item.value;
                if (tmpLabelMap.hasOwnProperty(label)) {
                  isValid = false;
                  data[i].error = true;
                  data[i].textForError = 'Labels are unique. Please check!';
                } else {
                  data[i].error = false;
                  data[i].textForError = '';
                }
                tmpLabelMap[label] = label;
              }
              if (item.name === 'value') value = item.value;
              if (item.name === 'isRequired') isRequired = item.value;
              if (item.name === 'validate') validate = item?.value?.value || '';
            }
          });

          tmpDataUi.traits = newTraits;
          validationRule.push({
            id,
            isRequired,
            validate,
          });
          info.push({
            id,
            label,
            value,
            componentName: name,
            variationOf,
          });
        } else {
          validationRule.push({});
          info.push({ id, componentName: name, variationOf });
        }
      }
    }

    return { isValid, dataUi, validationRule, info, countComponent };
  };

  const handleSave = () => {
    const dataForm = {};
    Object.keys(listForm).forEach((key) => {
      const { dataUi, validationRule, info, isValid, countComponent } = getExtraData(
        listForm[key],
      );
      setRefreshError((prev) => prev + 1);
      if (!isValid) {
        setToastMessage({
          status: 'warning',
          title: 'label',
          message: 'Label is unique. Please check!',
        });
        setIsShowToastMessage((prev) => !prev);
      }
      dataForm[key] = { dataUi, validationRule, info, countComponent };
    });

    // callApi({
    //   method: 'patch',
    //   url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/form-builder/${formId}`,
    //   params: { campusId },
    //   data: { formContentString: JSON.stringify(dataForm) },
    // })
    centralAdminApi.updateFormBuilder({ campusId }, { formContentString: JSON.stringify(dataForm) }, formId)
      .then((res) => {
        if (
          checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, 'Submit Form')
        ) {
        }
      })
      .catch((error) => {
        console.log('Submit Form', error);
        setToastMessage({
          status: 'error',
          title: 'Submit Form Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      });
  };

  const handleAddSection = () => {
    setListForm((prev) => {
      prev[countForm] = [];
      return prev;
    });
    setRefresh((prev) => ({ ...prev, [countForm]: 0 }));
    setCountForm((prev) => prev + 1);
  };

  const onRemoveSection = useCallback((order) => {
    setListForm(prev => {
      if (prev[order])
        delete prev[order]
      return { ...prev }
    })
  }, [])

  useEffect(() => {
    if (listForm && Object.keys(listForm).length !== 0) {
      const tmp = Object.keys(listForm).map((key) => {
        return (
          <BuildForm
            key={key}
            order={key}
            dataUi={listForm[key]}
            refresh={refresh[key]}
            refreshError={refreshError}
            focus={key === eleFocus.order ? eleFocus.index : undefined}
            setRefresh={setRefresh}
            setListForm={setListForm}
            setInfoFocus={setInfoFocus}
            setEleFocus={setEleFocus}
            setRefreshProperty={setRefreshProperty}
            countComponent={listCountComponent[key] || 0}
            permission={permissionMap.admin}
            onRemoveSection={() => onRemoveSection(key)}
            isEdit={false}
            isBuild={true}
          />
        );
      });
      setRenderListForm(tmp);
    }
  }, [eleFocus, listForm, refresh, refreshError]);

  return (
    <div className={clsx('pr-12 mt-20 pl-12 flex transition-all-300', burger ? '1400px:pl-72' : '1400px:pl-32')}>
      <div className="w-[75%] pr-6" style={{ borderRight: '1px solid rgba(145, 158, 171, 0.32)' }}>
        <NavLinks urls={dataLinks.formLinks} />
        <div className="w-full flex mb-4 items-center justify-between">
          <h1 className="namepage">Form Builder</h1>
        </div>
        <div className="w-full flex items-center justify-between mb-4">
          <p className="text-lg">Customize your Application form</p>
          <div className={clsx('flex items-center justify-between min-w-[416px]', burger ? 'w-[44%]' : 'w-[38%]')}>
            <Button
              text="Cancel"
              buttonSize='large'
              onClick={handleCancel}
              buttonStyle="outlined"
              customStyle={{ fontWeight: 500, padding: '0.75rem 4rem' }}
            />
            <Button
              text="Save"
              buttonSize='large'
              onClick={handleSave}
              buttonStyle="contained"
              customStyle={{ fontWeight: 500, padding: '0.75rem 4rem' }}
              icon={globe_certificate_check_mark}
            />
          </div>
        </div>
        <div className="flex flex-wrap flex-col min-h-[25rem] px-2">
          {renderListForm}
          <section className="m-auto text-center mt-12">
            {renderListForm?.length === 0 && <>
              <img className="mx-auto" src={builder_wormies} alt="symbol" />
              <small>Let the Creation Begin</small>
            </>

            }
            <Button
              text="Add Section"
              buttonSize='large'
              onClick={handleAddSection}
              buttonStyle="contained"
              customStyle={{ padding: '1rem 3rem' }}
            />
          </section>
        </div>
      </div>

      <div className="w-[25%] pl-2">
        <div className="px-3 bg-white w-full text-black border-solid border-black mt-16">
          <div className="w-full h-8 flex items-center">
            <button
              className={clsx('w-1/3 text-left rounded text-base font-semibold', section === 'Blocks' ? 'text-[#FFC107]' : 'text-[#637381]')}
              type="button"
              onClick={() => {
                handleChangeSection('Blocks');
              }}
            >
              Blocks
            </button>
            <button
              className={clsx('w-1/3 text-left rounded text-base font-semibold', section === 'Traits' ? 'text-[#FFC107]' : 'text-[#637381]')}
              type="button"
              onClick={() => {
                handleChangeSection('Traits');
              }}
            >
              Traits
            </button>
            <button
              className={clsx('w-1/3 text-left rounded text-base font-semibold', section === 'Styles' ? 'text-[#FFC107]' : 'text-[#637381]')}
              type="button"
              onClick={() => {
                handleChangeSection('Styles');
              }}
            >
              Styles
            </button>
          </div>
          <div className='mt-8 text-sm font-semibold text-[#637381]'>{sectionMap[section]}</div>
        </div>
      </div>
    </div>
  );
});
FormBuilder.displayName = 'FormBuilder';
export default FormBuilder;
