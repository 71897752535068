const SingleTime = (props) => {
  const { data, style, onClick } = props

  return (
    <button className={`p-2 font-medium flex items-center text-xs border border-[${style.borderColor}] rounded-xl h-[1.85rem] w-[6.3rem] bg-[${style.bg}] text-[${style.color}]`}
      onClick={() => onClick(data)}
    >
      <svg className="mr-3" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 4.297a3.19 3.19 0 0 0-3.2 3.201A3.19 3.19 0 0 0 7 10.697a3.189 3.189 0 0 0 3.199-3.199A3.19 3.19 0 0 0 7 4.297Zm0 5.25a2.05 2.05 0 1 1 0-4.1 2.05 2.05 0 0 1 0 4.1ZM12 2.5c-.312-.312-.883-.248-1.273.142-.39.391-.453.959-.141 1.272.312.313.882.25 1.273-.141.39-.39.453-.961.141-1.273Zm-9.858 8.729c-.391.39-.454.959-.142 1.271.312.312.882.25 1.273-.141.391-.391.454-.961.142-1.273-.312-.312-.883-.248-1.273.143ZM2 2.5c-.312.312-.249.883.141 1.273.391.391.961.453 1.273.141.312-.312.249-.883-.142-1.273-.389-.389-.96-.453-1.272-.141Zm8.727 9.857c.39.391.96.455 1.273.143.313-.312.249-.883-.142-1.274s-.96-.453-1.273-.141c-.313.312-.248.882.142 1.272ZM7 2.498c.441 0 .8-.447.8-1C7.799.945 7.441.5 7 .5c-.442 0-.801.445-.801.998 0 .553.358 1 .801 1ZM7 14.5c.441 0 .8-.447.8-1 0-.553-.358-.998-.799-.998-.442 0-.801.445-.801.998-.001.553.357 1 .8 1Zm-5-7c0-.441-.45-.8-1.003-.8-.553 0-.997.359-.997.8 0 .442.444.8.997.8C1.55 8.3 2 7.942 2 7.5Zm12 0c0-.441-.448-.8-1.001-.8-.553 0-.999.359-.999.8 0 .442.446.8.999.8.553 0 1.001-.358 1.001-.8Z"
          fill={style.fill} />
      </svg>
      {data}
    </button>
  )
}

export default SingleTime;