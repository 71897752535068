import { useContext, useEffect, useMemo, useState } from 'react';
import { Modal } from '@mui/material';
import DocumentItem from '@pages/admission-document-settings/components/document-item';
import { CompanyTabs } from '@stories/index';
import Pagination from '@stories/pagination/pagination';
import { checkErrorApiFetch } from '@utils/check-error/api-error';
import { rowsPerPageCount } from '@utils/storage';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_START } from '@utils/utils';
import { ToastMessageContext } from 'context/toast-context';
import { dataLinks } from 'entities/routes';
import { NavLinks } from '../Header/components/index';
import AddDocumentSettingModal from './components/add-document-setting-modal';
import './admission-document-settings.scss';
import clsx from 'clsx';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';

export default function AdmissionDocumentSettings({ burger }) {
  const [open, openModal] = useState(false);
  const [programsTab, setProgramsTab] = useState([]);
  const [programTabCurrent, setProgramTabCurrent] = useState();
  const [programs, setPrograms] = useState([]);
  const [documentSettings, setDocumentSettings] = useState();
  const [documentSettingsOrigin, setDocumentSettingsOrigin] = useState();
  const [refresh, setRefresh] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campus = useStorage((state) => state.currentCampus);;
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_START);
  const [totalObjectsFromApi, setTotalObjectsFromApi] = useState(0);

  const handleChangeRowsPerPage = (event) => {
    setSelectedRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const isOpen = () => {
    openModal(!open);
  };

  const getProgramFormApi = () => {
    centralAdminApi.getListProgram({ campusId: campus?.isCentral ? undefined : campus?.id, isGetAll: true })
      .then((res) => {
        if (
          checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, 'Get Programs')
        ) {
          const programs = res.data.data.objects;
          const tabData = programs.map((item, key) => {
            return item.name;
          });
          setProgramsTab(tabData);
          setProgramTabCurrent(tabData[0]);
          setPrograms(programs);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Admission Team Settings', error);
      });
  };

  useEffect(() => {
    getProgramFormApi();
  }, [campus]);

  const getProgramId = useMemo(() => {
    const program = programs.find((item) => item.name === programTabCurrent);
    return program?.id;
  }, [programTabCurrent]);

  useEffect(() => {
    if (!getProgramId) return;
    centralAdminApi.getListDocumentSetting({
      campusId: campus?.isCentral ? undefined : campus?.id,
      programId: getProgramId,
      page: currentPage,
      pageSize: selectedRowsPerPage,
    })
      .then((res) => {
        if (
          checkErrorApiFetch(
            res,
            setToastMessage,
            setIsShowToastMessage,
            'Get Document Settings',
          )
        ) {
          const result = res.data.data.objects.map((item) => {
            const multipleDocuments =
              item.maximumDocument >= 2 ? 'Allowed' : 'Not Allowed';
            const fileRequirement = JSON.parse(item.fileRequirement);
            return {
              ...item,
              multipleDocuments,
              fileRequirement,
            };
          });
          setTotalObjectsFromApi(res.data.data.total);
          setDocumentSettings(result);
          setDocumentSettingsOrigin(result);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Get Document Settings Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Get Document Settings', error);
      });
  }, [programTabCurrent, refresh, currentPage, selectedRowsPerPage]);

  return (
    <>
      <Modal open={open} onClose={isOpen}>
        <div>
          <AddDocumentSettingModal
            open={open}
            isOpen={isOpen}
            setDocumentSettings={setDocumentSettings}
          />
        </div>
      </Modal>
      <div className={clsx('settings pr-20 pl-20 transition-all-300', burger ? '1400px:pl-80' : '1400px:pl-40')}>
        <div>
          <NavLinks urls={dataLinks.admissionDocumentSettingsLinks} />
          <div className="settings__tittle">
            <div className="namepage">Admission Document Settings</div>
            <button
              className="bg-main-blue text-white py-[11px] px-[22px] rounded h-fit self-center"
              onClick={isOpen}
            >
              Add Document
            </button>
          </div>
          <div className="settings__tabs flex w-full mb-11 mt-6">
            <CompanyTabs
              types={programsTab}
              setTypeState={setProgramTabCurrent}
              typeState={programTabCurrent}
            />
          </div>
        </div>
        <div className="w-full h-[50vh]">
          {documentSettings?.map((item, index) => (
            <DocumentItem
              key={index}
              info={item}
              index={index}
              getProgramId={getProgramId}
              setListForm={setDocumentSettings}
              setRefresh={setRefresh}
            />
          ))}
        </div>
        <Pagination
          customClass="fixed bottom-0 right-0 z-[3] pr-4"
          customStyle={{ height: '3rem' }}
          page={currentPage}
          rowsPerPage={selectedRowsPerPage}
          rowsPerPageCount={rowsPerPageCount}
          totalRows={totalObjectsFromApi}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
        />
      </div>
    </>
  );
}
