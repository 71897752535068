import React, { memo, useEffect, useState } from 'react';
import LanguageInfo from './language-info/language-info';
import LearningInfo from './learning-need/learning-need';
import HealthInfo from './health-info/health-info';
import SchoolServices from './school-service/school-service';
import OtherQuestions from './other-question/other-question';
import { Button } from '@stories/index';
import GeneratePdf from './generate-pdf';
import admissionApi from 'api/admission';
import useStorage from 'store/storage';

const SecondStep = memo((props) => {
  const {
    info,
    listField,
    styles,
    handleChange,
    handleAdd,
    handleCancel,
    handleSave = () => { },
    handlePrev,
    handleRemove,
    isAddApplication,
    enquiry,
    handleRefreshEnquiry = () => { },
    setIsLoading = () => { }
  } = props;

  const [isGeneratePdf, setIsGeneratePdf] = useState(false);
  const [application, setApplication] = useState();
  const campus = useStorage((state) => state.currentCampus);

  useEffect(() => {
    const getData = async () => {
      try {
        if (!isAddApplication) return;
        const res = await admissionApi.getQuery({ campusId: campus?.id }, enquiry.id);
        if (res.data.success) {
          const admissionResult = JSON.parse(res.data.data?.admissionResult?.info || "{}");
          admissionResult?.application && setApplication(admissionResult.application);
          setIsGeneratePdf(true);
        } else throw new Error(res.data.message);
      } catch (error) {
        console.log('err', error);
      }
    }
    getData();
  }, [isAddApplication]);

  return (
    <div className="classForScroll">
      <div className="information-field w-full">
        <div style={{ borderRadius: '1rem 1rem 0rem 0rem', backgroundColor: '#F9FAFB' }}>
          <p className="font-bold text-2xl text-main-black p-4">Health Information</p>
        </div>
        <div className="information-field_application p-10">
          <HealthInfo
            info={info?.healthInfo}
            variation="admission"
            listField={listField.health}
            handleChange={handleChange}
          />
        </div>
      </div>

      <div style={{ borderRadius: '1rem 1rem 0rem 0rem', backgroundColor: '#F9FAFB' }}>
        <p className="font-bold text-2xl text-main-black p-4">Language Information</p>
      </div>
      <div id="language-info" className="information-field_application p-10 min-h-fit">
        <LanguageInfo
          info={info?.languageInfo}
          handleChange={handleChange}
          handleAdd={handleAdd}
          handleRemove={handleRemove}
        />
      </div>

      <div style={{ borderRadius: '1rem 1rem 0rem 0rem', backgroundColor: '#F9FAFB' }}>
        <p className="font-bold text-2xl text-main-black p-4">Learning Needs</p>
      </div>
      <div className="information-field_application min-h-fit p-10">
        <LearningInfo
          info={info?.learningInfo}
          listField={listField.learning}
          handleChange={handleChange}
        />
      </div>

      <div style={{ borderRadius: '1rem 1rem 0rem 0rem', backgroundColor: '#F9FAFB' }}>
        <p className="font-bold text-2xl text-main-black p-4">School Services</p>
      </div>
      <div className="information-field_application min-h-fit p-10">
        <SchoolServices
          info={info?.schoolServices}
          listField={listField.schoolServices}
          handleChange={handleChange}
        />
      </div>

      <div style={{ borderRadius: '1rem 1rem 0rem 0rem', backgroundColor: '#F9FAFB' }}>
        <p className="font-bold text-2xl text-main-black p-4">Other Questions</p>
      </div>
      <div className="information-field_application min-h-fit p-10 text-base">
        <OtherQuestions
          info={info?.otherQuestions}
          variation="admission"
          listField={listField.otherQuestions}
          handleChange={handleChange}
        />
      </div>

      <div className="flex justify-center">
        <Button text="Previous" customStyle={styles.btnWhite} onClick={handlePrev} />
        <Button
          text="Cancel"
          customStyle={{ ...styles.btnWhite, marginLeft: '2rem' }}
          onClick={handleCancel}
        />
        <Button text="Save and next" customStyle={styles.btnBlue} onClick={() => {
          handleSave();
        }} />
      </div>

      {isGeneratePdf && application && (
        <GeneratePdf addressInfo={
          application.additionalInfo?.addressInfo
            ? application.additionalInfo.addressInfo
            : []
        }
          applicationParent={application.applicationParent || []}
          studentInfo={application.info}
          applicationId={application.id}
          handleRefreshEnquiry={handleRefreshEnquiry}
          setIsLoading={setIsLoading}
        />
      )}
    </div>
  );
});
SecondStep.displayName = 'SecondStep';
export default SecondStep;
