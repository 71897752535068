import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { approved, draft, inactive, pending } from '@static/image';
import { FeeBookStatus } from 'entities/data';
import { urls } from 'entities/urls';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

export default function FeeBookCard({ title, date, variant, type, onClick, feeBookId }) {
  const history = useHistory();
  const location = useLocation()
  const imageVariant = () => {
    return (
      (variant === FeeBookStatus.APPROVED && approved) ||
      (variant === FeeBookStatus.APPROVAL_PENDING && pending) ||
      (variant === FeeBookStatus.DRAFT && draft) ||
      (variant === FeeBookStatus.INACTIVE && inactive)
    );
  };

  return (
    <div
      className="flex flex-col items-center"
      onClick={() =>
        type === 'book' &&
        history.push(
          location?.pathname === urls.central.finance.feebooks ?
            urls.central.finance.feebookEdit :
            urls.campus.finance.feebookEdit,
          { feeBookId: feeBookId })
      }
    >
      <div
        onClick={onClick}
        className={clsx(
          'feebooks-card',
          `feebooks-card-${type}`,
          variant &&
          `feebooks-card-${type}_${variant === FeeBookStatus.APPROVAL_PENDING
            ? 'pending'
            : variant.toLowerCase()
          }`,
        )}
      >
        <p className={clsx(type === 'button' && 'text-2xl')}>
          {type === 'book' ? title : '+'}
        </p>
        <p>{type === 'book' ? date : 'Add Year Book'}</p>
      </div>
      {variant && <img alt="" src={imageVariant()} className={clsx("mr-8 mb-10", variant === FeeBookStatus.INACTIVE ? 'w-[5.5rem]' : 'w-22')} />}
    </div>
  );
}

FeeBookCard.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  variant: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

FeeBookCard.defaultProps = {
  type: 'book',
  onClick: () => { },
};
