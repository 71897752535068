import React, { useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { phone, ellipsis } from '../../../../static/image'
import './appointments.scss'
import DefaultAvatar from '@components/default-avatar'
import convertDateToFormat from '@helper/convertDateToFormat'
import { APPOINTMENT_STATUS } from '@utils/constant'
import { Slide } from 'react-reveal'
import StatusPopup from './status-popup'
import CancelPopup from './cancel-popup';
import ReschedulePopup from './reshedule-popup';
import CallPopup from './call-popup';
import ActiveCall from '@components/application-panel/sub-components/active-call'
import knowlarity from 'api/knowlarity'
import DialogCallEnd from '@components/application-panel/sub-components/dialog-call-end'
import { CALL_RESULT_TYPE, EVENT_CALL_TYPE } from '@constant/index'
import { ToastMessageContext } from 'context/toast-context'
import { getStatus } from '@helper/application-panel'
import admissionCallApi from 'api/admission-call'
import { checkErrorApiFetch, checkErrorSingleApi } from '@utils/check-error/api-error'
import admissionApi from 'api/admission'

const status = ['Confirmed', 'Canceled', 'Call to confirm']
const statusRender = (status) => {
  const kebab =
    status &&
    status
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/\s+/g, '-')
      .toLowerCase()
  return (
    <small className={clsx('appointments-user__status', status && `appointments-user__status_${kebab}`)}>{status}</small>
  )
}

const popup = {
  status: 'status',
  reschedule: 'reschedule',
  listCall: 'listCall',
  cancel: 'cancel',
  calling: 'calling',
  callNote: 'callNote'
}

export default function AppointmentsUser(props) {
  const { data, setRefresh, scheduleByDate } = props
  const [enquiryInfo, setEnquiryInfo] = useState({})
  const [visible, setVisible] = useState('');
  const [personCalling, setPersonCalling] = useState()
  const [eventCall, setEventCall] = useState()
  const [parents, setParents] = useState([])
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const fetchEnquiryParent = async ({ enquiryId, signal }) => {
    const res = await admissionApi.getParentOfEnquiry({ enquiryId, signal })
    if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, '', false)) {
      setParents([{
        relationship: res?.data?.data?.relationship,
        fullName: res?.data?.data?.users?.name,
        parentUserId: res?.data?.data?.parentId,
        phoneNumber: res?.data?.data?.users?.phoneNumber,
        email: res?.data?.data?.users?.email,
        photoURL: '',
      }])
    }
  }

  useEffect(() => {
    let controller
    const admResult = JSON.parse(data?.enquiry?.admissionResult?.info || null)
    const applicationParent = admResult?.application?.applicationParent
    if (applicationParent) {
      setParents(applicationParent)
    } else {
      controller = new AbortController();
      if (data?.enquiry?.id) {
        fetchEnquiryParent({ enquiryId: data.enquiry.id, signal: controller.signal })
      } else
        setParents([])
    }
    return () => {
      if (controller) controller.abort();
    }
  }, [data])

  useEffect(() => {
    if (data?.enquiry?.info) {
      setEnquiryInfo(JSON.parse(data.enquiry.info))
    }
  }, [data])

  const avatar = enquiryInfo?.avatar
  const name = `${enquiryInfo?.firstName} ${enquiryInfo?.lastName}`

  const getDate = (date) => {
    if (!date) return ''
    date = new Date(date)
    const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1))
    if (convertDateToFormat(date, 'MM/DD/YYYY') === convertDateToFormat(new Date(), 'MM/DD/YYYY'))
      return 'Today'
    if (convertDateToFormat(date, 'MM/DD/YYYY') === convertDateToFormat(tomorrow, 'MM/DD/YYYY'))
      return "Tomorrow"
    return convertDateToFormat(date, 'll').replace(',', '')
  }

  const onToggleStatusPopup = () => {
    setVisible(prev => {
      if ([popup.calling, popup.callNote].includes(prev)) {
        return prev
      }
      if (prev === popup.status) return ''
      return popup.status
    })
  }

  const onOpenReschedulePopup = () => {
    setVisible(popup.reschedule)
  }

  const onOpenCancelPopup = () => {
    setVisible(popup.cancel)
  }

  const onToggleListCallPopup = () => {
    setVisible(prev => {
      if (![popup.calling, popup.callNote].includes(prev)) {
        if (prev) return ''
        return popup.listCall
      }
      return prev
    })
  }

  const onOpenNotePopup = () => {
    setVisible(popup.callNote)
  }

  const onOpenCallingPopup = () => {
    setVisible(popup.calling)
  }

  const onCloseCallingPopup = () => {
    setVisible('')
  }

  const onClosePopup = () => {
    setVisible(prev => {
      if (![popup.calling, popup.callNote].includes(prev)) return ''
      return prev
    })
  }

  const onPublishCallMissed = async ({ enquiryId, campusId, parentUserId, data }) => {
    try {
      const apiData = {
        enquiryId,
        parentId: parentUserId,
        status: getStatus(data?.business_call_type),
      }
      const res = await admissionCallApi.create({ campusId, data: apiData })
      if (checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, '', false)) {
        onCloseCallingPopup()
        setRefresh(prev => !prev)
        setToastMessage({
          status: 'info',
          title: 'Missed Call',
        });
        setIsShowToastMessage(true);
      } else {
        setToastMessage({
          status: 'error',
          title: 'Store History Failed',
        });
        setIsShowToastMessage(true);
      }
    } catch (error) {
      console.log('error', error)
      setToastMessage({
        status: 'error',
        title: 'Store History Failed',
        message: error.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
    }
  }

  function handleRealTimeData(e, call_id, source) {
    try {
      const data = JSON.parse(e.data)
      if (data.uuid === call_id) {
        const type = data.type
        switch (type) {
          // case EVENT_CALL_TYPE.AGENT_CALL:
          // case EVENT_CALL_TYPE.CUSTOMER_ANSWER:
          //   return setEventCall(data)
          case EVENT_CALL_TYPE.CDR:
            source.close()
            if (data.business_call_type === CALL_RESULT_TYPE.Phone) {
              onOpenNotePopup()
              return setEventCall(data)
            } else {
              onPublishCallMissed({
                enquiryId: data?.enquiry?.id,
                campusId: data?.campus?.id,
                parentUserId: personCalling?.parentUserId,
                data
              })
            }
            break
          default:
            break
        }
      }
    } catch (error) {
      console.log('getRealtimeData error', error)
      throw Error(error)
    }
  }

  const onClickCall = async (info) => {
    try {
      onOpenCallingPopup()
      setPersonCalling(info)
      const resListRegisterNotification = await knowlarity.listRegisterNotification()
      if (resListRegisterNotification?.status !== 200) return
      const isRegistered = resListRegisterNotification?.data?.objects?.some(item => item.k_number === process.env.REACT_APP_KNOWLAIRTY_SR_NUMBER)
      if (!isRegistered) {
        const resRegister = await knowlarity.registerNotification()
        if (resRegister.status !== 200 || !resRegister.data?.message !== 'Successfully registered.') return
      }
      const res = await knowlarity.makeACall(info.phoneNumber)
      if (res?.data?.success) {
        const call_id = res?.data?.success?.call_id
        const source = knowlarity.streaming()
        source.onmessage = e => handleRealTimeData(e, call_id, source);
        source.onerror = (e) => {
          source.close();
        }
      } else {
        onCloseCallingPopup()
        throw Error(res?.data?.error?.message || "Make Outbound Call Failed")
      }
    } catch (error) {
      console.log('onClickCall ', error)
      setToastMessage({
        status: 'error',
        title: 'Make Outbound Call Failed',
        message: error.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
    }
  }


  return (
    <div className='appointments-user'>
      {status && <div className={clsx('application-panel-closed', visible ? '' : 'hidden')} onClick={onClosePopup} />}
      <div className='appointments-user-info items-center'>
        <div className='appointments-user__img-wrapper'>
          {avatar ? <img src={avatar} alt='' className='appointments-user__img' /> :
            <DefaultAvatar title={name} className='w-full aspect-square rounded-full' />}
        </div>
        <div>
          <p className='appointments-user__name'>{name}</p>
          <span className='appointments-user__date'>{`${data?.time || ''}, ${getDate(data?.date)}`}</span>
          {statusRender(data?.status || APPOINTMENT_STATUS['Call to confirm'])}
        </div>
      </div>
      <div className='appointments-user-actions'>
        <button className='appointments-user-actions__call-btn'
          onClick={onToggleListCallPopup} >
          <img src={phone} alt='phone' />
        </button>

        <button className='appointments-user-actions__submenu-btn'
          onClick={onToggleStatusPopup} >
          <img src={ellipsis} alt='ellipsis' />
        </button>

        {/* popup status */}
        <div className={clsx('relative', visible === popup.status ? 'visible' : 'invisible')}
          style={{ transitionDuration: '0.4s' }}>
          <Slide right when={visible === popup.status} duration={500}>
            <div className={clsx('shadow rounded-md absolute z-10 top-8 right-[-1rem]', visible === popup.status ? "block" : "hidden pointer-events-none")}>
              <StatusPopup data={data}
                setRefresh={setRefresh}
                onClosePopup={onClosePopup}
                onOpenCancelPopup={onOpenCancelPopup}
                onOpenReschedulePopup={onOpenReschedulePopup}
              />
            </div>
          </Slide>
        </div>

        {/* popup cancel meeting */}
        <div className={clsx('relative', visible === popup.cancel ? 'visible' : 'invisible')}
          style={{ transitionDuration: '0.4s' }}>
          <Slide right when={visible === popup.cancel} duration={500}>
            <div className={clsx('shadow rounded-lg absolute z-10 top-8 right-[-1rem]', visible === popup.cancel ? "block" : "hidden pointer-events-none")}>
              <CancelPopup data={data}
                setRefresh={setRefresh}
                onClosePopup={onClosePopup} />
            </div>
          </Slide>
        </div>

        {/* popup reschedule meeting */}
        <div className={clsx('relative', visible === popup.reschedule ? 'visible' : 'invisible')}
          style={{ transitionDuration: '0.4s' }}>
          <Slide right when={visible === popup.reschedule} duration={500}>
            <div className={clsx('shadow rounded-lg absolute z-10 top-8 right-[-1rem]', visible === popup.reschedule ? "block" : "hidden pointer-events-none")}>
              <ReschedulePopup data={data}
                setRefresh={setRefresh}
                scheduleByDate={scheduleByDate}
                onClosePopup={onClosePopup}
              />
            </div>
          </Slide>
        </div>

        {/* popup list call */}
        <div className={clsx('relative', visible === popup.listCall ? 'visible' : 'invisible')}
          style={{ transitionDuration: '0.4s' }}>
          <Slide right when={visible === popup.listCall} duration={500}>
            <div className={clsx('shadow rounded-lg absolute z-10 top-8 right-[-1rem]', visible === popup.listCall ? "block" : "hidden pointer-events-none")}>
              <CallPopup data={data}
                parents={parents}
                onClickCall={onClickCall}
              />
            </div>
          </Slide>
        </div>

        {/* popup calling active */}
        {visible === popup.calling && (
          <div className={clsx('relative')}
            style={{ transitionDuration: '0.4s' }}>
            <div className={clsx('shadow rounded-lg absolute z-10 top-8 right-[-1rem]')}>
              <ActiveCall info={personCalling}
                startCount={popup.calling}
                className='w-[22rem] h-full p-4'
              />
            </div>
          </div>
        )}

        {/* popup call note */}
        <div className='relative' style={{ transitionDuration: '0.4s' }}>
          <Slide right when={visible === popup.callNote} duration={500}>
            <div className={clsx('absolute top-10 right-0 z-10', visible === popup.callNote ? "" : "hidden pointer-events-none")}>
              <DialogCallEnd eventCall={eventCall}
                onClose={onClosePopup}
                personCalled={personCalling}
                enquiry={data?.enquiry}
                setRefresh={setRefresh}
                className='w-[27rem] shadow p-3 rounded-lg bg-white'
              />
            </div>
          </Slide>
        </div>

      </div>
    </div >
  )
}

AppointmentsUser.propTypes = {
  userName: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.string
}