import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import TaskItem from './task-item.jsx';

function TaskDragDrop(props) {
  const {
    burger,
    listTasks,
    selectedTeam,
    setRefresh,
    setIsEditTask,
    setSelectedTask,
    menuShow,
    setMenuShow,
    setPage,
    setNumber,
    setIsUpdate,
    setIndexSelectedTask,
  } = props;
  const [tasks, updatetasks] = useState(listTasks);

  useEffect(() => {
    updatetasks(listTasks);
  }, [listTasks]);

  // function handleOnDragEnd(result) {
  //   if (!result.destination) return;

  //   const items = Array.from(tasks);
  //   const [reorderedItem] = items.splice(result.source.index, 1);
  //   items.splice(result.destination.index, 0, reorderedItem);

  //   updatetasks(items);
  // }

  return (
    <ul
      id="tasks-scroll"
      // className="tasks pl-4 w-full max-h-[60%] overflow-y-scroll overflow-x-hidden"
      className="tasks pl-4 w-full overflow-y-auto overflow-x-hidden 1400px:min-h-[60vh] h-[45vh]"
    >
      {tasks.map((item, index) => {
        return (
          <TaskItem
            key={index}
            burger={burger}
            task={item}
            index={index}
            id={item.id}
            selectedTeam={selectedTeam}
            setRefresh={setRefresh}
            setIsEditTask={setIsEditTask}
            setSelectedTask={setSelectedTask}
            setMenuShow={setMenuShow}
            menuShow={menuShow}
            setPage={setPage}
            setNumber={setNumber}
            setIsUpdate={setIsUpdate}
            setIndexSelectedTask={setIndexSelectedTask}
          />
        );
      })}
    </ul>
  );
}

TaskDragDrop.propTypes = {
  /**  @param Type An array of objects.
   * @param Object Keys for objects - id: string, done: boolean, num: number, date: string, color: string, time: number
   * @param Example [
  {
    id: 'one',
    done: false,
    num: 20,
    date: '20th Jan - 10th Feb ',
    color: '#008060',
    time: 10
  },
  {
    id: 'two',
    done: true,
    num: 50,
    date: '25th Oct - 30th Oct ',
    color: '#EB5757',
    time: 0
  },
  {
    id: 'three',
    done: true,
    num: 30,
    date: '10th Sep - 30th Oct ',
    color: '#008060',
    time: 30
  }
]
   *
   *
   **/
  data: PropTypes.array,
};

export default TaskDragDrop;
