/* eslint-disable react-hooks/rules-of-hooks */
import { Modal } from '@mui/material';
import React, { memo, useMemo, useRef, useState } from 'react';
import { ellipsis } from '../../static/image';
import Checkbox from '../../stories/checkbox/checkbox';
import Chip from '../../stories/chip/chip';

import { chat, email, phone, whatsapp } from '../notifications/images';
import TaskAndReminderSetting from './components/task-and-reminder-setting/task-and-reminder-setting';
import './tasks-and-reminders.scss';

const TasksAndReminders = (props) => {
  const { data, module, indexTitle, onChange, setRefresh } = props;
  const [visible, setVisible] = useState(false);
  const dataSettings = useRef();

  function openModalHandler() {
    setVisible(!visible);
  }

  return (
    <div>
      <table className="w-full table-fixed">
        <thead className="">
          <tr className="text-sm text-main-black h-10">
            <th className="relative pl-2 w-[14%]" style={{ right: 2 }}>
              <ul className="tasks-header_icons">
                <li>
                  <img src={email} alt="icon" className="tasks-header_icon" />
                </li>
                <li>
                  <img src={phone} alt="icon" className="tasks-header_icon" />
                </li>
                <li>
                  <img src={chat} alt="icon" className="tasks-header_icon" />
                </li>
                <li>
                  <img src={whatsapp} alt="icon" className="tasks-header_icon" />
                </li>
              </ul>
            </th>
            <th className="w-[17%]">Tasks</th>
            <th className="w-[10%]">Due Time</th>
            <th className="w-[12%]">Workload Estimate</th>
            <th className="w-[10%]">Reminders</th>
            <th className="w-[10%]">Linked Goal</th>
            <th className="w-[25%]">Person or Roles</th>
            <th className="w-[2%]"></th>
          </tr>
        </thead>
        <tbody className="tasks-table_body">
          {data.map((item, index) => {
            const {
              defaultId,
              isSendEmail,
              isSendNotiApp,
              isSendPhone,
              isSendWhatsapp,
              task,
              dueDate,
              reminder,
              linkedGoal,
              workloadEstimate,
              roleNames,
              userIds,
            } = item;
            return (
              <tr key={defaultId}>
                <td className="tasks-content">
                  <div className="flex justify-between flex-row">
                    <Checkbox
                      value={isSendEmail}
                      onChange={() =>
                        onChange(module, indexTitle, index, 'isSendEmail', !isSendEmail)
                      }
                    />
                    <Checkbox
                      value={isSendNotiApp}
                      onChange={() =>
                        onChange(
                          module,
                          indexTitle,
                          index,
                          'isSendNotiApp',
                          !isSendNotiApp,
                        )
                      }
                    />
                    <Checkbox
                      value={isSendPhone}
                      onChange={() =>
                        onChange(module, indexTitle, index, 'isSendPhone', !isSendPhone)
                      }
                    />
                    <Checkbox
                      value={isSendWhatsapp}
                      onChange={() =>
                        onChange(
                          module,
                          indexTitle,
                          index,
                          'isSendWhatsapp',
                          !isSendWhatsapp,
                        )
                      }
                    />
                  </div>
                </td>
                <td className="tasks-content">{task}</td>
                <td className="tasks-content">{dueDate ?? 0 + ' Days'}</td>
                <td className="tasks-content">{workloadEstimate ?? 0 + ' Mins'}</td>
                <td className="tasks-content">{reminder}</td>
                <td className="tasks-content">
                  <div
                    className="border rounded-md flex items-center justify-center"
                    style={{ height: '1.5rem', borderColor: '#008060' }}
                  >
                    <p
                      className="text-xs whitespace-pre pr-2 pl-2 overflow-hidden overflow-ellipsis"
                      style={{ color: '#008060' }}
                    >
                      {linkedGoal}
                    </p>
                  </div>
                </td>
                <td className="tasks-content">
                  <ul style={{ verticalAlign: 'middle' }}>
                    {roleNames &&
                      roleNames.map((role, key) => {
                        return (
                          <Chip
                            key={key + role}
                            label={role}
                            symbol={role[0]}
                            deleteChip={false}
                            customStyle={{ height: 24, marginTop: 4, marginRight: 4 }}
                            chipAvatarStyle={{ width: 18, height: 18 }}
                            styleChip={'text'}
                          />
                        );
                      })}
                    {userIds &&
                      userIds.map((user, key) => {
                        return (
                          <Chip
                            key={key + user.name}
                            label={user.name}
                            image={user.photoURL}
                            deleteChip={false}
                            customStyle={{ height: 24, marginTop: 4, marginRight: 4 }}
                            chipAvatarStyle={{ width: 18, height: 18 }}
                            styleChip={'contained'}
                          />
                        );
                      })}
                  </ul>
                </td>
                <td>
                  <img
                    onClick={() => {
                      dataSettings.current = item;
                      openModalHandler();
                    }}
                    className="cursor-pointer pr-2 pl-2"
                    src={ellipsis}
                    alt="delegate"
                  ></img>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Modal open={visible} onClose={openModalHandler}>
        <div>
          <TaskAndReminderSetting
            visible={visible}
            setRefresh={setRefresh}
            setVisible={setVisible}
            dataSettings={dataSettings.current}
          />
        </div>
      </Modal>
    </div>
  );
};

export default TasksAndReminders;

const TaskSvg = memo(() => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 22H19C20.103 22 21 21.103 21 20V5C21 3.897 20.103 3 19 3H17C17 2.73478 16.8946 2.48043 16.7071 2.29289C16.5196 2.10536 16.2652 2 16 2H8C7.73478 2 7.48043 2.10536 7.29289 2.29289C7.10536 2.48043 7 2.73478 7 3H5C3.897 3 3 3.897 3 5V20C3 21.103 3.897 22 5 22ZM5 5H7V7H17V5H19V20H5V5Z"
        fill="black"
      />
      <path
        d="M11 13.586L9.20697 11.793L7.79297 13.207L11 16.414L16.207 11.207L14.793 9.79297L11 13.586Z"
        fill="black"
      />
    </svg>
  );
});
TaskSvg.displayName = 'TaskSvg';
