import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { cancel, markquestion } from '../../../../static/image';
import { Input, Datepicker, Checkbox } from '../../../../stories';

export default function AcademicTable(props) {
  const {
    burger,
    listTerms,
    setListTerms,
    termNameChangeHandle,
    lockTermChangeHandle,
    changeDateHandle,
  } = props;
  const styles = {
    tr: { top: 0, position: 'relative' },
    th: { display: 'flex', top: 25, left: 15, position: 'relative' },
    input: { height: '2.625rem', borderRadius: '0.5rem', width: 'auto !important' },
  };

  // checkbox not update so i use state to re-render component to show right state of checkbox
  const [test, setTest] = useState(false);
  useEffect(() => {
    setTest(!test);
  }, [listTerms]);

  return (
    <table
      className={clsx('table table_full')}
      style={{
        borderWidth: '0px',
        marginTop: '0px',
      }}
    >
      <thead>
        <tr style={styles.tr}>
          <th>Term Name</th>
          <th>Start Date</th>
          <th>End date</th>
          <th style={styles.th}>
            Lock Term
            {burger || <img className="ml-1" src={markquestion} alt="markquestion" />}
          </th>
        </tr>
      </thead>
      <tbody className="relative top-5">
        {listTerms.map((item, index) => (
          <tr key={index}>
            <td>
              <Input
                inputSize="small"
                // disabled={item.lockTerm}
                disabled
                customStyle={styles.input}
                label="Name"
                name={item.name}
                value={item.name}
                onChange={(e) => {
                  termNameChangeHandle(e, index);
                }}
              />
            </td>
            <td>
              <Datepicker
                label="Starts on"
                format="dd/MM/yy"
                disabled={item.lockTerm}
                pValue={new Date(item.startDate).toUTCString()}
                psetValue={(value) => {
                  changeDateHandle('start', value, index);
                }}
                inputCustomStyle={{
                  padding: '16.5px 0px 16.5px 14px !important',
                  height: '0.75rem',
                }}
              />
            </td>
            <td>
              <Datepicker
                label="Ends on"
                format="dd/MM/yy"
                disabled={item.lockTerm}
                pValue={new Date(item.endDate).toUTCString()}
                psetValue={(value) => {
                  changeDateHandle('end', value, index);
                }}
                inputCustomStyle={{
                  padding: '16.5px 0px 16.5px 14px !important',
                  height: '0.75rem',
                }}
              />
            </td>
            <td className="center">
              <Checkbox
                value={item.lockTerm}
                onChange={(e) => {
                  lockTermChangeHandle(e, index);
                }}
                // customStyle={{
                //   position: 'relative',
                //   left: '6px',
                // }}
              />
            </td>
            <td>
              <button
                className="ml-4"
                onClick={() => {
                  setListTerms((prev) => prev.filter((item, i) => i !== index));
                }}
              >
                <img src={cancel} alt="closed modal" />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

AcademicTable.propTypes = {
  listTerms: PropTypes.array,
  burger: PropTypes.bool,
  termNameChangeHandle: PropTypes.func,
  lockTermChangeHandle: PropTypes.func,
  changeDateHandle: PropTypes.func,
};
