import clsx from 'clsx';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { Fade } from 'react-reveal';
import EditPanelNew from '@pages/finance-module/components/edit-panel-new';
import { FeeBookStatus, feebookFieldRow } from 'entities/data';
import { Button } from '../../stories';
import approvalApi from 'api/approval';
import financeApi from 'api/finance';
import useStorage from 'store/storage';

const listKeys = [
  'admissionFee',
  'applicationFee',
  'customFees',
  'followingFees',
  'foodFee',
  'residentialFee',
  'residentialFeeForClasses',
  'residentialFromClassId',
  'transportFee',
  'tutionFees'
]

const FooterFinance = memo((props) => {
  const {
    feeCategory,
    style,
    customClass,
    handleNext = () => { },
    handelSendForApproval = () => { },
    handleClickCancel = () => { },
    handleClickSaveAsDraft = () => { },
    academicYearName,
    refreshFooter,
    feeBookInfo
  } = props;
  const [buttonText, setButtonText] = useState("Send for Approval");
  const [isHaveApprovalProcess, setIsHaveApprovalProcess] = useState(false)
  const [visiblePanel, setVisiblePanel] = useState(false);
  const [count, setCount] = useState(0);
  const [draftData, setDraftData] = useState([]);
  const campus = useStorage((state) => state.currentCampus);

  const handelSendForApprovalOrNext = () => {
    if (feeCategory === feebookFieldRow?.[4]?.text) {
      handelSendForApproval(isHaveApprovalProcess)
    } else {
      handleNext()
    }
  }
  useEffect(() => {
    // callApi({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/process/search`,
    //   params: { actionId: 8 },
    // })
    approvalApi.getApprovalProcess({ actionId: 8 })
      .then((res) => {
        if (res.data.success) {
          !Object.keys(res.data.data || {}).length && setButtonText('Save');
          Object.keys(res.data.data || {}).length && setIsHaveApprovalProcess(true)
        }
      })
      .catch((error) => {
        console.log('Get Process Failed', error);
      });
  }, [])

  useEffect(() => {
    if (academicYearName) {
      financeApi.getListFeebook({ campusId: campus?.isCentral ? undefined : campus?.id, academicYearName: academicYearName })
        .then((res) => {
          if (res.data.success) {
            const copyFeebookInfo = _.cloneDeep(feeBookInfo);
            let counter = 0;
            let data = [];
            (res.data.data.objects || []).forEach(fee => {
              if (fee.status === FeeBookStatus.DRAFT && fee.id !== copyFeebookInfo?.id && fee.academicYearId === copyFeebookInfo.academicYearId) {
                data.push(fee);
                counter++;
              }
            })
            counter > 0 && setCount(counter);
            const dates = {};
            data.forEach(dt => {
              const dateUpdate = new Date(dt.dateUpdated).toUTCString().slice(5, 16);
              dates[dateUpdate] = {};
            })

            data.forEach(dt => {
              const dtUpdate = new Date(dt.dateUpdated).toUTCString().slice(5, 16);
              const timeString = new Date(dt.dateUpdated).toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              });
              listKeys.forEach(key => {
                if (!(dt[key] && copyFeebookInfo[key])) {
                  if (dates[dtUpdate]?.[dt.id]) {
                    dates[dtUpdate][dt.id].push({
                      type: 'Edit',
                      title: 'Edit ' + key,
                      subtitle: 'Edit ' + key,
                      time: timeString,
                      id: dt.id
                    });
                  } else {
                    dates[dtUpdate][dt.id] = [{
                      type: 'Edit',
                      title: 'Edit ' + key,
                      subtitle: 'Edit ' + key,
                      time: timeString,
                      id: dt.id
                    }];
                  }
                } else {
                  let dataFee = dt[key];
                  let dataFeeInfo = copyFeebookInfo[key];
                  if (typeof dt[key] === 'string' && key !== 'residentialFromClassId') {
                    dataFee = JSON.parse(dt[key]);
                  };
                  if (typeof copyFeebookInfo[key] === 'string' && key !== 'residentialFromClassId') {
                    dataFeeInfo = JSON.parse(copyFeebookInfo[key]);
                  };
                  if (key === 'foodFee' || key === 'transportFee') {
                    Object.keys(dataFee).forEach(feeKey => {
                      if (typeof dataFee[feeKey] === 'object' && !Array.isArray(dataFee[feeKey]) && dataFee[feeKey] !== null) {
                        if (dataFee[feeKey].value === null) {
                          delete dataFee[feeKey];
                        } else {
                          dataFee[feeKey] = dataFee[feeKey].value;
                        };
                      };
                    });
                    Object.keys(dataFeeInfo).forEach(feeKey => {
                      if (typeof dataFeeInfo[feeKey] === 'object' && !Array.isArray(dataFeeInfo[feeKey]) && dataFeeInfo[feeKey] !== null) {
                        if (dataFeeInfo[feeKey].value === null) {
                          delete dataFeeInfo[feeKey];
                        } else {
                          dataFeeInfo[feeKey] = dataFeeInfo[feeKey].value;
                        };
                      };
                    });
                    if (dataFee.feeType !== 'Custom' || dataFee.feeType !== 'Term Dates') {
                      delete dataFee.terms;
                    };
                    if (dataFeeInfo.feeType !== 'Custom' || dataFeeInfo.feeType !== 'Term Dates') {
                      delete dataFeeInfo.terms;
                    };
                    // delete dataFeeInfo.totalPercentageChange;
                  } else if (key === 'residentialFee' || key === 'tutionFees') {
                    dataFee = dataFee.map(dtf => {
                      const { info, classId } = dtf;
                      let newInfo = info;
                      if (typeof info === 'string') {
                        newInfo = JSON.parse(info);
                      } else {
                        Object.keys(info).forEach(keyIf => {
                          if (typeof info[keyIf] === 'object' && !Array.isArray(info[keyIf]) && keyIf !== 'feeDistribution') {
                            newInfo[keyIf] = info[keyIf].value;
                          }
                        });
                      };
                      return {
                        info: newInfo,
                        classId
                      }
                    });
                    dataFeeInfo = dataFeeInfo.map(dtf => {
                      const { info, classId } = dtf;
                      let newInfo = info;
                      if (typeof info === 'string') {
                        newInfo = JSON.parse(info);
                      } else {
                        Object.keys(info).forEach(keyIf => {
                          if (typeof info[keyIf] === 'object' && !Array.isArray(info[keyIf]) && keyIf !== 'feeDistribution') {
                            newInfo[keyIf] = info[keyIf].value;
                          }
                        });
                      };
                      return {
                        info: newInfo,
                        classId
                      }
                    });
                  }
                  if (!_.isEqual(dataFee, dataFeeInfo)) {
                    if (dates[dtUpdate]?.[dt.id]) {
                      dates[dtUpdate][dt.id].push({
                        type: 'Edit',
                        title: 'Edit ' + key,
                        subtitle: 'Edit ' + key,
                        time: timeString,
                        id: dt.id
                      });
                    } else {
                      dates[dtUpdate][dt.id] = [{
                        type: 'Edit',
                        title: 'Edit ' + key,
                        subtitle: 'Edit ' + key,
                        time: timeString,
                        id: dt.id
                      }];
                    }
                  }
                }
              })
            });
            const draft = [];
            Object.keys(dates).forEach(key => {
              const dataChange = dates[key];
              const cards = Object.keys(dataChange).map(dt => {
                return {
                  title: 'Change fee',
                  id: dt,
                  edits: dataChange[dt]
                }
              });
              draft.push({
                date: key,
                cards
              });
            })
            draft.sort((a, b) => {
              let comparison = 0;
              let varA = new Date(a.date).getTime();
              let varB = new Date(b.date).getTime();
              if (varA > varB) {
                comparison = -1;
              } else if (varA < varB) {
                comparison = 1;
              }
              return comparison;
            })
            draft.length > 0 && setDraftData(draft);
          }
        })
        .catch((error) => {
          console.log('Get Feebook List Failed', error);
        })
    }
  }, [academicYearName, refreshFooter])

  const styles = { span: { fontSize: 13 }, button: { color: '#FF4842' } };
  return (
    <div
      className={`fixed bg-white justify-center items-center flex bottom-0 ${customClass}`}
      style={style}
    >
      <div className={clsx(visiblePanel ? 'pointer-events-none' : 'pointer-events-auto')}>
        <EditPanelNew
          visible={visiblePanel}
          setVisible={setVisiblePanel}
          draftsList={draftData}
        />
      </div>
      <Fade clear duration={300}>
        <div
          className="items-center justify-between flex w-fit h-[3rem] mb-4 pr-2 rounded"
          style={{
            boxShadow: '0px 0px 24px 0px #919EAB3D',
          }}
        >
          <button
            className="h-12 w-12 flex flex-col items-center justify-center text-white bg-main-blue"
            style={{
              borderTopLeftRadius: '0.5rem',
              borderBottomLeftRadius: '0.5rem',
            }}
            onClick={() => { setVisiblePanel(true) }}
          >
            {!!count && <span>{count}</span>}
            <span style={styles.span}>Edits</span>
          </button>
          <Button
            onClick={handleClickCancel}
            buttonStyle="text"
            buttonSize="large"
            text="Cancel"
            customStyle={styles.button}
          />
          {/* <span className='mx-2'>or</span> */}
          <Button
            onClick={handleClickSaveAsDraft}
            buttonStyle="text"
            buttonSize="large"
            text="Save as Draft"
          // customStyle={styles.button}
          />
          {/* <span className="mr-3">or</span> */}
          <Button
            onClick={handelSendForApprovalOrNext}
            text={feeCategory === feebookFieldRow?.[4]?.text ? buttonText : "Next"}
            buttonSize="large"
            className={clsx(feeCategory === feebookFieldRow?.[4]?.text ? "" : "px-12")}
          />
        </div>
      </Fade>
    </div>
  );
});
FooterFinance.displayName = 'FooterFinance';
export default FooterFinance;

FooterFinance.propTypes = {
  handleSendApproval: PropTypes.func,
  handleCancel: PropTypes.func,
};

FooterFinance.defaultProps = {
  handleSendApproval: () => { },
  handleCancel: () => { },
};
