import { handleCallApiFail } from '@helper/call-api';
import { Card } from '@mui/material';
import { Button } from '@stories/index';
import {
  checkErrorApiFetch,
  checkErrorMultipleApi,
  checkErrorSingleApi,
} from '@utils/check-error/api-error';
import { deleteKeysInObject, keyRelationship } from '@utils/utils';
import clsx from 'clsx';
import { ToastMessageContext } from 'context/toast-context';
import { EnquiryStatus } from 'entities/data';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import ScholarshipItem from './scholarship-item';
import financeApi from 'api/finance';
import mediaApi from 'api/media';
import admissionApi from 'api/admission';
import useStorage from 'store/storage';
import { initScholarship } from '@utils/constant';
import { SCHOLARSHIP_STATUS } from '@helper/admission';

const objValueType = {
  Percentage: 'Percentage',
  Amount: 'Amount',
};

function Scholarship(props) {
  const {
    applicationId,
    isParent,
    enquiry,
    isCompletedOrClosed,
    onPreviousStep,
    handleNextStage,
    handleRefreshEnquiry,
  } = props;
  const [scholarships, setScholarships] = useState();
  const [scholarshipOriginal, setScholarshipOriginal] = useState();
  const [extraInfo, setExtraInfo] = useState();
  const [refresh, setRefresh] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [admissionFee, setAdmissionFee] = useState();
  const [scholarshipEnables, setScholarshipEnables] = useState({});
  const campus = useStorage((state) => state.currentCampus);

  const getSiblingDiscount = (listSibling, siblingCount) => {
    const infoSibling = { name: '', discountType: 'Fixed Amount', value: '' };
    if (!listSibling || !siblingCount) return infoSibling;
    switch (siblingCount) {
      case 1:
        return listSibling.find((item) => item.name === '1st Sibling');
      case 2:
        return listSibling.find((item) => item.name === '2nd Sibling');
      case 3:
        return listSibling.find((item) => item.name === '3rd Sibling');
      default:
        return listSibling.find((item) => item.name === '3rd Sibling');
    }
  };

  useEffect(() => {
    financeApi
      .getListScholarship({ campusId: enquiry?.campus?.id })
      .then((res) => {
        if (
          checkErrorApiFetch(
            res,
            setToastMessage,
            setIsShowToastMessage,
            'Get Scholarship Finance ',
          )
        ) {
          const tmpScholarshipEnables = { partnerSchool: true, partnerCompany: true };
          const {
            academicScholarship,
            boardDiscount,
            partnerDiscount,
            principalDiscount,
            sibblingDiscount,
            sportsScholarship,
          } = res.data.data;
          if (JSON.parse(academicScholarship).active)
            tmpScholarshipEnables.academic = true;
          if (JSON.parse(boardDiscount).active) tmpScholarshipEnables.boardFund = true;
          if (JSON.parse(principalDiscount).active)
            tmpScholarshipEnables.principalFund = true;
          if (JSON.parse(sportsScholarship).active) tmpScholarshipEnables.sports = true;
          if (JSON.parse(sibblingDiscount).active)
            tmpScholarshipEnables.siblingScholarship = true;
          setScholarshipEnables(tmpScholarshipEnables);
          const listSibling = JSON.parse(sibblingDiscount).items.map((item) => ({
            ...item,
            label: item.name,
            idOption: item.name,
          }));
          let siblingCount = 1;
          if (enquiry?.admissionResult?.info) {
            const info = JSON.parse(enquiry.admissionResult.info);
            if (info?.application?.applicationParent) {
              siblingCount = info.application.applicationParent.reduce((count, item) => {
                if (item.relationship === keyRelationship.sibling) return count + 1;
                return count;
              }, 1);
            }
          }
          let listPartnerSchool = [];
          let listPartnerCompany = [];
          for (let discount of JSON.parse(partnerDiscount).items) {
            const option = {
              ...discount,
              label: discount.partnerName,
              idOption: discount.partnerName,
            };
            if (discount.partnerType === 'Cooperate') listPartnerSchool.push(option);
            if (discount.partnerType === 'Company') listPartnerCompany.push(option);
          }
          setExtraInfo({
            sibling: getSiblingDiscount(listSibling, siblingCount),
            listPartnerSchool,
            listPartnerCompany,
          });
        }
      })
      .catch((error) => {
        handleCallApiFail(
          error,
          'Get Data Failed',
          setToastMessage,
          setIsShowToastMessage,
        );
      });
  }, []);

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.set('file', file);
    formData.set('mediaId', file.name);
    return mediaApi.uploadFile(formData);
  };

  useEffect(() => {
    const fetch = async () => {
      if (applicationId) {
        let api;
        if (isParent) {
          api = admissionApi.getListScholarshipParent({ applicationId });
        } else api = admissionApi.getListScholarship({ applicationId });
        const res = await api;
        if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage)) {
          const initial = {
            documents: [],
            urlDocuments: [],
            info: { comment: '', fees: [{}] },
          };
          const tmp = res.data.data.map((item) => {
            if (item?.id)
              return {
                ...item,
                info: item.info ? JSON.parse(item.info) : { comment: '', fees: [{}] },
                documents: JSON.parse(item.documents),
                urlDocuments: Array(JSON.parse(item.documents).length).fill(0),
              };
            return JSON.parse(JSON.stringify(initial));
          });
          setScholarships(tmp.length === 0 ? [initial] : JSON.parse(JSON.stringify(tmp)));
          setScholarshipOriginal(JSON.stringify(tmp));
        }
      }
    };
    fetch();
  }, [applicationId, refresh, isParent]);

  const extractFee = (dataInput) => {
    try {
      const { data } = dataInput;
      const fee = {};
      if (data?.admissionFee) {
        const info = JSON.parse(data.admissionFee);
        const { isActive, price, type } = info;
        fee.admissionFee = isActive ? Number(price) || 0 : 0;
      }
      if (data?.customFees) {
        const info = JSON.parse(data.customFees);
        fee.customFees = Number(info.estAnnualFee) || 0;
      }
      if (data?.transportFee) {
        const info = JSON.parse(data?.transportFee);
        fee.transportFee = Number(info.estAnnualFee) || 0;
      }
      if (data?.foodFee) {
        const info = JSON.parse(data?.foodFee);
        fee.foodFee = Number(info.estAnnualFee) || 0;
      }
      if (data?.residentialFeeForClasses) {
        const info = JSON.parse(data.residentialFeeForClasses);
        const { isActive, type, price } = info;
        if (isActive && type === 'Same') {
          fee.residentialFee = Number(price) || 0;
        } else {
          if (data?.residentialFee) {
            const info = JSON.parse(data.residentialFee?.info || '{}');
            fee.residentialFee = Number(info.estAnnualFee) || 0;
          }
        }
      }
      if (data?.tutionFees?.info) {
        const info = JSON.parse(data.tutionFees.info);
        fee.tuitionFees = info.estAnnualFee;
      }
      return fee;
    } catch (error) {
      setToastMessage({
        status: 'error',
        title: 'Get Fees',
        message: error.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
    }
  };

  useEffect(() => {
    const fetch = async (signal) => {
      const res = await admissionApi.getListFeeBook(
        { campusId: campus?.id, applicationId },
        signal,
      );
      let fee;
      if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage)) {
        fee = extractFee(res.data);
      }
      setAdmissionFee(fee);
    };
    const controller = new AbortController();
    if (!isParent) fetch(controller.signal);
    return () => {
      controller.abort();
    };
  }, []);

  const onAddScholarship = () => {
    setScholarships((prev) => [...prev, JSON.parse(JSON.stringify(initScholarship))]);
  };

  const getDocuments = () => {
    const listFile = [];
    for (let scholarship of scholarships) {
      const { documents } = scholarship;
      if (documents && documents.length !== 0) {
        documents.forEach((document) => {
          if (document.name) listFile.push(document);
        });
      }
    }
    return listFile;
  };

  const uploadDocuments = async () => {
    const documents = getDocuments();
    if (documents.length !== 0) {
      const res = await Promise.all(documents.map((file) => uploadFile(file)));
      if (checkErrorMultipleApi(res, setToastMessage, setIsShowToastMessage, '', false)) {
        const newScholarships = structuredClone(scholarships);
        for (let scholarship of newScholarships) {
          const { documents: documentsScholarship, urlDocuments } = scholarship;
          if (documentsScholarship && documentsScholarship.length !== 0) {
            for (let index in documentsScholarship) {
              if (documentsScholarship[index].name) {
                documentsScholarship[index] = res.shift().data.data;
                URL.revokeObjectURL(urlDocuments[index]);
                urlDocuments[index] = '';
              }
            }
          }
        }
        setScholarships(newScholarships);
        return newScholarships;
      }
    }
    return scholarships;
  };

  const getScholarshipAction = (data) => {
    const scholarshipsCopy = JSON.parse(JSON.stringify(data));
    const listScholarshipDelete = [];
    const listScholarshipNewOrUpdate = [];
    scholarshipsCopy.forEach((scholarship) => {
      const { isDeleted } = scholarship;
      if (isDeleted) {
        const newScholarship = deleteKeysInObject({
          obj: scholarship,
          listKey: ['urlDocuments', 'isUpdate', 'isDeleted'],
        });
        newScholarship.info = JSON.stringify(newScholarship.info);
        listScholarshipDelete.push(newScholarship);
      } else {
        const newScholarship = deleteKeysInObject({
          obj: scholarship,
          listKey: ['urlDocuments', 'isUpdate', 'isDeleted'],
        });
        newScholarship.info = JSON.stringify(newScholarship.info);
        listScholarshipNewOrUpdate.push(newScholarship);
      }
    });
    return { listScholarshipDelete, listScholarshipNewOrUpdate };
  };

  const getEstFee = (feeName) => {
    switch (feeName) {
      case 'Admission Fee':
        return admissionFee.admissionFee;
      case 'Tuition Fee':
        return admissionFee.tuitionFees;
      case 'Residential Fee':
        return admissionFee.residentialFee;
      case 'Food Fee':
        return admissionFee.foodFee;
      case 'Transport Fee':
        return admissionFee.transportFee;
      case 'Custom Fee':
        return admissionFee.customFees;
      default:
        return 0;
    }
  };

  const handleCallApi = async ({ listScholarshipNewOrUpdate, listScholarshipDelete }) => {
    let newListScholarShipNewOrUpdate = listScholarshipNewOrUpdate;
    let newListScholarShipDelete = listScholarshipDelete;
    if (isParent) {
      newListScholarShipNewOrUpdate = listScholarshipNewOrUpdate.map((item) => {
        if (item.info) {
          delete item.info;
          return item;
        }
        return item;
      });
      newListScholarShipDelete = listScholarshipDelete.map((item) => {
        if (item.info) {
          delete item.info;
          return item;
        }
        return item;
      });
    }
    const apis = [];
    const campusId = campus?.isCentral ? undefined : campus?.id;
    if (listScholarshipDelete.length !== 0) {
      if (isParent) {
        apis.push(
          admissionApi.deleteApplicationScholarshipParent(
            { campusId },
            { applicationId, scholarships: newListScholarShipDelete },
            applicationId,
          ),
        );
      } else
        apis.push(
          admissionApi.deleteApplicationScholarship(
            { campusId },
            { applicationId, scholarships: newListScholarShipDelete },
            applicationId,
          ),
        );
    }
    if (listScholarshipNewOrUpdate.length !== 0) {
      if (isParent) {
        apis.push(
          admissionApi.updateApplicationScholarshipParent(
            { campusId },
            { applicationId, scholarships: newListScholarShipNewOrUpdate },
          ),
        );
      } else
        apis.push(
          admissionApi.updateApplicationScholarship(
            { campusId },
            { applicationId, scholarships: newListScholarShipNewOrUpdate },
          ),
        );
    }
    if (apis) {
      const res = await Promise.all(apis);
      if (checkErrorMultipleApi(res, setToastMessage, setIsShowToastMessage)) {
        setRefresh((prev) => !prev);
        handleRefreshEnquiry();
      }
    }
  };

  const handleRequestApproval = async () => {
    try {
      if (isCompletedOrClosed) return handleNextStage();
      const newScholarships = await uploadDocuments();
      const { listScholarshipNewOrUpdate, listScholarshipDelete } =
        getScholarshipAction(newScholarships);
      await handleCallApi({ listScholarshipNewOrUpdate, listScholarshipDelete });
    } catch (error) {
      setToastMessage({
        status: 'error',
        title: 'Update Scholarship',
        message: error,
      });
      //   setIsShowToastMessage(true);
    }
  };

  const amount = useMemo(() => {
    if (!scholarships) return 0;
    return scholarships?.reduce((total, scholarship) => {
      if (
        scholarship.info &&
        scholarship.info.total &&
        scholarship.status === SCHOLARSHIP_STATUS.approved
      ) {
        return total + Number(scholarship.info.total);
      }
      return total;
    }, 0);
  }, [scholarships]);

  const textButton = useMemo(() => {
    // sibling scholarship is not required to request approval.
    if (scholarships?.length === 1 && scholarships[0].scholarshipId === 7) return 'Save';
    return 'Request Approval';
  }, [isParent, scholarships]);

  const enquiryStatus = enquiry?.status;

  const onNextStep = async () => {
    if (isCompletedOrClosed) {
      handleNextStage();
      return;
    }
    const res = await admissionApi.moveFromScholarshipToFee({
      applicationId,
      campusId: campus?.id,
    });
    console.log('res 498', res);
    if (
      checkErrorSingleApi(
        res,
        setToastMessage,
        setIsShowToastMessage,
        'Move To Fee',
        false,
      )
    ) {
      handleRefreshEnquiry();
    }
  };
  const isPassStage = [
    EnquiryStatus.WAITING_FOR_PAYMENT,
    EnquiryStatus.FEE_PAYMENT,
    EnquiryStatus.CLOSED,
    EnquiryStatus.COMPLETE,
    EnquiryStatus.FAILED,
  ].includes(enquiryStatus);
  if (!scholarships || (!isParent && !admissionFee)) return <Spinner />;
  return (
    <div class="relative">
      <div className="flex items-center justify-between max-w-[38.575rem] 1200px:w-[96%] 1599px:w-[68%] mx-auto">
        <div className="mb-10">
          <h3 className="font-medium text-2xl mb-3">Scholarship</h3>
          <p className="text-xs">
            If the candidate qualifies for a scholarship please update relevant documents
            to check eligibility
          </p>
        </div>
      </div>
      <div className="text-center mb-4 sticky top-0">
        <div className="flex items-center justify-center">
          <INR />
          <p className="ml-2 font-bold" style={{ fontSize: '3.125rem' }}>
            {amount.toFixed(2)}
          </p>
        </div>
        <p className="text-lg mt-4">Estimated Total Scholarship Amount</p>
      </div>
      {scholarships.length > 0 &&
        scholarships.map((data, idx) => {
          return (
            <div
              className="1200px:w-[96%] 1599px:w-[64%] 1599px: max-w-[42.575rem] mx-auto"
              key={idx}
            >
              <Card
                sx={{
                  borderRadius: '0.5rem',
                  boxShadow: '0px 12px 24px 0px #919EAB3D, 0px 0px 2px 0px #919EAB3D',
                  marginBottom: '3rem',
                  padding: '0.5rem 2rem',
                }}
              >
                <ScholarshipItem
                  data={data}
                  idxScholarship={idx}
                  isParent={isParent}
                  admissionFee={admissionFee}
                  extraInfo={extraInfo}
                  scholarships={scholarships}
                  objValueType={objValueType}
                  scholarshipEnables={scholarshipEnables}
                  scholarshipOriginal={scholarshipOriginal}
                  setScholarships={setScholarships}
                  getEstFee={getEstFee}
                />
              </Card>
            </div>
          );
        })}

      {/* TODO: replace === => !== */}
      {enquiry.status !== EnquiryStatus.COMPLETE && (
        <div className="flex justify-center my-10">
          <Button
            buttonDisabled={isCompletedOrClosed}
            onClick={onAddScholarship}
            text="Add an another Scholarship"
            buttonStyle="outlined"
            customStyle={{
              width: '17rem',
              paddingLeft: '1.5rem',
              paddingRight: '0.5rem',
            }}
          />
        </div>
      )}
      <div className={clsx('flex items-center justify-center mt-3.5 mb-6 justify-end')}>
        <Button
          onClick={onPreviousStep}
          text="Previous Step"
          buttonStyle="outlined"
          customStyle={{ paddingLeft: '2.5rem', paddingRight: '2.5rem' }}
        />
        {!isPassStage && (
          <Button
            text={textButton}
            customStyle={{
              paddingLeft: '2.5rem',
              paddingRight: '2.5rem',
              marginLeft: '1.25rem',
            }}
            onClick={handleRequestApproval}
          />
        )}

        <Button
          text="Next Step"
          customStyle={{
            paddingLeft: '2.5rem',
            paddingRight: '2.5rem',
            marginLeft: '1.25rem',
          }}
          onClick={isPassStage ? handleNextStage : onNextStep}
        />
      </div>
    </div>
  );
}

const INR = () => {
  return (
    <svg
      width="27"
      height="41"
      viewBox="0 0 27 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.25 24.944H8.668l11.05 11.8c1.322 1.423.347 3.756-1.56 3.756a2.123 2.123 0 0 1-1.56-.689L3.533 25.856a3.138 3.138 0 0 1-.866-2.178c0-1.756 1.386-3.178 3.098-3.178h4.485c3.813 0 6.977-2.889 7.497-6.667H2.667c-1.192 0-2.167-1-2.167-2.222S1.475 9.39 2.667 9.39h14.43c-1.214-2.622-3.814-4.445-6.847-4.445H2.667C1.475 4.944.5 3.944.5 2.722S1.475.5 2.667.5h21.666c1.192 0 2.167 1 2.167 2.222s-.975 2.222-2.167 2.222h-4.896c1.04 1.29 1.82 2.8 2.275 4.445h2.621c1.192 0 2.167 1 2.167 2.222s-.975 2.222-2.167 2.222h-2.21c-.563 6.223-5.676 11.111-11.873 11.111Z"
        fill="#000"
      />
    </svg>
  );
};

export default Scholarship;
