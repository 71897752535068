import { memo, useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { cancel } from '@static/image';
import { Button, Input, SelectGroup } from '@stories/index';
import { checkErrorSingleApi } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { selectGroup } from 'entities/select-style';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';

const initOptions = [
  { label: 'Admissions', value: 'Admissions' },
  { label: 'Finance', value: 'Finance' },
];

const PopupCreate = memo((props) => {
  const { toggleOpen, isOpen, setIsAddForm = () => { } } = props;
  const [info, setInfo] = useState({});
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [options, setOptions] = useState(initOptions);
  const campus = useStorage((state) => state.currentCampus);

  const styles = {
    closeBtn: {
      backgroundColor: '#fff',
      color: '#202223',
      border: '1px solid #E1E3E5',
      marginRight: '0.5rem',
    },
    createBtn: { backgroundColor: '#404EED' },
  };

  const handleChangeInfo = useCallback((key, value) => {
    setInfo((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const handleOnClickCreate = useCallback(() => {
    if (!info.category) {
      setToastMessage({
        status: 'warning',
        title: 'Validation Rules',
        message: 'Missing Category',
      });
      setIsShowToastMessage(true);
      return;
    }
    if (!info.name) {
      setToastMessage({
        status: 'warning',
        title: 'Validation Rules',
        message: 'Missing Name',
      });
      setIsShowToastMessage(true);
      return;
    }
    const data = {
      usedIn: info.category?.value,
      name: info.name,
      formContentString: '',
    };
    centralAdminApi.createFormBuilder({ campusId: campus?.isCentral ? undefined : campus?.id }, data)
      .then((response) => {
        if (
          checkErrorSingleApi(
            response,
            setToastMessage,
            setIsShowToastMessage,
            'Create Form',
          )
        ) {
          toggleOpen(false);
          setIsAddForm((prev) => !prev);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Create Form Error',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Create Form Error', error);
      });
  }, [info]);

  return (
    <div className={isOpen ? 'action-modal action-modal-active' : 'action-modal'}>
      <div className="action-modal_bg" onClick={toggleOpen} />
      <div className="action-modal-card">
        <div className="action-modal-card-header">
          <h2 className="text-2xl ml-6">Add Custom Form</h2>
          <button className="mr-4" onClick={toggleOpen}>
            <img src={cancel} alt="closed modal" />
          </button>
        </div>
        <div className="action-modal-card-row">
          <div className="action-modal-card-row_label"></div>
          <div className="action-modal-card-row_selects">
            <SelectGroup
              classNameGroup="ml-10 mb-7 w-80"
              title="Select Category"
              isMulti={false}
              styles={selectGroup}
              options={options}
              value={info.category}
              onChange={(e) => {
                handleChangeInfo('category', e);
              }}
            />

            <div className="ml-10 w-80">
              <Input
                label="Name"
                value={info.name || ''}
                onChange={(e) => {
                  handleChangeInfo('name', e.target.value);
                }}
                customStyle={{
                  paddingLeft: '1rem',
                  width: '100%',
                  height: '2.65rem',
                  fontSize: '1rem',
                  borderRadius: '0.5rem',
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end pt-4 pr-4">
          <Button text="Close" customStyle={styles.closeBtn} onClick={toggleOpen} />
          <Button
            text="Create"
            customStyle={styles.createBtn}
            onClick={handleOnClickCreate}
          />
        </div>
      </div>
    </div>
  );
});
PopupCreate.displayName = 'PopupCreate';
export default PopupCreate;
