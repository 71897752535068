import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BloomHeader from '../../../components/bloomingdale/header/header';
import StudentCard from '../../../components/bloomingdale/student-card/studentCard';
import { mainavatar } from '../../../static/image';
import { EnquiryStatus } from '../../../entities/data';
import { ToastMessageContext } from '../../../context/toast-context';
import './cabinet.scss';
import admissionApi from 'api/admission';
import { urls } from 'entities/urls';

const BloomCabinet = () => {
  const [listApplications, setListApplications] = useState({ admission: [], feePayment: [] });
  const history = useHistory();
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  useEffect(() => {
    admissionApi.getListEnquiryParent()
      .then((res) => {
        const { data } = res;
        if (data.success) {
          let admission = [];
          let feePayment = [];
          data.data.objects.forEach((application) => {
            const isPayment = (application.currentStep === EnquiryStatus.SIGNATURE &&
              application.status === EnquiryStatus.WAITING_FOR_PAYMENT) ||
              application.currentStep === EnquiryStatus.FEE_PAYMENT
            if (isPayment) {
              feePayment.push(application);
            } else {
              admission.push(application);
            }
          });
          setListApplications({ admission, feePayment });
        } else {
          setToastMessage({
            status: 'error',
            title: 'Get List Applications Failed',
            message: data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Get List Applications Failed',
          message: error.response?.data?.message || error,
        });
        console.log('Get List Applications', error);
      });
  }, []);

  const checkPendingApplications = (status, nextStep) => {
    if (status === EnquiryStatus.COMPLETE) {
      return 'Completed';
    }
    if (!nextStep && status === EnquiryStatus.EXAM_FAIL) {
      return 'Failed';
    }
    return 'Pending';
  };

  return (
    <>
      <BloomHeader />
      <div className="bloom-cabinet mt-20">
        <div className="bloom-cabinet-admissions">
          <h4 className="bloom-cabinet-admissions_title bloom-cabinet_title ">
            Admissions
          </h4>
          <div className="bloom-cabinet-admissions_list">
            {listApplications.admission.map((application, idx) => {
              const { info, programGrade, academicYear, status, nextStep, id, campusId } =
                application;
              const { firstName, middleName, lastName, avatar } = info;
              let name;
              if (firstName) name = firstName;
              if (middleName) name = name + ' ' + middleName;
              if (lastName) name = name + ' ' + lastName;

              return (
                <div
                  onClick={() => {
                    history.push(urls.parent.dashboard, {
                      enquiryId: id,
                      campusId,
                    });
                  }}
                  className="cursor-pointer"
                  key={id || idx}
                >
                  <StudentCard
                    avatar={avatar || mainavatar}
                    name={name}
                    code={programGrade?.grade?.code || 'Grade'}
                    year={academicYear?.name || 'Academic Year'}
                    pending={checkPendingApplications(status, nextStep)}
                  />
                </div>
              );
            })}
            <button
              onClick={() => {
                history.push(urls.parent.form);
              }}
              className="bloom-cabinet-admissions_btn none-after h-16"
            >
              <span>+</span>
              Add new applicant
            </button>
          </div>
        </div>
        <div className="bloom-cabinet-payment">
          <h4 className="bloom-cabinet-payment_title bloom-cabinet_title">Fee Payment</h4>
          <div className="bloom-cabinet-admissions_list">
            {listApplications.feePayment.map((application, idx) => {
              const { info, programGrade, academicYear, status, nextStep, id, campusId } = application;
              const { firstName, middleName, lastName, avatar } = info;
              let name;
              if (firstName) name = firstName;
              if (middleName) name = name + ' ' + middleName;
              if (lastName) name = name + ' ' + lastName;
              return (
                <div
                  onClick={() => {
                    history.push(urls.parent.dashboard, {
                      enquiryId: id,
                      campusId
                    });
                  }}
                  className="cursor-pointer"
                  key={application?.id || idx}
                >
                  <StudentCard
                    avatar={avatar || mainavatar}
                    name={name}
                    code={programGrade?.grade?.code || 'Grade'}
                    year={academicYear?.name || 'Academic Year'}
                    pending={checkPendingApplications(status, nextStep)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default BloomCabinet;
