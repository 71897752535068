import { useContext, useState } from "react";
import convertDateToFormat from "@helper/convertDateToFormat";
import { cancel, reschedule_meeting } from "@static/image";
import { ToastMessageContext } from "context/toast-context";
import { Button } from "@stories/index";
import HorizontalCalendar from "@components/horizontal-calendar";
import { checkErrorSingleApi } from "@utils/check-error/api-error";
import admissionApi from "api/admission";

const ReschedulePopup = (props) => {
  const { data, setRefresh, scheduleByDate, onClosePopup } = props
  const [info, setInfo] = useState({})
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const onRescheduleMeeting = async () => {
    if (!info?.date || !info?.time) {
      setToastMessage({
        status: 'warning',
        title: 'Invalid Reschedule Time',
      });
      setIsShowToastMessage(true);
      return
    }
    try {
      const payload = {
        date: new Date(info.date),
        time: info.time
      }
      const res = await admissionApi.updateAdmissionCalendar({ id: data.id, data: payload })

      if (checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, 'Reschedule Meeting')) {
        setRefresh(prev => !prev)
        onClosePopup()
      }
    } catch (error) {
      console.log('Reschedule Meeting error', error);
      setToastMessage({
        status: 'error',
        title: 'Reschedule Meeting Failed',
        message: error.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
    }
  }

  return (
    <div className="bg-white w-[31rem] p-3 rounded-lg">
      <div className="flex items-center justify-between">
        <div className="flex items-center text-lg font-medium">
          <img src={reschedule_meeting} className="w-8 aspect-square mr-3" />
          Reschedule
        </div>
        <button onClick={onClosePopup}>
          <img src={cancel} className="w-7 aspect-square" />
        </button>
      </div>

      <hr className="my-4 bg-[#B0B6BE]" />

      <p className="text-base text-[#637381] font-semibold mb-3">
        The due Event date will be changed from <br />
        <span className="text-black">
          {`${convertDateToFormat(data?.date, 'll')} at ${data?.time}`}
        </span> to
      </p>


      <HorizontalCalendar setInfo={setInfo} info={info} scheduleByDate={scheduleByDate} />

      <hr className="mb-3 mt-7 bg-[#B0B6BE]" />

      <div className="flex items-center ml-[60%] w-[40%} justify-between min-w-[12rem] mt-4">
        <Button text='Cancel'
          buttonStyle='outline'
          className={'text-sm'}
          isOutlined
          onClick={onClosePopup}
        />
        <Button text='Reschedule'
          buttonStyle='contained'
          className={'text-sm'}
          onClick={onRescheduleMeeting}
        />
      </div>
    </div>
  )
}

export default ReschedulePopup