import PropTypes from 'prop-types';
import './sibling-card.scss';
import SingleCheckbox from './component/single-checkbox';
import { memo } from 'react';
import TypeAndValue from './component/type-and-value';

const SiblingCard = memo((props) => {
  const { index, data, handleChange } = props
  const { name, active, value, discountType } = data

  return (
    <div className="sibilling-card">
      <SingleCheckbox label={name}
        value={active}
        classLabel='font-extrabold text-lg mb-4'
        onChange={(e) => {
          handleChange({
            index,
            name: 'active',
            value: e.target.checked,
          })
        }}
      />

      <TypeAndValue info={{ discountType, value }}
        isFixed={true}
        label={{ value: 'Value', discountType: 'Discount Type' }}
        handleChange={({ name, value }) => handleChange({ index, name, value })}
      />

    </div>
  )
})
SiblingCard.displayName = 'SiblingCard'
export default SiblingCard

SiblingCard.propTypes = {
  value: PropTypes.number,
  title: PropTypes.string,
  discountValue: PropTypes.string,
  discountChange: PropTypes.func,
};
