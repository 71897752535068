import { memo } from 'react';
import ResidentialCriteria from './ResidentialCriteria';
import SingleCategoryFee from './SingleCategoryFee';

const ResidentialFee = memo((props) => {
  const {
    residentialFeeSave = [],
    numberOfMonthsInAcademicyear = 1,
    options = [],
    termsLength,
    residentialFeeForClasses,
    setResidentialFeeForClasses,
    residentialFromClassId,
    setResidentialFromClassId,
    residentialFee,
    setResidentialFee,
    listClasses,
    burger,
    refresh,
    razorpayBankAccount,
    listBankAccount = [],
    listAcademicYearTerm = []
  } = props;
  return (
    <div className="block w-full">
      <ResidentialCriteria
        residentialFeeForClasses={residentialFeeForClasses}
        setResidentialFeeForClasses={setResidentialFeeForClasses}
        residentialFromClassId={residentialFromClassId}
        setResidentialFromClassId={setResidentialFromClassId}
        listClasses={listClasses}
        title="Residential Fee"
      />
      <SingleCategoryFee
        residentialFeeSave={residentialFeeSave}
        numberOfMonthsInAcademicyear={numberOfMonthsInAcademicyear}
        options={options}
        termsLength={termsLength}
        residentialFee={residentialFee}
        setResidentialFee={setResidentialFee}
        listClasses={listClasses}
        burger={burger}
        refresh={refresh}
        razorpayBankAccount={razorpayBankAccount}
        listBankAccount={listBankAccount}
        listAcademicYearTerm={listAcademicYearTerm}
        title="Residential Fee"
      />
    </div>
  );
});
ResidentialFee.displayName = 'ResidentialFee';
export default ResidentialFee;
