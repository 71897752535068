import PropTypes from 'prop-types';
import './partner-card.scss';
import SingleInput from './component/single-input';
import SingleSelect from './component/single-select';
import TypeAndValue from './component/type-and-value';

export default function PartnerCard(props) {
  const { index, data, handleChange, processApprovals, optionsPartnerType, optionType } = props;
  const { partnerName, partnerType, discountType, value, approval } = data

  return (
    <div className="partner-card">

      {/* Partner Name */}
      <div className='mb-2'>
        <SingleInput label='Partner Name'
          type='text'
          value={partnerName}
          onChange={(e) => {
            handleChange({
              index,
              name: 'partnerName',
              value: e.target.value
            })
          }}
        />
      </div>

      {/* Partner Type */}
      <SingleSelect label='Partner Type'
        value={optionsPartnerType.find(item => item.value === partnerType)}
        options={optionsPartnerType}
        onChange={(e) => {
          handleChange({
            index,
            name: 'partnerType',
            value: e.value
          })
        }}
      />

      <TypeAndValue info={{ discountType, value }}
        isFixed={true}
        className='mb-2'
        label={{ value: 'Value', discountType: 'Discount Type' }}
        handleChange={({ name, value }) => handleChange({ index, name, value })}
      />

      {/* approval */}
      <SingleSelect label='Approval'
        value={approval ? processApprovals.find(item => item.value === approval) : null}
        options={processApprovals}
        onChange={(e) => {
          handleChange({
            index,
            name: 'approval',
            value: e.value,
          })
        }}
      />

    </div>
  )
}


PartnerCard.propTypes = {
  value: PropTypes.number,
  title: PropTypes.string,
  discountValue: PropTypes.string,
  discountChange: PropTypes.func,
  partnerValue: PropTypes.string,
  partnerChange: PropTypes.func,
};
