import React, { useState } from 'react';
import { Checkbox } from '../../../stories';

const CheckboxLabel = (props) => {
  const {
    // deletable,
    // isBuild,
    // isEdit,
    label,
    labelSide,
    height,
    value,
    defaultValue,
    onChange,
    disabled,
  } = props;
  // labelSide : left or right
  const [pValue, setPvalue] = useState(false);

  const handleChange = onChange
    ? onChange
    : () => {
        setPvalue((prev) => !prev);
      };

  const side = labelSide?.value || labelSide;

  return (
    <div
      className="flex items-center justify-around w-full"
      style={{
        height,
      }}
    >
      {side === 'left' && <p className="text-sm">{label}</p>}
      <Checkbox
        value={Boolean(value) || pValue || defaultValue}
        onChange={handleChange}
        disabled={disabled}
      />
      {side === 'right' && <p className="text-sm">{label}</p>}
    </div>
  );
};
export default CheckboxLabel;
