import clsx from 'clsx';
import { useContext, useState } from 'react';
import {
  academic,
  board_fund,
  partner_company,
  partner_school,
  principal_fund,
  sport,
} from '@static/image';
import { ToastMessageContext } from 'context/toast-context';
import ScholarshipInfo from './components/scholarship-info';
import PartnerDiscount from './components/partner-discount';
import SiblingDiscount from './components/sibling-discount';
import { initScholarship } from '@utils/constant';
import { SCHOLARSHIP_STATUS } from '@helper/admission';
import { Box, Stack, Typography } from '@mui/material';
import AddFeeDialog from './components/add-fee-dialog';

const listDataRender = [
  { id: 1, icon: sport, title: 'Sports', name: 'sports' },
  { id: 2, icon: academic, title: 'Academic', name: 'academic' },
  { id: 3, icon: partner_school, title: 'Partner School', name: 'partnerSchool' },
  { id: 4, icon: partner_company, title: 'Partner Company', name: 'partnerCompany' },
  { id: 5, icon: principal_fund, title: 'Principal Fund', name: 'principalFund' },
  { id: 6, icon: board_fund, title: 'Board Fund', name: 'boardFund' },
  { id: 7, icon: board_fund, title: 'Sibling Scholarship', name: 'siblingScholarship' },
];

const optionFees = [
  { value: 'Admission Fee', label: 'Admission Fee' },
  { value: 'Tuition Fee', label: 'Tuition Fee' },
  { value: 'Residential Fee', label: 'Residential Fee' },
  { value: 'Food Fee', label: 'Food Fee' },
  { value: 'Transport Fee', label: 'Transport Fee' },
  { value: 'Custom Fee', label: 'Custom Fee' },
];

function ScholarshipItem(props) {
  const {
    data,
    idxScholarship,
    // setFeeInfo,
    admissionFee,
    objValueType,
    // onOpenDialog,
    extraInfo,
    isParent,
    scholarshipEnables,
    setScholarships,
    scholarships,
    scholarshipOriginal,
    getEstFee,
  } = props;
  const [open, setOpen] = useState(false);
  const [feeInfo, setFeeInfo] = useState();
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const onRemoveScholarship = () => {
    setScholarships((prev) => prev.filter((_, index) => index !== idxScholarship));
  };

  const onSelectScholarship = (index, scholarshipOrder) => {
    setFeeInfo();
    setScholarships((prev) => {
      const scholarshipIds = prev.map((item) => item.scholarshipId);
      if (scholarshipIds.includes(scholarshipOrder)) {
        setToastMessage({
          status: 'info',
          title: 'Validate Rule',
          message: 'This scholarship already exists, please choose another scholarship',
        });
        setIsShowToastMessage(true);
        return prev;
      }
      const original = JSON.parse(scholarshipOriginal);
      if (original?.[index]?.scholarshipId === scholarshipOrder) {
        prev[index] = original[index];
        return [...prev];
      }
      prev[index] = JSON.parse(JSON.stringify(initScholarship));
      prev[index].scholarshipId = scholarshipOrder;
      return [...prev];
    });
  };

  const optionFeeFiltered = () => {
    if (!open) return [];
    const feesExisted = {};
    for (let fee of scholarships[idxScholarship].info.fees) {
      const { name } = fee;
      if (!(name in feesExisted)) feesExisted[name] = name;
    }
    return optionFees.filter((fee) => !(fee.value in feesExisted));
  };

  const onAddFee = (feeName) => {
    setOpen(false);
    let newFee = {
      active: true,
      name: feeName,
      value: '',
      valueType: objValueType.Amount,
      estFee: getEstFee(feeName),
    };
    if (feeInfo)
      newFee = {
        active: true,
        name: feeName,
        value: feeInfo.value,
        valueType: feeInfo.discountType.includes(objValueType.Amount)
          ? objValueType.Amount
          : objValueType.Percentage,
        estFee: getEstFee(feeName),
      };
    setScholarships((prev) => {
      prev[idxScholarship].info.fees = [...prev[idxScholarship].info.fees, newFee];
      return [...prev];
    });
  };

  const onOpenDialog = () => {
    setOpen(true);
  };

  const mapScholarship = {
    1: (data) => (
      <ScholarshipInfo
        startOrder={2}
        data={data}
        editFee={true}
        isParent={isParent}
        admissionFee={admissionFee}
        onOpenDialog={onOpenDialog}
        objValueType={objValueType}
        idxScholarship={idxScholarship}
        setScholarships={setScholarships}
      />
    ),
    2: (data) => (
      <ScholarshipInfo
        startOrder={2}
        data={data}
        editFee={true}
        isParent={isParent}
        admissionFee={admissionFee}
        objValueType={objValueType}
        onOpenDialog={onOpenDialog}
        idxScholarship={idxScholarship}
        setScholarships={setScholarships}
      />
    ),
    3: (data) => (
      <PartnerDiscount
        partnerNames={extraInfo?.listPartnerSchool || []}
        startOrder={2}
        data={data}
        isParent={isParent}
        admissionFee={admissionFee}
        objValueType={objValueType}
        onOpenDialog={onOpenDialog}
        idxScholarship={idxScholarship}
        setScholarships={setScholarships}
        setFeeInfo={setFeeInfo}
      />
    ),
    4: (data) => (
      <PartnerDiscount
        partnerNames={extraInfo?.listPartnerCompany || []}
        startOrder={2}
        data={data}
        isParent={isParent}
        admissionFee={admissionFee}
        objValueType={objValueType}
        onOpenDialog={onOpenDialog}
        idxScholarship={idxScholarship}
        setScholarships={setScholarships}
        setFeeInfo={setFeeInfo}
      />
    ),
    5: (data) => (
      <ScholarshipInfo
        startOrder={2}
        data={data}
        editFee={true}
        isParent={isParent}
        admissionFee={admissionFee}
        objValueType={objValueType}
        onOpenDialog={onOpenDialog}
        idxScholarship={idxScholarship}
        setScholarships={setScholarships}
      />
    ),
    6: (data) => (
      <ScholarshipInfo
        startOrder={2}
        data={data}
        editFee={true}
        isParent={isParent}
        admissionFee={admissionFee}
        objValueType={objValueType}
        onOpenDialog={onOpenDialog}
        idxScholarship={idxScholarship}
        setScholarships={setScholarships}
      />
    ),
    7: (data) => (
      <SiblingDiscount
        sibling={extraInfo?.sibling}
        startOrder={2}
        data={data}
        editFee={false}
        isParent={isParent}
        admissionFee={admissionFee}
        objValueType={objValueType}
        onOpenDialog={onOpenDialog}
        setFeeInfo={setFeeInfo}
        idxScholarship={idxScholarship}
        setScholarships={setScholarships}
      />
    ),
  };

  return (
    <>
      <Stack
        direction="row"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        height={4}
        my={2}
      >
        <Box>{renderStatus(data.status)}</Box>
        <button onClick={() => onRemoveScholarship(idxScholarship)}>
          <Close />
        </button>
      </Stack>
      <div className="mb-10 w-[100%] px-3">
        <p className="text-sm">1. Select scholarship</p>
        <div className="flex flex-wrap items-center justify-center">
          {listDataRender.map((item, idx) => {
            if (!scholarshipEnables[item.name]) return null;
            return (
              <button
                key={item.id}
                className={clsx(
                  'border-2 rounded-lg w-[8rem] my-[1rem] h-[9.5rem] mx-[2%] text-center cursor-pointer',
                  item.id === data.scholarshipId
                    ? 'border-indigo-500/100'
                    : 'border-[#637381]',
                )}
                style={{
                  boxShadow:
                    item.id === data.scholarshipId
                      ? '0px 8px 16px 0px #00AB553D'
                      : 'none',
                }}
                onClick={() => onSelectScholarship(idxScholarship, item.id)}
              >
                <img
                  className="m-auto w-12 h-12 mt-[10%] mb-[10%]"
                  src={listDataRender[idx].icon}
                  alt={data.title}
                />
                <p className="px-2">{item.title}</p>
              </button>
            );
          })}
        </div>
        {data.scholarshipId && mapScholarship[data.scholarshipId](data)}
        <AddFeeDialog
          open={open}
          onClose={() => setOpen(false)}
          optionFees={optionFeeFiltered()}
          handleAdd={(feeName) => onAddFee(feeName)}
        />
      </div>
    </>
  );
}

export default ScholarshipItem;

const Close = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.51367 11.743L6.75667 6.49996L11.9997 11.743M11.9997 1.25696L6.75567 6.49996L1.51367 1.25696"
        stroke="#EB5757"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const renderStatus = (status) => {
  if (status === SCHOLARSHIP_STATUS.approved)
    return (
      <Box sx={{ backgroundColor: 'rgba(84, 214, 44, 0.16)', borderRadius: '1rem' }}>
        <Typography variant="body2" sx={{ color: '#229A16', padding: '2px 8px' }}>
          {SCHOLARSHIP_STATUS.approved}
        </Typography>
      </Box>
    );
  if (status === SCHOLARSHIP_STATUS.waitingApproval)
    return (
      <Box sx={{ backgroundColor: '#FFF2E2', borderRadius: '1rem' }}>
        <Typography variant="body2" sx={{ color: '#F2C94C', padding: '2px 8px' }}>
          {SCHOLARSHIP_STATUS.waitingApproval}
        </Typography>
      </Box>
    );
  if (status === SCHOLARSHIP_STATUS.rejected)
    return (
      <Box sx={{ backgroundColor: '#FFF2E2', borderRadius: '1rem' }}>
        <Typography variant="body2" sx={{ color: '#EB5757', padding: '3px 8px' }}>
          {SCHOLARSHIP_STATUS.rejected}
        </Typography>
      </Box>
    );
  return <Box />;
};
