import { useContext, useEffect, useState } from 'react';
import { Fade } from 'react-reveal';
import { useLocation, useHistory } from 'react-router-dom';
import { ToastMessageContext } from '../../context/toast-context';
import AutocompletePlace from '@components/autocomplete-place';
import { PlaceTypes } from '@constant/index';
import useMap from '@hooks/google-map';
import { dataLinks } from '../../entities/routes';
import { staticsize } from '../../static/image';
import { Chip, Input, SelectGroup, Upload, Button } from '../../stories';
import { NavLinks } from '../Header/components/index';
import './create-campus.scss';
import { checkErrorSingleApi } from '@utils/check-error/api-error';
import mediaApi from 'api/media';
import authApi from 'api/auth';
import { LoadingButton } from '@mui/lab';
import DefaultAvatar from '@components/default-avatar';
import useStorage from 'store/storage';
import { selectStylesApprovers } from 'entities/select-style';

const styles = {
  btnWhite: {
    width: '6.563rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    backgroundColor: '#404eed',
    height: '2.563rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    '&:hover': {
      backgroundColor: 'rgba(64, 78, 237, 0.9)',
    },
  },
  btnCancel: {
    width: '6.563rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#404eed',
    backgroundColor: 'white',
    height: '2.563rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    border: '1px solid #404eed',
  },
};

const colorOption = ['#404EED', '#663174', '#F2C94C', '#00AB55', '#FFA48D'];

const CustomOption = (props) => {
  return (
    <div {...props.innerProps} style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={props.data.info?.photoURL}
        alt=""
        style={{ width: 20, height: 20, marginRight: 10 }}
      />
      <div>
        <div>{props.data.label}</div>
        {props.data.info?.phoneNumber && (
          <div style={{ fontSize: '12px', color: 'gray' }}>
            {props.data.info.phoneNumber}
          </div>
        )}
        {props.data.info?.email && (
          <div style={{ fontSize: '12px', color: 'gray' }}>{props.data.info.email}</div>
        )}
      </div>
    </div>
  );
};

export default function CreateCampus({ burger }) {
  const { getPlaces } = useMap();
  const [campus, setCampus] = useState(null);
  const [avatar, setAvatar] = useState('');
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const location = useLocation();
  const history = useHistory();
  const { updateCampuses, getCurrentCampus, updateCurrentCampus } = useStorage();
  const currentCompanyId = useStorage((state) => state.auth.companyId);
  const [listUsersOfCompany, setListUsersOfCompany] = useState([]);
  const companyId = location?.state?.companyId || currentCompanyId;
  const currentCampus = getCurrentCampus();

  const onChangePlace = async (place) => {
    if (place) {
      let latitude = place.geometry.location.lat();
      let longitude = place.geometry.location.lng();
      const { data, error } = await getPlaces({ latitude, longitude });
      if (error) {
        setToastMessage({
          status: 'error',
          title: 'Google Map',
          message: 'Something went wrong. Please try again',
        });
        setIsShowToastMessage(true);
        return;
      }
      if (data.data.results.length !== 0) {
        let country = '';
        let state = '';
        let city = '';
        let zipCode = '';
        for (let address of data.data.results[0].address_components) {
          const { long_name, types } = address;
          if (types.includes(PlaceTypes.locality)) city = long_name;
          if (types.includes(PlaceTypes.administrative_area_level_1)) {
            if (!city) city = long_name;
            state = long_name;
          }
          if (types.includes(PlaceTypes.country)) country = long_name;
          if (types.includes(PlaceTypes.postal_code)) zipCode = long_name;
        }
        setCampus((prev) => ({
          ...prev,
          country,
          state,
          city,
          zipCode,
          address: [place.formatted_address],
        }));
      }
    } else {
      setToastMessage({
        status: 'error',
        title: 'Google Map',
        message: 'Something went wrong. Please try again',
      });
      setIsShowToastMessage(true);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await authApi.getListUserOfCompany({
          companyId,
          isGetAll: true,
          excludeRoleNames: 'Parent;Student',
          isGetNoRole: true,
        });
        const mappingUsers = res.data.data.objects.map((user, index) => {
          return {
            name: user.name,
            label: user.name,
            value: user.name,
            color: colorOption[index % colorOption.length],
            info: user,
          };
        });
        setListUsersOfCompany(mappingUsers);
      } catch (error) {
        setToastMessage({
          status: 'error',
          title: 'Get List Users Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Users Error', error);
      }
    };

    fetchUsers();
  }, []);

  const handleShowWarning = (field) => {
    setToastMessage({
      status: 'warning',
      title: 'Validation Rule',
      message: 'Missing field ' + field,
    });
    setIsShowToastMessage(true);
  };

  const validation = (campus) => {
    const listField = [
      'name',
      'code',
      'address',
      'country',
      'city',
      'state',
      'zipCode',
      'phoneNumber',
      'principals',
      'admissionHeads',
      'cashiers',
    ];
    for (let field of listField) {
      if (!campus[field]) {
        handleShowWarning(field);
        return false;
      } else if (Array.isArray(campus[field]) && campus[field].length === 0) {
        handleShowWarning(field);
        return false;
      }
    }
    return true;
  };

  const createCampus = async (campus) => {
    if (!validation(campus)) return;
    try {
      const data = {
        ...(({ dateUpdated, dateCreated, id, ...o }) => ({
          ...o,
          companyId,
        }))(campus),
      };
      const res = await authApi.createCampus(data);
      if (
        checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, 'Create Campus')
      ) {
        if (res.data.data.companyId === currentCompanyId) {
          const resCampuses = await authApi.getListCampusUser({ isGetAll: true });
          updateCampuses(resCampuses.data.data.objects);
          if (!currentCampus) updateCurrentCampus(resCampuses.data.data.objects[0]);
        }
        history.goBack();
      }
    } catch (error) {
      setToastMessage({
        status: 'error',
        title: 'Create Campus Failed',
        message: error.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
      console.log('Create Campus Error', error);
    }
  };

  const handleSubmit = async (e, campus) => {
    try {
      setIsLoading(true);
      e.preventDefault();
      if (file) {
        const formData = new FormData();
        formData.set('file', file);
        formData.set('mediaId', file.name);
        const res = await mediaApi.uploadMedia(formData);
        if (
          checkErrorSingleApi(
            res,
            setToastMessage,
            setIsShowToastMessage,
            'Upload Logo',
            false,
          )
        ) {
          campus.logo = res.data.data;
          await createCampus(campus);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Upload Logo Failed',
            message: res?.data?.message,
          });
          setIsShowToastMessage(true);
        }
      } else await createCampus(campus);
      setIsLoading(false);
    } catch (error) {
      setToastMessage({
        status: 'error',
        title: '',
        message: 'Something went wrong. Please try again',
      });
      setIsShowToastMessage(true);
      console.log('Campus Detail Error', error);
      setIsLoading(false);
    }
  };

  const onChange = (name, value) => {
    setCampus({ ...campus, [name]: value });
  };

  const onCancel = () => {
    setCampus(null);
  };

  const formatOptionLabelUser = ({ label, info }) => {
    return (
      <div className="block">
        <Chip
          key={info?.id}
          label={label}
          image={info?.photoURL}
          avatarElement={<DefaultAvatar title={label} />}
          chipAvatarStyle={{ width: 18, height: 18 }}
          styleChip={'object-cover'}
          customStyle={{ padding: '0 !important', margin: '0', fontSize: '0.875rem' }}
        />
      </div>
    );
  };

  const filterOption = (option, filterString) => {
    const lowerCaseFilterString = filterString.toLowerCase();
    return (
      option.value.toLowerCase().includes(lowerCaseFilterString) ||
      option.label.toLowerCase().includes(lowerCaseFilterString) ||
      (option.data?.info && option.data.info.email.includes(lowerCaseFilterString)) ||
      (option.data?.info && option.data.info.phoneNumber.includes(lowerCaseFilterString))
    );
  };

  return (
    <div className="campus-detail px-8 w-full">
      <div>
        <Fade clear duration={300}>
          <NavLinks urls={dataLinks.campusDetailLinks} />
          <h1 className="namepage"> Create a New Campus </h1>
          <div className="campus-detail-form">
            <div className="campus-detail-form_section campus-detail-section">
              <div className="campus-detail-section_header">
                <h2 className="campus-detail-section_title">1. Campus Image</h2>
                <p className="campus-detail-section_text">
                  Upload a campus image to make it easily recognizable.
                </p>
              </div>
              <div className="campus-detail-upload">
                <img
                  className="campus-detail-upload_image"
                  src={avatar || staticsize}
                  alt="uploaded logo"
                />
                <Upload
                  text="Select Image"
                  id="image-upload"
                  accept="image/*"
                  onChange={(e) => {
                    let selected = e.target.files;
                    if (selected && selected?.[0]) {
                      const tmp = selected[0];
                      setFile(tmp);
                      const reader = new FileReader();
                      reader.readAsDataURL(tmp);
                      reader.onload = () => {
                        setAvatar(reader.result);
                      };
                      reader.onerror = function (error) {
                        console.log('Error: ', error);
                      };
                    }
                  }}
                />
              </div>
            </div>
            <div className="campus-detail-form_section campus-detail-section">
              <div className="campus-detail-section_header">
                <h2 className="campus-detail-section_title">2. Campus Details</h2>
                <p className="campus-detail-section_text">
                  Add/ Edit campus details including name, address and other essentials.
                </p>
              </div>
              <div className="campus-detail-section_content gap-x-0">
                <div className="flex">
                  <Input
                    onChange={(e) => onChange('name', e.target.value)}
                    label="Campus Name"
                    name="campus-name"
                    value={campus?.name || ''}
                    customStyle={{ width: '14.25rem' }}
                  />
                  <Input
                    onChange={(e) => onChange('code', e.target.value)}
                    label="Campus Code"
                    name="campus-code"
                    value={campus?.code || ''}
                    labelFix="left-10"
                    customStyle={{
                      width: '14.25rem',
                      marginLeft: '1.75rem',
                    }}
                  />
                </div>
                <AutocompletePlace
                  onChange={onChangePlace}
                  types={['route']}
                  renderInput={() => (
                    <Input
                      onChange={(e) => onChange('address', [e.target.value])}
                      label="Address"
                      name="address"
                      value={campus?.address?.[0] || ''}
                      customStyle={{ width: '30.25rem' }}
                    />
                  )}
                />
                <div className="flex">
                  <Input
                    onChange={(e) => onChange('country', e.target.value)}
                    label="Country"
                    name="country"
                    value={campus?.country || ''}
                    customStyle={{ width: '14.25rem' }}
                  />
                  <Input
                    onChange={(e) => onChange('city', e.target.value)}
                    label="City"
                    name="city"
                    value={campus?.city || ''}
                    labelFix="left-10"
                    customStyle={{
                      width: '14.25rem',
                      marginLeft: '1.75rem',
                    }}
                  />
                </div>
                <div className="flex">
                  <Input
                    onChange={(e) => onChange('state', e.target.value)}
                    label="State/Province"
                    name="state"
                    value={campus?.state || ''}
                    customStyle={{ width: '14.25rem', fontSize: 18 }}
                  />
                  <Input
                    onChange={(e) => onChange('zipCode', e.target.value)}
                    label="Zip Code"
                    name="zip-code"
                    value={campus?.['zipCode'] || ''}
                    labelFix="left-10"
                    customStyle={{
                      width: '14.25rem',
                      marginLeft: '1.75rem',
                    }}
                  />
                </div>
                <Input
                  onChange={(e) => onChange('phoneNumber', [e.target.value])}
                  label="Phone Number"
                  name="phone"
                  value={campus?.['phoneNumber'] || ''}
                  customStyle={{ width: '30.25rem' }}
                />
              </div>
            </div>
            <div className="campus-detail-form_section campus-detail-section">
              <div className="campus-detail-section_header">
                <h2 className="campus-detail-section_title">3. Campus Details</h2>
              </div>
              <div className="campus-detail-section_content gap-x-0">
                <div className="admission-approvals-edit_column grow w-[40rem]">
                  <div>
                    <SelectGroup
                      title="Principle"
                      // defaultValue={campus?.principals}
                      value={campus?.principals || null}
                      styles={selectStylesApprovers}
                      options={listUsersOfCompany}
                      formatOptionLabel={formatOptionLabelUser}
                      isMulti={true}
                      isSearchable={true}
                      onChange={(e) => {
                        if (e.length >= 1) {
                          onChange('principals', [e[e.length - 1]]);
                        } else {
                          onChange('principals', e);
                        }
                      }}
                      components={{
                        Option: CustomOption,
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      filterOption={filterOption}
                    />
                  </div>
                </div>
                <div className="admission-approvals-edit_column grow w-[40rem]">
                  <div>
                    <SelectGroup
                      title="Admission Head"
                      // defaultValue={campus?.admissionHeads}
                      value={campus?.admissionHeads || null}
                      styles={selectStylesApprovers}
                      options={listUsersOfCompany}
                      formatOptionLabel={formatOptionLabelUser}
                      isMulti={true}
                      isSearchable={true}
                      onChange={(e) => onChange('admissionHeads', e)}
                      components={{
                        Option: CustomOption,
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      filterOption={filterOption}
                    />
                  </div>
                </div>
                <div className="admission-approvals-edit_column grow w-[40rem]">
                  <div>
                    <SelectGroup
                      title="Cashier"
                      // defaultValue={campus?.cashiers}
                      value={campus?.cashiers || null}
                      styles={selectStylesApprovers}
                      options={listUsersOfCompany}
                      formatOptionLabel={formatOptionLabelUser}
                      isMulti={true}
                      isSearchable={true}
                      onChange={(e) => onChange('cashiers', e)}
                      components={{
                        Option: CustomOption,
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      filterOption={filterOption}
                    />
                  </div>
                </div>
                <div className="campus-detail-section_footer">
                  <Button
                    text="Cancel"
                    customStyle={styles.btnCancel}
                    onClick={onCancel}
                  />

                  <LoadingButton
                    loading={isLoading}
                    sx={styles.btnWhite}
                    loadingPosition="start"
                    startIcon={<></>}
                    variant="contained"
                    onClick={(e) => handleSubmit(e, campus)}
                  >
                    Save
                  </LoadingButton>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}
