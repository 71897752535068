import { reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import React, { useEffect, useState, useCallback, useContext } from 'react';
import Fade from 'react-reveal/Fade';
import { NavLink, useHistory } from 'react-router-dom';
import { useAuth } from '../../../Login/components/UserLogin/firebaseSignin';
import { Input } from '../../../../components/index.jsx';
import { Logo } from '../../index.jsx';
import { forgot_password, invisible, visible } from '../image';
import firebase from '../UserLogin/firebase';
import '../UserLogin/index.scss';
import { ToastMessageContext } from '../../../../context/toast-context';
import useStorage from 'store/storage';

export default function ChangePassword(props) {
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const { burger } = props;
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [reEnterNewPassword, setReEnterNewPassword] = useState('');
  const [show, setShow] = useState({});
  const history = useHistory();
  const { signOut } = useAuth();
  const userLogin = useStorage((state) => state.auth);
  const tenantId = userLogin.tenantId;
  useEffect(() => {
    let user = firebase.auth().currentUser; //Need to check why getting currentUser at the first time === null
  }, []);

  async function handleChangePassword() {
    const auth = firebase.auth();
    auth.tenantId = tenantId;
    const user = auth.currentUser;
    if (!oldPassword) {
      setToastMessage({
        status: 'error',
        title: 'Validation Rule',
        message: 'Please old password',
      });
      setIsShowToastMessage(true);
    } else if (!newPassword) {
      setToastMessage({
        status: 'error',
        title: 'Validation Rule',
        message: 'Please enter the new password',
      });
      setIsShowToastMessage(true);
    } else if (newPassword !== reEnterNewPassword) {
      setToastMessage({
        status: 'error',
        title: 'Validation Rule',
        message: 'The confirmation password is incorrect',
      });
      setIsShowToastMessage(true);
    } else {
      const credential = firebase.auth.EmailAuthProvider.credential(
        userLogin.email,
        oldPassword,
      );

      reauthenticateWithCredential(user, credential)
        .then(function () {
          updatePassword(user, newPassword).then(
            (result) => {
              setToastMessage({
                status: 'success',
                title: 'Successfully',
                message: 'Change Password Successfully',
              });
              setIsShowToastMessage(true);
              history.push('/home');
            },
            (error) => {
              setToastMessage({
                status: 'error',
                title: 'Error',
                message: 'Change Password Failed',
              });
              setIsShowToastMessage(true);
              console.log('error', error);
              history.go('/home');
            },
          );
        })
        .catch(function (error) {
          setToastMessage({
            status: 'error',
            title: 'Error',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
        });
    }
  }

  const handleShow = useCallback((key) => {
    setShow((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  }, []);

  return (
    <div className="login account-type pt-10" style={{ background: '#E0ECE4' }}>
      <div className="m-auto" style={{ paddingLeft: burger ? '13rem' : '' }}>
        <NavLink className="mb-6 block" to="/">
          <Logo fill="#A5BBAC" />
        </NavLink>
        <Fade clear duration={300}>
          <div className="box" style={{ width: '42rem' }}>
            <h1 className="text-3xl font-black">Change Your Password</h1>
            <p
              className="text-second-gray font-normal text-base mt-1"
              style={{ width: '28rem' }}
            >
              Thank you for verfying your email.Please enter a new password
            </p>
            <div className="flex mt-5" style={{ width: '19rem' }}>
              <div className="w-full">
                <div className="relative w-full">
                  <Input
                    name="password"
                    type={show.old ? 'text' : 'password'}
                    label="Old Password"
                    value={oldPassword}
                    customStyle={{
                      width: '100%',
                      marginBottom: '1rem',
                      height: '3rem',
                    }}
                    onChange={(e) => {
                      setOldPassword(e.target.value);
                    }}
                  />
                  <img
                    className="absolute"
                    onClick={() => {
                      handleShow('old');
                    }}
                    src={show.old ? visible : invisible}
                    alt="show"
                    style={{
                      cursor: 'pointer',
                      top: '50%',
                      right: '1rem',
                      transform: 'translateY(-50%)',
                    }}
                  />
                </div>
                <div className="relative w-full">
                  <Input
                    name="password"
                    type={show.new ? 'text' : 'password'}
                    label="New Password"
                    value={newPassword}
                    customStyle={{
                      width: '100%',
                      marginBottom: '1rem',
                      height: '3rem',
                    }}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                  />
                  <img
                    className="absolute"
                    onClick={() => {
                      handleShow('new');
                    }}
                    src={show.new ? visible : invisible}
                    alt="show"
                    style={{
                      cursor: 'pointer',
                      top: '50%',
                      right: '1rem',
                      transform: 'translateY(-50%)',
                    }}
                  />
                </div>
                <div className="relative w-full">
                  <Input
                    name="password"
                    type={show.reEnter ? 'text' : 'password'}
                    label="Re-enter new Password"
                    value={reEnterNewPassword}
                    customStyle={{
                      width: '100%',
                      marginBottom: '1rem',
                      height: '3rem',
                    }}
                    onChange={(e) => {
                      setReEnterNewPassword(e.target.value);
                    }}
                  />
                  <img
                    className="absolute"
                    onClick={() => {
                      handleShow('reEnter');
                    }}
                    src={show.reEnter ? visible : invisible}
                    alt="show"
                    style={{
                      cursor: 'pointer',
                      top: '50%',
                      right: '1rem',
                      transform: 'translateY(-50%)',
                    }}
                  />
                </div>
                <button
                  className="box-submit"
                  onClick={() => {
                    handleChangePassword();
                  }}
                  style={{ width: '19rem', marginTop: '1.5rem' }}
                  type="submit"
                >
                  Reset Password
                </button>
              </div>
              <img src={forgot_password} alt="forgot_password" className="dodles" />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}
