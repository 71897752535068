////INPUT
// info1: [
//   {
//       "id": 1,
//       "label": "Label1",
//       "value": "https://storage.googleapis.com/giiki-bucket/media/$2b$10$gPszbbx3vGCgLmiYMry4OEBetPYZe4qeI4YBatFdvK8BHzoFsJJG",
//       "componentName": "Photo",
//       "variationOf": "photo"
//   },
//   {
//       "id": 2,
//       "label": "Gender",
//       "value": "Male",
//       "componentName": "Gender",
//       "variationOf": "checkbox"
//   },
//   {
//       "id": 3,
//       "label": "",
//       "value": "",
//       "componentName": "Page Break",
//       "variationOf": "pageBreak"
//   },
//   {
//       "id": 4,
//       "label": "First Name",
//       "value": "first name",
//       "componentName": "First Name",
//       "variationOf": "textArea"
//   },
//   {
//       "id": 5,
//       "label": "Middle Name",
//       "value": "middle name",
//       "componentName": "Middle Name",
//       "variationOf": "textArea"
//   },
//   {
//       "id": 6,
//       "label": "Last Name",
//       "value": "last name",
//       "componentName": "Last Name",
//       "variationOf": "textArea"
//   },
//   {
//       "id": 7,
//       "label": "Date of Birth",
//       "value": "",
//       "componentName": "Date of Birth",
//       "variationOf": "datePicker"
//   },
//   {
//       "id": 8,
//       "label": "Anticipated Grade of Entry",
//       "value": {
//           "value": "65d8952f-eefd-4439-972e-982e8d88e806",
//           "label": "Pre Kindergarten 3"
//       },
//       "componentName": "AnticipatedGradeOfEntry",
//       "variationOf": "multiselect"
//   },
//   {
//       "id": 9,
//       "label": "Enrollment Year",
//       "value": {
//           "value": "ac14b4ba-d077-4154-ae92-87cbdf19c022",
//           "label": "CBSE Academic 1"
//       },
//       "componentName": "EnrollmentYear",
//       "variationOf": "multiselect"
//   },
//   {
//       "id": 10,
//       "label": "Current School",
//       "value": "current school",
//       "componentName": "Text Area",
//       "variationOf": "textArea"
//   }
// ]
// info2: [
//   [
//       {
//           "id": 1,
//           "label": "Avatar",
//           "value": "https://storage.googleapis.com/giiki-bucket/media/$2b$10$GbkJdi92syn2h35uFTglROBe6xLEJJBb1UTVjc7db5vZuy8n97sna",
//           "componentName": "Photo",
//           "variationOf": "photo"
//       },
//       {
//           "id": 2,
//           "label": "Full Name",
//           "value": "full name",
//           "componentName": "Full Name",
//           "variationOf": "textArea"
//       },
//       {
//           "id": 3,
//           "label": "Gender",
//           "value": "Male",
//           "componentName": "Gender",
//           "variationOf": "checkbox"
//       },
//       {
//           "id": 4,
//           "label": "Relationship with applicant",
//           "value": {
//               "value": "Mother",
//               "label": "Mother"
//           },
//           "componentName": "Relationship",
//           "variationOf": "multiselect"
//       },
//       {
//           "id": 5,
//           "label": "Occupation",
//           "value": {
//               "value": "Teacher",
//               "label": "Teacher"
//           },
//           "componentName": "Dropdown",
//           "variationOf": "multiselect"
//       },
//       {
//           "id": 6,
//           "label": "Phone Number",
//           "value": "123456789",
//           "componentName": "Phone Number",
//           "variationOf": "textArea"
//       },
//       {
//           "id": 7,
//           "label": "Email",
//           "value": "hhh@gmail.com",
//           "componentName": "Text Area",
//           "variationOf": "textArea"
//       }
//   ],
//   [
//       {
//           "id": 1,
//           "label": "Avatar",
//           "value": {},
//           "componentName": "Photo",
//           "variationOf": "photo"
//       },
//       {
//           "id": 2,
//           "label": "Full Name",
//           "value": "full name",
//           "componentName": "Full Name",
//           "variationOf": "textArea"
//       },
//       {
//           "id": 3,
//           "label": "Gender",
//           "value": "Male",
//           "componentName": "Gender",
//           "variationOf": "checkbox"
//       },
//       {
//           "id": 4,
//           "label": "Relationship with applicant",
//           "value": {
//               "value": "Mother",
//               "label": "Mother"
//           },
//           "componentName": "Relationship",
//           "variationOf": "multiselect"
//       },
//       {
//           "id": 5,
//           "label": "Occupation",
//           "value": {
//               "value": "Teacher",
//               "label": "Teacher"
//           },
//           "componentName": "Dropdown",
//           "variationOf": "multiselect"
//       },
//       {
//           "id": 6,
//           "label": "Phone Number",
//           "value": "123456789",
//           "componentName": "Phone Number",
//           "variationOf": "textArea"
//       },
//       {
//           "id": 7,
//           "label": "Email",
//           "value": "hhh@gmail.com",
//           "componentName": "Text Area",
//           "variationOf": "textArea"
//       }
//   ]
// ]
////OUTPUT
// {
//     Label1: 'https://storage.googleapis.com/giiki-bucket/media/$2b$10$cAeIllfpf41Bw.TqY1qFF.EwfjfCdsPfVdxdd4T7NsEfRARyg90Em',
//     Label2: 'my name',
//     'First Name': 'first',
//     'Middle Name': 'middle',
//     'Last Name': 'last',
//     'Date of Birth': '2022-12-16T10:49:17.000Z',
//     'Anticipated Grade of Entry': '014b4688-af61-4acb-bd53-08fbde65ff55',
//     Gender: 'Male',
//     'Enrollment Year': '21c190c3-49cf-428f-8d6e-0ce3dfdbb91e',
//     'Campus name': '',
//     'custom 1': 'custom'
//   }

import { stringToCamelCase } from '../utils/utils';

const transformSingleArray = (data) => {
  const result = {};
  for (const [key, value] of Object.entries(data)) {
    result[stringToCamelCase(key)] = value?.value?.label ?? value?.value ?? value;
  }
  return result;
};

export function transformDataFormBuilder(data) {
  if (!data) return [];
  if (Object.keys(data).length === 0) return [];

  const result = [];
  if (Array.isArray(data[0])) {
    data.forEach((info) => {
      result.push(transformSingleArray(info));
    });
  } else return transformSingleArray(data);

  return result;
}
