import { ic_close_black } from '@static/image';
import { memo, useContext, useEffect, useState } from 'react';
import { Input, SelectGroup } from '../../../../../stories';
import { EnquiryStatus } from 'entities/data';
import gradeApi from 'api/gradeApi';
import { checkErrorApiFetch } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';

const SchoolInformation = memo((props) => {
  const { enquiry, styles, info, error, listField, handleChange, handleAdd, handleRemove } = props;
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const [grades, setGrades] = useState()

  useEffect(() => {
    const fetch = async () => {
      const { res, error } = await gradeApi.getGrade()

      if (error) {
        setToastMessage({
          status: 'error',
          title: 'Get Grades',
          message: error?.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      }

      if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, false)) {
        const tmpGrades = res.data.data.objects.map(item => ({ value: item.id, label: item.name }))
        setGrades(tmpGrades)
      }
    }

    fetch();
  }, [])


  return (
    <div>
      {info &&
        info.length !== 0 &&
        info.map((item, index) => {
          if (item.isDelete) return null
          return (
            <SingleSchool
              key={`school${index}`}
              enquiry={enquiry}
              grades={grades}
              info={item}
              order={index}
              listField={listField}
              styles={styles}
              error={error?.[index]}
              handleChange={handleChange}
              handleRemove={handleRemove}
            />
          );
        })}
      <div className="bottom-7 flex flex-col items-center justify-center h-[10rem]">
        <div
          className="flex items-center justify-center h-16 w-16 rounded-full text-main-blue text-xl cursor-pointer"
          style={{ background: '#E5EFFD' }}
          onClick={() => {
            handleAdd({ name: 'schoolInfo' });
          }}
        >
          +
        </div>
        <p className="text-xs mt-2 text-button-disable">Add School History</p>
      </div>
    </div>
  );
});
SchoolInformation.displayName = 'SchoolInformation';
export default SchoolInformation;

const SingleSchool = memo((props) => {
  const { enquiry, grades, order, info, handleChange, handleRemove } = props;

  const style = {
    container: (styles) => ({ ...styles, width: '100%' }),
    control: (styles) => ({
      ...styles,
      width: '100%',
      border: '1px solid  rgba(145, 158, 171, 0.32)',
      boxShadow: 'none',
      cursor: 'pointer',
      borderRadius: '0.25rem',
      padding: '0 0 0 0',
      marginLeft: '0',
    }),
    indicatorSeparator: () => null,
    input: (styles) => ({ ...styles, height: '2.5rem', width: '6.25rem', color: '#212B36' }),
    indicatorsContainer: () => null,
    option: (styles, state) => ({
      ...styles,
      height: 'fit-content',
      width: '90%',
      padding: '0.438rem',
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
      backgroundColor: state.isSelected ? '#e2e2e2' : 'white',
      borderRadius: '0.25rem',
      color: '#212B36',
      cursor: 'pointer',
      '&:hover': state.isSelected
        ? { backgroundColor: '#e2e2e2' }
        : { backgroundColor: '#404eed', color: 'white' },
    }),
    menu: (styles) => ({ ...styles, width: '100%', zIndex: '2' }),
    singleValue: (styles) => ({
      ...styles,
      color: '#212B36',
      fontSize: '1rem',
      paddingLeft: '0.25rem',
    }),
  };


  return (
    <div className="information-field_application py-8 px-8 mb-4 min-h-fit">
      <div className="flex w-full gap-x-4">
        <Input
          label="School Name"
          value={info?.schoolName || ''}
          classGroup="w-[40%]"
          customStyle={{ height: 'unset' }}
          onChange={(e) =>
            handleChange({
              name: 'schoolInfo',
              order,
              key: 'schoolName',
              value: e.target.value,
            })
          }
        />

        <SelectGroup
          title='Start Grade'
          classNameGroup='w-[25%]'
          isMulti={false}
          options={grades}
          styles={style}
          value={grades ? grades.find(item => item.label === info.startGrade) : null}
          onChange={(e) => {
            handleChange({
              name: 'schoolInfo',
              order,
              key: 'startGrade',
              value: e.label,
            })
          }}
        />
        <SelectGroup
          title='End Grade'
          classNameGroup='w-[25%]'
          value={grades ? grades.find(item => item.label === info.endGrade) : null}
          isMulti={false}
          options={grades}
          styles={style}
          onChange={(e) => {
            handleChange({
              name: 'schoolInfo',
              order,
              key: 'endGrade',
              value: e.label,
            })
          }}
        />
        {/* <Input
          label="Start Grade"
          value={info?.startGrade || ''}
          classGroup="w-[25%]"
          onChange={(e) =>
            handleChange({
              name: 'schoolInfo',
              order,
              key: 'startGrade',
              value: e.target.value,
            })
          }
        /> */}
        {/* <Input
          label="End Grade"
          value={info?.endGrade || ''}
          classGroup="w-[25%]"
          onChange={(e) =>
            handleChange({
              name: 'schoolInfo',
              order,
              key: 'endGrade',
              value: e.target.value,
            })
          }
        /> */}
        {enquiry.status !== EnquiryStatus.COMPLETE && (
          <button title='remove' onClick={() => {
            handleRemove({ name: 'schoolInfo', order })
          }}>
            <img src={ic_close_black} alt='remove' />
          </button>
        )}
      </div>
    </div>
  );
});
SingleSchool.displayName = 'SingleSchool';
