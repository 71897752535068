import React, { memo } from 'react';

const Add = memo(() => {
  return (
    <svg
      width="20"
      className="mr-3 ml-3 transform rotate-45"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66602 10C1.66602 5.39763 5.39698 1.66667 9.99935 1.66667C12.2095 1.66667 14.3291 2.54464 15.8919 4.10744C17.4547 5.67025 18.3327 7.78986 18.3327 10C18.3327 14.6024 14.6017 18.3333 9.99935 18.3333C5.39698 18.3333 1.66602 14.6024 1.66602 10ZM12.5042 11.6667C12.5042 11.4445 12.4154 11.2315 12.2577 11.075L11.1743 10L12.2577 8.925C12.5844 8.59823 12.5844 8.06844 12.2577 7.74167C11.9309 7.4149 11.4011 7.4149 11.0743 7.74167L9.99935 8.825L8.92435 7.74167C8.59758 7.4149 8.06778 7.4149 7.74101 7.74167C7.41425 8.06844 7.41425 8.59823 7.74101 8.925L8.82435 10L7.74101 11.075C7.58325 11.2315 7.49452 11.4445 7.49452 11.6667C7.49452 11.8889 7.58325 12.1019 7.74101 12.2583C7.89749 12.4161 8.11048 12.5048 8.33268 12.5048C8.55488 12.5048 8.76788 12.4161 8.92435 12.2583L9.99935 11.175L11.0743 12.2583C11.2308 12.4161 11.4438 12.5048 11.666 12.5048C11.8882 12.5048 12.1012 12.4161 12.2577 12.2583C12.4154 12.1019 12.5042 11.8889 12.5042 11.6667Z"
        fill="#ababcd"
      />
    </svg>
  );
});
Add.displayName = 'Add';
export default Add;
