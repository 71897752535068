import { Modal } from '@mui/material';
import clsx from 'clsx';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TaskDragDrop } from '@components/tasks';
import useDebounce from '@hooks/useDebounce';
import { Input } from '@stories/index';
import { ToastMessageContext } from 'context/toast-context';
import { initialTask } from 'entities/data';
import BoardTeamMemberModal from './components/board-or-team-members';
import CreateNewBoardOrTeamModal from './components/create-new-board-or-team-modal';
import EditTaskPanel from './components/edit-task/edit-task';
import { DelegateTask, RequestExtension } from './components/menu-task';
import { TeamItem } from './components/team';
import { calendar, clock, headplus, kanban, list, plus, time } from './image';
import NavbarTask from './navbar-task';
import taskApi from 'api/task';
import useStorage from 'store/storage';
import Loading from '@components/loading';

import './tasks.scss';

const types = [
  { title: 'List View', svg: list },
  { title: 'Calendar View', svg: calendar },
  { title: 'Workload', svg: clock },
  { title: 'Kanban', svg: kanban },
  { title: 'Timeline', svg: time },
  { title: 'Add View', svg: plus },
];

const pageSize = 5;

const Tasks = React.forwardRef((props, ref) => {
  const { burger } = props;
  const [number, setNumber] = useState(0);
  const [active, setActive] = useState(types && types[0].title);
  const [isAddTask, setIsAddTask] = useState(false);
  const [isEditTask, setIsEditTask] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleTeamMemberModal, setVisibleTeamMemberModal] = useState(false);
  const [refresh, setRefresh] = useState({});
  const [isAddTeamSuccess, setIsAddTeamSuccess] = useState(false);
  const [showModalAddNewTask, setshowModalAddNewTask] = useState(false);
  const [isClickOutside, setIsClickOutside] = useState(true);
  const [isSelectTeam, setIsSelectTeam] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState();
  const [listTeams, setListTeams] = useState([]);
  const [listTasksInDatabase, setListTasksInDatabase] = useState([]);
  const [listTasks, setListTasks] = useState([]);
  const [newTask, setNewTask] = useState(initialTask);
  const [selectedTask, setSelectedTask] = useState({});
  const [indexSelectedTask, setIndexSelectedTask] = useState(0);
  const [isShowTeamMenu, setIsShowTeamMenu] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSort, setIsSort] = useState(false);
  const [orderDirection, setOrderDirection] = useState();
  const [orderBy, setOrderBy] = useState();

  const [menuShow, setMenuShow] = useState({
    delegate: false,
    request: false,
    reopen: false,
  });
  const [page, setPage] = useState(0);
  const [outOfData, setOutOfData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const refOutOfData = useRef();
  refOutOfData.current = outOfData;

  const campus = useStorage((state) => state.currentCampus);
  const location = useLocation();
  const [newTeamId, setNewTeamId] = useState(location?.state?.teamId);
  const user = useStorage((state) => state.auth);
  const debouncedSearch = useDebounce(searchValue, 500);

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    // const isBottom = scrollTop + clientHeight === scrollHeight;* 0.998
    let isBottom = Math.ceil(scrollTop + clientHeight) === Math.ceil(scrollHeight);
    if (isBottom && !refOutOfData.current) {
      isBottom = false;
      setPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    const ele = document.getElementById('tasks-scroll');
    if (ele) ele.addEventListener('scroll', handleScroll);

    return () => {
      const ele = document.getElementById('tasks-scroll');
      if (ele) ele.removeEventListener('scroll', handleScroll);
    };
  }, [isLoading]);

  useEffect(() => {
    taskApi
      .getListTeam({ campusId: campus?.isCentral ? undefined : campus?.id })
      .then((res) => {
        if (res.data.success) {
          if (!newTeamId) {
            setSelectedTeam(res.data.data.objects[0]);
          } else {
            const result = res.data.data.objects.find((data) => {
              return data.id === newTeamId;
            });
            if (!!result) {
              setSelectedTeam(result);
            } else {
              setSelectedTeam(res.data.data.objects[0]);
            }
            setNewTeamId();
          }
          setListTeams(res.data.data.objects);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Get List Teams Failed',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Get List Teams Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Get list teams error', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [isAddTeamSuccess, campus]);

  useEffect(() => {
    if (selectedTeam && !debouncedSearch) {
      setIsLoading(true);
      taskApi
        .getListTaskOfTeam(
          {
            campusId: campus?.isCentral ? undefined : campus?.id,
            page: 0,
            pageSize,
          },
          selectedTeam.id,
        )
        .then((res) => {
          if (res.data.success) {
            const dataApi = res.data.data;

            setListTasks(dataApi.objects);
            setListTasksInDatabase(dataApi.objects);
            setPage(0);
          } else {
            setToastMessage({
              status: 'error',
              title: 'Get List Task Failed',
              message: res.data.message,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          // setIsShowToastMessage(true);
          setToastMessage({
            status: 'error',
            title: 'Get List Task Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Get list tasks error', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedTeam]);

  useEffect(() => {
    if (selectedTeam && !isSearch) {
      setIsLoading(true);
      if (page !== 0) setIsLoadingMore(true);
      let pageRefresh = 0;
      if (refresh.index) {
        pageRefresh = Math.floor(refresh.index / pageSize);
      }
      taskApi
        .getListTaskOfTeam(
          {
            campusId: campus?.isCentral ? undefined : campus?.id,
            page: refresh.index ? pageRefresh : page,
            pageSize,
            orderBy,
            orderDirection,
            textSearch: debouncedSearch,
          },
          selectedTeam.id,
        )
        .then((res) => {
          if (res.data.success) {
            const dataApi = res.data.data;
            if (refresh.index) {
              //update task
              setListTasks((prev) => [
                ...prev.slice(0, pageRefresh * pageSize),
                ...res.data.data.objects,
                ...prev.slice((pageRefresh + 1) * pageSize),
              ]);
            } else if (page === 0) {
              //first time || create new task
              setListTasks(dataApi.objects);
              setOutOfData(false);
            } else {
              //loading scroll
              setListTasks((prev) => [...prev, ...dataApi.objects]);
              setOutOfData(dataApi.objects.length < pageSize);
            }
            // show loading
            if (page === 0) {
              setIsLoading(false);
            } else {
              setIsLoadingMore(false);
            }
          } else {
            setToastMessage({
              status: 'error',
              title: 'Get List Task Failed',
              message: res.data.message,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          // setIsShowToastMessage(true);
          setToastMessage({
            status: 'error',
            title: 'Get List Task Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Get list tasks error', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedTeam, refresh.count, page, isSort, isUpdate]);

  // Search
  useEffect(() => {
    if (selectedTeam) {
      if (page !== 0) setIsLoadingMore(true);
      if (page === 0) setIsLoading(true);
      let pageRefresh = 0;
      if (refresh.index) {
        pageRefresh = Math.floor(refresh.index / pageSize);
      }
      taskApi
        .getListTaskOfTeam(
          {
            campusId: campus?.isCentral ? undefined : campus?.id,
            page: refresh.index ? pageRefresh : page,
            pageSize,
            orderBy,
            orderDirection,
            textSearch: debouncedSearch,
          },
          selectedTeam.id,
        )
        .then((res) => {
          if (res.data.success) {
            const dataApi = res.data.data;
            if (refresh.index) {
              //update task
              setListTasks((prev) => [
                ...prev.slice(0, pageRefresh * pageSize),
                ...res.data.data.objects,
                ...prev.slice((pageRefresh + 1) * pageSize),
              ]);
            } else if (page === 0) {
              //first time || create new task
              setListTasks(dataApi.objects);
              setOutOfData(false);
            } else {
              //loading scroll
              setListTasks((prev) => [...prev, ...dataApi.objects]);
              setOutOfData(dataApi.objects.length < pageSize);
            }
            // show loading
            if (page === 0) {
              setIsLoading(false);
            } else {
              setIsLoadingMore(false);
            }
          } else {
            setToastMessage({
              status: 'error',
              title: 'Get List Task Failed',
              message: res.data.message,
            });
            setIsShowToastMessage(true);
          }
          setIsSearch(false);
        })
        .catch((error) => {
          // setIsShowToastMessage(true);
          setToastMessage({
            status: 'error',
            title: 'Get List Task Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Get list tasks error', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [debouncedSearch]);

  const onClickSaveHandle = () => {
    taskApi
      .createTask({ campusId: campus?.isCentral ? undefined : campus?.id }, newTask)
      .then((res) => {
        if (res?.data?.success) {
          setToastMessage({
            status: 'success',
            title: 'Create new task successfully',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
          handleCancel();
          setPage(0);
          setRefresh((prev) => ({
            ...prev,
            count: (prev.count || 0) + 1,
            index: undefined,
          }));
          setIsAddTask(false);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Add new task Failed',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Add new task error',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Add new task error', error);
      });
  };

  const handleCancel = () => {
    // setListTasks(listTasksInDatabase);
    setNewTask(initialTask);
    setIsAddTask(false);
    setshowModalAddNewTask(false);
  };

  const handleAddNewTask = () => {
    if (newTask.name.trim().length === 0) {
      setToastMessage({
        status: 'warning',
        title: 'Validation Rule',
        message: 'Name must not be empty',
      });
      setIsShowToastMessage(true);
      return;
    }
    onClickSaveHandle();
    // setListTasks((prevState) => [newTask, ...prevState]);
    // setshowModalAddNewTask(true);
    // setIsAddTask(false);
  };

  const handleSelectTeam = (data) => {
    setIsSearch(false);
    setSelectedTeam(data);
    setIsSelectTeam(false);
    setNewTask(initialTask);
    setIsAddTask(false);
    setshowModalAddNewTask(false);
    setListTasks([]);
    setPage(0);
    setIsLoading(true);
  };
  const checkManager = useMemo(() => {
    if (!selectedTeam || selectedTeam?.managers.length === 0) {
      return false;
    }
    const result = selectedTeam?.managers.find((manager) => {
      return manager?.user?.id === user?.uid;
    });
    return !!result;
  }, [selectedTeam, user]);

  return (
    // <div className={clsx('tasks-page pr-6', burger ? 'pl-0 1400px:pl-69  2xl:pl-69' : 'pl-0 1400px:pl-20')}>
    <div
      className={clsx(
        'tasks-page pr-20 1400px:pr-16',
        burger ? '1400px:pl-[19rem]' : '1400px:pl-32',
        'w-full',
      )}
    >
      <Modal open={visibleModal} onClose={setVisibleModal}>
        <div open={visibleModal} onClose={setVisibleModal}>
          <CreateNewBoardOrTeamModal
            open={visibleModal}
            isOpen={setVisibleModal}
            ref={ref}
            setIsAddTeamSuccess={setIsAddTeamSuccess}
            setNewTeamId={setNewTeamId}
          />
        </div>
      </Modal>
      <div className="flex items-center justify-between w-full pl-10 h-28 pr-10">
        {visibleTeamMemberModal && (
          <div
            // onClick={() => {
            //   setVisibleTeamMemberModal(false);
            // }}
            className="absolute top-0 left-0 right-0 bottom-0 w-[100%] h-[100%]"
          >
            <BoardTeamMemberModal
              selectedTeam={selectedTeam}
              setVisibleTeamMemberModal={setVisibleTeamMemberModal}
              setIsAddTeamSuccess={setIsAddTeamSuccess}
              setNewTeamId={setNewTeamId}
            />
          </div>
        )}
        {menuShow.delegate && (
          <div
            // onClick={() => {
            //   setVisibleTeamMemberModal(false);
            // }}
            className="absolute top-0 left-0 right-0 bottom-0 w-[100%] h-[100%] z-30"
          >
            <DelegateTask
              checkManager={checkManager}
              selectedTask={selectedTask}
              selectedTeam={selectedTeam}
              setVisibleTeamModal={() => {
                setMenuShow({
                  delegate: false,
                  request: false,
                  reopen: false,
                });
              }}
              setIsUpdate={setIsUpdate}
              setRefresh={setRefresh}
              setPage={setPage}
            />
          </div>
        )}
        {menuShow.request && (
          <div
            // onClick={() => {
            //   setVisibleTeamMemberModal(false);
            // }}
            className="absolute top-0 left-0 right-0 bottom-0 w-[100%] h-[100%] z-30"
          >
            <RequestExtension
              selectedTeam={selectedTeam}
              selectedTask={selectedTask}
              setVisibleTeamModal={() => {
                setMenuShow({
                  delegate: false,
                  request: false,
                  reopen: false,
                });
              }}
              setIsUpdate={setIsUpdate}
              setRefresh={setRefresh}
              setPage={setPage}
            />
          </div>
        )}
        <h1 className="namepage relative top-3">Tasks</h1>
        <TeamItem
          listTeams={listTeams}
          selectedTeam={selectedTeam}
          setIsSelectTeam={setIsSelectTeam}
          isSelectTeam={isSelectTeam}
          setIsShowTeamMenu={setIsShowTeamMenu}
          setVisibleTeamMemberModal={setVisibleTeamMemberModal}
          isShowTeamMenu={isShowTeamMenu}
          handleSelectTeam={handleSelectTeam}
          setVisibleModal={setVisibleModal}
          setIsSearch={setIsSearch}
        />
      </div>
      <div className="flex items-center justify-between w-full pl-10 pr-10">
        <NavbarTask
          active={active}
          setActive={setActive}
          types={types}
          setListTasks={setListTasks}
          setIsSearch={setIsSearch}
          setPage={setPage}
          setOrderDirection={setOrderDirection}
          setOrderBy={setOrderBy}
          setIsSort={setIsSort}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          setRefresh={setRefresh}
        />
      </div>
      <div
        className="flex pl-14 pr-4 mt-10 w-full justify-start"
        style={{ color: 'rgba(99, 115, 129, 0.6)' }}
      >
        <div className="font-normal text-xs text-[#63738199] flex items-center w-[25.5%] 2xl:w-[27.5%]">
          {!(isAddTask || showModalAddNewTask) && (
            <img
              onClick={() => {
                if (checkManager) {
                  setIsAddTask(!isAddTask);
                } else {
                  setToastMessage({
                    status: 'warning',
                    title: 'Have no permission',
                    message:
                      'You are not a team manager so you do not have permission to create tasks.',
                  });
                  setIsShowToastMessage(true);
                }
              }}
              src={headplus}
              className="mr-3 cursor-pointer"
              alt=""
            />
          )}
          Task Name
        </div>
        <p className="font-normal text-[#63738199] flex w-[9%] 1920px:w-[10%]">Status</p>
        <p className="font-normal text-[#63738199] flex w-[7%]">Owner</p>
        <p className="font-normal text-[#63738199] flex w-[13%] pl-[2rem]">Dates</p>
        <p className="font-normal text-[#63738199] flex w-[17%]">Time/Work Estimate</p>
        <p
          className={clsx(
            'font-normal text-[#63738199] flex w-[12%]',
            burger ? '1400px:w-[12.5%] 2xl:w-[13.5%]' : '',
          )}
        >
          Goals or KRI
        </p>
        <p className="font-normal text-[#63738199] flex w-[12%]">Dependency</p>
      </div>
      {/* <TaskManagement /> */}
      {/* <DragDrop /> */}
      {isAddTask && (
        <div className="w-[98%] rounded-lg mt-4 mb-6 shadow-[15px_8px_30px_rgba(0,0,0,0.1)]">
          <div className="flex my-6 ml-4">
            <img
              onClick={() => {
                handleAddNewTask();
              }}
              src={headplus}
              className="mr-3 mt-2 cursor-pointer w-6 h-6"
              alt=""
            />
            <Input
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleAddNewTask();
                }
              }}
              onChange={(e) => {
                //   setUser({ ...user, firstName: e.target.value });
                setNewTask((prevState) => ({
                  ...prevState,
                  name: e.target.value.trim(),
                  teamId: selectedTeam?.id,
                  startDate: new Date(),
                  endDate: new Date(),
                }));
              }}
              label="Add Task Name"
              name="add-task-name"
              type="text"
              // value=""
              customStyle={{
                width: '22.25rem',
                height: '2.5rem',
                fontSize: '0.875rem',
                borderRadius: '0.25rem',
              }}
              // error={error.statusFirstName}
            />
          </div>
        </div>
      )}
      {isLoading && !isLoadingMore ? (
        <div className="w-screen h-screen justify-center items-start flex pt-10">
          <Loading />
        </div>
      ) : listTasks.length > 0 ? (
        <div
          onClick={() => {
            setIsClickOutside(!isClickOutside);
          }}
          // className="max-h-[50vh] overflow-y-scroll overflow-x-hidden w-full mt-6"
          className="overflow-x-hidden w-full mt-10"
        >
          <TaskDragDrop
            burger={burger}
            listTasks={listTasks}
            selectedTeam={selectedTeam}
            setRefresh={setRefresh}
            setIsEditTask={setIsEditTask}
            setSelectedTask={setSelectedTask}
            setMenuShow={setMenuShow}
            menuShow={menuShow}
            setPage={setPage}
            setNumber={setNumber}
            setIsUpdate={setIsUpdate}
            setIndexSelectedTask={setIndexSelectedTask}
          />
        </div>
      ) : (
        <div
          onClick={() => {
            setIsClickOutside(!isClickOutside);
          }}
          className="mt-10 h-[50vh]"
        >
          Have no task
        </div>
      )}
      {isEditTask && (
        <div
          className="absolute top-0 right-0 left-0 bottom-0 z-20"
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.65)' }}
        ></div>
      )}
      {isEditTask && (
        <EditTaskPanel
          visible={isEditTask}
          setVisible={setIsEditTask}
          selectedTask={selectedTask}
          setListTasks={setListTasks}
          indexSelectedTask={indexSelectedTask}
          selectedTeam={selectedTeam}
          number={number}
        />
      )}
      {/* <ToastContainer /> */}
      {isLoadingMore && <p>Loading ...</p>}
    </div>
  );
});
export default Tasks;
