import React, { memo, useRef, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import dataPicker from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Audio from './audio';
import Enclose from './enclose';
import { checkErrorMultipleApi } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import admissionApi from '../../../../api/admission';

const AreaCommenting = memo((props) => {
  const { customStyle, styleGroup, clear, setClear, infoAction, handlePublish, actions } = props;
  const refText = useRef();
  const refEnclose = useRef()
  const [action, setAction] = useState('') // 'emoji' | 'audio' | 'enclose'
  const [audioFile, setAudioFile] = useState(null)
  const [enclose, setEnclose] = useState([]);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  useEffect(() => {
    refText.current.value = ""
    setAudioFile(null)
    setEnclose([])
  }, [clear])

  useEffect(() => {
    if (clear) {
      refText.current.value = '';
      setClear(false);
    }
    if (infoAction?.action === 'edit') {
      refText.current.value = infoAction.info.message;
    }
  }, [clear, infoAction]);

  const getApiUpload = (files) => {
    const result = []
    files.forEach(file => {
      const formData = new FormData();
      formData.set('file', file);
      formData.set('mediaId', file.name);
      // const api = callApi({
      //   method: 'post',
      //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/media/file`,
      //   data: formData,
      //   contentType: 'multipart/form-data',
      // });
      const api = admissionApi.uploadMediaFile(formData, 'multipart/form-data');
      result.push(api)
    })
    return result
  }

  const handleSubmit = async () => {
    try {
      let apiFiles = []
      if (enclose.length !== 0) apiFiles = getApiUpload(enclose)
      if (audioFile) {
        // const api = callApi({
        //   method: 'post',
        //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/media/file`,
        //   data: audioFile,
        //   contentType: 'multipart/form-data',
        // });
        const api = admissionApi.uploadMediaFile(audioFile, 'multipart/form-data');
        apiFiles.push(api)
      }
      const responses = await Promise.all(apiFiles);
      if (checkErrorMultipleApi(responses, setToastMessage, setIsShowToastMessage, 'Publish', false)) {
        const files = enclose.map((file, index) => {
          return {
            fileURL: responses[index].data?.data,
            filename: file?.name,
            size: file?.size ? `${(file?.size / (1024 * 1024)).toFixed(2)} mb` : '0.0 mb'
          }
        })
        const message = refText.current.value
        const data = { message, files }
        if (audioFile) data.audio = responses[responses.length - 1].data?.data
        handlePublish(data)
        setAction('')
      }
      return
    } catch (error) {
      console.log('error', error)
      return
    }
  };

  const handleEmojiSelect = (e) => {
    let val = refText.current.value;
    val += e.native;
    refText.current.value = val;
  };

  const handleChangeAction = (at) => {
    setAction(prev => prev === at ? '' : at)
  }

  const onChangeEnclose = (e) => {
    if (e.target?.files?.[0]) {
      setEnclose(Array.from(e.target.files))
    }
    setAction('')
  }

  return (
    <>
      <div style={{ width: '100%', position: 'relative', ...styleGroup }}>
        {(audioFile || action === 'audio' || enclose.length !== 0) && (
          < div className='absolute z-10 bottom-[100%] w-full rounded p-4 bg-[#fff]'>
            {(action === 'audio' || audioFile) && (
              <div className='maincard w-full p-2 h-fit mb-0'>
                <Audio setAudioFile={setAudioFile} />
              </div>
            )}
            {enclose.length !== 0 && (
              <div className='maincard w-full p-2 h-fit mb-0'>
                <Enclose enclose={enclose} setEnclose={setEnclose} />
              </div>
            )}
          </div>
        )}

        <div
          className="rounded-lg relative h-[22%]"
          style={{
            backgroundColor: '#D9E6E9',
            ...customStyle,
          }}
        >
          <input onChange={onChangeEnclose} type='file' multiple={true} ref={refEnclose} hidden={true} />

          <textarea
            ref={refText}
            className="h-[55%] hidden-scrollbar p-2 text-sm rounded-lg resize-none focus: outline-0 w-full"
            placeholder="Comments"
            style={{
              backgroundColor: '#D9E6E9',
            }}
          />
          <div className="w-full px-[2%] flex justify-end h-[45%] rounded-lg pb-[2%]">
            <div className="flex items-center justify-around max-w-[55%]">
              {/* emoji */}
              {actions.emoji && (
                <svg className="block object-fill cursor-pointer mr-3" width="24" height="24" viewBox="0 0 24 24"
                  fill='none'
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    handleChangeAction('emoji')
                  }}
                >
                  <path d="M18.364 5.636a9 9 0 0 1 0 12.728 9 9 0 1 1 0-12.728M9 9.5H8m7.5-.5v1"
                    stroke={action === 'emoji' ? '#27C59A' : '#8C9094'}
                    strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M15.5 14.688S14.187 16 12 16c-2.188 0-3.5-1.312-3.5-1.312"
                    stroke={action === 'emoji' ? '#27C59A' : '#8C9094'} strokeWidth="1.5"
                    strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              )}

              {/* enclose */}
              {actions.enclose && (
                <svg className='cursor-pointer mr-3' width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    handleChangeAction('enclose')
                    if (refEnclose) refEnclose.current.click()
                  }}
                >
                  <path d="m17.478 14.962 2.192-2.192a4.554 4.554 0 0 0-6.44-6.44l-2.192 2.192m4.63 1.81-5.336 5.336m-1.81-4.63L6.33 13.23a4.554 4.554 0 0 0 6.44 6.44l2.192-2.192"
                    stroke={action === 'enclose' ? '#60a5fa' : '#8C9094'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              )}

              {/* audio */}
              {/* {actions.record && (
                <svg className='cursor-pointer mr-3' width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handleChangeAction('audio')} >
                  <path d="M1.375 8.125A.625.625 0 0 1 2 8.75V10a5 5 0 1 0 10 0V8.75a.625.625 0 1 1 1.25 0V10a6.25 6.25 0 0 1-5.625 6.219v2.531h3.75a.624.624 0 1 1 0 1.25h-8.75a.625.625 0 1 1 0-1.25h3.75v-2.531A6.25 6.25 0 0 1 .75 10V8.75a.625.625 0 0 1 .625-.625Z"
                    fill={action === 'audio' ? '#B72136' : '#8C9094'} />
                  <path d="M9.5 10a2.5 2.5 0 0 1-5 0V3.75a2.5 2.5 0 1 1 5 0V10ZM7 0a3.75 3.75 0 0 0-3.75 3.75V10a3.75 3.75 0 0 0 7.5 0V3.75A3.75 3.75 0 0 0 7 0Z"
                    fill={action === 'audio' ? '#B72136' : '#8C9094'} />
                </svg>
              )} */}

              {/* <img className="block object-fill cursor-pointer" src={tag} alt='tag' /> */}

              <button type="button"
                className="text-xs text-white bg-black px-[7%] py-[2%] rounded h-[1.875rem] w-20 ml-1"
                onClick={handleSubmit}
              >
                Publish
              </button>
            </div>
          </div>

          {action === 'emoji' && (
            <div className="absolute bottom-28 overflow-auto h-64 z-20">
              <Picker
                data={dataPicker}
                set='native'
                icons='outline'
                previewPosition="top"
                maxFrequentRows={4}
                onEmojiSelect={(e) => {
                  handleEmojiSelect(e);
                }}
              />
            </div>
          )}
        </div>
      </div >
    </>
  );
});
AreaCommenting.displayName = 'AreaCommenting';

AreaCommenting.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

AreaCommenting.defaultProps = {
  width: '100%',
  height: '2.5rem',
};

export default AreaCommenting;
