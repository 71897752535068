import { useMemo } from 'react';
import clsx from 'clsx';

import { buttoncheck } from '@pages/bloomingdale/image';
import { Input, SelectGroup } from '@stories/index';
import { bloomSelect, selectAdmission } from 'entities/select-style';
import DatePicker from '@stories/datepicker/datepicker';
import TextArea from '@stories/textarea/textarea';
import NewSelectGroup from '@components/select/NewSelectGroup';

const ContentGroup = (props) => {
  const { data, handleChange, info, error, variation } = props;
  const { label, type, isMulti, data: dataField, disabled } = data;
  const isAdmission = variation === 'admission';
  const newLabel = isAdmission ? label : '';
  const customStyle = {
    textArea: isAdmission ? {} : { width: '100%' },
    input: isAdmission ? { height: 'unset' } : { borderRadius: '0px', height: 'unset' },
  };

  const mapComponent = useMemo(
    () => ({
      textArea: (
        <TextArea
          label={newLabel}
          disableLabel={true}
          type="text"
          value={info || ''}
          customStyle={{ width: '100%' }}
          onChange={(e) => {
            const val = e.target.value;
            handleChange(val);
          }}
        />
      ),
      input: (
        <Input
          disabled={disabled}
          customStyle={customStyle.input}
          type="text"
          label={newLabel}
          error={error}
          value={info || ''}
          onChange={(e) => {
            const val = e.target.value;
            handleChange(val);
          }}
        />
      ),
      button: (
        <div className="flex items-center w-full flex-wrap gap-x-2 gap-y-3">
          {dataField &&
            dataField.map((item, key) => {
              const { value, label } = item;
              return (
                <button
                  key={key}
                  className={clsx(
                    'group-button none-after',
                    value === info?.value && 'group-button--active',
                    error && 'border-[red]',
                  )}
                  style={{ marginRight: '0px' }}
                  onClick={() => handleChange(item)}
                >
                  {value === info?.value && (
                    <img
                      src={buttoncheck}
                      className="w-3.5 aspect-square mr-3"
                      alt="check"
                    />
                  )}
                  {label}
                </button>
              );
            })}
        </div>
      ),
      select: (
        <SelectGroup
          title={newLabel}
          isMulti={isMulti}
          styles={{
            ...bloomSelect,
            ...selectAdmission,
            option: (baseStyles) => ({
              ...baseStyles,
              width: '100%',
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              width: '100%',
            }),
          }}
          options={dataField}
          value={info || null}
          onChange={(e) => {
            handleChange(e);
          }}
          error={error}
        />
      ),
      date: (
        <div className="w-full">
          <DatePicker
            label={newLabel}
            sx={{ '& .MuiFormControl-root': { width: '100%' } }}
            pValue={info}
            psetValue={(e) => handleChange(e)}
            customStyle={{ width: '100%', height: '1.7rem' }}
          />
        </div>
      ),
      'new-select': (
        <NewSelectGroup
          listOptions={dataField}
          onChange={(e) => {
            const dataSelected = {
              label: e.target.value,
              value: e.target.value,
            };
            handleChange(dataSelected);
          }}
          value={info}
          // id="grade"
          label="Anticipated Grade of Entry"
        />
      ),
    }),
    [
      customStyle.input,
      customStyle.textArea,
      dataField,
      disabled,
      error,
      handleChange,
      info,
      isMulti,
      newLabel,
    ],
  );

  return (
    <div className="mb-4 h-fit">
      {!isAdmission && (
        <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
          {label}
        </p>
      )}
      {mapComponent[type]}
    </div>
  );
};
export default ContentGroup;
