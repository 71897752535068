import { Input } from '@stories/index';
import { useEffect } from 'react';
import ScholarshipInfo from './scholarship-info';

const SiblingDiscount = (props) => {
  const {
    sibling,
    isParent,
    data,
    setFeeInfo,
    admissionFee,
    startOrder,
    objValueType,
    onOpenDialog,
    idxScholarship,
    setScholarships,
  } = props;

  useEffect(() => {
    if (sibling) {
      setFeeInfo((prev) => ({
        ...prev,
        value: sibling?.value,
        discountType: sibling?.discountType,
      }));
      setScholarships((prev) => {
        prev[idxScholarship].info = { ...prev[idxScholarship].info, name: sibling?.name };
        prev[idxScholarship].info.fees = prev[idxScholarship].info.fees.map((fee) => ({
          ...fee,
          value: sibling?.value,
          valueType: sibling?.discountType?.includes(objValueType?.Amount)
            ? objValueType.Amount
            : objValueType.Percentage,
        }));

        return [...prev];
      });
    }
  }, [sibling]);

  return (
    <>
      <p className="text-sm mt-2">{startOrder}. Sibling Discount</p>
      <Input label="" disabled={true} defaultValue={sibling?.name} />

      <ScholarshipInfo
        startOrder={startOrder + 1}
        data={data}
        editable={false}
        isParent={isParent}
        admissionFee={admissionFee}
        objValueType={objValueType}
        onOpenDialog={onOpenDialog}
        idxScholarship={idxScholarship}
        setScholarships={setScholarships}
        hiddenAction={true}
      />
    </>
  );
};

export default SiblingDiscount;
