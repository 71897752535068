import React, { useContext, useEffect, useState } from 'react'
import Zoom from 'react-reveal/Zoom'
import clsx from 'clsx'
import { filelistfill, women } from '../image'
import SingleNote from "@components/application-panel/sub-components/single-note";
import AreaCommenting from "@pages/tasks/components/area-commenting";
import { checkErrorApiFetch, checkErrorSingleApi } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import admissionNoteApi from 'api/admission-note';

const NOTE_TYPE = {
  notes: "Notes",
  audioNote: "Audio Notes"
}

const NotePick = (props) => {
  const { visible, enquiry } = props
  const [refresh, setRefresh] = useState(false)
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [notes, setNotes] = useState([])

  const fetch = async () => {
    try {
      const res = await admissionNoteApi.getListNote({ campusId: enquiry.campus.id, enquiryId: enquiry.id })
      if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, false)) {
        setNotes(res.data.data.objects)
      }
    } catch (error) {
      console.log('Get List Note', error)
    }
  }

  useEffect(() => {
    if (enquiry?.campus?.id && enquiry?.id)
      fetch()
  }, [refresh, enquiry])

  const handlePublish = async (data) => {
    const dataApi = { noteType: NOTE_TYPE.notes, data: data }
    dataApi.enquiryId = enquiry?.id
    if (data?.audio) dataApi.noteType = NOTE_TYPE.audioNote
    try {
      const res = await admissionNoteApi.createNote({ campusId: enquiry?.campus?.id }, dataApi)
      if (checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, 'Publish')) {
        setRefresh(prev => !prev)
      }
    } catch (error) {
      console.log('push note', error)
    }
  }

  return (
    <Zoom bottom duration={700} when={visible}>
      <div className={clsx('windows-field', visible ? 'pointer-events-auto' : 'pointer-events-none hidden')}>
        <div className='flex items-center justify-between pt-5 pl-5 pr-5'>
          <div className='flex item'>
            <div
              className='w-10 h-10 mr-3 flex items-center justify-center rounded-lg mb-7'
              style={{ background: '#F4F6F8' }}
            >
              <img src={filelistfill} alt='' />
            </div>
            <p className='font-semibold mt-2.5'>My notes</p>
          </div>
          <button
            className='bg-main-blue w-6 h-6 text-white text-xl flex items-center justify-center rounded-full mb-9'>
            +
          </button>
        </div>

        {notes ? (
          <div style={{ height: 'calc(100vh - 17.5rem)' }}>
            <p className='pl-5 text-main-black text-base mb-4'>Recent Notes</p>
            <div className={'h-[95%] overflow-y-auto px-5 pt-2 flex flex-col'}>
              {notes?.map((item, index) => {
                return <SingleNote key={`notes${index}`} data={item} className={'mb-5'} />
              })}
            </div>
          </div>
        ) : (
          <>
            <p className='pl-5 text-main-black text-base mb-8'>Recent Notes</p>
            <div className='flex flex-col items-center justify-center'>
              <img src={women} className='mb-3' />
              <p className='text-base font-medium'>You’ve made no notes yet.</p>
              <p className='text-sm'>🤔 Maybe it’s a good time to get started.</p>
            </div>
          </>
        )}

        <div className={'px-5 py-4'}>
          <AreaCommenting customStyle={{}}
            actions={{ emoji: true, enclose: true, record: true }}
            styleGroup={{ position: 'absolute', width: '90%', bottom: '4rem' }}
            handlePublish={(data) => handlePublish(data)}
            clear={refresh} setClear={() => {
            }}
            infoAction={{ info: { message: 'abc' } }} />
        </div>
      </div>
    </Zoom>
  )
}

export default NotePick
