import { rejected } from "@static/image"

const Enclose = (props) => {
  const { enclose, setEnclose } = props

  const onRemoveEnclose = (index) => {
    setEnclose(prev => [...prev.slice(0, index), ...prev.slice(index + 1)])
  }

  return (
    <ul className="h-fit text-xs w-full">
      {enclose.map((file, index) => {
        return (
          <li key={index} className={`flex items-center w-full ${index !== 0 && 'mt-2'}`}>
            <p className="w-[95%]">{file.name}</p>
            <img src={rejected} alt='reject'
              onClick={() => onRemoveEnclose(index)}
              className="aspect-square w-4 cursor-pointer"
            />
          </li>)
      })}
    </ul>
  )
}

export default Enclose