import clsx from 'clsx';
import * as _ from 'lodash';
import {
  useCallback,
  useContext,
  useEffect, useRef,
  useState
} from 'react';
import Accordion from '@components/accordion/accordion';
import TasksAndReminders from '@components/tasks-and-reminders/tasks-and-reminders';
import { CompanyTabs } from '@stories/index';
import { checkErrorApiFetch, checkErrorSingleApi } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { dataLinks } from 'entities/routes';
import { NavLinks } from '../Header/components/index';
import './tasks-settings.scss';
import notificationApi from 'api/notification';
import useStorage from 'store/storage';

export default function TasksSettings({ burger }) {
  const [refresh, setRefresh] = useState(0);
  const [modulesTab, setModulesTab] = useState([]);
  const [moduleTabCurrent, setModuleTabCurrent] = useState('');
  const [dataOfModule, setDataOfModule] = useState([]);
  const [dataTasksAndRemindersSettings, setDataTasksAndRemindersSettings] = useState([]);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const refOriginData = useRef();
  const campus = useStorage((state) => state.currentCampus);
  const auth = useStorage((state) => state.auth);

  useEffect(() => {
    // callApi({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_NOTIFICATION}/api/v1/notification/task-and-reminder/list`,
    //   params: { campusId: campus?.isCentral ? undefined : campus?.id },
    // })
    notificationApi.getTaskAndReminderList({ campusId: campus?.isCentral ? undefined : campus?.id })
      .then((res) => {
        if (
          checkErrorApiFetch(
            res,
            setToastMessage,
            setIsShowToastMessage,
            'Get Tasks and Reminders',
          )
        ) {
          setDataTasksAndRemindersSettings(res.data.data);
          refOriginData.current = JSON.stringify(res.data.data);

          const listModule = [];
          res.data.data.forEach((item) => {
            listModule.push(item.module);
          });
          setModulesTab(listModule);
          setModuleTabCurrent(listModule[0]);
          setDataOfModule(res.data.data[0]);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      });
  }, [refresh]);

  const handleSave = useCallback(() => {
    const dataPost = [];
    const origin = JSON.parse(refOriginData.current);

    for (let i = 0; i < dataTasksAndRemindersSettings.length; i++) {
      for (let j = 0; j < dataTasksAndRemindersSettings[i].data.length; j++) {
        for (let k = 0; k < dataTasksAndRemindersSettings[i].data[j].length; k++) {
          const objOrigin = origin[i].data[j][k];
          const objUpdate = dataTasksAndRemindersSettings[i].data[j][k];
          if (!_.isEqual(objOrigin, objUpdate)) {
            const {
              id,
              defaultId,
              description,
              isSendNotiApp,
              isSendEmail,
              isSendPhone,
              isSendWhatsapp,
              dueDate,
              workloadEstimate,
              reminder,
              linkedGoal,
              roleNames,
              userIds,
            } = objUpdate;
            const data = {
              defaultId,
              description,
              isSendNotiApp,
              isSendEmail,
              isSendPhone,
              isSendWhatsapp,
              dueDate,
              workloadEstimate,
              reminder,
              linkedGoal,
              roleNames,
              userIds: ((userIds) => {
                if (userIds.length === 0) return [];
                return userIds.map((item) => {
                  return item.id;
                });
              })(userIds),
            };
            if (id) data.id = id;
            dataPost.push(data);
          }
        }
      }
    }

    // callApi({
    //   method: 'post',
    //   url: `${process.env.REACT_APP_URL_API_NOTIFICATION}/api/v1/notification/task-and-reminder`,
    //   params: { campusId: auth?.campusId || campus?.isCentral ? undefined : campus?.id },
    //   data: { data: dataPost },
    // })
    notificationApi.createTaskAndReminder({ campusId: auth?.campusId || campus?.isCentral ? undefined : campus?.id }, { data: dataPost })
      .then((res) => {
        if (checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage)) {
          setToastMessage({
            status: 'success',
            title: 'Task Setting Successfully',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
          setRefresh((prev) => prev + 1);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Task Setting Error',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      });
  }, [dataTasksAndRemindersSettings]);

  const handleCancel = () => {
    const dataOrigin = JSON.parse(refOriginData.current);
    setDataTasksAndRemindersSettings(dataOrigin);
    setDataOfModule(dataOrigin.find((item) => item.module === moduleTabCurrent));
  };

  const handleChangeModuleTab = useCallback(
    (module) => {
      setModuleTabCurrent(module);
      setDataOfModule(
        dataTasksAndRemindersSettings.find((item) => item.module === module),
      );
    },
    [moduleTabCurrent, dataTasksAndRemindersSettings],
  );

  const handleChangeDetail = useCallback((module, title, row, key, value) => {
    setDataTasksAndRemindersSettings((prev) => {
      for (const dataOfModule of prev) {
        if (dataOfModule.module === module) {
          dataOfModule.data[title][row][key] = value;
          break;
        }
      }
      return [...prev];
    });
  }, []);

  return (
    // <div className={clsx('tasks-settings pr-24', burger ? 'pl-24 1400px:pl-80' : 'pl-24 1400px:pl-40')}>
    // <div className="tasks-settings pr-24 px-8 w-full">
    <div className="tasks-settings px-8 w-full">
      <NavLinks urls={dataLinks.tasksSettingLinks} />
      <h1 className="namepage">Tasks and Reminders</h1>
      <div className="mb-6">
        <CompanyTabs
          types={modulesTab}
          setTypeState={handleChangeModuleTab}
          typeState={moduleTabCurrent}
        />
      </div>
      <div className="notifications__items">
        {dataOfModule?.data?.length !== 0 &&
          dataOfModule?.data?.map((listSettings, index) => {
            return (
              <Accordion key={listSettings + index} title={listSettings[0].title}>
                <TasksAndReminders
                  module={moduleTabCurrent}
                  indexTitle={index}
                  data={listSettings}
                  setRefresh={setRefresh}
                  onChange={handleChangeDetail}
                />
              </Accordion>
            );
          })}
      </div>
      <div
        className={clsx(
          'flex justify-center mb-4 fixed bottom-2',
          burger ? 'w-[76%]' : 'w-full',
        )}
      >
        <button
          type="button"
          className="w-1/4 border-solid border px-[7%] py-1 rounded mr-4"
          style={{
            borderColor: 'blue',
            color: 'blue',
          }}
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          type="button"
          className="w-1/4 text-white rounded"
          style={{ backgroundColor: 'blue' }}
          onClick={handleSave}
        >
          Save
        </button>
      </div>
    </div>
  );
}
