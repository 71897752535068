import { memo } from 'react';
import { Fade } from 'react-reveal';
import { Button } from '@stories/index';
import ApplicationInformation from './application-information';
import FamilyInformation from './family-information';
import AddressEditInformation from './address-edit-information/address-edit-information';
import { SchoolInformation } from '.';

const FirstStep = memo((props) => {
  const {
    enquiry,
    info,
    listField,
    setInfo,
    styles,
    parentUserIdChosen,
    handleChange,
    handleAdd,
    handleCancel,
    handleNext,
    handleRemove
  } = props;
  const { studentInfo, parentInfo, addressInfo } = info;
  return (
    <section className='classForScroll'>
      <div className="information-field">
        <Fade clear duration={300}>
          <div className="relative" style={{ zIndex: 5 }}>
            <p className="font-bold text-base text-main-black mb-5">
              Applicant Information
            </p>
            <ApplicationInformation
              styles={styles}
              listField={listField.student}
              info={studentInfo}
              handleChange={handleChange}
            />
          </div>
          <div className="relative w-fit" style={{ zIndex: 4 }}>
            <p className="font-bold text-base text-main-black mb-5">
              Family Member Information
            </p>
            <div className='information-field_all_family'>
              <FamilyInformation
                enquiry={enquiry}
                setInfo={setInfo}
                info={parentInfo}
                parentUserIdChosen={parentUserIdChosen}
                handleChange={handleChange}
                handleAdd={handleAdd}
                handleRemove={handleRemove}
              />
            </div>
          </div>
          <div className="relative" style={{ zIndex: 3 }}>
            <p className="font-bold text-base text-main-black mb-5">Address Information</p>
            <AddressEditInformation
              styles={styles}
              listField={listField.address}
              info={addressInfo}
              handleChange={handleChange}
              handleAdd={handleAdd}
            />
          </div>

          <div className="relative" style={{ zIndex: 2 }}>
            <p className="font-bold text-base text-main-black mb-5">School Information</p>
            <SchoolInformation
              enquiry={enquiry}
              styles={styles}
              listField={listField.school}
              info={info.schoolInfo}
              handleChange={handleChange}
              handleAdd={handleAdd}
              handleRemove={handleRemove}
            />
          </div>
        </Fade>
        <div className="flex justify-center">
          <Button text="Cancel" customStyle={styles.btnWhite} onClick={handleCancel} />
          <Button text="Next" customStyle={styles.btnBlue} onClick={handleNext} />
        </div>
      </div>
    </section>
  );
});
FirstStep.displayName = 'FirstStep';
export default FirstStep;
