import { ic_complete_fee, ic_term_fee } from '@static/image';

import './cashier-fee-card.scss';

function CashierFeeCard(props) {
  const {
    isComplete,
    title,
    dueDate = new Date(),
    id,
    price,
    idx,
    handleSelected = () => {},
  } = props;

  return (
    <div
      onClick={() => {
        handleSelected(idx);
      }}
      className="flex items-center justify-start my-4 cashier-fee-card cursor-pointer"
    >
      {isComplete ? (
        <div>
          <img src={ic_complete_fee} alt="fee card" />
        </div>
      ) : (
        <div className="w-4 h-4"></div>
      )}
      <div className="flex flex-col ml-3 w-full">
        <div className="flex items-center">
          <img className="mr-3" src={ic_term_fee} alt="term fee" />
          <span className="cashier-fee-card-title">{title}</span>
        </div>
        <div className="flex items-center justify-between">
          <div className="cashier-fee-card-id">
            <p>Due {new Date(dueDate).toGMTString().slice(4, 11)}</p>
            <p>#{id}</p>
          </div>
          <div className="cashier-fee-card-price">₹{price}</div>
        </div>
      </div>
    </div>
  );
}

export default CashierFeeCard;
