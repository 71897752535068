import React, { useState } from 'react';

import { deleteIcon, editIcon, move } from './icon';
import FormPageBreak from './form-PageBreak';
import { componentFieldName, componentField } from '../utils';

const FormComponentUser = (props) => {
  const {
    id,
    order,
    index,
    isEdit,
    isFocus,
    isBuild,
    isRequired,
    deletable,
    component,
    label,
    value,
    error,
    textForError,
    formStyle,
    handleFocus,
    handleDelete,
    handleDragStart,
    handleDragOver,
    ...rest
  } = props;

  // const [opacity, setOpacity] = useState(1);

  const { width, height, ...restFormStyle } = formStyle;

  const attributes = { label, value, ...rest };

  switch (component) {
    case 'Multiselect':
      attributes.styles = { width: '100%', height };
      break;
    default:
      attributes.customStyle = { width: '100%', height };
      break;
  }

  if (
    [componentFieldName.multiselect, componentFieldName.dropdown].includes(component) &&
    typeof props.options === 'string'
  ) {
    const tmp = props.options.split(';').map((item) => ({ value: item, label: item }));
    attributes.options = tmp;
  }

  if (component === componentFieldName.pageBreak) return <FormPageBreak key={id} />;

  return (
    <div
      className="component"
      draggable
      onDragStart={(e) => {
        // handleDragStart(e, index);
        // setOpacity(0.3);
      }}
      onDragOver={handleDragOver}
      style={{
        width,
        ...restFormStyle,
        outline: error ? '1px #ff4842 solid' : isFocus ? '1px black solid' : '',
        outlineOffset: '0rem',
        // opacity: opacity,
      }}
    >
      <div className="setting items-center absolute bg-white px-[0.15rem] right-2 top-[-0.5rem] z-10">
        {isEdit && (
          <>
            <img
              className="cursor-pointer"
              title="edit"
              src={editIcon}
              onClick={() => {
                handleFocus(order, index);
              }}
              alt="edit"
            />
            <img
              className="ml-2 cursor-pointer"
              title="delete"
              src={deleteIcon}
              onClick={() => {
                handleDelete(order, index);
              }}
              alt="delete"
            />
          </>
        )}
      </div>
      {componentField[component](attributes)}
      {error && textForError && (
        <p className="text-sm" style={{ color: '#ff4842', marginLeft: 10 }}>
          {textForError}
        </p>
      )}
    </div>
  );
};
export default FormComponentUser;
