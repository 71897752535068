import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastMessageContext } from '../../context/toast-context';
import { checkErrorApiFetch } from '../../utils/check-error/api-error';
import EditUser from './edit-user';
import authApi from 'api/auth';
import useStorage from 'store/storage';

const USER_ROLE = {
  TEACHER: 'Teacher',
  STUDENT: 'Student',
  PARENT: 'Parent',
  GIIKI_ADMIN: 'Giiki Admin',
  CENTRAL_ADMIN: 'Central Admin',
  CAMPUS_ADMIN: 'Campus Admin',
};

export default function Edit({ burger, state }) {
  const [isParent, setIsParent] = useState(false);
  const [isStudent, setIsStudent] = useState(false);
  const [isTeacher, setIsTeacher] = useState(false);
  const [selectUser, setSelectUsers] = useState({});
  const [isGiikiAdminOrCentralAdmin, setIsGiikiAdminOrCentralAdmin] = useState(false);
  const location = useLocation();
  const userLogin = useStorage((state) => state.auth);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  useEffect(() => {
    let roleOfUser = '';
    const idUserSelect = location.state.selectUser.id;

    if (location.state?.selectUser?.userRoles) {
      const length = location.state.selectUser.userRoles.length;
      for (let i = 0; i < length; i++) {
        if (location.state.selectUser.userRoles[i].roles.name === USER_ROLE.PARENT) {
          roleOfUser = USER_ROLE.PARENT;
          setIsParent(true);
          break;
        }
        if (location.state.selectUser.userRoles[i].roles.name === USER_ROLE.STUDENT) {
          roleOfUser = USER_ROLE.STUDENT;
          setIsStudent(true);
          break;
        }
        if (location.state.selectUser.userRoles[i].roles.name === USER_ROLE.TEACHER) {
          roleOfUser = USER_ROLE.TEACHER;
          setIsTeacher(true);
          break;
        }
      }
    }

    // callApi({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_AUTH}/api/v1/user/${idUserSelect}`,
    //   params: { roleName: roleOfUser },
    // })
    authApi
      .getRoleOfUser({ roleName: roleOfUser }, idUserSelect)
      .then((results) => {
        if (
          checkErrorApiFetch(results, setToastMessage, setIsShowToastMessage, 'Get User')
        ) {
          const user = results.data.data;
          if (user?.detail) {
            setSelectUsers({
              ...user,
              firstName: user.detail.firstName,
              lastName: user.detail.lastName,
              middleName: user.detail.middleName,
            });
          } else {
            setSelectUsers(user);
          }
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Get User Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Get User Error', error);
      });

    if (userLogin?.roles) {
      const length = userLogin.roles.length;
      for (let i = 0; i < length; i++) {
        if (
          userLogin.roles[i].name === USER_ROLE.GIIKI_ADMIN ||
          userLogin.roles[i].name === USER_ROLE.CENTRAL_ADMIN
        ) {
          setIsGiikiAdminOrCentralAdmin(true);
          break;
        }
      }
    }
  }, []);

  return (
    <div>
      {location?.state && (
        <>
          <EditUser
            selectUser={selectUser}
            {...(location.state.company
              ? { company: location.state.company }
              : { campus: location.state.campus })}
            // campus={location.state?.campus || {}}
            // company={location.state?.company || location.state?.campus?.companyId || {}}
            // company={location.state?.company || {}}
            burger={burger}
            isParent={isParent}
            isStudent={isStudent}
            isTeacher={isTeacher}
            isGiikiAdminOrCentralAdmin={isGiikiAdminOrCentralAdmin}
          />
        </>
      )}
    </div>
  );
}
