import clsx from 'clsx';

import { request } from '../../image';

const TeamMenu = ({ setVisibleTeamMemberModal, setIsShowTeamMenu }) => {
  return (
    <div
      style={{ boxShadow: '2px 2px 15px 4px #E5E5E5' }}
      className="absolute top-[3rem] left-[0rem] flex flex-col w-[12rem] h-[10rem] bg-[#fff] rounded-lg border p-3 pl-4 text-[14px] z-50"
    >
      <p className="text-[14px] my-2">Team Menu</p>
      <div className="flex items-center justify-start my-1">
        <img src={request} alt=""></img>
        <p
          onClick={() => {
            setVisibleTeamMemberModal(true);
            setIsShowTeamMenu(false);
          }}
          className={clsx('ml-3 cursor-pointer')}
        >
          Team members
        </p>
      </div>
    </div>
  );
};

export default TeamMenu;
