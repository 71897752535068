import { memo } from 'react';

import { Button } from '@stories/index';
import { backarrow } from '@pages/bloomingdale/image';
import HealthInfo from '@components/admission-module/application/information/subcomponents/health-info/health-info';
import LanguageInfo from '@components/admission-module/application/information/subcomponents/language-info/language-info';
import LearningInfo from '@components/admission-module/application/information/subcomponents/learning-need/learning-need';
import HistorySchoolInfo from '@components/admission-module/application/information/subcomponents/history-school/history-school';
import FooterDetail from '../footer-detail/footer-detail';

const SecondStep = memo((props) => {
  const { info, listField, setStep, handleChange, handleAdd, handleCancel, handleRemove, handleNext } =
    props;
  const { schoolInfo, healthInfo, languageInfo, learningInfo } = info;
  return (
    <section className='classForScroll'>
      <div className="w-full flex items-start pl-20 pr-20">
        <div className="w-full">
          <h1 className="font-semibold text-lg mb-10 text-main-blue">
            Application Form | Student History
          </h1>
          <section>
            <div className="flex items-center pl-5 font-semibold mb-4 h-[3rem] bg-[#f5fcf9]">
              School History
            </div>
            <div className="space-left">
              <HistorySchoolInfo
                listField={listField.school}
                info={schoolInfo}
                handleChange={handleChange}
                handleAdd={handleAdd}
                handleRemove={handleRemove}
              />
            </div>
          </section>

          <section>
            <div className="flex items-center pl-5 font-semibold mb-4 h-[3rem] bg-[#f5fcf9]">
              Health Information
            </div>
            <div className="space-left">
              <HealthInfo
                info={healthInfo}
                variation="parent"
                listField={listField.health}
                handleChange={handleChange}
              />
            </div>
          </section>

          <section>
            <div className="flex items-center pl-5 font-semibold mb-2 h-[3rem] bg-[#f5fcf9]">
              Language Information
            </div>
            <div className="space-left">
              <LanguageInfo
                info={languageInfo}
                handleChange={handleChange}
                handleAdd={handleAdd}
                handleRemove={handleRemove}

              />
            </div>
          </section>

          <section>
            <div className="flex items-center pl-5 font-semibold mb-4 h-[3rem] bg-[#f5fcf9]">
              Learning Needs
            </div>
            <div className="space-left">
              <LearningInfo
                info={learningInfo}
                variation="parent"
                listField={listField.learning}
                handleChange={handleChange}
              />
            </div>
          </section>
        </div>
      </div>
      <FooterDetail handleCancel={handleCancel} handleNext={handleNext} />
    </section>
  );
});
SecondStep.displayName = 'SecondStep';
export default SecondStep;
