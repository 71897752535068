import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SelectGroup } from '@stories/index';
import { checkErrorApiFetch } from '@utils/check-error/api-error';
import { localStorageKey, sessionStorageKey } from '@utils/storage';
import { ToastMessageContext } from 'context/toast-context';
import { deleteIcon, editIcon } from './icon';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';

const EnrollmentYear = (props) => {
  const {
    id,
    index,
    isEdit,
    isBuild,
    isFocus,
    isRequired,
    deletable,
    label,
    value,
    error,
    textForError,
    cascadeValue,
    formStyle,
    handleFocus,
    handleDelete,
    ...rest
  } = props;
  const { width, height, ...restFormStyle } = formStyle;
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [options, setOptions] = useState([]);
  const campus = useStorage((state) => state.currentCampus);
  const campusId = campus?.id;

  const fetchData = useCallback((gradeId) => {
    centralAdminApi.getListAcademicYear({ campusId, gradeId })
      .then((res) => {
        if (
          checkErrorApiFetch(
            res,
            setToastMessage,
            setIsShowToastMessage,
            'Get Enrollment Year',
          )
        ) {
          const listYear = res.data.data.objects.map(({ id, name }) => ({
            value: id,
            label: name,
          }));
          setOptions(listYear);
        }
      })
      .catch((error) => {
        setOptions([]);
        console.log('Get Enrollment Year', error);
        setToastMessage({
          status: 'error',
          title: 'Enrollment Year Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      });
  }, [campusId]);

  useEffect(() => {
    fetchData(cascadeValue.gradeId);
  }, [cascadeValue.gradeId, campusId]);

  const attributes = {
    label,
    value,
    options,
    styles: { width: '100%', height },
    ...rest,
  };

  return (
    <div
      className="component"
      style={{
        width,
        ...restFormStyle,
        outline: error ? '1px #ff4842 solid' : isFocus ? '1px black solid' : '',
        outlineOffset: '0rem',
      }}
    >
      <div className="setting items-center absolute bg-white px-[0.15rem] right-2 top-[-0.5rem] z-10">
        {isBuild && (
          <>
            <img
              alt=""
              className="cursor-pointer"
              title="edit"
              src={editIcon}
              onClick={() => {
                handleFocus(index);
              }}
            />
            <img
              alt=""
              className="ml-2 cursor-pointer"
              title="delete"
              src={deleteIcon}
              onClick={() => {
                handleDelete(index);
              }}
            />
          </>
        )}
        {isEdit && (
          <>
            <img
              alt=""
              className="cursor-pointer"
              title="edit"
              src={editIcon}
              onClick={() => {
                handleFocus(index);
              }}
            />
            {deletable && (
              <img
                alt=""
                className="ml-2 cursor-pointer"
                title="delete"
                src={deleteIcon}
                onClick={() => {
                  handleDelete(index);
                }}
              />
            )}
          </>
        )}
      </div>
      <SelectGroup {...attributes} />
      {error && textForError && (
        <p className="text-sm" style={{ color: '#ff4842', marginLeft: 10 }}>
          {textForError}
        </p>
      )}
    </div>
  );
};
export default EnrollmentYear;
