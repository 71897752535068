import React, { memo, useMemo } from 'react';
import clsx from 'clsx';
import { buttoncheck } from '@pages/bloomingdale/image';
import { SelectGroup } from '@stories/index';
import { languages } from '@utils/utils';
import { bloomSelect } from 'entities/select-style';
import Add from '../add/add';
import { ic_close_black } from '@static/image';

const LanguageInfo = memo((props) => {
  const { info, handleChange, handleRemove, handleAdd } = props;
  return (
    <>
      {info.map((item, index) => {
        if (item.isDelete) return null

        return (
          <div key={`language${index}`}>
            <SingleLanguage
              info={item}
              order={index}
              handleAdd={handleAdd}
              handleChange={handleChange}
              handleRemove={handleRemove}
            />
          </div>
        )
      })}
      <div className="flex items-center justify-center">
        <button
          onClick={() => {
            handleAdd({ name: 'languageInfo' });
          }}
          className="mt-6 mb-6 none-after flex items-center font-semibold text-sm"
          style={{ color: '#ababcd' }}
        >
          <Add />
          Add Language
        </button>
      </div>
    </>
  );
});
LanguageInfo.displayName = 'LanguageInfo';
export default LanguageInfo;

const SingleLanguage = memo((props) => {
  const { info, order, handleRemove, handleChange } = props;

  const proficiency = useMemo(
    () => [
      { label: 'Read', value: 'read' },
      { label: 'Speak', value: 'speak' },
      { label: 'Write', value: 'write' },
    ],
    [],
  );

  return (
    <div className="flex items-center justify-between h-20">
      <div className="h-full w-[25%]">
        <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
          Language Name
        </p>
        <SelectGroup
          isMulti={false}
          styles={{
            ...bloomSelect,
            control: (baseStyles) => ({
              ...baseStyles,
              height: '2.55rem'
            }),
          }}
          options={languages}
          value={info?.languageName || null}
          onChange={(e) => {
            handleChange({ name: 'languageInfo', order, key: 'languageName', value: e });
          }}
        />
      </div>
      <div className="h-full w-[70%]">
        <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
          Proficiency
        </p>
        <div className="flex items-center">
          {proficiency.map((item, index) => {
            const { value, label } = item;
            return (
              <button
                key={`proficiency${order}${index}`}
                className={clsx(
                  'group-button none-after',
                  info?.[value] && 'group-button--active',
                )}
                onClick={() =>
                  handleChange({
                    name: 'languageInfo',
                    order,
                    key: value,
                    value: !info?.[value],
                  })
                }
              >
                {info?.[value] && (
                  <img
                    src={buttoncheck}
                    className="w-3.5 aspect-square mr-3"
                    alt="check"
                  />
                )}
                {label}
              </button>
            );
          })}
          <button title='remove' onClick={() => {
            handleRemove({ name: 'languageInfo', order })
          }}>
            <img src={ic_close_black} alt='remove' />
          </button>
        </div>
      </div>
    </div>
  );
});
