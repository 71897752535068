import React, { useState } from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import { callout_card } from "@static/image";
import { Button, SelectGroup } from "@stories/index";

const AddFeeDialog = (props) => {
  const { open, onClose, optionFees, handleAdd } = props;
  const [feeName, setFeeName] = useState(null);

  const isDisabled = !feeName

  const onAdd = () => {
    handleAdd(feeName.value)
    setFeeName(null)
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className='text-xl font-medium' sx={{ color: '#202223', fontFamily: 'Inter' }}>
        Add other Fee
      </DialogTitle>

      <Divider />

      <DialogContent sx={{ width: '35rem', height: 'fit-content', minHeight: '10rem', py: 7 }} >
        <Box px={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'} columnGap={3}>
          <img src={callout_card} />
          <SelectGroup
            isMulti={false}
            value={feeName}
            title='Select Fee'
            options={optionFees}
            onChange={(e) => setFeeName(e)}
          />
        </Box>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button text='Cancel' buttonStyle='outlined' onClick={onClose} />
        <Button text='Add' buttonStyle='contained' buttonDisabled={isDisabled} onClick={onAdd} />
      </DialogActions>

    </Dialog>
  )
}

export default AddFeeDialog;