import { useContext, useRef } from 'react';
import { Button, Input } from '@stories/index';
import { checkErrorSingleApi } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { toggleButtonStyles } from '../../../../entities/select-style';
import taskApi from 'api/task';
import useStorage from 'store/storage';

function UpdateTaskName(props) {
  const {
    initialSelect,
    isSelect,
    setIsSelect,
    task,
    index,
    setRefresh,
    setIsUpdate = () => { },
    setPage = () => { },
  } = props;
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campus = useStorage((state) => state.currentCampus);
  const nameRef = useRef();

  const handleUpdateTaskName = () => {
    const newName = nameRef.current.value.trim();
    if (newName.length === 0) {
      setToastMessage({
        status: 'error',
        title: 'Validation Rule',
        message: 'Task name is not empty',
      });
      setIsShowToastMessage(true);
      return;
    }
    taskApi.updateTaskById({ campusId: campus?.isCentral ? undefined : campus?.id }, { name: newName }, task.id)
      .then((res) => {
        if (
          checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, 'Update Task')
        ) {
          setIsSelect(initialSelect);
          setRefresh((prev) => ({
            ...prev,
            count: (prev.count || 0) + 1,
            index,
          }));
          setIsUpdate((prev) => !prev);
          setPage(0);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Update Task Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Update task error', error.message);
      });
  };

  return (
    <>
      {isSelect.name && (
        <div className="flex flex-wrap justify-center absolute top-12 w-[18rem] h-32 left-[-1rem] bg-[#fff] shadow-2xl z-20 rounded">
          <div className="mt-6">
            <Input
              refCustom={nameRef}
              onChange={(e) => {
                // setEnteredTaskName(e.target.value);
              }}
              label="Task Name"
              name="task-name"
              type="text"
              customStyle={{
                width: '16.25rem',
                height: '2.5rem',
                fontSize: '0.875rem',
                borderRadius: '0.25rem',
              }}
            // error={error.statusFirstName}
            />
          </div>
          <div className="flex justify-center">
            <Button
              className="text-[0.8rem]"
              text="Cancel"
              customStyle={{
                ...toggleButtonStyles.btnWhite,
                width: '7rem',
                height: '1.5rem',
              }}
              onClick={() => {
                setIsSelect(initialSelect);
                // setEnteredTaskName('');
              }}
            />
            <Button
              className="text-[0.8rem]"
              text="Save"
              customStyle={{
                ...toggleButtonStyles.btnBlue,
                width: '7rem',
                height: '1.5rem',
              }}
              onClick={() => {
                handleUpdateTaskName();
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default UpdateTaskName;
