import { memo } from 'react';
import SchoolServices from '@components/admission-module/application/information/subcomponents/school-service/school-service';
import OtherQuestions from '@components/admission-module/application/information/subcomponents/other-question';
import { EnquiryStatus } from 'entities/data';
import { Button } from '@stories/index';

const ThirdStep = memo((props) => {
  const { info, enquiry, listField, handleChange, handleCancel, handleSave } = props;
  const { schoolServices, otherQuestions } = info;

  return (
    <section className='classForScroll'>
      <div className="w-full flex items-start pl-20 pr-20">
        <div className="w-full">
          <h1 className="font-semibold text-lg mb-10 text-main-blue">
            Application Form | School Preferences
          </h1>

          <section>
            <div className="flex items-center pl-5 font-semibold mb-4 h-[3rem] bg-[#f5fcf9]">
              School Services
            </div>
            <div className="space-left">
              <SchoolServices
                info={schoolServices}
                listField={listField.schoolServices}
                handleChange={handleChange}
              />
            </div>
          </section>

          <section>
            <div className="flex items-center pl-5 font-semibold mb-4 mt-8 h-[3rem] bg-[#f5fcf9]">
              Other Questions
            </div>
            <div className="space-left">
              <OtherQuestions
                info={otherQuestions}
                listField={listField.otherQuestions}
                handleChange={handleChange}
              />
            </div>
          </section>
        </div>
      </div>
      <div className="bloom-footer justify-center">
        <Button
          buttonStyle="outlined"
          text="Cancel"
          buttonSize="medium"
          customStyle={{
            width: '12rem',
            justifyContent: 'center',
            marginRight: '1rem',
            borderRadius: '0px',
          }}
          onClick={handleCancel}
        />

        <Button
          buttonStyle="contained"
          onClick={handleSave}
          text={[EnquiryStatus.CLOSED, EnquiryStatus.COMPLETE].includes(enquiry.status) ? 'Next Step' : 'Save And Next'}
          buttonSize="medium"
          customStyle={{
            width: '12rem',
            justifyContent: 'center',
            borderRadius: '0px',
          }}
        />
      </div>
    </section>
  );
});
ThirdStep.displayName = 'ThirdStep';
export default ThirdStep;
