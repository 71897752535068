import { callApi } from '@helper/call-api';

class TaskApi {
  // constructor() {
  //   this.baseUrl = `${process.env.REACT_APP_URL_API_TASK}/api/v2/user`;
  // }

  reopenTAsk = async (taskId, params) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_TASK}/api/v2/task/reopen/${taskId}`,
      params,
    });
  };

  updateTaskById = async (params, data, taskId) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_TASK}/api/v2/task/${taskId}`,
      params,
      data,
    });
  };

  updateTimeWork = async (params, data, taskId) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_TASK}/api/v2/task/time-work/${taskId}`,
      params,
      data,
    });
  };

  createTask = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_TASK}/api/v2/task`,
      params,
      data,
    });
  };

  getListTaskOfTeam = async (params, teamId) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_TASK}/api/v2/task/list/${teamId}`,
      params,
    });
  };

  uploadMedia = async (data, contentType = 'multipart/form-data') => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_TASK}/api/v1/media`,
      data,
      contentType,
    });
  };

  getTaskById = async (params, taskId) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_TASK}/api/v2/task/information/${taskId}`,
      params,
    });
  };

  updateTaskInformation = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_TASK}/api/v2/task/information`,
      params,
      data,
    });
  };

  updateTeamById = async (params, data, teamId) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_TASK}/api/v2/task/team/${teamId}`,
      params,
      data,
    });
  };

  getListTeam = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_TASK}/api/v2/task/team/list`,
      params,
    });
  };

  getListConversation = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_TASK}/api/v2/task/conversation/list`,
      params,
    });
  };

  createNewMessage = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_TASK}/api/v2/task/conversation`,
      params,
      data,
    });
  };

  editMessage = async (params, data, messageId) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_TASK}/api/v2/task/conversation/${messageId}`,
      params,
      data,
    });
  };

  requestExtensionById = async (params, data, taskId) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_TASK}/api/v2/task/request-extension/${taskId}`,
      params,
      data,
    });
  };

  delegateTaskById = async (params, data, taskId) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_TASK}/api/v2/task/delegate/${taskId}`,
      params,
      data,
    });
  };

  acceptDelegation = async (data, delegationId) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_TASK}/api/v2/task/delegation/${delegationId}`,
      data,
    });
  };

  deleteFileById = async (params, fileId) => {
    return callApi({
      method: 'delete',
      url: `${process.env.REACT_APP_URL_API_TASK}/api/v2/task/file/${fileId}`,
      params,
    });
  };

  getListFile = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_TASK}/api/v2/task/file/list`,
      params,
    });
  };

  uploadMediaFile = async (data, contentType = 'multipart/form-data') => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_TASK}/api/v1/media/file`,
      data,
      contentType,
    });
  };

  saveTaskFile = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_TASK}/api/v2/task/file`,
      params,
      data,
    });
  };

  createNewTeam = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_TASK}/api/v2/task/team`,
      params,
      data,
    });
  };
}
const taskApi = new TaskApi();
export default taskApi;
