import { callApi } from '@helper/call-api';

class MediaApi {
  // constructor() {
  //   this.baseUrl = `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/media`;
  // }

  uploadMedia = async (data, contentType = 'multipart/form-data') => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/media`,
      data,
      contentType,
    });
  };

  uploadFile = async (data, contentType = 'multipart/form-data') => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/media/file`,
      data,
      contentType,
    });
  };

  uploadMediaAdmission = async (data, contentType = 'multipart/form-data') => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/media`,
      data,
      contentType,
    });
  };
}

const mediaApi = new MediaApi();
export default mediaApi;
