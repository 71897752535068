import { useState } from 'react';

import CashierSideBar from '../CashierSideBar';
import UserCard from '../UserCard';
import UnpaidList from './UnpaidList';
import ScholarshipList from './ScholarshipList';
import PaidList from './PaidList';

const tabs = ['Unpaid', 'Scholarships', 'Paid'];

function BillSideBar(props) {
  const {
    applicationId,
    user,
    fee = [],
    listScholarships = [],
    handleSelected = () => {},
    handleClickCancel = () => {},
  } = props;
  const [tabActive, setTabActive] = useState(tabs[0]);

  return (
    <CashierSideBar title="Bills" setTabActive={setTabActive} tabActive={tabActive}>
      <div className="mt-5">
        <UserCard
          handleClickCancel={handleClickCancel}
          applicationId={applicationId}
          user={user}
          isShowClose={true}
        />
      </div>
      {tabActive === tabs[0] && <UnpaidList fee={fee} handleSelected={handleSelected} />}
      {tabActive === tabs[1] && <ScholarshipList listScholarships={listScholarships} />}
      {tabActive === tabs[2] && <PaidList fee={fee} />}
    </CashierSideBar>
  );
}

export default BillSideBar;
