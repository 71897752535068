import React, { useEffect, useState } from 'react';

import {
  approvalActivity,
  mainavatar,
  Accept,
  Waiting,
  Reject,
  add,
  addNew,
  approvalNew,
  needInfoNew,
  rejectNew,
  feedback,
  waitApproval,
} from '../../../static/image';
import './body-activity.scss';

export default function BodyActivity({ activityData }) {
  // const [activities, setActivities] = useState([])
  //   const statusHandle = (status) => {
  //     let result = '';
  //     if (status) {
  //       result = status;
  //     }
  //     return result;
  //   };
  const handleDateTime = (date) => {
    if (date) {
      const day = new Date(date).toUTCString().slice(5, 16);
      const time = new Date(date).toLocaleTimeString('en-US');
      return { day, time };
    }
    // } else {
    //   day = new Date().toUTCString().slice(5, 16)
    //   time = new Date().toLocaleTimeString('en-US')
    // }
    return { day: '', time: '' };
  };
  const activities = [];
  activityData.forEach((activity) => {
    const { resultDate, name } = activity;
    activity.processStageApprover.forEach((user) => {
      const { processStageApproverResult, approver } = user;
      // let status, dateUpdated, message
      // const { status, dateUpdated, message } = processStageApproverResult;
      const { name, photoURL, userRoles } = approver;

      const { day, time } = handleDateTime(
        processStageApproverResult ? processStageApproverResult?.dateUpdated : resultDate,
      );
      const data = {
        // status: statusHandle(status ? status : ''),
        status: processStageApproverResult?.status || '',
        date: day,
        time: time,
        stageName: name,
        user: {
          name: name ? name : 'User Name',
          avatar: photoURL ? photoURL : mainavatar,
          position: userRoles?.[0]?.name ? userRoles[0].name : 'User Position',
          message: processStageApproverResult?.message ? processStageApproverResult.message : '',
        },
      };
      activities.push(data);
    });
  });
  const handleIconAproval = (isApproval) => {
    switch (isApproval.toLowerCase()) {
      case 'approve':
        return approvalNew;
      case 'reject':
        return rejectNew;
      case 'create':
        return addNew;
      case 'need info':
        return needInfoNew;
      default:
        return waitApproval;
    }
    // let iconAproval;
    // if (isApproval.toLowerCase() === 'approve') {
    //   iconAproval = approvalNew;
    // } else if (isApproval.toLowerCase() === 'reject') {
    //   iconAproval = rejectNew;
    // } else if (isApproval.toLowerCase() === 'create') {
    //   iconAproval = addNew;
    // } else if (isApproval.toLowerCase() === 'need info') {
    //   iconAproval = needInfoNew;
    // } else {
    //   iconAproval = waitApproval;
    // }
    // return iconAproval;
  };

  return (
    <div className="relative">
      {activities.length === 0 ? (
        <div className="flex flex-col items-center mt-20">
          <div>
            <img src={approvalActivity} alt="avatar" className="mr-2 pb-0.5 rounded-full" />
          </div>
          <div className="text-xl text-[#979797] mt-4">No updates yet for this item</div>
        </div>
      ) : (
        <div className="flex flex-col">
          {activities.map((activity, idx) => {
            const { status, date, stageName, time, user } = activity;
            const { name, position, avatar, message } = user;
            return (
              <div key={idx} className="flex mt-2">
                <div className="min-w-[2rem] mt-1">
                  <img
                    src={handleIconAproval(status)}
                    alt="avatar"
                    className="w-5 h-5 z-10 bg-slate-50 rounded-full border-[0.5px] border-[#B0B6BE]"
                  />
                  {idx !== activities.length - 1 && (
                    <div className="ml-2.5" style={{ backgroundColor: '#B0B6BE', height: '100%', width: '0.5px' }} />
                  )}
                </div>
                <div className="min-w-[6rem] mt-0.5">
                  <p className="text-xs">
                    {date.slice(0, 7)}
                    <span style={{ fontSize: '0.5rem' }}>{date.slice(7)}</span>
                  </p>
                  <p style={{ color: '#BABEC3', fontSize: '0.625rem' }}>{time}</p>
                </div>
                <div className="flex flex-col">
                  <div className="text-sm mt-0.25">{stageName}</div>
                  <div className="flex">
                    <img src={avatar} alt="avatar" className="w-5 h-5 mr-2 pb-0.5 rounded-full" />
                    <p className="align-middle mt-0.5" style={{ fontSize: '0.625rem' }}>
                      {name} | {position}
                    </p>
                  </div>
                  {message && (
                    <div className="relative">
                      <div className="triangle-bottom-right-activity"></div>
                      <p
                        className="inline-block border mt-1 py-2 px-2 rounded-lg max-w-xs"
                        style={{ fontSize: '0.625rem', color: '#8C9094', backgroundColor: '#F1F2F3' }}
                      >
                        {message}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

//Admission Test
// const activities = [
//     {
//         status: 'create',
//         date: '07 Sep 2022',
//         time: '3:30 PM',
//         stageName: 'Create',
//         user: {
//             name: 'Satya Nadellai',
//             avatar: mainavatar,
//             position: 'Teacher',
//             message: '',
//         }
//     },
//     {
//         status: 'approval',
//         date: '08 Sep 2022',
//         time: '3:30 PM',
//         stageName: 'Document Verification',
//         user: {
//             name: 'Meena Kodali',
//             avatar: mainavatar,
//             position: 'Admissions Counsellor',
//             message: '',
//         }
//     },
//     {
//         status: 'need info',
//         date: '08 Sep 2022',
//         time: '3:50 PM',
//         stageName: 'Document Verification',
//         user: {
//             name: 'Sunday wolf',
//             avatar: mainavatar,
//             position: 'Admissions Counsellor',
//             message: 'He did well in Math needs to improve in Language. Overall scored 20/45',
//         }
//     },
//     {
//         status: 'approval',
//         date: '08 Sep 2022',
//         time: '4:30 PM',
//         stageName: 'Document Verification',
//         user: {
//             name: 'Nancy Drew',
//             avatar: mainavatar,
//             position: 'Admissions Counsellor',
//             message: 'He did well in Math needs to improve in Language. Overall scored 20/45. He did well in Math needs to improve in Language. Overall scored 20/45',
//         }
//     },
//     {
//         status: 'reject',
//         date: '09 Sep 2022',
//         time: '10:30 AM',
//         stageName: 'Final Approval',
//         user: {
//             name: 'Harsimran Kaur',
//             avatar: mainavatar,
//             position: 'Principal',
//             message: 'He did well in Math needs to improve in Language. Overall scored 20/45',
//         }
//     }
// ]
