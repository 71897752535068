import { cancel, cancel_meeting } from "@static/image";
import { Button, SelectGroup } from "@stories/index";
import { checkErrorSingleApi } from "@utils/check-error/api-error";
import { APPOINTMENT_STATUS } from "@utils/constant";
import admissionApi from "api/admission";
import { ToastMessageContext } from "context/toast-context";
import { useContext, useState } from "react";

const reasons = [
  { value: 'The Guest is unable to make it', label: 'The Guest is unable to make it' },
  { value: 'Had to cancel because of school Activities', label: 'Had to cancel because of school Activities' },
  { value: 'Host is not available', label: 'Host is not available' }
]
const CancelPopup = (props) => {
  const { data, setRefresh, onClosePopup } = props
  const [reason, setReason] = useState(null)
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const onCancelMeeting = async () => {
    try {
      const payload = {
        status: APPOINTMENT_STATUS['Canceled'],
        reason: reason?.value
      }
      const res = await admissionApi.updateAdmissionCalendar({ id: data.id, data: payload })

      if (checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, 'Cancel Meeting')) {
        setRefresh(prev => !prev)
        onClosePopup()
      }
    } catch (error) {
      console.log('Confirm Meeting error', error);
      setToastMessage({
        status: 'error',
        title: 'Confirm Meeting Failed',
        message: error.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
    }
  }


  return (
    <div className="bg-white w-[31rem] p-3 rounded-lg">
      <div className="flex items-center justify-between">
        <div className="flex items-center text-lg font-medium">
          <img src={cancel_meeting} className="w-8 aspect-square mr-3" />
          Cancel
        </div>
        <button onClick={onClosePopup}>
          <img src={cancel} className="w-7 aspect-square" />
        </button>
      </div>

      <hr className="my-4 bg-[#B0B6BE]" />

      <p className="text-base text-[#637381] font-semibold mb-3">The meeting is canceled because</p>
      <SelectGroup
        value={reason}
        options={reasons}
        onChange={(e) => setReason(e)}
      />

      <hr className="my-3 bg-[#B0B6BE]" />

      <div className="flex items-center ml-[65%] w-[35%} justify-between min-w-[10rem] mt-4">
        <Button text='Cancel'
          buttonStyle='outline'
          isOutlined
          className={'text-sm'}
          onClick={onClosePopup}
        />
        <Button text='Extend'
          buttonStyle='contained'
          className={'text-sm'}
          onClick={onCancelMeeting}
        />
      </div>
    </div>
  )
}
export default CancelPopup;