import React, { memo, useState, useCallback, useEffect } from 'react';

import { mainavatar } from '../../../../static/image';
import Popup from './Popup';
import { point, threepoint, like } from './icon';

const SingleComment = memo((props) => {
  const { customStyle, info, children, setInfoAction, backgroundColor, index } = props;
  const { message, name, photoURL, duration, owner, edited } = info;
  const [openPopup, setOpenPopup] = useState(false);
  const [showChild, setShowChild] = useState(false);

  const handleOpenPopup = useCallback(() => {
    setOpenPopup((prev) => !prev);
  }, []);

  const handleEdit = useCallback(() => {
    setOpenPopup(false);
    setInfoAction((prev) => ({
      ...prev,
      action: 'edit',
      index,
      info,
    }));
  }, [info]);

  const handleReply = useCallback(() => {
    setInfoAction((prev) => ({
      ...prev,
      action: 'reply',
      index,
      info,
    }));
  }, [info]);

  return (
    <div className="relative">
      <div
        className="px-2 py-2 border-t not-italic rounded"
        style={{
          borderColor: '#B0B6BE',
          backgroundColor: backgroundColor,
          ...customStyle,
        }}
      >
        <div className="w-full h-[2rem] flex items-center justify-between">
          <div className="flex items-center w-full">
            <img
              className="block w-[1.5rem] h-[1.5rem] object-fill rounded"
              src={photoURL || mainavatar}
              alt='avatar comment'
            />
            <p className="text-ellipsis1 text-sm w-fit max-w-[50%] ml-[2%] font-medium">
              {/* Mali Chan */}
              {name}
            </p>
            <img className="block ml-[2%]" src={point} alt='point comment'/>

            <p className="text-xs ml-[1%]" style={{ color: '#BABEC3' }}>
              {/* 1 hours ago */}
              {duration}
            </p>
            {edited && (
              <p className="ml-[3%] text-xs" style={{ color: '#BABEC3' }}>
                edited
              </p>
            )}
          </div>
          <div>
            <img className="cursor-pointer" src={threepoint} onClick={handleOpenPopup} alt='three point'/>
          </div>
        </div>
        <div>
          <p className="text-xs w-full h-fit max-h-[5rem]" style={{ color: '#828282' }}>
            {message}
          </p>
        </div>
        <div className="w-[20%] flex items-center justify-between mt-1">
          <div
            // className="flex items-center justify-between w-[40%] px-[5%] rounded"
            className="flex items-center justify-center w-[20%] px-[5%] rounded"
            style={{
              backgroundColor: '#0000001A',
            }}
          >
            <img src={like} className="cursor-pointer" alt='like'/>
            {/* <p className="ml-2 text-sm">1</p> */}
          </div>
          <p className="text-sm cursor-pointer font-medium" onClick={handleReply}>
            Reply
          </p>
        </div>
      </div>
      {children.length !== 0 &&
        (showChild ? (
          children
        ) : (
          <div className="ml-[4%]">
            <p
              className="text-xs text-green-400 cursor-pointer"
              onClick={() => {
                setShowChild(true);
              }}
              style={{ marginLeft: customStyle.marginLeft }}
            >
              {`${children.length} ${children.length === 1 ? 'reply' : 'replies'} `}
            </p>
          </div>
        ))}
      {/* {children} */}
      {openPopup && owner && <Popup handleEdit={handleEdit} />}
    </div>
  );
});
SingleComment.displayName = 'SingleComment';

export default SingleComment;
