import * as _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ToastMessageContext } from '../../../context/toast-context';
import FirstStep from './first-step/first-step';
import SecondStep from './second-step/second-step';
import ThirdStep from './third-step/third-step';
import {
  getDataApi,
  getListField,
  handleAddElement,
  handleChangeField,
  handleRemoveElement,
  scroll,
  syncObjValue,
} from '@helper/application';
import './details.scss';
import { checkErrorApiFetch, checkErrorSingleApi } from '@utils/check-error/api-error';
import { useListProgramAndClass, useListYear } from '@utils/custom-hook';
import { gender as listGender } from '@utils/utils';
import authApi from 'api/auth';
import admissionApi from 'api/admission';

export default function BloomDetails(props) {
  const { enquiry, isCompletedOrClosed, handleNextStage, handleRefreshAndNext } = props;
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [info, setInfo] = useState({}); // info for all step
  const [step, setStep] = useState(1);
  const refOrigin = useRef();
  const parentUserIdChosen = useRef({})

  const { enquiryId, campusId } = useMemo(() => {
    if (enquiry) {
      const {
        academicYear,
        campus,
        programGrade,
        programGradeId,
        info: infoAdmission,
        admissionResult,
      } = enquiry;
      let data = {};
      if (admissionResult) {
        const admissionInfo = JSON.parse(admissionResult.info);
        const { application } = admissionInfo;
        const { additionalInfo, applicationParent, info: infoApplication } = application;
        const {
          addressInfo,
          schoolInfo,
          healthInfo,
          languageInfo,
          learningInfo,
          schoolServices,
          otherQuestions,
        } = additionalInfo;
        const newApplicationParent = applicationParent.map((item) => {
          const { fullName, email, phoneNumber, gender, photoURL, parentUserId } = item;
          parentUserIdChosen.current[parentUserId] = parentUserId;
          return {
            ...item,
            infoOrigin: {
              fullName,
              email,
              phoneNumber,
              gender,
              photoURL: photoURL || '',
              parentUserId,
            },
          };
        });
        syncObjValue({
          languageInfo,
          learningInfo,
          schoolServices,
          newApplicationParent,
          infoApplication,
        });
        data = {
          studentInfo: {
            ...infoApplication,
            year: { value: academicYear.id, label: academicYear?.name },
            programGrade: { value: programGradeId },
          },
          parentInfo: newApplicationParent || [{}],
          addressInfo: addressInfo || [{}],
          schoolInfo: schoolInfo || [{}],
          healthInfo: healthInfo || {},
          languageInfo: languageInfo || [{}],
          learningInfo: learningInfo || {},
          schoolServices: schoolServices || {},
          otherQuestions: otherQuestions || {},
        };
      } else {
        const { firstName, lastName, avatar, email, phoneNumber } = infoAdmission;
        const studentInfo = {
          firstName,
          lastName,
          avatar,
          email,
          phoneNumber,
          dateOfBirth: new Date(),
          campus: campus.name,
          year: { value: academicYear.id, label: academicYear?.name },
          programGrade: { value: programGradeId },
        };
        data = {
          studentInfo,
          parentInfo: [{}],
          addressInfo: [{}],
          schoolInfo: [{}],
          healthInfo: {},
          languageInfo: [{}],
          learningInfo: {},
          schoolServices: {},
          otherQuestions: {},
        };
      }
      setInfo(data);
      refOrigin.current = JSON.stringify(data);
      return { enquiryId: enquiry.id, campusId: campus.id };
    }
  }, [enquiry]);

  const addParent = useCallback(async () => {
    try {
      const res = await authApi.getMe()
      if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage)) {
        const { name, photoURL, phoneNumber, gender, email, id } = res.data.data;
        const data = {
          fullName: name,
          gender: listGender.find(item => item.value === gender),
          email,
          phoneNumber,
          photoURL,
          parentUserId: id,
          infoOrigin: res.data.data,
        };
        setInfo(prev => ({ ...prev, parentInfo: [data] }))
        parentUserIdChosen.current[id] = id;
      }
    } catch (error) {
      setToastMessage({
        status: 'error',
        title: 'Add Parent',
        message: error?.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
    }

  }, [])

  useEffect(() => {
    if (!enquiry?.admissionResult) {
      addParent()
    }
  }, [])

  const { data: listProgramGrade } = useListProgramAndClass({ campusId, year: info?.studentInfo?.year?.label });
  const { data: listYear } = useListYear({ campusId });

  const listField = useMemo(() => {
    return getListField({ listProgramGrade, listYear });
  }, [listProgramGrade, listYear]);

  const handleChange = useCallback(({ name, order, objValue, key, value }) => {
    // objValue: object key:value
    handleChangeField({ setInfo, name, order, objValue, key, value })
  }, []);

  const handleAdd = useCallback(({ name }) => {
    handleAddElement({ setInfo, name })
  }, []);

  const handleRemove = useCallback(({ name, order }) => {
    handleRemoveElement({ setInfo, name, order, parentUserIdChosen })
  }, [parentUserIdChosen])

  const handleSave = useCallback(async () => {
    try {
      if (_.isEqual(JSON.parse(refOrigin.current), info) || isCompletedOrClosed) {
        handleNextStage()
        return
      } else {
        const dataApi = await getDataApi({ info, enquiryId, parentUserIdChosen });
        const api = await admissionApi.updateApplicationParent({ campusId }, dataApi);
        if (
          checkErrorSingleApi(
            api,
            setToastMessage,
            setIsShowToastMessage,
            'Submit Application',
          )
        ) {
          handleRefreshAndNext()
        }
      }
    } catch (error) {
      setToastMessage({
        status: 'error',
        title: 'Submit Application',
        message: error?.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
      console.log('Submit Application', error);
    }
  }, [campusId, enquiryId, info, setIsShowToastMessage, setToastMessage, parentUserIdChosen]);

  const handleCancelFirstStep = useCallback(() => {
    const original = JSON.parse(refOrigin.current);
    const { studentInfo, parentInfo, addressInfo } = original;
    setInfo((prev) => ({ ...prev, studentInfo, parentInfo, addressInfo }));
  }, []);

  const handleCancelSecondStep = useCallback(() => {
    const original = JSON.parse(refOrigin.current);
    const { healthInfo, languageInfo, learningInfo, schoolInfo } = original;
    setInfo((prev) => ({
      ...prev,
      healthInfo,
      languageInfo,
      learningInfo,
      schoolInfo,
    }));
  }, []);

  const handleCancelThirdStep = useCallback(() => {
    const original = JSON.parse(refOrigin.current);
    const { schoolServices, otherQuestions } = original;
    setInfo((prev) => ({
      ...prev,
      schoolServices,
      otherQuestions,
    }));
  }, []);

  const handleNext = useCallback(() => {
    setStep((prev) => prev + 1);
    scroll();
  }, []);

  const mapStep = useMemo(
    () => ({
      1: (
        <FirstStep
          info={info}
          setInfo={setInfo}
          setStep={setStep}
          listField={listField}
          parentUserIdChosen={parentUserIdChosen}
          handleChange={handleChange}
          handleAdd={handleAdd}
          handleCancel={handleCancelFirstStep}
          handleNext={handleNext}
          handleRemove={handleRemove}
        />
      ),
      2: (
        <SecondStep
          info={info}
          enquiryId={enquiryId}
          listField={listField}
          setStep={setStep}
          handleChange={handleChange}
          handleAdd={handleAdd}
          handleCancel={handleCancelSecondStep}
          handleNext={handleNext}
          handleRemove={handleRemove}
        />
      ),
      3: (
        <ThirdStep
          info={info}
          listField={listField}
          enquiry={enquiry}
          handleChange={handleChange}
          handleAdd={handleAdd}
          handleCancel={handleCancelThirdStep}
          handleSave={handleSave}
        />
      ),
    }),
    [
      enquiryId,
      handleAdd,
      handleCancelFirstStep,
      handleCancelSecondStep,
      handleCancelThirdStep,
      handleChange,
      handleNext,
      handleSave,
      info,
      listField,
    ],
  );

  return (
    <div className="detail-application-parent">
      {mapStep[step]}
    </div>
  )
}
