import { useEffect, useState } from "react"
import { convertSecondToHourMinuteSecond } from "@helper/admission"
import { getPrefixUserName } from "@helper/application"
import { mainavatar } from "@static/image"
import { hang_up, pause_call } from "../image"

const ActiveCall = (props) => {
  const { info, className } = props
  const [count, setCount] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(prev => prev + 1)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const parentName = `${getPrefixUserName(info?.relationship)}${info?.fullName || ''}`

  const classNameDefault = 'w-full h-[4rem]'

  return (
    <div className={`${className ? className : classNameDefault} rounded-lg bg-[#D9E6E9] text-[#212B36]`}>
      <p className="text-base font-bold">Active Call </p>
      <div className="flex items-center mt-3 justify-between">
        <img src={info?.photoURL || mainavatar} className="w-9 rounded-full aspect-square" />
        <section>
          <div className="text-xs font-medium flex">
            <p className="max-w-[5rem] text-ellipsis-custom">{parentName}</p>
            {info?.relationship && <p>{`(${info?.relationship})`}</p>}
          </div>
          <p className="font-medium text-xs text-[#637381]">{info?.phoneNumber || ''}</p>
        </section>
        <div className="flex items-center bg-[#FFFFFF] text-[#27C59A] text-xs font-medium py-2 px-3 rounded-2xl">
          <small className="bg-[#27C59A] rounded-full w-2 aspect-square mr-2" />
          <p className="w-[2rem]">
            {convertSecondToHourMinuteSecond(count)}
          </p>
        </div>
        <img src={hang_up} alt='hang_up' />
        <img src={pause_call} alt='pause_call' />
      </div>
    </div>
  )
}

export default ActiveCall;