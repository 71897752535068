import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import dynamics from '../../../../static/image/dynamics.svg'
import brokenArrow from '../../../../static/image/brokenArrow.svg'
import './data-giiki.scss'

export default function DataGiiki({ cl, title, percents, count }) {
  return (
    <div className={clsx('data-giiki')}>
      <div className='data-giiki-info'>
        <p className='data-giiki__title'>{title}</p>
        
        <p className={clsx('data-giiki__count', cl)}>{count}</p>
      </div>
      <div className='data-giiki__img-wrapper'>
        {percents == 0 ? '' : 
          <p className='data-giiki__percents'>
            <img src={brokenArrow} alt='' />
            {percents > 0 ? `-${percents}%` : `+${percents}%`}
          </p>
        }
        
      </div>
    </div>
  )
}

DataGiiki.propTypes = {
  title: PropTypes.string,
  percents: PropTypes.string,
  count: PropTypes.string,
  burger: PropTypes.bool
}

//DataWidget