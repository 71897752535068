import { Modal } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { NavLinks } from '../../Header/components/index';
import CreateFeePanel from '../components/create-fee';
import Footer from '@components/footer/footer';
import { callApi } from '@helper/call-api';
import { bifilterleft, searchgray } from '@static/image';
import { ProdCard } from '@stories/index';
import { checkErrorMultipleApiFetch } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { dataLinks } from 'entities/routes';
import FeeGradesCard from '../components/fee-grades-card/fee-grades-card';
import './grades-fee-payment.scss';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';

export default function GradesFeePayment({ burger }) {
  const [visible, setVisible] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [isAddProgram, setIsAddProgram] = useState(false);
  const [listProgrammes, setListProgrammes] = useState([]);
  const [listGrades, setListGrades] = useState([]);
  const [listGradesInDatabase, setListGradesInDatabase] = useState([]);
  const [listGradeProgrammes, setListGradeProgrammes] = useState([]);
  const [dataFee, setDataFee] = useState([]);
  const [feeInfo, setFeeInfo] = useState({});
  const [openModalAddProgram, setOpenModalAddProgram] = useState(false);
  const [index, setIndex] = useState(0);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const inputSearchRef = useRef();
  const currentCampus = useStorage((state) => state.currentCampus);

  const handleSearchGrade = () => {
    const dataSearch = inputSearchRef.current.value.trim();
    if (!dataSearch) {
      listGrades.length !== listGradesInDatabase.length &&
        setListGrades(listGradesInDatabase);
      return;
    }
    const listSearchResults = listGradesInDatabase.filter((grade) => {
      return (
        grade.name.toLowerCase().includes(dataSearch.toLowerCase()) ||
        grade.code.toLowerCase().includes(dataSearch.toLowerCase()) ||
        dataSearch == grade.minAge ||
        dataSearch == grade.maxAge
      );
    });
    setListGrades(listSearchResults);
  };

  useEffect(() => {
    const campusId = currentCampus?.isCentral ? undefined : currentCampus?.id
    // const resProgram = callApi({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/program/list`,
    //   params: { campusId: currentCampus?.isCentral ? undefined : currentCampus?.id },
    // });
    const resProgram = centralAdminApi.getListProgram({ campusId })
    // const resGrade = callApi({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/grade/list`,
    //   params: { campusId: currentCampus?.isCentral ? undefined : currentCampus?.id },
    // });
    const resGrade = centralAdminApi.getListGrade({ campusId })
    // const resGradeProgram = callApi({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/grade/fee/list`,
    //   params: { campusId: currentCampus?.isCentral ? undefined : currentCampus?.id },
    // });
    const resGradeProgram = centralAdminApi.getListGradeProgram({ campusId })
    Promise.all([resProgram, resGrade, resGradeProgram])
      .then((res) => {
        if (checkErrorMultipleApiFetch(res, setToastMessage, setIsShowToastMessage)) {
          setListProgrammes(res[0].data.data.objects);
          setListGrades(res[1].data.data.objects);
          setListGradesInDatabase(res[1].data.data.objects);
          setListGradeProgrammes(res[2].data.data.objects);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Program Fees Error', error);
      });
  }, [isCancel, isAddProgram]);

  const handleClickCancel = () => {
    setIsCancel(!isCancel);
  };

  const handleUpdateFee = (data, idx) => {
    setIndex(idx);
    setFeeInfo(data);
    setVisible(true);
  };
  const handleAddFee = (feeDetail) => {
    const index = dataFee.findIndex((data) => {
      return data.programGradeId === feeDetail.programGradeId;
    });
    if (index === -1) {
      setDataFee((prevState) => {
        return [...prevState, feeDetail];
      });
    } else {
      const data = [...dataFee];
      data[index] = feeDetail;
      setDataFee(data);
    }
  };

  const handelSendForApproval = () => {
    if (dataFee.length === 0) {
      return;
    }

    const data = dataFee.map((item) => {
      return { ...(({ index, dateCreated, dateUpdated, ...o }) => o)(item) };
    });
    const dataApi = {
      name: `Edit or Create Fee Payment`,
      actionId: 7, // action id update
      changeContent: {
        actionName: 'Edit Fee Payment',
        apiURL: process.env.REACT_APP_URL_API_ADMISSION,
        endpoint: '/api/v1/admission/grade/fee',
        apiMethod: 'POST',
        content: {
          data,
        },
        params: { campusId: currentCampus?.isCentral ? undefined : currentCampus?.id },
      },
      keyFields: [],
    };

    callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v1/approval/request`,
      params: { campusId: currentCampus?.isCentral ? undefined : currentCampus?.id },
      data: dataApi,
    })
      .then((res) => {
        if (res.data.success) {
          setToastMessage({
            status: 'success',
            title: 'Send For Approval Successfully',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
          setDataFee([]);
          handleClickCancel();
          // getDataFormApi();
        } else {
          setToastMessage({
            status: 'error',
            title: 'Send For Approval Failed',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Send For Approval Failed',
          message: error.response?.data?.message || error,
        });
        console.log('Program Feees Error', error);
      });
  };

  useEffect(() => {
    const slider = document.querySelector('.parent');
    let mouseDown = false;
    let startX, scrollLeft;

    let startDragging = function (e) {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };
    let stopDragging = function (event) {
      mouseDown = false;
    };

    slider.addEventListener('mousemove', (e) => {
      e.preventDefault();
      if (!mouseDown) {
        return;
      }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    });

    // Add the event listeners
    slider.addEventListener('mousedown', startDragging, false);
    slider.addEventListener('mouseup', stopDragging, false);
    slider.addEventListener('mouseleave', stopDragging, false);
  }, []);

  return (
    // <div className={clsx('fee-payment', burger ? 'ml-80' : 'ml-40')}>
    <div className="fee-payment w-full px-8 mt-4 mb-0 relative">
      {/* {openModalAddProgram && (
        <div
          // onClick={(e) => {
          //   e.preventDefault();
          //   e.stopPropagation();
          //   setOpenModalAddProgram(false);
          // }}
          className="add-program absolute top-0 left-0 right-0 w-[100%] h-[100%] z-20"
        >
          <AddProgramModal
            setOpenModalAddProgram={setOpenModalAddProgram}
            setIsAddProgram={setIsAddProgram}
          />
        </div>
      )} */}
      <Fade clear duration={300}>
        {/* <div className="fixed"> */}
        <div className="" style={{ height: '14rem', paddingTop: '3rem' }}>
          <NavLinks urls={dataLinks.feeLinks} />
          <div className="flex items-center justify-between">
            <h1 className="text-page-name namepage">Grades Fee Payment</h1>
          </div>
        </div>
        <div className="flex max-h-full overflow-y-auto overflow-x-hidden">
          <div className="fee-payment-search">
            <div className="fee-payment-search-input">
              <img
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  inputSearchRef.current.focus();
                }}
                className="fee-payment-search-input_img cursor-pointer"
                src={searchgray}
                alt="search"
              />
              <input
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (e.key === 'Enter') {
                    handleSearchGrade();
                  }
                }}
                onBlur={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (inputSearchRef.current.value.trim() === '') {
                    setListGrades(listGradesInDatabase);
                  }
                }}
                ref={inputSearchRef}
                className="fee-payment-search-input_input"
                placeholder="Search"
              />
              <img
                className="fee-payment-search-input_sort"
                src={bifilterleft}
                alt="filter"
              />
            </div>
            {listGrades.map((item, key) => (
              <React.Fragment key={key}>
                {key === 0 && <hr className="hr-top" />}
                <div className="fee-payment-search-card">
                  <h5>{item.name}</h5>
                  <p>
                    <span>Age Group </span>
                    <strong>
                      {item.minAge}-{item.maxAge} Years
                    </strong>
                  </p>
                </div>
                {key !== listGrades.length - 1 && <hr className="hr-bottom " />}
              </React.Fragment>
            ))}
          </div>
          <div
            className={clsx(
              'parent table-class overflow-x-auto',
              burger ? 'navbar w-[60vw]' : 'w-[75vw]',
            )}
          >
            <table className="">
              <thead>
                <tr
                // style={{ position: '-webkit-sticky', position: 'sticky', top: '20px' }}
                >
                  {listProgrammes.map((item, key) => (
                    <th key={key}>
                      <ProdCard
                        firstHeader="Programmes"
                        firstName={item.name}
                        modal={true}
                        access={false}
                        // opinio={item.opinio}
                        secondHeader="Age Group"
                        secondName={`${item.minAge}-${item.maxAge} Years`}
                      />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {listGrades.map((item, key) => {
                  return (
                    <FeeGradesCard
                      key={key}
                      grade={item}
                      listProgrammes={listProgrammes}
                      listGradeProgrammes={listGradeProgrammes}
                      handleUpdateFee={handleUpdateFee}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Fade>
      <div className="w-full h-20" />
      {/* {dataFee.length !== 0 && ( */}
      {true && (
        <Footer
          handleClickEdit={() => { }}
          handleClickCancel={handleClickCancel}
          handelSendForApproval={handelSendForApproval}
          isSaveDraft={false}
          burger={burger}
          style={{ width: '100%', marginLeft: burger ? '-8rem' : '' }}
        />
      )}
      {visible && <div className="fixed inset-0 bg-slate-200/[.4]" />}
      {visible && (
        <Modal open={visible} onClose={() => setVisible(!visible)}>
          <div>
            <CreateFeePanel
              idx={index}
              visible={visible}
              setVisible={setVisible}
              feeInfo={feeInfo}
              setListGradeProgrammes={setListGradeProgrammes}
              handleAddFee={handleAddFee}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}

GradesFeePayment.propTypes = {
  burger: PropTypes.bool,
};
