import React, { useState, useEffect, useRef } from 'react';
// import './style.scss';

export default function Image(props) {
  const { onChange, value, customStyle, label, ...rest } = props;
  const [imgPreview, setImgPreview] = useState('');
  const refInput = useRef();

  useEffect(() => {
    if (value) {
      if (typeof value === 'object' && value.name) {
        let reader = new FileReader();
        reader.onload = (e) => {
          setImgPreview(e.target.result);
        };
        reader.readAsDataURL(value);
      }
    }
  }, [value]);

  return (
    <div
      {...rest}
      // className="flex justify-center aligns-center"
      style={customStyle}
    >
      <p className="w-full h-4 text-sm text-left">{label}</p>
      <div
        className="w-full bg-[#e5effd] flex items-center justify-center cursor-pointer"
        style={{ height: 'calc(100% - 1rem)' }}
        onClick={() => {
          if (refInput.current) refInput.current.click();
        }}
      >
        {value ? (
          <img
            className="image w-full h-full object-fill"
            src={imgPreview || value}
            alt="uploaded image"
          />
        ) : (
          <p className="image text-main-blue text-xl">+</p>
        )}
        <input
          ref={refInput}
          type="file"
          id="image-upload-2"
          name="image-upload-2"
          className="hidden"
          accept="image/*"
          onChange={onChange}
        />
      </div>
    </div>
  );
}
