import React from 'react';
import clsx from 'clsx';

import './module-section.scss';

export default function ModuleSection(props) {
  const { listModule, handleClickArrow, openPage } = props;
  return listModule.map(({ module, pageModule }, indexModule) => {
    return (
      <React.Fragment key={`module${indexModule}`}>
        <div className="h-6 pl-10 relative flex items-center">
          <Arrow
            open={openPage[module]}
            onClick={() => {
              handleClickArrow(module);
            }}
          />
          <h2
            className="cursor-pointer subtitle1 line-clamp-1"
            style={{
              fontWeight: 'bold',
            }}
            onClick={() => {
              handleClickArrow(module);
            }}
            title={module}
          >
            {module}
          </h2>
        </div>
        {indexModule !== listModule.length - 1 && <hr className="w-[100vw]" />}
        {openPage[module] &&
          pageModule.map((page, index) => (
            <React.Fragment key={`page${indexModule}${index}`}>
              <p className="flex  items-center h-10 pl-10">{page}</p>
              {index !== pageModule.length - 1 && <hr className="w-[100vw]" />}
            </React.Fragment>
          ))}
      </React.Fragment>
    );
  });
}

const Arrow = ({ open, onClick }) => {
  return (
    <svg
      width="12"
      className={clsx(
        'transform transition-all absolute left-3.5 top-1.5 cursor-pointer',
        open ? 'rotate-0' : 'rotate-180',
      )}
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M10.7651 7L5.88262 1L1.00009 7"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
