import clsx from 'clsx';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { callApi } from '@helper/call-api';
import { uploadFileSVG } from '@static/image';
import { Button, Input, ProofCardNew, Upload } from '@stories/index';
import { checkErrorApiFetch } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { EnquiryStatus } from 'entities/data';
import { selectStyleCardApplication } from 'entities/select-style';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { backarrow } from '../image';
import ChildScholarship from './child-scholarship';
import admissionApi from 'api/admission';

const dataLinks = [
  { name: 'Applicant Details', active: 'Done' },
  { name: 'Application Fee', active: 'Done' },
  { name: 'Child Assessment', active: 'Done' },
  { name: 'Principal Approval', active: 'Done' },
  { name: 'Documents', active: 'Active' },
  { name: 'Finance Aproval', active: 'Unactive' },
  { name: 'Complete', active: 'Unactive' },
];

export default function BloomChildDocument(props) {
  // const [type, setType] = useState('scheldued');
  const [enquiry, setEnquiry] = useState({});
  const [latestEnquiry, setLatestEnquiry] = useState({});
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const location = useLocation();
  const [listDocuments, setListDocuments] = useState([]);
  const [applicationId, setApplicationId] = useState();
  const [step, setStep] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const history = useHistory();

  const styles = {
    circle: buildStyles({
      textSize: '1.5rem',
      pathTransitionDuration: 5,
      pathColor: `#008060`,
      textColor: 'black',
      trailColor: 'rgba(226,226,226,0.2)',
      backgroundColor: '#3e98c7',
    }),
  };
  useEffect(() => {
    if (location.state?.applicationId) {
      setApplicationId(location.state.applicationId);
    }
    if (location.state?.enquiry) {
      setEnquiry(location.state.enquiry);
    }
  }, [location.state]);

  const percent = useMemo(() => {
    if (listDocuments.length === 0) return 0;
    const list = listDocuments.filter((document) => {
      return document.status === 'Approved';
    });
    const per = (list.length / listDocuments.length) * 100;
    return Math.round(per * 100) / 100;
  }, [listDocuments]);

  useEffect(() => {
    if (enquiry?.currentStep === EnquiryStatus.COMPLETE) {
      setLatestEnquiry(enquiry);
      const admissionResult = JSON.parse(enquiry.admissionResult.info);
      setListDocuments(admissionResult.documentVerification?.documents || []);
      return;
    }
    enquiry.id &&
      admissionApi.getEnquiryParent(enquiry.id)
        .then((res) => {
          if (
            checkErrorApiFetch(
              res,
              setToastMessage,
              setIsShowToastMessage,
              'Get Application Documents',
            )
          ) {
            const admissionResult = JSON.parse(res.data.data.admissionResult.info);
            setListDocuments(admissionResult.documentVerification?.documents || []);
            setLatestEnquiry(res.data.data);
          }
        })
        .catch((error) => {
          console.log('Get Application Documents Error', error);
          setToastMessage({
            status: 'error',
            title: 'Get Application Documents Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
        });
  }, [enquiry, refresh]);

  const appStyle = document.getElementsByClassName('app');

  useEffect(() => {
    if (appStyle && appStyle[0]) appStyle[0].style.overflowX = 'hidden';
  }, []);
  return (
    <div>
      {/* <BloomHeader /> */}
      {/* <BloomCrumbs data={dataLinks} /> */}
      <div className="w-screen flex items-start mt-12 pl-20">
        <button
          onClick={() => {
            history.goBack();
          }}
          className="h-8 flex items-center none-after font-semibold cursor-pointer mr-10"
        >
          <img src={backarrow} className="w-4 h-4" alt="" />
        </button>
        {!step && (
          <div className={clsx('w-screen flex flex-col pr-20 mt-1', 'items-center')}>
            <div className="flex items-center justify-between w-[75%]">
              <div className="mb-10">
                <h3 className="font-medium text-2xl mb-3">Documents</h3>
                <p className="text-xs" style={{ width: '17.375rem' }}>
                  Please upload mandotary Documents The documents will be verified in 2
                  Working days
                </p>
              </div>
              <div className="w-20 h-20">
                <CircularProgressbarWithChildren value={percent} styles={styles.circle}>
                  <div
                    className="flex flex-col items-center text-xs"
                    style={{ marginTop: '-0.313rem' }}
                  >
                    <strong>{percent}%</strong>
                    <p>Complete</p>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
            {listDocuments.map((document, idx) => {
              return (
                <div key={document.id} className="w-[80%]">
                  <ProofCardNew
                    isParent={true}
                    title={document.info.title}
                    num={idx}
                    nameCard={document.name}
                    approved={document.status}
                    image={document.fileURL}
                    data={JSON.parse(document.info)}
                    documentId={document.id}
                    setRefresh={setRefresh}
                  // approved="approved"
                  />
                </div>
              );
            })}
            <UploadDocument applicationId={applicationId} setRefresh={setRefresh} />
            <div className="flex justify-end mb-6 w-[80%]">
              <Button
                text="Next"
                // buttonDisabled={!isEdit}
                customStyle={{
                  justifyContent: 'right',
                  paddingLeft: '3.438rem',
                  paddingRight: '3.438rem',
                  marginLeft: '1.25rem',
                }}
                onClick={() => {
                  setStep(true);
                }}
              />
            </div>
          </div>
        )}
        {step && (
          <ChildScholarship
            applicationId={applicationId}
            enquiry={latestEnquiry}
            percent={percent}
            setStep={setStep}
          />
        )}
      </div>
    </div>
  );
}

const UploadDocument = ({ applicationId, setRefresh = () => { } }) => {
  const styles = {
    upload: {
      background: `#F1F8F5`,
      color: '#4F4F4F',
    },
    select: selectStyleCardApplication,
  };

  const [imgPreview, setImgPreview] = useState(false);
  const [isDocument, setIsDocument] = useState(false);
  const [documentName, setDocumentName] = useState('');
  const [array, setArray] = useState([]);
  const [listInfo, setListInfo] = useState([]);
  const [formData, setFormData] = useState(new FormData());
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const history = useHistory();

  const changeImageHandler = (e) => {
    let selected = e.target.files;
    if (selected && selected[0]) {
      let file = selected[0];
      setIsDocument(true);
      formData.set('file', file);
      formData.set('mediaId', file.name);
      setFormData(formData);
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImgPreview(reader.result);
      };
      e.target.value = null;
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };

  const handleUploadDocument = async () => {
    if (!isDocument) {
      setToastMessage({
        status: 'info',
        title: 'Validate',
        message: 'Please Upload Document',
      });
      setIsShowToastMessage(true);
      return;
    }
    if (documentName.trim() === '') {
      setToastMessage({
        status: 'info',
        title: 'Validate',
        message: 'Please Enter Document Name',
      });
      setIsShowToastMessage(true);
      return;
    }
    let logo = '';
    try {
      const resultUploadImage = await admissionApi.uploadMedia(formData);
      if (resultUploadImage.data.success) {
        logo = resultUploadImage.data.data;
      } else {
        setToastMessage({
          status: 'error',
          title: 'Upload Image Failed',
          message: resultUploadImage.data.message,
        });
        setIsShowToastMessage(true);
      }
    } catch (error) {
      setToastMessage({
        status: 'error',
        title: 'Upload Image Failed',
        message: error.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
      console.log('Edit User Error', error);
    }

    const data = {
      applicationId: applicationId,
      documents: [
        {
          name: documentName,
          fileURL: logo,
          info: listInfo,
        },
      ],
    };

    admissionApi.uploadApplicationDocumentParent(data)
      .then((response) => {
        if (response.data.success) {
          setRefresh((prev) => !prev);
          setFormData(new FormData());
          setListInfo([]);
          setArray([]);
          setIsDocument(false);
          setDocumentName('');
          setImgPreview(false);
          setToastMessage({
            status: 'success',
            title: 'Upload Document Successfully',
            message: response.data.message,
          });
          setIsShowToastMessage(true);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Upload Document Failed',
            message: response.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        console.log('Upload Document Error', error);
        setToastMessage({
          status: 'error',
          title: 'Upload Document Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      });
  };

  return (
    <div className="mb-5 w-[80%]">
      <div
        className="border rounded-lg border-main-gray"
        style={{ width: '100%', height: '13%' }}
      >
        <div className="flex border-b border-main-gray justify-between items-center h-16">
          <input
            value={documentName}
            className="border-none outline-none ml-4"
            type="text"
            placeholder="Document Name"
            onChange={(e) => {
              setDocumentName(e.target.value);
            }}
          />
          <div
            className="rounded-lg flex items-center justify-center mr-12 text-10px"
            style={Object.assign(styles.upload)}
          >
            Upload
          </div>
        </div>
        <div className="flex">
          <div className="min-w-[50%] max-w-[50%]">
            <div className="flex justify-center mt-5">
              <img
                style={{ width: '13.563rem', height: '10rem' }}
                src={imgPreview ? imgPreview : uploadFileSVG}
                alt="uploaded"
              />
            </div>
            <div className={clsx('flex items-center mt-3.5 mb-2 justify-center')}>
              <Upload
                customStyle={{ borderRadius: '0.25rem' }}
                text="Upload Image"
                id="image-upload"
                accept="image/*"
                imageChange={setImgPreview}
                onChange={(e) => {
                  changeImageHandler(e);
                }}
              />
              <Button
                text={'Save'}
                customStyle={{
                  paddingLeft: '3.438rem',
                  paddingRight: '3.438rem',
                  marginLeft: '1.25rem',
                }}
                onClick={() => {
                  handleUploadDocument();
                }}
              />
            </div>
          </div>
          <div className="min-w-[48%] max-w-[50%] ml-4">
            {array.map((value, idx) => {
              return (
                <div key={value} className="flex mt-4">
                  <Input
                    value={listInfo[idx].title}
                    label="Title"
                    customStyle={{ width: '100%' }}
                    onChange={(e) => {
                      const value = e.target.value;
                      setListInfo((prev) => {
                        const data = [...prev];
                        data[idx].title = value;
                        return data;
                      });
                    }}
                  />
                  <Input
                    style={{ marginLeft: '0.5rem' }}
                    value={listInfo[idx].value}
                    label="Value"
                    onChange={(e) => {
                      const value = e.target.value;
                      setListInfo((prev) => {
                        const data = [...prev];
                        data[idx].value = value;
                        return data;
                      });
                    }}
                  />
                </div>
              );
            })}
            <div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setArray((prevArr) => {
                  return [...prevArr, prevArr.length];
                });
                setListInfo((prevListInfo) => {
                  return [...prevListInfo, { title: '', value: '' }];
                });
              }}
              className="my-4 mr-4 opacity-70 cursor-pointer text-right"
            >
              + Add Infomation
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
