import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const initialStorage = {
  auth: null,
  routes: null,
  campuses: null,
  years: null,
  modules: null,
  company: null,
  currentCampus: null,
  currentYear: null,
  previousYear: null,
  accessToken: null,
  refreshToken: null,
  deviceToken: null,
  confirmCodeSms: null,
  navbarTitle: null,
  isGiikiAdmin: false,
  period: null,
  navApp: null,
  rememberMe: false,
};
const useStorage = create(
  persist(
    (set, get) => ({
      ...initialStorage,
      getAuth: () => get().auth,
      getRoutes: () => get().routes,
      getCampuses: () => get().campuses,
      getYears: () => get().years,
      getModules: () => get().modules,
      getCompany: () => get().central,
      getCurrentCampus: () => get().currentCampus,
      getCurrentYear: () => get().currentYear,
      getPreviousYear: () => get().previousYear,
      getAccessToken: () => get().accessToken,
      getRefreshToken: () => get().refreshToken,
      getDeviceToken: () => get().deviceToken,
      getConfirmCodeSms: () => get().confirmCodeSms,
      getNavbarTitle: () => get().navbarTitle,
      getIsGiikiAdmin: () => get().isGiikiAdmin,
      getNavApp: () => get().navApp,
      getPeriod: () => get().period,
      getRememberMe: () => get().rememberMe,
      updateAuth: (auth) => set({ auth }),
      updateRoutes: (routes) => set({ routes }),
      updateCampuses: (campuses) => set({ campuses }),
      updateYears: (years) => set({ years }),
      updateModules: (modules) => set({ modules }),
      updateCompany: (company) => set({ company }),
      updateCurrentCampus: (currentCampus) => set({ currentCampus }),
      updateCurrentYear: (currentYear) => set({ currentYear }),
      updatePreviousYear: (previousYear) => set({ previousYear }),
      updateAccessToken: (accessToken) => set({ accessToken }),
      updateRefreshToken: (refreshToken) => set({ refreshToken }),
      updateDeviceToken: (deviceToken) => set({ deviceToken }),
      updateConfirmCodeSms: (confirmCodeSms) => set({ confirmCodeSms }),
      updateNavbarTitle: (navbarTitle) => set({ navbarTitle }),
      updateIsGiikiAdmin: (isGiikiAdmin) => set({ isGiikiAdmin }),
      updateNavApp: (navApp) => set({ navApp }),
      updatePeriod: (period) => set({ period }),
      updateRememberMe: (rememberMe) => set({ rememberMe }),
      removeAuth: () => set({ auth: {} }),
      resetStorage: () => set({ ...initialStorage }),
    }),
    {
      name: 'persist:giiki1', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    },
  ),
);
export default useStorage;
