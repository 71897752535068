import { memo, useEffect, useState } from 'react';

const UploadPhoto = memo((props) => {
  const { id, info, handleChange, showLabel = true } = props;
  const [imgPreview, setImgPreview] = useState(false);
  // const [formData, setFormData] = useState(new FormData());

  useEffect(() => {
    if (typeof info === 'object' && info?.name) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setImgPreview(e.target.result);
      };
      reader.readAsDataURL(info);
    } else setImgPreview(false);
  }, [info]);

  const changeImageHandler = (e) => {
    let selected = e.target.files;
    if (selected && selected[0]) {
      let file = selected[0];
      handleChange(file);
      // formData.set('file', file);
      // formData.set('mediaId', file.name);
      // setFormData(formData);

      let reader = new FileReader();
      reader.onload = (e) => {
        setImgPreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="mb-4">
      {showLabel && (
        <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
          Photo
        </p>
      )}
      <div className="w-16">
        <label htmlFor={id} className="cursor-pointer">
          {imgPreview || info ? (
            <img
              className="w-16 h-16 rounded-full border border-main-blue border-dashed"
              src={imgPreview || info || ''}
              alt="uploaded"
            />
          ) : (
            <div className="flex items-center justify-center w-16 h-16 border border-main-blue border-dashed rounded-full text-main-blue text-xl">
              <p className="bg-main-blue rounded-full text-white w-4 flex items-center justify-center h-4 text-sm">
                +
              </p>
            </div>
          )}
        </label>
        <input
          type="file"
          id={id}
          name="image-upload"
          className="hidden"
          accept="image/*"
          onChange={changeImageHandler}
        />
      </div>
    </div>
  );
});
UploadPhoto.displayName = 'UploadPhoto';
export default UploadPhoto;
