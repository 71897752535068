import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import Zoom from 'react-reveal/Zoom';

import {
  selectStylesWithNoTitle,
  toggleButtonStyles,
} from '../../../../entities/select-style';
import { Button, Input, SelectGroup, ToggleButton } from '../../../../stories';

import { ToastMessageContext } from '../../../../context/toast-context';
import { currencies, INDEX_CURRENCY_INDIA } from '../../../../utils/utils';
import './create-fee.scss';

export default function CreateFeePanel(props) {
  const { visible, setVisible, feeInfo, setListGradeProgrammes, handleAddFee, idx } =
    props;
  const [feeDetail, setFeeDetail] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState();
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const resetHandle = () => {
    const data = {
      ...feeInfo.programGradeFee,
      index: feeInfo.index,
      programGradeId: feeInfo.programGradeId,
    };
    setFeeDetail(data);
  };

  useEffect(() => {
    resetHandle();
  }, [feeInfo]);

  const onClickSaveHandle = () => {
    if (!feeDetail?.applicationFee || !feeDetail?.feePayment) {
      setToastMessage({
        status: 'warning',
        title: 'Validate Rule',
        message: 'Please enter data in the input box',
      });
      setIsShowToastMessage(true);
      return;
    }

    if (!feeDetail?.currency) {
      feeDetail['currency'] = currencies[INDEX_CURRENCY_INDIA].value;
    }

    setListGradeProgrammes((prevState) => {
      const dataUpdate = [...prevState];
      const { index, ...res } = feeDetail;
      dataUpdate[feeInfo.index].programGrade[idx].programGradeFee = { ...res };
      return dataUpdate;
    });
    const feeInfoUpdate = {
      ...feeDetail,
      extraInfo: {
        programName: feeInfo?.programName,
        gradeName: feeInfo?.gradeName,
      },
    };
    handleAddFee(feeInfoUpdate);
    setVisible(false);
  };

  return (
    <Zoom bottom duration={700} when={visible}>
      <div
        className={clsx(
          'fee-payment-panel',
          visible ? 'pointer-events-auto' : 'pointer-events-none hidden',
        )}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center flex-auto w-32">
            <div>
              <h2 className="heading-grade-name text-2xl">
                {feeInfo?.gradeName || 'Grade Name'}
              </h2>
              <p className="heading-program-name text-sm">
                {feeInfo?.programName || 'Program Name'}
              </p>
            </div>
          </div>
          <button className="mr-5 pb-5" onClick={() => setVisible(false)}>
            <Close />
          </button>
        </div>
        <hr className="mt-2" />
        <div className="h-[calc(100%-7rem)] w-full">
          <div className="flex-row overflow-y-auto overflow-x-hidden h-full w-full">
            <div>
              <h3 className="body-heading">1. Application Fee</h3>
              <Input
                onChange={(e) => {
                  setFeeDetail((prevState) => {
                    return {
                      ...prevState,
                      applicationFee: +e.target.value,
                    };
                  });
                }}
                type="number"
                label="Application Fee"
                value={feeDetail?.applicationFee || ''}
                customStyle={{
                  width: '30rem',
                  height: '2.5rem',
                  fontSize: '0.875rem',
                  borderRadius: '0.25rem',
                  marginTop: '1rem',
                  paddingLeft: '1.25rem',
                }}
              />
            </div>

            <div>
              <h3 className="body-heading">2. Fee Payment</h3>
              <Input
                onChange={(e) => {
                  setFeeDetail((prevState) => {
                    return {
                      ...prevState,
                      feePayment: +e.target.value,
                    };
                  });
                }}
                type="number"
                label="Fee Payment"
                value={feeDetail?.feePayment}
                customStyle={{
                  width: '30rem',
                  height: '2.5rem',
                  fontSize: '0.875rem',
                  borderRadius: '0.25rem',
                  marginTop: '1rem',
                  paddingLeft: '1.25rem',
                }}
              />
            </div>

            <div>
              <h3 className="body-heading mb-3">3. Currency</h3>
              <div className="w-[30rem]">
                <SelectGroup
                  title="Curency"
                  value={selectedCurrency ?? currencies[INDEX_CURRENCY_INDIA]}
                  styles={selectStylesWithNoTitle}
                  options={currencies}
                  isMulti={false}
                  isSearchable={true}
                  onChange={(e) => {
                    setSelectedCurrency(e);
                    setFeeDetail((prevState) => {
                      return {
                        ...prevState,
                        currency: e.value,
                      };
                    });
                  }}
                />
              </div>
              {/* <Input
                onChange={(e) => {
                  setFeeDetail((prevState) => {
                    return {
                      ...prevState,
                      currency: e.target.value,
                    };
                  });
                }}
                type="number"
                label="Currency"
                value={feeDetail?.currency || ''}
                customStyle={{
                  width: '26.1875rem',
                  height: '2.5rem',
                  fontSize: '0.875rem',
                  borderRadius: '0.5rem',
                  marginTop: '1rem',
                  paddingLeft: '1.25rem',
                }}
              /> */}
            </div>
          </div>
          <div className="flex justify-center absolute bottom-0 left-7 right-7">
            <Button
              text="Cancel"
              customStyle={toggleButtonStyles.btnWhite}
              onClick={() => {
                setVisible(false);
              }}
            />
            <Button
              text="Save"
              customStyle={toggleButtonStyles.btnBlue}
              onClick={onClickSaveHandle}
            />
          </div>
        </div>
      </div>
    </Zoom>
  );
}

const Close = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 1.4L14.6 0L8 6.6L1.4 0L0 1.4L6.6 8L0 14.6L1.4 16L8 9.4L14.6 16L16 14.6L9.4 8L16 1.4Z"
        fill="#2F80ED"
      />
    </svg>
  );
};

// const Footer = () => {
//   return (
//     <div className="flex justify-center absolute bottom-0 left-7 right-7">
//       <Button
//         text="Cancel"
//         customStyle={toggleButtonStyles.btnWhite}
//         onClick={() => {}}
//       />
//       <Button text="Save" customStyle={toggleButtonStyles.btnBlue} onClick={() => {}} />
//     </div>
//   );
// };

const GradeSetting = ({ checkToggle, setCheckToggle, title, content }) => {
  return (
    <div className="flex justify-end border rounded-md w-[30rem] h-16 items-center mt-4">
      <div className="flex-1 ml-4">
        <p className="text-sm text-[#00160A] font-semibold">{title}</p>
        <p className="text-xs text-[#637381] font-normal">{content}</p>
      </div>
      <ToggleButton
        name={title}
        value={checkToggle}
        customStyle={{ marginRight: '2rem' }}
        onChange={setCheckToggle}
        inputSize="middle"
        bg="#A2BCB0"
      />
    </div>
  );
};
