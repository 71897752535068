import { useEffect, useState, useContext } from 'react';
import { CampusInfoField } from './campus-info-field.jsx';
import { ToastMessageContext } from 'context/toast-context';
import { checkErrorApiFetch } from '@utils/check-error/api-error';
import { mainavatar } from '@static/image/index.jsx';
import authApi from 'api/auth.js';

export default function General({ campus }) {
  const [data, setData] = useState([]);
  const [principals, setPrincipals] = useState([]);
  const [admissionHeads, setAdmissionHeads] = useState([]);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  useEffect(() => {
    if (!campus?.id) return;
    // authApi
    //   .getUserOfCompanyOrCampus({
    //     campusId: campus.id,
    //     filterRoleNames: 'Principal;Campus Admin',
    //   })
    //   .then((res) => {
    //     if (
    //       checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, 'Get Users')
    //     ) {
    //       const result = res.data.data.objects;
    //       setData(result);
    //     }
    //   })
    //   .catch((error) => {
    //     setToastMessage({
    //       status: 'error',
    //       title: 'Get Users Failed',
    //       message: error.response?.data?.message || error,
    //     });
    //     setIsShowToastMessage(true);
    //     console.log('Campus Information General Error', error);
    //   });
    authApi
      .getKeyRoles({
        campusId: campus.id,
      })
      .then((result) => {
        if (
          checkErrorApiFetch(
            result,
            setToastMessage,
            setIsShowToastMessage,
            'Get Key Roles',
          )
        ) {
          setPrincipals(
            result.data.data.principals.map((p) => ({ ...p, role: 'Principal' })),
          );
          setAdmissionHeads(
            result.data.data.admissionsHeads.map((p) => ({
              ...p,
              role: 'Admission Head',
            })),
          );
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Get Key Roles Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Get Key Roles Error', error);
      });
  }, [campus]);

  let address;
  if (campus?.address) {
    address = campus.address[0].split(', ');
  }

  return (
    <div>
      <CampusInfoField title="Campus Name" body={campus?.name ? campus.name : ''} />
      <CampusInfoField
        title="Address Line 1"
        body={campus?.address ? campus.address[0] : ''}
      />
      <CampusInfoField
        title="Address Line 2"
        body={campus?.address ? campus.address[1] : ''}
      />
      <div className="flex items-start">
        <CampusInfoField title="City" body={campus?.city ? campus.city : ''} />
        <CampusInfoField title="State" body={campus?.state ? campus.state : ''} />
        <CampusInfoField title="Country" body={campus?.country ? campus.country : ''} />
      </div>
      <div className="flex items-start">
        <CampusInfoField
          title="Contact Number"
          body={campus?.phoneNumber ? campus.phoneNumber[0] : ''}
        />
        <CampusInfoField
          title="Secondary Contact"
          body={campus?.phoneNumber ? campus.phoneNumber[1] : ''}
        />
        <CampusInfoField title="Email" body={campus?.email ? campus.email : ''} />
      </div>
      <CampusInfoField
        title="Campus Logo"
        body="image"
        image={campus?.logo ? campus.logo : mainavatar}
      />
      <div className="w-full mt-12 mb-12" style={{ height: 1, background: '#E1E3E5' }} />
      <p className="text-sm font-semibold mb-8" style={{ color: '#6D7175' }}>
        Key Members
      </p>
      {[...principals, ...admissionHeads].map((item, key) => (
        <div
          key={key}
          className="flex items-center justify-between mb-5  text-sm font-medium"
          style={{ width: '50rem' }}
        >
          <div className="flex items-center" style={{ width: '300px' }}>
            <img
              src={item.photoURL}
              className="rounded-full w-8 h-8 mr-5"
              alt="personal"
            />
            {item.name}
          </div>
          <div className="flex flex-col">
            <p key={key} className="text-main-black" style={{ width: '150px' }}>
              {item.role}
            </p>
            {/* {item.userRoles.map((element, key) => (
              <p key={key} className="text-main-black" style={{ width: '150px' }}>
                {element.roles.name}
              </p>
            ))} */}
          </div>

          {/* <p className="text-main-black" style={{ width: '150px' }}>
            {item.userRoles.some((element) => element.roles.name === 'Principal')
              ? 'Principal'
              : 'Campus Admin'}
          </p> */}
          <p style={{ width: '250px', color: '#6D7175' }}>{item.email}</p>
          {/* <p
            style={{
              width: '100px',
              color: item.status === 'Active' ? '#3A8B93' : '#A21B00',
            }}
          >
            {item.status}
          </p> */}
        </div>
      ))}
    </div>
  );
}
