import React, { memo } from 'react';
import ContentGroup from '../content-group/content-group';

const HealthInfo = memo((props) => {
  const { info, variation, listField, handleChange } = props;
  const [field1, field2, ...rest] = listField;
  return (
    <>
      <div
        className="grid mb-4"
        style={{ gridTemplateColumns: '1fr 2fr', columnGap: '2rem' }}
      >
        {[field1, field2].map((item, index) => (
          <ContentGroup
            key={`health${index}`}
            data={item}
            info={info?.[item.name]}
            variation={variation}
            handleChange={(e) => {
              handleChange({ name: 'healthInfo', key: item.name, value: e });
            }}
          />
        ))}
      </div>
      {rest.map((item, index) => (
        <div className="mb-8" key={`health${index}`}>
          <ContentGroup
            data={item}
            info={info?.[item.name]}
            variation={variation}
            handleChange={(e) => {
              handleChange({ name: 'healthInfo', key: item.name, value: e });
            }}
          />
        </div>
      ))}
    </>
  );
});
HealthInfo.displayName = 'HealthInfo';
export default HealthInfo;
