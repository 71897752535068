import { cashier_payment } from "@static/image";

function CashierPaymentSection() {
    return ( 
        <div className="flex flex-col items-center pt-20 ml-5 w-full">
            <img className="mt-20" src={cashier_payment} alt="cashier payment" />
            <h3 className="text-2xl leading-5 text-black font-semibold mb-3">Select the Student</h3>
            <p className="text-[#637381] text-sm font-semibold">Help us find the student to pull up all the due payment</p>
        </div>
     );
}

export default CashierPaymentSection;