import React, { useState } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';

import { CreateInstitute, StepOne, Payment, Domain, Buddy } from '../index.jsx';

export default function GettingStartModal({ modal, setModal, burger }) {
  const { id } = useParams();
  const [info, setInfo] = useState({}); // info create company
  const renderComponents = [
    {
      linkId: 'create',
      componentRender: (key) => (
        <CreateInstitute burger={burger} setModal={setModal} key={key} info={info} setInfo={setInfo} />
      ),
    },
    {
      linkId: 'step-one',
      componentRender: (key) => <StepOne burger={burger} setModal={setModal} key={key} info={info} setInfo={setInfo} />,
    },
    {
      linkId: 'payment',
      componentRender: (key) => <Payment burger={burger} setModal={setModal} key={key} info={info} setInfo={setInfo} />,
    },
    {
      linkId: 'domain',
      componentRender: (key) => <Domain burger={burger} setModal={setModal} key={key} info={info} setInfo={setInfo} />,
    },
    {
      linkId: 'buddy',
      componentRender: (key) => <Buddy burger={burger} setModal={setModal} key={key} info={info} setInfo={setInfo} />,
    },
  ];

  return (
    <div
      className={clsx(
        'institutions-modal',
        modal ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none',
      )}
    >
      {renderComponents.map((item, key) => {
        return id === item.linkId && item.componentRender(key);
      })}
    </div>
  );
}
