import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// import { DesktopDatePicker } from '@mui/x-date-pickers';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './style.scss';

const DatePicker = (props) => {
  const { pValue, psetValue, color, label, disabled, customStyle, inputCustomStyle, format } = props;
  const [value, setValue] = useState(new Date());
  const { ...rest } = customStyle || {};
  const height = customStyle?.height || '';

  const theme = createTheme({
    palette: {
      primary: { main: color || '#27C59A' },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          className="w-full"
          disabled={disabled}
          displayStaticWrapperAs="desktop"
          openTo="day"
          label={label}
          minDate={new Date(new Date().getFullYear() - 50, 1, 1)}
          maxDate={new Date(new Date().getFullYear() + 10, 12, 31)}
          value={pValue ? pValue : value}
          views={['year', 'month', 'day']}
          inputFormat={format || 'dd/MM/yyyy'}
          shouldDisableYear={null}
          onChange={(newValue) => {
            psetValue ? psetValue(newValue) : setValue(newValue);
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                sx={{
                  // '.MuiFormControl-root': { width: 1 },
                  '& .MuiOutlinedInput-input': {
                    height: height.toString(),
                    padding: '0.625rem',
                    ...inputCustomStyle,
                  },
                }}
              />
            );
          }}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default DatePicker;

DatePicker.propTypes = {
  // pValue: PropTypes.date,
  psetValue: PropTypes.func,
  color: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};
