import React from "react"
import { Input } from "@stories/index"
import CurrencyInput from "react-currency-input-field"
import './input-flow-type.scss'

const InputFlowType = (props) => {
  const { value, editFee, type, objValueType, onChange } = props

  return (
    !type || type?.includes(objValueType.Percentage) ? (
      <Input label=''
        disabled={!editFee}
        value={Number(value) <= 100 ? value : 100}
        classGroup='w-[100%]'
        customStyle={{ height: '2.5rem', borderRadius: '0.25rem' }}
        type="text"
        onChange={(e) => {
          const value = e.target.value
          if (value === '') return onChange(value)
          if (!(/\d|\./).test(value) || (Number(value) > 100)) return e.preventDefault()
          onChange(value)
        }} />
    ) : (
      <CurrencyInput
        className='w-full h-10 rounded border text-xl p-2.5 input-currency'
        allowNegativeValue={false}
        disabled={!editFee}
        defaultValue={value}
        decimalsLimit={2}
        onValueChange={(value) => {
          onChange(value)
        }}
      />
    )
  )
}

export default InputFlowType;