function checkErrorSingleApi(
  res,
  setToastMessage,
  setIsShowToastMessage,
  title = 'Submit data',
  showToastIfSuccess = true,
) {
  const success = res?.data?.success;
  const message = res?.data?.message;

  if (!success) {
    setToastMessage({
      status: 'error',
      title: `${title} Failed`,
      message: message,
    });
    setIsShowToastMessage(true);
    return success;
  }

  if (showToastIfSuccess) {
    setToastMessage({
      status: 'success',
      title: `${title} Successfully`,
      message: message,
    });
    setIsShowToastMessage(true);
  }
  return success;
}

function checkErrorApiFetch(
  res,
  setToastMessage,
  setIsShowToastMessage,
  title = 'Fetch Data',
  showToastIfSuccess = true,
) {
  if (!res?.data?.success) {
    setToastMessage({
      status: 'error',
      title: `${title} Failed`,
      message: res?.data?.message,
    });
    setIsShowToastMessage(true);
    return false;
  }

  // if (showToastIfSuccess) {
  //   setToastMessage({
  //     status: 'success',
  //     title: `${title} Successfully`,
  //     message: res.data.message,
  //   });
  //   setIsShowToastMessage(true);
  // }

  return true;
}

function checkErrorMultipleApiFetch(res, setToastMessage, setIsShowToastMessage) {
  const failed = res.find(({ data }) => !data.success);
  if (failed) {
    setToastMessage({
      status: 'error',
      title: 'Fetch Data Failed',
      message: typeof failed.data.message === 'string' ? failed.data.message : '',
    });
    setIsShowToastMessage(true);
    return false;
  }
  return true;
}

function checkErrorMultipleApi(
  res,
  setToastMessage,
  setIsShowToastMessage,
  title = 'Submit data',
  showToastIfSuccess = true,
) {
  const failed = res.find(({ data }) => !data.success);
  if (failed) {
    setToastMessage({
      status: 'error',
      title: `${title} Failed`,
      // title: 'Submit Data Failed'
      message: failed.data.message,
    });
    setIsShowToastMessage(true);
    return false;
  }

  if (showToastIfSuccess) {
    setToastMessage({
      status: 'success',
      title: `${title} Success`,
      // title: 'Submit Data Success',
      message: '',
    });
    setIsShowToastMessage(true);
  }

  return true;
}

export {
  checkErrorSingleApi,
  checkErrorMultipleApiFetch,
  checkErrorApiFetch,
  checkErrorMultipleApi,
};
