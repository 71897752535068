import React, { useState, useMemo, useRef, useContext, useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import { Button } from '../';
import { uploadFileSVG } from '../../static/image';
import { checkErrorSingleApi } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { callApi } from '@helper/call-api';
import { Input } from '@stories/index';
import { TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DOCUMENT_STATUS } from '@helper/admission';
import { EnquiryStatus } from 'entities/data';
import admissionApi from 'api/admission';

const ProofCard = (props) => {
  const { isParent, num, setRefresh, applicationId, documentSetting, data } = props;
  const {
    fileRequirements,
    documentCategoryName,
    optionDocuments,
    status,
    atLeastDocument,
  } = useMemo(() => {
    const {
      fileRequirement,
      name: documentCategoryName,
      atLeastDocument,
    } = documentSetting;
    let fileRequirements = [];
    let optionDocuments = [];
    if (fileRequirement) {
      const tmp = JSON.parse(fileRequirement);
      fileRequirements = tmp.filter((item) => item);
      optionDocuments = fileRequirements.map((item) => ({
        label: item.fileName,
        value: item.fileName,
      }));
    }
    return { fileRequirements, optionDocuments, documentCategoryName, atLeastDocument };
  }, []);
  // useEffect(() => {
  //   if (documentSelected) {
  //     const tmp = fileRequirements.find((item) => documentSelected === item.fileName)
  //     setFields(tmp.mandatoryFields)
  //   }
  // }, [documentSelected, fileRequirements])

  const styles = {
    box: {
      paddingLeft: '0.625rem',
      paddingRight: '0.625rem',
      paddingTop: '0.125rem',
      paddingBottom: '0.125rem',
    },
    [DOCUMENT_STATUS.approved]: {
      background: `rgba(84, 214, 44, 0.16)`,
      color: '#229A16',
    },
    [DOCUMENT_STATUS.waitingApproval]: {
      background: `#FFF2E2`,
      color: '#F2C94C',
    },
    [DOCUMENT_STATUS.rejected]: {
      background: `#FFF2E2`,
      color: '#EB5757',
    },
    [DOCUMENT_STATUS.upload]: {
      background: `#F1F8F5`,
      color: '#4F4F4F',
    },
    select: {
      control: (styles) => ({
        ...styles,
        width: '14rem',
        border: 'none',
        boxShadow: 'none',
        cursor: 'pointer',
        fontSize: '1.375rem',
        marginLeft: '0.875rem',
      }),
      indicatorSeparator: () => null,
      input: (styles) => ({ ...styles, width: '6.25rem', color: 'black' }),
      indicatorsContainer: () => null,
      option: (styles, state) => ({
        ...styles,
        // height: '2.188rem',
        padding: '0.438rem',
        margin: 0,
        // padding: 0,
        backgroundColor: state.isSelected ? '#404eed' : 'white',
        cursor: 'pointer',
        '&:hover': { backgroundColor: '#e2e2e2', color: 'black' },
      }),
      menu: (styles) => ({ ...styles, marginLeft: '1.188rem' }),
    },
  };

  const countDocument = Math.max(atLeastDocument, data.length);

  return (
    <div className="mb-5">
      {<p className="text-xs mb-2">{`${num + 1}. ${documentCategoryName}`}</p>}
      {Array.from(Array(countDocument).keys()).map((index) => {
        return (
          <AreaDocument
            key={`areaDocument${num + 1}${index}`}
            isParent={isParent}
            applicationId={applicationId}
            data={data[index]}
            styles={styles}
            setRefresh={setRefresh}
            optionDocuments={optionDocuments}
            fileRequirements={fileRequirements}
            documentCategoryName={documentCategoryName}
          />
        );
      })}
    </div>
  );
};

export default ProofCard;

ProofCard.propTypes = {
  title: PropTypes.string,
  num: PropTypes.number,
  nameCard: PropTypes.string,
  approved: PropTypes.string,
};

const AreaDocument = (props) => {
  const {
    data,
    isParent,
    styles,
    setRefresh,
    optionDocuments,
    applicationId,
    fileRequirements,
    documentCategoryName,
  } = props;
  const refUpload = useRef();
  const [documentSelected, setDocumentSelected] = useState();
  const refInitialInfo = useRef();
  const [info, setInfo] = useState(data);
  const [status, setStatus] = useState(data?.status || DOCUMENT_STATUS.new);
  const [fileName, setFileName] = useState(null);
  const [editing, setEditing] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const documentId = data?.id;

  useEffect(() => {
    setInfo(data);
    setStatus(data?.status || DOCUMENT_STATUS.upload);
  }, [data]);

  const onChangeDocument = (e) => {
    const selected = fileRequirements.find((item) => item.fileName === e.value);
    if (selected) {
      setFileName(e);
      setDocumentSelected(selected);
      const initialInfo = {
        name: documentCategoryName,
        info: {
          fileName: e.value,
          mandatoryFields: JSON.parse(JSON.stringify(selected.mandatoryFields)),
        },
      };
      refInitialInfo.current = JSON.stringify(initialInfo);
      setInfo(initialInfo);
    }
  };

  const onChangeFile = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      const preview = URL.createObjectURL(file);
      setInfo((prev) => ({ ...prev, file, preview }));
    }
  };

  const handleChangeField = ({ index, value }) => {
    setInfo((prev) => ({
      ...prev,
      info: {
        ...prev.info,
        mandatoryFields: [
          ...prev.info.mandatoryFields.map((item, i) => {
            if (i === index) {
              item.value = value;
            }
            return { ...item };
          }),
        ],
      },
    }));
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const handleDeleteInfo = () => {
    if (info?.preview) URL.revokeObjectURL(info.preview);
    setInfo(JSON.parse(refInitialInfo.current));
    refUpload.current.value = '';
  };

  const checkErrorRequired = ({ fileSelected, info }) => {
    const { mandatoryFields } = fileSelected;
    if (!info?.fileURL && !info?.file?.name)
      return { valid: false, error: 'Please import file' };
    for (let i in mandatoryFields) {
      const { fieldName, operator, value } = info.info.mandatoryFields[i];
      if (operator === 'date' && !value) {
        info.info.mandatoryFields[i].value = new Date();
        continue;
      }
      if (!value) return { valid: false, error: `Please fill in ${fieldName}` };
    }
    return { valid: true, error: '' };
  };

  const handleSendApproval = async () => {
    try {
      const { valid, error } = checkErrorRequired({
        fileSelected: documentSelected,
        info,
      });
      if (!valid) {
        setToastMessage({
          status: 'warning',
          title: 'Document Validate',
          message: error,
        });
        setIsShowToastMessage(true);
        return;
      }
      const formData = new FormData();
      formData.set('file', info.file);
      formData.set('mediaId', info.file.name);
      const apiImage = await admissionApi.uploadMedia(formData);
      if (
        checkErrorSingleApi(
          apiImage,
          setToastMessage,
          setIsShowToastMessage,
          'Upload File',
          false,
        )
      ) {
        URL.revokeObjectURL(info.preview);
      }
      const apiData = {
        applicationId,
        name: documentCategoryName,
        fileURL: apiImage.data.data,
        info: info.info,
      };
      let res;
      if (isParent) {
        res = await admissionApi.uploadApplicationDocumentParent(apiData);
      } else {
        res = await admissionApi.updateApplicationDocument(apiData);
      }
      if (
        checkErrorSingleApi(
          res,
          setToastMessage,
          setIsShowToastMessage,
          'Upload Document',
        )
      ) {
        const infoParse = JSON.parse(res.data.data.info);
        setStatus(res.data.data.status);
        setInfo((prev) => ({ ...prev, ...res.data.data, info: infoParse }));
        setEditing(false);
        setRefresh((prev) => !prev);
      }
    } catch (error) {
      setToastMessage({
        status: 'error',
        title: 'Validate',
        message: error.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
    }
  };

  const handleSendApprovalUpdate = async (status) => {
    try {
      const fileSelected = fileRequirements.find(
        (item) => item.fileName === info?.info?.fileName,
      );
      const { valid, error } = checkErrorRequired({ fileSelected, info });
      if (!valid) {
        setToastMessage({
          status: 'warning',
          title: 'Document Validate',
          message: error,
        });
        setIsShowToastMessage(true);
        return;
      }
      let fileURL = info?.fileURL;
      if (info?.file?.name) {
        const formData = new FormData();
        formData.set('file', info.file);
        formData.set('mediaId', info.file.name);
        const apiImage = await admissionApi.uploadMedia(formData);
        if (
          checkErrorSingleApi(
            apiImage,
            setToastMessage,
            setIsShowToastMessage,
            'Upload File',
            false,
          )
        ) {
          URL.revokeObjectURL(info.preview);
          fileURL = data.data;
        }
      }
      const apiData = {
        applicationId,
        name: documentCategoryName,
        fileURL,
        info: info.info,
        status: status || info.status,
      };
      let url = `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/application/document/${info.id}`;
      if (isParent)
        url = `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/application/document/parent/${info.id}`;
      const res = await callApi({
        method: 'patch',
        url,
        data: apiData,
      });
      if (checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, 'Update')) {
        const infoParse = JSON.parse(res.data.data.info);
        setStatus(res.data.data.status);
        setInfo((prev) => ({ ...prev, ...res.data.data, info: infoParse }));
        setEditing(false);
      }
    } catch (error) {
      setToastMessage({
        status: 'error',
        title: 'Validate',
        message: error.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
    }
  };

  const handleDeleteDocument = async () => {
    let api = admissionApi.deleteApplicationDocumentById(documentId);
    if (isParent) api = admissionApi.deleteApplicationDocumentParentById(documentId);
    const res = await api;
    if (
      checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, 'Delete Document')
    ) {
      setRefresh((prev) => !prev);
      setDocumentSelected(null);
      setFileName(null);
    }
  };

  const mapField = ({ disabled, label, value, handleChange, key }) => {
    const textKey = `text-${key}`;
    const dateKey = `date-${key}`;
    return {
      text: (
        <div key={textKey} className="mb-4 h-fit text-xs font-normal">
          <p style={{ color: '#000000' }}>{label}</p>
          <Input
            // disabled={!editing}
            disabled={disabled}
            customStyle={{
              padding: 0,
              border: 'none',
              color: '#8C9094',
              fontSize: '12px',
              fontWeight: 400,
            }}
            classGroup="h-[1.5rem]"
            type="text"
            label={''}
            placeholder="Please Enter"
            value={value || ''}
            onChange={(e) => {
              const val = e.target.value;
              handleChange(val);
            }}
          />
        </div>
      ),
      date: (
        <div key={dateKey} className="mb-4 h-fit text-xs font-normal">
          <p style={{ color: '#000000' }}>{label}</p>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              disabled={disabled}
              className="w-full"
              displayStaticWrapperAs="desktop"
              openTo="day"
              label={''}
              minDate={new Date(new Date().getFullYear() - 50, 1, 1)}
              maxDate={new Date(new Date().getFullYear() + 10, 12, 31)}
              value={value}
              views={['year', 'month', 'day']}
              shouldDisableYear={null}
              onChange={(e) => {
                handleChange(e);
              }}
              format="D Month, Yr"
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{
                      '& .MuiInput-input': {
                        fontSize: '12px',
                        fontWeight: 400,
                        color: '#8C9094',
                      },
                      '& .MuiSvgIcon-root': {
                        width: '0.9rem',
                        height: '0.9rem',
                      },
                      '& .MuiOutlinedInput-input': {
                        height: '1rem',
                        padding: '0.625rem',
                      },
                      '& .MuiInput-root': {
                        '&:before, :after, :hover:not(.Mui-disabled):before': {
                          borderBottom: 0,
                        },
                      },
                    }}
                  />
                );
              }}
            />
          </LocalizationProvider>
        </div>
      ),
    };
  };

  const mapButtonStatus = {
    [DOCUMENT_STATUS.new]: (
      <>
        <Button
          text="Upload Image"
          buttonStyle="outlined"
          customStyle={{
            paddingLeft: '2.5rem',
            paddingRight: '2.5rem',
            marginRight: '1.5rem',
          }}
          onClick={() => {}}
        />
        <Button
          text="Save"
          customStyle={{
            paddingLeft: '2.5rem',
            paddingRight: '2.5rem',
            marginRight: '1.5rem',
          }}
          onClick={() => {}}
        />
      </>
    ),
    [DOCUMENT_STATUS.upload]: (
      <>
        <Button
          text="Delete"
          buttonStyle="outlined"
          customStyle={{
            paddingLeft: '2.5rem',
            paddingRight: '2.5rem',
            marginRight: '1.5rem',
          }}
          onClick={handleDeleteInfo}
        />
        <Button
          text="Save"
          customStyle={{
            paddingLeft: '2.5rem',
            paddingRight: '2.5rem',
            marginRight: '1.5rem',
          }}
          onClick={handleSendApproval}
        />
      </>
    ),
    [DOCUMENT_STATUS.waitingApproval]: (
      <>
        <Button
          text="Delete"
          buttonStyle="outlined"
          customStyle={{
            paddingLeft: '2.5rem',
            paddingRight: '2.5rem',
            marginRight: '1.5rem',
          }}
          onClick={handleDeleteDocument}
        />
        {editing ? (
          <Button
            text="Update"
            customStyle={{
              paddingLeft: '2.5rem',
              paddingRight: '2.5rem',
              marginRight: '1.5rem',
            }}
            onClick={() => handleSendApprovalUpdate()}
          />
        ) : (
          <Button
            text="Edit"
            customStyle={{
              paddingLeft: '2.5rem',
              paddingRight: '2.5rem',
              marginRight: '1.5rem',
            }}
            onClick={handleEdit}
          />
        )}
      </>
    ),
    [DOCUMENT_STATUS.approved]: (
      <>
        <Button
          text="Delete"
          buttonDisabled={true}
          buttonStyle="outlined"
          customStyle={{
            paddingLeft: '2.5rem',
            paddingRight: '2.5rem',
            marginRight: '1.5rem',
          }}
          onClick={() => {}}
        />
        <Button
          text="Edit"
          buttonDisabled={true}
          customStyle={{
            paddingLeft: '2.5rem',
            paddingRight: '2.5rem',
            marginRight: '1.5rem',
          }}
          onClick={() => {}}
        />
      </>
    ),
    [DOCUMENT_STATUS.rejected]: (
      <>
        <Button
          text="Delete"
          // buttonDisabled={true}
          buttonStyle="outlined"
          customStyle={{
            paddingLeft: '2.5rem',
            paddingRight: '2.5rem',
            marginRight: '1.5rem',
          }}
          onClick={handleDeleteDocument}
        />
        {editing ? (
          <Button
            text="Update"
            customStyle={{
              paddingLeft: '2.5rem',
              paddingRight: '2.5rem',
              marginRight: '1.5rem',
            }}
            onClick={() => handleSendApprovalUpdate(DOCUMENT_STATUS.waitingApproval)}
          />
        ) : (
          <Button
            text="Edit"
            customStyle={{
              paddingLeft: '2.5rem',
              paddingRight: '2.5rem',
              marginRight: '1.5rem',
            }}
            onClick={handleEdit}
          />
        )}
      </>
    ),
  };

  return (
    <div
      className="border rounded-lg border-main-gray mb-6"
      style={{ width: '100%', height: '17%' }}
    >
      <div className="flex border-b border-main-gray justify-between items-center h-16">
        {info?.applicationId ? (
          <h2 className="text-2xl ml-5 font-medium">{info.info.fileName}</h2>
        ) : (
          <Select
            defaultValue={null}
            styles={styles.select}
            options={optionDocuments}
            value={fileName}
            onChange={(e) => {
              setEditing(true);
              onChangeDocument(e);
            }}
          />
        )}

        <ApproveDocument
          isParent={isParent}
          approved={status}
          documentId={documentId}
          setRefresh={setRefresh}
        >
          <div
            className={clsx('rounded-lg flex items-center justify-center text-10px px-2')}
            style={styles[status]}
          >
            {status}
          </div>
        </ApproveDocument>
      </div>

      <div className="flex items-center min-h-[13rem] max-h-[20rem]">
        <div
          style={{
            width:
              documentSelected?.mandatoryFields || info?.info?.mandatoryFields
                ? '60%'
                : '100%',
            padding: '1rem 1rem 1rem 1.5rem',
            height: '100%',
          }}
        >
          {info?.preview || info?.fileURL ? (
            <div className="w-full flex overflow-x-hidden relative">
              <img
                className={clsx(
                  'py-0 px-4',
                  editing ? 'cursor-pointer' : 'cursor-default',
                )}
                src={info?.preview || info?.fileURL}
                alt=""
                onClick={() => {
                  if (editing) refUpload.current.click();
                }}
              />
            </div>
          ) : (
            <img
              className="w-full h-[10.063rem] cursor-pointer"
              src={uploadFileSVG}
              alt=""
              onClick={() => {
                if (editing) refUpload.current.click();
              }}
            />
          )}
          <input
            className="hidden"
            type="file"
            accept="image/*"
            ref={refUpload}
            multiple={true}
            onChange={onChangeFile}
          />
        </div>

        {info?.info?.mandatoryFields && (
          <>
            <div className="w-[5%]" />
            <div className="w-[30%]">
              <div className="pt-4">
                {info.info.mandatoryFields.map((item, index) => {
                  const { fieldName, operator, value } = item;
                  return mapField({
                    disabled: !editing || status === EnquiryStatus.NEW,
                    label: fieldName,
                    value,
                    handleChange: (e) => handleChangeField({ index, value: e }),
                    key: index,
                  })[operator];
                })}
              </div>
            </div>
          </>
        )}
      </div>

      <div
        className={clsx(
          'flex mb-3',
          status === DOCUMENT_STATUS.new ? 'justify-center' : 'justify-end',
        )}
      >
        {info && mapButtonStatus[status]}
      </div>
    </div>
  );
};

const ApproveDocument = ({
  isParent,
  children,
  approved,
  documentId,
  setRefresh = () => {},
}) => {
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const handleUpdateStatus = async (status) => {
    try {
      const response = await admissionApi.updateApplicationDocumentById(
        { status },
        documentId,
      );
      if (
        checkErrorSingleApi(
          response,
          setToastMessage,
          setIsShowToastMessage,
          'Update Status',
        )
      ) {
        setRefresh((prev) => !prev);
      }
    } catch (error) {
      console.log('Update Document Error', error);
      setToastMessage({
        status: 'error',
        title: 'Update Document Failed',
        message: error.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
    }
  };
  const showTippy = approved === 'Send For Approval' && !isParent;
  const renderResult = () => (
    <div className="" tabIndex="-1">
      {showTippy ? (
        <div className="w-[7rem] h-[6rem] border rounded-lg bg-[#fff] pl-5">
          <div
            onClick={() => {
              handleUpdateStatus('Approved');
            }}
            className="my-2 py-1 cursor-pointer text-[#229A16]"
          >
            Approve
          </div>
          <div
            onClick={() => {
              handleUpdateStatus('Rejected');
            }}
            className="py-1 cursor-pointer text-[#EB5757]"
          >
            Reject
          </div>
        </div>
      ) : null}
    </div>
  );

  return (
    <Tippy
      content={renderResult()}
      arrow={false}
      delay={100}
      placement="bottom-end"
      interactive={true}
    >
      <p
        className="rounded-lg flex items-center justify-center mr-6 text-10px px-2"
        style={{ cursor: showTippy ? 'pointer' : 'default' }}
      >
        {children}
      </p>
    </Tippy>
  );
};
