import React, { useEffect, useRef, useState } from 'react';

// components
import ModuleSection from './ModuleSection';
import PermissionSection from './PermissionSection';
import { bifilterleft, searchgray } from '@static/image';

export default function RolesPermissions(props) {
  const {
    openPopupRoleDetail,
    burger,
    isApproval,
    listModule,
    listPermission = [],
    listRole,
    openPage,
    openPermission,
    handleOnChange,
    handleClickArrow,
  } = props;

  const [listModulesRender, setListModulesRender] = useState([]);
  const inputSearchRef = useRef();
  const handleSearchModule = () => {
    const dataSearch = inputSearchRef.current.value.trim();
    if (!dataSearch) {
      listModule.length !== listModulesRender.length && setListModulesRender(listModule);
      return;
    }
    const results = [];
    listModule.forEach((module) => {
      if (module.module.toLowerCase().includes(dataSearch.toLowerCase())) {
        results.push(module);
      } else {
        const pageModule = module.pageModule.filter((page) => {
          return page.toLowerCase().includes(dataSearch.toLowerCase());
        });
        if (pageModule.length > 0) {
          results.push({
            module: module.module,
            pageModule: pageModule,
          });
        }
      }
    });
    setListModulesRender(results);
  };

  useEffect(() => {
    setListModulesRender(listModule);
  }, [listModule]);

  useEffect(() => {
    const slider = document.querySelector('.parent');
    let mouseDown = false;
    let startX, scrollLeft;

    let startDragging = function (e) {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };
    let stopDragging = function (event) {
      mouseDown = false;
    };

    slider.addEventListener('mousemove', (e) => {
      e.preventDefault();
      if (!mouseDown) {
        return;
      }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    });

    // Add the event listeners
    slider.addEventListener('mousedown', startDragging, false);
    slider.addEventListener('mouseup', stopDragging, false);
    slider.addEventListener('mouseleave', stopDragging, false);
  }, []);

  return (
    <div>
      <div className="flex">
        <div className="grades-programs-search">
          <div className="h-24">
            <div className="grades-programs-search-input  ">
              <img
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  inputSearchRef.current.focus();
                }}
                className="grades-programs-search-input_img"
                src={searchgray}
                alt="search"
              />
              <input
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (e.key === 'Enter') {
                    handleSearchModule();
                  }
                }}
                onBlur={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (inputSearchRef.current.value.trim() === '') {
                    setListModulesRender(listModule);
                  }
                }}
                ref={inputSearchRef}
                className="grades-programs-search-input_input"
                // style={{ width: burger ? "11.25rem" : "" }}
                placeholder="Search"
              />
              <img
                className="grades-programs-search-input_sort"
                src={bifilterleft}
                alt="filter"
                style={{ zIndex: 0 }}
              />
            </div>
          </div>
          <ModuleSection
            listModule={listModulesRender}
            handleClickArrow={handleClickArrow}
            openPage={openPage}
          />
        </div>
        <div className="parent grades-programs-row flex overflow-x-auto" style={{ width: 'calc(100%)' }}>
          <PermissionSection
            openPopupRoleDetail={openPopupRoleDetail}
            isApproval={isApproval}
            listRole={listRole}
            listPermission={listPermission}
            listModule={listModulesRender}
            openPermission={openPermission}
            handleOnChange={handleOnChange}
          />
        </div>
      </div>
    </div>
  );
}
