import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { Input, SelectGroup } from '../../../../../stories';
import CheckboxFormBuilder from '../../../../../stories/checkboxformbuider';
import useDebounce from '../../../../../utils/custom-hook/useDebounce';

const style = {
  label: 'text-sm w-[30%]',
  input: {
    style: {
      height: '2rem',
      backgroundColor: 'white',
      width: '100%',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
  },
  select: {
    style: {
      width: '65%',
      height: '2rem',
    },
  },
};

const TraitSection = (props) => {
  const { data, order, eleFocus, setListForm, setRefresh, refreshProperty } = props;
  const [infoExtract, setInfoExtract] = useState({});

  const extractInfo = useCallback((data) => {
    const result = {};
    if (data) {
      data.forEach(({ modifiable, name, value, defaultValue }) => {
        if (modifiable) result[name] = value || defaultValue || '';
      });
    }

    return result;
  }, []);

  useEffect(() => {
    setInfoExtract(extractInfo(data));
  }, [data, extractInfo, refreshProperty]);

  const debounce = useDebounce(({ eleFocus, index, value }) => {
    setListForm((prev) => {
      prev[eleFocus.order][eleFocus.index].traits[index].value = value;
      return prev;
    });
    setRefresh((prev) => ({
      ...prev,
      [order]: prev[order] + 1,
    }));
  });

  const findById = useCallback((data, id) => {
    for (const item of data) {
      const { id: itemId, children } = item;
      if (itemId === id) return item;
      if (children) {
        if (children.length !== 0) return findById(children, id);
      }
    }
  }, []);

  const handleChange = useCallback(
    ({ eleFocus, index, value, name }) => {
      setInfoExtract((prev) => ({
        ...prev,
        [name]: value,
      }));
      debounce({ eleFocus, index, value });
    },
    [debounce],
  );

  const mapType = {
    select: ({ info, index, disabled }) => (
      <Select
        key={`select${index}`}
        eleFocus={eleFocus}
        order={order}
        index={index}
        info={info}
        disabled={disabled}
        handleChange={handleChange}
        value={infoExtract[info.name]}
      />
    ),
    text: ({ info, index, disabled }) => (
      <Text
        key={`text${index}`}
        index={index}
        eleFocus={eleFocus}
        order={order}
        info={info}
        disabled={disabled}
        handleChange={handleChange}
        value={infoExtract[info.name]}
      />
    ),
    checkbox: ({ info, index, disabled }) => (
      <CheckboxPopup
        key={`checkbox${index}`}
        index={index}
        eleFocus={eleFocus}
        order={order}
        info={info}
        disabled={disabled}
        handleChange={handleChange}
        value={infoExtract[info.name]}
      />
    ),
    number: ({ info, index, disabled }) => (
      <Number
        key={`number${index}`}
        index={index}
        eleFocus={eleFocus}
        order={order}
        info={info}
        disabled={disabled}
        handleChange={handleChange}
        value={infoExtract[info.name]}
      />
    ),
  };

  if (!eleFocus && eleFocus !== 0)
    return (
      <div className="px-4">
        <p>Please click on any of the components</p>
      </div>
    );

  if (Object.keys(infoExtract).length === 0)
    return (
      <div className="px-4">
        <p>no trait</p>
      </div>
    );

  return (
    <div>
      {data.map((item, index) => {
        const { type, modifiable, disabled } = item;
        if (modifiable) return mapType[type]({ info: item, index, disabled });
        return null;
      })}
    </div>
  );
};
export default TraitSection;

function CheckboxPopup(props) {
  const { info, eleFocus, handleChange, value, index } = props;
  const { name, label, disabled } = info;

  return (
    <div className="flex justify-between items-center w-full mt-4">
      <p className="text-sm w-[70%]">{label}</p>
      <CheckboxFormBuilder
        value={value || false}
        disabled={disabled}
        onChange={(e) => {
          const value = e.target.checked;
          handleChange({ eleFocus, index, value, name });
        }}
      />
    </div>
  );
}

function Text(props) {
  const { info, eleFocus, handleChange, index, value } = props;
  const { name, label, placeholder, disabled } = info;

  return (
    <div className="flex justify-between items-center w-full mt-4">
      <p className={style.label}>{label}</p>
      <Input
        label=""
        placeholder={placeholder}
        customStyle={style.input.style}
        value={value}
        disabled={disabled}
        onChange={(e) => {
          const value = e.target.value;
          handleChange({ eleFocus, index, value, name });
        }}
      />
    </div>
  );
}

function Number(props) {
  const { info, eleFocus, handleChange, value, index } = props;
  const { name, label, placeholder, disabled } = info;

  return (
    <div className="flex justify-between items-center w-full mt-4">
      <p className={style.label}>{label}</p>
      <Input
        label=""
        disabled={disabled}
        placeholder={placeholder}
        customStyle={style.input.style}
        type="number"
        value={value}
        onChange={(e) => {
          const value = e.target.value;
          handleChange({ eleFocus, index, value, name });
        }}
      />
    </div>
  );
}

function Select(props) {
  const { info, eleFocus, handleChange, value, index } = props;
  const { name, label, options, disabled } = info;

  let newOptions = options;
  let newValue = value;
  if (typeof options === 'string') {
    newOptions = options.split(';').map((item) => ({ label: item, value: item }));
  }
  if (typeof value === 'string') {
    newValue = { label: value, value };
  }

  return (
    <div className="flex justify-between items-center w-full mt-4">
      <p className={style.label}>{label}</p>
      <SelectGroup
        customStyle={style.select.style}
        options={newOptions}
        isMulti={false}
        value={newValue}
        isDisabled={disabled}
        onChange={(e) => {
          const value = e;
          handleChange({ eleFocus, index, value, name });
        }}
      />
    </div>
  );
}
