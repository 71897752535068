import React, { memo } from 'react';
import { deleteIcon, editIcon } from './icon';

const FormPageBreak = memo((props) => {
  const {
    id,
    index,
    isEdit,
    isFocus,
    isBuild,
    isRequired,
    deletable,
    component,
    label,
    value,
    error,
    textForError,
    formStyle,
    handleFocus,
    handleDelete,
    handleDragStart,
    handleDragOver,
    ...rest
  } = props;

  const { height, borderWidth } = formStyle;
  // return <div className="flex basis-full" style={{height}}/>;

  return (
    <div
      className="component flex basis-full"
      draggable
      onDragStart={(e) => {
        handleDragStart(e, index);
      }}
      onDragOver={handleDragOver}
      style={{
        border: '0.5px solid',
        outline: isFocus ? '1px black solid' : '',
        outlineOffset: '0rem',
        borderWidth,
      }}
    >
      <div className="setting items-center absolute bg-white px-[0.15rem] right-2 top-[-0.5rem] z-10">
        {isBuild && (
          <img
            className="ml-2 cursor-pointer"
            title="delete"
            src={deleteIcon}
            onClick={() => {
              handleDelete(index);
            }}
          />
        )}
        {isEdit && deletable && (
          <img
            className="ml-2 cursor-pointer"
            title="delete"
            src={deleteIcon}
            onClick={() => {
              handleDelete(index);
            }}
          />
        )}
      </div>
      <div
        // className="flex basis-full"
        style={{ height }}
      />
    </div>
  );
});
FormPageBreak.displayName = 'FormNewRow';
export default FormPageBreak;
