import { cancel } from '@static/image';
import { Button, Input } from '@stories/index';
import { ToastMessageContext } from 'context/toast-context';
import { useContext, useRef, useState, useMemo } from 'react';
import './add-program.scss';
import useOnClickOutside from '@hooks/useClickOutside';
import InputSlider from '@components/input-slider';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';

function AddProgramModal(props) {
  const { setModalAddProgram, modalAddProgram, setIsAddProgram } = props;
  let isState = Object.keys(modalAddProgram.state).length !== 0
  const [programName, setProgramName] = useState('');
  const [state, setState] = useState({
    min: 0,
    max: 30,
  });
  const defaultState = useMemo(() => isState ? Object.values(modalAddProgram.state) : Object.values(state), []);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const currentCampus = useStorage((state) => state.currentCampus);
  const styles = {
    closeBtn: {
      backgroundColor: '#fff',
      color: '#202223',
      border: '1px solid #E1E3E5',
      marginRight: '0.5rem',
    },
    createBtn: { backgroundColor: "#404eed", color: "white" },
  };


  const getInputSliderValue = (value) => {
    setState(prev => ({ ...prev, min: value[0], max: value[1] }))
  }

  const handleCreateProgram = () => {
    if (programName.trim().length === 0) {
      setToastMessage({
        status: 'warning',
        title: 'Validated Field',
        message: 'Program Name Can Not Be Empty',
      });
      setIsShowToastMessage(true);
      return;
    }
    // callApi({
    //   method: 'post',
    //   url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2/admission/program`,
    //   params: { campusId: currentCampus?.isCentral ? undefined : currentCampus?.id },
    //   data: { name: programName, minAge: state.min, maxAge: state.max },
    // })
    centralAdminApi.createProgram({ campusId: currentCampus?.isCentral ? undefined : currentCampus?.id },
      { name: programName, minAge: state.min, maxAge: state.max })
      .then((res) => {
        if (res.data.success) {
          setToastMessage({
            status: 'success',
            title: 'Add Program Successfully',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
          setModalAddProgram({ isOpen: false, state: {}, firstName: "", id: "" });
          setIsAddProgram((prev) => !prev);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Add Program Failed',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Add Program Failed',
          message: error.response?.data?.message || error,
        });
        console.log('Add Program Error', error);
      });
  };

  const handleEditProgram = () => {
    if (modalAddProgram?.firstName.trim().length === 0) {
      setToastMessage({
        status: 'warning',
        title: 'Validated Field',
        message: 'Program Name Can Not Be Empty',
      });
      setIsShowToastMessage(true);
      return;
    }
    if (!modalAddProgram?.id?.trim()?.length) {
      setToastMessage({
        status: 'warning',
        title: 'Missing Program Id',
      });
      setIsShowToastMessage(true);
      return;
    }
    // callApi({
    //   method: 'patch',
    //   url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2/admission/program/${modalAddProgram?.id}`,
    //   params: { campusId: currentCampus?.isCentral ? undefined : currentCampus?.id },
    //   data: { name: modalAddProgram.firstName, minAge: state.min, maxAge: state.max },
    // })
    centralAdminApi.updateProgram({ campusId: currentCampus?.isCentral ? undefined : currentCampus?.id },
      { name: modalAddProgram.firstName, minAge: state.min, maxAge: state.max })
      .then((res) => {
        if (res.data.success) {
          setToastMessage({
            status: 'success',
            title: 'Add Program Successfully',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
          setModalAddProgram({ isOpen: false, state: {}, firstName: "", id: "" });
          setIsAddProgram((prev) => !prev);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Add Program Failed',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Add Program Failed',
          message: error.response?.data?.message || error,
        });
        console.log('Add Program Error', error);
      });
  };

  const clickOutSide = useRef()
  useOnClickOutside(clickOutSide, () => {
    setModalAddProgram({ isOpen: false, state: {}, firstName: "", id: "" })
  })

  return (
    <div ref={clickOutSide} className="bg-[#fff] absolute top-1/2 left-1/2 rounded-lg"
      style={{ transform: 'translateX(-50%) translateY(-50%)' }}>
      <div
        className={` ${!isState ? "w-[50rem]" : "w-[30rem]"} bg-white rounded-lg shadow-feebook-modal py-1 px-5`}
        onClick={() => { }}
      >
        <div className="flex items-center justify-between my-4">
          <h2 className="text-2xl ml-6 mt-2"> {`${!isState ? "Create New Program" : "Edit Program"}`}</h2>
          <button
            className="mr-4"
            onClick={() => {
              setModalAddProgram({ isOpen: false, state: {}, firstName: "", id: "" });
            }}
          >
            <img src={cancel} alt="closed modal" />
          </button>
        </div>
        <hr></hr>
        <div className="flex items-center justify-around my-6">
          {!isState && <div className="create-board-card-row_label"></div>}
          <div className="ml-2 mr-6 flex flex-col h-[100%]">
            <div className="my-auto">
              <Input
                label="Program Name"
                name="add-program"
                type="text"
                value={`${!isState ? programName : modalAddProgram.firstName}`}
                onChange={(e) => {
                  if (!isState) {
                    setProgramName(e.target.value);
                  } else {
                    modalAddProgram.firstName = e.target.value
                    setProgramName(modalAddProgram.firstName);
                  }
                }}
              />
            </div>
            <div className="mt-4 ml-4">
              <p>Eligible Age</p>
              <div className="h-[2rem] w-[22rem] py-12">
                <InputSlider min={0} max={30} step={0.5} defaultValue={defaultState} getValue={getInputSliderValue} size={"medium"} />
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="flex justify-end py-4 pr-4">
          <Button
            text="Cancel"
            customStyle={styles.closeBtn}
            onClick={() => {
              setModalAddProgram({ isOpen: false, state: {}, firstName: "", id: "" });
            }}
          />
          <Button
            text={`${!isState ? "Create" : "Edit"}`}
            customStyle={styles.createBtn}
            onClick={() => {
              if (!isState) {
                handleCreateProgram();
              } else {
                handleEditProgram();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default AddProgramModal;
