import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { iccheck } from '../image';

const ButtonDatePicker = (props) => {
  const { color, setTime, time, listTime } = props
  const [active, setActive] = useState();

  useEffect(() => {
    let timeSelected = '';
    if (!time) {
      timeSelected = listTime[0].label;
    } else {
      listTime.forEach((showTime) => {
        if (showTime.value[0] === time?.[0]) {
          timeSelected = showTime.label;
        }
      });
    }
    setActive(timeSelected);
  }, []);

  return (
    <div className="inline-block">
      {listTime.map((showTime) => (
        <button
          key={showTime.label}
          onClick={() => {
            setActive(showTime.label);
            setTime(showTime.value);
          }}
          className={clsx(
            'time-select-button w-[8rem]',
            showTime.label === active && 'time-select-button_active',
          )}
          style={color ? { color: color, borderColor: color } : {}}
        >
          {showTime.label === active && (
            <img
              src={iccheck}
              style={{ position: 'absolute', left: '0.45rem', top: '0.2rem' }}
              alt="iccheck"
            />
          )}
          <p style={{ paddingLeft: showTime.label === active ? '1rem' : '' }}>
            {showTime.label}
          </p>
        </button>
      ))}
    </div>
  );
};

export default ButtonDatePicker;
