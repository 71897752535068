import { memo, useState } from 'react';
import useDebounce from '../../../../utils/custom-hook/useDebounce';
import useStorage from 'store/storage';
import SingleCampus from '../SingleCampus/SingleCampus';

const PopupSwitchCampus = memo((props) => {
  const { open, onClose } = props;
  const [search, setSearch] = useState('');
  const { getCampuses, getCurrentCampus, updateCurrentCampus } = useStorage()
  const campuses = getCampuses()
  const currentCampus = getCurrentCampus()
  const newCampuses = campuses?.filter(item => item.id !== currentCampus?.id)
  const [listCampus, setListCampus] = useState(newCampuses);

  const debounce = useDebounce((search) => {
    if (search) {
      const newListCampus = newCampuses.filter(
        ({ name, city, state }) => {
          const nameLower = name.toLowerCase();
          const cityLower = city.toLowerCase();
          const stateLower = state.toLowerCase();
          const searchLower = search.toLowerCase();
          return (
            nameLower.includes(searchLower) ||
            cityLower.includes(searchLower) ||
            stateLower.includes(searchLower)
          );
        },
      );
      setListCampus(newListCampus);
    } else setListCampus(newCampuses)
  }, 300);

  const handleSearch = (e) => {
    const val = e.target.value;
    setSearch(val);
    debounce(val);
  };

  const onSwitchCampus = (campus) => {
    updateCurrentCampus(campus)
    const newCampuses = campuses.filter(item => item.id !== campus.id)
    setListCampus(newCampuses)
    onClose()
  };

  return (
    <div
      id="popup-campus"
      className="absolute z-10 w-[90%] bottom-[5.25rem] overflow-y-auto bg-[#fff] rounded shadow"
      style={{
        transition: 'all 0.3s ease-in-out',
        maxHeight: open ? '40%' : '0px',
        padding: open ? '0.5rem' : '0px',
      }}
    >
      <div className="w-full h-[2.5rem] flex items-center border border-solid border-white rounded pr-[1%]">
        <svg
          className="w-[15%]"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m12.425 11.25 2.833 2.825a.833.833 0 0 1 0 1.183.833.833 0 0 1-1.183 0l-2.825-2.833a6.6 6.6 0 0 1-4.083 1.408 6.667 6.667 0 1 1 6.666-6.666 6.6 6.6 0 0 1-1.408 4.083ZM7.167 2.167a5 5 0 1 0 0 10 5 5 0 0 0 0-10Z"
            fill="#000"
            fillOpacity=".8"
          />
        </svg>
        <input
          onChange={handleSearch}
          value={search}
          type="text"
          placeholder="Search for Campus"
          className="w-[85%] h-full outline-0 bg-[#fff] text-sm text-black"
        />
      </div>

      {listCampus?.length !== 0 && listCampus?.map((campus) => {
        return <SingleCampus key={campus.id} campus={campus} onClick={onSwitchCampus} />;
      })}
    </div>
  );
});
PopupSwitchCampus.displayName = 'PopupCampuses';
export default PopupSwitchCampus;