import React, { useState } from 'react';

import { Checkbox } from '../../../../stories';
import { phstudent, desc } from '../../../../static/image';
import { formatMoney } from '../../../../helper/format-money';

const styles = {
  study: { color: '#404EED' },
  playgroup: { textTransform: 'uppercase' },
  section: { color: '#919eab' },
  yrs: { top: 1 },
};

export default function FeeGradesCard(props) {
  const { grade, listProgrammes, listGradeProgrammes, handleUpdateFee } = props;
  const [checkOne, setCheckOne] = useState(false);

  const checkGradeProgram = (grade, program, listGradeProgrammes) => {
    for (const gradeProgram of listGradeProgrammes) {
      if (grade.id === gradeProgram.id) {
        for (const data of gradeProgram.programGrade) {
          if (data.program.id === program.id) {
            return true;
          }
        }
      }
    }
    return false;
  };
  const checkFee = (grade, program, listGradeProgrammes) => {
    const filterProgrammes = [];
    listGradeProgrammes.forEach((data, index) => {
      if (data.id === grade.id) {
        filterProgrammes.push({ ...data, index: index });
      }
    });
    const filterProgramGrade = filterProgrammes.find((data) => {
      const findProgramInProgramGrade = data.programGrade.findIndex((proGra) => {
        return proGra.program.id === program.id;
      });
      return findProgramInProgramGrade !== -1;
    });

    let programGradeFee;
    let idx = 0;
    filterProgramGrade?.programGrade.forEach((proGrade, index) => {
      if (proGrade.program.id === program.id) {
        programGradeFee = proGrade.programGradeFee;
        idx = index;
      }
    });
    if (Array.isArray(programGradeFee)) {
      programGradeFee = programGradeFee[0];
    }
    if (!programGradeFee) {
      return {
        status: false,
        data: {
          index: filterProgramGrade?.index,
          programGradeId: filterProgramGrade?.programGrade?.[idx]?.id,
          programName: program.name,
          gradeName: grade.name,
        },
        idx: idx,
      };
    }
    return {
      status: true,
      data: {
        index: filterProgramGrade?.index,
        programGradeId: filterProgramGrade?.programGrade?.[idx]?.id,
        programGradeFee: programGradeFee,
        programName: program.name,
        gradeName: grade.name,
      },
      idx: idx,
    };
  };

  return (
    <tr className="grades-class-card-row_card h-[7.1rem] align-bottom">
      {listProgrammes.map((item, key) => {
        const resultCheckFee = checkFee(grade, item, listGradeProgrammes);
        return !checkGradeProgram(grade, item, listGradeProgrammes) ? (
          <td key={key} className="align-middle pl-28">
            <Checkbox value={true} disabled={true} />
          </td>
        ) : resultCheckFee.status ? (
          <GradeFeeElement
            item={resultCheckFee.data}
            key={key}
            handleUpdateFee={handleUpdateFee}
            idx={resultCheckFee.idx}
          />
        ) : (
          <td key={key} className="align-middle pl-28">
            <Checkbox
              value={checkOne}
              onChange={() => {
                handleUpdateFee(
                  {
                    ...resultCheckFee.data,
                  },
                  resultCheckFee.idx,
                );
              }}
            />
          </td>
        );
      })}
    </tr>
  );
}

const GradeFeeElement = ({ item, handleUpdateFee, idx }) => {
  return (
    <td>
      <div
        onClick={() => {
          handleUpdateFee(item, idx);
        }}
        className="w-60 h-25 pt-2 pb-2 flex justify-around ml-5 rounded-2xl shadow-grade cursor-pointer"
      >
        <div className="grades-card-section_text">
          <p className="cursor-pointer" style={styles.study}>
            {/* <img className="inline" src={desc} alt="study" /> */}
            Application fee:{' '}
            {formatMoney(
              item.programGradeFee?.applicationFee,
              item.programGradeFee?.currency,
            )}
          </p>
          <p className="cursor-pointer" style={styles.study}>
            {/* <img className="inline" src={desc} alt="study" /> */}
            Fee payment:{' '}
            {formatMoney(
              item.programGradeFee?.feePayment,
              item.programGradeFee?.currency,
            )}
          </p>
        </div>
      </div>
    </td>
  );
};
