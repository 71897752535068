import { checkErrorApiFetch } from '@utils/check-error/api-error';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { ToastMessageContext } from 'context/toast-context';
import { dataLinks } from 'entities/routes';
import { NavLinks } from '../../Header/components/index';
import { AcademicModal, AcademicYears } from '../components/index';
import { plus, sort } from '@static/image';
import { Button } from '@stories/index';
import './academic-terms.scss';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';

export default function AcademicTerms({ burger }) {
  const [programs, setPrograms] = useState([]);
  const [type, setType] = useState(!!programs && programs[0]);
  const [listAcademic, setListAcademic] = useState([]);
  const [selectAcademic, setSelectAcademic] = useState([]);
  const [onEdit, setOnEdit] = useState(true);
  const [isSort, setIsSort] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campus = useStorage((state) => state.currentCampus);

  const getListAcademics = useCallback((programId) => {
    centralAdminApi.getListAcademicYear({ programId: programId, campusId: campus?.isCentral ? undefined : campus?.id })
      .then((res) => {
        if (
          checkErrorApiFetch(
            res,
            setToastMessage,
            setIsShowToastMessage,
            'Get List Academic Terms',
          )
        ) {
          const { objects } = res.data.data;
          setListAcademic(objects);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Get List Academic Terms Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Academic Terms Error', error);
      });
  }, [campus]);

  useEffect(() => {
    centralAdminApi.getListProgram({ campusId: campus?.isCentral ? undefined : campus?.id })
      .then((res) => {
        const { data } = res;
        if (data.success) {
          setPrograms(data.data.objects);
          setType(data.data.objects[0]);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Get List Programs Failed',
            message: data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Get List Programs Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Academic Terms Error', error);
      });
  }, [campus]);

  useEffect(() => {
    if (type?.id) {
      getListAcademics(type.id);
    }
  }, [type, onEdit]);

  const onAddAcademic = () => {
    const currentYear = new Date().getFullYear();
    const date = new Date();
    date.setDate(31);
    date.setMonth(6);
    const startDate = date.toDateString();
    date.setMonth(date.getMonth() + 3) // the end day will default more than 3 months
    const endDate = date.toDateString();
    const newAcademic = {
      isNew: true,
      programId: type?.id,
      name: `${currentYear}-${currentYear + 1}`,
      academicYearTerm: [
        {
          name: 'Term 1',
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          lockTerm: false,
        },
      ],
    };
    setOnEdit(!onEdit);
    setSelectAcademic(newAcademic);
  };

  const onModal = (academic) => {
    setOnEdit(!onEdit);
    setSelectAcademic(academic);
  };
  const cancelHandle = () => {
    setOnEdit(true);
  };

  const onSort = () => {
    setIsSort(!isSort);
  };

  return (
    <div className={clsx('academ-terms pr-20 pl-20 transition-all-300', burger ? '1400px:pl-80' : '1400px:pl-40')}>
      <Fade clear duration={300}>
        <NavLinks urls={dataLinks.academLinks} />
      </Fade>
      {onEdit ? (
        <div>
          <h1 className="namepage">Academic Years</h1>
          <div className='flex justify-between items-center w-full'>
            <div>
              {programs?.map((item, key) => (
                <button
                  className={clsx(
                    'mr-5 h-11 border-b-2 transition-all none-after z-20',
                    item.name === type?.name ? 'border-main-blue' : 'border-white',
                  )}
                  onClick={() => {
                    setType(item);
                    setListAcademic([]);
                  }}
                  key={key}
                >
                  <div className='flex h-[1rem] items-start leading-6'>
                    {item.name}
                  </div>
                </button>
              ))}
            </div>
            <div className="academ-terms-header justify-end w-[16rem]">
              <button onClick={onSort} className="academ-terms-header_sort">
                <img
                  src={sort}
                  alt="sort"
                  className={isSort ? 'animate-sort_on' : 'animate-sort'}
                />
              </button>
              <Button text="Add Academic Year" onClick={onAddAcademic} icon={plus} />
            </div>
          </div>

          <div className='mt-4 w-full'>
            <AcademicYears
              onAddAcademic={onAddAcademic}
              onModal={onModal}
              burger={burger}
              listAcademic={listAcademic}
            />
          </div>
        </div>
      ) : (
        <AcademicModal
          setOnEdit={setOnEdit}
          cancelHandle={cancelHandle}
          burger={burger}
          selectAcademic={selectAcademic}
        />
      )}



      {/* <AcademicYears
          onAddAcademic={onAddAcademic}
          onModal={onModal}
          burger={burger}
          listAcademic={listAcademic}
        /> */}
      {/* {onEdit ? (
        <AcademicYears
          onAddAcademic={onAddAcademic}
          onModal={onModal}
          burger={burger}
          listAcademic={listAcademic}
        />
      ) : (
        <AcademicModal
          setOnEdit={setOnEdit}
          cancelHandle={cancelHandle}
          burger={burger}
          selectAcademic={selectAcademic}
        />
      )} */}
    </div>
  );
}

AcademicTerms.propTypes = {
  burger: PropTypes.bool,
};
