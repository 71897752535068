import { useContext, useState } from 'react';
import { Button, SelectGroup } from '@stories/index';
import { checkErrorSingleApi } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { toggleButtonStyles } from '../../../../entities/select-style';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';

function UpdateStatus(props) {
  const { setIsEditStatus = () => { }, status, id, setRefresh = () => { } } = props;
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campus = useStorage((state) => state.currentCampus);
  const [selectedStatus, setSelectedStatus] = useState({ value: status, label: status });

  const optionSelect = [
    { value: 'Required', label: 'Required' },
    { value: 'Optional', label: 'Optional' },
    { value: 'Disabled', label: 'Disabled' },
  ];

  const handleUpdateFormStatus = () => {
    centralAdminApi.updateDocumentSetting({ campusId: campus?.isCentral ? undefined : campus?.id }, { status: selectedStatus.value }, id)
      .then((res) => {
        if (
          checkErrorSingleApi(
            res,
            setToastMessage,
            setIsShowToastMessage,
            'Update document setting',
          )
        ) {
          setRefresh((prev) => prev + 1);
          setIsEditStatus(false);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Update document setting Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Update document setting error', error.message);
      });
  };

  return (
    <>
      <div className="flex flex-wrap justify-center absolute top-12 right-[-1rem] w-[18rem] h-32 bg-[#fff] shadow-2xl z-20 rounded">
        <div className="mt-6 w-full ml-6 mr-6">
          <SelectGroup
            value={selectedStatus}
            options={optionSelect}
            isMulti={false}
            isSearchable={true}
            onChange={(e) => {
              setSelectedStatus(e);
            }}
          />
        </div>
        <div className="flex justify-center">
          <Button
            className="text-[0.8rem]"
            text="Cancel"
            customStyle={{
              ...toggleButtonStyles.btnWhite,
              width: '7rem',
              height: '1.5rem',
            }}
            onClick={(e) => {
              e.stopPropagation();
              setIsEditStatus(false);
            }}
          />
          <Button
            className="text-[0.8rem]"
            text="Save"
            customStyle={{
              ...toggleButtonStyles.btnBlue,
              width: '7rem',
              height: '1.5rem',
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleUpdateFormStatus();
            }}
          />
        </div>
      </div>
    </>
  );
}

export default UpdateStatus;
