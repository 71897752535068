import SelectTeam from './select-team';
import TeamMenu from './team-menu';

import { ellipsis, mainavatar } from '../../../../static/image';
import { bloom, miniarrow } from '../../image';

function TeamItem(props) {
  const {
    listTeams,
    selectedTeam,
    setIsSelectTeam,
    isSelectTeam,
    setIsShowTeamMenu,
    setVisibleTeamMemberModal,
    isShowTeamMenu,
    handleSelectTeam,
    setVisibleModal,
  } = props;

  return (
    <>
      {selectedTeam ? (
        <div className="flex relative left-16 mr-2">
          <div className="flex flex-col items-center">
            <p className="text-xs text-second-gray">Managers</p>
            <div className="flex -space-x-2 items-center">
              {selectedTeam.managers.length > 0 && selectedTeam.managers.length < 4 ? (
                selectedTeam.managers.map((member, idx) => {
                  return (
                    <img
                      key={idx}
                      className="inline-block h-8 w-8 h-8 rounded-full ring-2 ring-white"
                      src={member?.user?.photoURL || mainavatar}
                      alt=""
                    />
                  );
                })
              ) : selectedTeam.managers.length >= 4 ? (
                [0, 1, 2, 3].map((key) => {
                  return key !== 3 ? (
                    <img
                      key={key}
                      className="inline-block h-8 w-8 h-8 rounded-full ring-2 ring-white"
                      src={selectedTeam?.managers?.[key]?.user?.photoURL || mainavatar}
                      alt=""
                    />
                  ) : (
                    <div
                      key={key}
                      className="flex items-center justify-center bg-green-200 text-sm h-8 w-8 h-8 rounded-full ring-2 ring-white"
                    >
                      +{selectedTeam.managers.length - 3}
                    </div>
                  );
                })
              ) : (
                <div>-</div>
              )}
            </div>
          </div>
          <div className="flex flex-col ml-5">
            <p className="text-xs text-second-gray">Members</p>
            <div className="flex -space-x-2 items-center">
              {selectedTeam.members.length > 0 && selectedTeam.members.length < 4 ? (
                selectedTeam.members.map((member, idx) => {
                  return (
                    <img
                      key={idx}
                      className="inline-block h-8 w-8 h-8 rounded-full ring-2 ring-white"
                      src={member?.user?.photoURL || mainavatar}
                      alt=""
                    />
                  );
                })
              ) : selectedTeam.members.length >= 4 ? (
                [0, 1, 2, 3].map((key) => {
                  return key !== 3 ? (
                    <img
                      key={key}
                      className="inline-block h-8 w-8 h-8 rounded-full ring-2 ring-white"
                      src={selectedTeam?.members?.[key]?.user?.photoURL || mainavatar}
                      alt=""
                    />
                  ) : (
                    <div
                      key={key}
                      className="flex items-center justify-center bg-green-200 text-sm h-8 w-8 h-8 rounded-full ring-2 ring-white"
                    >
                      +{selectedTeam.members.length - 3}
                    </div>
                  );
                })
              ) : (
                <div>-</div>
              )}
            </div>
          </div>
          <div className="flex flex-col ml-5 relative">
            <p className="text-xs text-second-gray">Team/Board</p>
            <div className="flex mt-1.5 mr-4 items-center">
              <div
                className="cursor-pointer flex items-center"
                onClick={() => {
                  setIsSelectTeam(!isSelectTeam);
                  setIsShowTeamMenu(false);
                }}
              >
                <img
                  src={selectedTeam?.logoURL || bloom}
                  alt="bloom"
                  className="mr-2 w-8 h-8 rounded-full"
                />
                {selectedTeam?.name || 'My Team'}
                <img src={miniarrow} alt="team-1" className="ml-2 w-5 h-5" />
              </div>
              <div
                onClick={() => {
                  // setVisibleTeamMemberModal(true); //copy cai nay
                  setIsShowTeamMenu((prev) => !prev);
                  setIsSelectTeam(false);
                }}
                className="ml-3 p-1 cursor-pointer"
              >
                <img src={ellipsis} alt="team-2" className="ml-3 mr-2" />
              </div>
            </div>
            {isShowTeamMenu && (
              <TeamMenu
                setVisibleTeamMemberModal={setVisibleTeamMemberModal}
                setIsShowTeamMenu={setIsShowTeamMenu}
              />
            )}
            {isSelectTeam && (
              <div className="absolute top-14 w-[22rem] left-[-12.75rem] z-20 bg-[#fff] drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] rounded">
                <SelectTeam
                  list={listTeams}
                  handleSelectTeam={handleSelectTeam}
                  setVisibleModal={setVisibleModal}
                  setIsSelectTeam={setIsSelectTeam}
                  selectedTeam={selectedTeam}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          onClick={() => {
            setVisibleModal(true);
          }}
          className="cursor-pointer"
        >
          Add new team
        </div>
      )}
    </>
  );
}

export default TeamItem;
