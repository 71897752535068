import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import convertDateToFormat from '@helper/convertDateToFormat.js'
import { checkErrorApiFetch } from '@utils/check-error/api-error.js'
import { ToastMessageContext } from 'context/toast-context.jsx'
import AppointmentsUser from './appointments-user.jsx'
import './appointments.scss'
import admissionApi from 'api/admission.js'

export default function Appointments({ burger, campus }) {
  const [appointments, setAppointments] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [scheduleByDate, setScheduleByDate] = useState()
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const fetchData = async () => {
    const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1))

    const res = await admissionApi.getListAdmissionCalendarByDate({
      campusId: campus.id,
      startDate: convertDateToFormat(new Date(new Date().setDate(new Date().getDate() - 1)), 'MM/DD/YYYY'),
      // endDate: convertDateToFormat(tomorrow, 'MM/DD/YYYY')
    })
    if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, '', false)) {
      const scheduleDate = {}
      for (let schedule of res.data.data) {
        const { date, time } = schedule
        const newDate = convertDateToFormat(date, 'MM/DD/YYYY')
        if (!scheduleDate[newDate]) {
          scheduleDate[newDate] = [time]
        } else {
          scheduleDate[newDate].push(time)
        }
      }
      setScheduleByDate(scheduleDate)
      setAppointments(res.data.data)
    } else {
      setToastMessage({
        status: 'error',
        title: 'Fetch Appointments Failed',
      });
      setIsShowToastMessage(true);
    }
  }

  useEffect(() => {
    if (campus?.id)
      fetchData()
  }, [refresh, campus?.id])

  return (
    <div className={clsx('appointments grid-container__item--4', burger ? 'appointments_open' : 'appointments_closed')}>
      <div className='appointments-title'>Appointments</div>
      <div className='appointments-row'>
        {appointments?.slice(0, 4)?.map(item => {
          return <AppointmentsUser key={item?.id} data={item} setRefresh={setRefresh} scheduleByDate={scheduleByDate} />
        })}
      </div>
    </div>
  )
}

Appointments.propTypes = {
  /**  @param Type An array of objects.
   * @param Object Keys for objects - userName: string, date: string, status: string, image: string
   * @param status Key variations - Confirmed, Canceled, Call to confirm, ''
   * @param Example [
  { userName: 'Jacqueline Likoki', date: '3: 30 PM, Today', status: 'Confirmed', image: 'https://localhost:8080/...' },
  { userName: 'Jacqueline Likoki', date: '3: 30 PM, Today', status: 'Canceled', image: 'https://localhost:8080/...' },
  { userName: 'Jacqueline Likoki', date: '3: 30 PM, Tommorow', status: 'Call to confirm', image: 'https://localhost:8080/...' },
  { userName: 'Jacqueline Likoki', date: '3: 30 PM, Aug 14 2020', status: '', image: 'https://localhost:8080/...' }
]
   **/
  data: PropTypes.array
}
