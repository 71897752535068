import Add from '@mui/icons-material/Add.js';
import DragDropFile from '@pages/import-user/component/drag-drop-file';
import { pdf } from '@static/image';

const UploadDocument = (props) => {
  const { documents, urlDocuments, handleChange, onRemoveDocument, order, idxScholarship } = props;

  return (
    <div className="text-sm mt-4">
      {order}. Upload Documents
      <p className="text-sm mb-4">
        Please upload relevant documents that prove the affiliation. These documents are
        reviewed by a different department we request you to reupload if you have already
        submited.
      </p>
      <div
        style={{
          boxShadow: '0px 16px 32px -4px #919EAB3D,0px 0px 2px 0px #919EAB3D',
        }}
        className="p-3 rounded-lg"
      >
        <DragDropFile
          title="Scan and Upload the documents"
          multiple={false}
          handleFile={handleChange}
          idxScholarship={idxScholarship}
        />
      </div>
      {documents.length !== 0 && (
        <aside>
          <h4 className="text-base font-semibold text-main-black mt-5">Uploaded Files</h4>
          <ul>
            {documents.map((document, index) => (
              <li className="flex items-center mt-2" key={`documentScholarship${index}`}>
                <img src={pdf} alt="" className="w-10 h-10 mr-3" />
                <div className='flex items-center space-between'>
                  <a key={`document${index}`}
                    className="text-xs text-main-black font-semibold mb-0.5 mr-16"
                    href={urlDocuments[index] || document}
                    rel="help"
                    target="_blank"
                  >
                    {`Scholarship ${index + 1}`}
                  </a>
                  <button onClick={() => onRemoveDocument(index)}>
                    <Add style={{ color: '#EB5757', transform: 'rotate(45deg)' }} />
                  </button>
                </div>
                {/* <a
                  className="text-xs text-main-black font-semibold mb-0.5"
                  href={documents}
                  rel="help"
                  target="_blank"
                >
                  {'Scholarship'}
                </a> */}
                {/* <p className="text-second-gray text-10px">{date}</p> */}
              </li>
            ))}
          </ul>
        </aside>
      )}
    </div>
  );
};

export default UploadDocument;
