import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './checkboxformbuilder.scss';

const CheckboxFormBuilder = ({
  value,
  onChange,
  customClass,
  disabled,
  color,
  group = false,
  groupTitle = 'group title',
  styleTitle,
  classTitle,
  onKeyDown,
}) => {
  const [checkedValue, setCheckedValue] = useState(value);

  const handleChange = (e) => {
    setCheckedValue(e.target.checked);
    if (onChange !== undefined) {
      onChange(e);
    }
  };

  return disabled === true ? (
    // <label className={clsx('pure-material-checkbox_non', customClass)}>
    <label className={clsx(value && 'pure-material-checkbox', customClass)}>
      <input type="checkbox" checked={value} disabled="disabled" />
      <span className="cursor-not-allowed"></span>
    </label>
  ) : (
    <div className={group ? 'flex items-center' : ''}>
      <label
        className={clsx(
          color === 'green' ? 'pure-material-checkbox_green' : 'pure-material-checkbox',
          customClass,
        )}
      >
        <input
          type="checkbox"
          checked={value}
          onChange={handleChange}
          onKeyDown={onKeyDown}
        />
        <span></span>
      </label>
      {group && (
        <p className={classTitle} style={styleTitle}>
          {groupTitle}
        </p>
      )}
    </div>
  );
};

CheckboxFormBuilder.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  groupTitle: PropTypes.string,
  classTitle: PropTypes.string,
  styleTitle: PropTypes.object,
  group: PropTypes.bool,
};

CheckboxFormBuilder.defaultProps = {
  disabled: false,
};

export default CheckboxFormBuilder;
