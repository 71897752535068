import { memo } from 'react';
import SingleSelect from './component/single-select';
import TypeAndValue from './component/type-and-value';

const SportCard = memo((props) => {
  const { data, processApprovals, aboveLimitOption, handleChange } = props
  const discountType = data?.discountType || ''
  const maximumValue = data?.maximumValue || ''
  const aboveLimit = data?.aboveLimit || ''
  const approval = data?.approval || null
  const finalApproval = data?.finalApproval || null

  return (
    <div className="principial-card">
      <TypeAndValue info={{ discountType, value: maximumValue }}
        isFixed={false}
        className='mb-3'
        label={{ value: 'Maximum Value', discountType: 'Discount Type' }}
        handleChange={({ name, value }) => handleChange({ index: 0, name, value })}
      />

      {/* Approval */}
      <div className='mb-0 mt-[-0.25rem]'>
        <SingleSelect label='Approval'
          value={approval ? processApprovals.find(item => item.value === approval) : null}
          options={processApprovals}
          onChange={(e) => {
            handleChange({
              index: 0,
              name: 'approval',
              value: e.value,
            })
          }}
        />
      </div>

      {/* Above limit option */}
      <SingleSelect label='Above limit option'
        value={aboveLimitOption.find(item => item.value === aboveLimit) || null}
        options={aboveLimitOption}
        onChange={(e) => {
          handleChange({
            index: 0,
            name: 'aboveLimit',
            value: e.value,
          })
        }}
      />

      {/* Final Approval */}
      {aboveLimit === 'Need Approval' && (
        <SingleSelect label='Final Approval'
          value={finalApproval ? processApprovals.find(item => item.value === finalApproval) : null}
          options={processApprovals}
          onChange={(e) => {
            handleChange({
              index: 0,
              name: 'finalApproval',
              value: e.value,
            })
          }}
        />
      )}

    </div>
  )
})
SportCard.displayName = 'SportCard'
export default SportCard