import React from 'react';
import { Pagination } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select, { components } from 'react-select';
import useOnClickOutside from '@hooks/useClickOutside';
import { makeStyles } from '@material-ui/styles';
import './application-window.scss';
import EnquiriesCard from './enquiries-card.jsx';
import { selectStyleWindow } from 'entities/select-style';
import { enquiry_type_1, enquiry_type_2, enquiry_type_3, enquiry_type_4, sort as ic_sort } from '@static/image';
import { transformDataFormBuilder } from '@helper/transform-data-formbuilder';
import { Zoom } from 'react-reveal';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { close } from '../../../../static/image/index';
import InputSlider from '@components/input-slider';
import { urls } from 'entities/urls';
import { EnquiryStatus } from 'entities/data';

const filterEnquiriesOptions = [
  { value: 'All', label: 'All Enquiries' },
  { value: 'New', label: 'New' },
  { value: 'Application', label: 'Application' },
  { value: 'Referral applications', label: 'Referral' },
  { value: 'Sibling applications', label: 'Sibling' },
];

const listType = [
  { status: 'Phone Enquiry', icon: enquiry_type_1 },
  { status: 'Website', icon: enquiry_type_2 },
  { status: 'Walk in', icon: enquiry_type_3 },
  { status: 'Referral', icon: enquiry_type_4 },
];

export default function ApplicationWindow(props) {
  const {
    fixed = true,
    listStage,
    currentEnquiry,
    currentPage,
    pageSize,
    listEnquiry,
    filter,
    totalEnquiry,
    handleChangePage,
    handleChangeEnquiry,
    handleChangeFilter,
    handleChangeInputFilter,
    currentEnquiryId
  } = props;

  const styles = { sort: { width: '1.125rem', height: '1.125rem' } };
  const history = useHistory();
  const popupSortRef = useRef();
  const [isOpenModalSort, setIsOpenModalSort] = useState(false);
  const [valueSlider, setValueSlider] = useState([0, 30]) // [number, number] 0->30
  const onChangeSlider = (value) => {
    setValueSlider(value)
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiPagination-ul': {
        flexWrap: 'nowrap',
      },
    },
  }));
  const classes = useStyles();

  useOnClickOutside(popupSortRef, () => setIsOpenModalSort(false))

  return (
    <div className={clsx('enquiries-window ', fixed ? 'fixed' : 'relative')}>
      <div className="enquiries-window-header">
        <div className="flex items-center pt-1 pl-1">
          <Select
            value={filter.status}
            options={filterEnquiriesOptions}
            components={{ DropdownIndicator }}
            isMulti={false}
            className="mr-10"
            styles={selectStyleWindow}
            onChange={(e) => {
              handleChangeFilter({ key: 'status', value: e });
            }}
          />
          <button
            className="add-enquiries-btn"
            onClick={() => {
              history.push(urls.createEnquiry);
            }}
          >
            +
          </button>
          <button
            className="w-6 h-6 OnClickOutside flex justify-center items-center rounded-full"
            onClick={() => setIsOpenModalSort(!isOpenModalSort)}
          >
            <img className="OnClickOutside" style={styles.sort} src={ic_sort} alt="" />
          </button>
          <div ref={popupSortRef}>
            {isOpenModalSort && (
              <Zoom duration={300}>
                <div className="absolute top-[3rem] left-[4rem] w-auto z-20 bg-[#fff] shadow-2xl rounded-2xl">
                  <div className="p-4 pt-2">
                    <div className="flex justify-between">
                      <h3 className="font-bold text-base">Filter</h3>
                      <button onClick={() => setIsOpenModalSort(false)}>
                        <img
                          className="hover:bg-slate-50 opacity-70 w-8 p-2 rounded-lg"
                          src={close}
                          alt="close"
                        />
                      </button>
                    </div>
                    <hr className="my-2" />
                    <div>
                      <h3 className="font-bold text-base opacity-70">Type</h3>
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '1fr 1fr 1fr',
                        }}
                      >
                        {listType.map((data, index) => {
                          return (
                            <div
                              key={index}
                              className={clsx(
                                'm-2 w-[5.5rem] h-20 rounded-2xl text-xs hover:bg-slate-100 p-1 cursor-pointer flex flex-col justify-center items-center border-2',
                              )}
                            >
                              <img
                                className="w-8 pb-1"
                                src={data?.icon}
                                alt={data?.status}
                              />
                              <p className="text-center text-[0.6rem] font-bold opacity-90">
                                {data?.status}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <hr className="my-2" />
                    <div>
                      <h3 className="font-bold text-base opacity-70">Grades</h3>
                      <div className="flex justify-between">
                        <span className="opacity-50">set Classes</span>
                        <span className="opacity-70 font-bold inline-block ">
                          <span className='inline-block w-10 text-center'>{valueSlider[0]}</span>-<span className='inline-block w-10 text-center'>{valueSlider[1]}</span>
                        </span>
                      </div>
                      <InputSlider
                        min={0}
                        max={30}
                        defaultValue={[0, 30]}
                        step={0.5}
                        getValue={onChangeSlider}
                        valueLabelDisplay={"off"}
                        size={"small"}
                      />
                    </div>
                    <hr className="my-2" />
                    <div>
                      <h3 className="font-bold text-base opacity-70">Stages</h3>
                      <div className="flex justify-between mt-1 items-center">
                        <span className="text-base opacity-80">New Equiry</span>
                        <Checkbox defaultChecked className="text-gray-800" sx={{ padding: '0px' }} />
                      </div>
                      <div className="flex justify-between items-center ">
                        <span className="opacity-80">Application</span>
                        <Checkbox className=" text-gray-800" sx={{ padding: '0px' }} />
                      </div>
                      <div className="flex justify-between items-center">
                        <span className="opacity-80">Exam</span>
                        <Checkbox className=" text-gray-800" sx={{ padding: '0px' }} />
                      </div>
                    </div>
                    <hr className="my-2" />
                    <hr className="my-4" />
                    <div className="flex justify-around ">
                      <Button className=" w-28 text-black capitalize" variant="outlined">
                        Cancel
                      </Button>
                      <Button
                        className=" w-28 bg-black text-white capitalize"
                        variant="outlined"
                      >
                        Filter
                      </Button>
                    </div>
                  </div>
                </div>
              </Zoom>
            )}
          </div>
        </div>
        <div className="flex items-center pt-1 pl-2 justify-between ">
          <SearchIcon />
          <input
            value={filter.name}
            onChange={(e) => {
              handleChangeInputFilter(e);
            }}
            // onKeyDown={(e) => {
            //   if (e.key === 'Enter') {
            //     handleSearchEnquiries(e);
            //   }
            // }}
            placeholder="Search Enquiries"
            className="w-full ml-1 text-base focus:outline-none"
          />
        </div>
      </div>
      <div className="enquiries-window-body">
        <p className="enquiries-window-body_title">Recent Enquiries</p>
        {listEnquiry?.map((item, key) => {
          if (Array.isArray(item.info)) item.info = transformDataFormBuilder(item.info);
          const avatar = item.info?.avatar;
          const name = item.info?.firstName + ' ' + item.info?.lastName;
          const py = item?.programGrade?.grade?.code;
          const date = item?.academicYear?.name;
          let currentStep = item?.currentStepShowing;
          let nextStep = item?.nextStep;

          if (item.enquiryStatus === EnquiryStatus.FAILED.toLowerCase()) {
            currentStep = EnquiryStatus.FAILED
          } else if (item.id === currentEnquiry?.id) {
            currentStep = currentEnquiry?.currentStepShowing;
            nextStep = currentEnquiry?.nextStep;
          } else if (nextStep) {
            if (listStage && listStage?.length !== 0) {
              const tmp = listStage.findIndex((item) => {
                const name = item.textDefault || item.name;
                return name === currentStep;
              });
              if (tmp !== -1 && tmp < listStage.length)
                currentStep = listStage[tmp + 1]?.name;
            }
          }
          return (
            <EnquiriesCard
              key={key}
              avatar={avatar}
              name={name}
              py={py}
              date={date}
              currentEnquiryId={currentEnquiryId}
              handleChangeEnquiry={handleChangeEnquiry}
              id={item.id}
              currentStep={currentStep}
            />
          );
        })}
      </div>
      <div
        className="absolute bottom-16 left-[50%] "
        style={{ transform: 'translateX(-50%)' }}
      >
        <Pagination
          className={classes.root}
          count={Math.floor(totalEnquiry / pageSize) + 1}
          showFirstButton
          showLastButton
          page={currentPage + 1}
          size="small"
          siblingCount={0}
          boundaryCount={1}
          onChange={(e, value) => {
            handleChangePage(value - 1);
          }}
        />
      </div>
    </div>
  );
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="5"
        height="4"
        viewBox="0 0 5 4"
        fill="none"
      >
        <path
          d="M0.307026 1.80667L2.03369 3.53334C2.29369 3.79334 2.71369 3.79334 2.97369 3.53334L4.70036 1.80667C5.12036 1.38667 4.82036 0.666672 4.22703 0.666672H0.773693C0.18036 0.666672 -0.112974 1.38667 0.307026 1.80667Z"
          fill="#212B36"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

export const SearchIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.425 13.25L17.2583 16.075C17.4161 16.2315 17.5048 16.4445 17.5048 16.6667C17.5048 16.8889 17.4161 17.1019 17.2583 17.2583C17.1019 17.4161 16.8889 17.5048 16.6667 17.5048C16.4445 17.5048 16.2315 17.4161 16.075 17.2583L13.25 14.425C12.0854 15.3392 10.6472 15.8352 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667C15.8352 10.6472 15.3392 12.0854 14.425 13.25ZM9.16667 4.16667C6.40524 4.16667 4.16667 6.40524 4.16667 9.16667C4.16667 11.9281 6.40524 14.1667 9.16667 14.1667C11.9281 14.1667 14.1667 11.9281 14.1667 9.16667C14.1667 6.40524 11.9281 4.16667 9.16667 4.16667Z"
          fill="#919EAB"
          fillOpacity="0.8"
        />
      </svg>
    </div>
  );
};

export const SortIcon = () => {
  return (
    <div>
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.8125 0.666779L14.7208 0.673195C14.5559 0.695383 14.4046 0.776637 14.2951 0.901884C14.1855 1.02713 14.1251 1.18787 14.125 1.35428V12.9886L11.1733 10.0388L11.0963 9.97278C10.9568 9.86907 10.7832 9.82207 10.6104 9.84124C10.4376 9.8604 10.2785 9.94431 10.1651 10.0761C10.0517 10.2078 9.99244 10.3777 9.99922 10.5514C10.006 10.7251 10.0783 10.8898 10.2017 11.0123L14.3303 15.1373L14.4073 15.2033C14.5398 15.3011 14.7029 15.3481 14.8671 15.3359C15.0313 15.3236 15.1856 15.2528 15.302 15.1364L19.4242 11.0114L19.4902 10.9344C19.5883 10.8019 19.6355 10.6385 19.6232 10.4741C19.6109 10.3097 19.54 10.1552 19.4233 10.0388L19.3463 9.97278C19.2138 9.87471 19.0505 9.82751 18.8861 9.8398C18.7217 9.85208 18.5672 9.92302 18.4508 10.0397L15.5 12.9923V1.35428L15.4945 1.26078C15.4719 1.09619 15.3905 0.945346 15.2653 0.836149C15.1401 0.726951 14.9795 0.666786 14.8134 0.666779H14.8125ZM4.69617 0.868445L0.576666 4.98886L0.509749 5.06586C0.411928 5.19829 0.36488 5.36143 0.37716 5.52561C0.38944 5.6898 0.460233 5.84412 0.576666 5.96053L0.653666 6.02744C0.786097 6.12527 0.949235 6.17231 1.11342 6.16003C1.2776 6.14775 1.43193 6.07696 1.54833 5.96053L4.49725 3.0107V14.6505L4.50458 14.744C4.52718 14.9086 4.60861 15.0595 4.73381 15.1687C4.85902 15.2779 5.01953 15.338 5.18567 15.338L5.27825 15.3316C5.44301 15.3092 5.59407 15.2279 5.70345 15.1027C5.81283 14.9774 5.87313 14.8168 5.87317 14.6505L5.87225 3.01253L8.82667 5.96144L8.90367 6.02744C9.04328 6.12985 9.2164 6.17581 9.38842 6.15613C9.56044 6.13645 9.71871 6.05258 9.83159 5.92129C9.94447 5.79 10.0037 5.62096 9.99732 5.44793C9.99099 5.2749 9.9196 5.11063 9.79742 4.98794L5.66875 0.868445L5.59083 0.802445C5.4584 0.704624 5.29526 0.657577 5.13108 0.669857C4.9669 0.682137 4.81257 0.752929 4.69617 0.869362V0.868445Z"
          fill="#637381"
        />
      </svg>
    </div>
  );
};

export const FilterIcon = () => {
  return (
    <div>
      <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.74 0H0.240015C0.174583 0.00504797 0.113479 0.0346537 0.0689654 0.0828769C0.0244517 0.1311 -0.000178981 0.194373 1.54489e-05 0.26V1.12C-0.000585379 1.20815 0.0163447 1.29553 0.0498212 1.37708C0.0832977 1.45862 0.132653 1.5327 0.195016 1.595L6.19502 7.595V12.595L9.82001 14.4V7.585L15.82 1.585C15.9348 1.46118 15.999 1.29884 16 1.13V0.26C16 0.191044 15.9726 0.124912 15.9239 0.0761523C15.8751 0.0273929 15.809 0 15.74 0Z"
          fill="#323338"
        />
      </svg>
    </div>
  );
};

export const ExpandIcon = (props) => {
  const { width = '12', height = '7', classes = '' } = props;
  return (
    <div className={classes}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 12 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.166635 1.33337C0.166254 1.13866 0.234066 0.949962 0.3583 0.800035C0.499591 0.629612 0.702885 0.522412 0.923325 0.502088C1.14376 0.481763 1.36323 0.549986 1.5333 0.691702L5.99997 4.42503L10.475 0.825035C10.6471 0.685226 10.8679 0.619811 11.0885 0.643272C11.309 0.666733 11.5111 0.777135 11.65 0.950035C11.8033 1.12434 11.877 1.35472 11.8533 1.58568C11.8296 1.81664 11.7106 2.02722 11.525 2.1667L6.52497 6.1917C6.21746 6.44446 5.77413 6.44446 5.46663 6.1917L0.466634 2.02503C0.262095 1.85548 0.150661 1.59856 0.166635 1.33337Z"
          fill="#323338"
        />
      </svg>
    </div>
  );
};

ApplicationWindow.propTypes = {
  fixed: PropTypes.bool,
  data: PropTypes.array,
};
