import { approvalActivity, file, mainavatar } from '@static/image';
import { checkErrorApiFetch, checkErrorSingleApi } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { forwardRef, memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import AreaCommenting from '../area-commenting';
import './style.scss';
import taskApi from 'api/task';
import useStorage from 'store/storage';

function TaskInformation(props) {
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const { taskId } = props;
  const [listInformation, setListInformation] = useState([]);
  const [clear, setClear] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const refElement = useRef();
  const campus = useStorage((state) => state.currentCampus);
  const auth = useStorage((state) => state.auth);

  useEffect(() => {
    if (refresh === 0) setIsLoading(true);
    taskApi.getTaskById({ campusId: campus?.isCentral ? undefined : campus?.id }, taskId)
      .then((res) => {
        if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage)) {
          if (res.data.data.objects.length > 0) {
            setListInformation(res.data.data.objects);
          }
        }
        if (refresh === 0) setIsLoading(false);
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        if (refresh === 0) setIsLoading(false);
        console.log('Get task information error', error);
      });
  }, [refresh]);

  const handlePublish = useCallback((value) => {
    const data = {
      taskId,
      info: {
        title: `Comment made by ${auth?.displayName}`,
      },
      comment: value.message
    };
    taskApi.updateTaskInformation({ campusId: campus?.isCentral ? undefined : campus?.id }, data)
      .then((res) => {
        if (
          checkErrorSingleApi(
            res,
            setToastMessage,
            setIsShowToastMessage,
            'Add Task Information',
          )
        ) {
          setClear(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Add Task Information',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Task information Error', error);
      })
      .finally(() => {
        setRefresh((prev) => prev + 1);
      });
  }, []);

  useEffect(() => {
    if (refElement.current) {
      const { scrollHeight, clientHeight } = refElement.current;
      const pos = scrollHeight - clientHeight;
      refElement.current.scrollTop = pos;
    }
  }, [refresh]);

  return (
    <div
      className="overflow-y-auto overflow-x-hidden h-[82%] max-h-[82%] 1920px:h-[90%] 1920px:max-h-[90%] relative"
    >
      {isLoading ? (
        <div className="h-[75%]">
          <p>Loading ...</p>
        </div>
      ) : (
        <InformationMemo
          ref={refElement}
          listInformation={listInformation}
          customClass="h-[75%]"
        />
      )}
      <AreaCommenting
        actions={{ emoji: true, enclose: false, record: false }}
        handlePublish={handlePublish}
        clear={clear}
        setClear={setClear} />
    </div>
  );
}

export default memo(TaskInformation);

const Information = (props, ref) => {
  const { listInformation = [], customClass } = props;
  // const handleIconApproval = (isApproval) => {
  //   switch (isApproval.toLowerCase()) {
  //     case 'approve':
  //       return approvalNew;
  //     default:
  //       return waitApproval;
  //   }
  // };

  return (
    <div
      ref={ref}
      className={`information relative overflow-y-auto pb-[2%] ${customClass}`}
    >
      {listInformation.length === 0 ? (
        <div className="flex flex-col items-center mt-10 overflow-x-hidden overflow-y-scroll">
          <div>
            <img
              src={approvalActivity}
              alt="avatar"
              className="mr-2 pb-0.5 rounded-full"
            />
          </div>
          <div className="text-xl text-[#979797] mt-4">No updates yet for this item</div>
        </div>
      ) : (
        <div className="flex flex-col">
          {listInformation.map((info, idx) => {
            const {
              comment: message,
              dateCreated,
              stageName,
              time,
              createdBy: user,
              info: infoData,
            } = info;
            const { name, roles, photoURL: avatar } = user;
            const { title, content } = infoData;
            return (
              <div key={idx} className="flex mt-3 justify-around">
                <div className="flex w-[22%] justify-between">
                  <div className="w-[20%] mt-1">
                    <img
                      src={file}
                      alt="avatar"
                      className="w-5 h-5 p-1 z-10 bg-slate-50 rounded-full border-[0.5px] border-[#B0B6BE]"
                    />
                  </div>
                  <div className="w-[75%] mt-0.5">
                    <p className="text-xs font-medium">
                      {new Date(dateCreated).toUTCString().slice(5, 12)}
                      <span style={{ fontSize: '0.5rem' }}>
                        {new Date(dateCreated).toUTCString().slice(12, 16)}
                      </span>
                    </p>
                    <p style={{ color: '#BABEC3', fontSize: '0.625rem' }}>
                      {new Date(dateCreated).toUTCString().slice(17, 22)}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col w-[73%]">
                  <div className="text-sm mt-0.25 font-medium">{title || 'Information Title'}</div>
                  <div className="flex">
                    <img
                      src={avatar || mainavatar}
                      alt="avatar"
                      className="w-[14px] h-[14px] mr-2 mt-0.5 rounded-full"
                    />
                    <p className="align-middle mt-0.5 font-medium text-[#637381]" style={{ fontSize: '0.625rem' }}>
                      {name} |{' '}
                      {roles.length > 0
                        ? roles.map((data, idx) => {
                          return (
                            <span key={idx}>
                              {data?.name || 'Position'}
                              {idx !== roles.length - 1 && ', '}
                            </span>
                          );
                        })
                        : 'Position'}
                    </p>
                  </div>
                  {content && content.length !== 0 && (
                    <ul className="text-[10px] leading-4 font-medium list-disc list-inside ml-[2%]">
                      {content.map((item) => {
                        return <li key={item}>{item}</li>;
                      })}
                    </ul>
                  )}
                  {message && (
                    <div className="relative">
                      <div className="triangle-bottom-right-activity"></div>
                      <p
                        className="inline-block mt-1 py-2 px-2 rounded-lg max-w-[13rem]"
                        style={{
                          fontSize: '0.625rem',
                          color: '#8C9094',
                          backgroundColor: '#F1F2F3',
                        }}
                      >
                        {message}
                      </p>
                    </div>
                  )}
                  <ul>
                    {infoData?.[0]?.content.map((data, idx) => {
                      return (
                        <li
                          className="list-disc, list-inside text-[10px]"
                          key={idx}
                          style={{ listStyleType: 'disc', listStylePosition: 'inside' }}
                        >
                          <strong>{data}</strong>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
const InformationRef = forwardRef(Information, {});
const InformationMemo = memo(InformationRef);
InformationMemo.displayName = 'InformationMemo';
