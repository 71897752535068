import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import ApprovalEdit from './approval-edit';
import './approval-item.scss';
import { callApi } from '@helper/call-api';
import { ToastMessageContext } from 'context/toast-context';
import ApprovalStage from './approval-stage';
import useStorage from 'store/storage';

const ApprovalItem = (props) => {
  const { handleCancel, approvalProcessData, getDataFormApi, listAction } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [approvalProcess, setApprovalProcess] = useState({});
  const [isEditLogicProcess, setIsEditLogicProcess] = useState(false); // check is edit of Process. True call = patch, false call = put
  const [isEditLogicStage, setIsEditLogicStage] = useState(true); // check is edit of stage. True false call = patch of stage
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campus = useStorage((state) => state.currentCampus);

  useEffect(() => {
    setApprovalProcess(approvalProcessData);
  }, [approvalProcessData]);

  function accordionOpenHandler() {
    setIsOpen(!isOpen);
  }

  function openEditStageHandler() {
    setIsOpen(true);
    addNewStage();
  }

  function addNewStage() {
    const newStage = {
      title: `Level ${approvalProcess?.approvalProcessStage?.length + 1}`,
      multiple: false,
    };
  }

  const handlerClickSave = (isEditLogicProcess, isEditLogicStage) => {
    const data = {
      name: approvalProcess?.name,
      color: '$123456',
      triggeredKeyFields: approvalProcess?.triggeredKeyFields,
      highlightKeyFields: approvalProcess?.highlightKeyFields,
      isMandateComments: approvalProcess?.isMandateComments,
      isResetApprovalOnChangeOfFields: approvalProcess?.isResetApprovalOnChangeOfFields,
      isLockFieldsOnApproval: approvalProcess?.isLockFieldsOnApproval,
      isWhatsAppNoti: approvalProcess?.isWhatsAppNoti,
      isEmailNoti: approvalProcess?.isEmailNoti,
      isAppNoti: approvalProcess?.isAppNoti,
      hourReminderEvery: approvalProcess?.hourReminderEvery,
    };

    if (approvalProcess?.isCreateProcess) {
      const newData = {
        name: approvalProcess?.name,
        actionIds: approvalProcess?.actionIds,
        stages: (() => {
          return approvalProcess.approvalProcessStage.map((stage) => {
            return {
              isNotEditLogicStage: stage.isNotEditLogicStage,
              id: stage.id,
              level: stage.level,
              name: stage.name,
              approvalType: stage.approvalType || 'single',
              description: stage.description,
              dueDate: +stage.dueDate,
              userIds: (() => {
                return stage.processStageApprover.map((item) => {
                  return item.approver.id;
                });
              })(),
              escalatedToRoleIds: (() => {
                return stage.escalatedToRoles.map((item) => {
                  return item.id;
                });
              })(),
            };
          });
        })(),
        color: '$123456',
        triggeredKeyFields: approvalProcess?.triggeredKeyFields,
        highlightKeyFields: approvalProcess?.highlightKeyFields,
        isMandateComments: approvalProcess?.isMandateComments,
        isResetApprovalOnChangeOfFields: approvalProcess?.isResetApprovalOnChangeOfFields,
        isLockFieldsOnApproval: approvalProcess?.isLockFieldsOnApproval,
        isWhatsAppNoti: approvalProcess?.isWhatsAppNoti,
        isEmailNoti: approvalProcess?.isEmailNoti,
        isAppNoti: approvalProcess?.isAppNoti,
        hourReminderEvery: approvalProcess?.hourReminderEvery,
      };
      callApi({
        method: 'post',
        url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/process`,
        // params: { campusId: campus?.isCentral ? undefined : campus?.id },
        data: newData,
      })
        .then((res) => {
          if (res.data.success) {
            setToastMessage({
              status: 'success',
              title: 'Create Process Successfully',
              message: res.data.message,
            });
            setIsShowToastMessage(true);
            getDataFormApi();
          } else {
            setToastMessage({
              status: 'error',
              title: 'Create Process Failed',
              message: res.data.message,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Create Process Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Approval Item Error', error);
        });
    }
    if (!approvalProcess?.isCreateProcess && !isEditLogicProcess && !isEditLogicStage) {
      const newData = {
        stages: (() => {
          return approvalProcess.approvalProcessStage.map((stage) => {
            return {
              isNotEditLogicStage: stage.isNotEditLogicStage,
              id: stage.id,
              level: stage.level,
              name: stage.name,
              approvalType: stage.approvalType || 'single',
              description: stage.description,
              dueDate: +stage.dueDate,
              userIds: (() => {
                return stage.processStageApprover.map((item) => {
                  return item.approver.id;
                });
              })(),
              escalatedToRoleIds: (() => {
                return stage.escalatedToRoles.map((item) => {
                  return item.id;
                });
              })(),
            };
          });
        })(),
      };

      newData.stages.forEach((item) => {
        if (item.isNotEditLogicStage) {
          const res = callApi({
            method: 'patch',
            url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/process/stage`,
            params: { campusId: campus?.isCentral ? undefined : campus?.id },
            data: item,
          });
          res
            .then((res) => {
              if (res.data.success) {
                setToastMessage({
                  status: 'success',
                  title: 'Update Process Successfully',
                  message: res.data.message,
                });
                setIsShowToastMessage(true);
                getDataFormApi();
              } else {
                setToastMessage({
                  status: 'error',
                  title: 'Update Process Failed',
                  message: res.data.message,
                });
                setIsShowToastMessage(true);
              }
            })
            .catch((error) => {
              setToastMessage({
                status: 'error',
                title: 'Update Process Failed',
                message: error.response?.data?.message || error,
              });
              setIsShowToastMessage(true);
              console.log('Approval Item Error', error);
            });
        }
      });
    } else if (!approvalProcess?.isCreateProcess && !isEditLogicProcess) {
      const newData = {
        ...data,
        id: approvalProcess.id,
      };
      const res = callApi({
        method: 'patch',
        url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/process`,
        params: { campusId: campus?.isCentral ? undefined : campus?.id },
        data: newData,
      });
      res
        .then((res) => {
          if (res.data.success) {
            setToastMessage({
              status: 'success',
              title: 'Update Process Successfully',
              message: res.data.message,
            });
            setIsShowToastMessage(true);
            getDataFormApi();
          } else {
            setToastMessage({
              status: 'error',
              title: 'Update Process Failed',
              message: res.data.message,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Update Process Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Approval Item Error', error);
        });
    }
    if (!approvalProcess?.isCreateProcess && isEditLogicProcess) {
      const newData = {
        ...data,
        prevProcessId: approvalProcess.id,
        actionIds: (() => {
          return approvalProcess.processAction.map((item) => {
            return item.approvalAction.id;
          });
        })(),
        stages: (() => {
          return approvalProcess.approvalProcessStage.map((stage) => {
            return {
              isNotEditLogicStage: stage.isNotEditLogicStage,
              id: stage.id,
              level: stage.level,
              name: stage.name,
              approvalType: stage.approvalType || 'single',
              description: stage.description,
              dueDate: +stage.dueDate,
              userIds: (() => {
                return stage.processStageApprover.map((item) => {
                  return item.approver.id;
                });
              })(),
              escalatedToRoleIds: (() => {
                return stage.escalatedToRoles.map((item) => {
                  return item.id;
                });
              })(),
            };
          });
        })(),
      };

      const res = callApi({
        method: 'put',
        url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/process/update`,
        // params: { campusId: campus?.isCentral ? undefined : campus?.id },
        data: newData,
      });
      res
        .then((res) => {
          if (res.data.success) {
            setToastMessage({
              status: 'success',
              title: 'Update Process Successfully',
              message: res.data.message,
            });
            setIsShowToastMessage(true);
            getDataFormApi();
          } else {
            setToastMessage({
              status: 'error',
              title: 'Update Process Failed',
              message: res.data.message,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Update Process Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Approval Item Error', error);
        });
    }
  };

  return (
    <div className="admission-approvals-item">
      <div className="admission-approvals-item_header">
        <h2 className="admission-approvals-item_title">
          {approvalProcess?.name ?? 'No name'}
        </h2>
        <ul className="admission-approvals-item_stages admission-approvals-stages ">
          {approvalProcess?.approvalProcessStage?.map((item, idx) => {
            return (
              <ApprovalStage
                key={idx}
                title={'Level ' + item?.level}
                subtitle={
                  item?.approvalType === 'single'
                    ? 'Single Approval'
                    : 'Multiple Approval'
                }
                approvers={item?.processStageApprover}
              />
            );
          })}
          <li className="admission-approvals-stages_column">
            <h4
              className="admission-approvals-stages_title"
              style={{ lineHeight: '2.25rem', fontWeight: '700' }}
            >{`Level ${approvalProcess?.approvalProcessStage?.length + 1}`}</h4>
            <div className="admission-approvals-stages_list">
              <div
                className="admission-approvals-item_btn-plus mb-[0.1875rem]"
                onClick={openEditStageHandler}
              >
                <span>+</span>
              </div>
            </div>
            <h6
              className="admission-approvals-stages_subtitle"
              style={{ lineHeight: '1.75rem', fontWeight: '700' }}
            >
              Not Required
            </h6>
          </li>
        </ul>
        <button
          onClick={accordionOpenHandler}
          className="admission-approvals-item_btn none-after"
        >
          <span></span>
        </button>
      </div>
      {isOpen && (
        <div className="admission-approvals-item_body">
          <ApprovalEdit
            approvalProcess={approvalProcess}
            setApprovalProcess={setApprovalProcess}
            setIsEditLogicProcess={setIsEditLogicProcess}
            setIsEditLogicStage={setIsEditLogicStage}
            listAction={listAction}
          />
          <div className="w-full flex justify-end items-end mt-2">
            <button
              className="border border-main-blue text-main-blue mr-4 pt-2 pb-2.5 w-40 rounded"
              onClick={() => {
                handleCancel();
                accordionOpenHandler();
              }}
            >
              Cancel
            </button>
            <button
              className="bg-main-blue text-white pt-2.5 mr-10 pb-2.5 w-40 rounded"
              onClick={() => {
                handlerClickSave(isEditLogicProcess, isEditLogicStage);
                accordionOpenHandler();
              }}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

ApprovalItem.propTypes = {
  title: PropTypes.string,
};

ApprovalItem.defaultProps = {
  title: 'Approval Title',
};

export default ApprovalItem;
