export const listTime = [
  { label: '11:00 - 11:30 AM', value: [11.0, 11.5] },
  { label: '11:30 - 12:00 PM', value: [11.5, 12.0] },
  { label: '12:00 - 12:30 PM', value: [12.0, 12.5] },
  { label: '12:30 - 13:00 PM', value: [12.5, 13.0] },
  { label: '13:00 - 13:30 PM', value: [13.0, 13.5] },
  { label: '13:30 - 14:00 PM', value: [13.5, 14.0] },
  { label: '14:00 - 14:30 PM', value: [14.0, 14.5] },
  { label: '14:30 - 15:00 PM', value: [14.5, 15.0] },
  { label: '15:00 - 15:30 PM', value: [15.0, 15.5] },
  { label: '15:30 - 16:00 PM', value: [15.5, 16.0] },
  { label: '16:00 - 16:30 PM', value: [16.0, 16.5] },
  { label: '16:30 - 17:00 PM', value: [16.5, 17.0] },
  { label: '17:00 - 17:30 PM', value: [17.0, 17.5] },
  { label: '17:30 - 18:00 PM', value: [17.5, 18.0] },
];
