import PropTypes from 'prop-types';
import React from 'react';

import './input.scss';

const BloomInput = ({ value, title, id, placeholder, type, onChange, onKeyDown }) => {
  return (
    <div className="bloom-input">
      <label htmlFor={id}>{title}</label>
      <input
        id={id}
        value={value}
        type={type}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
      />
    </div>
  );
};

BloomInput.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
};

BloomInput.defaultProps = {
  title: 'input text',
  placeholder: '',
  type: 'text',
};

export default BloomInput;
