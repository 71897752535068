import { useState, useEffect } from 'react'

const useCountDown = (time) => {
  const [isStop, setIsStop] = useState(false);
  const [duration, setDuration] = useState(time);
  const [isRunning, setIsRunning] = useState(false);

  const start = () => {
    setIsRunning(true);
  };
  const stop = () => {
    setIsStop(true);
    setIsRunning(false);
  };
  const reset = () => {
    setIsRunning(false);
    setDuration(time)
    setIsStop(false);
  };

  const resume = () => {
    setIsRunning(true);
    setIsStop(false);
  };

  useEffect(() => {
    if (isRunning === true) {
      let timer = duration
      const interval = setInterval(function () {
        setDuration(prev => {
          if (prev <= 0) {
            reset()
            return timer
          } else return prev - 1
        })
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isRunning]);

  return { start, stop, reset, resume, duration, isStop, isRunning }
}

export default useCountDown