import React, { memo } from 'react';
import ContentGroup from '../content-group/content-group';

const SchoolServices = memo((props) => {
  const { info, listField, handleChange } = props;
  return listField.map((item, key) => {
    return (
      <ContentGroup
        key={`SchoolServices${key}`}
        data={item}
        info={info?.[item.name]}
        handleChange={(e) => {
          handleChange({ name: 'schoolServices', key: item.name, value: e });
        }}
      />
    );
  });
});
SchoolServices.displayName = 'SchoolServices';
export default SchoolServices;
