import * as _ from 'lodash';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { ToastMessageContext } from '../../../../context/toast-context';
import { checkErrorSingleApi } from '../../../../utils/check-error/api-error';
import './information.scss';
import {
  AddressInformation,
  ParentInformation,
  PersonalInformation,
} from './subcomponents';
import {
  getDataApi,
  getListField,
  handleAddElement,
  handleChangeField,
  handleRemoveElement,
  syncObjValue,
  scroll,
} from '@helper/application';
import FirstStep from './subcomponents/first-step-edit';
import SecondStep from './subcomponents/second-step-edit';
import { useListProgramAndClass, useListYear } from '@utils/custom-hook';
import Loading from '@components/loading';
import admissionApi from 'api/admission';

export default function Information(props) {
  const {
    burger,
    enquiry,
    isCompletedOrClosed,
    handleNextStage,
    enquiryParent,
    handleRefreshEnquiry = () => { },
  } = props;
  const [edit, setEdit] = useState(false);
  const [info, setInfo] = useState({});
  const [step, setStep] = useState(1);
  const [isAddApplication, setIsAddApplication] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const parentUserIdAlreadyExists = useRef({});
  const parentUserIdChosen = useRef({});
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const refOrigin = useRef();

  const { data, enquiryId, campusId, hasAdmissionResult } = useMemo(() => {
    const {
      academicYear,
      campus,
      programGrade,
      programGradeId,
      classData,
      info: infoAdmission,
      admissionResult,
      id: enquiryId,
    } = enquiry;
    let hasAdmissionResult = false;
    let data = {};
    if (admissionResult) {
      hasAdmissionResult = true;
      const admissionInfo = JSON.parse(admissionResult.info);
      const { application } = admissionInfo;
      const { additionalInfo, applicationParent, info: infoApplication } = application;
      const {
        addressInfo,
        schoolInfo,
        healthInfo,
        languageInfo,
        learningInfo,
        schoolServices,
        otherQuestions,
      } = additionalInfo;
      const newApplicationParent = applicationParent.map((item) => {
        const { fullName, email, phoneNumber, gender, photoURL, parentUserId } = item;
        parentUserIdAlreadyExists.current[parentUserId] = parentUserId;
        parentUserIdChosen.current[parentUserId] = parentUserId;
        return {
          ...item,
          infoOrigin: {
            fullName,
            email,
            phoneNumber,
            gender: gender?.value || gender,
            photoURL: photoURL || '',
            parentUserId,
          },
        };
      });
      syncObjValue({
        languageInfo,
        learningInfo,
        schoolServices,
        newApplicationParent,
        infoApplication,
      });
      data = {
        studentInfo: {
          ...infoApplication,
          year: { value: academicYear.id, label: academicYear?.name },
          programGrade: { value: programGradeId, label: `${classData.name} (${programGrade.grade.name})` },
        },
        parentInfo: newApplicationParent || [{}],
        addressInfo: addressInfo || [{}],
        schoolInfo: schoolInfo || [{}],
        healthInfo: healthInfo || {},
        languageInfo: languageInfo || [{}],
        learningInfo: learningInfo || {},
        schoolServices: schoolServices || {},
        otherQuestions: otherQuestions || {},
      };
      // sync object value
    } else {
      const { firstName, lastName, avatar, email, phoneNumber } = infoAdmission;
      const studentInfo = {
        firstName,
        lastName,
        avatar,
        email,
        phoneNumber,
        dateOfBirth: new Date(),
        campus: campus.name,
        year: { value: academicYear.id, label: academicYear?.name },
        programGrade: { value: programGradeId, label: `${classData.name} (${programGrade.grade.name})` },
      };
      const parentInfo = { ...enquiryParent }
      syncObjValue({ parentInfo });
      data = {
        studentInfo,
        parentInfo: [parentInfo],
        addressInfo: [{}],
        schoolInfo: [{}],
        healthInfo: {},
        languageInfo: [{}],
        learningInfo: {},
        schoolServices: {},
        otherQuestions: {},
      };
    }
    setInfo(data);
    refOrigin.current = JSON.stringify(data);
    return { data, enquiryId, campusId: campus.id, hasAdmissionResult };
  }, [enquiry]);

  const { data: listProgramGrade } = useListProgramAndClass({ campusId, year: info?.studentInfo?.year?.label });
  const { data: listYear } = useListYear({ campusId });

  const listField = useMemo(() => {
    return getListField({ listProgramGrade, listYear });
  }, [listProgramGrade, listYear]);

  const handleChange = ({ name, order, objValue, key, value }) => {
    // objValue: object key:value
    handleChangeField({ setInfo, name, order, objValue, key, value });
  };

  const handleAdd = ({ name }) => {
    handleAddElement({ setInfo, name });
  };

  const handleCancel = () => {
    setEdit(false);
    setInfo(JSON.parse(refOrigin.current));
    setStep(1);
  };

  // const handleCancelFirstStep = useCallback(() => {
  //   const original = JSON.parse(refOrigin.current);
  //   const { studentInfo, parentInfo, addressInfo, schoolInfo } = original;
  //   setInfo((prev) => ({ ...prev, studentInfo, parentInfo, addressInfo, schoolInfo }));
  // }, []);

  // const handleCancelSecondStep = useCallback(() => {
  //   const original = JSON.parse(refOrigin.current);
  //   const { healthInfo, languageInfo, learningInfo, schoolServices, otherQuestions } =
  //     original;
  //   setInfo((prev) => ({
  //     ...prev,
  //     healthInfo,
  //     languageInfo,
  //     learningInfo,
  //     schoolServices,
  //     otherQuestions,
  //   }));
  // }, []);

  const handleRemove = ({ name, order }) => {
    handleRemoveElement({ setInfo, name, order, parentUserIdChosen });
  };

  const handleNext = () => {
    setStep((prev) => prev + 1);
    scroll();
  };

  const handlePrev = () => {
    setStep((prev) => prev - 1);
    scroll();
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      if (_.isEqual(JSON.parse(refOrigin.current), info) || isCompletedOrClosed) {
        handleNextStage();
        return;
      }
      const dataApi = await getDataApi({ info, enquiryId, parentUserIdChosen });
      const api = await admissionApi.updateApplication({ campusId }, dataApi);
      if (
        checkErrorSingleApi(
          api,
          setToastMessage,
          setIsShowToastMessage,
          'Submit Application',
        )
      ) {
        setIsAddApplication(true);
        // setIsLoading(false);
        // handleRefreshEnquiry()
      } else throw new Error(api.data.message);
    } catch (error) {
      setToastMessage({
        status: 'error',
        title: 'Submit Application',
        message: error?.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
      setIsLoading(false);
    }
  };

  const styles = {
    btnBlue: {
      width: '10.563rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      backgroundColor: '#404eed',
      height: '2.563rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      marginLeft: '2rem',
    },
    btnWhite: {
      width: '6.563rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#404eed',
      backgroundColor: 'white',
      height: '2.563rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      border: '1px solid #404eed',
    },
    input: {
      height: '2.5rem',
    }
  }

  const mapStep = {
    1: (
      <FirstStep
        enquiry={enquiry}
        info={info}
        listField={listField}
        setStep={setStep}
        setInfo={setInfo}
        styles={styles}
        parentUserIdChosen={parentUserIdChosen}
        handleChange={handleChange}
        handleAdd={handleAdd}
        handleCancel={handleCancel}
        handleNext={handleNext}
        handleRemove={handleRemove}
      />
    ),
    2: (
      <SecondStep
        info={info}
        listField={listField}
        styles={styles}
        handleChange={handleChange}
        handleAdd={handleAdd}
        handleCancel={handleCancel}
        handleSave={handleSave}
        handlePrev={handlePrev}
        handleRemove={handleRemove}
        enquiry={enquiry}
        isAddApplication={isAddApplication}
        handleRefreshEnquiry={handleRefreshEnquiry}
      />
    )
  }

  if (edit) {
    return (
      <div className="flex w-full">
        <div className="mx-auto">{mapStep[step]}</div>
        {isLoading && (
          <div className="fixed inset-0 bg-[#33333380] flex items-center justify-center">
            <Loading />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="w-full flex">
      <div className="information-field mx-auto">
        <PersonalInformation
          burger={burger}
          setEdit={setEdit}
          listField={listField}
          isDisableEditApplication={false}
          hasAdmissionResult={hasAdmissionResult}
          data={{
            studentInfo: data.studentInfo,
            schoolInfo: data.schoolInfo,
          }}
        />
        {data.parentInfo.length !== 0 && (
          <ParentInformation
            burger={burger}
            listField={listField.parent}
            data={data.parentInfo}
          />
        )}

        {data.addressInfo.length !== 0 && hasAdmissionResult && (
          <AddressInformation
            burger={burger}
            listField={listField.address}
            data={data.addressInfo}
          />
        )}
      </div>
    </div>
  );
}
