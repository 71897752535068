import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal';
import {
  AddWidget,
  Appointments,
  Charts,
  DashboardTable,
  EnqurCard,
  PercentCard,
  Stats,
  Tasks,
  TopReferals,
  TotalCards,
} from '../../components/admission-module/dashboard';
import {
  checkErrorMultipleApiFetch
} from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { DashboardHeader } from '../../components/admission-module/dashboard';
import './dashboard.scss';
import admissionApi from 'api/admission';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';

export default function Dashboard({ burger }) {
  const [listAcademicYear, setListAcademicYear] = useState([]);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState();
  const [dataTotal, setDataTotal] = useState({});
  const [listTopCounselors, setListTopCounselors] = useState([]);
  const [listTopReferals, setListTopReferals] = useState([]);
  const [achievement, setAchievement] = useState('');
  const [listCountApplication, setListCountApplication] = useState();
  const [listCountEnquiry, setListCountEnquiry] = useState();
  const [listCountEnrolment, setListCountEnrolment] = useState();
  const [totalActiveEnquiryInThisWeek, setTotalActiveEnquiryInThisWeek] = useState(0);
  const [totalActiveEnquiry, setTotalActiveEnquiry] = useState(0);
  const [totalEnquiry, setTotalEnquiry] = useState(0);
  const [totalConversion, setTotalConversion] = useState(0);
  const [totalApplication, setTotalApplication] = useState(0);
  const [totalFailEnquiry, setTotalFailEnquiry] = useState(0);
  const [totalFailEnquiryInThisWeek, setTotalFailEnquiryInThisWeek] = useState(0);
  const [timeToConversionInThisWeek, setTimeToConversionInThisWeek] = useState('');
  const [timeToConversionInBeforeWeek, setTimeToConversionInBeforeWeek] = useState('');
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campus = useStorage((state) => state.currentCampus);

  useEffect(() => {
    const getData = async () => {
      try {
        const campusId = campus?.isCentral ? undefined : campus?.id
        const getAcademicYear = centralAdminApi.getListAcademicYear({ campusId })
        const getChart = admissionApi.getChart({ campusId })
        const res = await Promise.all([getAcademicYear, getChart])
        if (checkErrorMultipleApiFetch(res, setToastMessage, setIsShowToastMessage)) {
          if (res[0]?.data?.data?.objects && Array.isArray(res[0]?.data?.data?.objects)) {
            const listAca = res[0]?.data.data.objects.map((dt) => {
              return {
                value: dt.name,
                label: `${dt.name} Academic Year`,
              };
            });
            if (listAca?.length) {
              setListAcademicYear(listAca);
              const currentDate = new Date()
              const currentYear = currentDate.getFullYear()
              const startDate = new Date(`08-01-${currentYear} 00:00:00`)
              const endDate = new Date(`07-31-${currentYear + 1} 23:59:59`)
              if (startDate <= currentDate <= endDate) {
                for (let academic of listAca) {
                  if (academic?.value === `${currentYear}-${currentYear + 1}`) {
                    setSelectedAcademicYear(academic)
                    break;
                  }
                  else setSelectedAcademicYear(listAca[listAca.length - 1]);
                }
              } else
                setSelectedAcademicYear(listAca[listAca.length - 1]);
            }
          }
          if (
            res[1]?.data?.data?.listCountApplication &&
            Array.isArray(res[1]?.data?.data?.listCountApplication)
          ) {
            setListCountApplication(res[1].data.data.listCountApplication);
          }
          if (
            res[1]?.data?.data?.listCountEnquiry &&
            Array.isArray(res[1]?.data?.data?.listCountEnquiry)
          ) {
            setListCountEnquiry(res[1].data.data.listCountEnquiry);
          }
          if (
            res[1]?.data?.data?.listCountEnrolment &&
            Array.isArray(res[1]?.data?.data?.listCountEnrolment)
          ) {
            setListCountEnrolment(res[1].data.data.listCountEnrolment);
          }
        }
      } catch (error) {
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Admission Dashboard', error);
      }
    }
    getData();
  }, [campus?.id]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (!selectedAcademicYear) return
        const campusId = campus?.isCentral ? undefined : campus?.id
        const academicYear = selectedAcademicYear.value
        const getTotal = admissionApi.getTotalEnquiry({ campusId, academicYear })
        const getTopCounselors = admissionApi.getTopCounselors({ campusId, academicYear })
        const getTopReferral = admissionApi.getTopReferral({ campusId, academicYear })
        const getMyData = admissionApi.getMyData({ campusId, academicYear })
        const res = await Promise.all([getTotal, getTopCounselors, getTopReferral, getMyData])
        if (checkErrorMultipleApiFetch(res, setToastMessage, setIsShowToastMessage, 'Admission Dashboard')) {
          if (res[0]?.data?.success && res[0]?.data?.data) {
            setDataTotal(res[0].data.data);
          }
          if (res[1]?.data?.success && res[1]?.data?.data) {
            setListTopCounselors(res[1].data.data);
          }
          if (res[2]?.data?.success && res[2]?.data?.data) {
            setListTopReferals(res[2].data.data.list || []);
            setAchievement(res[2].data.data.achievement || '');
          }
          if (res[3]?.data?.success && res[3]?.data?.data) {
            setTotalActiveEnquiry(res[3].data.data.totalActiveEnquiry || 0);
            setTotalEnquiry(res[3].data.data.totalEnquiry || 0);
            setTotalConversion(res[3].data.data.totalConversion || 0);
            setTotalApplication(res[3].data.data.totalApplication || 0);
            setTotalFailEnquiry(res[3].data.data.totalFailEnquiry || 0);
            setTotalFailEnquiryInThisWeek(
              res[3].data.data.totalFailEnquiryInThisWeek || 0,
            );
            setTotalActiveEnquiryInThisWeek(
              res[3].data.data.totalActiveEnquiryInThisWeek || 0,
            );
            setTimeToConversionInThisWeek(res[3].data.data.timeToConversionInThisWeek || '');
            setTimeToConversionInBeforeWeek(res[3].data.data.timeToConversionInBeforeWeek || '');
          }
        }
      } catch (error) {
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Admission Dashboard - Get Total Enquiry', error);

      }
    }
    getData();
  }, [selectedAcademicYear]);

  const onSelectedAcademicYear = (value) => {
    if (value) {
      setSelectedAcademicYear(value);
    }
  };

  const handleCalcPercent = useCallback((a, b) => {
    if (b === 0) {
      return b * 100;
    }
    return Math.round(a * 10000 / b) / 100;
  }, []);

  return (
    <React.Fragment>
      <div className={clsx('dashboard px-20 1400px:pr-16', burger ? '1400px:pl-[19rem]' : '1400px:pl-32')}
      >
        <div className="mt-10">
          <DashboardHeader
            onSelectedAcademicYear={onSelectedAcademicYear}
            listAcademicYear={listAcademicYear}
            selectedAcademicYear={selectedAcademicYear}
          />
        </div>
        <Fade clear duration={300}>
          {/* <div className={clsx('grid-container', burger ? 'pr-0' : '1400px:pr-8')}> */}
          <div
            className={clsx('grid-container transition-all-300', burger ? '' : 'xl:ml-0')}
          >
            <TotalCards dataTotal={dataTotal} />
            <Tasks />
            <Charts
              listCountApplication={listCountApplication}
              listCountEnquiry={listCountEnquiry}
              listCountEnrolment={listCountEnrolment}
            />
            <Appointments campus={campus} />
            <div className="flex 1620px:flex-row flex-col w-full grid-container__item--5">
              <DashboardTable listTopCounselors={listTopCounselors} />
              <div className="grid 1260px:grid-cols-2 grid-cols-3 ml-24 1260px:ml-0 2xl:block 2xl:ml-0 gap-x-4 w-full">
                <PercentCard
                  percentage={handleCalcPercent(totalConversion, totalEnquiry)}
                  num={totalConversion}
                  title="My Conversions"
                  variant="conv"
                  progressColor="#008060"
                />
                <PercentCard
                  percentage={handleCalcPercent(totalApplication, totalEnquiry)}
                  num={totalApplication}
                  title="Application Issued"
                  variant="apl"
                  progressColor="#2D9CDB"
                />
                <PercentCard
                  percentage={handleCalcPercent(totalActiveEnquiry, totalEnquiry)}
                  num={totalActiveEnquiry}
                  title="My Active Enquiries"
                  variant="enq"
                  progressColor="#F2994A"
                  date={`${totalActiveEnquiryInThisWeek} added this week`}
                />
                {/* <EnqurCard
                  title="My Active Enquiries"
                  enqiries="120"
                  date="5 added this week"
                /> */}
                <EnqurCard
                  title="My Failed Enquiries"
                  enqiries={totalFailEnquiry}
                  date={`${totalFailEnquiryInThisWeek} added this week`}
                  isRotate={false}
                />
                <EnqurCard
                  title="Time to Conversion"
                  enqiries={timeToConversionInThisWeek}
                  date={timeToConversionInBeforeWeek}
                  isRotate={timeToConversionInBeforeWeek.includes('Down')}
                />
              </div>
            </div>
            <TopReferals achievement={achievement} listTopReferals={listTopReferals} />
            <Stats />
            <AddWidget />
          </div>
        </Fade>
      </div>
      {/* <ApplicationPanel /> */}
    </React.Fragment>
  );
}

Dashboard.propTypes = {
  burger: PropTypes.bool,
};
