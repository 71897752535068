import React from 'react';
import clsx from 'clsx';
import GettingStart from './components/getting-start/getting-start';
import './institutions.scss';

export default function Institutions({ burger, isOpen }) {
  return (
    <div className={clsx("institutions pl-[8rem] pr-24 pt-16 transition-all-300", burger ? '1400px:pl-[19rem]' : '1400:pl-40')} >
      <GettingStart burger={burger} />
    </ div>
  );
}
