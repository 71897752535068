import SignatureView from './PDF/SignatureView';
import React, { useState, useContext, useEffect } from 'react';

function ApplicationSignature(props) {
  const { application, showPrint, campus, date, fee, urlSignatureFile } = props;
  const [applicationInfo, setApplicationInfo] = useState(application);

  useEffect(() => {
    setApplicationInfo(application);
  }, [application]);
  // useEffect(() => {
  //   if (application?.id) {
  //     callApi({
  //       method: 'get',
  //       url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/application/${application.id}`,
  //     })
  //       .then((results) => {
  //         if (results?.data?.success && results.data.success) {
  //           // console.log(results.data.data);
  //           setApplicationInfo(results.data.data);
  //         } else {
  //           setToastMessage({
  //             status: 'error',
  //             title: 'Get Application Failed',
  //             message: results.data.message,
  //           });
  //           setIsShowToastMessage(true);
  //         }
  //       })
  //       .catch((error) => {
  //         setToastMessage({
  //           status: 'error',
  //           title: 'Get Application Failed',
  //           message: error.response?.data?.message || error,
  //         });
  //         setIsShowToastMessage(true);
  //         console.log('Get Application Error', error);
  //       });
  //   }
  // }, [application]);

  return (
    <div
      style={{
        width: '100%',
        height: '55.438rem',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {applicationInfo && campus && (
        <SignatureView
          fee={fee}
          date={date}
          campus={campus}
          applicationInfo={applicationInfo}
          showPrint={showPrint}
          urlSignatureFile={urlSignatureFile}
        />
      )}
    </div>
  );
}

export default ApplicationSignature;
