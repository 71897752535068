import { mainavatar } from '@static/image';
import './bank-account-item.scss';
import clsx from 'clsx';
import useStorage from 'store/storage';

const url =
  'https://storage.googleapis.com/giiki-bucket/media/$2b$10$PmkWB2YaKx6JFF7RKSExOeXIdJ3Vhe41eM7CK78YgSil4ZwiH722';
const length = 7;
const arr = new Array(length);

function BankItem(props) {
  const { bankAccount, listCampus = [] } = props;

  const central = useStorage((state) => state.currentCampus);

  return (
    <>
      {bankAccount && (
        <div className="bank-item-container">
          <div className="bank-item-name">
            <CleaningTeamIcon />
            <div className="ml-2.5">
              <p className="text-sm leading-[17.5px] font-semibold">
                {bankAccount?.accountNickName || 'Bank Account NickName'}
              </p>
              <p className="text-[10px] leading-[14px] font-medium">
                HDFC Bank {bankAccount?.branchIFSCCode || 'xxxx'} |{' '}
                {central?.city || central?.state || central?.country || 'City'}
              </p>
            </div>
          </div>
          <LineIcon />
          <div className="flex flex-col">
            <p className="text-sm leading-[17.5px] font-semibold m-auto mb-1">Status</p>
            <p
              className={clsx(
                'text-[10px] leading-[13.5px] font-bold m-auto px-2 py-1 rounded-xl ',
                bankAccount?.status === 'Active'
                  ? 'bg-[#F1F8F5] text-[#6FCF97]'
                  : 'bg-[#ffe2e1] text-[#B72136]',
              )}
            >
              {bankAccount?.status}
            </p>
          </div>
          <LineIcon />
          <div className="flex flex-col">
            <p className="text-sm leading-[17.5px] font-semibold mb-1">Business Type</p>
            <p className="text-[#347C84] text-sm leading-[17.5px] font-semibold m-auto">
              {bankAccount?.businessType === 'private_limited'
                ? 'Private LTD'
                : 'Society'}
            </p>
          </div>
          <LineIcon />
          <div className="flex flex-col">
            <p className="text-sm leading-[17.5px] font-semibold mb-1">Campuses</p>
            <div className="flex -space-x-2 items-center">
              {listCampus.length > 0 && listCampus.length < 4 ? (
                listCampus.map((campus, idx) => {
                  return (
                    <img
                      key={campus.id || idx}
                      className="inline-block h-8 w-8 h-8 rounded-full ring-2 ring-white"
                      src={campus.logo || mainavatar}
                      alt=""
                    />
                  );
                })
              ) : listCampus.length >= 4 ? (
                [0, 1, 2, 3].map((key) => {
                  return key !== 3 ? (
                    <img
                      key={key}
                      className="inline-block h-8 w-8 h-8 rounded-full ring-2 ring-white"
                      src={listCampus[key]?.logo || mainavatar}
                      alt=""
                    />
                  ) : (
                    <div
                      key={key}
                      className="flex items-center justify-center bg-green-200 text-sm h-8 w-8 h-8 rounded-full ring-2 ring-white"
                    >
                      +{listCampus.length - 3}
                    </div>
                  );
                })
              ) : (
                <div>-</div>
              )}
            </div>
          </div>
          <div className="p-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="3"
              height="13"
              viewBox="0 0 3 13"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.99992 2.08333C2.99992 2.81971 2.40296 3.41667 1.66659 3.41667C0.930206 3.41667 0.333252 2.81971 0.333252 2.08333C0.333252 1.34695 0.930206 0.75 1.66659 0.75C2.40296 0.75 2.99992 1.34695 2.99992 2.08333ZM2.99992 6.75C2.99992 7.48638 2.40296 8.08333 1.66659 8.08333C0.930206 8.08333 0.333252 7.48638 0.333252 6.75C0.333252 6.01362 0.930206 5.41667 1.66659 5.41667C2.40296 5.41667 2.99992 6.01362 2.99992 6.75ZM1.66659 12.75C2.40296 12.75 2.99992 12.153 2.99992 11.4167C2.99992 10.6803 2.40296 10.0833 1.66659 10.0833C0.930206 10.0833 0.333252 10.6803 0.333252 11.4167C0.333252 12.153 0.930206 12.75 1.66659 12.75Z"
                fill="#637381"
              />
            </svg>
          </div>
        </div>
      )}
    </>
  );
}

export default BankItem;

const LineIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2"
      height="68"
      viewBox="0 0 2 68"
      fill="none"
    >
      <path opacity="0.3" d="M1.33326 67.5L1.33325 0" stroke="#B0B6BE" />
    </svg>
  );
};

const CleaningTeamIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
    >
      <rect y="0.75" width="30" height="30" rx="15" fill="#F3EEDA" />
      <path
        d="M7.49695 23.2531V22.9329C7.49695 21.7406 8.33854 20.714 9.50779 20.4802L11.8804 20.0059C12.0765 19.9667 12.2801 20.0068 12.4465 20.1176V20.1176C13.9927 21.1489 16.0075 21.1489 17.5536 20.1176V20.1176C17.7201 20.0068 17.9237 19.9667 18.1197 20.0059L20.4924 20.4802C21.6616 20.714 22.5032 21.7406 22.5032 22.9329V23.2531"
        stroke="#98D3CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2001 17.4173H19.1684C19.859 17.4173 20.4189 16.8574 20.4189 16.1667V12.832C20.4189 9.83922 17.9928 7.41309 15 7.41309V7.41309C12.0072 7.41309 9.58105 9.83922 9.58105 12.832V16.1667C9.58105 16.8574 10.1409 17.4173 10.8316 17.4173H12.7999"
        stroke="#98D3CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3326 11.2637H16.6674C17.5882 11.2637 18.3347 12.0102 18.3347 12.931V14.916C18.3347 16.7577 16.8417 18.2507 15 18.2507V18.2507C13.1583 18.2507 11.6653 16.7577 11.6653 14.916V12.931C11.6653 12.0102 12.4118 11.2637 13.3326 11.2637Z"
        stroke="#98D3CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8317 23.2528H19.1685"
        stroke="#98D3CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3347 23.253V20.0483"
        stroke="#98D3CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6653 23.253V20.0483"
        stroke="#98D3CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7515 17.7549L17.371 20.2326"
        stroke="#98D3CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2501 17.7549L12.6307 20.2326"
        stroke="#98D3CB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
