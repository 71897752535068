import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import Fade from 'react-reveal'
import {
  Charts,
  Stats,
  PercentCard,
  DashboardTable,
  EnqurCard,
  TotalCards,
  AddWidget,
  TopReferals,
  Tasks,
  Appointments
} from '../../../components/admission-module/dashboard'
import TotalDataCards from '../../giiki-admin-reports/components/total-data-cards/total-data-cards'
import DataGiikiCharts from '../../giiki-admin-reports/components/data-giiki-charts/data-giiki-charts'
import { dataTabs } from '../../../entities/routes'
import { NavTabs } from '../../Header/components/index'
import ApplicationPanel from '../../../components/application-panel/application-panel'
import { DashboardHeader } from '../../../components/admission-module/dashboard'
import './reports.scss'

export default function Reports({ burger }) {

  return (
    <React.Fragment>
      <div className={clsx('report px-12', burger ? '1400px:pl-72' : '1400px:pl-32')}>
        <h1 className='namepage'>Usage Reports</h1>
        <NavTabs tabs={dataTabs.giikiReportTabs} />
        <Fade clear duration={300}>
          <TotalDataCards />
          <DataGiikiCharts />
          {/* </div> */}
        </Fade>
      </div>
    </React.Fragment>
  )
}

Reports.propTypes = {
  burger: PropTypes.bool
}
