import React, { useEffect, useState } from "react"
import { arrow_in_circle } from "@components/application-panel/image"
import { createTheme, ThemeProvider } from '@mui/material/styles'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import StaticDatePicker from '@mui/lab/StaticDatePicker'
import { ClickAwayListener, TextField } from "@mui/material"
import Triangle from "./Triangle"
import SingleDay from "./SingleDay"
import SingleTime from "./SingleTime"
import convertDateToFormat from "@helper/convertDateToFormat"


const HorizontalCalendar = (props) => {
  const { info, setInfo, scheduleByDate } = props
  const [openCalendar, setOpenCalendar] = useState(false)
  const [currentDate, setCurrentDate] = useState(new Date())
  const [currentWeek, setCurrentWeek] = useState(0)
  const [maxWeek, setMaxWeek] = useState(0)
  const [dayInMonth, setDayInMonth] = useState([])
  const [infoDate, setInfoDate] = useState({})

  const days = {
    0: 'Sun',
    1: 'Mon',
    2: 'Tue',
    3: 'Wen',
    4: 'Thur',
    5: 'Fri',
    6: 'Sat'
  }
  const times = ['9:00 am', '10:00 am', '11:00 am', '12:00 pm', '1:00 pm', '2:00 pm', '3:00 pm', '4:00 pm', '5:00 pm']
  const countDateInWeek = 7
  const stringDate = currentDate ? `${currentDate?.toLocaleString('default', { month: 'long' })} ${currentDate.getFullYear()}` : ''

  const onClickCalendar = () => {
    setOpenCalendar(prev => !prev)
  }

  const onClickToday = () => {
    setCurrentDate(new Date())
  }

  const onNextWeek = () => {
    setCurrentWeek(prev => {
      if (prev === maxWeek) {
        return prev
      } else return prev + 1

    })
  }

  const onPreviousWeek = () => {
    setCurrentWeek(prev => {
      if (prev === 0) {
        return prev
      } else return prev - 1
    })
  }

  const onClickDate = (data) => {
    setInfoDate(data)
    setInfo(prev => ({ ...prev, date: data.fullDate }))
  }

  const onClickTime = (data) => {
    setInfo(prev => ({ ...prev, time: data }))
  }

  const theme = createTheme({
    palette: {
      primary: { main: '#27C59A' }
    }
  })

  const dateStyles = {
    focus: { date: 'text-[#D9E6E9] bg-[#00E6AC]', day: 'text-[#212B36] font-bold' },
    available: { date: 'text-[#8C9094] bg-[#0E353D]', day: 'text-[#8C9094] font-medium' },
    full: { date: 'text-[#8C9094] bg-[#919EAB52]', day: 'text-[#8C9094] font-medium' }
  }

  const timeStyles = {
    normal: { fill: '#000000', color: '#8C9094', bg: '#FFFFFF', borderColor: '#8C9094' },
    chosen: { fill: '#FFFFFF', color: '#FFFFFF', bg: '#00E6AC', borderColor: '#00E6AC' }
  }

  const triangleStyles = {
    leftUnavailable: {
      bg: '#B0B6BE',
      rotate: 'rotate-0'
    },
    rightUnavailable: {
      bg: '#B0B6BE',
      rotate: 'rotate-180'
    },
    leftAvailable: {
      bg: '#000000',
      rotate: 'rotate-0'
    },
    rightAvailable: {
      bg: '#000000',
      rotate: 'rotate-180'
    }
  }

  const getDayInMonth = (current) => {
    const dayInMonth = []
    const date = current, y = date.getFullYear(), m = date.getMonth();
    const firstDate = new Date(y, m, 1);
    const lastDate = new Date(y, m + 1, 0);

    // first week og month
    switch (firstDate.getDay()) {
      case 0:
        dayInMonth.push({ day: days[6] })
        break;
      case 1:
        dayInMonth.push({ day: days[6] }, { day: days[0] })
        break;
      case 2:
        dayInMonth.push({ day: days[6] }, ...Array.from({ length: 2 }, (_, i) => ({ day: days[i] })))
        break;
      case 3:
        dayInMonth.push({ day: days[6] }, ...Array.from({ length: 3 }, (_, i) => ({ day: days[i] })))
        break;
      case 4:
        dayInMonth.push({ day: days[6] }, ...Array.from({ length: 4 }, (_, i) => ({ day: days[i] })))
        break;
      case 5:
        dayInMonth.push({ day: days[6] }, ...Array.from({ length: 5 }, (_, i) => ({ day: days[i] })))
        break;
      default:
        break;
    }

    for (let i = 1; i <= lastDate.getDate(); i++) {
      const newDate = new Date(firstDate);
      newDate.setDate(newDate.getDate() + (i - 1));
      const date = convertDateToFormat(newDate, 'MM/DD/YYYY')

      const obj = {
        fullDate: date,
        date: newDate.getDate(),
        day: days[newDate.getDay()],
        timeAvailable: scheduleByDate?.[date] ? times.filter(time => !scheduleByDate[date].includes(time)) : times,
        isHoliday: false
      }

      dayInMonth.push(obj)
    }

    // last week of month
    switch (lastDate.getDay()) {
      case 0:
        dayInMonth.push(...Array.from({ length: 5 }, (_, i) => ({ day: days[i + 1] })))
        break;
      case 1:
        dayInMonth.push(...Array.from({ length: 4 }, (_, i) => ({ day: days[i + 1] })))
        break;
      case 2:
        dayInMonth.push(...Array.from({ length: 3 }, (_, i) => ({ day: days[i + 1] })))
        break;
      case 3:
        dayInMonth.push(...Array.from({ length: 2 }, (_, i) => ({ day: days[i + 1] })))
        break;
      case 4:
        dayInMonth.push({ day: days[5] })
        break;
      case 5:
        break;
      case 6:
        dayInMonth.push(...Array.from({ length: 6 }, (_, i) => ({ day: days[i] })))
      default:
        break;
    }

    return dayInMonth
  }

  useEffect(() => {
    if (currentDate) {
      const tmpDayInMonth = getDayInMonth(currentDate)
      setDayInMonth(tmpDayInMonth)
      setMaxWeek(Math.floor((tmpDayInMonth.length - 1) / 7))

      const index = tmpDayInMonth.findIndex(item => item.date === currentDate.getDate())
      setCurrentWeek(Math.floor(index / countDateInWeek))
      setInfo(prev => ({ ...prev, date: convertDateToFormat(currentDate, 'MM/DD/YYYY') }))
      setInfoDate(tmpDayInMonth[index])
    }
  }, [currentDate, scheduleByDate])


  return (
    <section className="w-full">
      <div className="flex items-center justify-between">
        <button className="flex items-center justify-between"
          onClick={onClickCalendar}>
          <p className="text-[#212B36] text-base font-medium mr-2">{stringDate}</p>
          <img src={arrow_in_circle} alt={'arrow'} className="w-5 h-5" />
        </button>

        <button className="border border-[#979797] text-[#979797] text-xs rounded-full py-1 px-2"
          onClick={onClickToday}
        >
          Today
        </button>
      </div>

      <div className="flex item-center justify-between flex-nowrap mt-4">
        <button className={`${currentWeek === 0 && 'cursor-default pointer-events-none'}`}
          onClick={onPreviousWeek}>
          <Triangle style={currentWeek === 0 ? triangleStyles.leftUnavailable : triangleStyles.leftAvailable} />
        </button>

        {dayInMonth.slice(countDateInWeek * currentWeek, countDateInWeek * (currentWeek + 1)).map((item, index) => {
          let style = dateStyles.available
          if (info?.date === item.fullDate) style = dateStyles.focus
          if (!item?.timeAvailable || item.timeAvailable.length === 0) style = dateStyles.full
          return <SingleDay key={`date${index}`} data={item} style={style} onClick={onClickDate} />
        })}

        <button className={`${currentWeek === maxWeek && 'cursor-default pointer-events-none'}`}
          onClick={onNextWeek}>
          <Triangle style={currentWeek === maxWeek ? triangleStyles.rightUnavailable : triangleStyles.rightAvailable} />
        </button>
      </div>

      {/* <div className="flex flex-wrap gap-x-6 gap-y-3 mt-4 h-[7.05rem]"> */}
      <div className="h-[6.05rem]">
        <div className="grid grid-cols-4 gap-y-3 mt-4">
          {infoDate?.timeAvailable?.map(item => {
            return <SingleTime key={item}
              data={item}
              style={info?.time === item ? timeStyles.chosen : timeStyles.normal}
              onClick={onClickTime} />
          })}
        </div>
      </div>

      {
        openCalendar && (
          <div className="absolute z-10 bg-white top-0 left-0 w-full h-full flex items-center justify-center">
            <ClickAwayListener onClickAway={onClickCalendar}>
              <div style={{ height: 300, overflow: 'hidden' }}>
                <ThemeProvider theme={theme}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StaticDatePicker
                      displayStaticWrapperAs='desktop'
                      openTo='day'
                      minDate={new Date(2019, 11, 24)}
                      maxDate={new Date(2025, 11, 24)}
                      value={currentDate}
                      shouldDisableYear={null}
                      onChange={(newValue) => {
                        setCurrentDate(newValue)
                        onClickCalendar()
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              </div>
            </ClickAwayListener>
          </div>
        )
      }

    </section >
  )
}

export default HorizontalCalendar