import clsx from 'clsx';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastMessageContext } from '../../context/toast-context';

import { callApi } from '../../helper/call-api';
import { CompanyCard, CompanyTabsManagement } from '../../stories';
import Pagination from '../../stories/pagination/pagination';
import { rowsPerPageCount } from '../../utils/storage';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_START } from '../../utils/utils';
import { companyStatus } from '@utils/constant';
import './company.scss';
import authApi from 'api/auth';

export default function Company({ burger, isOpen }) {
  const [typeState, setTypeState] = useState(companyStatus && companyStatus[0]);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const history = useHistory();
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_START);
  const [totalObjectsFromApi, setTotalObjectsFromApi] = useState(0);

  const handleChangeRowsPerPage = (event) => {
    setSelectedRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    // const res = callApi({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/company/list`,
    //   params: {
    //     status: typeState, page: currentPage, pageSize: selectedRowsPerPage,
    //   },
    // });
    const res = authApi.getListCompany({ status: typeState, page: currentPage, pageSize: selectedRowsPerPage, })
    const fetchCompanies = async () => {
      setLoading(true);
      res
        .then((res) => {
          const { data } = res;
          if (data.success) {
            setCompanies(data.data.objects);
            setTotalObjectsFromApi(data.data.total);
            setLoading(false);
          } else {
            setToastMessage({
              status: 'error',
              title: 'Get Companies Failed',
              message: data.message,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Get Companies Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Company Error', error);
        });
    };
    fetchCompanies();
  }, [typeState, currentPage, selectedRowsPerPage]);

  const styles = {
    button: {
      width: '9.813rem',
      height: '2.75rem',
      background: '#663174',
      color: '#F1F8F5',
    },
  };

  return (
    <div>
      <div className={clsx('company px-20 transition-all-300', burger ? '1400px:pl-80' : '1400px:pl-40')}>
        <h1 className="namepage">Company Management</h1>
        <div>
          <div className="flex items-center justify-between w-full  mb-12">
            <div>
              <CompanyTabsManagement
                types={companyStatus}
                setTypeState={setTypeState}
                typeState={typeState}
              />
            </div>
            <button
              onClick={() => {
                history.push('/giiki/institutions/create');
              }}
              className="flex items-center justify-center rounded-lg"
              style={styles.button}
            >
              Create Company
            </button>
          </div>
          {companies.map((company, key) => (
            <CompanyCard info={company} key={key} loading={loading} />
          ))}
          <div className="datatable-footer">
            <Pagination
              customClass="fixed bottom-4 right-0 z-[3] pr-4"
              customStyle={{ height: '3rem' }}
              page={currentPage}
              rowsPerPage={selectedRowsPerPage}
              rowsPerPageCount={rowsPerPageCount}
              totalRows={totalObjectsFromApi}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
