import clsx from 'clsx';
import { useContext, useState } from 'react';
import { Button } from '@stories/index';
import { checkErrorSingleApi } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { statusList } from 'entities/data';
import { toggleButtonStyles } from '../../../../entities/select-style';
import taskApi from 'api/task';
import useStorage from 'store/storage';

function UpdateTaskStatus(props) {
  const {
    initialSelect,
    isSelect,
    setIsSelect,
    task,
    index,
    setRefresh,
    setIsUpdate = () => { },
    setPage = () => { },
  } = props;
  const [status, setStatus] = useState(task?.status);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campus = useStorage((state) => state.currentCampus);

  const handleUpdateTaskStatus = () => {
    taskApi.updateTaskById({ campusId: campus?.isCentral ? undefined : campus?.id }, { status: status }, task.id)
      .then((res) => {
        if (
          checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, 'Update Task')
        ) {
          setIsSelect(initialSelect);
          setRefresh((prev) => ({
            ...prev,
            count: (prev.count || 0) + 1,
            index,
          }));
          setIsUpdate((prev) => !prev);
          setPage(0);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Update Task Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Update task error', error.message);
      });
  };

  return (
    <>
      {isSelect.status && (
        <div className="absolute top-8 left-[-2rem] w-60 z-20 bg-[#fff] shadow-2xl rounded">
          <SelectProperty
            status={status}
            list={statusList}
            setStatus={setStatus}
            handleUpdateTaskStatus={handleUpdateTaskStatus}
            setIsSelect={setIsSelect}
            initialSelect={initialSelect}
            property="status"
          />
        </div>
      )}
    </>
  );
}

export default UpdateTaskStatus;

const SelectProperty = ({
  list = [],
  setStatus,
  property,
  status,
  setIsSelect,
  initialSelect,
  handleUpdateTaskStatus,
}) => {
  return (
    <ul className="p-1">
      {list.map((data, index) => {
        return (
          <li
            onClick={() => {
              setStatus(data.status);
            }}
            key={index}
            className={clsx(
              'm-2 text-center rounded text-xs py-2',
              data.status === status ? 'border-2 border-indigo-600' : '',
            )}
            style={{ backgroundColor: data.color, color: '#fff' }}
          >
            {data.status}
          </li>
        );
      })}
      <li>
        <div className="flex justify-center">
          <Button
            className="text-[0.8rem]"
            text="Cancel"
            customStyle={{
              ...toggleButtonStyles.btnWhite,
              width: '7rem',
              height: '1.5rem',
            }}
            onClick={() => {
              setIsSelect(initialSelect);
              // setEnteredTaskName('');
            }}
          />
          <Button
            className="text-[0.8rem]"
            text="Save"
            customStyle={{
              ...toggleButtonStyles.btnBlue,
              width: '7rem',
              height: '1.5rem',
            }}
            onClick={() => {
              handleUpdateTaskStatus();
            }}
          />
        </div>
      </li>
    </ul>
  );
};
