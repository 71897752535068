import clsx from 'clsx';
import PropTypes from 'prop-types';
import { APPLICATION_STEP } from '../../../../utils/utils';
import './application-window.scss';
import DefaultAvatar from '@components/default-avatar';

export default function EnquiriesCard(props) {
  const {
    avatar,
    name,
    py,
    date,
    currentEnquiryId,
    handleChangeEnquiry,
    id,
    currentStep,
  } = props;

  const styles = {
    New: { color: '#229A16', background: 'rgba(84, 214, 44, 0.16)' },
    Application: { color: '#404EED', background: '#EDF4FE' },
    Signature: { color: '#FFCC00', background: 'rgb(255 204 0 / 24%)' },
    Exam: { color: '#FF99FF', background: 'rgb(255 153 255 / 21%)' },
    'Principal Approval': { color: '#996600', background: 'rgb(153 102 0 / 27%)' },
    'Document Verification': { color: '#660066', background: '#66006642' },
    'Fee Payment': { color: '#003366', background: '#00336652' },
    Complete: { color: '#FFFFFF', background: '#2E72D2' },
    Failed: { color: '#EB5757', background: ' #FFE9E8' },
  };

  return (
    <div
      className={clsx(
        'enquiries-card',
        currentEnquiryId === id ? 'enquiries-card_selected' : '',
      )}
      onClick={() => handleChangeEnquiry(id)}
      title={name}
    >
      {avatar ? <img className="rounded-full w-9 h-9" src={avatar} alt="avatar" /> :
        <DefaultAvatar title={name} className='rounded-full w-9 h-9' />}
      <div className="flex ml-4">
        <div>
          <div className="w-[6.5rem]">
            <h3 className="overflow-hidden text-ellipsis whitespace-nowrap">{name}</h3>
          </div>
          <p className="enquiries-card_pattern"
            style={APPLICATION_STEP[currentStep] ? styles[currentStep] : { color: '#54D62C', backgroundColor: '#54D62C29' }}>
            {APPLICATION_STEP[currentStep] || currentStep}
          </p>
        </div>
        <div className="flex flex-col items-center ml-10 enquiries-card_date">
          <h3>{py}</h3>
          <p className="text-second-gray mt-1.5">{date}</p>
        </div>
      </div>
    </div>
  );
}

EnquiriesCard.propTypes = {
  name: PropTypes.string,
  py: PropTypes.string,
  pattern: PropTypes.string,
  date: PropTypes.string,
};
