import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import clsx from 'clsx';
import { sort, icedit, plus } from '../../../../static/image';
import { Button } from '../../../../stories';
import './academic-years.scss';

const MS_PER_DAY = 1000 * 60 * 60 * 24;
const LENGTH_WIDTH = 0.2;

export default function AcademicYears(props) {
  const { onAddAcademic, onModal, burger, listAcademic } = props;

  const handleDateTime = (date) => {
    if (!date) {
      return '';
    }
    const data = new Date(date).toDateString();
    return data.slice(4, 10) + ', ' + data.slice(11);
  };

  return (
    <Fade clear duration={300}>
      <div className="academ-terms-card w-full">
        {listAcademic.map((item) => (
          <div
            key={`${item.name}`}
            className={clsx(
              'flex flex-col justify-items-start justify-between academ-terms-card_row w-full'
            )}
          >
            <h3 className="academ-terms-card_text font-bold">{item.name}</h3>
            <div className="flex flex-wrap justify-between">
              <div className="flex flex-wrap max-w-[90%]">
                {item.academicYearTerm.map((term, index) => {
                  const distanceDate = Math.ceil(
                    Math.abs(new Date(term.endDate) - new Date(term.startDate)) /
                    MS_PER_DAY,
                  );
                  const setWidth = distanceDate * LENGTH_WIDTH;

                  const currentTerm = item.academicYearTerm[index];
                  const nextTerm = item.academicYearTerm[index + 1];

                  const startDateOfNextTerm =
                    index !== item.academicYearTerm.length - 1
                      ? nextTerm.startDate
                      : currentTerm.endDate;
                  const distanceGap = Math.ceil(
                    Math.abs(
                      new Date(startDateOfNextTerm) - new Date(currentTerm.endDate),
                    ) / MS_PER_DAY,
                  );
                  const setWidthGap = distanceGap * LENGTH_WIDTH;
                  const setWidthTime = setWidth - 2
                  return (<React.Fragment key={index}>
                    <div
                      className={index !== item.academicYearTerm.length - 1 ? `term-box term-box_${index}` : `term-box-end term-box term-box_${index}`}
                      style={{ width: `${setWidth}rem` }}
                    >
                      <h4 className="term-box_name">{term.name}</h4>
                      <p
                        className="term-box_date"
                        style={{ width: `calc(${setWidthTime}rem - 8px)` }}
                        title={`${handleDateTime(term.startDate)} - ${handleDateTime(
                          term.endDate,
                        )}`}
                      >
                        {handleDateTime(term.startDate)} -{' '}
                        {handleDateTime(term.endDate)}
                      </p>
                    </div>
                    <div
                      className={`term-box-gap term-box-gap_${index}`}
                      style={{ width: `${setWidthGap}rem` }}
                    ></div>
                  </React.Fragment>
                  );
                })}
              </div>
              <Button
                buttonSize="small"
                onClick={() => {
                  onModal(item);
                }}
                icon={icedit}
                text="Edit"
                customStyle={{
                  backgroundColor: 'black',
                  marginLeft: '1rem',
                  alignSelf: 'center',
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </Fade>
  );
}

AcademicYears.propTypes = {
  onAddAcademic: PropTypes.func,
  onModal: PropTypes.func,
  burger: PropTypes.bool,
  listAcademic: PropTypes.array,
};
