import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import './textarea.scss';

const TextArea = ({
  type,
  label,
  name,
  labelFix,
  placeholder,
  error,
  value,
  customStyle,
  inputSize,
  disabled,
  disableLabel,
  textForError,
  onChange,
}) => {
  return (
    <React.Fragment>
      <div className="textarea">
        <textarea
          disabled={disabled}
          value={value}
          type={type}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          className={clsx(
            'input-styled',
            error ? `error` : ``,
            `input-size-${inputSize}`,
          )}
          style={customStyle}
          autoComplete="off"
          required
        />
        {disableLabel && (
          <label htmlFor={name} className={`label ${labelFix}`}>
            {label}
          </label>
        )}
      </div>
      {error && <p style={{ color: '#ff4842', marginLeft: 10 }}>{textForError}</p>}
    </React.Fragment>
  );
};

TextArea.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  textForError: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  customStyle: PropTypes.object,
  inputSize: PropTypes.string,
  onChange: PropTypes.func,
};

TextArea.defaultProps = {
  type: 'text',
  label: 'Text Input',
  onChange: () => {},
  name: 'thatinput',
  placeholder: '',
  error: false,
  customStyle: {},
  inputSize: 'medium',
};

export default TextArea;
