import { cancel_meeting, confirm_meeting, reschedule_meeting } from "@static/image"
import { checkErrorSingleApi } from "@utils/check-error/api-error"
import { APPOINTMENT_STATUS } from "@utils/constant"
import admissionApi from "api/admission"
import { ToastMessageContext } from "context/toast-context"
import { useContext } from "react"

const StatusPopup = (props) => {
  const { data, setRefresh, onClosePopup, onOpenCancelPopup, onOpenReschedulePopup } = props
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const onConfirmMeeting = async () => {
    try {
      const res = await admissionApi.updateAdmissionCalendar({ id: data.id, data: { status: APPOINTMENT_STATUS['Confirmed'] } })

      if (checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, 'Confirm Meeting')) {
        setRefresh(prev => !prev)
        onClosePopup()
      }
    } catch (error) {
      console.log('Confirm Meeting error', error);
      setToastMessage({
        status: 'error',
        title: 'Confirm Meeting Failed',
        message: error.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
    }
  }

  const listStatus = [
    { symbol: confirm_meeting, label: 'Confirm Meeting', onClick: onConfirmMeeting },
    { symbol: reschedule_meeting, label: 'Reschedule', onClick: onOpenReschedulePopup },
    { symbol: cancel_meeting, label: 'Cancel', onClick: onOpenCancelPopup }
  ]


  return (
    <div className="text-[#212B36] font-medium p-4 w-[15rem] bg-white rounded-lg">
      <p className="text-base">Status</p>
      <ul className="mt-4">
        {listStatus.map((item, index) => {
          return (
            <li key={item.label} className={`${index !== listStatus.length - 1 && 'mb-4'}`}>
              <button className={`flex items-center ${data?.status === APPOINTMENT_STATUS.Canceled && 'cursor-not-allowed'}`}
                disabled={data?.status === APPOINTMENT_STATUS.Canceled}
                onClick={item.onClick}>
                <img src={item.symbol} alt='symbol' className="mr-4" />
                <p className="text-sm">{item.label}</p>
              </button>
            </li>
          )
        })}

      </ul>
    </div>
  )
}

export default StatusPopup