import { memo } from 'react';
import SingleInput from './component/single-input';
import SingleCheckbox from './component/single-checkbox';
import TypeAndValue from './component/type-and-value';

const ReferralCard = memo((props) => {
  const { data, handleChange } = props
  const name = data?.name || ''
  const active = !!data?.active
  const discountType = data?.discountType || null
  const value = data?.value || ''
  const stackable = !!data?.stackable
  const maximumStack = data?.maximumStack || ''

  return (
    <div className="principial-card">
      {/* name */}
      <SingleCheckbox label={name}
        value={active}
        classLabel='font-extrabold text-lg mb-4'
        onChange={(e) => {
          handleChange({
            index: 0,
            name: 'active',
            value: e.target.checked,
          })
        }}
      />
      <TypeAndValue info={{ discountType, value }}
        isFixed={true}
        className='mb-3'
        label={{ value: 'Value', discountType: 'Discount Type' }}
        handleChange={({ name, value }) => handleChange({ index: 0, name, value })}
      />
      {/* Stackable */}
      <div className='mb-2'>
        <SingleCheckbox label='Stackable'
          value={stackable}
          classLabel='text-[#808692]'
          onChange={(e) => {
            handleChange({
              scholarship: 'parentReferralDiscount',
              index: 0,
              name: 'stackable',
              value: e.target.checked,
            })
          }}
        />
      </div>
      {/* Maximum Stack */}
      <SingleInput label='Maximum Stack'
        value={maximumStack}
        type='number'
        onChange={(e) => {
          handleChange({
            scholarship: 'parentReferralDiscount',
            index: 0,
            name: 'maximumStack',
            value: e.target.value,
          })
        }}
      />
    </div>
  )
}
)
ReferralCard.displayName = 'ReferralCard'

export default ReferralCard
