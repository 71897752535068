import { ToggleButton } from "@stories/index";
import React, { memo } from "react";

const SingleCheckbox = memo((props) => {
  const { label, value, classLabel, onChange } = props

  return (
    <div className='principial-card-row mb-3 items-center'>
      <p className={`${classLabel} mb-0`} >{label}</p>
      <ToggleButton
        inputSize="small"
        value={value}
        onChange={onChange}
        customStyle={{ marginLeft: '0.938rem' }}
      />
    </div>
  )
})
SingleCheckbox.displayName = 'SingleCheckbox'

export default SingleCheckbox;