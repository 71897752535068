import React, { useEffect, useState, useContext } from 'react';
import Zoom from 'react-reveal/Zoom';
import clsx from 'clsx';
import { feedback } from '../../../static/image';
import { callApi } from '../../../helper/call-api';
import './feedback.scss';
import { ToastMessageContext } from '../../../context/toast-context';
import { checkErrorSingleApi } from '../../../utils/check-error/api-error';
import useStorage from 'store/storage';

function Feedback(props) {
  const {
    users,
    requestId,
    isShowFeedbackModal,
    setIsShowFeedbackModal,
    feedbackModalStatus,
    isStatusOfApprover,
    isUpdate,
    setIsUpdate,
  } = props;
  const [message, setMessage] = useState('');
  const auth = useStorage(state => state.auth);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const approverClickHandle = () => {
    const user = users.find((data) => {
      return data.userId === auth.uid;
    });
    const data = {
      requestId: requestId,
      processStageApproverId: user?.processStageApproverId || '',
      status: feedbackModalStatus,
      message: message,
    };
    setIsShowFeedbackModal(pre => {
      return {
        isVisible: false,
        status: null,
      }
    });
    const res = callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/request/stage/feedback`,
      data: data,
    });
    res
      .then((result) => {
        if (
          checkErrorSingleApi(result, setToastMessage, setIsShowToastMessage, 'Feedback')
        ) {
          setIsUpdate(!isUpdate);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Feedback Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Feedback Error', error);
      });
  };

  return (
    <Zoom bottom duration={1000} when={isShowFeedbackModal.isVisible}>
      <div
        className={clsx(
          'feedback-approval-information-panel',
          isShowFeedbackModal.isVisible ? 'pointer-events-auto' : 'pointer-events-none hidden',
          // pl-5 pr-5 absolute bottom-0 bg-white border rounded-lg shadow-main-card  
          "px-5 absolute right-3 top-16 z-10 bg-white border rounded-lg shadow-main-card "
        )}
      >
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center flex-auto w-32">Feedback</div>
          <button
            onClick={() => {
              setMessage('');
              setIsShowFeedbackModal(pre => {
                return {
                  isVisible: false,
                  status: null,
                }
              });
            }}
          >
            <Close />
          </button>
        </div>
        <hr className="mt-2" />
        <div className="mt-4 flex justify-around items-center">
          <div className="pt-0">
            <img src={feedback} alt="avatar" className="" />
          </div>
          <div className="relative pt-0">
            <textarea
              rows="7"
              cols="35"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              className="p-2 my-2 font-light border-2 rounded-lg"
            ></textarea>
            <div className="w-20 text-center text-xs absolute top-0 left-3 bg-[#FFFFFF]">
              Comments
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 left-7 right-7">
          <hr className="mt-2" />
          <div className="flex justify-end items-center h-20">
            <button
              className="font-light border rounded-lg text-[#202223] mr-4 w-20 h-9 bg-[#FFFFFF] hover:bg-[#B0B6BE] active:bg-[#FFFFFF] text-sm"
              onClick={(e) => {
                setMessage('');
                setIsShowFeedbackModal(pre => {
                  return {
                    isVisible: false,
                    status: null,
                  }
                });
              }}
            >
              Cancel
            </button>
            <button
              className={clsx("font-light border rounded-lg w-20 h-9 text-sm text-[#FFFFFF]",
                feedbackModalStatus === "approve" ? "bg-[#008060] hover:bg-[#27AE60] active:bg-[#008040]"
                  : feedbackModalStatus === "reject" ? "bg-[#fd5749] hover:bg-[#e67b7b] active:bg-[#fd5749v]"
                    : "bg-[#f2c94c] hover:bg-[#e6e67b] active:bg-[#f2c94c]")}
              onClick={(e) => {
                approverClickHandle();
              }}
            >
              {feedbackModalStatus === "approve" ? "Approve" : feedbackModalStatus === "reject" ? "Reject" : "Need Info "}
            </button>
          </div>
        </div>
      </div>
    </Zoom>
  );
}

const Close = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 1.4L14.6 0L8 6.6L1.4 0L0 1.4L6.6 8L0 14.6L1.4 16L8 9.4L14.6 16L16 14.6L9.4 8L16 1.4Z"
        fill="#2F80ED"
      />
    </svg>
  );
};

export default Feedback;
