import React, { useEffect } from 'react';
import { Complete } from '../../../components/admission-module/application';

export default function BloomChildComplete() {
  const appStyle = document.getElementsByClassName('app');

  useEffect(() => {
    if (appStyle && appStyle[0]) appStyle[0].style.overflowX = 'hidden';
  }, []);

  return (
    <div className="w-screen flex items-start mt-12 pl-20">
      <div className="w-screen flex flex-col items-center pr-40 mt-1">
        <Complete />
      </div>
    </div>
  );
}