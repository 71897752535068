import clsx from 'clsx';
import { useContext, useEffect, useMemo, useState } from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import NewPdf from '@components/admission-module/application/signature/PDF/NewPdf';
import { css } from '@emotion/react';
import { done, print } from '@static/image';
import { ToastMessageContext } from 'context/toast-context';
import { EnquiryStatus } from 'entities/data';
import { useLocation } from 'react-router-dom';
import { BloomPayment } from '..';
import './review.scss';
import admissionApi from 'api/admission';
import useStorage from 'store/storage';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function BloomReview(props) {
  const {
    isCompletedOrClosed,
    handleNextStage,
    handleRefreshAndNext,
    handleRefreshEnquiry,
  } = props;
  const [showPrint, setShowPrint] = useState(false);
  const [imgPreview, setImgPreview] = useState(false);
  const [latestEnquiry, setLatestEnquiry] = useState({});
  const [admissionResult, setAdmissionResult] = useState({});
  const [signatureInfo, setSignatureInfo] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [application, setApplication] = useState();
  const [enquiry, setEnquiry] = useState(props.enquiry);
  const [step, setStep] = useState(0);
  const [state, setState] = useState({
    start: true,
    waitingSignature: false,
    waitingPayment: false,
    done: false,
  });
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const location = useLocation();
  const campus =
    useStorage((state) => state.currentCampus);;
  // const campus = getCurrentCampus();
  useEffect(() => {
    if (props.enquiry) {
      setEnquiry(props.enquiry);
    }
  }, [props.enquiry]);

  // useEffect(() => {
  //   if (enquiry?.admissionResult?.info) {
  //     const info = JSON.parse(enquiry.admissionResult.info);
  //     setApplication(info.application);
  //   }
  // }, [enquiry]);

  useEffect(() => {
    if (enquiry?.currentStep === EnquiryStatus.COMPLETE) {
      setShowPrint(true);
      setLatestEnquiry(enquiry);
      setState({
        waitingSignature: false,
        waitingPayment: false,
        done: true,
        start: false,
      });
      const admissionResult = JSON.parse(enquiry.admissionResult.info);
      setAdmissionResult(admissionResult);
      setApplication(admissionResult.application);
      return;
    }

    enquiry?.id &&
      // callApi({
      //   method: 'get',
      //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/enquiry/parent/${enquiry.id}`,
      // })
      admissionApi.getEnquiryParent(enquiry.id)
        .then((results) => {
          if (results.data.success) {
            const admissionResult = JSON.parse(results.data.data.admissionResult.info);
            setAdmissionResult(admissionResult);
            admissionResult?.application && setApplication(admissionResult.application);
            admissionResult?.application?.status !==
              EnquiryStatus.WAITING_FOR_SIGNATURE && setShowPrint(true);
            const signatureInfoDB =
              admissionResult.application?.signatureInfo &&
              JSON.parse(admissionResult.application.signatureInfo);
            signatureInfoDB && setSignatureInfo(signatureInfoDB);
            setLatestEnquiry(results.data.data);
            const application = admissionResult?.application || {};
            if (application?.status !== EnquiryStatus.WAITING_FOR_SIGNATURE) {
              setState({
                waitingSignature: false,
                waitingPayment: false,
                done: false,
                start: false,
              });
            }
            if (application?.status === EnquiryStatus.WAITING_FOR_SIGNATURE) {
              setState({
                waitingSignature: true,
                waitingPayment: false,
                done: false,
                start: true,
              });
            }
            if (application?.status === EnquiryStatus.WAITING_FOR_PAYMENT) {
              setState({
                start: false,
                waitingSignature: false,
                done: false,
                waitingPayment: true,
              });
              setShowPrint(true);
            }
            if (application?.status === EnquiryStatus.APPLICATION_COMPLETE) {
              setState({
                start: false,
                waitingSignature: false,
                waitingPayment: false,
                done: true,
              });
              setShowPrint(true);
            }
          } else {
            setToastMessage({
              status: 'error',
              title: 'Get Enquiries Failed',
              message: results.data.message,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Get Enquiries Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Get Application Error', error);
        });
  }, [enquiry, refresh]);

  const changeImageHandler = (e) => {
    let selected = e.target.files;
    if (selected && selected[0]) {
      let reader = new FileReader();

      reader.onload = (e) => {
        setImgPreview(e.target.result);
      };
      reader.readAsDataURL(selected[0]);
    }
  };
  const updateStatus = () => {
    // let url = `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/application/parent-signature/${application?.id}`;
    // // if (application?.status === EnquiryStatus.WAITING_FOR_PAYMENT) {
    // //   url = `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/application/signature/fee-payment/${applicationId}`;
    // // }
    // callApi({
    //   method: 'patch',
    //   url,
    //   // params: { campusId: campus?.isCentral ? undefined : campus?.id },
    // })
    admissionApi.updateSignature(application?.id)
      .then((results) => {
        if (results?.data?.success && results.data.success) {
          // setApplication(results.data.data);
          setRefresh((prev) => !prev);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Update Failed',
            message: results.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Update Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Update Error', error);
      });
  };
  const appStyle = document.getElementsByClassName('app');

  useEffect(() => {
    if (appStyle && appStyle[0]) appStyle[0].style.overflowX = 'hidden';
  }, []);

  const handleNextStep = () => {
    if (!admissionResult.application || admissionResult.application.isPending) {
      setToastMessage({
        status: 'warning',
        title: 'Waiting for admission process',
        message: `The current status is ${enquiry.status} at step ${enquiry.currentStep}`,
      });
      setIsShowToastMessage(true);
      return;
    }
    setStep(1);
  };

  const handleConfirmSignature = () => {
    // let url = `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/application/parent-signature/confirm/${application?.id}`;
    // callApi({
    //   method: 'patch',
    //   url,
    //   params: { campusId: campus?.isCentral ? undefined : campus?.id },
    // })
    admissionApi.confirmSignature({ campusId: campus?.isCentral ? undefined : campus?.id }, application?.id)
      .then((results) => {
        if (results?.data?.success) {
          setRefresh((prev) => !prev);
          setToastMessage({
            status: 'success',
            title: 'Confirm Signature Successfully',
            message: results.data.success || 'success',
          });
          setIsShowToastMessage(true);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Confirm Signature Failed',
            message: results.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Confirm Signature Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Confirm Signature Error', error);
      });
  };

  const handleDownloadPdfFile = () => {
    // const accesstoken = getAccessToken();
    const accesstoken = useStorage.getAccessToken();

    const apiUrl = `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/application/parent-signature/pdf/${application?.id}`;
    // callApi({
    //   method: 'get',
    //   url: apiUrl,
    //   params: { campusId: campus?.isCentral ? undefined : campus?.id },
    // })
    fetch(apiUrl, {
      headers: {
        accesstoken,
        'Content-Type': 'application/pdf',
      },
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob);

        // Create a link element and trigger download
        const link = document.createElement('a');
        link.href = url;
        link.download = `application_${application?.id}.pdf`;
        link.click();
      });
    // .then((results) => {
    //   console.log('results', results)
    //   if (results?.data?.success && results.data.success) {
    //     //   console.log(results.data.data);
    //     // setIsCallAPI((prev) => !prev);
    //     // handleRefreshAndNext();
    //     setToastMessage({
    //       status: 'success',
    //       title: 'Download File Successfully',
    //       message: results.data.message,
    //     });
    //     setIsShowToastMessage(true);
    //   } else {
    //     setToastMessage({
    //       status: 'error',
    //       title: 'Download File Failed',
    //       message: results?.data?.message || '',
    //     });
    //     setIsShowToastMessage(true);
    //   }
    // })
    // .catch((error) => {
    //   setToastMessage({
    //     status: 'error',
    //     title: 'Download File Failed',
    //     message: error.response?.data?.message || error,
    //   });
    //   setIsShowToastMessage(true);
    //   console.log('Download File Error', error);
    // });
  };

  const mapStep = useMemo(
    () => ({
      0: (
        <div>
          <div className="w-screen flex items-start mt-12 pl-20">
            {/* <button className="h-8 flex items-center none-after font-semibold cursor-pointer mr-10">
              <img src={backarrow} className="w-4 h-4" />
            </button> */}
            <div className="w-screen flex flex-col items-start pr-40 mt-1">
              <p className="font-semibold">Review Application</p>
              <div className="mx-auto">
                {showPrint && (
                  <div className="flex items-center justify-center">
                    <img className="w-4 h-4 mr-2" src={print} alt="print" />
                    <button
                      className="text-[#2F80ED] text-[1.2rem]"
                      onClick={() => {
                        handleDownloadPdfFile();
                      }}
                    >
                      Print Application
                    </button>
                  </div>
                )}
              </div>
              <div
                className="relative mx-auto w-[70vw] pt-2 mt-10 mb-5 border flex items-center justify-center text-third-gray"
                style={{ height: '57.4rem' }}
              >
                {application && (
                  <NewPdf
                    addressInfo={
                      application.additionalInfo?.addressInfo
                        ? application.additionalInfo.addressInfo
                        : []
                    }
                    applicationParent={application.applicationParent || []}
                    handleRefreshEnquiry={handleRefreshEnquiry}
                    isShowButtonSign={
                      application?.status === EnquiryStatus.WAITING_FOR_SIGNATURE
                    }
                    studentInfo={application.info}
                    isParent={true}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    applicationId={application.id}
                    isShowESign={
                      // signatureInfo.requestStatus !== 'completed' &&
                      // signatureInfo.requestStatus !== 'inprogress'
                      application?.status === EnquiryStatus.WAITING_FOR_SIGNATURE
                    }
                  />
                )}
                <div
                  className={clsx(
                    'absolute flex flex-col items-center justify-center',
                    state.done && 'signature-done z-10',
                    (state.waitingSignature || state.waitingPayment) &&
                    'signature-waiting z-10',
                    'top-0',
                  )}
                  style={{ width: '100%', height: '57.438rem' }}
                >
                  {(state.waitingSignature || state.waitingPayment) && (
                    <BounceLoader
                      color="#FFEBD3"
                      loading={true}
                      css={override}
                      size={60}
                    />
                  )}
                  {state.done && (
                    <img
                      src={done}
                      style={{ width: '3.75rem', height: '3.75rem' }}
                      alt="done"
                    />
                  )}
                  {state.waitingSignature && (
                    <p className="text-sm text-main-black font-semibold mt-3">
                      Waiting for Signature
                    </p>
                  )}
                  {state.waitingPayment && (
                    <p className="text-sm text-main-black font-semibold mt-3">
                      Waiting for Payment
                    </p>
                  )}
                  {state.done && (
                    <p className="text-sm text-main-black font-semibold mt-3">
                      Application Complete
                    </p>
                  )}
                </div>
              </div>

              <div className="bloom-footer w-[70vw] mx-auto mt-24">
                {!(enquiry.status === EnquiryStatus.WAITING_FOR_SIGNATURE) && (
                  <div onClick={handleNextStep}>
                    <button className="flex items-center bg-main-blue pl-3.5 pr-1.5 pt-1 pb-1 text-sm text-white font-semibold">
                      Next
                      <Arrow />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ),
      1: (
        <BloomPayment
          enquiry={enquiry}
          isCompletedOrClosed={isCompletedOrClosed}
          handleNextStage={handleNextStage}
          handleRefreshAndNext={handleRefreshAndNext}
        />
      ),
    }),
    [step, application, signatureInfo, setRefresh, handleRefreshAndNext],
  );

  return mapStep[step];
}

const Arrow = () => {
  return (
    <svg
      width="24"
      height="24"
      className="ml-1.5"
      viewBox="0 -5 24 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.55 2.5L7 4.05L14.95 12L7 19.95L8.55 21.5L18 12L8.55 2.5Z"
        fill="white"
      />
    </svg>
  );
};
