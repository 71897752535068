import { Modal } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { NavLinks } from '@pages/Header/components';
import Pagination from '@stories/pagination/pagination';
import { checkErrorMultipleApiFetch } from '@utils/check-error/api-error';
import { rowsPerPageCount } from '@utils/storage';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_START } from '@utils/utils';
import { ToastMessageContext } from 'context/toast-context';
import { dataLinks } from 'entities/routes';
import ActionModal from './components/action-modal';
import ApprovalItem from './components/approval-item';
import clsx from 'clsx';
import './admission-approvals.scss';
import approvalApi from 'api/approval';
import useStorage from 'store/storage';

const AdmissionApprovals = ({ burger }) => {
  const [open, openModal] = useState(false);
  const [listApprovalProcess, setListApprovalProcess] = useState([]);
  const [isCancel, setIsCancel] = useState(false);
  const [listAction, setListAction] = useState([]);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  // const campus = useStorage((state) => state.currentCampus);

  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_START);
  const [totalObjectsFromApi, setTotalObjectsFromApi] = useState(0);
  const handleChangeRowsPerPage = (event) => {
    setSelectedRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const isOpen = () => {
    openModal(!open);
  };

  const getDataFormApi = () => {
    // const processApi = callApi({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/process/list`,
    //   params: { campusId: campus?.isCentral ? undefined : campus?.id, page: currentPage, pageSize: selectedRowsPerPage },
    // });
    // const ActionApi = callApi({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/action/list`,
    //   params: { campusId: campus?.isCentral ? undefined : campus?.id },
    // });
    // Promise.all([processApi, ActionApi])
    // const processApi = approvalApi.getListProcess({ campusId: campus?.isCentral ? undefined : campus?.id, page: currentPage, pageSize: selectedRowsPerPage })
    // const actionApi = approvalApi.getListAction({ campusId: campus?.isCentral ? undefined : campus?.id })
    const processApi = approvalApi.getListProcess({ page: currentPage, pageSize: selectedRowsPerPage })
    const actionApi = approvalApi.getListAction()
    Promise.all([processApi, actionApi])
      .then((res) => {
        if (checkErrorMultipleApiFetch(res, setToastMessage, setIsShowToastMessage)) {
          setListApprovalProcess(res[0].data.data.objects);
          setTotalObjectsFromApi(res[0].data.data.total);
          setListAction(res[1].data.data.objects);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Approval Process Error', error);
      });
  };

  const handleCancel = () => {
    setIsCancel(!isCancel);
  };

  useEffect(() => {
    getDataFormApi();
  }, [isCancel, currentPage, selectedRowsPerPage]);

  return (
    <React.Fragment>
      <Modal open={open} onClose={isOpen}>
        <div>
          <ActionModal
            open={open}
            isOpen={isOpen}
            listApprovalProcess={listApprovalProcess}
            setListApprovalProcess={setListApprovalProcess}
          />
        </div>
      </Modal>
      <div
        className={clsx('admission-approvals ml-20 pr-16 transition-all-300', burger ? 'pl-0 1400px:pl-69  2xl:pl-69' : 'pl-0 1400px:pl-20')}
      >
        <NavLinks urls={dataLinks.ApprovalEditLinks} />
        <div className="w-full flex mb-10 items-center justify-between">
          <h1 className="namepage">Admission Approvals</h1>
          <button
            className="bg-main-blue text-white p-2 pr-3.5 pl-3.5 rounded"
            onClick={isOpen}
          >
            Add Approval Process
          </button>
        </div>
        <div className="w-full">
          {listApprovalProcess.map((approvalProcess, index) => (
            <ApprovalItem
              key={index}
              approvalProcessData={approvalProcess}
              handleCancel={handleCancel}
              getDataFormApi={getDataFormApi}
              listAction={listAction}
            />
          ))}
        </div>
      </div>
      <div className="datatable-footer">
        <Pagination
          customClass="fixed bottom-0 right-0 z-[3] pr-4"
          customStyle={{ height: '3rem' }}
          page={currentPage}
          rowsPerPage={selectedRowsPerPage}
          rowsPerPageCount={rowsPerPageCount}
          totalRows={totalObjectsFromApi}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
        />
      </div>
    </React.Fragment>
  );
};

export default AdmissionApprovals;
