import { useContext, useState } from 'react';
import { Button } from '@stories/index';
import { checkErrorSingleApi } from '@utils/check-error/api-error';
import clsx from 'clsx';
import { ToastMessageContext } from 'context/toast-context';
import { goalsList } from 'entities/data';
import {
  toggleButtonStyles
} from '../../../../entities/select-style';
import taskApi from 'api/task';
import useStorage from 'store/storage';

function UpdateTaskGoal(props) {
  const {
    initialSelect,
    isSelect,
    setIsSelect,
    task,
    index,
    setRefresh,
    setIsUpdate = () => { },
    setPage = () => { },
  } = props;
  const [goalsOrKRI, setGoalsOrKRI] = useState(task?.goalsOrKRI);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campus = useStorage((state) => state.currentCampus);

  const handleUpdateTaskGoal = () => {
    taskApi.updateTaskById({ campusId: campus?.isCentral ? undefined : campus?.id }, { goalsOrKRI }, task.id)
      .then((res) => {
        if (
          checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, 'Update Task')
        ) {
          setIsSelect(initialSelect);
          setRefresh((prev) => ({
            ...prev,
            count: (prev.count || 0) + 1,
            index,
          }));
          setIsUpdate((prev) => !prev);
          setPage(0);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Update Task Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Update task error', error.message);
      });
  };

  return (
    <>
      {isSelect.kri && (
        <div className="absolute top-8 left-[-2rem] w-60 z-20 bg-[#fff] shadow-2xl rounded">
          <SelectProperty
            goalsOrKRI={goalsOrKRI}
            list={goalsList}
            setGoalsOrKRI={setGoalsOrKRI}
            handleUpdateTaskGoal={handleUpdateTaskGoal}
            setIsSelect={setIsSelect}
            initialSelect={initialSelect}
            property="status"
          />
        </div>
      )}
    </>
  );
}

export default UpdateTaskGoal;

const SelectProperty = ({
  list = [],
  setGoalsOrKRI,
  property,
  goalsOrKRI,
  setIsSelect,
  initialSelect,
  handleUpdateTaskGoal,
}) => {
  return (
    <ul className="p-1">
      {list.map((data, index) => {
        return (
          <li
            onClick={() => {
              setGoalsOrKRI(data.status);
            }}
            key={index}
            className={clsx(
              'm-2 text-center rounded text-xs py-2',
              data.status === goalsOrKRI ? 'border-2 border-indigo-600' : '',
            )}
            style={{ backgroundColor: data.color, color: '#fff' }}
          >
            {data.status}
          </li>
        );
      })}
      <li>
        <div className="flex justify-center">
          <Button
            className="text-[0.8rem]"
            text="Cancel"
            customStyle={{
              ...toggleButtonStyles.btnWhite,
              width: '7rem',
              height: '1.5rem',
            }}
            onClick={() => {
              setIsSelect(initialSelect);
              // setEnteredTaskName('');
            }}
          />
          <Button
            className="text-[0.8rem]"
            text="Save"
            customStyle={{
              ...toggleButtonStyles.btnBlue,
              width: '7rem',
              height: '1.5rem',
            }}
            onClick={() => {
              handleUpdateTaskGoal();
            }}
          />
        </div>
      </li>
    </ul>
  );
};
