import React, { useState, useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import { looking, inbox } from '../image';
import '../UserLogin/index.scss';
import { Logo } from '../../index.jsx';
import { Input, InputVerification } from '../../../../components/index.jsx';
import useDebounce from '../../../../utils/custom-hook/useDebounce';
import { validEmail } from '../../../../utils/validate';
import { ToastMessageContext } from '../../../../context/toast-context';
import { checkErrorSingleApi } from '../../../../utils/check-error/api-error';
import authApi from 'api/auth';
import useStorage from 'store/storage';

export default function ForgotPassword({ tenantId }) {
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [account, setAccount] = useState(false);
  const { updateAuth } = useStorage();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [codeVerify, setCodeVerify] = useState('');

  const debounce = useDebounce((text) => {
    setError(!validEmail.test(text));
  });

  const onChangeEmail = (e) => {
    const text = e.target.value;
    setEmail(text);
    debounce(text);
  };

  const onChangeCodeVerify = (e) => {
    setCodeVerify(e);
  };

  // const tenantId = "giiki-test-1-a9hhl-2uj1p";
  // const tenantId = 'company-1-mu4w9'
  const handleSendVerifyCode = () => {
    if (error) {
      setToastMessage({
        status: 'error',
        title: 'Invalid Data',
        message: 'Invalid Email',
      });
      setIsShowToastMessage(true);
      return;
    }
    if (email === '') {
      setToastMessage({
        status: 'warning',
        title: 'Validation Rule',
        message: 'Please enter your email!',
      });
      setIsShowToastMessage(true);
    } else {
      // const config = {
      //   method: 'get',
      //   url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/auth/send-verify-code?email=${email}&tenantId=${tenantId}`,
      // };

      // callApi(config)
      authApi.sendVerifyCode(email, tenantId)
        .then((response) => {
          checkErrorSingleApi(
            response,
            setToastMessage,
            setIsShowToastMessage,
            'Send Verify Code',
          );
        })
        .catch(function (error) {
          setToastMessage({
            status: 'error',
            title: 'Send Verify Code Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
        });
      setAccount(true);
    }
  };

  const handleCheckVerifyCode = () => {
    const re = new RegExp('^\\d{6}$');
    if (!re.test(codeVerify)) {
      setToastMessage({
        status: 'error',
        title: 'Invalid Data',
        message: 'Invalid code',
      });
      setIsShowToastMessage(true);
      return;
    }

    // const config = {
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/auth/check-verify-code?email=${email}&tenantId=${tenantId}&codeVerify=${codeVerify}`,
    // };

    // callApi(config)
    authApi.checkVerifyCode(email, tenantId, codeVerify)
      .then(function (response) {
        if (
          (checkErrorSingleApi(response, setToastMessage, setIsShowToastMessage),
            'Reset Password')
        ) {
          updateAuth({ email, tenantId, codeVerify });
          history.push('/login/type');
        }
      })
      .catch(function (error) {
        console.log(error);
        setToastMessage({
          status: 'error',
          title: 'Reset Password Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      });
  };

  return (
    <div
      className="login account-type"
      style={{ background: !account ? '#FDD998' : '#E0ECE4' }}
    >
      <div className="m-auto">
        <NavLink className="mb-10 block" to="/">
          <Logo fill={!account ? '#FFB226' : '#A5BBAC'} />
        </NavLink>
        <Fade clear duration={300}>
          <div className="box" style={{ width: '48rem' }}>
            <h1 className="text-3xl font-black">
              {!account ? 'Forgot Password' : 'Please check your Email!'}
            </h1>
            <p
              className="text-second-gray font-normal text-base mt-1"
              style={{ width: '28rem' }}
            >
              {!account
                ? `Please enter the email address associated with your account, and we'll email you a link to reset your password.`
                : `We've emailed a 6-digit confirmation code to ${email}, please enter the code in below box to verify your email.`}
            </p>
            <div className="flex mt-5">
              {!account ? (
                <div>
                  <div className="relative">
                    <Input
                      error={error}
                      value={email}
                      onChange={onChangeEmail}
                      label="Email"
                      customStyle={{ width: '27.688rem', marginBottom: '1rem' }}
                    />
                  </div>
                  <button
                    className="box-submit"
                    onClick={() => {
                      handleSendVerifyCode();
                    }}
                    style={{ marginTop: '1.5rem' }}
                    type="submit"
                  >
                    {/* Reset Password */}
                    Next
                  </button>
                </div>
              ) : (
                <div style={{ width: '25.75rem' }}>
                  <InputVerification
                    value={codeVerify}
                    onChange={onChangeCodeVerify}
                    className="mt-12"
                  />
                  <button
                    className="box-submit"
                    onClick={() => {
                      handleCheckVerifyCode();
                    }}
                    style={{ width: '25.75rem', marginTop: '1.5rem' }}
                    type="submit"
                  >
                    Next
                  </button>
                </div>
              )}
              <img src={!account ? looking : inbox} alt="looking" className="dodles" />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}
