import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Eyes = (props) => {
  const { disable, onlyView, indexPer, handleChange, roleName } = props;

  return (
    <svg
      width="24"
      className={clsx('mr-2', onlyView ? 'cursor-not-allowed' : 'cursor-pointer')}
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        if (onlyView) return;
        handleChange(roleName, indexPer, 'read', disable);
      }}
    >
      <circle cx="12" cy="12" r="12" fill={!disable ? '#C4CDD5' : '#404EED'} />
      {!disable && <rect width="12" height="12" transform="translate(6 6)" fill="#C4CDD5" />}
      <circle cx="12" cy="12" r="0.75" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.865 8.50018C14.855 8.41018 16.615 11.1952 16.935 11.7502C17.0244 11.9049 17.0244 12.0955 16.935 12.2502C16.505 13.0002 14.9 15.4302 12.135 15.5002H12.01C9.06503 15.5002 7.38003 12.7952 7.06503 12.2502C6.97572 12.0955 6.97572 11.9049 7.06503 11.7502C7.50003 11.0002 9.10003 8.57018 11.865 8.50018ZM10.25 12.0002C10.25 12.9667 11.0335 13.7502 12 13.7502C12.9665 13.7502 13.75 12.9667 13.75 12.0002C13.75 11.0337 12.9665 10.2502 12 10.2502C11.0335 10.2502 10.25 11.0337 10.25 12.0002Z"
        fill="white"
      />
    </svg>
  );
};

Eyes.propTypes = {
  disable: PropTypes.bool,
};

Eyes.defaultProp = {
  disable: false,
  // index:
};

export default Eyes;
