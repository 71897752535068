import clsx from 'clsx';
import CreateRoleModal from './create-role-modal';
import UpdateRoleModal from './update-role-modal';
import { useRef } from 'react';
import useOnClickOutside from '@hooks/useClickOutside';
import useStorage from 'store/storage';

export default function PopupRole({
  roleDetail,
  showPopupCreate,
  isCreate,
  isAdmin,
  handleClickClose,
  data,
  loginTypes,
  setRefresh,
}) {
  const popupRef = useRef()
  const campus = useStorage((state) => state.currentCampus);
  const campusId = campus?.id;

  useOnClickOutside(popupRef, handleClickClose)

  return (
    <div
      className={clsx(
        'w-full h-full fixed z-50 flex top-0 left-0',
      )}
      style={{
        backgroundColor: '#000000a3',
      }}
    >
      <div ref={popupRef}
        style={{
          width: '37rem',
        }}
        className=" rounded-md bg-white px-4 py-6 m-auto relative"
      >
        <button
          className="absolute right-4 top-4"
          onClick={() => {
            handleClickClose();
          }}
        >
          <Close />
        </button>
        <div>
          {isCreate ? (
            <CreateRoleModal
              roleDetail={roleDetail}
              showPopupCreate={showPopupCreate}
              handleClickClose={handleClickClose}
              optionTypes={loginTypes}
              // notifyMessage={notifyMessage.create}
              isAdmin={isAdmin}
              setRefresh={setRefresh}
              campusId={campus?.isCentral ? undefined : campus?.id}
            />
          ) : (
            <UpdateRoleModal
              handleClickClose={handleClickClose}
              optionTypes={loginTypes}
              data={data}
              permissionOrigin={JSON.stringify(data.dataPermission)}
              // notifyMessage={notifyMessage.update}
              isAdmin={isAdmin}
              setRefresh={setRefresh}
              campusId={campusId}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const Close = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 16 16"
      fill="#404eed"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 1.4L14.6 0L8 6.6L1.4 0L0 1.4L6.6 8L0 14.6L1.4 16L8 9.4L14.6 16L16 14.6L9.4 8L16 1.4Z" />
    </svg>
  );
};
