import React from 'react';

import { deleteIcon, editIcon } from './icon';
const FormParagraph = (props) => {
  const {
    id,
    index,
    order,
    isEdit,
    isFocus,
    isBuild,
    deletable,
    component,
    label,
    value,
    error,
    textForError,
    formStyle,
    handleFocus,
    handleDelete,
    handleDragStart,
    handleDragOver,
    ...rest
  } = props;
  // const [error, setError] = useState(false);
  // const { lineHeight, fontSize, ...restFormStyle } = formStyle;

  return (
    <div
      className="component"
      draggable
      onDragStart={(e) => {
        // handleDragStart(e, index);
      }}
      onDragOver={handleDragOver}
      style={{
        // ...restFormStyle,
        outline: error ? '1px #ff4842 solid' : isFocus ? '1px black solid' : '',
        outlineOffset: '0rem',
      }}
    >
      <div className="setting items-center absolute bg-white px-[0.15rem] right-2 top-[-0.5rem] z-10">
        {isEdit && (
          <>
            <img
              className="cursor-pointer"
              title="edit"
              src={editIcon}
              onClick={() => {
                handleFocus(order, index);
              }}
              alt="edit"
            />
            <img
              className="ml-2 cursor-pointer"
              title="delete"
              src={deleteIcon}
              onClick={() => {
                handleDelete(order, index);
              }}
              alt="delete"
            />
          </>
        )}
      </div>
      <p
        style={{
          // fontSize,
          // lineHeight,
          minWidth: '5rem',
          minHeight: '1rem',
          width: 'fit-content',
          height: 'fit-content',
        }}
      >
        {value}
      </p>
      {error && textForError && (
        <p className="text-sm" style={{ color: '#ff4842', marginLeft: 10 }}>
          {textForError}
        </p>
      )}
    </div>
  );
};
export default FormParagraph;
