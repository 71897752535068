import { useContext, useState } from 'react';
import { Button, Datepicker } from '@stories/index';
import { checkErrorSingleApi } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { toggleButtonStyles } from '../../../../entities/select-style';
import taskApi from 'api/task';
import useStorage from 'store/storage';

function UpdateTaskDate(props) {
  const {
    initialSelect,
    isSelect,
    setIsSelect,
    task,
    index,
    setRefresh,
    setIsUpdate = () => { },
    setPage = () => { },
  } = props;
  const [startDate, setStartDate] = useState(task?.startDate);
  const [endDate, setEndDate] = useState(task?.endDate);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campus = useStorage((state) => state.currentCampus);

  const handleUpdateTaskDate = () => {
    if (new Date(startDate) > new Date(endDate)) {
      setToastMessage({
        status: 'warning',
        title: 'Validation Rule',
        message: 'The start date must be less than the end date',
      });
      setIsShowToastMessage(true);
      return;
    }

    taskApi.updateTaskById({ campusId: campus?.isCentral ? undefined : campus?.id }, { endDate, startDate }, task.id)
      .then((res) => {
        if (
          checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, 'Update Task')
        ) {
          setIsSelect(initialSelect);
          setRefresh((prev) => ({
            ...prev,
            count: (prev.count || 0) + 1,
            index,
          }));
          setIsUpdate((prev) => !prev);
          setPage(0);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Update Task Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Update task error', error.message);
      });
  };

  return (
    <>
      {isSelect.date && (
        <div className="absolute top-8 left-[-10rem] w-[24rem] z-30 h-60 bg-[#fff] shadow-2xl z-20 rounded">
          <div className="flex flex-col mt-10 justify-around">
            <div className="mb-8 mx-4">
              <Datepicker
                label="Start Date"
                disabled={false}
                pValue={
                  !!startDate
                    ? new Date(startDate).toUTCString()
                    : new Date().toUTCString()
                }
                psetValue={(value) => {
                  setStartDate(value);
                }}
              />
            </div>
            <div className="mb-4 mx-4">
              <Datepicker
                label="End Date"
                disabled={false}
                pValue={
                  !!endDate ? new Date(endDate).toUTCString() : new Date().toUTCString()
                }
                psetValue={(value) => {
                  setEndDate(value);
                }}
              />
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <Button
              className="text-[0.8rem]"
              text="Cancel"
              customStyle={{
                ...toggleButtonStyles.btnWhite,
                width: '5rem',
                height: '1.5rem',
              }}
              onClick={() => {
                setIsSelect(initialSelect);
              }}
            />
            <Button
              className="text-[0.8rem]"
              text="Save"
              customStyle={{
                ...toggleButtonStyles.btnBlue,
                width: '5rem',
                height: '1.5rem',
              }}
              onClick={handleUpdateTaskDate}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default UpdateTaskDate;
