import React, { useCallback, useState } from 'react';
import { SelectGroup } from '../../../stories';
import CheckboxOutline from '../../../stories/checkboxoutline';
import { languages } from '../../../utils/utils';
import { deleteIcon, editIcon, move } from './icon';

const FormLanguageCard = (props) => {
  const {
    index,
    // choiceSkill = { Read: false, Speak: false, Write: false },
    // setRefresh,
    isFocus,
    isEdit,
    isBuild,
    isRequired,
    value,
    deletable,
    formStyle,
    onChange,
    handleDragStart,
    handleFocus,
    handleDelete,
    handleDragOver,
    ...rest
  } = props;
  const skill = value?.skill || { Read: false, Speak: false, Write: false };
  // const [refresh, setRefresh] = useState(0);
  // const { language, skill } = value;

  // const [choiceSkill, setChoiceSkill] = useState(
  //   value?.skill || { Read: false, Speak: false, Write: false },
  // );

  // const changeSkill = (skill) => {
  //   if (!skill) {
  //     setRefresh((prev) => prev + 1);
  //     return choiceSkill;
  //   }
  //   onChangeSkill(skill);
  //   setRefresh((prev) => prev + 1);

  //   return {
  //     ...choiceSkill,
  //     [skill]: !choiceSkill[skill],
  //   };
  // };

  const changeSkill = (name) => {
    skill[name] = !skill[name];
    return skill;
  };

  return (
    <div
      className="component"
      draggable
      onDragStart={(e) => {
        handleDragStart(e, index);
      }}
      onDragOver={handleDragOver}
      style={{
        outline: isFocus ? '1px black solid' : '',
        outlineOffset: '0rem',
        ...formStyle,
      }}
    >
      <div className="setting items-center absolute bg-white px-[0.15rem] right-2 top-[-0.5rem] z-10">
        {isBuild && (
          <>
            <img
              className="cursor-pointer"
              title="edit"
              src={editIcon}
              onClick={() => {
                handleFocus(index);
              }}
            />
            <img
              className="ml-2 cursor-pointer"
              title="delete"
              src={deleteIcon}
              onClick={() => {
                handleDelete(index);
              }}
            />
          </>
        )}
        {isEdit && (
          <>
            <img
              className="cursor-pointer"
              title="edit"
              src={editIcon}
              onClick={() => {
                handleFocus(index);
              }}
            />
            {deletable && (
              <img
                className="ml-2 cursor-pointer"
                title="delete"
                src={deleteIcon}
                onClick={() => {
                  handleDelete(index);
                }}
              />
            )}
          </>
        )}
      </div>
      <div className="flex items-center w-full">
        <div className="w-[35%]">
          <p className="mb-2font-semibold" style={{ color: '#2b2c34' }}>
            Additional Language
          </p>
          <SelectGroup
            value={value?.language || null}
            options={languages}
            onChange={(e) => {
              onChange({ language: e, skill });
            }}
            isMulti={false}
          />
        </div>
        <div className="flex mt-6 w-[65%] justify-around items-center ml-4">
          {Object.keys(skill).map((item) => {
            return (
              <CheckboxOutline
                key={`${item}${index}`}
                value={skill[item]}
                label={item}
                onChange={() => {
                  // onChangeSkill(item);
                  onChange({ language: value.language, skill: changeSkill(item) });
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default FormLanguageCard;
