import { useMemo } from 'react';

import './bill-information.scss';

function BillInformation(props) {
  const { fee = [], listScholarships = [] } = props;

  const totalFee = useMemo(() => {
    const total = fee.reduce((sum, item) => {
      if (item.isSelected) {
        return sum + item.price;
      }
      return sum;
    }, 0);
    return total;
  }, [fee]);
  const totalFeeScholarship = useMemo(() => {
    const total = listScholarships.reduce((sum, item) => {
      if (item.isApproved && !item.isUsed) {
        return sum + item.price;
      }
      return sum;
    }, 0);
    return total;
  }, [listScholarships]);

  return (
    <div className="w-full bill-information">
      <p className="bill-information-heading">Bill Details</p>
      {/* Fee */}
      <div className="">
        {fee.map((f) => {
          const dueDate = `Inv #${f.id} on ${new Date(f.date)
            .toGMTString()
            .slice(4, 11)}`;
          return (
            f.isSelected && (
              <div
                key={`${f.id}-${f.name}`}
                className="flex items-center justify-between"
              >
                <div className="mt-2 w-[80%]">
                  <p className="bill-information-title">{f.name}</p>
                  <p className="bill-information-des">{dueDate}</p>
                </div>
                <div className="bill-information-price">₹{f.price}</div>
              </div>
            )
          );
        })}
      </div>
      {/* Scholarship */}
      <p className="bill-information-heading-2">Scholarship</p>
      <div className="">
          {listScholarships.map((scholarship) => {
            let content = ' ';
            if (scholarship.approverName.length > 0) {
              content = `Approved by ${scholarship.approverName.join(', ')} on ${new Date(
                scholarship.approvedDate,
              )
                .toGMTString()
                .slice(4, 11)}`;
            }
            return (
              scholarship.isApproved && !scholarship.isUsed && (
                <div
                  key={`${scholarship.id}-${scholarship.title}`}
                  className="flex items-center justify-between"
                >
                  <div className="mt-2">
                    <p className="bill-information-title">{scholarship.title}</p>
                    <p className="bill-information-des h-3.5">{content}</p>
                  </div>
                  <div className="bill-information-price">₹ -{scholarship.price}</div>
                </div>
              )
            );
          })}
        </div>
      <div className="flex items-center justify-between mt-10">
        <p className="bill-information-total">Total</p>
        <p className="bill-information-price">₹ {totalFee - totalFeeScholarship}</p>
      </div>
      <div className="bill-information-div"></div>
    </div>
  );
}

export default BillInformation;
