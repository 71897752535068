import React from 'react';
import PropTypes from 'prop-types';

const TextFieldCard = (props) => {
  const { title, body, classNameTitle, classNameBody } = props;
  const value = body?.label || body || '';
  const styles = { p: { color: '#6D7175' } };

  return (
    <div className="text-sm">
      <p className={`mb-4 ${classNameTitle}`} style={styles.p}>
        {title}
      </p>
      <p className={`mb-8 ${classNameBody}`}>{value}</p>
    </div>
  );
};

export default TextFieldCard;

TextFieldCard.propTypes = {
  title: PropTypes.string,
  // body: PropTypes.string,
};
