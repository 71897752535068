import { memo } from 'react';
import useStorage from 'store/storage';

const PopupSwitchAcademicYear = memo((props) => {
  const { open, onClose } = props;
  const { getYears, updateCurrentYear, updatePreviousYear } = useStorage()
  const years = getYears()

  const handelChangeYear = (year) => {
    updateCurrentYear(year);
    const index = years.indexOf(year);
    if (index !== -1 && years[index + 1]) {
      updatePreviousYear(years[index + 1]);
    } else {
      updatePreviousYear();
    };
    onClose();
  };

  return (
    <div
      id="popup-campus"
      className="absolute z-10 w-[90%] bottom-[5rem] overflow-y-auto bg-[#fff] rounded shadow"
      style={{
        transition: 'max-height 0.4s',
        maxHeight: open ? '200%' : '0px',
      }}
    >
      <div className="campus mt-2 p-2">
        <p className="text-[#000] text-sm font-medium">Academic Year</p>
        <div className='overflow-auto max-h-[5rem]'>
          {years?.map((year) => {
            return (
              <div
                key={year}
                className="flex items-center mt-2 ml-2 cursor-pointer"
                onClick={() => handelChangeYear(year)}
              >
                <p
                  className="overflow-hidden text-ellipsis whitespace-nowrap text-xs font-medium mr-3"
                  style={{ color: '#bdc1cc' }}
                  title={year}
                >
                  {year}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});
PopupSwitchAcademicYear.displayName = 'PopupSwitchAcademicYear';
export default PopupSwitchAcademicYear;
