import clsx from 'clsx'

const ValueType = (props) => {
  const { value = "Amount", objValueType, editFee, onChange } = props

  return (
    <div className="flex">
      <button disabled={!editFee} className={clsx('grid place-items-center border rounded rounded-br-none rounded-tr-none w-10 h-10', value.includes(objValueType.Amount) ? 'bg-[#919EAB59]' : 'bg-white')}
        onClick={() => onChange(objValueType.Amount)}>
        <INR />
      </button>
      <button disabled={!editFee} className={clsx('grid place-items-center border rounded rounded-tl-none rounded-bl-none w-10 h-10', value.includes(objValueType.Percentage) ? 'bg-[#919EAB59]' : 'bg-white')}
        onClick={() => onChange(objValueType.Percentage)}>
        <Percent />
      </button>
    </div>
  )
}

export default ValueType;

const INR = () => {
  return (
    <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.625 14.5h-.912l6.375 6.637c.762.8.2 2.113-.9 2.113a1.24 1.24 0 0 1-.9-.387l-7.538-7.85a1.741 1.741 0 0 1-.5-1.226c0-.987.8-1.787 1.788-1.787h2.587c2.2 0 4.025-1.625 4.325-3.75h-8.7C.562 8.25 0 7.687 0 7c0-.688.563-1.25 1.25-1.25h8.325c-.7-1.475-2.2-2.5-3.95-2.5H1.25C.562 3.25 0 2.687 0 2 0 1.312.563.75 1.25.75h12.5C14.438.75 15 1.313 15 2c0 .688-.563 1.25-1.25 1.25h-2.825c.6.725 1.05 1.575 1.313 2.5h1.512c.688 0 1.25.563 1.25 1.25 0 .688-.563 1.25-1.25 1.25h-1.275c-.325 3.5-3.275 6.25-6.85 6.25Z" fill="#000" /></svg>
  )
}

const Percent = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.197 8.447a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Zm0-5a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5Zm10.605 8.105a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5Zm0 5a1.25 1.25 0 1 1 0-2.498 1.25 1.25 0 0 1 0 2.498ZM20.134.366a1.25 1.25 0 0 0-1.768 0l-17.5 17.5a1.25 1.25 0 1 0 1.767 1.768l17.5-17.5a1.25 1.25 0 0 0 0-1.768Z" fill="#000" />
    </svg>
  )
}