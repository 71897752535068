import { Modal } from '@mui/material';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { NavLinks } from '../../Header/components/index';
import AddProgramModal from '../components/add-program/add-program';
import CreateClassPanel from '../components/create-class';
import { GradesCard } from '../components/index';
import Footer from '@components/footer/footer';
import { bifilterleft, searchgray } from '@static/image';
import { ProdCard } from '@stories/index';
import { checkErrorMultipleApiFetch } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { dataLinks } from 'entities/routes';
import './program-classes.scss';
import approvalApi from 'api/approval';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';


export default function ProgramClasses({ burger }) {
  const [visible, setVisible] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [isAddProgram, setIsAddProgram] = useState(false);
  const [listProgrammes, setListProgrammes] = useState([]);
  const [listGrades, setListGrades] = useState([]);
  const [listGradesInDatabase, setListGradesInDatabase] = useState([]);
  const [listGradeProgrammes, setListGradeProgrammes] = useState([]);
  const [dataClass, setDataClass] = useState([]);
  const [classInfo, setClassInfo] = useState({});
  const [modalAddProgram, setModalAddProgram] = useState({
    isOpen: false, state: {}, firstName: "", id: ""
  });
  const [index, setIndex] = useState(0);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const inputSearchRef = useRef();
  const [isShowTextApproval, setIsShowTextApproval] = useState(false);
  const [numberOfEdits, setNumberOfEdits] = useState(0);
  const [draftsList, setDraftsList] = useState([]);
  const [isRenderDraft, setIsRenderDraft] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [draftId, setDraftId] = useState();
  const campus = useStorage((state) => state.currentCampus);

  const getDraftsList = useCallback(async () => {
    await centralAdminApi.getListClassDraft({ campusId: campus?.isCentral ? undefined : campus?.id })
      .then((res) => {
        if (res.data.success) {
          const drafts = res.data.data.objects;
          setDraftsList(drafts);
          setNumberOfEdits(res.data.data.total);
        }
      })
      .catch((error) => {
        console.log('Get list draft fail', error);
      });
  }, [campus]);

  const sendDraft = useCallback(
    async (draft) => {
      await centralAdminApi.createClassDraft({ campusId: campus?.isCentral ? undefined : campus?.id }, draft)
        .then((res) => {
          if (res.data.success) {
            setToastMessage({
              status: 'success',
              title: 'Save as Draft Successfully',
              message: res.data.message,
            });
            setIsShowToastMessage(true);
          } else {
            setToastMessage({
              status: 'error',
              title: 'Save as Draft Failed',
              message: res.data.message,
            });
            setIsShowToastMessage(true);
          }
        });
    },
    [campus],
  );

  const handleSearchGrade = () => {
    const dataSearch = inputSearchRef.current.value.trim();
    if (!dataSearch) {
      listGrades.length !== listGradesInDatabase.length &&
        setListGrades(listGradesInDatabase);
      return;
    }
    const listSearchResults = listGradesInDatabase.filter((grade) => {
      return (
        grade.name.toLowerCase().includes(dataSearch.toLowerCase()) ||
        grade.code.toLowerCase().includes(dataSearch.toLowerCase()) ||
        dataSearch === grade.minAge ||
        dataSearch === grade.maxAge
      );
    });
    setListGrades(listSearchResults);
  };

  const handleUpdateClass = (data, idx) => {
    setIndex(idx);
    setClassInfo(data);
    setVisible(true);
  };
  const handleAddClass = (classDetail) => {
    const index = dataClass.findIndex((data) => {
      return data.programGradeId === classDetail.programGradeId;
    });
    if (index === -1) {
      setDataClass((prevState) => {
        return [...prevState, classDetail];
      });
    } else {
      const data = [...dataClass];
      data[index] = classDetail;
      setDataClass(data);
    }
  };

  const handleClickCancel = () => {
    setIsCancel(!isCancel);
  };

  const handleSaveAsDraft = async () => {
    return
    if (dataClass.length === 0) {
      return;
    }
    const now = new Date();
    const timeString = now.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    const info = [];
    const data = dataClass.map((item) => {
      return {
        ...(({ index, dateCreated, dateUpdated, ...o }) => {
          const type = o.hasOwnProperty('id') ? 'Edit' : 'Add';
          const dataInfo = {
            title: `Update ${o.extraInfo.programName}`,
            subtitle: `${type} ${o.extraInfo.gradeName}`,
            time: timeString,
            type,
          };
          info.push(dataInfo);
          return o;
        })(item),
      };
    });
    const infoChanges = {
      name: 'Change class',
      changes: info,
    };

    await sendDraft({ info: infoChanges, data });
    await getDraftsList();
  };

  const handelSendForApproval = () => {
    if (dataClass.length === 0) {
      return;
    }
    const data = dataClass.map((item) => {
      return { ...(({ index, dateCreated, dateUpdated, ...o }) => o)(item) };
    });
    const dataApi = {
      name: `Edit or Create Classes`,
      actionId: 4, // action id update
      changeContent: {
        actionName: 'Edit Class',
        apiURL: process.env.REACT_APP_URL_API_CENTRAL_ADMIN,
        endpoint: '/api/v2/admission/classes',
        apiMethod: 'POST',
        content: {
          data,
        },
        params: { campusId: campus?.isCentral ? undefined : campus?.id },
      },
      keyFields: [],
    };
    centralAdminApi.createClass({ campusId: campus?.isCentral ? undefined : campus?.id }, { data })
      .then((res) => {
        if (res.data.success) {
          setToastMessage({
            status: 'success',
            title: 'Create Or Update Classes Successfully',
            // title: 'Send For Approval Successfully',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
          setDataClass([]);
          handleClickCancel();
          // getDataFormApi();
        } else {
          setToastMessage({
            status: 'error',
            title: 'Create Or Update Classes Failed',
            // title: 'Send For Approval Failed',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Send For Approval Failed',
          message: error.response?.data?.message || error,
        });
        console.log('Program Classes Error', error);
      });
  };

  useEffect(() => {
    const campusId = campus?.isCentral ? undefined : campus?.id
    const resProgram = centralAdminApi.getListProgram({ campusId, isGetAll: true });
    const resGrade = centralAdminApi.getListGrade({ campusId, isGetAll: true });
    const resGradeProgram = centralAdminApi.getListProgramGrade({ campusId, isGetAll: true });

    const listAPI = [resProgram, resGrade, resGradeProgram];
    if (isRenderDraft) {
      const getDraftClass = centralAdminApi.getClassDraft({ campusId }, draftId);
      listAPI.push(getDraftClass);
    }
    Promise.all(listAPI)
      .then((res) => {
        if (checkErrorMultipleApiFetch(res, setToastMessage, setIsShowToastMessage)) {
          setListProgrammes(res[0].data.data.objects);
          setListGrades(res[1].data.data.objects);
          setListGradesInDatabase(res[1].data.data.objects);

          const listGradeProgrammes = res[2].data.data.objects;

          if (isRenderDraft) {
            const dataDraft = JSON.parse(res[3].data.data.data);
            const result = cloneDeep(listGradeProgrammes);
            for (const [idx, obj] of listGradeProgrammes.entries()) {
              const oldClass = obj.programGrade[0].class;
              for (const classOfGrade of dataDraft) {
                if (oldClass && oldClass.id === classOfGrade.id) {
                  delete classOfGrade.extraInfo;
                  const dataClass = {
                    ...oldClass,
                    ...classOfGrade,
                  };
                  result[idx].programGrade[0].class = dataClass;
                }
                if (!oldClass && obj.programGrade[0].id === classOfGrade.programGradeId) {
                  delete classOfGrade.extraInfo;
                  result[idx].programGrade[0].class = classOfGrade;
                }
              }
            }
            setListGradeProgrammes(result);
          } else {
            setListGradeProgrammes(listGradeProgrammes);
          }
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Program Classes Error', error);
      });
  }, [isCancel, isAddProgram, isRenderDraft, campus, isRefresh]);

  useEffect(() => {
    const slider = document.querySelector('.parent');
    let mouseDown = false;
    let startX, scrollLeft;

    let startDragging = function (e) {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };
    let stopDragging = function (event) {
      mouseDown = false;
    };

    slider.addEventListener('mousemove', (e) => {
      e.preventDefault();
      if (!mouseDown) {
        return;
      }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    });

    // Add the event listeners
    slider.addEventListener('mousedown', startDragging, false);
    slider.addEventListener('mouseup', stopDragging, false);
    slider.addEventListener('mouseleave', stopDragging, false);

    // handle Show Text Approval
    // callApi({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/process/search`,
    //   params: { actionId: 4, campusId: campus?.isCentral ? undefined : campus?.id },
    // })
    approvalApi.getApprovalProcess({ actionId: 4, campusId: campus?.isCentral ? undefined : campus?.id })
      .then((res) => {
        if (res.data.success) {
          setIsShowTextApproval(!!Object.keys(res.data.data || {}).length);
        }
      })
      .catch((error) => {
        console.log('Get Process Failed', error);
      });
  }, [campus]);

  // useEffect(() => {
  //   getDraftsList();
  // }, [campus]);

  return (
    <div
      className={clsx(
        'program-classes relative mt-8 mx-12',
        burger ? '1400px:ml-72' : '1400px:ml-32',
      )}
    >
      {modalAddProgram.isOpen && (
        <div className="add-program fixed top-0 left-0 w-[100vw] h-[100vh] z-[51]" >
          <AddProgramModal
            modalAddProgram={modalAddProgram}
            setModalAddProgram={setModalAddProgram}
            setIsAddProgram={setIsAddProgram}
          />
        </div>
      )}
      <Fade clear duration={300}>
        <div className="" style={{ height: '11rem', paddingTop: '3rem' }}>
          <NavLinks urls={dataLinks.classesLinks} />
          <div className="flex items-center justify-between">
            <h1 className="text-page-name namepage">Grades and Programs2</h1>
            <p
              onClick={() => {
                setModalAddProgram({ isOpen: true, state: {}, firstName: "", id: "" });
              }}
              className="cursor-pointer text-gray-500"
            >
              + Add Program
            </p>
          </div>
        </div>
        <div className="flex max-h-full overflow-y-auto overflow-x-hidden pt-3 pl-6">
          <div className="program-classes-search w-72 border-2 border-t-0">
            <div className="flex justify-between p-2">
              <div className="flex border-b-2 border-neutral-600 w-56">
                <img
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    inputSearchRef.current.focus();
                  }}
                  className="program-classes-search-input_img cursor-pointer "
                  src={searchgray}
                  alt="search"
                />
                <input
                  onKeyDown={(e) => {
                    e.stopPropagation();
                    if (e.key === 'Enter') {
                      handleSearchGrade();
                    }
                  }}
                  onBlur={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (inputSearchRef.current.value.trim() === '') {
                      setListGrades(listGradesInDatabase);
                    }
                  }}
                  ref={inputSearchRef}
                  className="program-classes-search-input_input w-48 "
                  placeholder="Search"
                />
              </div>
              <img className="" src={bifilterleft} alt="filter" />
            </div>
            {listGrades.map((item, key) => (
              <React.Fragment key={key}>
                {key === 0 && <hr className="hr-top" />}
                <div className="program-classes-search-card">
                  <div className="w-full">
                    <h5>{item.name}</h5>
                    <p>
                      <span>Age Group </span>
                      <strong className=" text-black">
                        {item.minAge}-{item.maxAge} Years
                      </strong>
                    </p>
                  </div>
                </div>
                {key !== listGrades.length - 1 && <hr className="hr-bottom " />}
              </React.Fragment>
            ))}
          </div>
          <div
            className={clsx(
              'parent table-class program overflow-x-auto pr-3'
            )}
            style={{
              width: 'calc(100% - 20rem)'
            }}
          >
            <table>
              <thead className="h-[8.75rem]">
                <tr
                // style={{ position: '-webkit-sticky', position: 'sticky', top: '20px' }}
                >
                  {listProgrammes.map((item, key) => (
                    <th key={key}>
                      <ProdCard
                        setModalAddProgram={setModalAddProgram}
                        firstHeader="Programmes"
                        firstName={item.name}
                        modal={true}
                        access={false}
                        programId={item.id}
                        // opinio={item.opinio}
                        secondHeader="Age Group"
                        secondName={`${item.minAge}-${item.maxAge} Years`}
                      />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {listGrades.map((item, key) => {
                  return (
                    <GradesCard
                      setIndex={setIndex}
                      key={key}
                      grade={item}
                      index={key}
                      listProgrammes={listProgrammes}
                      listGradeProgrammes={listGradeProgrammes}
                      handleUpdateClass={handleUpdateClass}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Fade>
      <div className="w-full h-20" />
      {(dataClass.length !== 0 || !!numberOfEdits) && (
        <Footer
          isStorybook={true}
          isApproval={isShowTextApproval}
          burger={burger}
          numberOfEdits={numberOfEdits}
          draftsList={draftsList}
          handleSaveAsDraft={handleSaveAsDraft}
          handleClickCancel={handleClickCancel}
          handelSendForApproval={handelSendForApproval}
          setDraftId={setDraftId}
          setIsRenderDraft={setIsRenderDraft}
          style={{ width: '100%', marginLeft: burger ? '8rem' : '' }}
        />
      )}
      {visible && <div className="fixed inset-0 bg-slate-200/[.4]" />}
      {visible && (
        <Modal open={visible} onClose={() => setVisible(!visible)}>
          <div>
            <CreateClassPanel
              idx={index}
              visible={visible}
              setVisible={setVisible}
              classInfo={classInfo}
              setListGradeProgrammes={setListGradeProgrammes}
              handleAddClass={handleAddClass}
              setIsRefresh={setIsRefresh}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}

ProgramClasses.propTypes = {
  burger: PropTypes.bool,
};
