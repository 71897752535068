import { Input, SelectGroup } from '@stories/index';
import MandatoryFields from './mandatoryFields';
import { close_third, close_x_red } from '@static/image';
import { useCallback } from 'react';

export default function Criteria(props) {
  const { indexFile, file, itemSetting, setItemSetting } = props;

  const formatOptions = [
    { value: 'Passport', label: 'Passport' },
    { value: 'Drivers License', label: 'Drivers License' },
    { value: 'Aadhaar', label: 'Aadhaar' },
    { value: 'PAN', label: 'PAN' },
    { value: 'SSN', label: 'SSN' },
  ];

  const handleDelete = useCallback((indexFile) => {
    setItemSetting((prev) => {
      const newFileRequirement = prev.fileRequirement.filter((item, index) => index !== indexFile)
      return {
        ...prev,
        fileRequirement: newFileRequirement,
      };
    });
  }, [])

  if (itemSetting?.fileRequirement[indexFile]) {
    return (
      <div className="criteria p-4 m-4 mb-[3rem]">
        <div className="criteria-header justify-end">
          {/* <h4 className="criteria-header_title"></h4> */}
          <button
            onClick={(e) => {
              handleDelete(indexFile)
            }}
          >
            <img
              src={close_x_red}
            />
          </button>
        </div>
        <div className="flex flex-wrap justify-between">
          <div className="document-settings-left">
            <SelectGroup
              title="Format"
              value={
                file?.format
                  ? {
                    value: file?.format,
                    label: file?.format,
                  }
                  : formatOptions[0]
              }
              options={formatOptions}
              onChange={(e) => {
                setItemSetting((prev) => {
                  const fileRequirement = prev.fileRequirement;
                  fileRequirement[indexFile].format = e.value;
                  return {
                    ...prev,
                    fileRequirement,
                  };
                });
              }}
              styles={{
                control: (style) => ({
                  ...style,
                  height: '2.8rem'
                })
              }}
              customStyle={{
                marginTop: '0.5rem',
                width: '100%',
                borderRadius: '0.375rem',
                fontSize: 14,
                fontSize: '0.875rem',
              }}
            />
          </div>
          <div className="document-settings-right">
            <Input
              label="File Document Name"
              name="file-document-name"
              type="text"
              value={file?.fileName}
              onChange={(e) => {
                setItemSetting((prev) => {
                  const fileRequirement = prev.fileRequirement;
                  fileRequirement[indexFile].fileName = e.target.value;
                  return {
                    ...prev,
                    fileRequirement,
                  };
                });
              }}
              customStyle={{
                marginTop: '0.5rem',
                width: '19.5rem',
                height: '2.8rem',
                fontSize: '0.875rem',
                borderRadius: '0.25rem',
              }}
            />
          </div>
        </div>
        {file?.mandatoryFields && (
          <div className='w-[95%]'>
            <div className="criteria-header">
              <h4 className="criteria-header_title mt-4">Mandatory Fields</h4>
              <button
                onClick={(e) => {
                  setItemSetting((prev) => {
                    const fileRequirement = prev.fileRequirement;
                    fileRequirement[indexFile].mandatoryFields = [];
                    return {
                      ...prev,
                      fileRequirement,
                    };
                  });
                }}
              >
                <img
                  src={close_third}
                />
              </button>

            </div>
            <div className='w-[75%] m-w-[40.5625rem]'>
              <MandatoryFields
                indexFileRequirement={indexFile}
                mandatoryFields={file?.mandatoryFields}
                setItemSetting={setItemSetting}
              />
            </div>
          </div>
        )}

        {/* <div>
          {file?.mandatoryFields && (
            <MandatoryFields
              indexFileRequirement={indexFile}
              mandatoryFields={file?.mandatoryFields}
              setItemSetting={setItemSetting}
            />
          )}
        </div> */}
      </div>
    );
  }
}
