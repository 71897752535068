import React, { useContext, useEffect, useState } from 'react';
import Zoom from 'react-reveal/Zoom';
import clsx from 'clsx';
import { mailunreadfill } from '../image';
import { Fade } from 'react-reveal';
import SendEmailPopup from './send-email';
import ButtonBottom from './button-bottom';
import SingleMail from './single-mail';
import { ToastMessageContext } from 'context/toast-context';
import admissionApi from 'api/admission';

const MessagePick = (props) => {
  const {
    isVerifyEmail,
    visible,
    setVisible = () => { },
    enquiry = {},
    handleOpenEmail = () => { },
    parents = [],
  } = props;

  const [refresh, setRefresh] = useState(false);
  const [listEmails, setListEmails] = useState([]);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const onToggleAddEvent = () => {
    setVisible((prev) => ({ ...prev, sendEmail: !prev?.sendEmail }));
  };

  const handleRefresh = () => {
    onToggleAddEvent();
    setRefresh(prev => !prev);
  }

  useEffect(() => {
    if (enquiry.id) {
      admissionApi.getListEmail({ enquiryId: enquiry.id })
        .then((results) => {
          if (results?.data?.success) {
            if (Array.isArray(results?.data?.data)) {
              setListEmails(results?.data?.data);
            }
          } else {
            setToastMessage({
              status: 'error',
              title: 'Get List Email Failed',
              message: results.data.message,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Get List Email  Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Get List Error', error);
        });
    }
  }, [enquiry, refresh, isVerifyEmail]);

  return (
    <>
      <Zoom bottom duration={700} when={visible.message}>
        <div
          className={clsx(
            'windows-field w-[400px]',
            visible.message ? 'pointer-events-auto' : 'pointer-events-none hidden',
          )}
        >
          <div className="flex items-center justify-between pt-5 pl-5 pr-5">
            <div className="flex item">
              <div
                className="w-10 h-10 mr-3 flex items-center justify-center rounded-lg mb-7"
                style={{ background: '#C8FACD' }}
              >
                <img src={mailunreadfill} alt="" />
              </div>
              <p className="font-semibold mt-2.5">Email</p>
            </div>
            <button
              onClick={onToggleAddEvent}
              className="bg-main-blue w-6 h-6 text-white text-xl flex items-center justify-center rounded-full mb-9"
            >
              +
            </button>
          </div>
          <div className="h-[70%] max-h-[30rem] overflow-auto px-5 mb-5 flex flex-col">
            {(listEmails || []).map((dt) => {
              return <SingleMail key={dt.id} emailData={dt} />;
            })}
          </div>
          <ButtonBottom titleButton="Message" onClick={onToggleAddEvent} />
        </div>
      </Zoom>
      <Fade right when={visible.sendEmail} duration={500}>
        <div
          onClick={onToggleAddEvent}
          className={clsx(
            'fixed top-0 inset-x-0 inset-y-0 bg-slate-100/70 z-30',
            visible.sendEmail ? 'block' : 'hidden pointer-events-none',
          )}
        ></div>
        <div
          className={clsx(
            'shadow bg-white rounded-md',
            visible.sendEmail ? 'block' : 'hidden pointer-events-none',
          )}
          style={{
            width: '720px',
            // height: '40.75rem',
            position: 'fixed',
            zIndex: '33',
            top: `calc(50% - 12.375rem)`,
            left: `calc(50% - 15.85rem)`,
            boxSizing: 'border-box',
          }}
        >
          <SendEmailPopup
            handleRefresh={handleRefresh}
            enquiryId={enquiry.id}
            parents={parents}
            onToggleAddEvent={onToggleAddEvent}
          />
        </div>
      </Fade>
    </>
  );
};

export default MessagePick;
