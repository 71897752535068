import clsx from 'clsx';
import React, { memo } from 'react';
import { buttoncheck } from '../../pages/bloomingdale/image';

const CheckboxOutline = memo((props) => {
  const { value, label, onChange } = props;
  return (
    <button
      className={clsx('group-button none-after', value && 'group-button--active')}
      onClick={() => {
        onChange(label);
      }}
    >
      {label}
      {value && <img src={buttoncheck} className="absolute w-3.5 h-3.5 top-1 right-1" />}
    </button>
  );
});
CheckboxOutline.displayName = 'CheckboxOutline';
export default CheckboxOutline;
