import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { NavLink } from 'react-router-dom';
import { radio } from '../../../static/image';
import './studentCard.scss';

const StudentCard = ({ avatar, name, code, year, pending }) => {
  return (
    <div
      className={clsx(
        'student-card ',
        pending === 'Pending'
          ? 'student-card--pending'
          : pending === 'Failed'
            ? 'student-card--failed'
            : '',
      )}
    >
      <img
        src={avatar}
        alt="student avatar"
        className="student-card_avatar w-[10%] aspect-square"
      />
      <div className="student-card-info w-[80%]">
        <div className="w-[65%]">
          <h5 className="student-card-info_name">{name}</h5>
          <div className="student-card-info-bottom">
            <div className="student-card-info_code">{code}</div>
            <div className="student-card-info_year">{year}</div>
          </div>
        </div>
        <div className="student-card-info_status w-[30%]">
          <img src={radio} alt="radio icon" />
          <span className="text-sm">{pending}</span>
        </div>
      </div>
      <div className="w-[10%]">
        {/* <NavLink to=urls.parent.dashboard className="student-card_btn none-after"> */}
        <Arrow />
        {/* </NavLink> */}
      </div>
    </div>
  );
};

StudentCard.propTypes = {
  name: PropTypes.string,
  code: PropTypes.string,
  year: PropTypes.string,
  pending: PropTypes.string,
};

// StudentCard.defaultProps = {
//   avatar: 'https://place-hold.it/50x50',
//   name: 'student name',
//   code: 'XX0',
//   year: '2000',
//   pending: 'Completed',
// };

export default StudentCard;

const Arrow = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 -2 24 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.55 2.5L7 4.05L14.95 12L7 19.95L8.55 21.5L18 12L8.55 2.5Z"
        fill="#1f0cdc"
      />
    </svg>
  );
};
