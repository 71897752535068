import React, { memo } from 'react';
import ContentGroup from '../content-group/content-group';

const LearningInfo = memo((props) => {
  const { info, variation, listField, handleChange } = props;
  return listField.map((item, key) => {
    return (
      <ContentGroup
        key={`learning${key}`}
        data={item}
        info={info?.[item.name]}
        variation={variation}
        handleChange={(e) => {
          handleChange({ name: 'learningInfo', key: item.name, value: e });
        }}
      />
    );
  });
});
LearningInfo.displayName = 'LearningInfo';
export default LearningInfo;
