import { useContext, useEffect, useState } from 'react';
import { pause, play } from '@static/image';
import { ToastMessageContext } from 'context/toast-context';
import taskApi from 'api/task';
import useStorage from 'store/storage';

const styles = { main: { height: '2rem' } };
let isAddTime = true;

const NewTimer = (props) => {
  const {
    taskDetail,
    setStatus = () => { },
    updatetaskDetail = () => { },
    isWorking,
    startWorkTime = 0,
    startTime,
    endTime,
    setStartTime = () => { },
    isSetTime = false,
    isShowControl = true,
  } = props;
  const [time, setTime] = useState(0);
  const [start, setStart] = useState(isWorking);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const campus = useStorage((state) => state.currentCampus);
  useEffect(() => {
    let currentTime = 0;
    if (isWorking) {
      currentTime = (taskDetail.minutesWorked + startWorkTime) * 60;
    } else {
      currentTime = taskDetail.minutesWorked * 60;
    }
    setTime(currentTime);
  }, [taskDetail]);

  useEffect(() => {
    setStart(isWorking);
  }, [isWorking]);

  function convertTime(seconds) {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let sec = Math.floor(seconds % 60);

    if (isNaN(hours) === true && isNaN(minutes) === true && isNaN(sec) === true) {
      return '00:00:00';
    } else {
      if (hours < 10) {
        hours = '0' + hours;
      }
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (sec < 10) {
        sec = '0' + sec;
      }
      return hours + ':' + minutes + ':' + sec;
    }
  }

  useEffect(() => {
    let interval = null;

    if (start) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      setStartTime(time);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [start]);

  const handleUpdateTimeWorkOfTask = (status) => {
    taskApi.updateTimeWork({ campusId: campus?.isCentral ? undefined : campus?.id }, { isWorking: status }, taskDetail.id)
      .then((res) => {
        if (res?.data?.success) {
          updatetaskDetail((prevState) => {
            return { ...prevState, ...res.data.data };
          });
          setTime(res.data.data.minutesWorked * 60);
          // isAddTime = false;
        } else {
          setStart((prev) => !prev);
          setToastMessage({
            status: 'error',
            title: 'Start/Pause Working Time Failed',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        console.log('Start or pause task error: ', error);
        setStart((prev) => !prev);
        setToastMessage({
          status: 'error',
          title: 'Start/Pause Working Time Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      });
  };

  return (
    <div
      className="border rounded flex items-center w-[9.5rem] 2xl:w-[10.5rem] 1620px:w-[11rem]"
      style={styles.main}
    >
      <p className="text-xs mx-1 2xl:mx-4">
        <span style={{ color: '#27C59A', fontSize: 12 }}>{convertTime(time)}</span> /{' '}
        <span style={{ fontSize: 12 }}>
          {convertTime(taskDetail.minutesEstimate * 60)}
        </span>
      </p>
      {!isSetTime && (
        <button
          onClick={() => {
            if (taskDetail.status === 'Done') {
              setToastMessage({
                status: 'warning',
                title: 'Validation Rule',
                message: 'Can not start task is finished!',
              });
              setIsShowToastMessage(true);
              return;
            }
            setStart(!start);
            handleUpdateTimeWorkOfTask(!start);
          }}
        >
          {isShowControl && <img src={start ? pause : play} alt="timer" />}
        </button>
      )}
    </div>
  );
};

export default NewTimer;
