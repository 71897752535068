import { useLocation } from 'react-router-dom';
import CampusUsers from './components/campus-users/campus-users';
import useStorage from 'store/storage';

export default function CampusListUsers({ burger }) {
  const location = useLocation();
  const currentCampus = useStorage((state) => state.currentCampus);
  const campus = location?.state?.selectCampus || currentCampus;

  return <CampusUsers campus={campus} burger={burger} />;
}
