import { useContext, useRef } from 'react';
import { Button, Input } from '@stories/index';
import { checkErrorSingleApi } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { toggleButtonStyles } from '../../../../entities/select-style';
import taskApi from 'api/task';
import useStorage from 'store/storage';

function UpdateTaskTime(props) {
  const {
    initialSelect,
    isSelect,
    setIsSelect,
    task,
    index,
    setRefresh,
    setIsUpdate = () => { },
    setPage = () => { },
  } = props;
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campus = useStorage((state) => state.currentCampus);
  const hourRef = useRef();
  const minuteRef = useRef();

  const handleUpdateTaskTime = () => {
    if (+hourRef.current.value === 0 && +minuteRef.current.value === 0) {
      setToastMessage({
        status: 'warning',
        title: 'Validation Rule',
        message: 'Please estimate time',
      });
      setIsShowToastMessage(true);
      return;
    }
    if (!hourRef.current.value && !minuteRef.current.value) {
      setToastMessage({
        status: 'warning',
        title: 'Validation Rule',
        message: 'Please estimate time',
      });
      setIsShowToastMessage(true);
      return;
    }
    const time = +hourRef.current.value * 60 + +minuteRef.current.value;

    taskApi.updateTaskById({ campusId: campus?.isCentral ? undefined : campus?.id }, { minutesEstimate: time }, task.id)
      .then((res) => {
        if (
          checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, 'Update Task')
        ) {
          setIsSelect(initialSelect);
          setRefresh((prev) => ({
            ...prev,
            count: (prev.count || 0) + 1,
            index,
          }));
          setIsUpdate((prev) => !prev);
          setPage(0);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Update Task Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Update task error', error.message);
      });
  };

  return (
    <>
      {isSelect.time && (
        <div className="flex flex-wrap justify-center absolute top-10 left-[-5rem] w-[20rem] h-32 bg-[#fff] shadow-2xl z-20 rounded">
          <div className="flex mt-6 ml-3">
            <Input
              refCustom={hourRef}
              onChange={(e) => {
                //   setUser({ ...user, firstName: e.target.value });
              }}
              //   labelFix="top-[52%] custom"
              label="Hours"
              name="hours"
              type="number"
              min="0"
              // value=""
              customStyle={{
                width: '8.25rem',
                height: '2rem',
                fontSize: '0.675rem',
                borderRadius: '0.25rem',
              }}
            // error={error.statusFirstName}
            />
            <Input
              refCustom={minuteRef}
              onChange={(e) => {
                //   setUser({ ...user, lastName: e.target.value });
              }}
              labelFix="left-10"
              label="Minutes"
              name="minutes"
              type="number"
              min="0"
              max="59"
              // value=""
              customStyle={{
                width: '8.25rem',
                height: '2rem',
                marginLeft: '1.75rem',
                fontSize: '0.675rem',
                borderRadius: '0.25rem',
              }}
            // error={error.statusLastName}
            />
          </div>
          <div className="flex justify-center mt-4">
            <Button
              className="text-[0.8rem]"
              text="Cancel"
              customStyle={{
                ...toggleButtonStyles.btnWhite,
                width: '5rem',
                height: '1.5rem',
              }}
              onClick={() => {
                setIsSelect(initialSelect);
              }}
            />
            <Button
              className="text-[0.8rem]"
              text="Save"
              customStyle={{
                ...toggleButtonStyles.btnBlue,
                width: '5rem',
                height: '1.5rem',
              }}
              onClick={handleUpdateTaskTime}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default UpdateTaskTime;
