import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal';
import { OverviewCard } from '@stories/index';
import { checkErrorApiFetch } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import './admissions-overview.scss';
import clsx from 'clsx';
import admissionApi from 'api/admission';
import useStorage from 'store/storage';
import { Typography } from '@mui/material';

export default function AdmissionsOverview({ burger }) {
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [dataAdmissionOverview, setDataAdmissionOverview] = useState(null);
  const campus = useStorage((state) => state.currentCampus);

  useEffect(() => {
    admissionApi.getAdmissionOverview({ campusId: campus?.isCentral ? undefined : campus?.id })
      .then((res) => {
        if (
          checkErrorApiFetch(
            res,
            setToastMessage,
            setIsShowToastMessage,
            'Get Enquiry Overview',
          )
        ) {
          if (res?.data?.data && res.data.data.length !== 0) {
            const sorted = res.data.data.sort((a, b) => a?.grade?.minAge - b?.grade?.minAge)
            setDataAdmissionOverview(sorted);
          } else setDataAdmissionOverview([])
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Get Enquiry Overview Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Get Enquiry Overview Error', error);
      });
  }, [campus]);


  return (
    <div className={clsx('admissions-overview transition-all-300 pl-16 pr-12', burger ? '1400px:pl-[19.5rem]' : '1400px:pl-[9rem]')}>
      <h2 className="namepage">Admissions Overview</h2>
      <Fade clear duration={300}>
        <div className='pb-8'>
          {dataAdmissionOverview && dataAdmissionOverview.length === 0 && <Typography variant="h6" className="text-center">No Enquiry</Typography>}
          {dataAdmissionOverview && dataAdmissionOverview.length !== 0 && (
            dataAdmissionOverview.map((item, key) => (
              <OverviewCard data={item} key={key} />
            ))
          )}
        </div>
      </Fade>
      {/* <ApplicationPanel /> */}
    </div>
  );
}

AdmissionsOverview.propTypes = {
  burger: PropTypes.bool,
};
