import clsx from 'clsx';
import React, { useState, useEffect, memo } from 'react';
import { useCallback } from 'react';
import useDebounce from '../../../../../utils/custom-hook/useDebounce';
import { camelToKebabCase } from '../../../../../utils/utils';

const StyleSection = (props) => {
  const { data, order, eleFocus, setListForm, setRefresh, refreshProperty } = props;
  const [objGroupStyle, setObjGroupStyle] = useState({});
  const [open, setOpen] = useState({
    general: true,
    margin: true,
  });

  const findById = useCallback((data, id) => {
    for (const item of data) {
      const { id: itemId, children } = item;
      if (itemId === id) return item;
      if (children) {
        if (children.length !== 0) findById(children, id);
      }
    }
  }, []);

  const debounce = useDebounce(({ eleFocus, styleName, value }) => {
    setListForm((prev) => {
      prev[eleFocus.order][eleFocus.index].styles[styleName] = value;
      return prev;
    });
    setRefresh((prev) => ({
      ...prev,
      [order]: prev[order] + 1,
    }));
  });

  const handleChange = useCallback(
    ({ group, styleName, value }) => {
      setObjGroupStyle((prev) => ({
        ...prev,
        [group]: {
          ...prev[group],
          [styleName]: value,
        },
      }));
      debounce({ eleFocus, styleName, value });
    },
    [debounce, eleFocus],
  );

  const getGroupStyle = useCallback((data) => {
    const result = {};
    if (data) {
      Object.keys(data).forEach((styleName) => {
        if (['width', 'height', 'backgroundColor'].includes(styleName)) {
          if (!result.general) result.general = {};
          result.general[styleName] = data[styleName];
        }
        if (styleName.includes('margin')) {
          if (!result.margin) result.margin = {};
          result.margin[styleName] = data[styleName];
        }
        if (styleName.includes('padding')) {
          if (!result.padding) result.padding = {};
          result.padding[styleName] = data[styleName];
        }
      });
    }

    return result;
  }, []);

  useEffect(() => {
    setObjGroupStyle(getGroupStyle(data));
  }, [data, getGroupStyle, refreshProperty]);

  const handleToggleOpen = useCallback((key) => {
    setOpen((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  }, []);

  if (!data)
    return (
      <div className="px-4">
        <p>Please click on any of the components</p>
      </div>
    );

  return (
    <div className="px-4">
      {Object.keys(objGroupStyle).map((name, index) => {
        return (
          <GroupStyle
            key={name}
            groupName={name}
            objStyle={objGroupStyle[name]}
            isOpen={open[name]}
            handleChange={handleChange}
            handleToggleOpen={handleToggleOpen}
          />
        );
      })}
    </div>
  );
};
export default StyleSection;

const GroupStyle = memo((props) => {
  const { groupName, objStyle, isOpen, handleChange, handleToggleOpen } = props;

  const getTypeInput = useCallback((styleName) => {
    if (['color', 'backgroundColor'].includes(styleName)) return 'color';
    return 'text';
  }, []);

  return (
    <div className="mb-6">
      <div className="flex" onClick={() => handleToggleOpen(groupName)}>
        <p>{groupName}</p>
      </div>
      <hr className="mb-0" />
      <div
        className={clsx(
          'grid grid-cols-2 gap-x-5 duration-300',
        )}
      >
        {Object.keys(objStyle).map((name, index) => {
          return (
            <SingleStyle
              key={`${name} ${index}`}
              groupName={groupName}
              styleName={name}
              value={objStyle[name]}
              type={getTypeInput(name)}
              handleChange={handleChange}
            />
          );
        })}
      </div>
    </div>
  );
});
GroupStyle.displayName = 'GroupStyle';

const SingleStyle = memo((props) => {
  const { groupName, styleName, value, type, handleChange } = props;
  return (
    <div className="w-full mt-2">
      <p className="w-full">{camelToKebabCase(styleName)}</p>
      <input
        className="w-full text-center"
        style={{
          outline: '1px solid black',
        }}
        type={type}
        value={value}
        onChange={(e) => {
          const value = e.target.value;
          handleChange({ group: groupName, styleName, value });
        }}
      />
    </div>
  );
});
SingleStyle.displayName = 'SingleStyle';
