import { BASE_CAMPUS_FINANCE_URL, BASE_CENTRAL_FINANCE_URL } from 'entities/urls';
import { memo, useCallback } from 'react';
import SwitchCampus from '../SwitchCampus/SwitchCampus.jsx';
import SwitchCampusAndFinance from '../SwitchCampusAndFinance/SwitchCampusAndFinance.jsx';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min.js';

const UserCardSwitch = memo((props) => {
  const { openList, setOpenList } = props;
  const location = useLocation()
  const pathname = location.pathname
  let type = 'campus'
  if (pathname.includes(BASE_CENTRAL_FINANCE_URL + '/') ||
    pathname.includes(BASE_CAMPUS_FINANCE_URL + '/')) type = 'finance'

  const onClickListCampus = useCallback(() => {
    setOpenList(prev => ({ ...prev, campus: !prev.campus, year: false }))
  }, [])

  const onClickListYear = useCallback(() => {
    setOpenList(prev => ({ ...prev, year: !prev.year, campus: false }))
  }, [])

  if (type === 'finance') return <SwitchCampusAndFinance openList={openList}
    onSwitchCampus={onClickListCampus} onSwitchYear={onClickListYear} />
  return <SwitchCampus openPopup={openList.campus} onClick={onClickListCampus} />
});
UserCardSwitch.displayName = 'UserCardSwitch';
export default UserCardSwitch;