import PropTypes from 'prop-types';
import React from 'react';
import { peoplefill } from '../../static/image';
import './overviewcard.scss';
import OverviewCardWindow from './overviewcardwindow.jsx';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { urls } from 'entities/urls';
import { EnquiryStatus } from 'entities/data';
import useStorage from 'store/storage';

const OverviewCard = ({ data }) => {
  const history = useHistory()
  const campus = useStorage((state) => state.currentCampus);
  const overviewData = [
    { title: 'Enquiries', number: data.overview?.enquiries || 0 },
    {
      title: 'Application',
      number: data.overview?.Application || 0 + data.overview?.New || 0,
    },
    { title: 'E Signature', number: data.overview?.Signature || 0 },
    { title: 'Exam', number: data.overview?.Exam || 0 },
    { title: 'Approval', number: data.overview?.['Principal Approval'] || 0 },
    { title: 'Document', number: data.overview?.['Document Verification'] || 0 },
    { title: 'Fee Payment', number: data.overview?.['Fee Payment'] || 0 },
    {
      title: 'Closed',
      number: data.overview?.Closed || 0 + data.overview?.Complete || 0,
    },
    { title: 'Failed', number: data.overview?.Fail || 0 },
  ];

  const getCurrentStep = (title) => {
    if (!title) return ''
    switch (title) {
      case 'Enquiries':
        return ''
      case 'Application':
        return EnquiryStatus.NEW
      case 'E Signature':
        return EnquiryStatus.SIGNATURE
      case 'Exam':
        return EnquiryStatus.EXAM
      case 'Approval':
        return EnquiryStatus.PRINCIPAL_APPROVAL
      case 'Closed':
        return EnquiryStatus.COMPLETE
      case 'Failed':
        return EnquiryStatus.FAILED
      default:
        return ''
    }
  }

  const handleClick = (title) => {
    history.push(`${urls.campus.admission.applications}?campusId=${campus?.id}&programGradeId=${data?.id}&currentStep=${getCurrentStep(title)}`)
  }

  return (
    <div className="admission-overview-card">
      <div className="admission-overview-card-group w-[20rem]">
        <div className="flex flex-col">
          <h3 className="text-base text-main-black font-semibold">{data?.name}</h3>
          <p className="text-xs text-second-gray">{data?.programGrade?.program?.campus?.name}</p>
          <p className="text-xs text-second-gray">{data?.programGrade?.program?.name}</p>
        </div>
        <div className="flex items-center">
          <img src={peoplefill} alt="" />
          <div className="flex -space-x-2 overflow-hidden ml-2">
            {data.admissionTeamSetting?.counselors?.map((item) => (
              <img
                key={item.counselor?.id}
                title={item.counselor?.name}
                className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                src={item.counselor?.photoURL}
                alt=""
              />
            ))}
          </div>
        </div>
      </div>
      {overviewData.map((item, key) => (
        <OverviewCardWindow title={item.title} number={item.number} key={key} handleClick={() => handleClick(item.title)} />
      ))}
    </div>
  );
};

OverviewCard.propTypes = {
  /**  @param Type An object.
   * @param Object Keys for objects - data: array, title: string
   * @param data  objects. Keys for object - title: string, link: string, number: string
   * @param Example {
  data: {
    "program": {
                "id": "65d8952f-eefd-4439-972e-982e8d88e806",
                "name": "CBSE",
                "campus": {
                    "id": "474d44d3-d6ef-421d-aa7c-6b1c9fc82f2d",
                    "name": "Nalanda Vidyanikethan Primary"
                }
            },
            "grade": {
                "id": "5e447555-d263-4e79-b5d1-c3c20705a870",
                "name": "Pre Kindergarten 3"
            },
            "admissionTeamSetting": {
                "counselors": [
                    {
                        "counselor": {
                            "id": "011e7fbf-8bb5-4853-8942-298207cdcf1f",
                            "name": "Nal-AdmCoun Adm Coun",
                            "photoURL": "https://storage.googleapis.com/giiki-bucket/media/$2b$10$Q4UaiyVjNUo7xW8SeqItvuRhtDqOJR9fIJpfpvPrXoGvbr9ywBuPa"
                        }
                    }
                ]
            },
            "overview": {
                "enquiries": 1,
                "New": 1
            }
  },
  title: 'Playgroup',
  location: 'Bloomingdale, Gallileo'
}
   **/
  data: PropTypes.object,
};

export default OverviewCard;
