import { useRef, useCallback } from 'react';

export default function useDebounce(callback, delay = 500) {
  const time = useRef(null);

  const debounce = useCallback(
    (...args) => {
      if (time.current) {
        clearTimeout(time.current);
      }

      time.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay],
  );

  return debounce;
}
