import { mainavatar } from '@static/image';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';

const ApprovalStage = ({ title, subtitle, approvers }) => {
  return (
    <li className="admission-approvals-stages_column">
      <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
      <h4 className="admission-approvals-stages_title" style={{ lineHeight: '2.25rem', fontWeight: '700' }}>{title}</h4>
      <div className="admission-approvals-stages_list">
        {approvers?.map((item, idx) => {
          return (
            <img
              data-for="main"
              data-tip={item?.approver?.name || 'User Name'}
              data-iscapture="true"
              key={idx + '_' + item?.approver?.photoURL ?? 'No photo'}
              className="inline-block h-8 w-8 rounded-full ring-2 ring-white mb-[0.1875rem]"
              src={item?.approver?.photoURL || mainavatar}
              alt=""
            />
          );
        })}
      </div>
      <h6 className="admission-approvals-stages_subtitle" style={{ lineHeight: '1.75rem', fontWeight: '700' }}>{subtitle}</h6>
    </li>
  );
};

ApprovalStage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  data: PropTypes.array,
};

ApprovalStage.defaultProps = {
  title: 'Stage Name',
  title: 'Stage Subtitle',
  data: [
    {
      avatar: 'https://place-hold.it/32x32',
    },
    {
      avatar:
        'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fwww.djulideil.com%2Fbitches%2Ftochka-ru-sobaka-09.jpg&f=1&nofb=1',
    },
    {
      avatar: 'https://place-hold.it/32x32',
    },
  ],
};

export default ApprovalStage;
