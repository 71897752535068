import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { mainavatar } from '@static/image';
import { Checkbox } from '@stories/index';
import Pagination from '@stories/pagination/pagination';
import { checkErrorApiFetch } from '@utils/check-error/api-error';
import { rowsPerPageCount } from '@utils/storage';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_START } from '@utils/utils';
import { ToastMessageContext } from 'context/toast-context';
import { BASE_CENTRAL_URL, urls } from 'entities/urls';
import { listTypeOfRole } from '@constant/index';
import Loading from '@components/loading';
import './user-list.scss';
import authApi from 'api/auth';
import useStorage from 'store/storage';

export default function UserList({ burger }) {
  const [type, setType] = useState(listTypeOfRole[0]);
  const [companyUsers, setCompanyUsers] = useState([]);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const location = useLocation()
  const pathname = location.pathname
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_START);
  const [totalObjectsFromApi, setTotalObjectsFromApi] = useState(0);
  const userLogin = useStorage((state) => state.auth);
  const campus = useStorage((state) => state.currentCampus);
  const company = useStorage((state) => state.company);
  const isCentral = pathname.includes(BASE_CENTRAL_URL + '/');
  const isGiikiAdmin = useStorage((state) => state.isGiikiAdmin);

  const editUserPath = isGiikiAdmin ? urls.giiki.users.edit :
    (isCentral ? urls.central.setup.users.edit : urls.campus.setting.users.edit);

  const handleChangeRowsPerPage = (event) => {
    setSelectedRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };


  useEffect(() => {
    setIsLoading(true);
    // callApi({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/user/get-users-of-company`,
    //   params: {
    //     companyId: userLogin?.companyId,
    //     campusId: isCentral ? undefined : campus?.id,
    //     page: currentPage,
    //     pageSize: selectedRowsPerPage,
    //     filterRoleNames: type,
    //   },
    // })
    let objCallApi = {
      companyId: userLogin?.companyId,
      campusId: isCentral ? undefined : campus?.id,
      page: currentPage,
      pageSize: selectedRowsPerPage,
      filterRoleNames: type,
    };
    if (type === 'Teacher/Staff') {
      objCallApi = { ...objCallApi, isGetNoRole: true }
    }
    authApi.getListUserOfCompany(objCallApi)
      .then((res) => {
        if (
          checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, 'Get Users')
        ) {
          setCompanyUsers(res.data?.data?.objects || []);
          setTotalObjectsFromApi(res.data?.data?.total || 0);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Get User Of Company Or Campus Error',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        setIsLoading(false);
        console.log('User List Error', error);
      });
  }, [currentPage, type, selectedRowsPerPage, campus]);

  const handleDateTime = (date) => {
    let data;
    if (date) {
      data = new Date(date);
    } else {
      data = new Date();
    }
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const dateAdded = data.toLocaleDateString('en-GB', options);
    return `${dateAdded.slice(0, dateAdded.length - 5)}, ${dateAdded.slice(dateAdded.length - 5)}`;
  };

  const styles = {
    button: {
      width: '9.813rem',
      height: '2.75rem',
      background: '#663174',
      color: '#F1F8F5',
    },
  };

  return (
    <div className={clsx('user-list pr-24 pl-24 transition-all-300', burger ? '1400px:pl-80' : '1400px:pl-40')}>
      <div className="flex justify-items-center justify-between w-full">
        <h1 className="namespace">
          {company?.info?.name || 'User List'}
        </h1>
        <div className="flex">
          <button
            className="flex items-center justify-center rounded-lg"
            style={styles.button}
            onClick={() => {
              history.push(isGiikiAdmin ? urls.giiki.users.create
                : urls.central.setup.users.create,
                { companyId: userLogin.companyId });
            }}
          >
            Create Member
          </button>
        </div>
      </div>
      <div className="mb-5">
        {listTypeOfRole.map((item, key) => (
          <button
            className={clsx(
              'mr-7 h-12 border-b-2 transition-all none-after',
              item === type ? 'border-main-blue' : 'border-white',
            )}
            onClick={() => {
              setCompanyUsers([]);
              setCurrentPage(0);
              setType(item);
            }}
            key={key}
          >
            {item}
          </button>
        ))}
      </div>
      <div className='pl-4'>
        {
          type === 'Student' ? (
            <Student data={companyUsers} history={history} campus={campus} isLoading={isLoading} editUserPath={editUserPath} />
          ) : (
            <AllUser
              data={companyUsers}
              handleDateTime={handleDateTime}
              history={history}
              // campus={campus}
              // company={company}
              company={userLogin.companyId}
              tabtype={type}
              isLoading={isLoading}
              editUserPath={editUserPath} />
          )
        }
      </div>
      <div className="h-20"></div>
      <div className="datatable-footer">
        <Pagination
          customClass="fixed bottom-6 right-12 z-[3] pr-4"
          customStyle={{ height: '3rem' }}
          page={currentPage}
          rowsPerPage={selectedRowsPerPage}
          rowsPerPageCount={rowsPerPageCount}
          totalRows={totalObjectsFromApi}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
        />
      </div>
    </div >
  );
}

const AllUser = ({ data, handleDateTime, history, campus, company, tabtype, isLoading, editUserPath }) => {
  if (isLoading) {
    return (<div className="mt-10 flex items-center justify-center">
      <Loading />
    </div>)
  } else if (data && data.length > 0) {
    return (
      <table className="w-full">
        <thead className='border-b'>
          <tr className="h-14 relative text-main-black text-sm font-semibold">
            <th align="left">
              Avatar
            </th>
            <th align="left">Name</th>
            <th align="left">Role</th>
            <th align="left" className="relative">
              Date Added
            </th>
            <th align="left">Email Address</th>
            <th align="left">Status</th>
          </tr>
        </thead>
        <tbody>
          {data.map((user, key) => (
            <React.Fragment key={key}>
              <tr className="h-6" />
              <tr className="font-medium text-sm">
                <td className="max-w-[8%]">
                  <img
                    className="w-8 h-8 rounded-full"
                    src={user.photoURL ? user.photoURL : mainavatar}
                    alt="admins"
                  />
                </td>
                <td
                  className="text-left w-[12.25rem] cursor-pointer"
                  onClick={() => {
                    history.push(editUserPath, {
                      selectUser: user,
                      campus,
                      company
                    });
                  }}
                >
                  {user.name}
                </td>
                <td className="text-left max-w-[20%]">
                  {!user?.roles?.length && <p>No Role</p>}
                  {user?.roles?.length && user.roles.map((role) => {
                    return <p key={role.id}>{role.name}</p>;
                  })}
                </td>
                <td className="relative text-left max-w-[14%]">
                  {handleDateTime(user.dateCreated)}
                </td>
                <td className="text-left w-[22rem] break-words">{user.email}</td>
                <td
                  className="text-left max-w-[8%]"
                  style={
                    user.status === 'Active' ? { color: '#3A8B93' } : { color: '#6C0F00' }
                  }
                >
                  {user?.status && user.status ? user.status : 'Inactive'}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    );
  } else {
    return <div>Have No {tabtype}</div>
  }
};

const Student = ({ data, history, campus, isLoading, editUserPath }) => {
  if (isLoading) {
    return (<div className="mt-10 flex items-center justify-center">
      <Loading />
    </div>)
  } else if (data && data.length > 0) {
    return (
      data.length > 0 && (
        <table className="table-auto">
          <thead className="border-b">
            <tr className="h-14 relative text-main-black text-sm font-semibold">
              <th align="left" className="w-12">
                <Checkbox />
              </th>
              <th align="left">
                <div className="flex items-center">
                  Name <Arrow />
                </div>
              </th>
              <th align="center">Grade</th>
              <th align="center">Parents</th>
              <th align="center">Last Accessed</th>
              <th align="center">Status</th>
              <th align="center"></th>
            </tr>
          </thead>
          <tbody>
            {data.map((student, key) => (
              <tr
                key={key}
                className="h-16 relative left-5 text-main-black text-sm font-semibold"
              >
                <td className="">
                  <Checkbox />
                </td>
                <td className="text-center">
                  <div
                    className="flex items-center font-medium cursor-pointer"
                    onClick={() => {
                      history.push(editUserPath, { selectUser: student, campus });
                    }}
                  >
                    <img
                      src={student.photoURL ? student.photoURL : mainavatar}
                      alt="profile"
                      className="w-10 h-10 rounded-full mr-3.5"
                    />
                    {student.name}
                  </div>
                </td>
                <td className="text-center">{student.grade || '-'}</td>
                <td className="text-center">
                  {!student?.student?.studentParents ||
                    student.student.studentParents.length === 0
                    ? '-'
                    : student.student.studentParents.map((student) => {
                      return (
                        <p key={student.parent.id}>
                          {student.parent.middleName
                            ? `${student.parent.firstName} ${student.parent.middleName} ${student.parent.lastName}`
                            : `${student.parent.firstName} ${student.parent.lastName}`}
                        </p>
                      );
                    })}
                </td>
                <td className="text-center">
                  {student.lastAccessed
                    ? new Date(student.lastAccessed).toUTCString().slice(5, 16)
                    : '-'}
                </td>
                <td className="text-xs items-center">
                  <div
                    className="rounded flex items-center justify-center m-auto"
                    style={
                      student.status === 'Active'
                        ? {
                          background: 'rgba(84, 214, 44, 0.16)',
                          width: '3.75rem',
                          height: '1.375rem',
                          color: '#229A16',
                        }
                        : {
                          background: 'rgba(255, 72, 66, 0.16)',
                          width: '4.25rem',
                          height: '1.375rem',
                          color: '#B72136',
                        }
                    }
                  >
                    {student.status}
                  </div>
                </td>
                <td className="text-center">
                  <Crumbs />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    );
  } else {
    return <div>Have No Student</div>
  }
};

const Arrow = () => {
  return (
    <svg
      width="19"
      className="ml-1"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4443 9L14.3868 7.9425L10.1943 12.1275V3H8.69434V12.1275L4.50934 7.935L3.44434 9L9.44434 15L15.4443 9Z"
        fill="#212B36"
      />
    </svg>
  );
};

const Crumbs = () => {
  return (
    <svg
      width="21"
      height="20"
      className="cursor-pointer"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7884 4.16667C11.7884 5.08714 11.0422 5.83333 10.1217 5.83333C9.20127 5.83333 8.45508 5.08714 8.45508 4.16667C8.45508 3.24619 9.20127 2.5 10.1217 2.5C11.0422 2.5 11.7884 3.24619 11.7884 4.16667ZM11.7884 10C11.7884 10.9205 11.0422 11.6667 10.1217 11.6667C9.20127 11.6667 8.45508 10.9205 8.45508 10C8.45508 9.07953 9.20127 8.33333 10.1217 8.33333C11.0422 8.33333 11.7884 9.07953 11.7884 10ZM10.1217 17.5C11.0422 17.5 11.7884 16.7538 11.7884 15.8333C11.7884 14.9129 11.0422 14.1667 10.1217 14.1667C9.20127 14.1667 8.45508 14.9129 8.45508 15.8333C8.45508 16.7538 9.20127 17.5 10.1217 17.5Z"
        fill="#637381"
      />
    </svg>
  );
};

const Users = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z"
        fill="#663174"
      />
      <path
        d="M17 13C18.6569 13 20 11.6569 20 10C20 8.34315 18.6569 7 17 7C15.3431 7 14 8.34315 14 10C14 11.6569 15.3431 13 17 13Z"
        fill="#663174"
      />
      <path
        d="M21 19.9995C21.5523 19.9995 22 19.5518 22 18.9995C21.9984 17.0928 20.9125 15.3531 19.2003 14.514C17.4882 13.6749 15.4479 13.8826 13.94 15.0495C11.9371 13.0545 8.93071 12.46 6.31923 13.5427C3.70774 14.6253 2.00382 17.1725 2 19.9995C2 20.5518 2.44772 20.9995 3 20.9995H15C15.5523 20.9995 16 20.5518 16 19.9995"
        fill="#663174"
      />
    </svg>
  );
};
