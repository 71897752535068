import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ToastMessageContext } from '../../context/toast-context';
import { dataLinks } from '../../entities/routes';
import { Button, CompanyTabs } from '../../stories';
import { NavLinks } from '../Header/components/index';
import { General } from './components/index';
import './campus-information.scss';
import authApi from 'api/auth';
import useStorage from 'store/storage';

export default function CampusInformation({ burger }) {
  const dataTypes = ['General'];
  // const dataTypes = ['General', 'Grades of Programmes', 'Roles and Permissions']
  const [typeState, setTypeState] = useState(dataTypes && dataTypes[0]);
  const [visiblePanel, setVisiblePanel] = useState(false);
  const [campus, setCampus] = useState({});
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const history = useHistory();
  const componentRender = [
    { type: 'General', component: (key) => <General key={key} campus={campus} /> },
    // { type: 'Grades of Programmes', component: (key) => <GradesPrograms key={key} campus={campus} /> },
    // { type: 'Roles and Permissions', component: (key) => <RolesPermissions key={key} /> },
  ];
  const currentCampus = useStorage((state) => state.currentCampus);
  const campusId = currentCampus?.id;

  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      setCampus(location.state.selectCampus);
    } else {
      // const res = callApi({
      //   method: 'get',
      //   url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/campus`,
      //   params: { campusId },
      // });
      // res
      authApi.getCampus({ campusId })
        .then((results) => {
          const { data } = results;
          if (data.success) {
            setCampus(data.data);
          } else {
            setToastMessage({
              status: 'error',
              title: 'Get Campus Failed',
              message: data.message,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Get Campus Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Campus Information Error', error);
        });
    }
  }, []);

  return (
    <div className={clsx('campus-information pr-24 pl-24', burger ? '1400px:pl-80' : '1400px:pl-40')}>
      <NavLinks urls={dataLinks.campusInfoLinks} />
      <div className="flex items-center justify-between">
        <h1 className="namepage">
          {typeState === 'General'
            ? `${campus?.name ? campus.name : 'No campus'}`
            : typeState === 'Grades of Programmes'
              ? 'Grades of Programmes'
              : 'Roles and Permissions'}
        </h1>
        {typeState === 'Roles and Permissions' && (
          <Button
            text="Create a Role"
            onClick={() => {
              history.push('/company/create-role');
            }}
            className="relative bottom-2 text-sm"
          />
        )}
      </div>
      {typeState === 'Roles and Permissions' && (
        <div
          className={clsx(
            visiblePanel ? 'pointer-events-none' : 'pointer-events-auto w-full',
          )}
        >
          {/* <EditCreateRole visible={visiblePanel} setVisible={setVisiblePanel} campus={campus}/> */}
        </div>
      )}

      <div className="flex items-center w-full mb-12">
        <CompanyTabs
          types={dataTypes}
          setTypeState={setTypeState}
          typeState={typeState}
        />
      </div>
      {componentRender.map((item, key) => item.type === typeState && item.component(key))}
    </div>
  );
}
