import convertDateToFormat from "@helper/convertDateToFormat";
import { objEventType } from "@utils/constant";

const SingleSchedule = (props) => {
  const { className, data } = props;

  const getCreatedAt = (stringDate) => {
    if (!stringDate) return ''
    const objDate = new Date(stringDate)
    return `${convertDateToFormat(objDate, 'll')} at ${convertDateToFormat(objDate, 'LT')}`
  }

  const getEventString = (eventType) => {
    if (!eventType) return ''

    switch (eventType) {
      case objEventType['School Tour']:
        return 'School Tour with'
      case objEventType['Meeting With Principal']:
        return 'Meeting With Principal'
      default:
        break;
    }
  }
  return (
    <div className={`maincard block w-full h-[4.5rem] px-4 py-2 flex-row items-center justify-between ${className}`}>
      <p className="text-[#161C24] text-sm">{data?.time}</p>
      <div className={'flex items-center text-sm'}>
        <p className={'text-[#161C24]'}>
          {getEventString(data?.eventType)}&nbsp;
          {data?.eventType === objEventType['School Tour'] && (
            <span className={'text-[#0C53B7]'}>{data?.hostUser?.name || ''}&nbsp;</span>
          )}
          <span className={'text-[#637381]'}>by&nbsp;</span>
        </p>
        <img className={'w-4 aspect-square rounded-full'} src={data?.createdByUser?.photoURL || ''} />
      </div>
      <p className={'text-xs text-[#637381]'}>
        {data?.dateCreated ? getCreatedAt(data.dateCreated) : ''}
      </p>
    </div>
  )
}

export default SingleSchedule;