import clsx from 'clsx';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, SelectGroup } from '@stories/index';
import { selectStylesWithTitle } from 'entities/select-style';
import { BusinessTypes } from '@utils/constant';
import { ToastMessageContext } from 'context/toast-context';
import { create_bank_account } from './images';
import './new-bank-account.scss';
import { urls } from 'entities/urls';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';

function NewBankAccount(props) {
  const { burger } = props
  const [step, setStep] = useState(1);
  const [businessType, setBusinessType] = useState(BusinessTypes[0]);
  const [businessName, setBusinessName] = useState('');
  const [accountNickName, setAccountNickName] = useState('');
  const [branchIFSCCode, setBranchIFSCCode] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [reAccountNumber, setReAccountNumber] = useState('');
  const [beneficiaryName, setBeneficiaryName] = useState(BusinessTypes[0]);
  const [errorStepOne, setErrorStepOne] = useState({
    businessName: false,
    accountNickName: false,
  });
  const [errorStepTwo, setErrorStepTwo] = useState({
    branchIFSCCode: false,
    accountNumber: false,
    reAccountNumber: false,
  });

  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const central = useStorage((state) => state.currentCampus);
  const history = useHistory();

  const handleClickSave = () => {
    if (step === 1) {
      if (!businessName.trim() || !accountNickName.trim()) {
        !businessName.trim() &&
          setErrorStepOne((prev) => {
            return {
              ...prev,
              businessName: true,
            };
          });
        !accountNickName.trim() &&
          setErrorStepOne((prev) => {
            return {
              ...prev,
              accountNickName: true,
            };
          });
        return;
      }
      setStep(2);
    }
    if (step === 2) {
      if (
        !branchIFSCCode.trim() ||
        !accountNumber.trim() ||
        !reAccountNumber.trim() ||
        reAccountNumber.trim() !== accountNumber.trim()
      ) {
        !branchIFSCCode.trim() &&
          setErrorStepTwo((prev) => {
            return {
              ...prev,
              branchIFSCCode: true,
            };
          });
        !accountNumber.trim() &&
          setErrorStepTwo((prev) => {
            return {
              ...prev,
              accountNumber: true,
            };
          });
        (!reAccountNumber.trim() || reAccountNumber.trim() !== accountNumber.trim()) &&
          setErrorStepTwo((prev) => {
            return {
              ...prev,
              reAccountNumber: true,
            };
          });
        return;
      }
      setStep(3);
    }
    if (step === 3) {
      centralAdminApi.addBankAccount({ companyId: central.companyId }, {
        businessName,
        businessType: businessType.value,
        accountNickName,
        accountNumber,
        branchIFSCCode
      })
        .then((res) => {
          if (res.data.success) {
            setToastMessage({
              status: 'success',
              title: 'Add New Bank Account Successfully',
            });
            setIsShowToastMessage(true);
            history.push(urls.central?.finance?.bankAccounts || '/central/finance/bank-accounts');
            // history.push('/central/finance/bank-accounts');
          } else {
            setToastMessage({
              status: 'error',
              title: 'Add New Bank Account Failed',
              message: res.data.message,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Add New Bank Account Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Add New Bank Account Failed', error);
        });
    }
  };
  const handleClickBack = () => {
    setStep((prev) => prev - 1);
  };
  return (
    <div className={clsx('new-bank-account flex justify-between pr-24 pl-24 mt-[5rem] transition-all-300', burger ? '1400px:pl-80' : '1400px:pl-40')}>
      <LeftSideBar currentStep={step} />
      <div className="flex flex-col items-center gap-6">
        <div className="flex flex-col items-center gap-6">
          <p className="text-black text-2xl font-bold">Create an Account</p>
          <p className="text-sm font-medium text-[#4F5660]">
            Let us setup basic info for future references
          </p>
          <img src={create_bank_account} alt="" />
        </div>
        {step === 1 && (
          <div className="flex flex-col items-center justify-center gap-5">
            <Input
              onChange={(e) => {
                setBusinessName(e.target.value);
                setErrorStepOne((prev) => {
                  return {
                    ...prev,
                    businessName: !e.target.value,
                  };
                });
              }}
              onBlur={() => {
                setErrorStepOne((prev) => {
                  return {
                    ...prev,
                    businessName: !businessName.trim(),
                  };
                });
              }}
              label="Business Name"
              name="business-name"
              value={businessName || ''}
              customStyle={{
                width: '397px',
                height: '2.5rem',
                fontSize: '0.875rem',
                borderRadius: '0.25rem',
              }}
              error={errorStepOne.businessName}
            />
            <SelectGroup
              customStyle={{ width: '397px' }}
              title="Business Type"
              placeholder=""
              labelFix={{
                left: '0.3125rem',
                fontSize: '1rem',
              }}
              defaultValue={businessType}
              value={businessType}
              options={BusinessTypes}
              isMulti={false}
              isSearchable={true}
              styles={selectStylesWithTitle}
              onChange={(e) => {
                setBusinessType(e);
              }}
            />
            <Input
              onChange={(e) => {
                setAccountNickName(e.target.value);
                setErrorStepOne((prev) => {
                  return {
                    ...prev,
                    accountNickName: !e.target.value,
                  };
                });
              }}
              onBlur={() => {
                setErrorStepOne((prev) => {
                  return {
                    ...prev,
                    accountNickName: !accountNickName.trim(),
                  };
                });
              }}
              label="Account Nick Name"
              name="account-nick-name"
              value={accountNickName || ''}
              customStyle={{
                width: '397px',
                height: '2.5rem',
                fontSize: '0.875rem',
                borderRadius: '0.25rem',
              }}
              error={errorStepOne.accountNickName}
            />
          </div>
        )}
        {step === 1 && (
          <button
            onClick={handleClickSave}
            className="text-white font-medium text-sm w-[404px] py-3 px-6 bg-[#404EED] rounded"
          >
            Save and continue
          </button>
        )}
        {step === 2 && (
          <div className="flex flex-col items-center justify-center gap-5">
            <Input
              onChange={(e) => {
                setBranchIFSCCode(e.target.value);
                setErrorStepTwo((prev) => {
                  return {
                    ...prev,
                    branchIFSCCode: !e.target.value.trim(),
                  };
                });
              }}
              onBlur={() => {
                setErrorStepTwo((prev) => {
                  return {
                    ...prev,
                    branchIFSCCode: !branchIFSCCode.trim(),
                  };
                });
              }}
              label="Branch IFSC Code"
              name="Branch IFSC Code"
              value={branchIFSCCode || ''}
              customStyle={{
                width: '397px',
                height: '2.5rem',
                fontSize: '0.875rem',
                borderRadius: '0.25rem',
              }}
              error={errorStepTwo.branchIFSCCode}
            />
            <Input
              onChange={(e) => {
                setAccountNumber(e.target.value);
                setErrorStepTwo((prev) => {
                  return {
                    ...prev,
                    accountNumber: !e.target.value.trim(),
                  };
                });
              }}
              onBlur={() => {
                setErrorStepTwo((prev) => {
                  return {
                    ...prev,
                    accountNumber: !accountNumber.trim(),
                  };
                });
              }}
              label="Account Number"
              name="Account Number"
              value={accountNumber || ''}
              customStyle={{
                width: '397px',
                height: '2.5rem',
                fontSize: '0.875rem',
                borderRadius: '0.25rem',
              }}
              error={errorStepTwo.accountNumber}
            />
            <Input
              onChange={(e) => {
                setReAccountNumber(e.target.value);
                setErrorStepTwo((prev) => {
                  return {
                    ...prev,
                    reAccountNumber:
                      !e.target.value.trim() ||
                      e.target.value.trim() !== accountNumber.trim(),
                  };
                });
              }}
              onBlur={() => {
                setErrorStepTwo((prev) => {
                  return {
                    ...prev,
                    reAccountNumber:
                      !reAccountNumber.trim() ||
                      reAccountNumber.trim() !== accountNumber.trim(),
                  };
                });
              }}
              label="Re-Enter Account Number"
              name="Re-Enter Account Number"
              value={reAccountNumber || ''}
              customStyle={{
                width: '397px',
                height: '2.5rem',
                fontSize: '0.875rem',
                borderRadius: '0.25rem',
              }}
              error={errorStepTwo.reAccountNumber}
            />
            <SelectGroup
              customStyle={{ width: '397px' }}
              title="Beneficiary Name"
              placeholder=""
              labelFix={{
                left: '0.3125rem',
                fontSize: '1rem',
              }}
              defaultValue={businessType}
              value={businessType}
              options={BusinessTypes}
              isMulti={false}
              isSearchable={true}
              styles={selectStylesWithTitle}
              onChange={(e) => {
                setBeneficiaryName(e);
              }}
              isDisabled={true}
            />
          </div>
        )}
        {step === 2 && (
          <div className="flex items-center justify-between w-full">
            <button
              onClick={handleClickBack}
              className="w-44 text-[#404EED] font-medium text-sm py-3 px-6 bg-white rounded border border-[#404EED]"
            >
              Back
            </button>
            <button
              onClick={handleClickSave}
              className="w-44 text-white font-medium text-sm py-3 px-6 bg-[#404EED] rounded"
            >
              Save and Next
            </button>
          </div>
        )}
        {step === 3 && (
          <div className="w-[450px] flex flex-col gap-5 pb-7">
            <div>
              <p className="text-2xl font-medium">{accountNickName}</p>
              <p className="text-[#637381] leading-[14px] text-[10px]">
                HDFC Bank: {accountNumber} | {central?.city || central?.state}
              </p>
            </div>
            <div className="flex gap-[50px]">
              <div>
                <p className="new-bank-account-label">Beneficiary</p>
                <p className="new-bank-account-info">{businessName}</p>
              </div>
              <div>
                <p className="new-bank-account-label">IFSC Code</p>
                <p className="new-bank-account-info">{branchIFSCCode}</p>
              </div>
            </div>
            <div>
              <p className="new-bank-account-label">Company Type</p>
              <p className="new-bank-account-info">{businessType.label}</p>
            </div>
            <div>
              <p className="new-bank-account-label">Address</p>
              <p className="new-bank-account-info">{central?.address?.[0]}</p>
            </div>
          </div>
        )}
        {step === 3 && (
          <div className="flex items-center justify-between w-full">
            <button
              onClick={handleClickBack}
              className="w-52 text-[#404EED] font-medium text-sm py-3 px-6 bg-white rounded border border-[#404EED]"
            >
              Back
            </button>
            <button
              onClick={handleClickSave}
              className="w-52 text-white font-medium text-sm py-3 px-6 bg-[#404EED] rounded"
            >
              Confirm and Add
            </button>
          </div>
        )}
      </div>
      <di className="w-44"></di>
    </div>
  );
}

export default NewBankAccount;

const LeftSideBar = (props) => {
  const { currentStep = 1 } = props;

  return (
    <div className="mt-10 p-2.5 w-44">
      <div className="flex items-center justify-start">
        <CircleIcon status={currentStep > 1 ? 'past' : 'present'} />
        <p
          className={clsx(
            'text-sm text-[#2E3338] ml-3',
            currentStep !== 1 ? 'font-normal' : 'font-bold',
          )}
        >
          Business Details
        </p>
      </div>
      <div className="w-4 flex justify-center">
        <LineIcon />
      </div>
      <div className="flex items-center justify-start">
        <CircleIcon
          status={currentStep > 2 ? 'past' : currentStep === 2 ? 'present' : 'future'}
        />
        <p
          className={clsx(
            'text-sm ml-3',
            currentStep > 2
              ? 'font-normal text-[#2E3338]'
              : currentStep === 2
                ? 'font-bold text-[#2E3338]'
                : 'font-normal text-[#B8BBBE]',
          )}
        >
          Bank Details
        </p>
      </div>
      <div className="w-4 flex justify-center">
        <LineIcon />
      </div>
      <div className="flex items-center justify-start">
        <CircleIcon status={currentStep < 3 ? 'future' : 'present'} />
        <p
          className={clsx(
            'text-sm ml-3',
            currentStep < 3 ? 'font-medium text-[#B8BBBE]' : 'font-bold text-[#2E3338]',
          )}
        >
          Complete
        </p>
      </div>
    </div>
  );
};

const LineIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1"
      height="53"
      viewBox="0 0 1 53"
      fill="none"
    >
      <line x1="0.5" y1="2.18557e-08" x2="0.499998" y2="53" stroke="#E1E3E5" />
    </svg>
  );
};

const CircleIcon = (props) => {
  const { status = 'future' } = props;
  if (status === 'past') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
          fill="#27C59A"
        />
      </svg>
    );
  }
  if (status === 'present') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
          fill="#404EED"
        />
      </svg>
    );
  }
  if (status === 'future') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 15C9.85652 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85652 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8C1 9.85652 1.7375 11.637 3.05025 12.9497C4.36301 14.2625 6.14348 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z"
          fill="#8C9196"
        />
      </svg>
    );
  }
};
