import React, { memo, useEffect, useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';

import { FeeTermsCard, ToggleButton, TypesOfFees } from '@stories';
import { anal, analarrow, arrow, anal_down, analarrow_down } from '@static/image';
import { feebookField } from 'entities/data';
import { formatMoney } from '@helper/format-money';
import { SelectGroup } from '@stories/index';
import { selectStylesWithNoTitle } from 'entities/select-style';
import { calcPercent } from '@utils/utils';

import './custom-fee-card.scss';

const serviceOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

const styles = {
  p: { color: '#81878c', fontSize: '0.875rem', marginBottom: '0.313rem' },
  img: { marginBottom: '0.125rem', marginLeft: '0.313rem' },
  span: { fontSize: '0.75rem', marginBottom: '0.063rem' },
};
const listFee = ['Custom fee'];

const CustomFeeCard = memo((props) => {
  const {
    oldTerms = [],
    termsLength = 1,
    listClasses,
    types,
    customFees,
    title,
    refresh,
    dataFeeCallApi,
    setDataFeeCallApi = () => { },
    razorpayBankAccount,
    listBankAccount = [],
    listAcademicYearTerm = []
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [isGradesApplicable, setIsGradesApplicable] = useState(false);
  const [numberTerms, setNumberTerms] = useState(0);
  const [termsArray, setTermsArray] = useState([]);
  const [active, setActive] = useState(types && types[0]);
  const [numFee, setNumFee] = useState(0);
  const [listGradeClasses, setListGradeClasses] = useState([]);
  const [isEnterName, setIsEnterName] = useState(false);
  const [name, setName] = useState('');
  const [isOptionalService, setIsOptionalService] = useState();
  const [selectedIsOptionalService, setselectedIsOptionalService] = useState();
  const [isRefundable, setIsRefundable] = useState();
  const [selectedIsRefundable, setselectedIsRefundable] = useState();
  const [isScholarshipApplicable, setIsScholarshipApplicable] = useState();
  const [selectedIsScholarshipApplicable, setSelectedIsScholarshipApplicable] =
    useState();
  const [listClassesGradesApplicable, setListClassesGradesApplicable] = useState();
  const [selectedListClassesGradesApplicable, setselectedListClassesGradesApplicable] =
    useState();
  const [listBankAccountsSelected, setListBankAccountsSelected] = useState({
    'Custom fee': null,
  });

  useEffect(() => {
    const list = listClasses.map((cls) => {
      return {
        label: cls.name,
        value: cls.id,
      };
    });
    setListGradeClasses(list);
  }, [listClasses]);

  useEffect(() => {
    if (numberTerms) {
      if (numberTerms === 0) {
        setTermsArray([]);
      } else if (numberTerms > termsArray.length) {
        setTermsArray((prev) => {
          const arr = [...prev];
          for (let i = termsArray.length; i < numberTerms; i++) {
            arr.push({
              name: `Term ${i + 1} Fee`,
              fee: 0,
              dueFee: new Date(),
            });
          }
          return arr;
        });
      } else if (numberTerms < termsArray.length) {
        setTermsArray((prev) => {
          return prev.slice(0, numberTerms);
        });
      }
    }
  }, [numberTerms]);

  useEffect(() => {
    if (!!customFees) {
      customFees.name && setName(customFees.name);
      customFees.feeType && setActive(customFees.feeType);
      customFees.feeTypeFee?.value && setNumFee(customFees.feeTypeFee.value);
      setIsGradesApplicable(!!customFees.isGradesApplicable);
      customFees.isOptionalService && setIsOptionalService(customFees.isOptionalService);
      customFees.isOptionalService &&
        setselectedIsOptionalService(() => {
          return serviceOptions.find((opt) => opt.value === customFees.isOptionalService);
        });
      customFees.isRefundable && setIsRefundable(customFees.isRefundable);
      customFees.isRefundable &&
        setselectedIsRefundable(() => {
          return serviceOptions.find((opt) => opt.value === customFees.isRefundable);
        });
      customFees.isScholarshipApplicable &&
        setIsScholarshipApplicable(customFees.isScholarshipApplicable);
      customFees.isScholarshipApplicable &&
        setSelectedIsScholarshipApplicable(() => {
          return serviceOptions.find(
            (opt) => opt.value === customFees.isScholarshipApplicable,
          );
        });
      customFees.listClassesGradesApplicable &&
        setListClassesGradesApplicable(customFees.listClassesGradesApplicable);
      customFees.listClassesGradesApplicable &&
        setselectedListClassesGradesApplicable(() => {
          const arr = [];
          listClasses.forEach((classes) => {
            if (customFees.listClassesGradesApplicable.includes(classes.id)) {
              arr.push({
                label: classes.name,
                value: classes.id,
              });
            }
          });
          return arr.length > 0 ? arr : undefined;
        });
      const selectedBankAccount = listBankAccount.find(
        (acc) => acc.value === customFees.razorpayBankAccountId,
      );
      selectedBankAccount &&
        setListBankAccountsSelected((prev) => {
          return {
            ...prev,
            'Transport fee': selectedBankAccount,
          };
        });
      const dataTerm = _.cloneDeep(customFees.terms || []);
      if (customFees?.feeType === 'Custom') {
        const numberOfTerms = customFees.numberOfTerms || 0;
        numberOfTerms && setNumberTerms(numberOfTerms);
        setTermsArray(dataTerm);
      } else if (customFees?.feeType === 'Term Dates') {
        setNumberTerms(termsLength);
        setTermsArray(dataTerm);
      } else {
        const newDataTerm = listAcademicYearTerm?.map(academicYear => {
          return {
            fee: 0,
            name: `${academicYear.name} Fee`,
            dueFee: academicYear.startDate
          }
        }) || [];
        setTermsArray(newDataTerm);
        setNumberTerms(termsLength);
      }
      // customFees.terms && setTermsArray(customFees.terms);
      // customFees.numberOfTerms && setNumberTerms(customFees.numberOfTerms);
    } else {
      const newDataTerm = listAcademicYearTerm?.map(academicYear => {
        return {
          fee: 0,
          name: `${academicYear.name} Fee`,
          dueFee: academicYear.startDate
        }
      }) || [];
      setTermsArray(newDataTerm);
      setNumberTerms(termsLength);
    }
  }, [customFees, refresh, listAcademicYearTerm]);

  const calcCustomFee = (termsArray = []) => {
    let sum = 0;
    termsArray.forEach((term) => {
      sum += +term.fee;
    });
    return sum;
  };

  const handleChangeTermArray = (length, array) => {
    let arr = [...array];
    if (length === 0) {
      arr = [];
    } else if (length > array.length) {
      for (let i = array.length; i < length; i++) {
        arr.push({
          name: `Term ${i + 1} Fee`,
          fee: 0,
          dueFee: new Date(),
        });
      }
    } else if (length < array.length) {
      arr = arr.slice(0, length);
    }
    return arr;
  };

  const handleSetDataFeeCallApi = (type, value) => {
    const data = { ...dataFeeCallApi };
    const key = feebookField[title];
    let arr = [...termsArray];
    if (type === 'numberOfTerms') {
      setNumberTerms(value);
      arr = handleChangeTermArray(value, termsArray);
    }
    if (type === 'feeType' && value === 'Term Dates') {
      setNumberTerms(termsLength);
      arr = handleChangeTermArray(termsLength, termsArray);
    }
    let estAnnualFee = 0;
    if (
      type === 'numberOfTerms' ||
      active === 'Custom' ||
      (type === 'feeType' && value === 'Custom')
    ) {
      estAnnualFee = calcCustomFee(arr);
    } else if (type === 'terms') {
      estAnnualFee = calcCustomFee(value);
    } else if (type === 'feeTypeFee') {
      estAnnualFee = value;
    } else {
      estAnnualFee = numFee;
    }

    if (!!data[key]) {
      data[key][type] = value;
      data[key].terms = arr;
      data[key].estAnnualFee = estAnnualFee;
      setDataFeeCallApi(data);
    } else {
      setDataFeeCallApi((prev) => {
        const dataFee = {
          ...prev,
          [key]: {
            name: name,
            feeType: active,
            feeTypeFee: numFee,
            terms: arr,
            numberOfTerms: numberTerms,
            isOptionalService: isOptionalService,
            isRefundable: isRefundable,
            isScholarshipApplicable: isScholarshipApplicable,
            estAnnualFee: estAnnualFee,
            isGradesApplicable: isGradesApplicable,
            listClassesGradesApplicable: listClassesGradesApplicable,
            [type]: value,
          },
        };
        return dataFee;
      });
    }
  };

  return (
    <React.Fragment>
      <div
        className={clsx(
          'feebook-edit-card py-2 px-3',
          isOpen ? 'feebook-edit-card_open' : 'feebook-edit-card_closed',
        )}
      >
        <div className="feebook-edit-card_row w-full" onClick={() => setIsOpen(!isOpen)}>
          <p className="font-normal text-base ml-8">{name}</p>
          <TypesOfFees title={`Fee Type`} sum={active} />
          <TypesOfFees
            title="Number of Terms"
            sum={active === 'Custom' || active === 'Term Dates' ? numberTerms : '--'}
          />
          <TypesOfFees
            title="Optional Service"
            sum={selectedIsOptionalService ? selectedIsOptionalService.label : '--'}
          />
          <TypesOfFees
            title="Est. Annual Fee"
            sum={
              active === 'Custom' || active === 'Term Dates'
                ? calcCustomFee(termsArray)
                : numFee
            }
          />
          <div className="flex items-center">
            {(!!customFees?.estAnnualFee?.percentageChange ||
              customFees?.estAnnualFee?.percentageChange == 0) &&
              !isNaN(
                calcPercent(
                  customFees.estAnnualFee.percentageChange,
                  customFees.estAnnualFee.value,
                  active === 'Custom' || active === 'Term Dates'
                    ? calcCustomFee(termsArray)
                    : numFee,
                ),
              ) && (
                <img
                  src={
                    calcPercent(
                      customFees.estAnnualFee.percentageChange,
                      customFees.estAnnualFee.value,
                      active === 'Custom' || active === 'Term Dates'
                        ? calcCustomFee(termsArray)
                        : numFee,
                    ) < 0
                      ? anal_down
                      : anal
                  }
                  alt=""
                  className={clsx(
                    calcPercent(
                      customFees.estAnnualFee.percentageChange,
                      customFees.estAnnualFee.value,
                      active === 'Custom' || active === 'Term Dates'
                        ? calcCustomFee(termsArray)
                        : numFee,
                    ) < 0
                      ? 'rotate-90'
                      : '',
                  )}
                />
              )}
            <span
              className={clsx(
                'ml-1',
                (!!customFees?.estAnnualFee?.percentageChange ||
                  customFees?.estAnnualFee?.percentageChange == 0) &&
                  !isNaN(
                    calcPercent(
                      customFees.estAnnualFee.percentageChange,
                      customFees.estAnnualFee.value,
                      active === 'Custom' || active === 'Term Dates'
                        ? calcCustomFee(termsArray)
                        : numFee,
                    ),
                  ) &&
                  calcPercent(
                    customFees.estAnnualFee.percentageChange,
                    customFees.estAnnualFee.value,
                    active === 'Custom' || active === 'Term Dates'
                      ? calcCustomFee(termsArray)
                      : numFee,
                  ) < 0
                  ? 'text-[#ff4343]'
                  : '',
              )}
            >
              {(!!customFees?.estAnnualFee?.percentageChange ||
                customFees?.estAnnualFee?.percentageChange == 0) &&
                !isNaN(
                  calcPercent(
                    customFees.estAnnualFee.percentageChange,
                    customFees.estAnnualFee.value,
                    active === 'Custom' || active === 'Term Dates'
                      ? calcCustomFee(termsArray)
                      : numFee,
                  ),
                )
                ? '' +
                calcPercent(
                  customFees.estAnnualFee.percentageChange,
                  customFees.estAnnualFee.value,
                  active === 'Custom' || active === 'Term Dates'
                    ? calcCustomFee(termsArray)
                    : numFee,
                ) +
                '%'
                : '--'}
            </span>
          </div>
          <button className="feebook-edit-card_row_button">
            <img src={arrow} alt="" />
          </button>
        </div>
        {isOpen && (
          <React.Fragment>
            <div className="feebook-edit-card_content w-[95%]">
              <h3 className=" font-normal text-sm">Fee Type</h3>
              <div className="feebook-edit-card_content_buttons flex">
                {types.map((type) => (
                  <button
                    key={type}
                    onClick={() => {
                      setActive(type);
                      handleSetDataFeeCallApi('feeType', type);
                    }}
                    className={clsx('tab-button', type === active && 'tab-button_active')}
                  >
                    {type}
                  </button>
                ))}
              </div>
              <div className="flex flex-wrap ml-1.5 mb-6">
                <div className="w-40 mb-6">
                  <div className="mb-6 text-[0.85rem] mr-2">
                    <div className="flex mb-2">
                      <p className="text-[#a1abb4]">Fee Name</p>
                      <ToggleButton
                        value={isEnterName}
                        onChange={() => {
                          setIsEnterName(!isEnterName);
                        }}
                        inputSize="smalling"
                        customStyle={styles.img}
                      />
                    </div>
                    <div>
                      <input
                        disabled={!isEnterName}
                        className="border-none outline-none w-11/12"
                        placeholder="Enter Fee Name"
                        type="text"
                        value={name}
                        onChange={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setName(e.target.value);
                          handleSetDataFeeCallApi('name', e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-48 mr-2">
                  <p style={styles.p}>
                    {active === 'Custom' || active === 'Term Dates'
                      ? 'Number of Terms'
                      : `${active} Fee`}
                  </p>
                  <p className="flex items-center">
                    {active === 'Custom' || active === 'Term Dates' ? (
                      <input
                        disabled={active === 'Term Dates'}
                        value={numberTerms}
                        type="number"
                        className="w-14 border-none outline-none"
                        onChange={(e) => {
                          setNumberTerms(e.target.value);
                          if (e.target.value) {
                            handleSetDataFeeCallApi('numberOfTerms', +e.target.value);
                          }
                        }}
                      />
                    ) : (
                      <React.Fragment>
                        ₹{' '}
                        <input
                          value={numFee}
                          type="number"
                          className="ml-1 border-none outline-none"
                          style={{ maxWidth: '3.5rem' }}
                          onChange={(e) => {
                            setNumFee(e.target.value);
                            handleSetDataFeeCallApi('feeTypeFee', +e.target.value);
                          }}
                        />{' '}
                        {(!!customFees?.feeTypeFee?.percentageChange ||
                          customFees?.feeTypeFee?.percentageChange == 0) &&
                          !isNaN(
                            calcPercent(
                              customFees.feeTypeFee.percentageChange,
                              customFees.feeTypeFee.value,
                              numFee,
                            ),
                          ) && (
                            <img
                              src={
                                calcPercent(
                                  customFees.feeTypeFee.percentageChange,
                                  customFees.feeTypeFee.value,
                                  numFee,
                                ) < 0
                                  ? analarrow_down
                                  : analarrow
                              }
                              style={styles.img}
                              alt=""
                              className={clsx(
                                (!!customFees?.feeTypeFee?.percentageChange ||
                                  customFees?.feeTypeFee?.percentageChange == 0) &&
                                  calcPercent(
                                    customFees.feeTypeFee.percentageChange,
                                    customFees.feeTypeFee.value,
                                    numFee,
                                  ) < 0
                                  ? 'rotate-180'
                                  : '',
                              )}
                            />
                          )}
                        <span
                          style={styles.span}
                          className={clsx(
                            (!!customFees?.feeTypeFee?.percentageChange ||
                              customFees?.feeTypeFee?.percentageChange == 0) &&
                              calcPercent(
                                customFees.feeTypeFee.percentageChange,
                                customFees.feeTypeFee.value,
                                numFee,
                              ) < 0
                              ? 'text-[#ff4343]'
                              : 'text-[#2ED775]',
                          )}
                        >
                          {(!!customFees?.feeTypeFee?.percentageChange ||
                            customFees?.feeTypeFee?.percentageChange == 0) &&
                            !isNaN(
                              calcPercent(
                                customFees.feeTypeFee.percentageChange,
                                customFees.feeTypeFee.value,
                                numFee,
                              ),
                            )
                            ? '' +
                            calcPercent(
                              customFees.feeTypeFee.percentageChange,
                              customFees.feeTypeFee.value,
                              numFee,
                            ) +
                            '%'
                            : '--'}
                        </span>
                      </React.Fragment>
                    )}
                  </p>
                </div>
                <div className="flex flex-col w-52 mr-2">
                  <div className="flex items-center">
                    <p style={styles.p}>Is this Service Optional</p>
                  </div>
                  <SelectGroup
                    classNamePrefix="custom-fee"
                    value={selectedIsOptionalService}
                    isMulti={false}
                    onChange={(e) => {
                      setIsOptionalService(e.value);
                      setselectedIsOptionalService(e);
                      handleSetDataFeeCallApi('isOptionalService', e.value);
                    }}
                    options={serviceOptions}
                    styles={selectStylesWithNoTitle}
                    classNameGroup="w-[8rem]"
                  />
                </div>
                <div className="flex flex-col w-48 mr-2">
                  <div className="flex items-center">
                    <p style={styles.p}>Is this fee refundable</p>
                  </div>
                  <SelectGroup
                    classNamePrefix="custom-fee"
                    value={selectedIsRefundable}
                    isMulti={false}
                    onChange={(e) => {
                      setIsRefundable(e.value);
                      setselectedIsRefundable(e);
                      handleSetDataFeeCallApi('isRefundable', e.value);
                    }}
                    options={serviceOptions}
                    styles={selectStylesWithNoTitle}
                    classNameGroup="w-[8rem]"
                  />
                </div>
                <div className="flex flex-col w-48 mr-2">
                  <div className="flex items-center">
                    <p style={styles.p}>Is Scholarship Applicable</p>
                  </div>
                  <SelectGroup
                    classNamePrefix="custom-fee"
                    value={selectedIsScholarshipApplicable}
                    isMulti={false}
                    onChange={(e) => {
                      setIsScholarshipApplicable(e.value);
                      setSelectedIsScholarshipApplicable(e);
                      handleSetDataFeeCallApi('isScholarshipApplicable', e.value);
                    }}
                    options={serviceOptions}
                    styles={selectStylesWithNoTitle}
                    classNameGroup="w-[8rem]"
                  />
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center">
                    <p className="flex items-center" style={styles.p}>
                      Grades Applicable
                    </p>
                    <ToggleButton
                      value={isGradesApplicable}
                      onChange={() => {
                        setIsGradesApplicable(!isGradesApplicable);
                        handleSetDataFeeCallApi(
                          'isGradesApplicable',
                          !isGradesApplicable,
                        );
                      }}
                      inputSize="smalling"
                      customStyle={styles.img}
                    />
                  </div>
                  {isGradesApplicable && (
                    <SelectGroup
                      classNamePrefix="custom-fee-grade"
                      value={selectedListClassesGradesApplicable}
                      isMulti={true}
                      onChange={(e) => {
                        const arr = e.map((data) => data.value);
                        setListClassesGradesApplicable(arr);
                        setselectedListClassesGradesApplicable(e);
                        handleSetDataFeeCallApi('listClassesGradesApplicable', arr);
                      }}
                      options={listGradeClasses}
                      styles={selectStylesWithNoTitle}
                      classNameGroup="w-[15rem]"
                    />
                  )}
                </div>
              </div>
              {(active === 'Custom' || active === 'Term Dates') &&
                Array.isArray(termsArray) &&
                termsArray.map((item, key) => (
                  <FeeTermsCard
                    isSetTime={active === 'Term Dates' ? false : true}
                    handleSetDataFeeCallApi={handleSetDataFeeCallApi}
                    setTermsArray={setTermsArray}
                    termsArray={termsArray}
                    item={item}
                    key={key}
                    index={key}
                    maxValue={termsArray.length}
                    oldFee={key >= oldTerms.length ? {} : oldTerms[key]}
                  />
                ))}
            </div>
            {razorpayBankAccount.type === 'Different' && (
              <div className="px-8 flex flex-col gap-2.5 my-5">
                <p className="text-[#81878C] text-sm font-semibold">Bank Account</p>
                {listFee.map((fee) => (
                  <div key={fee} className="flex gap-5 items-center justify-start">
                    <p className="min-w-[120px] text-[#919EAB] font-normal text-sm">
                      {fee}
                    </p>
                    <SelectGroup
                      value={listBankAccountsSelected[fee]}
                      options={listBankAccount}
                      onChange={(e) => {
                        setListBankAccountsSelected((prev) => ({ ...prev, [fee]: e }));
                        handleSetDataFeeCallApi('razorpayBankAccountId', e.value)
                      }}
                      classNameGroup="w-[20rem] mx-3"
                    />
                  </div>
                ))}
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
});
CustomFeeCard.displayName = 'CustomFeeCard';

export default CustomFeeCard;
