import PropTypes from 'prop-types';
import React, { Fragment, useMemo, useState, useRef, useEffect, useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import { EnquiryStatus, chipData } from '../../../../entities/data';
import { ellipsis, mainavatar } from '../../../../static/image';
import { Button, Chip } from '../../../../stories';
import { APPLICATION_STEP } from '../../../../utils/utils';
import './application-header.scss';
import { useUser } from '@utils/custom-hook';
import { Zoom } from 'react-reveal';
import useOnClickOutside from '@hooks/useClickOutside';
import admissionApi from 'api/admission';
import { checkErrorSingleApi } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';

export default function ApplicationHeader(props) {
  const { enquiry, handleRefreshEnquiries, setIsLoading, handleNextStage } = props;
  const { currentStep, programGrade, createdBy, info } = enquiry;
  const [userIdReferral, setUserIdReferral] = useState();
  const [popupCancelValue, setPopupCancelValue] = useState(false);
  const { data: userReferral, error, loading } = useUser({ id: userIdReferral });
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const popupCancelRef = useRef()
  const styles = {
    button: {
      paddingLeft: '0.625rem',
      paddingRight: '0.625rem',
      marginTop: '0.313rem',
      fontSize: '0.813rem',
    },
  };

  // const academicYear = useMemo(() => {
  //   const academicYear = enquiry.academicYear.academicYearTerm;
  //   const startDate = academicYear[0].startDate?.slice(0, 4);
  //   const endDate = academicYear[0].endDate?.slice(2, 4);
  //   return `${startDate}-${endDate}`;
  // }, [enquiry]);
  const academicYear = useMemo(() => {
    const academicYear = enquiry.academicYear.academicYearTerm;
    const startDate = academicYear[0].startDate?.slice(0, 4);
    const endDate = Number(startDate) + 1
    return `${startDate}-${String(endDate).slice(2, 4)}`;
  }, [enquiry]);

  const grade = useMemo(() => {
    return programGrade.grade.code;
  }, [enquiry]);

  const families = useMemo(() => {
    if (!enquiry.admissionResult) return [];

    const data = JSON.parse(enquiry.admissionResult?.info);

    setUserIdReferral(
      data?.application?.additionalInfo?.otherQuestions?.whereDidYouHearAboutUs?.userId,
    );

    return data.application.applicationParent?.map((item) => {
      const { fullName, relationship, photoURL } = item;
      return {
        name: fullName,
        relationship: relationship || '',
        photoURL,
      };
    });
  }, [enquiry]);

  const schoolHistory = useMemo(() => {
    if (!enquiry.admissionResult?.info) return [];
    const data = JSON.parse(enquiry.admissionResult?.info);
    return data.application.additionalInfo?.schoolInfo?.map((item) => {
      return {
        schoolName: item.schoolName || '',
        // address: item.address || '',
        // photoURL: item.avatar || '',
      };
    });
  }, [enquiry]);

  const togglePopupCancel = () => {
    setPopupCancelValue(prev => !prev)
  }

  useOnClickOutside(popupCancelRef, () => setPopupCancelValue(false))

  const handleCancel = async () => {
    try {
      setIsLoading(true);
      const api = await admissionApi.updateEnquiryById({ status: EnquiryStatus.FAILED.toLowerCase() }, enquiry.id)
      if (
        checkErrorSingleApi(
          api,
          setToastMessage,
          setIsShowToastMessage,
          'Cancel Application',
        )
      ) {
        setPopupCancelValue(false)
        handleRefreshEnquiries()
      }
      setIsLoading(false);
    } catch (error) {
      setToastMessage({
        status: 'error',
        title: 'Cancel Application',
        message: error?.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
      setIsLoading(false);
    }
  }

  return (
    <div className="application-header">
      <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
      <div className="application-header-profile">
        <div className="flex">
          <img
            data-for="main"
            data-tip={createdBy?.name || 'User Name'}
            data-iscapture="true"
            className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
            src={createdBy?.photoURL || mainavatar}
            alt=""
          />
          <div className="ml-3">
            {/* <h6 className="text-sm font-bold">{createdBy.name}</h6> */}
            <h6 className="text-sm font-bold">{`${info.firstName} ${info.lastName}`}</h6>
            <p className="text-micro text-second-gray">{`id # ${createdBy.id}`}</p>
          </div>
        </div>
        <div className="flex">
          <p className="rounded-profile rounded-profile_main">
            {APPLICATION_STEP[currentStep]}
          </p>
          <p className="rounded-profile rounded-profile_second">{grade}</p>
          <p className="rounded-profile rounded-profile_second w-18">{academicYear}</p>
        </div>
      </div>
      <div className="2xl:border-r border-l border-main-gray">
        <div className="application-header-family top-1">
          <p className="inline-block mr-7 relative bottom-1">Family</p>
          {enquiry.admissionResult &&
            families?.map((item, key) => (
              <div key={key} className="inline-block mr-2">
                <Chip
                  // styleChip={item.styleChip}
                  customLabelStyle={{ paddingRight: '0.5rem' }}
                  styleChip="outlined"
                  label={item.name}
                  chipAvatarStyle={{ width: 18, height: 18 }}
                  image={item.photoURL || mainavatar}
                  customStyle={{ height: '1.5rem' }}
                />
              </div>
            ))}
        </div>
      </div>
      <div className="application-header-referal">
        <div className="flex mt-2">
          <p className="text-sm text-second-gray mr-2">Referral</p>
          {userReferral && <Chip
            customLabelClass="pr-3"
            styleChip={chipData[1].styleChip}
            label={userReferral?.name}
            chipAvatarStyle={{ width: 18, height: 18 }}
            image={userReferral?.photoURL || mainavatar}
            customStyle={chipData[1].style}
          />}
        </div>
        <div className="flex items-center">
          <p className="text-sm text-second-gray mr-2 relative top-1">School</p>
          {enquiry.admissionResult &&
            schoolHistory?.map((item, key) => {
              return (
                <Fragment key={key}>
                  <div className="flex items-center school-card justify-center h-[2rem] min-w-[5rem] w-fit mt-2">
                    <h2
                      className="leading-3 overflow-hidden text-ellipsis whitespace-nowrap"
                      title={item.schoolName}
                    >
                      {item.schoolName}
                    </h2>
                  </div>
                </Fragment>
              )
            }
            )}
        </div>
      </div>
      <div className="application-header-type">
        <p className="text-sm text-second-gray mt-2.5">
          Type <span className="text-main-black">Phone Enquiry</span>
        </p>
        <Button
          text="Next Stage"
          buttonStyle="outlined"
          buttonSize="small"
          customStyle={styles.button}
          onClick={() => handleNextStage()}
        />
        <button
          className="absolute top-9 right-3 w-6 h-5 flex justify-center"
          onClick={togglePopupCancel}
        >
          <img src={ellipsis} alt="" />
        </button>
        <div ref={popupCancelRef}>
          {popupCancelValue && (
            <Zoom duration={300} when={popupCancelValue}>
              <div className="p-3 w-48 h-14 border-2 absolute top-16 z-20 bg-white shadow-standart flex justify-center items-center rounded-lg"
                style={{ right: '-2rem' }}
              >
                <div className="">
                  <Button text='Cancel' buttonStyle="outlined"
                    customStyle={{ color: 'rgb(226, 68, 92)', borderColor: 'rgb(226, 68, 92)' }}
                    onClick={handleCancel}
                    buttonDisabled={enquiry.enquiryStatus === EnquiryStatus.FAILED.toLowerCase()}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Zoom>
          )}
        </div>
      </div>
    </div>
  );
}

