import React, { useCallback, useContext, useEffect, useState } from 'react';

import { ToastMessageContext } from '../../context/toast-context';
import { cancel, group_stage } from '../../static/image';
import { Button, Input, SelectGroup } from '../../stories';
import './edit-stage-popup.scss';

const styles = {
  closeBtn: {
    backgroundColor: '#fff',
    color: '#202223',
    border: '1px solid #E1E3E5',
    marginRight: '0.5rem',
  },
  createBtn: { backgroundColor: '#404eed' },
};

export default function EditStagePopup(props) {
  const { open, isOpen, currentStage, listForm, handleSaveEdit } = props;
  const { formId, index: indexOfStage, name: stageName, textDefault, conversionProbability } = currentStage
  const [tableOpen, setTableOpen] = useState(false);
  const [selectForm, setSelectForms] = useState(listForm.find(form => form.value === formId));
  const [nameOfStage, setNameOfStage] = useState('');
  const [conversionProbabilityOfStage, setConversionProbabilityOfStage] = useState();
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  useEffect(() => {
    setNameOfStage(stageName);
    setConversionProbabilityOfStage(conversionProbability);
  }, []);

  const allClose = () => {
    setTableOpen(false);
  };

  const handleSave = useCallback(() => {
    let message = ''
    if (!selectForm && !textDefault) message = 'Please select a link form'
    if (!nameOfStage) message = 'Please entered stage name'
    if (!conversionProbabilityOfStage) message = 'Please entered conversion probability'

    if (message) {
      setToastMessage({
        status: 'warning',
        title: 'Validate Rule',
        message
      });
      setIsShowToastMessage(true);
      return;
    }

    handleSaveEdit(
      indexOfStage,
      nameOfStage,
      conversionProbabilityOfStage,
      selectForm?.value,
    );
  }, [selectForm, textDefault, nameOfStage, conversionProbabilityOfStage, indexOfStage])

  return (
    <div className={open ? 'edit-stage edit-stage-active' : 'edit-stage'}>
      <div
        className="edit-stage_bg"
        onClick={() => {
          isOpen();
        }}
      />
      <div
        className="edit-stage-card w-[60vw]"
        onClick={tableOpen === true ? allClose : null}
      >
        <div className="edit-stage-card-header">
          <div className="flex ml-6">
            <img className="w-8 h-8" src={group_stage} alt="group_stage" />
            <h2 className="text-2xl ml-4">Add/Edit Stage</h2>
          </div>
          <button
            className="mr-4"
            onClick={() => {
              isOpen();
            }}
          >
            <img src={cancel} alt="closed modal" />
          </button>
        </div>
        <div className="edit-stage-card-row mt-6 mx-6">
          <p>
            The due Date will be changed from <strong>24 Aug 2022</strong> to{' '}
          </p>
          <div className=" flex justify-between mt-6">
            <Input
              //   refCustom={teamNameRef}
              value={nameOfStage}
              label="Stage Name"
              name="stage-name"
              type="text"
              customStyle={{
                width: '27vw',
              }}
              onChange={(e) => {
                setNameOfStage(e.target.value);
              }}
            />
            <Input
              //   refCustom={teamNameRef}
              value={conversionProbabilityOfStage}
              label="Conversion Probability"
              name="conversion-probability"
              type="number"
              customStyle={{
                width: '27vw',
              }}
              onChange={(e) => {
                setConversionProbabilityOfStage(e.target.value);
              }}
            />
          </div>
          <div className="mt-8">
            <SelectGroup
              title="Linked Form"
              classNamePrefix="edit-stage"
              placeholder="Select Link Form"
              isMulti={false}
              isSearchable={true}
              value={selectForm || null}
              options={listForm}
              //   formatOptionLabel={formatOptionLabelUser}
              onChange={(e) => {
                setSelectForms(e);
              }}
            />
          </div>
        </div>
        <div className="flex justify-end pt-4 pr-4">
          <Button
            text="Cancel"
            customStyle={styles.closeBtn}
            onClick={() => {
              isOpen();
            }}
          />
          <Button
            onClick={handleSave}
            text="Save"
            customStyle={styles.createBtn}
          />
        </div>
      </div>
    </div>
  );
}
